import { useAPI } from "../../../hooks";

export const useUnlinkAccount = () =>
  useAPI({
    send: "unlink-county-fusion-escrow-account",
    listenFor: {
      success: "county-fusion-escrow-account-unlinked",
      failure: "error-unlinking-county-fusion-escrow-account",
    },
  });
