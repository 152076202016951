import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Thumbnail } from "../../../../../../../components";
import { selectors } from "../../../../../../../store";
import { SecuredText } from "./_image-preview.secured-text";
import { Wrapper } from "./_image-preview.wrapper";

const imgStyle = css`
  width: 60px;
`;

export const ImagePreview = ({
  docID,
  isHidden = false,
  isSecured = false,
  openPreview = () => {},
  thumbnail = "",
}) => {
  const open = () => openPreview(docID);
  const securedImg = useSelector(
    selectors.assets.getImg("graphic_secured_doc.svg")
  );

  if (isHidden) {
    return null;
  }

  if (isSecured && thumbnail) {
    return (
      <Wrapper>
        <button className="thumbnail" onClick={open}>
          <img css={imgStyle} alt="secured document" src={securedImg} />
          <SecuredText>Secured Document</SecuredText>
        </button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Thumbnail src={thumbnail} openPreview={open} />
    </Wrapper>
  );
};
