import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { DateTime } from "../../components";
import { makeBEMBlock } from "../../utils";
import Currency from "./history.format-currency";

const dateTimeStyle = css`
  padding-left: 11px;
`;

const styles = {
  dontPrint: css`
    @media print {
      display: none;
    }
  `,
  ifPrint: css`
    @media print {
      display: block;
    }
    @media screen {
      display: none;
    }
  `,
};

const block = makeBEMBlock("order-history");

const getHistoryTableConfig = ({ isAdmin }) => ({
  rowProps: () => ({
    className: "order-history__table-row",
  }),
  columns: [
    {
      name: "Order Date",
      key: "createDate",
      headerClassName: "order-history__table-header-cell",
      cellClassName: "order-history__table-cell",
      render: ({ entity }) => (
        <DateTime
          css={dateTimeStyle}
          dateTime={new Date(entity.createdOn)}
          formatting={"MMM dd, yyyy"}
          dataTestId="orderDate"
        />
      ),
    },
    {
      name: isAdmin ? "Name" : "",
      key: "name",
      headerClassName: "order-history__table-header-cell",
      cellClassName: "order-history__table-cell",
      render: ({ entity }) => (isAdmin ? <div>{entity.userName}</div> : null),
    },
    {
      name: "Order ID",
      key: "id",
      headerClassName: "order-history__table-header-cell",
      cellClassName: "order-history__table-cell",
      render: ({ cell, entity }) => {
        if (isAdmin) return <div>{entity.orderNumber}</div>;

        return (
          <>
            <div css={styles.ifPrint}>{entity.orderNumber}</div>
            <Link
              css={styles.dontPrint}
              className={block.element("link")}
              to={`/account/history/${cell}`}
              data-testid="orderLink"
            >
              {entity.orderNumber}
            </Link>
          </>
        );
      },
    },
    {
      name: "# Items",
      key: "",
      headerClassName: "order-history__table-header-cell",
      cellClassName: "order-history__table-cell",
      render: ({ entity }) => (
        <div data-testid="orderNumItems">{entity.itemsNumber}</div>
      ),
    },
    {
      name: "Price",
      key: "amount",
      headerClassName: "order-history__table-header-cell",
      cellClassName: "order-history__table-cell",
      render: ({ cell, entity }) => (
        <Currency
          quantity={cell}
          currency={entity.currency}
          type={entity.type}
          dataTestId="orderPrice"
        />
      ),
    },
  ],
});

export default getHistoryTableConfig;
