// mod: doc-preview

import { connect } from "react-redux";
import { actions, selectors } from "../../store";
import { randomString } from "../../utils";

const mapStateToProps = (state) => ({
  items: selectors.user.getCheckoutItems(state),
  attachmentData: selectors.user.getCheckoutDocumentsAttachmentData(state),
  checkoutType: selectors.user.getCheckoutType(state),
  entityType: selectors.user.getCheckoutEntityType(state),
  priceQuotes: selectors.documents.getPriceQuotes(state),
  department: selectors.workspaces.getSelectedDepartment(state),
  totalPages: selectors.user.getCheckoutItemTotalPages(state),
  isFetchingQuote: selectors.documents.getQuoteProgress(state),
  hasTranslation: selectors.docPreview.hasTranslation(state),
  hasTranscription: selectors.docPreview.hasTranscription(state),
  cartID: selectors.cart.getCartId(state),
  newDocPreviewEnabled: selectors.configuration.isNewDocPreviewEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearQuotes: () => dispatch(actions.documents.clearQuotes()),
  getDocPriceQuote: ({ items, updateQuoteID }) => {
    const quoteId = randomString();

    updateQuoteID(quoteId);

    return dispatch(
      actions.documents.getDocPriceQuote({ docs: items, quoteId })
    );
  },
  cancelGetPriceQuote: (id) =>
    dispatch(actions.documents.cancelGetPriceQuote({ quoteId: id })),
});

export default connect(mapStateToProps, mapDispatchToProps);
