import styled from "@emotion/styled";

export const Container = styled.section`
  margin-top: 100px;
  text-align: center;
  color: #596e93;

  & h1 {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  & a {
    font-weight: 600;
    margin-top: 20px;
    font-size: 1.2em;
    color: #006dd5;
  }

  & a:hover {
    text-decoration: underline;
  }

  & p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  & p:last-of-type {
    margin-bottom: 20px;
  }
`;
