import styled from "@emotion/styled";

export const SortedListHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & p {
    flex-grow: 2;
  }
`;
