// feat: usePaymentMethods

import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import { branch } from "recompose";
import { compose } from "redux";
import { selectors } from "../store";

const withStripe = (Component) => {
  const C = (props) => <Component {...props} />;

  C.displayName = `withStripe(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  const mapStateToProps = (state) => ({
    useStripe: !selectors.configuration.isNCourtEnabled(state),
  });

  return compose(
    connect(mapStateToProps),
    branch(({ useStripe }) => useStripe, injectStripe)
  )(C);
};

export default withStripe;
