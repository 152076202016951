import { types } from "@kofile/ko-search-action-types";
import { useAPI } from "../../hooks/use-api";

export const useIndividualHistory = () =>
  useAPI({
    send: types.FETCH_USER_ORDERS_0,
    initialPayload: {},
    listenFor: {
      success: types.FETCH_USER_ORDERS_FULFILLED_0,
      failure: types.FETCH_USER_ORDERS_REJECTED_0,
    },
  });
