import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ManageSubscriptions } from "../../../../../units/account-settings.subscriptions.manage";
import { SignupForSubscription } from "../../../../../units/account-settings.subscriptions.signup";
import { SettingsPane } from "../settings-pane";
import SubscriptionsContext from "./subscriptions-context";
import { useSubscriptions } from "./use-subscriptions";

const Subscriptions = (props) => {
  const { path } = useRouteMatch();

  const subs = useSubscriptions({ valid: false });

  return (
    <SubscriptionsContext.Provider value={subs}>
      <SettingsPane title="Subscription">
        <Switch>
          <Route exact path={[`${path}/edit/:id`, path]}>
            <ManageSubscriptions {...props} />
          </Route>
          <Route>
            <SignupForSubscription />
          </Route>
        </Switch>
      </SettingsPane>
    </SubscriptionsContext.Provider>
  );
};

export default Subscriptions;
