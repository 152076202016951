import PropTypes from "prop-types";
import { ErrorBar } from "../../../../../../../components";

export const TableError = ({ message }) => (
  <div className="fraud-alert__account_errorbar">
    <ErrorBar error={message} />
  </div>
);

TableError.displayName = "Error";

TableError.propTypes = {
  message: PropTypes.string,
};

TableError.defaultProps = {
  message: "",
};

export default TableError;
