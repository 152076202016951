import { passwordValidations } from "../../../../../../utils/formValidation";

const formConfig = {
  currentPassword: {
    id: "currentPassword",
    type: "PasswordInput",
    ariaLabel: "Current Password",
    label: "Current Password",
    placeholder: "Enter Current Password",
    fieldId: "current-password",
    showPasswordOption: true,
    validations: [{ method: "isRequired" }],
  },
  newPassword: {
    id: "newPassword",
    type: "PasswordInput",
    ariaLabel: "New Password",
    label: "New Password",
    placeholder: "Enter New Password",
    fieldId: "new-password",
    showPasswordOption: true,
    validations: passwordValidations,
  },
};

export default formConfig;
