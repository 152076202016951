import { parse, stringify } from "query-string";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import head from "ramda/src/head";
import isNil from "ramda/src/isNil";
import merge from "ramda/src/merge";
import omit from "ramda/src/omit";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SEARCH_RESULTS } from "../../../constants";
import { withModal, withModalRerenderBlock } from "../../../hoc";
import { actions, selectors } from "../../../store";
import {
  formatSearchText,
  getOffset,
  getPage,
  conditionallyWrapWithQuotes,
} from "../../../utils";
import { updateSort } from "./utils";
import SearchResultsView from "./view";

const doesNotContain = complement(contains);
const hasFetched = propOr(false, "hasFetched");

const selector = (state) => {
  const deptCode = selectors.workspaces.getSelectedDepartment(state);

  return {
    deptCode,
    documents: selectors.documents.getActiveWorkspaceData(state),
    viewModes: selectors.configuration.getDepartmentResultsViewModes(
      state,
      deptCode
    ),
    departmentCodes: selectors.configuration.getDepartmentCodes(state),
    deptConfig: selectors.configuration.getConfigForDepartment(state),
    exportInProgress: selectors.exportResults.getExportResultsProgress(state),
    results: selectors.documents.getResults(state),
    certifiedDate: selectors.search.getCertifiedDate(state, deptCode),
    workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
  };
};

const SearchResults = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [invalidDepartment, setInvalidDepartment] = useState(false);
  const [refetchResults, setRefetchResults] = useState(false);
  const data = useSelector(selector);
  const quickSearchScope = useSelector(selectors.search.getQuickSearchScope);

  const {
    viewModes,
    workspaceID,
    documents,
    deptConfig,
    departmentCodes,
    certifiedDate,
    results,
    exportInProgress,
  } = data;

  const query = {
    limit: SEARCH_RESULTS.DEFAULT_LIMIT,
    offset: SEARCH_RESULTS.DEFAULT_OFFSET,
    ...parse(location.search),
  };

  const { department: deptCode } = query;
  const haveDocsBeenFetched = hasFetched(documents);
  const fetchPayload = actions.documents.fetch({ query, workspaceID });

  const fetch = useCallback(
    (includeAggregations = false) => {
      dispatch({ ...fetchPayload, includeAggregations });
    },
    [fetchPayload]
  );

  useEffect(() => {
    if (refetchResults) {
      const { state = {} } = location;
      const { includeAggregations } = state;

      fetch(includeAggregations);
      setRefetchResults(false);
    }
  }, [refetchResults, location, fetch]);

  useEffect(() => {
    if (history.action === "POP") {
      setRefetchResults(true);
    }
  }, [history]);

  useEffect(() => {
    const { searchOcrText, searchValue } = query;

    if (searchOcrText === "true") {
      const { outputValue: updatedSearchValue, valueWasWrapped } =
        conditionallyWrapWithQuotes({
          searchOcrText: true,
          value: searchValue,
          keywordSearch: quickSearchScope.ocrUsesKeywordSearch === true,
        });

      if (valueWasWrapped) {
        updateResultsRoute({
          query: merge(query, {
            searchValue: updatedSearchValue,
          }),
          shouldRefetch: true,
          workspaceID,
          history,
          routeAdjustedPreFetch: true,
        });
      }
    }
  }, [workspaceID, history, query]);

  useEffect(() => {
    const { limit } = query;

    if (!contains(limit, SEARCH_RESULTS.LIMIT_OPTIONS)) {
      updateResultsRoute({
        query: merge(query, {
          limit: SEARCH_RESULTS.DEFAULT_LIMIT,
        }),
        shouldRefetch: true,
        workspaceID,
        history,
      });
    }
  }, [location, workspaceID, history, query]);

  useEffect(() => {
    if (doesNotContain(deptCode, departmentCodes)) {
      setInvalidDepartment(true);
    }
  }, [deptCode, departmentCodes]);

  useEffect(() => {
    dispatch(
      actions.search.fetchDepartmentDateRanges({
        department: deptCode,
      })
    );
  }, [deptCode]);

  useEffect(() => {
    const { state = {} } = location;
    const { refetch, routeAdjustedPreFetch = false } = state;
    const shouldRefetch = refetch === true || isNil(refetch);

    if (!routeAdjustedPreFetch && (shouldRefetch || !haveDocsBeenFetched)) {
      setRefetchResults(true);
    }
  }, [location, haveDocsBeenFetched]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [documents]);

  const {
    viewType = head(viewModes),
    limit = SEARCH_RESULTS.DEFAULT_LIMIT,
    offset = SEARCH_RESULTS.DEFAULT_OFFSET,
  } = query;

  const handlerArgs = { history, workspaceID, query };
  const numRecords = pathOr(0, ["meta", "numRecords"], documents);
  const isLoading = propOr(false, "isLoading", documents);
  const meta = propOr({ byOrder: [], byHash: {} }, "meta", documents);

  const viewProps = {
    common: { isLoading },
    export: { inProgress: exportInProgress },
    header: {
      listType: viewType,
      viewModes,
      updateViewType: updateViewType(handlerArgs),
      query,
      documents: propOr([], "byOrder", documents),
      meta,
      columns: deptConfig.viewConfig,
      defaultLimit: SEARCH_RESULTS.DEFAULT_LIMIT,
      department: deptConfig,
      certifiedDate,
      numRecords,
    },
    view: {
      searchResults: {
        key: "results",
        type: viewType,
        results,
        onSortUpdate: onSortUpdate(handlerArgs),
      },
      loading: {
        isLoading,
        department: deptConfig,
        loadingText: `Loading Results for ${formatSearchText(
          query,
          deptConfig.partyFields
        )}`,
      },
      noResults: {
        numRecords,
        query,
        partyFields: deptConfig.partyFields,
        key: "noResults",
        errors: prop("errors", documents),
        invalidDepartment,
      },
    },
    filters: {
      data: meta,
      query,
      onFilterUpdate: onFilterUpdate(handlerArgs),
    },
    pagination: {
      onChange: onPagination(handlerArgs),
      rangeSize: limit,
      currentPage: getPage(offset, limit),
      listLength: numRecords,
    },
  };

  return <SearchResultsView {...viewProps} />;
};

export default compose(withModal, withModalRerenderBlock)(SearchResults);

SearchResults.displayName = "SearchResults";

SearchResults.defaultProps = {
  documents: {
    hasFetched: false,
    isLoading: false,
    meta: {
      numRecords: 0,
      statistics: {},
    },
    data: {
      byOrder: [],
      byHash: {},
    },
  },
};

function updateResultsRoute({
  query,
  shouldRefetch,
  includeAggregations,
  workspaceID,
  history,
  routeAdjustedPreFetch,
}) {
  history.push({
    pathname: "/results",
    search: stringify(query),
    state: {
      workspaceID,
      refetch: shouldRefetch,
      includeAggregations,
      routeAdjustedPreFetch,
    },
  });
}

function onPagination({ query, workspaceID, history }) {
  return (page) => {
    const { limit = SEARCH_RESULTS.DEFAULT_LIMIT } = query;

    updateResultsRoute({
      history,
      shouldRefetch: true,
      includeAggregations: false,
      query: merge(query, {
        offset: getOffset(page, limit),
      }),
      workspaceID,
    });
  };
}

function onFilterUpdate({ history, query, workspaceID }) {
  return (key) => (value) => {
    const cleanQuery = value ? query : omit([key], query);
    const objectToMerge = value ? { [key]: value } : {};

    updateResultsRoute({
      history,
      shouldRefetch: true,
      query: merge(cleanQuery, { ...objectToMerge, offset: 0 }),
      workspaceID,
    });
  };
}

function onSortUpdate({ history, query, workspaceID }) {
  return (key) =>
    updateResultsRoute({
      history,
      shouldRefetch: true,
      includeAggregations: false,
      query: { ...query, ...updateSort(query, key) },
      workspaceID,
    });
}

function updateViewType({ history, query, workspaceID }) {
  return (type) => () =>
    updateResultsRoute({
      history,
      shouldRefetch: false,
      query: merge(query, { viewType: type }),
      workspaceID,
    });
}
