import { css } from "@emotion/react";
import { footerHeight } from "../../measurements";

export default {
  container: (theme) => css`
    align-items: baseline;
    box-sizing: border-box;
    display: grid;
    font-size: 12px;
    gap: 10px;
    grid-area: footer;
    grid-template: 1fr / auto auto;
    height: ${footerHeight};
    padding: 16px;
    width: 100%;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      grid-template: 1fr / 1fr;
      height: auto;
      padding: 12px 10px;
      justify-items: center;
    }
  `,
  left: (theme) => css`
    text-align: start;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      text-align: center;
    }
  `,
  right: (theme) => css`
    text-align: end;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      text-align: center;
    }
  `,
  logo: css`
    margin-left: 5px;
    width: 65px;
  `,
};
