import all from "ramda/src/all";
import concat from "ramda/src/concat";
import filter from "ramda/src/filter";
import pipe from "ramda/src/pipe";
import reduce from "ramda/src/reduce";
import replace from "ramda/src/replace";
import split from "ramda/src/split";
import validator from "validator";
import { notEmpty } from "../utils";

export const errors = {
  INCORRECT_RANGE: "Incorrect input range",
  NOT_NUMBERS: "Values must be numbers",
  OUT_OF_RANGE: "Values must be within page limit",
};

export const removeWhiteSpace = replace(/\s/g, "");
const parse = split(",");
const explode = reduce((acc, val) => concat(acc, val.split("-")), []);
const filterEmpty = filter(notEmpty);

const rangesInOrder = (val) => {
  if (validator.matches(val, /[0-9]+-[0-9]+/)) {
    const numbers = val.split("-");

    return parseInt(numbers[0], 10) <= parseInt(numbers[1], 10);
  }

  return true;
};

const checkRangeOrders = all(rangesInOrder);

export const buildList = pipe(removeWhiteSpace, parse, filterEmpty);

export const explodeList = pipe(explode, filterEmpty);

const areAllIntegers = all(validator.isInt);

const validatePageSelection = (totalPages, inputValue) => {
  if (inputValue === "") {
    return "";
  }

  const inRange = (val) => val >= 0 && val <= totalPages;
  const list = buildList(inputValue);

  if (!checkRangeOrders(list)) {
    return errors.INCORRECT_RANGE;
  }

  const explodedList = explodeList(list);

  if (!areAllIntegers(explodedList)) {
    return errors.NOT_NUMBERS;
  }

  if (!all(inRange, explodedList)) {
    return errors.OUT_OF_RANGE;
  }

  return "";
};

export const passwordValidations = [
  {
    method: "isRequired",
  },
  {
    method: "matches",
    arg: /[a-zA-Z0-9.,;:/*@!`%#&}{"'?^~_-]{8,2048}/,
    error:
      "Passwords must be at least 8 characters long and contain only letters, numbers or special characters. Spaces are not allowed.",
  },
];

export default validatePageSelection;
