import { useSelector } from "react-redux";
import { selectors } from "../../store";
import Group from "./_icon.group";
import IconButton from "./document-actions.button";
import PageInput from "./document-actions.page-input";
import style from "./style";
import { isMultiPage, isSinglePage } from "./utils";

const DocumentActions = ({ page, disabled }) => {
  const hideThumbnails = useSelector(
    selectors.configuration.shouldHideThumbnailInDept
  );

  return (
    <nav css={style.actionsContainer}>
      <Group>
        <IconButton
          text="Go To First Page"
          icon="jump_to_end_triangle"
          shouldMirrorIcon={true}
          onClick={page.goToFirstPage}
          disabled={!page.hasPrev || disabled}
        />
        <IconButton
          text="Go To Previous Page"
          icon="jump_triangle"
          shouldMirrorIcon={true}
          onClick={page.goToPrevPage}
          disabled={!page.hasPrev || disabled}
        />
        <IconButton
          text="Go To Next Page"
          icon="jump_triangle"
          onClick={page.goToNextPage}
          disabled={!page.hasNext || disabled}
        />
        <IconButton
          text="Go To Last Page"
          icon="jump_to_end_triangle"
          onClick={page.goToLastPage}
          disabled={!page.hasNext || disabled}
        />
      </Group>

      <PageInput
        currentPage={page.currentPage}
        pageLimit={page.pageCount}
        goToPage={page.goToPage}
        disabled={disabled}
      />

      <Group>
        <IconButton
          text="Zoom Out"
          icon="magnifying_glass_out"
          onClick={page.zoomOut}
          disabled={disabled}
        />
        <IconButton
          text="Zoom In"
          icon="magnifying_glass_in"
          onClick={page.zoomIn}
          disabled={disabled}
        />
      </Group>

      <Group>
        <IconButton
          text="Rotate Left"
          icon="rotate"
          onClick={page.rotateLeft}
          disabled={disabled}
        />
        <IconButton
          text="Rotate Right"
          icon="rotate"
          shouldMirrorIcon={true}
          onClick={page.rotateRight}
          disabled={disabled}
        />
      </Group>

      <Group>
        <IconButton
          text="Fit Width"
          icon="fit_width"
          onClick={page.fitWidth}
          disabled={disabled}
        />
        <IconButton
          text="Fit Height"
          icon="fit_height"
          onClick={page.fitHeight}
          disabled={disabled}
        />
        <IconButton
          text="Fit Best"
          icon="fit_best"
          onClick={page.fitBest}
          disabled={disabled}
        />
      </Group>

      {hideThumbnails !== true && (
        <Group>
          <IconButton
            text="Single Page View"
            icon="page_single"
            onClick={page.viewSinglePage}
            isActive={isSinglePage(page.viewType)}
          />
          <IconButton
            text="Multi Page View"
            icon="page_multi"
            onClick={page.viewMultiPage}
            isActive={isMultiPage(page.viewType)}
          />
        </Group>
      )}

      {page.totalHits > 0 && (
        <Group>
          <IconButton
            text="Toggle Ocr Navigation"
            icon="ocr_nav"
            onClick={page.toggleOcrVisibility}
            isActive={page.isOcrVisible}
          />
        </Group>
      )}
    </nav>
  );
};

export default DocumentActions;
