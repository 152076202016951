import styled from "@emotion/styled";

export const RemoveModalBody = styled.div`
  border-bottom: 2px solid #d8dde6;
  color: rgba(4, 29, 68, 0.8);
  font-family: "Source Sans Pro";
  font-size: 16px;
  padding: 40px 30px 50px;
  text-align: center;
`;
