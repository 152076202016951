import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../store";
import style from "./style";

const BackButton = () => {
  const dispatch = useDispatch();
  const workspace = useSelector(selectors.workspaces.getActiveWorkspace);
  const hasResults = workspace.searchUrl ?? false;
  const inWorkspace = { workspaceID: workspace.id };
  const { getImg } = selectors.assets;
  const backArrowIcon = useSelector(getImg("icon_arrow_left.svg"));
  const text = hasResults ? "Back to Results" : "Go To Search";

  const goBack = (e) => {
    e.preventDefault();
    dispatch(push(workspace.searchUrl, inWorkspace));
  };

  const goToHome = (e) => {
    e.preventDefault();
    dispatch(push("/", inWorkspace));
  };

  return (
    <button css={style.back} onClick={hasResults ? goBack : goToHome}>
      <img src={backArrowIcon} alt={text} />
      {text}
    </button>
  );
};

BackButton.displayName = "BackButton";

export default BackButton;
