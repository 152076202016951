import path from "ramda/src/path";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../../components";
import { selectors } from "../../../store";
import { isAbsent } from "../../../utils";
import Columns from "./columns";
import { getComponentByKey } from "./get-prop-display";
import { GroupWrap } from "./group-wrap";
import { useManageExtendedGroups } from "./use-manage-extended-groups";

export const Summary = () => {
  const summary = useSelector(selectors.docPreview.getDocumentSummary);
  const { title, groups, columns, id, truncatedCount = 10 } = summary;
  const extendedProperties = useManageExtendedGroups(id);

  const isOpen = (key) => path(["data", key, "isOpen"], extendedProperties);

  const reconcileExtendedGroups = ({ key, meta }) =>
    extendedProperties.reconcile(key, prop("fetchKey", meta));

  if (isAbsent(summary.id)) return <LoadingSpinner />;

  return (
    <div className="doc-preview__summary">
      <div className="doc-preview__summary-header">
        <h2>{title}</h2>
      </div>

      <Columns columns={columns} />

      {groups.map((data) => {
        const Component = getComponentByKey(data.key);
        if (isAbsent(Component)) return null;

        return (
          <GroupWrap
            key={`${data.key}-${id}`}
            data={data}
            truncatedCount={truncatedCount}
            isOpen={isOpen(data.key)}
            loading={prop("loading", extendedProperties)}
            toggleOpen={() => reconcileExtendedGroups(data)}
          >
            <Component />
          </GroupWrap>
        );
      })}
    </div>
  );
};

export default Summary;
