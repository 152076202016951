import { types as syncTypes } from "@kofile/ko-search-action-types";
import types from "../types/password";

export default {
  resetPassword: (payload) => ({
    type: syncTypes.REQUEST_RESET_PASSWORD_0,
    payload,
  }),
  updatePassword: (payload) => ({
    type: syncTypes.UPDATE_RESET_PASSWORD_0,
    payload,
  }),
  clearState: (payload) => ({ type: types.CLEAR_STATE, payload }),
  clearError: (payload) => ({ type: types.CLEAR_ERROR, payload }),
};
