import { useAPI } from "../../../hooks";

export const useLinkAccount = () =>
  useAPI({
    send: "link-county-fusion-escrow-account",
    listenFor: {
      success: "county-fusion-escrow-account",
      failure: "error-linking-county-fusion-escrow-account",
    },
  });
