import styled from "@emotion/styled";

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;

  & .loading__spinner {
    height: 30px;
    margin: 0;
    width: 30px;
  }

  & > .loading__container {
    width: 30px;
  }

  & .react-datepicker__input-container > input {
    background: none;
    border: none;
    box-sizing: border-box;
    color: #041d44;
    cursor: pointer;
    font-family: "Source Sans Pro";
    height: 35px;
    padding: 6px 2px;
    width: 80px;
  }
`;
