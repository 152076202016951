import { stringify } from "query-string";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { A11yAnnouncement, ReturnButton, Stripe } from "../../../components";
import { withSSRRenderGuard } from "../../../hoc";
import { selectors } from "../../../store";
import RegistrationForm from "./View";

const Register = () => {
  const { user } = selectors;
  const email = useSelector(user.getUserEmail);
  const registrationComplete = useSelector(user.getRegistrationComplete);

  if (registrationComplete) {
    const emailQuery = stringify({ email });
    const returnPath = `/check-registration-email?${emailQuery}`;

    window.location = `/signout?returnPath=${returnPath}`;

    return null;
  }

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <A11yAnnouncement>Navigated to register page</A11yAnnouncement>
      <ReturnButton />
      <Stripe>
        <RegistrationForm />
      </Stripe>
    </>
  );
};

export default withSSRRenderGuard(Register);
