import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TermsAndConditions } from "../components";
import { actions, selectors } from "../store";

const Types = {
  FraudAlert: "fraud-alert",
  Status: "status",
};

export const useTermsAndConditions = ({
  cancel = () => {},
  submit = () => {},
  use,
}) => {
  const [language, setLanguage] = useState("en");

  const dispatch = useDispatch();
  const { configuration, user, termsAndConditions } = selectors;
  const { fetch, fetchStatus } = actions.termsAndConditions;

  const tenantId = useSelector(configuration.getTenantId);
  const error = useSelector(termsAndConditions.errorFetchingTerms);
  const { id: userId } = useSelector(user.getUserDetails);
  const { data, loading, hasAccepted } = useSelector(
    termsAndConditions.getTerms
  );

  const changeLanguage = (e) => {
    setLanguage(e.value);
  };

  useEffect(() => void dispatch(fetchStatus({ tenantId, userId })), [tenantId]);

  useEffect(() => void dispatch(fetch({ tenantId })), [tenantId]);

  const acceptTerms = async () => {
    await dispatch(actions.termsAndConditions.accept());
    submit();
  };

  const props = {
    cancel,
    changeLanguage,
    data,
    error,
    language,
    loading,
    submit: acceptTerms,
  };

  const display = () => <TermsAndConditions {...props} />;

  const types = {
    [Types.FraudAlert]: { display, loading, hasAccepted },
    [Types.Status]: { hasAccepted },
  };

  return types[use];
};

export const useFraudAlertTerms = ({ submit, cancel }) => {
  return useTermsAndConditions({ use: Types.FraudAlert, submit, cancel });
};

export const useTermsStatus = () => {
  return useTermsAndConditions({ use: Types.Status });
};
