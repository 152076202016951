import PropTypes from "prop-types";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import { Component } from "react";
import { List, AutoSizer } from "react-virtualized";
import Option from "./Option";

class FilterSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selections: props.selections || [],
    };

    this.getListRef = (ref) => {
      this.listRef = ref;
    };

    this.addSelection = (selection) => {
      const newSelections = this.state.selections.concat([selection]);

      this.props.notifyParent(newSelections.join(","));

      this.setState({ selections: newSelections });
    };

    this.removeSelection = (selection) => {
      const newSelections = this.state.selections.filter(
        (x) => x !== selection
      );

      this.props.notifyParent(newSelections.join(","));

      this.setState({ selections: newSelections });
    };
  }

  render() {
    const { options, id, isOnlySelection = false } = this.props;
    const { selections } = this.state;
    const optionLabels = map(prop("label"), options);
    const optionHits = map(prop("hits"), options);

    const rowRenderer = ({ key, index, style, isScrolling }) => {
      const value = optionLabels[index];
      const hits = optionHits[index];

      const optionProps = {
        id,
        removeSelection: this.removeSelection,
        addSelection: this.addSelection,
        value,
        index,
        hits,
        selections,
        isOnlySelection,
        style,
        isScrolling,
      };

      return <Option key={key} {...optionProps} />;
    };

    const rowHeight = 25;
    const maxHeight = 250;
    const rowCount = optionLabels.length;
    const height = Math.min(maxHeight, rowCount * rowHeight);

    const listProps = {
      height,
      rowCount,
      rowRenderer,
      rowHeight,
    };

    return (
      <div className="filter-set">
        <AutoSizer disableHeight>
          {({ width }) => (
            <List ref={this.getListRef} width={width} {...listProps} />
          )}
        </AutoSizer>
      </div>
    );
  }
}

FilterSet.displayName = "FilterSet";

FilterSet.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  notifyParent: PropTypes.func,
  selections: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  isOnlySelection: PropTypes.bool,
};

FilterSet.defaultProps = {
  notifyParent: () => {},
  selections: [],
};

export default FilterSet;
