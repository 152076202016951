import styled from "@emotion/styled";

const disabledStyles = `
  & .form-row {
    opacity: 0.5;
  }

  & .form-row:first-of-type {
    opacity: 1;
  }

  & input:disabled {
    background: var(--color-neutral-lightest);
  }
`;

export const Form = styled.form`
  ${(props) => props.disabled && disabledStyles}
`;
