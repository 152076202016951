import cond from "ramda/src/cond";
import has from "ramda/src/has";
import lensProp from "ramda/src/lensProp";
import set from "ramda/src/set";
import T from "ramda/src/T";
import {
  hasLoaded,
  isInert,
  needsAuthentication,
  isInit,
  isLoading,
  hasError,
} from "../constants/status";

const defaultLens = lensProp("default");
const withDefault = set(defaultLens, T);
const defaultConditions = withDefault({
  isInert,
  isInit,
  isLoading,
  hasLoaded,
  hasError,
  needsAuthentication,
});

export const chooseViewFor = (status) => ({
  knownConditions: defaultConditions,
  with(givenConditions) {
    this.knownConditions = withDefault(givenConditions);

    return this;
  },
  when(statusMap) {
    const conditions = [];

    for (const condition in statusMap) {
      if (has(condition, this.knownConditions)) {
        conditions.push([
          this.knownConditions[condition],
          statusMap[condition],
        ]);
      }
    }

    return cond(conditions)(status);
  },
});
