import { useSelector } from "react-redux";
import { selectors } from "../../store";
import useDocument from "../doc-detail.common/useDocument";

export default ({
  hitIndex,
  hitMapIndex,
  isOcrVisible,
  ocrHits,
  setHitIndex,
  setHitMapIndex,
  toggleOcrVisibility,
  totalHits,
}) => {
  const { assets } = selectors;
  const getImg = useSelector(assets.getBoundImg);
  const exitIcon = getImg("icon_close_viewer.svg");
  const jumpToEndIcon = getImg("icon_jump_to_end_triangle.svg");
  const jumpToNextIcon = getImg("icon_jump_triangle.svg");
  const { hitMap } = ocrHits;
  const lastPage = totalHits - 1;

  const isFirst = hitMapIndex === 0;
  const isLast = hitMapIndex === lastPage;

  const { currentPage, setCurrentPage } = useDocument();

  function goToFirstHit() {
    const [page, hitIndex] = hitMap[0];
    setHitIndex(hitIndex);
    setHitMapIndex(0);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }

  function goToLastHit() {
    const [page, hitIndex] = hitMap[lastPage];
    setHitIndex(hitIndex);
    setHitMapIndex(lastPage);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }

  function goToNextHit() {
    const nextPage = hitMapIndex + 1;
    if (nextPage >= lastPage) return goToLastHit();
    const [page, hitIndex] = hitMap[nextPage];
    setHitIndex(hitIndex);
    setHitMapIndex(nextPage);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }

  function goToPreviousHit() {
    const prevPage = hitMapIndex - 1;
    if (prevPage <= 0) return goToFirstHit();
    const [page, hitIndex] = hitMap[prevPage];
    setHitIndex(hitIndex);
    setHitMapIndex(prevPage);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }

  return {
    exitIcon,
    goToFirstHit,
    goToLastHit,
    goToNextHit,
    goToPreviousHit,
    hitIndex,
    isFirst,
    isLast,
    isOcrVisible,
    jumpToEndIcon,
    jumpToNextIcon,
    toggleOcrVisibility,
    totalHits,
  };
};
