import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { cart, user } from "../actions";
import types from "../types/initialization";

export const fetchCartOnLoad$ = (actions$) =>
  actions$.pipe(
    ofType(types.BEFORE_LOAD),
    map(() => cart.fetch())
  );

export const nonInteractiveLogin = (actions$) =>
  actions$.pipe(
    ofType(types.BEFORE_LOAD),
    map(() => user.nonInteractiveSignin())
  );

export default [fetchCartOnLoad$];
