import { TooltipTrigger } from "../../components";

const DateInput = ({ tooltip, ...props }) => (
  <TooltipTrigger
    className="react-datepicker__input"
    tooltip={tooltip}
    render={({ innerRef, ...triggerProps }) => (
      <input
        {...props}
        {...triggerProps}
        ref={innerRef}
        type="text"
        aria-label={tooltip}
      />
    )}
  />
);

export default DateInput;
