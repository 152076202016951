import { useAPI } from "../../../hooks";

export const useCheckAccountConnection = () =>
  useAPI({
    send: "verify-county-fusion-escrow-is-linked",
    listenFor: {
      success: "county-fusion-escrow-account-is-linked",
      failure: "error-verifying-county-fusion-escrow-account-linking",
    },
  });
