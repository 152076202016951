import { format } from "date-fns";
import PropTypes from "prop-types";
import any from "ramda/src/any";
import isNil from "ramda/src/isNil";
import { isNotNil, makeDate } from "../../utils";
import { Container } from "./_date-range.custom.container";
import { InlineDatePicker } from "./date-range.inline-picker";

const FMT = "yyyyMMdd";

const parseDateRange = (str) => {
  const [rawFrom, rawTo] = str.split(",");
  const from = makeDate(rawFrom);
  const to = makeDate(rawTo);

  return { from, to };
};

const formatDateRange = ({ from, to }) => {
  const rawFrom = format(from, FMT);
  const rawTo = format(to, FMT);

  return `${rawFrom},${rawTo}`;
};

const hasInvalidDates = any(isNil);

export const CustomDateRange = (props) => {
  const {
    isOpen,
    value,
    dateRangeOption,
    dateRange,
    maxRange,
    onChange,
    isLoading,
    disabled,
    label,
  } = props;

  if (value === dateRangeOption && isNotNil(dateRange) && isNotNil(maxRange)) {
    const { from: startDate, to: endDate } = parseDateRange(dateRange);
    const { from: minStartDate, to: maxEndDate } = parseDateRange(maxRange);

    if (hasInvalidDates([startDate, endDate, minStartDate, maxEndDate])) {
      return null;
    }

    const onChangeStart = (newValue) => {
      if (newValue instanceof Date) {
        onChange({ value: formatDateRange({ from: newValue, to: endDate }) });
      }
    };

    const onChangeEnd = (newValue) => {
      if (newValue instanceof Date) {
        onChange({ value: formatDateRange({ from: startDate, to: newValue }) });
      }
    };

    return (
      <Container isOpen={isOpen}>
        <InlineDatePicker
          minDate={minStartDate}
          maxDate={maxEndDate}
          startDate={startDate}
          endDate={endDate}
          onChangeStart={onChangeStart}
          onChangeEnd={onChangeEnd}
          dropdownOpen={isOpen}
          isLoading={isLoading}
          disabled={disabled}
          label={label}
        />
      </Container>
    );
  }

  return null;
};

CustomDateRange.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  dateRangeOption: PropTypes.string.isRequired,
  dateRange: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
