import PropTypes from "prop-types";
import contains from "ramda/src/contains";
import { Checkbox } from "../../../../../../../components";

const Option = ({
  isOnlySelection,
  value,
  hits,
  removeSelection,
  addSelection,
  selections,
  id,
  style,
  index,
}) => {
  const isSelected = contains(value, selections);

  const onClickHandler = () =>
    isSelected ? removeSelection(value) : addSelection(value);

  const hitsText = isOnlySelection && !isSelected ? `(+${hits})` : `(${hits})`;

  return (
    <div style={style} index={index}>
      <div className="filter-set__option">
        <Checkbox
          id={`${id}_${value}`}
          className="filter-set__option-checkbox"
          isChecked={isSelected}
          onClick={onClickHandler}
        />
        <span className="filter-set__option-label">
          <span className="filter-set__option-label-value">{value}</span>
          <span className="filter-set__option-label-hits">{hitsText}</span>
        </span>
      </div>
    </div>
  );
};

Option.displayName = "Option";

Option.propTypes = {
  value: PropTypes.string.isRequired,
  hits: PropTypes.number,
  removeSelection: PropTypes.func.isRequired,
  addSelection: PropTypes.func.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};

export default Option;
