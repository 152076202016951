import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../store";

const styles = {
  container: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: "Source Sans Pro", sans-serif;
    height: 100%;
  `,
  cartIcon: css`
    height: 100px;
    width: 100px;
    margin-top: 90px;
    filter: brightness(0) saturate(100%) invert(52%) sepia(37%) saturate(3521%)
      hue-rotate(122deg) brightness(94%) contrast(80%);
  `,
  header: css`
    color: rgba(4, 29, 68, 0.7);
    font-size: 28px;
    height: 2.5rem;
    line-height: 40px;
    margin-top: 40px;
    text-align: center;
  `,
  description: css`
    color: rgba(4, 29, 68, 0.7);
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  `,
};

const EmptyCart = () => {
  const cartIcon = useSelector(selectors.assets.getImg("icon_cart.svg"));
  return (
    <section css={styles.container}>
      <img
        css={styles.cartIcon}
        src={cartIcon}
        alt="cart icon"
        aria-hidden="true"
      />
      <h3 css={styles.header}>Your Shopping Cart is Empty</h3>
      <p css={styles.description}>
        To add documents to your cart, search for documents and choose “Add to
        Cart” from the search results page.
      </p>
    </section>
  );
};

export default EmptyCart;
