import thunkify from "ramda/src/thunkify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const State = {
  Error: "error",
  Ready: "ready",
  Disabled: "disabled",
  Submitting: "submitting",
};

const getState = (props) => {
  const {
    error,
    ncourtPaymentInitiated,
    isLoading,
    clicked,
    checkoutInProgress,
    disabled,
    useNCourt,
  } = props;

  if (error) return State.Error;

  if (disabled) return State.Disabled;

  if (useNCourt && (ncourtPaymentInitiated || clicked)) {
    return State.Submitting;
  }

  if (checkoutInProgress || isLoading) return State.Submitting;

  return State.Ready;
};

const Footer = (props) => {
  const {
    disabled = false,
    isLoading = false,
    submit,
    isSubmittingPayment,
  } = props;

  const [clicked, setClicked] = useState(false);
  const [state, setFooterState] = useState(State.Ready);

  const { ncourt, checkout, configuration } = selectors;
  const useNCourt = useSelector(configuration.isNCourtEnabled);
  const ncourtPaymentInitiated = useSelector(ncourt.expressCheckoutInitiated);
  const checkoutInProgress = useSelector(checkout.getInProgress);
  const ncourtError = useSelector(ncourt.checkoutError);
  const hasError = !!ncourtError;

  const bools = {
    disabled,
    isLoading,
    ncourtPaymentInitiated,
    checkoutInProgress,
    clicked,
    hasError,
    useNCourt,
  };

  const onClick = (e) => {
    setClicked(true);
    submit(e);
  };

  useEffect(thunkify(setClicked)(ncourtPaymentInitiated), [
    ncourtPaymentInitiated,
  ]);
  useEffect(thunkify(setFooterState)(getState(bools)), [bools]);

  useEffect(() => {
    if (!isSubmittingPayment) {
      setFooterState(State.Ready);
    }
  }, [isSubmittingPayment]);

  if (state === State.Error) {
    return null;
  }

  return (
    <button
      disabled={[State.Disabled, State.Submitting].includes(state)}
      className="express-checkout__place-order"
      data-testid="checkoutOrderButton"
      onClick={onClick}
    >
      {state === State.Submitting && "Processing..."}
      {state !== State.Submitting && "Place Your Order"}
    </button>
  );
};

export default Footer;
