import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Link } from "../../../../components";
import { selectors } from "../../../../store";
import { Amount } from "./_cart.amount";
import { Container } from "./_cart.container";
import { Text } from "./_cart.text";
import { NavLink } from "./_nav-link";
import { navLink } from "./_style.nav-link";

const iconStyle = css`
  height: 20px;
  width: 20px;
`;

const CartLink = ({ cartAmount = 0 }) => {
  const { getImg } = selectors.assets;
  const cartIcon = useSelector(getImg("icon_cart.svg"));

  return (
    <NavLink>
      <Link
        to="/cart/contents"
        workspaceID="cart"
        dataTourId="cartWidget"
        aria-label="Cart"
        role="button"
        css={navLink}
      >
        <Container>
          <img css={iconStyle} src={cartIcon} alt="cart" />
          <Text data-testid="cart">Cart</Text>
          <Amount data-testid="headerCartCount">{cartAmount}</Amount>
        </Container>
      </Link>
    </NavLink>
  );
};

export default CartLink;
