import equals from "ramda/src/equals";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectors } from "../../../../store";
import { ContactSupport } from "../../../../units/common/contact-support";
import { PaymentMethods } from "../../../../units/payment-methods";
import { chooseViewFor } from "../../../../utils/choose-view-for";
import { keyMirror } from "../../../../utils/key-mirror";
import NCourtCheckout from "./providers.ncourt";
import StripeCheckout from "./providers.stripe";

const Kind = keyMirror("Stripe", "NCourt");
const isStripe = equals(Kind.Stripe);
const isNCourt = equals(Kind.NCourt);

export const OldPaidCheckout = () => {
  const useNCourt = useSelector(selectors.configuration.isNCourtEnabled);
  const checkoutType = useNCourt ? Kind.NCourt : Kind.Stripe;

  return chooseViewFor(checkoutType)
    .with({ isStripe, isNCourt })
    .when({
      isStripe: () => <StripeCheckout />,
      isNCourt: () => <NCourtCheckout />,
      default: () => <ContactSupport />,
    });
};

const NewPaidCheckout = () => {
  const totalPrice = useSelector(selectors.cart.getTotalPrice);
  const cartId = useSelector(selectors.cart.getCartId);
  const item = { type: "cart", id: cartId };

  return <PaymentMethods amount={totalPrice} item={item} />;
};

export const PaidCheckout = () => {
  const history = useHistory();
  const order = useSelector(selectors.checkout.getOrder);
  const orderIsPaid = useSelector(selectors.checkout.getSuccess);

  const usePaymentMethods = useSelector(
    selectors.configuration.usePaymentMethods
  );

  useEffect(() => {
    if (orderIsPaid === true) {
      history.push(`/cart/checkout-complete/${order.id}`);
    }
  }, [orderIsPaid, order]);

  return usePaymentMethods ? <NewPaidCheckout /> : <OldPaidCheckout />;
};

export default PaidCheckout;
