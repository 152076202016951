import { types as syncTypes } from "@kofile/ko-search-action-types";
import adjust from "ramda/src/adjust";
import merge from "ramda/src/merge";
import { order as initialState } from "../initialState";
import types from "../types/order";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.FETCH_ORDER_1]: fetchOrder,
  [syncTypes.FETCH_ORDER_FULFILLED_2]: fetchOrderFulfilled,
  [syncTypes.FETCH_ORDER_REJECTED_1]: fetchOrderRejected,
  [syncTypes.FETCH_ORDER_CANCELLED_1]: fetchOrderCancelled,
  [syncTypes.FETCH_ORDER_DOCUMENT_FULLFILLED_0]: fetchOrderDocumentFulfilled,
  [syncTypes.FETCH_ORDER_ZIP_FULLFILLED_0]: fetchOrderZipFulfilled,
  [types.CLEAR]: clear,
});

function fetchOrder(state, { payload }) {
  return {
    ...state,
    id: payload,
    isLoading: true,
    data: null,
    error: null,
  };
}

function fetchOrderFulfilled(state, { payload }) {
  return {
    ...state,
    data: payload,
    isLoading: false,
    error: null,
  };
}

function fetchOrderRejected(state, { payload }) {
  return {
    ...state,
    data: null,
    isLoading: false,
    error: payload,
  };
}

function fetchOrderCancelled(state) {
  return {
    ...state,
    data: null,
    isLoading: false,
    error: null,
  };
}

function fetchOrderDocumentFulfilled(state, { payload }) {
  const { data } = state;
  const indexToUpdate = data.items.findIndex(({ id }) => id === payload.docID);
  const mergeLink = merge({ link: payload.link });
  const updatedItems = adjust(mergeLink, indexToUpdate, data.items);

  return {
    ...state,
    data: merge(data, { items: updatedItems }),
  };
}

function fetchOrderZipFulfilled(state, { payload }) {
  return {
    ...state,
    zipLink: payload.link,
  };
}

function clear(state) {
  return {
    ...state,
    data: null,
    isLoading: false,
    error: null,
  };
}
