export const ENTITY_TYPES = {
  DOCUMENT: "document",
  ATTACHMENT: "attachment",
  EXPORT: "export",
};

export const CHECKOUT_TYPES = {
  NORMAL: "normal",
  EXPRESS: "express",
};
