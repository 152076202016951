const tourSteps = [
  {
    title: "Choose a Department to Search Within",
    selector: "#department-select",
    position: "right",
    text: "Search within a particular department, which is a group of related types of documents.",
  },
  {
    title: "Enter a Word or Phrase in the Search Field",
    selector: "[data-tourid='searchInputBox']",
    position: "right",
    text: "The word or phrase entered in the search criteria will be searched through all searchable categories.",
  },
  {
    title: "Define the Date Range",
    selector: "[data-tourid='dateRangeSelect']",
    position: "left",
    text: "Define a recorded start date and a recorded end date to narrow your search results.",
  },
  {
    title: "Run the Search Query",
    selector: "[data-tourid='searchSubmitButton']",
    position: "left",
    text: "Press the 'Enter' key or click on the orange magnifying glass to run the search query.",
  },
  {
    title: "Run an Advanced Search",
    selector: "[data-tourid='advancedSearchLink']",
    position: "right",
    text: "Search individual index fields.",
  },
  {
    title: "View Your Recent Search Activity",
    selector: "[data-tourid='searchHistoryControl']",
    position: "right",
    text: "See searches you've previously run and click on one to re-run a particular search query.",
  },
];

export default tourSteps;
