import styled from "@emotion/styled";

export const ActionButton = styled.button`
  color: #006dd5;
  font-size: 12px;
  padding: 9px 16px;
  background-color: var(--color-neutral-lightest);
  cursor: pointer;
  border: 1px solid rgba(4, 29, 68, 0.15);
  border-radius: 4px;
  white-space: nowrap;
`;
