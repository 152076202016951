import { Route, Switch } from "react-router-dom";
import { ChoosePlan } from "../account-settings.subscriptions.common/choose-plan";
import { PendingOverview } from "../account-settings.subscriptions.manage/subscriptions.active.pending-overview";
import { ApplicationOverview } from "./application.overview";
import { SubmitApplication } from "./application.submit";
import { Wrapper } from "./application.wrapper";

export const ApplyForSubscription = ({
  Go,
  Routes,
  Replace,
  plan,
  setPlan,
}) => (
  <Switch>
    <Route path={Routes.PendingApplication}>
      <PendingOverview sub={plan} />
    </Route>
    <Route path={Routes.Activate}>
      <Wrapper view="landing">
        <ApplicationOverview next={Go.ToChoose} />
      </Wrapper>
    </Route>
    <Route path={Routes.Choose}>
      <ChoosePlan
        select={setPlan}
        next={Go.ToSubmitApplication}
        showMultiUserPlans={false}
      />
    </Route>
    <Route path={Routes.SubmitApplication}>
      <Wrapper view="confirmation">
        <SubmitApplication prev={Replace.WithChoose} plan={plan} />
      </Wrapper>
    </Route>
  </Switch>
);
