import styled from "@emotion/styled";

export const Button = styled.button`
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;
