import { isAbsent } from "../../utils";

const formatTo = {
  rawNumber: (formattedNumber) => {
    return formattedNumber.replace(/\D/g, "");
  },
  phoneNumber: (rawNumber) => {
    if (isAbsent(rawNumber)) return "";
    const makeSubstr = (start, end) => rawNumber.substring(start, end);

    const steps = [
      { condition: rawNumber.length > 0, format: `(${makeSubstr(0, 3)})` },
      { condition: rawNumber.length >= 4, format: ` ${makeSubstr(3, 6)}` },
      { condition: rawNumber.length >= 7, format: `-${makeSubstr(6, 10)}` },
    ];

    return steps.reduce(
      (acc, step) => (step.condition ? acc + step.format : acc),
      ""
    );
  },
};

export const PhoneNumberInput = (props) => {
  const { value = "" } = props;
  const raw = formatTo.rawNumber(value);

  const handleInputChange = (e) => {
    const input = e.target.value;
    const rawNum = formatTo.rawNumber(input);

    props.onChange(rawNum);
  };

  return (
    <input
      id={props.id || "phone-input"}
      className="form-field__input"
      type="text"
      value={formatTo.phoneNumber(raw)}
      onChange={handleInputChange}
      maxLength={14} // Limit input to formatted length
      placeholder={props.placeholder || "(000) 000-0000"}
      aria-label={props.ariaLabel || "Phone Number"}
      disabled={props.disabled}
    />
  );
};
