import { CheckboxField } from "../../components/Fields";
import { isNotNil } from "../../utils";
import { capitalizeFirstLetter } from "../../utils/capitalize-first-letter";

const AddDocCheckbox = ({ isPresent, type, action, label }) => {
  if (!isPresent) return null;

  const standardLabel = `Include ${capitalizeFirstLetter(type)}`;
  const checkboxLabel = isNotNil(label) ? label : standardLabel;

  return (
    <CheckboxField
      id={`add-${type}`}
      checkboxLabel={checkboxLabel}
      labelPosition="right"
      onChange={action}
    />
  );
};

export default AddDocCheckbox;
