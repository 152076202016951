import { types as syncTypes } from "@kofile/ko-search-action-types";
import always from "ramda/src/always";
import compose from "ramda/src/compose";
import cond from "ramda/src/cond";
import contains from "ramda/src/contains";
import head from "ramda/src/head";
import identity from "ramda/src/identity";
import lensPath from "ramda/src/lensPath";
import lensProp from "ramda/src/lensProp";
import mergeRight from "ramda/src/mergeRight";
import omit from "ramda/src/omit";
import propOr from "ramda/src/propOr";
import set from "ramda/src/set";
import T from "ramda/src/T";
import { CHECKOUT_TYPES, ENTITY_TYPES } from "../../constants";
import { user as initialState } from "../initialState";
import types from "../types/user";
import { handleActions } from "./utils";

export const errorTypes = cond([
  [
    contains("ECONNREFUSED"),
    always("There was a server error. Please try again."),
  ],
  [T, identity],
]);

export default handleActions(initialState, {
  [types.CLEAR_ERROR]: (state) => ({
    ...state,
    loginError: "",
    cardError: "",
    registerError: "",
    changePasswordError: "",
  }),

  [types.SET_ERROR]: (state, { payload }) => ({
    ...state,
    cardError: payload.error,
  }),

  [syncTypes.REGISTER_0]: (state) => ({
    ...state,
    loading: true,
  }),

  [syncTypes.REGISTER_FULFILLED_1]: (state, { payload }) => ({
    ...state,
    loading: false,
    registrationComplete: true,
    loggedIn: true,
    userDetails: payload.userData,
  }),

  [syncTypes.REGISTER_REJECTED_1]: (state, { payload }) => ({
    ...state,
    loading: false,
    registerError: errorTypes(payload.errors[0]),
  }),

  [types.CLEAR_REGISTRATION_COMPLETE]: (state) => ({
    ...state,
    registrationComplete: false,
  }),

  [syncTypes.SIGN_IN_0]: (state) => ({
    ...state,
    loading: true,
    loginError: "",
  }),

  [syncTypes.SIGN_IN_FULFILLED_1]: (state, { payload }) => ({
    ...state,
    loading: false,
    loggedIn: true,
    userDetails: payload.userDetails,
    cardInfo: payload.cardInfo,
    loginError: "",
  }),

  [syncTypes.NON_INTERACTIVE_SIGN_IN_FULFILLED_2]: (state, { payload }) => ({
    ...state,
    loading: false,
    loggedIn: !payload.isAnonymous,
    userDetails: payload.userDetails,
    cardInfo: payload.cardInfo,
    loginError: "",
  }),

  [syncTypes.SIGN_IN_REJECTED_1]: (state, { payload }) => ({
    ...state,
    loading: false,
    loggedIn: false,
    userDetails: {},
    loginError: errorTypes(payload.errors),
  }),

  [syncTypes.SIGN_OUT_0]: (state) => ({
    ...state,
    loading: true,
    loginError: "",
  }),

  [syncTypes.SIGN_OUT_FULFILLED_1]: (state) => ({
    ...state,
    loading: false,
    loggedIn: false,
    userDetails: {},
    loginError: "",
    verified: false,
  }),

  ["update-user-info"]: (state) => ({
    ...state,
    loading: true,
  }),

  ["user-info-updated"]: (state, { payload }) => ({
    ...state,
    userDetails: mergeRight(state.userDetails, payload),
    settings: mergeRight(state.settings, {
      contactPreferences:
        payload?.alertMethods ?? state.settings.contactPreferences,
      consentToContact:
        payload?.consentToContact ?? state.settings.consentToContact,
    }),
    loading: false,
  }),

  ["error-updating-user-info"]: (state, { payload }) => ({
    ...state,
    loading: false,
    updateError: payload,
  }),

  [syncTypes.UPDATE_TOUR_VIEWED_FULFILLED_0]: (state, { payload }) => {
    const updateLens = lensPath([
      "settings",
      "viewedTours",
      payload.tourViewed,
    ]);

    return set(updateLens, true, state);
  },

  [syncTypes.REQUEST_SAVE_CARD_0]: (state) => ({
    ...state,
    cardError: "",
    loading: true,
  }),

  [syncTypes.REQUEST_SAVE_CARD_FULFILLED_0]: (state, { payload }) => ({
    ...state,
    cardInfo: payload.cardInfo,
    useSavedCard: true,
    cardError: "",
    loading: false,
  }),

  [syncTypes.REQUEST_SAVE_CARD_REJECTED_0]: (state, { payload }) => ({
    ...state,
    cardError: head(payload.errors),
    loading: false,
  }),

  [types.SET_USE_SAVED_CARD]: (state, { payload }) => ({
    ...state,
    useSavedCard: payload,
  }),

  [types.SANITIZE_TOKEN]: (state) => {
    const ort = state.ort;
    const authLens = lensProp("authToken");

    return compose(set(authLens, ort), omit(["ort"]))(state);
  },

  [syncTypes.VERIFY_USER_REQUESTED_0]: (state) => ({
    ...state,
    loading: true,
  }),

  [syncTypes.VERIFY_USER_FULFILLED_0]: (state) => ({
    ...state,
    loading: false,
    verified: true,
    userDetails: {
      ...state.userDetails,
      isVerified: true,
    },
  }),

  [syncTypes.VERIFY_USER_REJECTED_0]: (state, { payload }) => ({
    ...state,
    verificationError: payload.errors[0],
    loading: false,
  }),

  [types.UPDATE_AUTH]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),

  [syncTypes.FETCH_USER_CARDS_0]: (state) => ({
    ...state,
    cardError: "",
    loading: true,
  }),

  [syncTypes.FETCH_USER_CARDS_FULFILLED_0]: (state, { payload }) => ({
    ...state,
    cardInfo: head(payload.cards),
    cardError: "",
    loading: false,
  }),

  [syncTypes.FETCH_USER_CARDS_REJECTED_0]: (state, { payload }) => ({
    ...state,
    cardError: head(payload.errors),
    loading: false,
  }),

  ["fetched-user-settings"]: (state, { payload }) => ({
    ...state,
    settings: payload,
    hasFetchedUserSettings: true,
  }),

  ["error-fetching-user-settings"]: (state) => ({
    ...state,
    settings: {},
    hasFetchedUserSettings: true,
  }),

  ["set-user-settings"]: (state, { payload }) => {
    return {
      ...state,
      settings: { ...state.settings, ...payload },
    };
  },

  ["change-password"]: (state) => ({
    ...state,
    loading: true,
  }),

  ["password-changed"]: (state) => ({
    ...state,
    loading: false,
  }),

  ["error-changing-password"]: (state, { payload }) => ({
    ...state,
    loading: false,
    changePasswordError: payload?.reason?.message,
  }),

  [types.CLEAR_CARD_INFO]: (state) => ({
    ...state,
    cardError: "",
    cardInfo: {},
  }),

  [types.ADD_DOCUMENTS_TO_CART]: (state, { payload }) => ({
    ...state,
    checkoutWindowData: {
      cartID: payload.cartID,
      department: payload.department,
      itemIDs: payload.itemIDs,
      checkoutType: CHECKOUT_TYPES.NORMAL,
      entityType: ENTITY_TYPES.DOCUMENT,
    },
  }),

  [types.ADD_ATTACHMENTS_TO_CART]: (state, { payload }) => ({
    ...state,
    checkoutWindowData: {
      cartID: payload.cartID,
      department: payload.department,
      itemIDs: payload.itemIDs,
      checkoutType: CHECKOUT_TYPES.NORMAL,
      entityType: ENTITY_TYPES.ATTACHMENT,
    },
  }),

  [types.EXPRESS_CHECKOUT]: (state, { payload }) => ({
    ...state,
    checkoutWindowData: {
      cartID: payload.cartID,
      department: payload.department,
      itemIDs: payload.itemIDs,
      checkoutType: CHECKOUT_TYPES.EXPRESS,
      entityType: ENTITY_TYPES.DOCUMENT,
    },
  }),

  [types.EXPORT_RESULTS]: (state, { payload }) => ({
    ...state,
    checkoutWindowData: {
      cartID: payload.cartID,
      department: payload.department,
      itemIDs: payload.itemIDs,
      checkoutType: CHECKOUT_TYPES.EXPRESS,
      entityType: ENTITY_TYPES.EXPORT,
    },
  }),

  [syncTypes.REMOVE_SAVED_CARD_0]: (state) => {
    const lens = lensPath(["removeCard", "loading"]);

    return set(lens, true, state);
  },

  [syncTypes.REMOVE_SAVED_CARD_FULFILLED_0]: (state) => {
    const lens = lensPath(["removeCard", "success"]);

    return set(lens, true, state);
  },

  [syncTypes.REMOVE_SAVED_CARD_REJECTED_0]: (state, { payload }) => {
    const lens = lensPath(["removeCard"]);

    const removeCard = {
      ...initialState.removeCard,
      error: payload.error,
    };

    return set(lens, removeCard, state);
  },

  [types.RESET_REMOVE_CARD]: (state) => {
    return set(lensProp("removeCard"), initialState.removeCard, state);
  },
});
