import { css } from "@emotion/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router";
import { Link } from "../../../components";
import { withWorkspaceId } from "../../../hoc";
import { actions, selectors } from "../../../store";
import AdvancedSearch from "../../../units/search.advanced";
import BasicSearch from "../../../units/search.basic";
import SearchContentPanel from "./content-panel";
import SearchMasthead from "./search-masthead";
import SearchHistory from "./SearchHistory";

const styles = {
  container: css`
    display: grid;
    grid-template-areas:
      "sidebar masthead"
      "sidebar search-type-links"
      "sidebar search"
      "sidebar history";
    grid-template-columns: auto 1fr;
    grid-template-rows: min-content min-content min-content min-content;
  `,
  sidebar: css`
    grid-area: sidebar;
  `,
  searchTypeLinks: css`
    grid-area: search-type-links;
    padding: 16px 0 18px;
    text-align: center;

    .search__type {
      display: inline-block;
      padding: 0 10px;
    }

    .search__type + .search__type {
      border-left: 1px solid rgba(4, 29, 68, 0.2);
    }

    .search__select-type {
      color: var(--color-primary-500);
      font-size: 14px;
      line-height: 14px;
    }

    .search__select-type a {
      color: inherit;
      font-size: 17px;
      text-decoration: none;
    }

    .search__select-type--active {
      color: #041d44;
      font-weight: 600;
    }

    .search__select-type--disabled a {
      color: grey;
      cursor: not-allowed;
      pointer-events: none;
    }
  `,
};

export const Search = ({ workspaceID }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { workspaces, configuration } = selectors;
  const shouldLimitSearch = useSelector(configuration.isLimitedSearchEnabled);
  const deptCode = useSelector(workspaces.getSelectedDepartment);
  const shouldFetchDeptDates = useSelector(
    workspaces.determineDeptHasEmptyDate
  );

  const removeFetchedDocuments = (workspaceID) =>
    dispatch(actions.documents.removeFetchedDocuments({ workspaceID }));

  const fetchDepartmentDateRanges = (nextDeptCode) =>
    dispatch(
      actions.search.fetchDepartmentDateRanges({
        shouldFetch: shouldFetchDeptDates,
        department: nextDeptCode || deptCode,
      })
    );

  useEffect(() => {
    fetchDepartmentDateRanges();
    removeFetchedDocuments(workspaceID);
  }, []);

  if (shouldLimitSearch && location.pathname === "/") {
    return (
      <Redirect to={{ pathname: "/search/advanced", state: { workspaceID } }} />
    );
  }

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <div css={styles.container}>
        <SearchContentPanel />
        <SearchMasthead />
        <ul css={styles.searchTypeLinks}>
          <li className="search__type">
            <Link
              disabled={shouldLimitSearch}
              exact
              to="/"
              className="search__select-type"
              title={
                shouldLimitSearch ? "Quick Search (disabled)" : "Quick Search"
              }
            >
              Quick Search
            </Link>
          </li>
          <li className="search__type" data-tourid="advancedSearchLink">
            <Link
              exact
              title="Advanced Search"
              to="/search/advanced"
              className="search__select-type"
            >
              Advanced Search
            </Link>
          </li>
        </ul>

        <Switch>
          <Route exact path="/" render={() => <BasicSearch />} />
          <Route
            path="/search/advanced"
            render={() => (
              <AdvancedSearch
                fetchDepartmentDateRanges={fetchDepartmentDateRanges}
              />
            )}
          />
        </Switch>
        {!shouldLimitSearch && <SearchHistory />}
      </div>
    </>
  );
};

export default withWorkspaceId(Search);
