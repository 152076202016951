import { Component } from "react";
import { ErrorPage } from "../../components";

/**
 * react 16.8: hooks don't yet cover the componentDidCatch use case
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage showLink={this.props.showLink || false} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
