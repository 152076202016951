import styled from "@emotion/styled";

export const CardsContainer = styled.div((props) => {
  const { hideNameOption } = props;

  let gridTemplate = `
"picture header header header" auto
"picture card1 or card2" auto
/ 40% 25% auto 25%`;

  if (hideNameOption === true) {
    gridTemplate = `
"picture header" auto
"picture card2" auto
/ 40% auto
`;
  }

  return {
    display: "grid",
    margin: "30px 0",
    padding: "15px 0",
    gridTemplate,
  };
});
