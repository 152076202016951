import { css } from "@emotion/react";
import not from "ramda/src/not";
import React from "react";
import { isAbsent, isPresent } from "../../../utils";
import ExpandGroupButton from "./expandButton";

const styles = {
  container: (isSubGroup) => css`
    margin-bottom: 16px;
    padding-top: 1rem;
    padding-left: ${isSubGroup ? "20px" : "0"};

    & h4 {
      font-weight: 600;
      background-color: rgba(4, 29, 68, 0.04);
      text-transform: capitalize;
    }

    & h4,
    & li,
    & p {
      font-family: "Source Sans Pro";
      font-size: 15px;
      padding: 8px;
    }

    button {
      margin: auto;
    }
  `,
  isOpen: css`
    max-height: 400px;
    overflow-y: scroll;
  `,
};

export const GroupWrap = ({
  data = {},
  truncatedCount = 10,
  isOpen = false,
  loading = false,
  toggleOpen = () => {},
  isSubGroup = false,
  children,
}) => {
  const { value = [], key, meta = {} } = data;
  const label = data.label || key;

  const makeExpandingLabel = () => {
    const groupType = data.label || key;
    if (loading) return `Loading ${groupType}`;
    if (isOpen) return `Hide ${groupType}`;

    return `Show All ${meta.total} ${groupType}`;
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const showAll = isOpen || isSubGroup || not(Array.isArray(value));
      const data = showAll ? value : value.slice(0, truncatedCount);

      return React.cloneElement(child, { data });
    }

    return child;
  });

  return (
    <div css={styles.container(isSubGroup)}>
      {typeof label === "string" && <h4>{label}</h4>}
      {isAbsent(value) && <p>{`No ${label.toLowerCase()} found.`}</p>}

      <ExpandGroupButton
        label={makeExpandingLabel()}
        hide={meta.total <= 10 || !label || isSubGroup}
        loading={loading}
        toggle={toggleOpen}
      />

      {isPresent(value) && <div css={styles.isOpen}>{childrenWithProps}</div>}
    </div>
  );
};
