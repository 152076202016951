import styled from "@emotion/styled";

export const RemoveModalTitle = styled.div`
  color: #041d44;
  font-family: "Source Sans Pro";
  font-size: 24px;
  padding: 25px 0;
  text-align: center;
  border-bottom: 2px solid #d8dde6;
`;
