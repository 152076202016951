import { Select } from "..";
import { generateTextComponents } from "./generate-text-components";
import { styles } from "./styles";

const Content = ({
  body = "",
  onChange,
  onStateChange,
  title = "Terms and Conditions",
}) => {
  const selectProps = {
    className: "date-select",
    css: styles.select,
    options: [
      { label: "English", value: "en" },
      { label: "Spanish", value: "es" },
    ],
    onChange,
    onStateChange,
    value: "en",
  };

  return (
    <div css={styles.container}>
      <div css={styles.title}>
        <h1>{title}</h1>
        <Select {...selectProps} />
      </div>
      <div css={styles.body}>{generateTextComponents(body)}</div>
    </div>
  );
};

export default Content;
