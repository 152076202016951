import { useAPI } from "../../hooks/use-api";

export const useAddMember = () =>
  useAPI({
    send: "add-member-to-subscription",
    listenFor: {
      success: "member-added-to-subscription",
      failure: "error-adding-member-to-subscription",
    },
  });
