import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import last from "ramda/src/last";
import prop from "ramda/src/prop";
import { Component, Fragment } from "react";
import { Scheduler, fromEvent } from "rxjs";
import { publish, refCount, subscribeOn } from "rxjs/operators";
import { OverflowMenu } from "./workspaces.overflow";
import Tab from "./workspaces.tab";

const TAB_MIN_WIDTH = 135;

const stealActiveWorkspace = (normal, overflow, isOverflowActive) => {
  const normalLast = last(normal);
  const normalWithoutLast = normal.slice(0, normal.length - 1);
  const overflowLast = last(overflow);

  return {
    workspaces: isOverflowActive
      ? [...normalWithoutLast, overflowLast]
      : normal,
    overflowWorkspaces: isOverflowActive ? [normalLast, ...overflow] : overflow,
  };
};

const container = css`
  align-items: stretch;
  background-color: var(--color-primary-900);
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

class WorkspaceTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverflown: false,
      overflowWorkspaces: [],
      workspaces: props.workspaces,
    };

    this.childRefs = {};

    this.setWorkspacesRef = (el) => {
      this.workspacesRef = el;
    };

    this.setChildRef = (index) => (el) => {
      this.childRefs[index] = el;
    };

    this.rebalanceChildren = (currentWorkspaces) => {
      const ref = this.workspacesRef;

      if (!isNil(ref)) {
        const availableSpace = ref.offsetWidth;
        const workspaces = currentWorkspaces;
        const numWorkspaces = workspaces.length;
        const normalWorkspaceCap = Math.floor(availableSpace / TAB_MIN_WIDTH);
        const normalWorkspaceCapIndex = normalWorkspaceCap - 1;
        const normalWorkspaces = workspaces.slice(0, normalWorkspaceCapIndex);

        const overflowWorkspaces = workspaces.slice(
          normalWorkspaceCapIndex,
          numWorkspaces
        );

        const overflowActive = overflowWorkspaces.find(prop("isActive"));

        const normalWorkspacesAdjusted = stealActiveWorkspace(
          normalWorkspaces,
          overflowWorkspaces,
          overflowActive
        );

        this.setState(normalWorkspacesAdjusted);
      }
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.workspaces.length !== nextProps.workspaces.length) {
      this.rebalanceChildren(nextProps.workspaces);
    }
  }

  componentDidMount() {
    this.rebalanceChildren(this.props.workspaces);

    const resize = fromEvent(window, "resize").pipe(publish(), refCount());

    this.resizeHandler = resize
      .pipe(subscribeOn(Scheduler.animationFrame))
      .subscribe(() => this.rebalanceChildren(this.props.workspaces));
  }

  render() {
    const { close } = this.props;
    const { overflowWorkspaces, workspaces } = this.state;

    return (
      <Fragment>
        <ul css={container} ref={this.setWorkspacesRef}>
          {workspaces.map((workspace, i) => (
            <Tab
              key={i}
              {...workspace}
              close={close}
              getTabRef={this.setChildRef(i)}
            />
          ))}
        </ul>
        <OverflowMenu overflowWorkspaces={overflowWorkspaces} />
      </Fragment>
    );
  }
}

export default WorkspaceTabs;
