import { css } from "@emotion/react";
import { nanoid } from "nanoid";
import append from "ramda/src/append";
import dissoc from "ramda/src/dissoc";
import equals from "ramda/src/equals";
import lensPath from "ramda/src/lensPath";
import map from "ramda/src/map";
import not from "ramda/src/not";
import over from "ramda/src/over";
import pipe from "ramda/src/pipe";
import propEq from "ramda/src/propEq";
import reject from "ramda/src/reject";
import remove from "ramda/src/remove";
import set from "ramda/src/set";
import values from "ramda/src/values";
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import { createPortal } from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { INTERCEPTS, OPEN, INTERCEPT_ENABLED, ENABLED } from "./constants";

const toActual = values;

const toEditing = map((v) => ({
  ...v,
  force: v.force.reduce((m, k) => ({ ...m, [nanoid()]: k }), {}),
}));

const buttonStyle = css`
  font-weight: bold;
  border-radius: 2px;
  padding: 5px 10px;
`;

const style = {
  wrap: css`
    padding: 20px;
    background-color: #1e272e;
    min-height: min-content !important;
    color: #eee;
    border-bottom: 4px solid #f53b57;

    & form > * + * {
      margin-top: 10px;
    }
  `,
  list: css`
    & > * + * {
      margin-top: 5px;
    }

    & li > * + * {
      margin-left: 10px;
    }
  `,
  madLibItem: css`
    display: flex;
    align-items: center;
  `,
  input: css`
    font-family: "Source Sans Pro", sans-serif;
    border: 0;
    border-bottom: 2px solid #d2dae2;
    background: transparent;
    color: #eee;
    font-size: 16px;
    margin: 0 5px;
    min-width: 300px;
  `,
  newButton: css`
    ${buttonStyle}
  `,
  saveButton: css`
    ${buttonStyle}
  `,
  delButton: css`
    ${buttonStyle}
  `,
  enableButton: (isEnabled) => css`
    ${buttonStyle}
    background-color: ${isEnabled ? "#0be881" : "#ef5777"};
    text-shadow: ${isEnabled ? "transparent" : "#000"} 1px 1px;
    color: ${isEnabled ? "#000" : "#fff"};
  `,
  notices: css`
    background-color: #f53b57;
    color: var(--color-neutral-lightest);
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 99999;
  `,
};

const NOTICE = {
  INT_ENABLED: {
    key: "intercepts-enabled",
    copy: "You have intercepts configured and enabled. Open Bunni to see details.",
  },
};

const Bunni = () => {
  const [enabled, setEnabled] = useState(false);
  const [interceptEnabled, setInterceptEnabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [intercepts, setIntercepts] = useState([]);
  const [editingIntercepts, setEditingIntercepts] = useState([]);
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    try {
      const enabled = JSON.parse(localStorage.getItem(ENABLED));
      const saved = JSON.parse(localStorage.getItem(INTERCEPTS));
      const open = JSON.parse(localStorage.getItem(OPEN));
      const intEnabled = JSON.parse(localStorage.getItem(INTERCEPT_ENABLED));

      if (Array.isArray(saved)) {
        setIntercepts(saved);
        setEditingIntercepts(toEditing(saved));
      }

      if (open === true || open === false) {
        setIsOpen(open);
      }

      if (intEnabled === true || intEnabled === false) {
        setInterceptEnabled(intEnabled);
      }

      if (enabled === true) {
        setEnabled(enabled);
      }
      // eslint-disable-next-line no-empty -- foolishly ignore any error
    } catch (_) {}
  }, []);

  useEffect(() => {
    localStorage.setItem(INTERCEPTS, JSON.stringify(intercepts));
  }, [intercepts]);

  useEffect(() => {
    localStorage.setItem(OPEN, JSON.stringify(isOpen));
  }, [isOpen]);

  useEffect(() => {
    localStorage.setItem(INTERCEPT_ENABLED, JSON.stringify(interceptEnabled));
  }, [interceptEnabled]);

  useEffect(() => {
    if (interceptEnabled && intercepts.length > 0) {
      setNotices((ns) => {
        const notice = ns.find((n) => n === NOTICE.INT_ENABLED);

        if (!notice) {
          return append(NOTICE.INT_ENABLED, ns);
        }

        return ns;
      });
    } else {
      setNotices(reject(propEq("key", NOTICE.INT_ENABLED.key)));
    }
  }, [interceptEnabled, intercepts]);

  useHotkeys(
    "ctrl+option+,",
    () => {
      if (enabled) {
        setIsOpen(not);
      }
    },
    {},
    [enabled]
  );

  const add = useCallback((e) => {
    e.preventDefault();

    const newIntercept = { key: nanoid(), when: "", force: { [nanoid()]: "" } };

    setEditingIntercepts(append(newIntercept));
  });

  const toggleIntEnabled = useCallback((e) => {
    e.preventDefault();
    setInterceptEnabled(not);
  });

  if (enabled !== true) {
    return null;
  }

  return (
    <Fragment>
      {isOpen && (
        <div css={style.wrap}>
          <form>
            <h1>Configure intercepts</h1>

            <button
              onClick={toggleIntEnabled}
              css={style.enableButton(interceptEnabled)}
            >
              {interceptEnabled ? "enabled" : "disabled"}
            </button>

            <ul css={style.list}>
              {editingIntercepts.map(
                renderIntercept(setEditingIntercepts, intercepts, setIntercepts)
              )}
            </ul>

            <button onClick={add} css={style.newButton}>
              new
            </button>
          </form>
        </div>
      )}
      <BunniNotice notices={notices} />
    </Fragment>
  );
};

export default Bunni;

function renderIntercept(setEditingIntercepts, intercepts, setIntercepts) {
  const findMatchingIntercept = (k) => {
    const index = intercepts.findIndex((i) => i.key === k);

    return index === -1 ? null : { index, ...intercepts[index] };
  };

  const del = (index) => (e) => {
    e.preventDefault();
    setIntercepts(remove(index, 1));
    setEditingIntercepts(remove(index, 1));
  };

  return Object.assign(
    ({ key, when, force }, index) => {
      const matchingIntercept = findMatchingIntercept(key);

      const saved =
        matchingIntercept !== null &&
        matchingIntercept.when === when &&
        equals(matchingIntercept.force, toActual(force));

      const unsaved = !saved;

      const update = (type, forceKey) => (e) => {
        e.preventDefault();

        const value = e.currentTarget.value;

        const lens = forceKey
          ? lensPath([index, type, forceKey])
          : lensPath([index, type]);

        setEditingIntercepts(set(lens, value));
      };

      const save = (e) => {
        e.preventDefault();

        const actualForce = toActual(force);

        if (matchingIntercept !== null) {
          const { index: mIndex } = matchingIntercept;
          const whenLens = lensPath([mIndex, "when"]);
          const forceLens = lensPath([mIndex, "force"]);

          setIntercepts(pipe(set(forceLens, actualForce), set(whenLens, when)));
        } else {
          setIntercepts(append({ key, when, force: actualForce }));
        }
      };

      const addNewForce = (e) => {
        e.preventDefault();

        const lens = lensPath([index, "force", nanoid()]);

        setEditingIntercepts(set(lens, ""));
      };

      const removeForce = (key) => (e) => {
        e.preventDefault();

        const lens = lensPath([index, "force"]);

        setEditingIntercepts(over(lens, dissoc(key)));
      };

      return (
        <li key={key} css={style.madLibItem}>
          <button onClick={save} css={style.saveButton}>{`save${
            unsaved ? "*" : ""
          }`}</button>

          <button onClick={del(index)} css={style.delButton}>
            del
          </button>

          <span>when sending</span>
          <input value={when} onChange={update("when")} css={style.input} />
          <span>, force left identified by one of</span>

          <ul>
            {Object.keys(force).map((key) => (
              <li key={key}>
                <button onClick={removeForce(key)}>-</button>
                <input
                  value={force[key]}
                  onChange={update("force", key)}
                  css={style.input}
                />
              </li>
            ))}
            <li>
              <button onClick={addNewForce}>+</button>
            </li>
          </ul>
        </li>
      );
    },
    { displayName: "EditForces" }
  );
}

function BunniNotice({ notices }) {
  const ref = useRef(null);
  const doc = useRef(null);

  useLayoutEffect(() => {
    doc.current = document;
  }, [doc.current]);

  useEffect(() => {
    if (doc.current === null) {
      return;
    }

    const root = doc.current.getElementById("bunni");

    if (ref.current === null) {
      ref.current = doc.current.createElement("div");
    }

    root.appendChild(ref.current);

    return () => {
      root.removeChild(ref.current);
    };
  }, [doc.current]);

  if (ref.current === null || notices.length === 0) {
    return null;
  }

  return createPortal(
    <ul css={style.notices}>
      {notices.map((notice) => (
        <li key={notice.key}>{notice.copy}</li>
      ))}
    </ul>,
    ref.current
  );
}
