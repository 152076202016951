import { css } from "@emotion/react";
import { Input } from "../common/input";

const style = (hasError) => css`
  padding-top: 10px;
  padding-bottom: ${hasError ? 0 : "37px"};
`;

const OrganizationName = ({ selected, name, onChange, showError }) => {
  if (selected !== "multiUser") {
    return null;
  }

  const label = "Organization Name";
  const error = showError
    ? "Must enter a organization name to create a multi user plan."
    : "";

  return (
    <Input
      type="text"
      name="organizationName"
      style={style(showError)}
      value={name}
      onChange={onChange}
      label={label}
      error={error}
    />
  );
};

export default OrganizationName;
