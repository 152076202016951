import { css } from "@emotion/react";
import { PropTypes } from "prop-types";
import propOr from "ramda/src/propOr";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container } from "./_container";
import { Info } from "./_info";
import { SubTitle } from "./_sub-title";
import { Title } from "./_title";
import { Icon } from "./icon";

const defaultMessage = "";
const errorMessages = {
  404: "The requested URL was not found.",
  500: "There was an internal error.",
  600: defaultMessage,
};

const linkStyle = css`
  color: rgba(0, 109, 213, 1);
`;

export const ErrorPage = ({ code = 600, showLink = true }) => {
  const message = propOr(defaultMessage, code, errorMessages);
  const codeMsg = code === 600 ? "n " : ` ${code}`;

  const linkText = showLink && ` or contact `;
  const link = showLink && (
    <Link to="/" css={linkStyle}>
      customer support
    </Link>
  );

  return (
    <Container>
      <Helmet>
        <title>Error</title>
      </Helmet>
      {showLink && <Icon />}
      <Title>{`Sorry about this!`}</Title>
      <SubTitle>{`There's been a${codeMsg} error.`}</SubTitle>
      <Info>
        {message} Please reload the page and try again later{linkText}
        {link}.
      </Info>
    </Container>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.number,
};

export default ErrorPage;
