import styled from "@emotion/styled";

export const Submit = styled.button`
  background-color: #ff6900;
  border-radius: 0 4px 4px 0;
  border-style: none;
  cursor: pointer;
  margin: 0;
  grid-area: submit;

  &:disabled {
    opacity: 0.5;
  }

  & svg {
    color: var(--color-neutral-lightest);
    height: 26px;
    margin-top: 4px;
    width: 26px;
  }
`;
