import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";

const style = css`
  margin-right: 10px;
`;

export const Icon = ({ icon, alt }) => {
  const image = useSelector(selectors.assets.getImg(`icon_${icon}.svg`));

  return <img css={style} src={image} alt={alt} />;
};
