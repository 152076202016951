import React, { useState } from "react";
import Modal from "react-modal";
import { isNotNil } from "../utils";

export const reactModalStyles = {
  overlay: {
    zIndex: 1000,
    display: "grid",
    placeItems: "start center",
    backgroundColor: "rgba(0, 0, 0, .5)",
    fontFamily: "Source Sans Pro",
  },
  content: {
    width: "min-content",
    height: "min-content",
    padding: "0",
    margin: "0",
    inset: "unset",
    border: "none",
    background: "unset",
    overflow: "visible",
    marginTop: "10px",
  },
};

const withModal = (Wrapped) => {
  const EnhancedComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [Child, setChild] = useState(null);
    const [modalProps, setModalProps] = useState({});

    const close = () => {
      setIsOpen(false);
      setChild(null);
      setModalProps({});
    };

    const open = (ModalComponent, incomingProps) => {
      setIsOpen(true);
      setChild(ModalComponent);
      setModalProps({ ...incomingProps, close });

      return close;
    };

    const modal = { isOpen, open, close };

    return (
      <>
        <Wrapped {...props} modal={modal} />
        <Modal
          isOpen={isOpen}
          onRequestClose={close}
          shouldCloseOnOverlayClick={true}
          contentLabel={props.label ? props.label : "Modal"}
          style={reactModalStyles}
        >
          {isNotNil(Child) &&
            React.cloneElement(Child, { ...Child.props, ...modalProps })}
        </Modal>
      </>
    );
  };

  EnhancedComponent.displayName = `withModal(${
    Wrapped.displayName || Wrapped.name
  })`;

  return EnhancedComponent;
};

export default withModal;
