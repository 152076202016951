import classnames from "classnames";
import always from "ramda/src/always";
import cond from "ramda/src/cond";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isAbsent } from "../../utils";

const makeClassNames = (onlineStatus) =>
  classnames("online-status", {
    "online-status--is-connected": onlineStatus.isConnected,
    "online-status--is-disconnected": onlineStatus.isDisconnected,
    "online-status--is-connecting": onlineStatus.isConnecting,
  });

const makeMessage = cond([
  [prop("isConnected"), always(<span aria-label="connected">Connected!</span>)],
  [
    prop("isConnecting"),
    always(
      <span
        aria-label="attempting to reconnect"
        className="online-status__ellipsis"
      >
        Attempting to reconnect
      </span>
    ),
  ],
  [
    prop("isDisconnected"),
    always(<span aria-label="you are offline">You are offline!</span>),
  ],
]);

export const OnlineStatus = (props) => {
  const { onlineStatus } = selectors;
  const isConnected = useSelector(onlineStatus.isConnected);
  const isConnecting = useSelector(onlineStatus.isConnecting);
  const isDisconnected = useSelector(onlineStatus.isDisconnected);

  const message = makeMessage({ isConnecting, isConnected, isDisconnected });

  if (isAbsent(message)) return null;

  return (
    <div role="alert" className={makeClassNames(props)}>
      {message}
    </div>
  );
};

export default OnlineStatus;
