import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import equals from "ramda/src/equals";
import isEmpty from "ramda/src/isEmpty";
import pathOr from "ramda/src/pathOr";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ErrorBar, Form } from "../../../components";
import { actions, selectors } from "../../../store";
import { parseUrl } from "../../../utils";
import formConfig from "./formConfig";

const enhance = Form.withFormState(formConfig, "state");

const MATCH_ERROR = "Passwords must match.";

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { matchError: "" };

    this.updateMatchError = this.updateMatchError.bind(this);
  }

  updateMatchError(error) {
    this.setState({ matchError: error });
  }

  componentWillReceiveProps({ passwordReset, firstWorkspace }) {
    const { id, path } = firstWorkspace;
    const { pathname, search } = parseUrl(path);

    if (passwordReset) {
      this.props.history.push({
        pathname,
        search,
        state: {
          workspaceId: id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  render() {
    const {
      updateFormValue,
      updateFormError,
      state,
      updatePassword,
      isLoading,
      updateError,
      clearError,
      match,
    } = this.props;

    const verifyCode = pathOr("", ["params", "token"], match);

    const formActions = {
      onChange: updateFormValue,
    };

    const form = Form.buildForm(formConfig, state, formActions);
    const newPassword = state.newPassword.value;
    const confirmPassword = state.confirmPassword.value;
    const passwordMatch = equals(newPassword, confirmPassword);

    const handleSubmit = (event) => {
      event.preventDefault();

      if (passwordMatch) {
        const isValidForm = Form.checkIfFormValid(
          formConfig,
          state,
          updateFormError
        );

        if (isValidForm) {
          clearError();
          this.updateMatchError("");
          updatePassword({ newPassword, verifyCode });
        }
      } else {
        this.updateMatchError(MATCH_ERROR);
      }
    };

    const invalidInput = isEmpty(newPassword) || isEmpty(confirmPassword);

    const error = updateError || this.state.matchError;

    return (
      <form className="reset-password" onSubmit={handleSubmit}>
        <div className="reset-password__header">
          <h1 className="reset-password__header-text">Reset Password</h1>
        </div>
        <ErrorBar error={error} />
        <div className="reset-password__body-wrap">
          <div className="reset-password__input-wrap">{form.newPassword}</div>
          <div className="reset-password__input-wrap">
            {form.confirmPassword}
          </div>
          <button
            disabled={invalidInput}
            className="reset-password__submit-button"
            type="submit"
          >
            {isLoading ? "Loading..." : "Change Password"}
          </button>
        </div>
      </form>
    );
  }
}

ResetPassword.displayName = "ForgotPassword";
ResetPassword.propTypes = {
  updateFormValue: PropTypes.func.isRequired,
  updateFormError: PropTypes.func.isRequired,
  state: PropTypes.shape({
    email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  updatePassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const mapStateToProps = (state) => ({
  isLoading: selectors.password.getLoading(state),
  updateError: selectors.password.getUpdateError(state),
  firstWorkspace: selectors.workspaces.getFirstWorkspace(state),
  passwordReset: selectors.password.getPasswordReset(state),
});

export const mapDispatchToProps = (dispatch) => ({
  updatePassword: ({ verifyCode, newPassword }) =>
    dispatch(actions.password.updatePassword({ verifyCode, newPassword })),
  clearState: () => dispatch(actions.password.clearState()),
  clearError: () => dispatch(actions.password.clearError()),
});

export const EnhancedResetPassword = compose(
  withRouter,
  enhance,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);

export default EnhancedResetPassword;
