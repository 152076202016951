import styled from "@emotion/styled";

export const Amount = styled.p`
  color: var(--color-neutral-lightest);
  font-family: inherit;
  font-size: 15px;
  align-items: center;
  border: 1px solid var(--color-neutral-lightest);
  border-radius: 3px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 28px;
`;
