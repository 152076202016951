import { types as syncTypes } from "@kofile/ko-search-action-types";
import head from "ramda/src/head";
import pathOr from "ramda/src/pathOr";
import { checkout as initialState } from "../initialState";
import types from "../types/checkout";
import { types as nCourtTypes } from "./ncourt";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.REQUEST_CHECKOUT_0]: requestCheckout,
  purchase: purchase,
  [syncTypes.REQUEST_EXPRESS_CHECKOUT_0]: requestExpressCheckout,
  [syncTypes.REQUEST_CHECKOUT_REJECTED_0]: requestCheckoutRejected,
  "error-purchasing": errorPurchasing,
  [types.CHECKOUT_ERROR]: checkoutError,
  [syncTypes.REQUEST_CHECKOUT_FULFILLED_1]: requestCheckoutFulfilled,
  "purchase-completed": purchaseCompleted,
  [types.CLEAR_ERROR]: clearError,
  [types.CLEAR]: clear,
  [types.MAKE_CHECKOUT_STALE]: makeCheckoutStale,
  [types.MAKE_CHECKOUT_FRESH]: makeCheckoutFresh,
  [nCourtTypes.checkoutReady]: earlyCompleteFreeNcourtCheckout,
  [nCourtTypes.expressCheckoutReady]: earlyCompleteFreeNcourtCheckout,
  [nCourtTypes.orderComplete]: ncourtCheckoutComplete,
});

function requestCheckout(state) {
  return {
    ...state,
    inProgress: true,
    result: null,
    success: false,
  };
}

function purchase(state) {
  return {
    ...state,
    inProgress: true,
    result: null,
    success: false,
  };
}

function requestExpressCheckout(state) {
  return {
    ...state,
    inProgress: true,
    result: null,
    success: false,
  };
}

function requestCheckoutRejected(state, { payload }) {
  return {
    ...state,
    inProgress: false,
    result: null,
    error: head(payload.errors) || "",
    success: false,
  };
}

function errorPurchasing(state, { payload }) {
  return {
    ...state,
    inProgress: false,
    result: null,
    error: pathOr("Error checking out", ["reason", "message"], payload),
    success: false,
  };
}

function checkoutError(state, { payload }) {
  return {
    ...state,
    inProgress: false,
    error: payload.error,
    success: false,
  };
}

function requestCheckoutFulfilled(state, { payload }) {
  return {
    ...state,
    inProgress: false,
    error: "",
    order: payload.order,
    success: true,
  };
}

function purchaseCompleted(state, { payload }) {
  return {
    ...state,
    inProgress: false,
    error: "",
    order: payload,
    success: true,
  };
}

function clearError(state) {
  return {
    ...state,
    error: "",
  };
}

function clear(state) {
  return {
    ...state,
    inProgress: false,
    error: "",
    order: {},
    success: false,
  };
}

function makeCheckoutStale(state) {
  return {
    ...state,
    isFresh: false,
  };
}

function makeCheckoutFresh(state) {
  return {
    ...state,
    isFresh: true,
  };
}

function earlyCompleteFreeNcourtCheckout(state, { payload }) {
  if (payload.url === null) {
    return {
      ...state,
      inProgress: false,
      error: "",
      order: {
        id: parseInt(payload.orderId, 10),
      },
      success: true,
    };
  } else {
    return {
      ...state,
      order: { ...state.order, id: parseInt(payload.orderId, 10) },
    };
  }
}

function ncourtCheckoutComplete(state, { payload }) {
  if (payload.status !== "paid") return state;

  return {
    ...state,
    inProgress: false,
    error: "",
    success: true,
    order: { id: parseInt(payload.id, 10) },
  };
}
