import { css } from "@emotion/react";

export const lightGray = "#d9dde3";

export default {
  secured: css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  hiddenImage: css`
    padding: 80px;
    font-size: 30px;
    line-height: 1.4;
    color: #68778f;
    background-color: #f0f0f0;
    margin: 40px;
  `,
  wrap: (havePanel) => css`
    flex: 1;
    height: 100%;
    position: relative;
    display: block;
    max-width: ${havePanel ? "50vw" : "100vw"};
  `,
  image: css`
    cursor: grab;
    user-select: none;

    &[data-is-dragging] {
      cursor: grabbing;
    }
  `,
  g: css`
    transform-origin: center;
  `,
  actionsContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-neutral-lightest);
    padding: 10px;

    > * {
      padding-right: 5px;
    }

    > * + * {
      padding-right: 0;
    }
  `,
  inputContainer: css`
    display: flex;
    min-width: 85px;
  `,
  inputText: css`
    align-self: center;
    color: #4d5e79;
  `,
  input: css`
    border: 1px solid ${lightGray};
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 14px;
    margin-right: 0.4rem;
    padding: 6px 0 6px 0.4rem;
    width: 50px;

    &:disabled {
      cursor: not-allowed;
    }
  `,
  noImage: css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  spinner: css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
  `,
};
