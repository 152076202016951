import { css } from "@emotion/react";

const style = {
  container: css`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
    grid-template-areas: "amount period" "label label";
    align-items: top;
    justify-items: start;
    grid-area: price;
    gap: 10px 5px;
  `,
  amount: css`
    color: #001d47;
    font-size: 22px;
    font-weight: 600;
    grid-area: amount;
  `,
  period: css`
    color: #6a778d;
    font-size: 16px;
    font-weight: 400;
    grid-area: period;
  `,
  label: css`
    font-size: 12px;
    grid-area: label;
    align-self: center;
  `,
};

export const Price = ({ amount, period, label }) => (
  <figure css={style.container}>
    <span css={style.amount}>{amount}</span>
    <span css={style.period}>/{period}</span>
    <span css={style.label}>{label}</span>
  </figure>
);
