import { css } from "@emotion/react";
import pipe from "ramda/src/pipe";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";

const lightBlue = "#e5f0fb";
const lightGreen = "#e6f9e9";

const determineColor = ({ inCart, downloading, downloaded }) => {
  if (downloading) return `background-color: ${lightBlue}`;
  if (inCart || downloaded) return `background-color: ${lightGreen}`;
  return "background-color: transparent";
};

const style = pipe(
  determineColor,
  (backgroundColor) => css`
    ${backgroundColor};
    grid-column: span icons;
    position: absolute;
    left: 0;
    top: 0;
    height: 35px;
    width: 100%;
  `
);

export const Background = ({ inCart, docId }) => {
  const { download: dl } = selectors;
  const downloading = useSelector(dl.isDownloadingForDocId(docId));
  const downloaded = useSelector(dl.hasDownloadedForDocId(docId));

  return <div css={style({ inCart, downloading, downloaded })} />;
};
