import { format, parse, parseISO } from "date-fns";
import allPass from "ramda/src/allPass";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import curryN from "ramda/src/curryN";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import keys from "ramda/src/keys";
import last from "ramda/src/last";
import length from "ramda/src/length";
import map from "ramda/src/map";
import mergeDeepRight from "ramda/src/mergeDeepRight";
import prop from "ramda/src/prop";
import values from "ramda/src/values";
import { createSelector } from "reselect";
import { selectors } from "../../store";
import { types as dateRangeTypes } from "../../units/search.basic/basic-search.utils";
import { makeDate } from "../../utils";
import { getActiveWorkspaceID } from "./workspaces/get-active-workspace-id";
import { getActiveWorkspaceSettings } from "./workspaces/get-active-workspace-settings";
import { getSelectedDepartment } from "./workspaces/get-selected-department";
import { getTabs } from "./workspaces/get-tabs";

export { getActiveWorkspaceID } from "./workspaces/get-active-workspace-id";
export { getActiveWorkspaceSettings } from "./workspaces/get-active-workspace-settings";
export { getSelectedDepartment } from "./workspaces/get-selected-department";
export { getTabs } from "./workspaces/get-tabs";
export { getWorkspaces } from "./workspaces/get-workspaces";

export const getActiveWorkspace = createSelector(
  [getActiveWorkspaceID, getTabs],
  prop
);

export const getWorkspaceList = createSelector(
  [getActiveWorkspaceID, getTabs],
  (id, workspaces) =>
    keys(workspaces).map((workspaceID) => ({
      ...workspaces[workspaceID],
      isActive: id === workspaceID,
    }))
);

export const getCartWorkspace = createSelector([getWorkspaceList], (list) =>
  list.find((w) => w.type === "cart")
);

export const getFirstWorkspace = createSelector(
  [getWorkspaceList],
  (list) => head(list) || { path: "" }
);

export const getSelectedDocumentsMap = createSelector(
  [getActiveWorkspaceSettings],
  prop("selectedDocuments")
);

export const getSelectedDocSummaryTab = createSelector(
  [getActiveWorkspaceSettings],
  prop("docSummaryTab")
);

export const getSelectedDocumentIDs = createSelector(
  [getSelectedDocumentsMap],
  compose(map(prop("id")), values)
);

export const getSelectedDocumentsRanks = createSelector(
  [getActiveWorkspaceSettings],
  prop("selectedDocumentsRanks")
);

export const getSelectedDocumentsKeys = createSelector(
  [getSelectedDocumentsMap],
  compose(map(Number.parseInt), keys)
);

export const getSelectedDocumentsCount = createSelector(
  [getSelectedDocumentsMap],
  compose(length, keys)
);

export const getSelectedDocumentsOrdered = createSelector(
  [getSelectedDocumentsMap, getSelectedDocumentsRanks],
  (documents, ranks) =>
    Object.entries(documents)
      .sort((a, b) => ranks[a[0]] - ranks[b[0]])
      .map(last)
);

export const getQuickSearchState = createSelector(
  [getActiveWorkspaceSettings],
  prop("quickSearchState")
);

export const getAdvancedSearchState = createSelector(
  [getActiveWorkspaceSettings],
  prop("advancedSearchState")
);

export const getSelectedDepartmentConfig = createSelector(
  [
    getSelectedDepartment,
    (state) => selectors.configuration.getDepartments(state),
  ],
  (currentDeptCode, deptConfigs) => {
    return deptConfigs.find((conf) => conf.code === currentDeptCode);
  }
);

const getDateAllRangesByType = (types = []) =>
  createSelector(
    [
      getSelectedDepartmentConfig,
      (state) => selectors.search.getDepartmentDateRanges(state),
    ],
    (deptConf, searchDateRanges) => {
      const { isCertified, denySearchAfterCertifiedDate, code } = deptConf;

      if (isNil(searchDateRanges[code])) return "";

      const dateRangeByDept = searchDateRanges[code];

      const defaultValues = {
        shouldConvert: isCertified && denySearchAfterCertifiedDate,
        certifiedDate: dateRangeByDept.certifiedDate,
      };

      const formattedRanges = types.reduce((previous, type) => {
        const dateRange = dateRangeByDept[type];
        if (isNil(dateRange)) return previous;

        const props = { ...defaultValues, dateRange };
        previous[type] = convertToCertifiedDate(props);
        return previous;
      }, {});

      return formattedRanges;
    }
  );

export const departmentHasEmptyDates = (state) => {
  const code = getSelectedDepartment(state);

  return !selectors.search.departmentHasDateRanges(state, code);
};

export const determineDeptHasEmptyDate = (state) => (code) =>
  !selectors.search.departmentHasDateRanges(state, code);

const { RECORDED, INSTRUMENT, APPLICATION } = dateRangeTypes;
export const quickSearchDateRange = createSelector(
  [
    getSelectedDepartmentConfig,
    getDateAllRangesByType([RECORDED, INSTRUMENT, APPLICATION]),
  ],
  (deptConf, dateRangeTypes) => {
    const { fullTextSearchDateRangeProp: searchType = RECORDED } = deptConf;

    return dateRangeTypes[searchType];
  }
);

export const getFullTextSearchDateRangeProp = createSelector(
  [getSelectedDepartmentConfig],
  prop("fullTextSearchDateRangeProp")
);

export const shouldUpdateDeptDateRange = createSelector(
  [(state) => selectors.search.getDepartmentDateRanges(state)],
  curryN(2, (dateRanges, code) => isNotPresent(dateRanges[code]))
);

export const getAdvancedSearchData = (state) => {
  const { configuration, search } = selectors;
  const code = getSelectedDepartment(state);
  const certDate = search.getCertifiedDate(state, code);
  const isCertified = configuration.getIsCertified(state, code);
  const docNumberFormat = configuration.getDocNumberFormat(state, code);
  const instNumberFormat = configuration.getInstNumberFormat(state, code);
  const docTypes = configuration.getAllDocTypesForDept(state, code);
  const savedFormState = getAdvancedSearchState(state);
  const hasEmptyDates = search.departmentHasEmptyDates(state, code);
  const datesLoading = search.getDatesLoading(state);
  const configFields = configuration.getDepartmentFields(state, code);
  const partyFields = configuration.getDeptParties(state);
  const legalFields = configuration.getDeptLegalFields(state, code);
  const departments = configuration.departmentOptions(state);
  const { fields } = configuration.getAdvancedSearch(state);
  const departmentConfig = configuration.getConfigForDepartment(state);

  const advSearchByDept = configuration.getDepartmentAdvancedSearch(
    state,
    code
  );
  const limitedSearch = configuration.getLimitedSearch(state);
  const {
    limitedLayout = [],
    layout: standardLayout = [],
    limitedGroups = [],
    groups: standardGroups = [],
  } = advSearchByDept;
  const layout = limitedSearch.enabled ? limitedLayout : standardLayout;
  const groups = limitedSearch.enabled ? limitedGroups : standardGroups;

  const required = configuration.getDeptRequiredFields(state, code);

  const denySearchAfterCertifiedDate =
    configuration.getDenySearchAfterCertifiedDate(state, code);

  let recordedDateRange = search.getRecordedDateRange(state, code);
  const instDateRange = search.getInstrumentDateRange(state, code);
  const applicationDateRange = search.getApplicationDateRange(state, code);
  const fullTextSearchDateRangeProp =
    departmentConfig.fullTextSearchDateRangeProp;

  const applyCertifiedDate = modifyDatesFromCertification({
    isCertified,
    denySearchAfterCertifiedDate,
    certifiedDate: certDate,
  });

  recordedDateRange = applyCertifiedDate(recordedDateRange);

  const fieldDefaultProps = {
    fullTextSearchDateRangeProp,
    docTypes: { department: code },
    applicationDateRange: { maxRange: applicationDateRange },
    instrumentDateRange: { maxRange: instDateRange },
    recordedDateRange: {
      certifiedDate: certDate,
      maxRange: recordedDateRange,
    },
    documentNumberRange: {
      documentNumberFormat: docNumberFormat,
      instrumentNumberFormat: instNumberFormat,
    },
  };

  return {
    departments,
    fields: mergeDeepRight(fields, fieldDefaultProps),
    selectedDepartment: {
      applicationDateRange,
      certDate,
      code,
      configFields,
      datesLoading,
      docNumberFormat,
      docTypes,
      fullTextSearchDateRangeProp,
      groups,
      hasEmptyDates,
      instDateRange,
      instNumberFormat,
      isCertified,
      layout,
      legalFields,
      partyFields,
      recordedDateRange,
      required,
      savedFormState,
    },
  };
};

export const getDepartmentPricing = createSelector(
  [
    getSelectedDepartment,
    (state) => selectors.configuration.getDepartmentPricing(state),
  ],
  (dept, getPricingForDept) => getPricingForDept(dept)
);

export const getPricePerCertification = createSelector(
  getDepartmentPricing,
  prop("perCertification")
);

export const department = {
  config: getSelectedDepartmentConfig,
  quickDateRange: quickSearchDateRange,
  shouldUpdateDateRange: shouldUpdateDeptDateRange,
  pricing: getDepartmentPricing,
  pricePerCertification: getPricePerCertification,
  ...getDateAllRangesByType([RECORDED, INSTRUMENT, APPLICATION]),
};

// utility functions
const formatDateForRange = (dateInAltFormat) => {
  if (dateInAltFormat instanceof Date) {
    return format(makeDate(dateInAltFormat), "yyyyMMdd");
  }

  let parsed = parseISO(dateInAltFormat);

  if (!(parsed instanceof Date && !isNaN(parsed))) {
    parsed = parse(dateInAltFormat, "MM/dd/yyyy", new Date());
  }

  return format(makeDate(parsed), "yyyyMMdd");
};

function convertToCertifiedDate({ shouldConvert, dateRange, certifiedDate }) {
  const [from] = dateRange.split(",");

  if (shouldConvert && certifiedDate) {
    // ToDo
    const to = formatDateForRange(certifiedDate);

    return [from, to].join(",");
  }

  return dateRange;
}

function isNotPresent(value) {
  return complement(allPass([complement(isNil), complement(isEmpty)]))(value);
}

function modifyDatesFromCertification(opts) {
  const { isCertified, denySearchAfterCertifiedDate, certifiedDate } = opts;

  return (dates) => {
    if (dates && certifiedDate) {
      const [from] = dates.split(",");

      if (isCertified && denySearchAfterCertifiedDate) {
        const certifiedTo = formatDateForRange(certifiedDate);

        return [from, certifiedTo].join(",");
      }

      return dates;
    }

    return dates;
  };
}
