// feat: usePaymentMethods

import { Warning } from "@kofile/icons";
import PropTypes from "prop-types";
import { isAbsent } from "../../utils";

const StripeMessage = ({ message }) => {
  if (isAbsent(message)) {
    return null;
  }

  return (
    <div className="stripe-message">
      <Warning
        id="stripemessage-warning-icon"
        className="stripe-message__icon"
      />
      <span className="stripe-message__text">{message}</span>
    </div>
  );
};

StripeMessage.propTypes = {
  message: PropTypes.string,
};

export default StripeMessage;
