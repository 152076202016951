import propOr from "ramda/src/propOr";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../components";
import { actions } from "../../store";
import { ContactSupport } from "../../units/common/contact-support";
import { chooseViewFor } from "../../utils/choose-view-for";
import { Receipt } from "./receipt";
import { useOrder } from "./use-order";

const OrderReceipt = (props) => {
  const { fresh, kind } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const { order, status } = useOrder(props.id || id);

  const setOrderNumber = () =>
    props.setOrderNumber(propOr("", "orderNumber", order));

  useEffect(setOrderNumber, [order]);

  useEffect(
    () => () => {
      dispatch(actions.checkout.clear());
      dispatch(actions.ncourt.clear());
    },
    []
  );

  return chooseViewFor(status).when({
    isLoading: () => <LoadingSpinner />,
    hasLoaded: () => <Receipt order={order} kind={kind} fresh={fresh} />,
    isError: () => <ContactSupport />,
    default: () => <LoadingSpinner />,
  });
};

OrderReceipt.defaultProps = {
  setOrderNumber: () => {},
};

export default OrderReceipt;
