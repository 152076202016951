import { useRef } from "react";
import BlueButton from "../doc-detail.common/_button.blue";
import WhiteButton from "../doc-detail.common/_button.white";
import Body from "./_body";
import Footer from "./_footer";
import FormContainer from "./_form";
import TextArea from "./_text-area";

const Form = ({ close, userInput, setUserInput, isSubmittable, onSubmit }) => {
  const ref = useRef(null);
  const placeholderText = "Insert your Feedback...";

  const clearAndClose = () => {
    ref.current.value = "";
    setUserInput("");
    close();
  };

  const updateInput = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <FormContainer onSubmit={onSubmit}>
      <Body hideBorder>
        <TextArea
          ref={ref}
          value={userInput}
          onInput={updateInput}
          onChange={updateInput}
          placeholder={placeholderText}
        ></TextArea>
      </Body>
      <Footer>
        <WhiteButton onClick={clearAndClose}>Cancel</WhiteButton>
        <BlueButton type="submit" disabled={!isSubmittable}>
          Submit
        </BlueButton>
      </Footer>
    </FormContainer>
  );
};

export default Form;
