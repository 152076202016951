import { useAPI, protocols } from "../../hooks/use-api";

const useFeedbackSubmit = () =>
  useAPI({
    send: "submit-document-feedback",
    protocol: protocols.HTTP,
    method: "POST",
  });

export default useFeedbackSubmit;
