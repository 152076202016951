import { css } from "@emotion/react";

export const styles = {
  header: (theme) => css`
    margin-top: 16px;
    margin-bottom: 6px;
    color: #041d44;
    font-size: 15px;
    font-weight: 600;

    @media screen and (max-width: ${theme.breakpoints.md}px) {
      font-size: 14px;
    }
  `,
};
