import { isAbsent } from "../../../utils";
import Addresses from "./addresses";
import LegalDescriptions from "./legal-description";
import { default as Legals } from "./legals";
import LotBlock from "./lot-block";
import MarginalReferenceUrls from "./marginal-reference.urls";
import MarriageCounty from "./marriage-county";
import Parcel from "./parcel";
import Parties from "./parties";
import SimpleArray from "./simple-array";

const map = {
  legalDescriptions: LegalDescriptions,
  parties: Parties,
  marginalReferences: MarginalReferenceUrls,
  remarks: SimpleArray,
  propAddress: Addresses,
  returnAddress: Addresses,
  consideration: SimpleArray,
  marriages: MarriageCounty,
  lotBlock: LotBlock,
  legals: Legals,
  parcel: Parcel,
};

export function getComponentByKey(key) {
  const Component = map[key];
  return isAbsent(Component) ? null : Component;
}
