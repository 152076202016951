import pick from "ramda/src/pick";
import { ofType } from "redux-observable";
import { race, timer } from "rxjs";
import { mergeMap, mapTo } from "rxjs/operators";
import actions from "../actions/notifications";
import types from "../types/notifications";

const hideNotificationAfterDelay = (action$) =>
  action$.pipe(
    ofType(types.ADD_NOTIFICATION),
    mergeMap((action) =>
      race(
        timer(action.payload.delay),
        action$.ofType(types.ADD_NOTIFICATION)
      ).pipe(mapTo(actions.removeNotification(pick(["id"], action.payload))))
    )
  );

export default [hideNotificationAfterDelay];
