import omit from "ramda/src/omit";
import internalTypes, { types } from "../types/cart";

export default {
  addToCart: (payload) => ({
    type: types.addToCart,
    payload: { items: payload.items.map(omit(["id"])) },
  }),
  expressCheckout: (payload) => ({
    type: internalTypes.REQUEST_EXPRESS_CART,
    payload,
  }),
  clearCart: () => ({ type: types.clearCart }),
  removeFromCart: (payload) => ({ type: types.removeFromCart, payload }),
  fetch: () => ({ type: "fetch-shopping-cart-for-a-user" }),
  makeCartStale: () => ({ type: internalTypes.MAKE_CART_STALE }),
  makeCartFresh: () => ({ type: internalTypes.MAKE_CART_FRESH }),
};
