import ModalShell from "../../../components/ModalShell";
import View from "./View";

const ModalChild = (props) =>
  Object.assign(
    () => (
      <ModalShell close={props.close}>
        <View {...props} isModal />
      </ModalShell>
    ),
    { displayName: "SignInModal" }
  );

export default ModalChild;
