import { withModal } from "../../hoc";
import useAddToCart from "./use-add-to-cart";

const AddToCart = ({ modal, children }) => {
  const { addDocToCart, text, disabled, isVisible } = useAddToCart({ modal });

  return isVisible ? children({ addDocToCart, disabled, text }) : null;
};

export default withModal(AddToCart);
