import { push } from "connected-react-router";
import { produce } from "immer";
import PropTypes from "prop-types";
import { stringify } from "query-string";
import allPass from "ramda/src/allPass";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import isEmpty from "ramda/src/isEmpty";
import lensPath from "ramda/src/lensPath";
import pickBy from "ramda/src/pickBy";
import pipe from "ramda/src/pipe";
import replace from "ramda/src/replace";
import set from "ramda/src/set";
import trim from "ramda/src/trim";
import type from "ramda/src/type";
import React from "react";
import { connect } from "react-redux";
import { actions, selectors } from "../../store";
import { conditionallyWrapWithQuotes } from "../../utils";
import BasicSearchView from "./basic-search";
import {
  findDepartmentConfig,
  findDateLabel,
  updatePresetByDepartment,
  validDateRangeInState,
  isValidDateRange,
} from "./basic-search.utils";

export class BasicSearch extends React.Component {
  constructor(props) {
    super(props);

    const initialDeptConfig = findDepartmentConfig(this.props);

    const { formState } = this.props.workspaces;
    const fullTextSearchKey =
      formState.departments.fullTextSearchKey ||
      initialDeptConfig.fullTextSearchDateRangeProp;
    const fullTextSearchLabel =
      formState.departments.fullTextSearchLabel ||
      initialDeptConfig.fullTextSearchDateRangeLabel;

    this.state = {
      form: {
        isValid: formState.form.isValid,
        isDisabled:
          formState.form.isDisabled ||
          !isValidDateRange(this.props.dateRange.rangeValue),
      },
      departments: {
        fullTextSearchKey,
        fullTextSearchLabel,
        value: this.props.workspaces.selectedDepartment,
      },
      searchTerm: {
        value: formState.searchTerm.value,
      },
      dateRange: {
        value: formState.dateRange.value,
        rangeValue:
          formState.dateRange.rangeValue || this.props.dateRange.rangeValue,
        isValid: formState.dateRange.isValid,
        presets: updatePresetByDepartment(this.props, fullTextSearchKey),
      },
      searchOcrText: {
        value: this.props.searchScope.default === "indexAndOcr",
      },
    };

    this.ogState = produce(this.state, (draft) => draft);

    this.form = {
      submit: submitForm.bind(this),
      handleSubmit: handleFormSubmit.bind(this),
      validate: validateForm.bind(this),
      reset: resetForm.bind(this),
    };

    this.departments = {
      select: selectDepartment.bind(this),
      storeDepartmentRef: storeDepartmentRef.bind(this),
    };

    this.searchTerm = {
      update: updateSearchTerm.bind(this),
      handleEnter: handleEnterForSearchTerm.bind(this),
    };

    this.dateRange = {
      update: updateDate.bind(this),
      updateRange: updateDateRange.bind(this),
    };

    this.searchOcrText = {
      update: setSearchOcrText.bind(this),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      dateRange: { rangeValue: oldRangeValue },
      workspaces: { selectedDepartment: oldSelectedDept },
      searchScope: oldSearchScope,
    } = this.props;

    const {
      dateRange: { rangeValue: newRangeValue },
      workspaces: { selectedDepartment: newSelectedDept },
      searchScope: newSearchScope,
    } = nextProps;

    const rangeValueChanged = oldRangeValue !== newRangeValue;
    const deptChanged = oldSelectedDept !== newSelectedDept;
    const scopeChanged = oldSearchScope.default !== newSearchScope.default;

    if (scopeChanged) {
      const value = newSearchScope.default === "indexAndOcr";
      this.searchOcrText.update(value);
    }

    if (deptChanged) {
      const { fullTextSearchKey } = this.state.departments;
      const setDateRangePresets = set(
        lensPath(["dateRange", "presets"]),
        updatePresetByDepartment(nextProps, fullTextSearchKey)
      );
      this.setState(setDateRangePresets);
    }

    if (rangeValueChanged) {
      const setFormDisabledFromDate = () => {
        const setFormDisabled = (status) =>
          set(lensPath(["form", "isDisabled"]), status);

        if (validDateRangeInState(this.state)) {
          this.setState(setFormDisabled(false));
        } else {
          this.setState(setFormDisabled(true));
        }
      };

      const setRangeValue = set(
        lensPath(["dateRange", "rangeValue"]),
        newRangeValue
      );

      this.setState(setRangeValue, setFormDisabledFromDate);
    }
  }

  componentWillUnmount() {
    const payload = {
      state: this.state,
      workspaceID: this.props.workspaces.workspaceID,
    };

    this.props.dispatch(actions.workspaces.updateQuickSearchState(payload));
  }

  render() {
    const selectedDepartment = this.props.departments.options.find(
      (dept) => dept.value === this.state.departments.value
    );

    const props = {
      form: {
        isDisabled: this.state.form.isDisabled,
        handleSubmit: this.form.handleSubmit,
        reset: this.form.reset,
      },
      departments: {
        options: this.props.departments.options,
        select: this.departments.select,
        storeDepartmentRef: this.departments.storeDepartmentRef,
        value: selectedDepartment,
        noDocuments: this.props.departments.noDocuments,
        tooltipText: this.props.departments.tooltipText,
      },
      searchTerm: {
        value: this.state.searchTerm.value,
        update: this.searchTerm.update,
        handleEnter: this.searchTerm.handleEnter,
      },
      dateRange: {
        dateRangeOption: this.props.dateRange.defaultValue,
        options: this.state.dateRange.presets,
        value: this.state.dateRange.value,
        onChange: this.dateRange.update,
        onRangeChange: this.dateRange.updateRange,
        rangeValue: this.state.dateRange.rangeValue,
        maxRange: this.props.dateRange.rangeValue,
        isLoading: this.props.dateRange.isLoading,
        isValid: this.state.dateRange.isValid,
        label: findDateLabel(this.props),
      },
      searchOcrText: {
        value: this.state.searchOcrText.value,
        update: this.searchOcrText.update,
        searchScope: this.props.searchScope,
      },
    };

    return <BasicSearchView {...props} />;
  }
}

BasicSearch.propTypes = {
  workspaces: PropTypes.shape({
    workspaceID: PropTypes.string.isRequired,
    selectedDepartment: PropTypes.string.isRequired,
    formState: PropTypes.shape({
      form: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
      }).isRequired,
      departments: PropTypes.shape({
        fullTextSearchKey: PropTypes.string,
        value: PropTypes.string,
      }).isRequired,
      searchTerm: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }).isRequired,
      dateRange: PropTypes.shape({
        value: PropTypes.string.isRequired,
        rangeValue: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
      }).isRequired,
      searchOcrText: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  departments: PropTypes.shape({
    configuration: PropTypes.array.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  dateRange: PropTypes.shape({
    defaultValue: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    presets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    rangeValue: PropTypes.string,
    maxRange: PropTypes.string,
  }).isRequired,
};

BasicSearch.displayName = "BasicSearch";

const mapStateToProps = (state) => {
  const selectedDepartment = selectors.workspaces.getSelectedDepartment(state);

  return {
    searchScope: selectors.search.getQuickSearchScope(state),
    departments: {
      options: selectors.configuration.departmentOptions(state),
      configuration: selectors.configuration.getDepartments(state),
      noDocuments: selectors.search.departmentHasEmptyDates(
        state,
        selectedDepartment
      ),
      tooltipText: selectors.configuration.getDepartmentTooltipText(state),
    },
    dateRange: {
      defaultValue: "DefaultValue",
      isLoading: selectors.search.isDeptDateRangeLoading(state),
      presets: selectors.configuration.dateRangePresets(state),
      rangeValue: selectors.workspaces.department.quickDateRange(state),
      canSearchDates: selectors.configuration.showBasicSearchDatePicker(state),
    },
    workspaces: {
      workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
      selectedDepartment,
      formState: selectors.workspaces.getQuickSearchState(state),
      shouldFetchDeptDates:
        selectors.workspaces.department.shouldUpdateDateRange(state),
    },
  };
};

export default connect(mapStateToProps)(BasicSearch);

// --------------------------------------------------------------------- private

function validateForm({ thenSubmit = false }) {
  const updateFormValidity = compose(
    set(lensPath(["form", "isValid"])),
    allPass([validDateRangeInState])
  );

  this.setState(updateFormValidity, () => {
    if (thenSubmit) {
      this.form.submit();
    }
  });
}

function handleFormSubmit(event) {
  event.stopPropagation();
  event.preventDefault();

  this.form.submit();
}

function submitForm() {
  if (this.state.form.isValid && !this.state.form.isDisabled) {
    const pathname = "/results";
    const deptCode = this.state.departments.value;
    const deptKey = this.state.departments.fullTextSearchKey;
    const searchOcrText = this.state.searchOcrText.value;
    const keywordSearch = this.props.searchScope.ocrUsesKeywordSearch === true;

    const trimmedValue = pipe(
      trim,
      replace("“", '"'),
      replace("”", '"'),
      replace("‘", "'"),
      replace("’", "'")
    )(this.state.searchTerm.value);

    const { outputValue: searchValue } = conditionallyWrapWithQuotes({
      value: trimmedValue,
      searchOcrText,
      keywordSearch,
    });

    const dates = this.props.dateRange.canSearchDates
      ? {
          [deptKey]:
            this.state.dateRange.value === "DefaultValue"
              ? this.state.dateRange.rangeValue
              : this.state.dateRange.value,
        }
      : {};

    const query = {
      department: deptCode,
      searchType: "quickSearch",
      searchValue,
      searchOcrText,
      keywordSearch,
      ...dates,
    };

    const queryString = stringify(pickBy(complement(isEmpty), query));

    this.props.dispatch(
      push(`${pathname}?${queryString}`, {
        workspaceID: this.props.workspaces.workspaceID,
      })
    );
  }
}

function selectDepartment({ value }) {
  const config = this.props.departments.configuration.find(
    (conf) => conf.code === value
  );

  const updateDepartment = set(lensPath(["departments", "value"]), value);

  const updateDeptKey = set(
    lensPath(["departments", "fullTextSearchKey"]),
    config.fullTextSearchDateRangeProp
  );

  const updateDeptValue = set(
    lensPath(["departments", "fullTextSearchValue"]),
    config.fullTextSearchDateRangeValue
  );

  const update = compose(updateDepartment, updateDeptKey, updateDeptValue);

  this.setState(update, () => {
    const { dispatch } = this.props;

    dispatch(actions.workspaces.updateSelectedDepartment(value));

    if (this.props.workspaces.shouldFetchDeptDates(value)) {
      dispatch(
        actions.search.fetchDepartmentDateRanges({
          department: value,
        })
      );
    }
  });
}

function updateSearchTerm(event) {
  const lens = lensPath(["searchTerm", "value"]);

  this.setState(set(lens, event.target.value));
}

function handleEnterForSearchTerm(event) {
  if (event.keyCode === 13) {
    event.preventDefault();
    event.stopPropagation();

    this.form.validate({ thenSubmit: true });
  }
}

function updateDate({ value }) {
  const lens = lensPath(["dateRange", "value"]);

  this.setState(set(lens, value));
}

function updateDateRange({ value }) {
  const lens = lensPath(["dateRange", "rangeValue"]);

  this.setState(set(lens, value), () => {
    this.forceUpdate();
  });
}

function storeDepartmentRef(ref) {
  this.departmentRef = ref;
}

function setSearchOcrText(val) {
  const isBool = type(val) === "Boolean";
  const updated = isBool ? val : val.target.value === "withOcr";
  const lens = lensPath(["searchOcrText", "value"]);

  this.setState(set(lens, updated));
}

function resetForm(e) {
  e.preventDefault();

  this.setState(this.ogState);
}
