import { css } from "@emotion/react";

export default {
  returnLink: css`
    color: #3088dc;
    cursor: pointer;
    display: block;
    padding: 20px 50px;
  `,
};
