import { makeActionTypes } from "../../utils";

export const types = {
  addedToCart: "purchasables-added-to-a-shopping-cart",
  addToCart: "add-purchasables-to-a-shopping-cart",
  clearCart: "clear-shopping-cart",
  cartCleared: "shopping-cart-cleared",
  errorClearingCart: "error-clearing-shopping-cart",
  errorAddingToCart: "error-adding-purchasables-to-a-shopping-cart",
  errorRemovingFromCart: "error-removing-purchasables-from-shopping-cart",
  removedFromCart: "purchasables-removed-from-shopping-cart",
  removeFromCart: "remove-purchasables-from-a-shopping-cart",
  userShoppingCart: "user-shopping-cart",
};

export default makeActionTypes("cart", [
  "REQUEST_EXPRESS_CART",
  "MAKE_CART_STALE",
  "MAKE_CART_FRESH",
]);
