import styled from "@emotion/styled";

export const ExistingCardWrapper = styled.div`
  width: 400px;
  background-color: var(--color-neutral-lightest);
  border-radius: 4px;
  display: grid;
  padding: 25px;
  border: 1px solid #dadde2;
  align-items: center;
  grid-template-areas:
    "last4      edit-button remove-button"
    "expiration edit-button remove-button";
  grid-template-columns: auto min-content min-content;
  gap: 15px;
`;
