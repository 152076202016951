import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status, isLoading } from "../../constants";
import { actions, selectors } from "../../store";
import { isNotNil, isPresent, randomString } from "../../utils";
import { formatItemsForQuote } from "./get-price-quote.format";

// hook for new doc preview & the future
export const usePriceQuote = (certifiedCopy) => {
  const dispatch = useDispatch();
  const [quoteId, setQuoteId] = useState("");
  const [currentQuote, setCurrentQuote] = useState({});
  const [status, setStatus] = useState(Status.Init);

  const { user, workspaces, documents } = selectors;
  const items = useSelector(user.getCheckoutItems);
  const department = useSelector(workspaces.getSelectedDepartment);
  const entityType = useSelector(user.getCheckoutEntityType);
  const totalPages = useSelector(user.getCheckoutItemTotalPages);
  const quotes = useSelector(documents.getPriceQuotes);
  const isFirstQuote = useSelector(documents.isFirstQuoteFetched);

  const formatQuote = (quote) => {
    const get = (key) => prop(key, quote);
    const quotedPages = get("totalPages");
    const totalPagesSelected = isPresent(quotedPages)
      ? quotedPages
      : totalPages;

    return {
      totalPagesSelected,
      totalPrice: get("totalPrice"),
      subtotal: get("subtotal"),
      convenienceFee: get("convenienceFee"),
    };
  };

  useEffect(() => {
    const quote = propOr({}, quoteId, quotes);

    if (isPresent(quote)) {
      setCurrentQuote(formatQuote(quote));
      setStatus(Status.Loaded);
    }
  }, [quotes]);

  const getQuote = (props) => {
    setStatus(Status.Loading);

    const {
      includeTranscription = false,
      includeTranslation = false,
      includeAttachments = false,
      isCertifiedCopy = false,
      selectedPages = "all",
    } = props;

    const certCopyChecked = prop("isChecked", certifiedCopy);

    const docs = formatItemsForQuote({
      inHook: true,
      items,
      department,
      entityType,
      totalPages,
      includeTranscription,
      includeTranslation,
      includeAttachments,
      isCertifiedCopy:
        isFirstQuote && isNotNil(certCopyChecked)
          ? certCopyChecked
          : isCertifiedCopy,
      form: { selectedPages },
    });

    const newQuoteId = randomString();
    setQuoteId(newQuoteId);

    const { getDocPriceQuote } = actions.documents;

    return dispatch(getDocPriceQuote({ docs, quoteId: newQuoteId }));
  };

  const quote = { ...currentQuote, isLoading: isLoading(status) };

  return [quote, getQuote];
};
