import compose from "ramda/src/compose";
import prop from "ramda/src/prop";

export const getExportResults = (state) => state.exportResults;
export const getExportResultsData = compose(prop("data"), getExportResults);

export const getExportResultsProgress = compose(
  prop("inProgress"),
  getExportResults
);

export const getExportResultsNumRows = compose(
  prop("rows"),
  getExportResultsData
);
