import isNil from "ramda/src/isNil";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import { compose, withReducer, withHandlers } from "recompose";
import { buildForm, checkIfFormValid } from "./form";
import reducer, { actions } from "./reducer";

export const noError = "";

export const createInitialState = (config, defaults = {}) => {
  const result = mapObjIndexed((valueObj, key) => {
    const defaultValue = defaults[key];

    return {
      value: isNil(defaultValue) ? noError : defaultValue,
      error: noError,
    };
  }, config);

  return result;
};

const withFormState = (formConfig, stateName, defaults = {}) =>
  compose(
    withReducer(
      stateName,
      "formDispatch",
      reducer,
      createInitialState(formConfig, defaults)
    ),
    withHandlers(actions)
  );

const withFormStateInitialized = (stateName, initializer) =>
  compose(
    withReducer(stateName, "formDispatch", reducer, initializer),
    withHandlers(actions)
  );

export default {
  buildForm,
  checkIfFormValid,
  createInitialState,
  withFormState,
  withFormStateInitialized,
};
