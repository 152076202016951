import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import { useReducer, useState } from "react";
import { useSearch } from "../../hooks";
import { isPresent } from "../../utils";

const useOcr = (data, currentPage) => {
  const [hitIndex, setHitIndex] = useState(0);
  const [hitMapIndex, setHitMapIndex] = useState(0);
  const search = useSearch();
  const highlight = search.get("highlight");

  const [isOcrVisible, toggleOcrVisibility] = useReducer(
    (x) => !x,
    !!highlight
  );

  const hasHighlight = isPresent(highlight);
  const ocrPresent = isPresent(prop("ocrHits", data));
  const ocrCountPresent = pathOr(0, ["ocrHits", "count"], data) > 0;
  const hasOcrHits = hasHighlight && ocrPresent && ocrCountPresent;
  const currentPageOcr = data?.ocrHits?.pages?.[currentPage];
  const totalHits = data?.ocrHits?.count;

  return {
    hasOcrHits: hasOcrHits,
    ocrHits: data?.ocrHits,
    currentPageOcr,
    totalHits,
    isOcrVisible,
    toggleOcrVisibility,
    hitMapIndex,
    setHitMapIndex,
    hitIndex,
    setHitIndex,
    highlight,
  };
};

export default useOcr;
