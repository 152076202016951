import { Fragment } from "react";
import { A11yAnnouncement } from "../../../components";
import EmptyNotepad from "./EmptyNotepad";

const Notepad = () => (
  <Fragment>
    <A11yAnnouncement>Navigated to notepad page</A11yAnnouncement>
    <EmptyNotepad />
  </Fragment>
);

Notepad.displayName = "Notepad";

export default Notepad;
