import compose from "ramda/src/compose";
import prop from "ramda/src/prop";

export const getPassword = prop("password");
export const getResetIsSent = compose(prop("resetIsSent"), getPassword);
export const getEmailToReset = compose(prop("emailToReset"), getPassword);
export const getLoading = compose(prop("loading"), getPassword);
export const getResetError = compose(prop("resetError"), getPassword);
export const getUpdateError = compose(prop("updateError"), getPassword);
export const getPasswordReset = compose(prop("passwordReset"), getPassword);
