import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import pathOr from "ramda/src/pathOr";
import { Status, genericError } from "../../constants";
import { handleActions } from "./utils";

const unverifiedMessage =
  "In order to complete your order, you need to verify your account. Please check your email and click on the verification link.";

export const types = {
  fetchDownloadUrl: "fetch-download-url",
  downloadUrl: "download-url",
  errorFetchingDownloadUrl: "error-fetching-download-url",
  clearDownload: "clear-download",
  downloadComplete: "download-complete",
};

export const emptyDownload = {
  url: null,
  error: null,
  docId: null,
  status: Status.Init,
};

export default handleActions([], {
  [types.fetchDownloadUrl]: (state, { payload }) => {
    const { docId } = payload.item;
    const updated = { ...emptyDownload, status: Status.Loading, docId };
    return initDownload(state, docId, updated);
  },

  [types.downloadUrl]: (state, { payload }) => {
    const updated = {
      status: Status.Loaded,
      url: payload.pdfPath,
    };
    return updateDownload(state, payload, updated);
  },

  [types.errorFetchingDownloadUrl]: (state, { payload }) => {
    const error = pathOr(genericError, ["reason", "message"], payload);
    const userIsUnverified = payload.userIsVerified === false;
    const message = userIsUnverified ? unverifiedMessage : error;
    const updated = {
      status: Status.Error,
      url: null,
      error: message,
      userIsUnverified,
    };

    return updateDownload(state, payload, updated);
  },

  [types.downloadComplete]: (state, { payload }) => {
    const updated = { status: Status.Inert };
    return updateDownload(state, payload, updated);
  },

  [types.clearDownload]: (state, { payload }) => updateDownload(state, payload),
});

const updateDownload = (state, payload, updated = null) =>
  state.reduce((acc, download) => {
    const isSelectedDownload = download.docId === payload.docId;

    if (isSelectedDownload) {
      return isNil(updated) ? [...acc] : [...acc, { ...download, ...updated }];
    }

    return [...acc, download];
  }, []);

const initDownload = (state, docId, updated) => {
  if (isEmpty(state)) return [updated];

  const isSelected = isSelectedDoc(docId);
  const isNewDownload = state.filter(isSelected).length === 0;

  if (isNewDownload) return [...state, updated];

  return state.reduce(consolidateDuplicates({ updated, isSelected }), []);
};

const isSelectedDoc = (docId) => (dl) => dl.docId === docId;

const consolidateDuplicates = (props) => (acc, download) => {
  const { updated, isSelected } = props;
  if (isSelected(download)) return [...acc, { ...download, ...updated }];
  return [...acc, updated];
};
