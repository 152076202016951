import isAfter from "date-fns/isAfter";
import isNil from "ramda/src/isNil";
import { useSelector } from "react-redux";
import { selectors } from "../store";

export const useSkipCoverPage = (docData) => {
  const { configuration } = selectors;
  const skipCoverPage = useSelector(configuration.skipCoverPage);
  const coverPageStartDate = useSelector(configuration.coverPageStartDate);
  const recordedDate = docData?.recordedDate;
  const pageCount = docData?.pageCount;

  const usesCoverPage = () =>
    isAfter(new Date(recordedDate), new Date(coverPageStartDate));

  if (isNil(coverPageStartDate)) return;
  if (isNil(recordedDate)) return;

  if (pageCount > 1 && usesCoverPage() && skipCoverPage) {
    return true;
  }

  return false;
};
