import { Fragment } from "react";
import { Capacity } from "../account-settings.subscriptions.common/subscription.capacity";
import { Price } from "../account-settings.subscriptions.common/subscription.price";
import { CancelButton } from "./_subscriptions.button.cancel";
import { OrgText } from "./_subscriptions.organization.text";
import { OrgTitle } from "./_subscriptions.organization.title";

export const AdminActions = (props) => {
  const { subscription: sub, cancel, changeButton, renewDate } = props;

  return (
    <Fragment>
      <Capacity>{sub.maxMembers}</Capacity>
      <Price amount={sub.amount} period={sub.period} label={sub.description} />
      {renewDate}
      {changeButton}
      <CancelButton onClick={cancel}>Cancel Subscription</CancelButton>
      <OrgTitle>Organization</OrgTitle>
      <OrgText>{sub.organizationName}</OrgText>
    </Fragment>
  );
};
