import { useCallback } from "react";
import { Button } from "../../components/button";
import { useAsset } from "../../hooks/use-asset";

export const PrintButton = ({ buttonText, emotionCss }) => {
  const icon = useAsset("icon_printer.svg");

  const click = useCallback((e) => {
    e.preventDefault();
    window.print();
  }, []);

  return (
    <Button onClick={click} icon={icon} css={emotionCss}>
      {buttonText ? buttonText : "Print Receipt"}
    </Button>
  );
};
