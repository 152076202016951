import { types as syncTypes } from "@kofile/ko-search-action-types";

export default {
  accept: (payload) => ({
    type: "accept-fraud-alert-terms-and-conditions",
    payload,
  }),

  fetch: (payload) => ({
    type: syncTypes.FETCH_I18N_TERMS_AND_CONDITIONS_0,
    payload,
  }),

  fetchStatus: (payload) => ({
    type: syncTypes.FETCH_USER_TERMS_STATUS_0,
    payload,
  }),
};
