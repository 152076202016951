import classnames from "classnames";
import PropTypes from "prop-types";
import Checkbox from "../../../../components/Checkbox";

const Item = (props) => {
  const {
    children,
    isSelected,
    itemProps,
    style,
    index,
    highlightedIndex,
    hits,
  } = props;
  const itemClass = classnames("tokenized-nested-select__item", {
    "tokenized-nested-select__item--highlighted": highlightedIndex === index,
  });

  return (
    <div
      className={itemClass}
      style={style}
      onMouseEnter={itemProps.onMouseEnter}
    >
      <Checkbox
        id={children}
        isChecked={isSelected}
        name={children}
        {...itemProps}
      />
      {children}
      {Boolean(hits) && (
        <span className="tokenized-nested-select__item-hits">{`(${hits})`}</span>
      )}
    </div>
  );
};

Item.displayName = "Item";

Item.propTypes = {
  children: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number,
  itemProps: PropTypes.object,
  style: PropTypes.object,
};

export default Item;
