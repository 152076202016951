import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { A11yAnnouncement, Link } from "../../../components";
import { selectors } from "../../../store";

const styles = {
  container: css`
    align-items: center;
    display: flex;
    flex-flow: column;
  `,
  wrapper: (isModal) => css`
    background-color: var(--color-neutral-lightest);
    border: ${isModal ? "none" : "1px solid rgba(4, 29, 68, 0.15)"};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: ${isModal ? "0 0 35px 0" : "35px"};
    width: 525px;
  `,
  icon: css`
    height: 85px;
    margin-bottom: 42px;
    width: 85px;
  `,
  title: css`
    color: #041c44;
    font-family: "Source Sans Pro";
    font-size: 26px;
    letter-spacing: 0.1px;
    line-height: 33px;
    margin-bottom: 15px;
  `,
  text: css`
    color: #4b5c78;
    font-family: "Source Sans Pro";
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 29px;
    text-align: center;
  `,
  link: css`
    & a {
      color: #006dd5;
      font-family: "Source Sans Pro";
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 15px;
    }
  `,
};

const CompleteAlert = ({ isModal = false }) => {
  const { getImg } = selectors.assets;
  const greenCheckIcon = useSelector(getImg("green_check.png"));

  return (
    <>
      <A11yAnnouncement>
        Navigated to complete property alert page
      </A11yAnnouncement>

      <div css={styles.container}>
        <div css={styles.wrapper(isModal)}>
          <img css={styles.icon} src={greenCheckIcon} alt="green checkmark" />
          <h1 css={styles.title}>Your Alert has been Configured</h1>
          <p css={styles.text}>
            We’ll send you an email if an alert is triggered based on the
            name(s) you specified as your search criteria.
          </p>
          <Link
            css={styles.link}
            to="/account/property-alert"
            workspaceID="property-alert"
          >
            View Property Alert Dashboard
          </Link>
        </div>
      </div>
    </>
  );
};

export default CompleteAlert;
