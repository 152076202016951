// mod: doc-preview

import compose from "ramda/src/compose";
import lensPath from "ramda/src/lensPath";
import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import set from "ramda/src/set";
import React from "react";
import { Stripe } from "../../components";
import { CHECKOUT_TYPES } from "../../constants";
import { isNotNil } from "../../utils";
import { getPriceQuote } from "../checkout.modal.common/get-price-quote";
import { supportDocPresent } from "../checkout.modal.common/support-docs-present";
import ExpressCheckout from "../checkout.modal.express";
import AddToCart from "./add-to-cart";
import connect from "./connect";

class CheckoutWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quoteID: "",
      form: {
        disabled: false,
        includeAttachments: false,
        certifiedCopy: this.props.certifiedCopy || false,
        selectedPages: "all",
      },
    };

    this.updateQuoteID = updateQuoteID.bind(this);
    this.getPriceQuote = getPriceQuote.bind(this);
    this.getTotalPages = getTotalPages.bind(this);

    this.form = {
      setPrice: setPrice.bind(this),
      setSelectedPages: setSelectedPages.bind(this),
      setIncludeAttachments: setIncludeAttachments.bind(this),
      setCertifiedCopy: setCertifiedCopy.bind(this),
      setDisabled: setFormDisabled.bind(this),
    };
  }

  componentDidMount() {
    this.getPriceQuote({
      props: this.props,
      form: this.state.form,
      updateQuoteID: this.updateQuoteID,
    })();
  }

  componentWillUnmount() {
    if (this.props.newDocPreviewEnabled) {
      this.props.clearQuotes();
    }
  }

  render() {
    const View = {
      [CHECKOUT_TYPES.NORMAL]: AddToCart,
      [CHECKOUT_TYPES.EXPRESS]: ExpressCheckout,
    }[this.props.checkoutType];

    const priceQuote = pathOr(
      {},
      ["priceQuotes", this.state.quoteID],
      this.props
    );

    const props = {
      isModal: this.props.isModal,
      form: {
        disabled: this.state.form.disabled,
        selectedPages: this.state.form.selectedPages,
        includeAttachments: this.state.form.includeAttachments,
        certifiedCopy: this.state.form.certifiedCopy,
        onSubmit: this.props.onSubmit,
      },
      common: {
        items: this.props.items,
        close: this.props.close,
        cartID: this.props.cartID,
        department: this.props.department,
        entityType: this.props.entityType,
      },
      header: {
        checkoutType: this.props.checkoutType,
        entityType: this.props.entityType,
        itemCount: this.props.items.length,
      },
      info: {
        totalPagesSelected: this.getTotalPages(),
        totalPrice: priceQuote.totalPrice,
        subtotal: priceQuote.subtotal,
        convenienceFee: priceQuote.convenienceFee,
        isLoading: this.props.isFetchingQuote,
      },
      pageSelection: {
        itemCount: this.props.items.length,
        currentPage: this.props.currentPage,
        totalPages: this.props.totalPages,
        entityType: this.props.entityType,
        onChange: this.form.setSelectedPages,
        onError: () => this.form.setDisabled(true),
        onClearError: () => this.form.setDisabled(false),
      },
      addSupportDocs: {
        attachments: {
          isPresent: this.props.attachmentData.totalAttachments !== 0,
          action: this.form.setIncludeAttachments,
          label: `Include ${this.props.attachmentData.totalAttachments} Attachments (${this.props.attachmentData.totalPages} pages)`,
        },
        transcription: {
          isPresent: supportDocPresent("transcription", this.props),
        },
        translation: {
          isPresent: supportDocPresent("translation", this.props),
        },
      },
      certifiedCopy: {
        onChange: this.form.setCertifiedCopy,
        isChecked: this.state.form.certifiedCopy,
        certificationFeeSubtotal: priceQuote.certificationFeeSubtotal,
        department: this.props.department,
      },
      footer: {
        onCancel: this.props.close,
        disabled: this.state.form.disabled,
      },
    };

    return (
      <Stripe>
        <View {...props} />
      </Stripe>
    );
  }
}

CheckoutWindow.defaultProps = {
  isModal: false,
  items: [],
  attachmentData: {
    totalAttachments: 0,
    totalPages: 0,
  },
  onSubmit: () => {},
};

export default compose(connect)(CheckoutWindow);

// --------------------------------------------------------------------- private

function updateQuoteID(id) {
  this.setState({ quoteID: id });
}

function setPrice(quoteID) {
  const price = this.props.priceQuotes[quoteID];

  this.setState(set(lensPath(["form", "price"]), price));
}

function setSelectedPages(selectedPages) {
  this.setState(
    set(lensPath(["form", "selectedPages"]), selectedPages),
    getPriceQuote({
      props: this.props,
      form: this.state.form,
      updateQuoteID: this.updateQuoteID,
      selectedPages,
    })
  );
}

function setIncludeAttachments(includeAttachments) {
  this.setState(
    set(lensPath(["form", "includeAttachments"]), includeAttachments),
    getPriceQuote({
      props: this.props,
      form: this.state.form,
      updateQuoteID: this.updateQuoteID,
      includeAttachments,
    })
  );
}

function setCertifiedCopy(certifiedCopy) {
  this.setState(
    set(lensPath(["form", "certifiedCopy"]), certifiedCopy),
    getPriceQuote({
      props: this.props,
      form: this.state.form,
      updateQuoteID: this.updateQuoteID,
      certifiedCopy,
    })
  );
}

function setFormDisabled(disabled) {
  this.setState(set(lensPath(["form", "disabled"]), disabled));
}

function getTotalPages() {
  const { quoteID } = this.state;
  const quotedPages = path(["priceQuotes", quoteID, "totalPages"], this.props);

  return isNotNil(quotedPages) ? quotedPages : this.props.totalPages;
}
