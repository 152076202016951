// feat: usePaymentMethods

import { CardElement } from "react-stripe-elements";
import ErrorMessage from "../../ErrorMessage";
import useCardErrors from "../use-card-errors";

const style = {
  base: {
    fontSize: "0.85rem",
    iconColor: "rgba(0, 109, 213, 0.7)",
    color: "rgba(4, 29, 68, 0.7)",
  },
  invalid: {
    iconColor: "rgba(196, 56, 47, 0.7)",
    color: "rgba(196, 56, 47, 0.7)",
  },
};

const CardInput = ({ onUserInput, disabled = false }) => {
  const { cardError, setCardError } = useCardErrors();

  const onChange = (e) => {
    setCardError(e);
    onUserInput();
  };

  return (
    <div>
      <CardElement
        iconStyle="solid"
        style={style}
        disabled={disabled}
        onChange={onChange}
      />
      <ErrorMessage inputError={cardError} />
    </div>
  );
};

export default CardInput;
