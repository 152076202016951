import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../../constants";
import { useFraudAlertTerms } from "../../../hooks/termsAndConditions";
import { actions, selectors } from "../../../store";
import { isAbsent, isPresent } from "../../../utils";

const defaultFields = {
  parties: {
    fieldType: "name",
    label: "Parties",
    placeholder: "LASTNAME FIRSTNAME (Insert party name by pressing enter)",
    required: true,
    copy: {
      subhead: "Enter personal and/or business name(s):",
      info: "For personal names, enter last name first e.g. JONES BOB",
    },
  },
};

export const useFraudAlertSetup = () => {
  const dispatch = useDispatch();
  const { Error, Loading, Loaded } = Status;

  const { configuration, fraudAlert, workspaces } = selectors;
  const config = useSelector(configuration.getFraudAlert);
  const department = useSelector(workspaces.getSelectedDepartment);
  const submissionStatus = useSelector(fraudAlert.getSubmissionStatus);
  const fields = propOr([], "alertFields", config);

  const fieldsConfig = isPresent(prop("fields", config))
    ? prop("fields", config)
    : defaultFields;

  const defaultFormData = fields.reduce((acc, field) => {
    acc[field] = { data: null, error: "" };
    return acc;
  }, {});

  const [formData, setFormData] = useState(defaultFormData);

  const [generalError, setGeneralError] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [mergeType, setMergeType] = useState("default");

  useEffect(() => {
    if (submissionStatus === Error) {
      setGeneralError("There was an error. Please try again later.");
    }
  }, [submissionStatus]);

  const submitFraudAlert = async (type = mergeType) => {
    const fieldsPayload = fields.reduce((acc, field) => {
      const data = formData[field].data;
      if (isPresent(data)) {
        acc[field] = field === "parcel" ? [data] : data;
      }
      return acc;
    }, {});

    await dispatch(
      actions.fraudAlert.create({
        department,
        fields: fieldsPayload,
        type,
      })
    );
  };

  const {
    display: displayTerms,
    hasAccepted,
    loading: termsLoading,
  } = useFraudAlertTerms({
    submit: () => {
      setShowTerms(false);
      submitFraudAlert();
    },
    cancel: () => {
      const errorMsg = "Please accept the terms to create a Property Alert";
      setGeneralError(errorMsg);
      setShowTerms(false);
    },
  });

  const submit = (event) => {
    event.preventDefault();
    let hasErrors = false;
    const updatedFormData = { ...formData };

    const allFieldsEmpty = fields.every((field) =>
      isAbsent(formData[field].data)
    );

    if (allFieldsEmpty) {
      setGeneralError("Please fill out at least one field to continue.");
      return;
    }

    fields.forEach((field) => {
      const fieldData = formData[field].data;
      const fieldConfig = fieldsConfig[field];

      if (fieldConfig?.required && isAbsent(fieldData)) {
        const msg = `This field is required, please enter a value to continue.`;
        updatedFormData[field].error = msg;
        hasErrors = true;
      } else {
        updatedFormData[field].error = "";
      }
    });

    setFormData(updatedFormData);

    if (hasErrors) return;

    const formElements = event.currentTarget.elements;
    const hasMergeCriteria = formElements.mergeCriteria?.checked;
    const hasMergeParties = formElements.mergeParties?.checked;

    let type = "default";
    if (hasMergeCriteria) {
      type = "mergeCriteria";
    } else if (hasMergeParties) {
      type = "mergeParties";
    }

    setMergeType(type);

    hasAccepted ? submitFraudAlert(type) : setShowTerms(true);
  };

  const inputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: { data: value, error: "" },
    }));
  };

  return {
    generalError,
    submit,
    inputChange,
    fields,
    fieldsConfig,
    formData,
    terms: {
      show: showTerms,
      loading: termsLoading,
      init: displayTerms,
    },
    submission: {
      isLoading: submissionStatus === Loading,
      hasLoaded: submissionStatus === Loaded,
    },
  };
};
