import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { A11yAnnouncement, Stripe } from "../../../components";
import { withSSRRenderGuard } from "../../../hoc";
import Checkout from "./Checkout";
import Contents from "./Contents";
import Receipt from "./receipt";

const paths = {
  CART: "/cart",
  CONTENTS: "/cart/contents",
  CHECKOUT: "/cart/checkout",
  RECEIPT: "/cart/checkout-complete/:orderId",
};

export const Cart = () => (
  <Fragment>
    <Helmet>
      <title>Cart</title>
    </Helmet>
    <A11yAnnouncement>Navigated to shopping cart page</A11yAnnouncement>
    <Stripe>
      <Switch>
        <Route exact path={paths.CONTENTS} component={Contents} />
        <Route exact path={paths.RECEIPT} component={Receipt} />
        <Route exact path={paths.CHECKOUT} component={Checkout} />
        <Redirect from={paths.CART} to={paths.CONTENTS} />
      </Switch>
    </Stripe>
  </Fragment>
);

Cart.defaultProps = {
  keys: { stripe: "" },
};

export default withSSRRenderGuard(Cart);
