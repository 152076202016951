import styled from "@emotion/styled";
import { buttonStyle } from "../account-settings.subscriptions.common/_style.button";
import { canBeDisabled } from "../account-settings.subscriptions.common/_style.button.can-be-disabled";
import { selectButtonStyle } from "../account-settings.subscriptions.common/_style.select.button";

export const SubmitPaymentButton = styled.button((props) => ({
  ...buttonStyle,
  ...selectButtonStyle,
  ...(canBeDisabled(props) || {}),
}));
