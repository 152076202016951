import { withModal } from "../../hoc";
import usePropertyAlert from "./use-property-alert";

const ConfigurePropertyAlert = (props) => {
  const { modal, children } = props;
  const { isVisible, ...propertyAlertProps } = usePropertyAlert(modal);

  return isVisible ? children(propertyAlertProps) : null;
};

export default withModal(ConfigurePropertyAlert);
