import classnames from "classnames";
import PropTypes from "prop-types";
import prop from "ramda/src/prop";
import { connect } from "react-redux";
import { actions } from "../../store";
import { noop, notEmptyOrNil } from "../../utils";

const px = (val) => (val ? `${val}px` : null);

export const Tooltip = (props) => {
  const {
    className,
    children = null,
    isHoverable = false,
    isOpen = false,
    left,
    right,
    top,
    bottom,
    position,
    distance,
    tooltipId,
    hide = noop,
  } = props;

  const tooltipClass = classnames("tooltip", className, {
    tooltip__active: isOpen,
  });

  const tailClasses = classnames("tooltip__tail", `tooltip__${position}`);

  const truePosition = {
    left: calculateLeft({ left, position, distance }),
    right,
    top: calculateTop({ top, position, distance }),
    bottom,
  };

  const anchorPosition = {
    left: px(truePosition.left),
    right: px(truePosition.right),
    top: px(truePosition.top),
    bottom: px(truePosition.bottom),
  };

  const tailWrapperClass = classnames("tooltip__tail-wrapper", {
    [`tooltip__${position}`]: notEmptyOrNil(position),
  });
  const anchorClass = classnames("tooltip__anchor", "tooltip__entry", {
    [`tooltip__${position}`]: notEmptyOrNil(position),
  });

  const hideOnMouseLeave = () => {
    if (isHoverable) {
      hide();
    }
  };

  return (
    <div
      onMouseLeave={hideOnMouseLeave}
      className={anchorClass}
      style={anchorPosition}
    >
      <div className={tooltipClass}>
        <div className={tailWrapperClass}>
          <div className={tailClasses} />
        </div>
        <div className="tooltip__children" role="tooltip" id={tooltipId}>
          {children}
        </div>
        {isHoverable && <div className="tooltip__gap" />}
      </div>
    </div>
  );
};

Tooltip.displayName = "Tooltip";

Tooltip.propTypes = {
  className: PropTypes.string,
  isHoverable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  isOpen: PropTypes.bool,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  bottom: PropTypes.number,
  distance: PropTypes.number,
  tooltipId: PropTypes.string,
};

const mapStateToProps = prop("tooltip");

const mapDispatchToProps = (dispatch) => ({
  hide: () => dispatch(actions.tooltip.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);

// -------------------------------------------------------------------- Private

function calculateLeft({ left, position, distance }) {
  if (position === "left") {
    return left - distance;
  }

  if (position === "right") {
    return left + distance;
  }

  return left;
}

function calculateTop({ top, position, distance }) {
  if (position === "top") {
    return top - distance;
  }

  if (position === "bottom") {
    return top + distance;
  }

  return top;
}
