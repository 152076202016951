import isNil from "ramda/src/isNil";
import { useEffect } from "react";
import { hasLoaded, hasError, isInit } from "../../constants/status";
import { useNotifications } from "../../hooks/use-notifications";
import { extractAPIError } from "../../utils/extract-api-error";
import { Container } from "./_pay.container";
import { Title } from "./_pay.title";
import { PlanInfo } from "./pay.plan-info";
import { useCreateSubscription } from "./use-create-subscription";

const notification = {
  success: "Your subscription is now active. An email receipt has been sent.",
};

export const PayFree = (props) => {
  const pushNotification = useNotifications();
  const { create, status, error } = useCreateSubscription();
  const { plan, next, prev, organizationName: orgName } = props;
  const hasNoPlan = isNil(plan);
  const invalidOrgName = isNil(orgName) || orgName.length === 0;
  const organizationName = invalidOrgName ? {} : { organizationName: orgName };

  if (hasNoPlan) {
    prev();

    return null;
  }

  useEffect(() => {
    if (isInit(status)) {
      create({ planCode: plan.planCode, ...organizationName });
    }

    if (hasLoaded(status)) {
      pushNotification({ message: notification.success });
      next();
    }

    if (hasError(status)) {
      pushNotification({ message: extractAPIError(error) });
      prev();
    }
  }, [status]);

  return (
    <Container>
      <Title>Subscribing...</Title>
      <PlanInfo plan={plan} />
    </Container>
  );
};
