import not from "ramda/src/not";
import { Fragment } from "react";
import { Capacity } from "../account-settings.subscriptions.common/subscription.capacity";
import { Price } from "../account-settings.subscriptions.common/subscription.price";
import { CancelButton } from "./_subscriptions.button.cancel";

export const SingleUserActions = (props) => {
  const { subscription: sub, cancel, changeButton, renewDate } = props;

  return (
    <Fragment>
      <Capacity>1</Capacity>
      <Price amount={sub.amount} period={sub.period} label={sub.description} />
      {renewDate}
      {not(sub.isAuthorized) && changeButton}
      {<CancelButton onClick={cancel}>Cancel Subscription</CancelButton>}
    </Fragment>
  );
};
