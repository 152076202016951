import { useCallback, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "../../hooks";
import { isNotNil } from "../../utils";

const ResizeObserver =
  typeof window !== "undefined" ? window.ResizeObserver : function () {};

let observer = null;

const getCanvasDimensions = ({ rotation, width, height }) => {
  const relativeRotation = Math.abs(rotation || 0) % 360;

  return relativeRotation === 0 || relativeRotation === 180
    ? { width: Math.max(0, width), height: Math.max(0, height) }
    : { width: Math.max(0, height), height: Math.max(width, 0) };
};

export default ({ rotation }) => {
  const [canvas, setCanvas] = useState(null);
  const node = useRef(null);

  const container = useCallback((el) => {
    if (el) {
      observer = new ResizeObserver(([element]) => {
        const container = element.contentRect;
        const navigation = element.target.children
          .item(0)
          ?.getBoundingClientRect();
        if (container && navigation) {
          const width = Math.floor(container.width);
          const height = Math.floor(container.height - navigation.height);
          const canvas = getCanvasDimensions({
            rotation,
            width,
            height,
          });
          setCanvas({
            ...canvas,
            viewPort: { width, height },
          });
        }
      });

      observer.observe(el);
      node.current = el;
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    return () => {
      if (isNotNil(observer)) {
        observer.unobserve(node.current);
        observer = null;
      }
    };
  }, []);

  return {
    container,
    canvas,
  };
};
