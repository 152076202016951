import { css } from "@emotion/react";
import { useContext, useState } from "react";
import { ModalShell } from "../../components";
import { withModal } from "../../hoc";
import { noop } from "../../utils";
import { ChoosePlan } from "../account-settings.subscriptions.common/choose-plan";
import { Date } from "../account-settings.subscriptions.common/date";
import { Capacity } from "../account-settings.subscriptions.common/subscription.capacity";
import { Price } from "../account-settings.subscriptions.common/subscription.price";
import { CancelChangeButton } from "./_subscriptions.button.cancel-change";
import { Subscription } from "./_subscriptions.container";
import { Title } from "./_subscriptions.title";
import NavContext from "./context.nav";
import { EditModal } from "./modal.edit";

const style = {
  choosePlan: css`
    border-top: 1px solid #dadde2;
    margin-top: 40px;
    padding-top: 20px;
    grid-area: choose-plan;
  `,
  bottomCancel: css`
    grid-area: trailing;
  `,
};

export const Editing = withModal(({ modal, subscription, onChange }) => {
  const [orgName, setOrgName] = useState("");
  const { amount, description, endDate, title, period } = subscription;
  const nav = useContext(NavContext);

  const change = (plan) => {
    modal.open(() => (
      <ModalShell close={modal.close}>
        <EditModal plan={plan} onChange={onChange} nav={nav} />
      </ModalShell>
    ));
  };

  return (
    <Subscription>
      <Title>{title}</Title>
      <Capacity>1</Capacity>
      <Price amount={amount} period={period} label={description} />
      <Date date={endDate}>{(formatted) => `Renews on ${formatted}`}</Date>
      <CancelChangeButton onClick={nav.goToSubscriptions} />
      <ChoosePlan
        currentSub={subscription}
        select={change}
        next={noop}
        css={style.choosePlan}
        planCode={subscription.planCode}
        setName={setOrgName}
        organizationName={orgName}
      />
      <CancelChangeButton
        onClick={nav.goToSubscriptions}
        css={style.bottomCancel}
      />
    </Subscription>
  );
});
