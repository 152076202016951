import { css } from "@emotion/react";
import isEmpty from "ramda/src/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import Announcement from "./announcement";

const style = css`
  grid-area: announcements;
`;

const Announcements = (props) => {
  const dispatch = useDispatch();
  const announcements = useSelector(selectors.announcements.getAnnouncements);

  const clear = (index) => () => {
    dispatch(actions.announcements.clearAnnouncement(index));
  };

  if (isEmpty(announcements) || announcements === undefined) {
    return null;
  }

  return (
    <div className="announcements" css={style}>
      {announcements.map(([, announcement], index) => (
        <Announcement
          {...props}
          key={index}
          announcement={announcement}
          clear={clear(index)}
        />
      ))}
    </div>
  );
};

export default Announcements;
