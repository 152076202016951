import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import notificationActions from "../actions/notifications";
import { types } from "../reducers/download";

export const notifyOnDownload$ = (action$) =>
  action$.pipe(
    ofType(types.downloadUrl),
    map(() =>
      notificationActions.addNotification({
        message: "1 document has been downloaded.",
      })
    )
  );

export const notifyOnDownloadError$ = (action$) =>
  action$.pipe(
    ofType(types.errorFetchingDownloadUrl),
    map(({ payload }) => {
      if (isNil(prop("userIsVerified", payload))) {
        return notificationActions.addNotification({
          message: "Error downloading document. Please try again later.",
        });
      }
    })
  );

export default [notifyOnDownload$, notifyOnDownloadError$];
