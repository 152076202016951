import always from "ramda/src/always";
import cond from "ramda/src/cond";
import equals from "ramda/src/equals";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import isEmpty from "ramda/src/isEmpty";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import not from "ramda/src/not";
import pipe from "ramda/src/pipe";
import prop from "ramda/src/prop";
import reduce from "ramda/src/reduce";
import reduced from "ramda/src/reduced";
import T from "ramda/src/T";
import toPairs from "ramda/src/toPairs";
import { createElement } from "react";
import validator from "validator";
import Field from "./Field";
import fieldMapping from "./fieldMapping";

const e = createElement;
const noError = "";

const validateNumberRange = (commaSeparatedNumbers) => {
  const split = commaSeparatedNumbers.split(",");
  const firstValue = split[0];
  const secondValue = split[1];

  if (!isEmpty(firstValue) && !isEmpty(secondValue)) {
    return parseInt(firstValue, 10) < parseInt(secondValue, 10);
  }

  return false;
};

const runSingleValidation = (
  { method, arg, error, numberRange = false },
  value
) => {
  const isRequired = pipe(prop("method"), equals("isRequired"));
  const isNumberRange = prop("numberRange");

  const valueNotEmpty = pipe(prop("value"), isEmpty, not);

  const validateRequired = pipe(
    prop("value"),
    cond([
      [isEmpty, always("This field is required.")],
      [T, always(noError)],
    ])
  );

  const runValidator = () =>
    method && validator[method](value, arg) ? noError : error;

  const numberRangeResponse = pipe(
    prop("value"),
    ifElse(validateNumberRange, always(noError), always(error)),
    ifElse(isEmpty, runValidator, identity)
  );

  const runValidateNumberRange = cond([
    [isNumberRange, numberRangeResponse],
    [T, runValidator],
  ]);

  const singleValidation = cond([
    [isRequired, validateRequired],
    [valueNotEmpty, runValidateNumberRange],
    [T, always(noError)],
  ]);

  return singleValidation({ method, numberRange, value });
};

export const runValidationSet = (validations, value, fieldProps) =>
  reduce(
    (carry, validation) => {
      const isCustomFunction = typeof validation === "function";
      const errorMessage = isCustomFunction
        ? validation(value, fieldProps)
        : runSingleValidation(validation, value);

      return errorMessage ? reduced(errorMessage) : noError;
    },
    noError,
    validations
  );

export const validate = (validations, value, fieldProps) =>
  validations ? runValidationSet(validations, value, fieldProps) : noError;

export const checkIfFormValid = (config, state, action) =>
  toPairs(state).reduce((acc, [key, obj]) => {
    const { value = "" } = obj;
    const formConfig = config[key];
    const validations = formConfig.validations;
    const error = validate(validations, value, config[key]);

    action(key)(error);

    return acc && isEmpty(error);
  }, true);

const constructField = (state, actions) => (configProps, key) => {
  const applyKey = (func) => func(key);
  const mappedActions = mapObjIndexed(applyKey, actions);

  // Default to party field
  const defaultType = "TokenizedSelect";

  const mergedProps = {
    type: defaultType,
    ...state[key],
    ...mappedActions,
    ...configProps,
    ...fieldMapping[key],
  };

  return e(Field, { ...mergedProps, key, id: key });
};

export const buildForm = (config, state, actions) =>
  mapObjIndexed(constructField(state, actions), config);
