import classnames from "classnames";
import PropTypes from "prop-types";
import contains from "ramda/src/contains";
import pluck from "ramda/src/pluck";
import { Checkbox } from "../../../../../../../components";

const Option = (props) => {
  const {
    options,
    category,
    value,
    hits,
    selections,
    addSelections,
    removeSelections,
    id,
    isOnlySelection,
  } = props;

  const isSelected =
    contains(value, selections) || contains(category, selections);
  const optionValues = pluck("value", options);

  const onClickHandler = () =>
    isSelected
      ? removeSelections(optionValues, category, value)
      : addSelections(optionValues, category, value);

  const textClass = classnames("decade-select-option__text", {
    "decade-select-option__text--active": isSelected,
  });

  const plusMark = isOnlySelection && !isSelected ? "+" : "";

  return (
    <div key={value} className="decade-select-option">
      <Checkbox
        id={`${id}_${value.toString()}`}
        isChecked={isSelected}
        onClick={onClickHandler}
      />
      <span className={textClass}>{value}</span>
      &nbsp;
      <span className="decade-select-option__paren-text">{`(${plusMark}${hits})`}</span>
    </div>
  );
};

Option.displayName = "Option";

Option.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  category: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hits: PropTypes.number.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  addSelections: PropTypes.func.isRequired,
  removeSelections: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOnlySelection: PropTypes.bool,
};

export default Option;
