import React from "react";
import { Helmet } from "react-helmet";

export const ContactSupport = ({ className }) => (
  <React.Fragment>
    <Helmet>
      <title>Contact Support</title>
    </Helmet>
    <div className={className}>
      <h1>We&apos;ve encountered an error. Please contact support.</h1>
    </div>
  </React.Fragment>
);
