import path from "ramda/src/path";
import { useState } from "react";

export const makeRadioOptions = (isParties) => {
  const merge = isParties ? "mergeParties" : "mergeCriteria";
  const copy = isParties ? "names" : "criteria";

  return [
    {
      id: merge,
      value: merge,
      label: `Set my alert for these ${copy} appearing together`,
    },
    {
      id: "default",
      value: "default",
      label: `Set my alert to include any of these ${copy}`,
    },
  ];
};

export const useMergeCriteriaRadio = (isParties) => {
  const [selected, setSelected] = useState(
    isParties ? "mergeParties" : "mergeCriteria"
  );

  const inputProps = {
    onChange: (e) => setSelected(path(["target", "value"], e)),
    value: selected,
  };

  return {
    selected,
    inputProps,
    radioOptions: makeRadioOptions(isParties),
  };
};
