import { types as syncTypes } from "@kofile/ko-search-action-types";
import { exportResults as initialState } from "../initialState";
import types from "../types/export-results";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.REQUEST_SEARCH_RESULT_EXPORT_1]: requestSearchResultExport,
  [syncTypes.REQUEST_SEARCH_RESULT_EXPORT_FULFILLED_1]:
    requestSearchResultExportFulfilled,
  [syncTypes.REQUEST_SEARCH_RESULT_EXPORT_REJECTED_0]:
    requestSearchResultExportRejected,
  [syncTypes.REQUEST_SEARCH_RESULT_EXPORT_CANCELLED_0]:
    requestSearchResultExportCancelled,
  [types.CLEAR]: clear,
});

function requestSearchResultExport(state, { payload }) {
  return {
    ...state,
    id: payload,
    data: {},
    inProgress: true,
    error: null,
  };
}

function requestSearchResultExportFulfilled(state, { payload }) {
  return {
    ...state,
    data: payload,
    inProgress: false,
    error: null,
  };
}

function requestSearchResultExportRejected(state, { payload }) {
  return {
    ...state,
    data: {},
    inProgress: false,
    error: payload,
  };
}

function requestSearchResultExportCancelled(state) {
  return {
    ...state,
    data: {},
    inProgress: false,
    error: null,
  };
}

function clear(state) {
  return {
    ...state,
    data: {},
    inProgress: false,
  };
}
