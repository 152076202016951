import { Chevron } from "@kofile/icons";
import classnames from "classnames";
import PropTypes from "prop-types";

const Button = (props) => {
  const {
    children,
    isOpen,
    onClick,
    style,
    index,
    highlightedIndex,
    itemProps,
  } = props;

  const buttonClass = classnames("tokenized-nested-select__button", {
    "tokenized-nested-select__button--highlighted": highlightedIndex === index,
  });

  const buttonOnClick = () => onClick(index);

  return (
    <button
      {...itemProps}
      className={buttonClass}
      onClick={buttonOnClick}
      style={style}
      type="button"
    >
      <Chevron
        id="tns-chevron-icon"
        className="icon--small"
        rotation={isOpen ? 0 : 270}
      />
      <span className="tokenized-nested-select__button-text">{children}</span>
    </button>
  );
};

Button.displayName = "Button";
Button.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number,
  style: PropTypes.object,
  itemProps: PropTypes.object,
};

export default Button;
