import { css } from "@emotion/react";
import { LoadingSpinner, ConfiguredHTMLContent } from "../../../components";
import { isAbsent } from "../../../utils";
import { ContentLink } from "./content-panel.links";
import { useFetchContentPanel } from "./use-fetch-content-panel";

const styles = {
  container: (isEmpty) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    grid-area: sidebar;
    padding: ${isEmpty ? "0" : "20px"};
    margin-top: 10px;
    margin-left: 10px;
    border-radius: var(--primitive-6);
    overflow-y: scroll;
    max-width: 400px;
  `,
  html: css`
    margin-top: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 10px 0;
      font-weight: 600;
      font-size: 1rem;
    }

    h1 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1.1rem;
    }
  `,
};

const SearchContentPanel = () => {
  const { content, isLoading } = useFetchContentPanel();

  if (isLoading) return <LoadingSpinner />;

  return (
    <div css={styles.container(isAbsent(content))}>
      {content?.links &&
        content?.links.map((link, i) => (
          <ContentLink key={`content-panel-link-${i}`} link={link} />
        ))}

      {content?.html && (
        <ConfiguredHTMLContent html={content?.html} customStyle={styles.html} />
      )}
    </div>
  );
};

export default SearchContentPanel;
