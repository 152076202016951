import styled from "@emotion/styled";

export const Image = styled.div`
  grid-area: picture;
  min-height: 300px;
  padding-right: 25px;

  & img {
    max-width: 90%;
  }
`;
