import { LoadingSpinner } from "../../../../../components";

const TableLoading = ({ searchText = "" }) => (
  <div className="table-loading">
    <LoadingSpinner />
    <h1 className="loading__text">
      {`${searchText}`}
      ...
    </h1>
  </div>
);

TableLoading.displayName = "TableLoading";

export default TableLoading;
