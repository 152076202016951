const formConfig = {
  email: {
    id: "email",
    type: "EmailInput",
    ariaLabel: "Email",
    placeholder: "Email",
    fieldId: "sign-in-input-email",
    autoFocus: true,
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "isEmail",
        error: "Input must be a valid email",
      },
    ],
  },
};

export default formConfig;
