import { useSelector } from "react-redux";
import { DropdownMenu } from "../../../../components";
import { selectors } from "../../../../store";
import { DropdownLink } from "./_dropdown-link";

const navigate = (url) => void window.open(url, "_blank");

const ExternalLinks = () => {
  const externalLinks = useSelector(selectors.configuration.getExternalLinks);
  const optionRenderer = ({ url, label, onClick = () => {} }) => {
    const click = () => {
      navigate(url);
      onClick();
    };
    return <DropdownLink onClick={click}>{label}</DropdownLink>;
  };

  return (
    <DropdownMenu
      label="Other Services"
      options={externalLinks}
      optionRenderer={optionRenderer}
    />
  );
};

export default ExternalLinks;
