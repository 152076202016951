import { Redirect, Route, Switch } from "react-router-dom";
import { Stripe } from "../../../../components";
import { withSSRRenderGuard } from "../../../../hoc";
import { OrderHistory } from "../../../../units/account-settings.history";
import {
  Profile,
  Password,
  Subscriptions,
  Organization,
  Payment,
  FraudAlert,
  FraudAlertMatch,
} from "./views";

const paths = {
  ACCOUNT: "/account",
  FRAUD_ALERT: "/account/property-alert",
  FRAUD_ALERT_MATCH: "/account/property-alert/matches/:matchId",
  ORDER_HISTORY: "/account/history",
  PASSWORD_SETTINGS: "/account/password",
  PAYMENT_INFO: "/account/payment",
  PROFILE_SETTINGS: "/account/profile",
  SUBSCRIPTIONS: "/account/subscription",
  ORGANIZATION_INFO: "/account/organization",
};

export const SettingsView = ({ organizationEnabled }) => (
  <Switch>
    <Route path={paths.PROFILE_SETTINGS}>
      <Profile />
    </Route>
    <Route exact path={paths.PASSWORD_SETTINGS}>
      <Password />
    </Route>
    <Route path={paths.ORDER_HISTORY}>
      <OrderHistory isOrganizationAdmin={organizationEnabled} />
    </Route>
    <Route path={paths.SUBSCRIPTIONS}>
      <Subscriptions isOrganizationAdmin={organizationEnabled} />
    </Route>
    <Route path={paths.ORGANIZATION_INFO}>
      <Organization organizationEnabled={organizationEnabled} />
    </Route>
    <Route exact path={paths.PAYMENT_INFO}>
      <Stripe>
        <Payment />
      </Stripe>
    </Route>
    <Route exact path={paths.FRAUD_ALERT}>
      <FraudAlert />
    </Route>
    <Route exact path={paths.FRAUD_ALERT_MATCH}>
      <FraudAlertMatch />
    </Route>
    <Redirect from={paths.ACCOUNT} to={paths.PROFILE_SETTINGS} />
  </Switch>
);

export default withSSRRenderGuard(SettingsView);
