import { types as syncTypes } from "@kofile/ko-search-action-types";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import find from "ramda/src/find";
import isEmpty from "ramda/src/isEmpty";
import lensPath from "ramda/src/lensPath";
import lensProp from "ramda/src/lensProp";
import map from "ramda/src/map";
import merge from "ramda/src/merge";
import reject from "ramda/src/reject";
import set from "ramda/src/set";
import toPairs from "ramda/src/toPairs";
import { cart as initialState } from "../initialState";
import internalTypes, { types } from "../types/cart";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.userShoppingCart]: syncCartEntity, // add fetch cart error handling
  [syncTypes.FETCH_CART_FULFILLED_3]: syncCartEntity,
  [types.addedToCart]: syncCartEntity,
  [types.cartCleared]: syncCartEntity,
  [types.removedFromCart]: syncCartEntity,
  [syncTypes.NON_INTERACTIVE_SIGN_IN_FULFILLED_2]: (state) => ({
    ...state,
    isFresh: true,
  }),
  [internalTypes.MAKE_CART_STALE]: (state) => ({
    ...state,
    isFresh: false,
  }),
  [internalTypes.MAKE_CART_FRESH]: (state) => ({
    ...state,
    isFresh: true,
  }),
  [internalTypes.REQUEST_EXPRESS_CART]: (state, { payload }) => ({
    ...state,
    express: payload,
  }),
});

export const collapseAttachments = (userCart) => {
  const { byHash, byOrder } = userCart;
  const pairs = toPairs(byHash);
  const documentPairs = pairs.filter(([_, { type }]) => type === "docimage");

  const documentHash = documentPairs.reduce((acc, [key, value]) => {
    return merge(acc, { [key]: set(lensProp("id"), key, value) });
  }, {});

  let attachmentPairs = pairs.filter(([_, { type }]) => type === "attachment");

  const collapsed = documentPairs.reduce((acc, [key, value]) => {
    const runningItemValue = acc[key];

    // If the document doesnt exist yet in the acc, add it
    if (!runningItemValue) {
      return merge(acc, { [key]: value });
    }

    const { docId, includedAttachments = [] } = runningItemValue;

    // attachment belongs to document
    const match =
      find(([_, value]) => value.docId === docId, attachmentPairs) || [];

    const [matchKey, matchValue] = match;
    const matchLens = lensPath([key, "includedAttachments"]);

    // append the match if there is one and remove from attachments
    if (!isEmpty(match)) {
      attachmentPairs = reject(([key, _]) => key === matchKey, attachmentPairs);

      return set(
        matchLens,
        [...includedAttachments, set(lensPath(["id"]), matchKey, matchValue)],
        acc
      );
    }

    return acc;
  }, documentHash);

  const remainingAttachments = attachmentPairs.reduce(
    (acc, [key, value]) => merge(acc, { [key]: value }),
    {}
  );

  const newCartHash = merge(collapsed, remainingAttachments);
  const newIds = compose(
    map(([key]) => key),
    toPairs
  )(newCartHash);

  return {
    byHash: newCartHash,
    byOrder: byOrder.filter((id) => contains(id, newIds)),
  };
};

function syncCartEntity(state, { payload }) {
  return {
    ...state,
    ...payload,
    cartID: payload.id,
    userCart: collapseAttachments(payload.userCart),
  };
}
