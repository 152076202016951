import pathOr from "ramda/src/pathOr";
import { isPresent } from "../../utils";

export const supportDocPresent = (type = "", props) => {
  const { items } = props;

  const hasSupportDoc =
    type === "transcription" ? "hasTranscription" : "hasTranslation";

  return isPresent(items) && pathOr(false, [0, hasSupportDoc], items);
};
