import styled from "@emotion/styled";
import Button from "../common/_button";

export default styled(Button)`
  padding: 5px 10px;
  font-size: 0.8rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;

  img {
    height: 18px;
    margin-right: 5px;
    ${(props) => props.makeIconWhite && "filter: brightness(0) invert(1);"}
  }
`;
