import PropTypes from "prop-types";
import isEmpty from "ramda/src/isEmpty";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const ErrorBar = ({ error = "" }) => {
  const warningIcon = useSelector(
    selectors.assets.getImg("icon_warning_triangle.svg")
  );

  return isEmpty(error) ? null : (
    <div className="error-bar">
      <img className="error-bar__icon" src={warningIcon} alt="warning" />
      <span className="error-bar__text">{error}</span>
    </div>
  );
};

ErrorBar.displayName = "Error";

ErrorBar.propTypes = {
  error: PropTypes.node,
};

export default ErrorBar;
