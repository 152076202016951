import styled from "@emotion/styled";
import { colors } from "../../../../../../../utils";

const isActive = `
  background: ${colors.brightBlue};
  border: none;
`;

export const Button = styled.button`
  align-items: center;
  background: ${colors.white};
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 42px;

  ${(props) => props.active && isActive}

  &:first-of-type {
    border-bottom-left-radius: 3px;
    border-color: rgba(4, 29, 68, 0.15);
    border-style: solid;
    border-top-left-radius: 3px;
    border-width: 1px 0 1px 1px;
  }

  &:last-of-type {
    border-bottom-right-radius: 3px;
    border-color: rgba(4, 29, 68, 0.15);
    border-style: solid;
    border-top-right-radius: 3px;
    border-width: 1px 1px 1px 0;
  }
`;
