import compose from "ramda/src/compose";
import cond from "ramda/src/cond";
import contains from "ramda/src/contains";
import defaultTo from "ramda/src/defaultTo";
import equals from "ramda/src/equals";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import last from "ramda/src/last";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import reject from "ramda/src/reject";
import toUpper from "ramda/src/toUpper";
import { createSelector } from "reselect";
import { DOC_PREVIEW } from "../../constants";
import { documentPropGetter } from "../../services";
import { selectors } from "../../store";
import { getDocPreviewSummary, getDocTypeLookupTable } from "./configuration";

export const searchTabs = [
  {
    id: DOC_PREVIEW.VIEW_TYPES.IMAGE,
    title: "IMAGE",
    path: "/",
    isActive: false,
    isLink: false,
  },
  {
    id: DOC_PREVIEW.VIEW_TYPES.SUMMARY,
    title: "SUMMARY",
    path: "/",
    isActive: false,
    isLink: false,
  },
  {
    id: DOC_PREVIEW.VIEW_TYPES.ATTACHMENTS,
    title: "ATTACHMENTS",
    path: "/",
    isActive: false,
    isLink: false,
  },
  {
    id: DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE,
    title: "ATTACHMENT IMAGE",
    path: "/",
    isActive: false,
    isLink: false,
    exIcon: true,
  },
];

export const getDocPreview = propOr({}, "docPreview");

export const getDocPreviewSettings = compose(
  propOr({}, "previewSettings"),
  getDocPreview
);

export const getViewed = compose(propOr([], "viewed"), getDocPreview);

export const getIsDocumentViewed = (state, id) =>
  compose(contains(id), getViewed)(state);

export const getDocument = compose(prop("document"), getDocPreview);

export const getDocumentData = compose(propOr({}, "data"), getDocument);

export const hasTranslation = compose(
  propOr(false, "hasTranslation"),
  getDocumentData
);

export const hasTranscription = compose(
  propOr(false, "hasTranscription"),
  getDocumentData
);

export const getDocumentSummary = createSelector(
  [
    getDocumentData,
    (state, dept) => getDocPreviewSummary(state, dept),
    getDocTypeLookupTable,
  ],
  (documentData = {}, summaryConfig, docTypeLookupTable) => {
    const { details = [], groups = [] } = summaryConfig;
    const docType = documentData.docType || "";
    const title = defaultTo("", (docTypeLookupTable || {})[docType]);
    const getDocProp = documentPropGetter(documentData || {});

    const summary = {
      title,
      groups: groups.map(getDocProp),
      columns: details.map(getDocProp),
      id: documentData.id,
      truncatedCount: documentData.truncatedCount,
    };

    return summary;
  }
);

export const getIsLoading = compose(prop("isLoading"), getDocPreview);

export const getStatus = compose(prop("status"), getDocPreview);

export const getActiveMarginalReference = compose(
  defaultTo(null),
  last,
  propOr([], "marginalReferencesViewed"),
  getDocPreview
);

export const getDocumentID = createSelector(
  [getDocument, getActiveMarginalReference],
  (doc, margRef) => margRef || propOr(null, "id", doc)
);

export const getDocumentNumber = compose(
  propOr(null, "docNumber"),
  getDocumentData
);

export const getCurrentPage = compose(prop("currentPage"), getDocPreview);

export const getDocumentAttachments = compose(
  propOr([], "attachments"),
  getDocumentData
);

export const getDocumentAttachmentById = (state, attachmentId) => {
  const attachments = compose(
    propOr([], "attachments"),
    getDocumentData
  )(state);

  return attachments.find(({ id }) => id === attachmentId) || {};
};

export const getActiveAttachment = compose(
  prop("activeAttachment"),
  getDocument
);

export const getAvailableTabs = createSelector(
  [
    getDocumentData,
    getActiveAttachment,
    (state) => selectors.workspaces.getSelectedDepartmentConfig(state),
  ],
  (docData, activeAttachment, { docPreviewConfig = {} }) => {
    const { showImages = true, showAttachments = true } = docPreviewConfig;
    const { imageUrls = [], isSecured, attachments = [] } = docData;

    return reject(
      ({ id }) =>
        cond([
          [
            equals(DOC_PREVIEW.VIEW_TYPES.IMAGE),
            () => imageUrls.length === 0 || !showImages,
          ],
          [
            equals(DOC_PREVIEW.VIEW_TYPES.ATTACHMENTS),
            () => isEmpty(attachments) || !showAttachments || isSecured,
          ],
          [
            equals(DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE),
            () => !activeAttachment || !showAttachments || isSecured,
          ],
        ])(id),
      searchTabs
    );
  }
);

export const getActiveView = createSelector(
  [getDocPreview, getAvailableTabs],
  ({ activeView }, availableTabs) => {
    const availableViews = availableTabs.map(prop("id"));
    const defaultView = head(availableTabs).id;

    if (!contains(activeView, availableViews)) {
      return defaultView;
    }

    return activeView || defaultView;
  }
);

export const getDocumentThumbnails = createSelector(
  [getActiveView, getDocumentData],
  (activeView, docData) => {
    const { imageThumbnailUrls = [], attachmentThumbnailUrls = [] } = docData;

    return {
      [DOC_PREVIEW.VIEW_TYPES.IMAGE]: imageThumbnailUrls,
      [DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE]: attachmentThumbnailUrls,
    }[activeView];
  }
);

export const getTabs = createSelector(
  [getAvailableTabs, getActiveView, getIsLoading],
  (tabs, activeView, isLoading) => {
    return tabs.map((tab) => {
      if (activeView === tab.id) {
        return { ...tab, isActive: true, isDisabled: isLoading };
      }

      return tab;
    });
  }
);

export const getDetailTabs = createSelector(
  [getAvailableTabs, getActiveView, getIsLoading],
  (tabs, activeView, isLoading) => {
    return tabs
      .filter((tab) => tab.id !== "image")
      .map((tab) => {
        const fakeActive = activeView === "image" ? "summary" : activeView;

        if (fakeActive === tab.id) {
          return { ...tab, isActive: true, isDisabled: isLoading };
        }

        return tab;
      });
  }
);

export const getNumImages = createSelector(
  [getActiveView, getDocumentData, getActiveAttachment],
  (activeView, docData, attachmentID) => {
    const { imageUrls = [], attachments = [] } = docData;
    const attachmentUrls = getUrlsFromAttachment(attachments, attachmentID);

    const images =
      {
        [DOC_PREVIEW.VIEW_TYPES.IMAGE]: imageUrls,
        [DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE]: attachmentUrls,
      }[activeView] || imageUrls;

    return images.length;
  }
);

export const getOCRHitsForCurrentPage = createSelector(
  [getDocumentData, getCurrentPage, getActiveView],
  (docData, page, activeView) =>
    activeView === DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE
      ? []
      : pathOr([], ["ocrHits", "pages", page - 1], docData)
);

export const getOCRHitTotal = createSelector(
  [getDocumentData, getActiveView],
  (docData, activeView) =>
    activeView === DOC_PREVIEW.VIEW_TYPES.IMAGE
      ? pathOr(0, ["ocrHits", "count"], docData)
      : 0
);

export const getCurrentImage = createSelector(
  [getActiveView, getDocumentData, getActiveAttachment, getCurrentPage],
  (activeView, docData, attachmentID, page) => {
    const { imageUrls = [], attachments = [] } = docData;
    const attachmentUrls = getUrlsFromAttachment(attachments, attachmentID);

    const images =
      activeView === DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE
        ? attachmentUrls
        : imageUrls;

    return images[page - 1] || "";
  }
);

export const getIsImageView = createSelector([getActiveView], (activeView) =>
  contains(activeView, [
    DOC_PREVIEW.VIEW_TYPES.IMAGE,
    DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE,
  ])
);

export const getIsThumbnailView = createSelector(
  [getActiveView],
  (activeView) =>
    contains(activeView, [
      DOC_PREVIEW.VIEW_TYPES.IMAGE,
      DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE,
      DOC_PREVIEW.VIEW_TYPES.DOCUMENT_THUMBNAIL,
      DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_THUMBNAIL,
    ])
);

export const getError = compose(prop("error"), getDocPreview);

export const getIsMarginalReference = compose(
  prop("isMarginalReference"),
  getDocument
);

export const getPreviewOpen = compose(prop("previewOpen"), getDocPreview);

export const getActiveDocumentID = createSelector(
  [getDocumentID, getPreviewOpen],
  (docID, previewOpen) => {
    if (!previewOpen) {
      return null;
    }

    return docID;
  }
);

export const getDepartment = compose(
  toUpper,
  defaultTo(""),
  prop("department"),
  getDocumentData
);

function getUrlsFromAttachment(attachments, attachmentID) {
  const attachment = attachments.find(({ id }) => id === attachmentID);

  return propOr([], "imageUrls", attachment);
}
