import prop from "ramda/src/prop";
import { useEffect } from "react";
import { LoadingSpinner } from "../../components";
import { hasLoaded } from "../../constants/status";
import { isNotNil } from "../../utils";
import { chooseViewFor } from "../../utils/choose-view-for";
import { ContactSupport } from "../common/contact-support";
import { MemberList } from "./member-list";
import { useMembers } from "./use-members";

export const Organization = ({ setOrganizationName }) => {
  const { data, status, error, initiate: refetchMemberList } = useMembers();

  useEffect(() => {
    const hasName = isNotNil(prop("organizationName", data));

    if (hasName && hasLoaded(status)) {
      setOrganizationName(data.organizationName);
    }
  }, [data, status]);

  return chooseViewFor(status).when({
    isInit: () => <LoadingSpinner />,
    isLoading: () => <LoadingSpinner />,
    hasLoaded: () => (
      <MemberList {...data} refetchMemberList={refetchMemberList} />
    ),
    default: () => <ContactSupport error={error} />,
  });
};

export default Organization;
