import React from "react";
import ReactDOM from "react-dom";

const announcementRef = React.createRef();

export const A11yAnnouncement = (props) => {
  if (announcementRef.current === null) {
    return null;
  }

  return ReactDOM.createPortal(props.children, announcementRef.current);
};

export const A11yAnnouncementContainer = () => (
  <div
    ref={announcementRef}
    role="status"
    aria-live="polite"
    aria-atomic="true"
    className="a11y-announcement__container"
  />
);
