import styled from "@emotion/styled";

const greyBorder = "border: 1px solid #e5e8ec";

export const List = styled.ul`
  ${(props) => (props.isOpen ? greyBorder : "")}

  & * + * {
    border-top: ${greyBorder};
  }
`;
