import { css } from "@emotion/react";
import prop from "ramda/src/prop";
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../../components/index.js";
import { default as Receipt } from "../../../components/order-receipt";

const style = css`
  margin-left: 60px;
  margin-top: 40px;
`;

const OrderReceipt = () => {
  const { orderId } = useParams();
  const history = useHistory();

  return (
    <React.Fragment>
      <Helmet>
        <title>Order Receipt</title>
      </Helmet>
      <Button css={style} onClick={() => history.goBack()}>
        &lt; Back to Previous Page
      </Button>
      <Receipt id={orderId} fresh kind={prop("Compressed")} />
    </React.Fragment>
  );
};

export default OrderReceipt;
