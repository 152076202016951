import styled from "@emotion/styled";
import Tooltip from "rc-tooltip";
import { Fragment, useState } from "react";
import { Popup } from "../../../../components";
import { LinkContainer } from "./_link-container";

const Container = styled(LinkContainer)`
  & a {
    padding-right: 10px;
  }
`;

const UserTooltip = (props) => {
  const [hovering, setHovering] = useState(false);

  let timeoutId;

  const tooltipMessage = `Click on  your user name to update your profile, change your password,
  view your Order History, re-download any purchased document, re-print a receipt,
  update your credit card or view/update your Property Alerts`;

  const tooltipHeader = `View/Modify your Profile`;

  const mouseEnter = () => {
    setHovering(true);
    clearTimeout(timeoutId);
  };

  const mouseLeave = () => {
    timeoutId = setTimeout(() => setHovering(false), 250);
  };

  return (
    <Fragment>
      <Tooltip
        visible={hovering}
        placement="bottomLeft"
        overlay={
          <Popup
            bodyText={tooltipMessage}
            headerText={tooltipHeader}
            buttonText={""}
            onClear={mouseLeave}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          />
        }
      >
        <Container onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          {props.children}
        </Container>
      </Tooltip>
    </Fragment>
  );
};

export default UserTooltip;
