import or from "ramda/src/or";
import prop from "ramda/src/prop";
import { ENTITY_TYPES } from "../../constants";
import { isPresent } from "../../utils";
import { checkToken, createToken, getResponse } from "../../utils/stripe";
import formatItemsByType from "./submit-payment.format-items";

const checkOrderIsFree = (order) => prop("totalPrice", order) === 0;

const submitPayment = (props) => (event) => {
  event.preventDefault();
  event.stopPropagation();

  if (props.formDisabled || props.ncourtEnabled) return;

  props.setFormDisabled(true);

  const {
    common,
    info,
    card,
    form,
    expressQuote,
    expressCheckout,
    userEnteredNewCard,
    stripe,
  } = props;

  const isExport = common.entityType === ENTITY_TYPES.EXPORT;

  const orderIsFree = isExport
    ? checkOrderIsFree(info)
    : checkOrderIsFree(info) || checkOrderIsFree(expressQuote);

  const userHasCard = isPresent(card.info);
  const useSavedCard = card.saved;

  const chargeWithSavedCard =
    userEnteredNewCard !== true &&
    useSavedCard === true &&
    userHasCard === true;

  const quickCharge = or(chargeWithSavedCard, orderIsFree);

  const itemInfo = {
    items: common.items,
    form,
    department: isPresent(common.items[0].department)
      ? common.items[0].department
      : common.department,
    pageCount: isExport
      ? info.totalPagesSelected
      : props?.pageSelection?.totalPages,
  };

  const items = formatItemsByType(common.entityType, itemInfo);

  if (quickCharge) {
    expressCheckout({ items, department: common.department });
  } else {
    createToken({ stripe, type: "card" })
      .then(checkToken)
      .then(getResponse(props))
      .then(({ token }) => {
        expressCheckout({
          cardToken: token.id,
          items,
          rememberCard: props.rememberPayment,
          department: common.department,
        });
      })
      .catch(props.setFormError);
  }
};

export default submitPayment;
