import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "../../../../../../../components";
import { selectors } from "../../../../../../../store";

const DEFAULT_SEARCH_PATH = "/";

export const EditSearch = ({ query, workspaceID }) => {
  const pencilIcon = useSelector(selectors.assets.getImg("icon_pencil.svg"));

  const { searchType = "quickSearch" } = query;
  const searchPath = {
    quickSearch: "/",
    advancedSearch: "/search/advanced",
  }[searchType];

  return (
    <Link
      to={searchPath || DEFAULT_SEARCH_PATH}
      className="search-results-header__edit-search"
      workspaceID={workspaceID}
      dataTourId="editSearch"
    >
      <div className="search-results-header__flex-wrap">
        <img
          alt="pencil"
          src={pencilIcon}
          className="search-results-header__pencil-icon"
        />
        Edit Search Criteria
      </div>
    </Link>
  );
};

EditSearch.displayName = "EditSearch";

export default withRouter(EditSearch);
