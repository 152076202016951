import { useAPI } from "../../hooks/use-api";

export const useSubscriptionAdminCheck = () =>
  useAPI({
    send: "check-if-user-is-subscription-administrator",
    initialPayload: {},
    exportAs: "isEnabled",
    listenFor: {
      success: "subscription-administrator-status",
      failure: "error-checking-if-user-is-subscription-administrator",
    },
  });
