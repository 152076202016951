/* global htmx -- Utilizes Htmx-powered fragments from API */

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";

const style = {
  container: css`
    margin: var(--spacing-2xl) auto;
    width: var(--layout-lg);

    & > * + * {
      margin-top: var(--spacing-lg);
    }

    & a {
      color: var(--primitive-blue-500);
    }
  `,
  content: css`
    background: var(--color-neutral-lightest);
    border: 1px solid var(--color-neutral-300);
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    & h1 {
      font-weight: 600;
      line-height: 1.5;
      font-size: 1.5em;
    }

    & h2 {
      font-weight: 600;
      line-height: 1.5;
      font-size: 1.2em;
    }
  `,
};

export const RefundPolicy = () => {
  useEffect(() => {
    htmx.ajax("GET", "/cb/refund-policy", { target: "#refund-policy" });
  }, []);

  return (
    <div css={style.container}>
      <Helmet>
        <title>Refund Policy</title>
      </Helmet>

      <a href="/cart/contents">Back to Cart</a>

      <div css={style.content} id="refund-policy"></div>
    </div>
  );
};
