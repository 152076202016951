import pick from "ramda/src/pick";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "../../../../../components";
import { actions, selectors } from "../../../../../store";
import { containsAll } from "../../../../../utils";

const AllCheckbox = () => {
  const dispatch = useDispatch();
  const { documents, workspaces } = selectors;
  const documentsOrder = useSelector(documents.getActiveWorkspaceDataOrder);
  const selectedDocuments = useSelector(workspaces.getSelectedDocumentsKeys);
  const documentsHash = useSelector(documents.getActiveWorkspaceDataHash);
  const isAllSelected = containsAll(documentsOrder, selectedDocuments);

  const removeAllDocuments = () =>
    dispatch(actions.workspaces.removeAllDocuments());
  const addAllDocuments = (docs) =>
    dispatch(actions.workspaces.addAllDocuments(docs));

  const onAllSelectionChanged = (event) => {
    event.stopPropagation();
    event.preventDefault();

    isAllSelected
      ? removeAllDocuments()
      : addAllDocuments(pick(documentsOrder, documentsHash));
  };

  return (
    <Checkbox
      id="search-results-all-checkbox"
      isChecked={isAllSelected}
      onChange={onAllSelectionChanged}
    />
  );
};

export default AllCheckbox;
