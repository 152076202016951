import PropTypes from "prop-types";
import { LoadingSpinner } from "../../../../../components";

const Loading = ({ searchText = "" }) => (
  <div className="loading" key="loading">
    <div className="loading__content">
      <LoadingSpinner />
      <h1 className="loading__text">
        {`${searchText}`}
        ...
      </h1>
    </div>
  </div>
);

Loading.displayName = "Loading";
Loading.propTypes = {
  searchText: PropTypes.string,
};

export default Loading;
