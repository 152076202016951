export const LegalDisplayTypes = {
  HIDE: "hide",
  SHOW_FIRST: "show-first",
  SHOW_ALL: "show-all",
};

export const LegalDisplayOptions = [
  { key: LegalDisplayTypes.HIDE, label: "Hide legals" },
  { key: LegalDisplayTypes.SHOW_FIRST, label: "Show first legal only" },
  { key: LegalDisplayTypes.SHOW_ALL, label: "Show all legals" },
];
