import styled from "@emotion/styled";

export const ContentsWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 12rem;
  padding: 16px;
  width: 1000px;

  @media (max-width: 1025px) {
    width: 700px;
  }
`;
