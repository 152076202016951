import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { isAbsent } from "../../utils";

const LENGTH_LIMIT = 65;

const getExtension = (path) => path.split(".").reverse()[0];
const clip = (path) => path.slice(0, LENGTH_LIMIT);

const removeExtension = (path) => {
  return path.substring(0, path.lastIndexOf(".")) || path;
};

const shortenWithEllipses = (path) => {
  return path.length > LENGTH_LIMIT ? `${clip(path)}...` : path;
};

const formatBreadcrumb = (name) => {
  if (isAbsent(name)) return "";

  const snipName = name.split("attachments/")[1] || name;
  return `> ${shortenWithEllipses(snipName)}`;
};

const formatAttachmentName = (name) => name.split("attachments/")[1] || name;

export default (document) => {
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [showSingleAttachment, setShowSingleAttachment] = useState(false);

  useEffect(() => {
    if (pageIndex !== 0) {
      setPageIndex(0);
    }
  }, [attachmentIndex]);

  const attachments = propOr([], "attachments", document);

  const collection = attachments.map((attachment) => ({
    id: attachment.id,
    path: formatAttachmentName(clip(removeExtension(attachment.filePath))),
    extension: getExtension(attachment.filePath),
    urls: attachment.imageUrls,
    totalPages: attachment.totalPages,
  }));

  const currentAttachmentName = collection[attachmentIndex]?.path ?? "";
  const pageCount = attachments[attachmentIndex]?.totalPages ?? 0;

  const openAttachment = (index) => {
    setAttachmentIndex(index);
    setShowSingleAttachment(true);
  };

  const closeAttachment = () => {
    setShowSingleAttachment(false);
    setAttachmentIndex(0);
  };

  return {
    actions: {
      open: openAttachment,
      close: closeAttachment,
    },
    breadcrumbText: formatBreadcrumb(currentAttachmentName),
    collection,
    current: {
      data: collection[attachmentIndex],
      pageCount,
      currentPage: pageIndex,
      setCurrentPage: setPageIndex,
    },
    showSingleAttachment,
  };
};
