import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";
import enhanceWithClickOutside from "react-click-outside";

class Dropdown extends Component {
  handleClickOutside() {
    if (this.props.dropdownOpen) {
      this.props.closeDropdown();
    }
  }

  render() {
    const { dropdownElements, dropdownOpen, baseRight } = this.props;
    const dropdownClass = classnames("select-action-dropdown__dropdown", {
      "select-action-dropdown__dropdown--show": dropdownOpen,
      "select-action-dropdown__base-right": baseRight,
    });

    return (
      <div className="select-action-dropdown">
        <div className={dropdownClass}>{dropdownElements}</div>
      </div>
    );
  }
}

Dropdown.displayName = "Dropdown";
Dropdown.propTypes = {
  dropdownElements: PropTypes.node.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  baseRight: PropTypes.bool.isRequired,
};

export default enhanceWithClickOutside(Dropdown);
