import { css } from "@emotion/react";

export const tab = {
  link: (isActive) => css`
    pointer-events: ${isActive ? "none" : "all"};
    overflow-x: hidden;
    padding: 4px 0 0 12px;

    & > * {
      flex: 1;
    }
  `,
  wrap: css`
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 12px;
  `,
  title: css`
    max-width: 100%;
    font-size: 14px;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  `,
  close: css`
    background: transparent;
    cursor: pointer;
    padding: 0 16px;
  `,
};
