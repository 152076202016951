import { css } from "@emotion/react";
import { viewTypes } from ".";

const headerAreas = `  
  "skip-links"
  "announcements"
  "header"
`;

const standardViewGrid = `
  grid-template-areas: ${headerAreas} "workspaces" "content" "footer";
  grid-template-rows: repeat(4, min-content) auto min-content;
`;

const limitedViewGrid = `
  grid-template-areas: ${headerAreas} "content" "footer";
  grid-template-rows: repeat(3, min-content) auto min-content;
`;

const accountViewGrid = `
  grid-template-areas: ${headerAreas} "content";
  grid-template-rows: repeat(3, min-content) auto;
`;

export default {
  global: (layoutType = viewTypes.STANDARD) => css`
    body {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0;
      min-height: 100vh;
      background-color: #f5f6f7;
    }

    svg {
      fill: currentColor;
    }

    input:disabled {
      background: rgba(4, 29, 68, 0.15);
    }

    a {
      color: rgb(4, 29, 68);
      text-decoration: none;
    }

    button {
      border: 0;
      border-radius: 0;
      cursor: pointer;
    }

    em {
      background-color: #fdf76a;
    }

    #content {
      min-height: 100vh;
      display: grid;
      grid-auto-rows: 0;

      ${layoutType === viewTypes.STANDARD ? standardViewGrid : ""}
      ${layoutType === viewTypes.LIMITED ? limitedViewGrid : ""}
      ${layoutType === viewTypes.ACCOUNT ? accountViewGrid : ""}
    }

    @media print {
      .announcements {
        display: none;
      }
    }

    :root {
      --form-label-color: #364869;
      --form-label-font: 600 0.9rem/1 "Source Sans Pro", sans-serif;

      --form-layout-gap: 0.5rem;
      --form-layout-grid-row-template: min-content;
    }
  `,
  content: (isAccountView) => css`
    grid-area: content;
    ${isAccountView ? "display: grid;" : ""}
  `,
};
