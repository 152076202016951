import { useAPI } from "../../hooks/use-api";

export const useCheckSubscriptionStatus = () =>
  useAPI({
    send: "check-subscription-status",
    initialPayload: {},
    listenFor: {
      success: "subscription-status",
      failure: "error-fetching-subscription-status",
    },
  });
