import { css } from "@emotion/react";
import { useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingSpinner } from "../../../../components";
import { actions, selectors } from "../../../../store";
import { isPresent } from "../../../../utils";

const style = {
  loader: css`
    margin: 100px auto;
    width: 450px;
  `,
};

const FreeCheckout = () => {
  const dispatch = useDispatch();

  const checkout = (data) => dispatch(actions.checkout.purchase(data));

  const history = useHistory();
  const checkoutError = useSelector(selectors.checkout.getError);
  const cartId = useSelector(selectors.cart.getCartId);
  const order = useSelector(selectors.checkout.getOrder);
  const orderIsPaid = useSelector(selectors.checkout.getSuccess);

  useEffect(() => void checkout({ shoppingCartId: +cartId }), [cartId]);

  useEffect(() => {
    if (orderIsPaid === true) {
      history.push(`/cart/checkout-complete/${order.id}`);
    }
  }, [orderIsPaid, order]);

  useEffect(() => {
    const isServerError = checkoutError === "Server error.";

    if (isPresent(checkoutError) && isServerError) {
      dispatch(actions.checkout.makeCheckoutStale());
      history.push("/cart/contents");
    }
  }, [checkoutError]);

  return (
    <Fragment>
      <Helmet>
        <title>Processing Checkout</title>
      </Helmet>

      <div css={style.loader}>
        <LoadingSpinner />
      </div>
    </Fragment>
  );
};

export default FreeCheckout;
