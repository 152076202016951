import head from "ramda/src/head";
import { createSelector } from "reselect";
import { getSelectedDepartment } from "../workspaces/get-selected-department";
import { getDepartments } from "./get-departments";

export const getConfigForDepartment = createSelector(
  getDepartments,
  getSelectedDepartment,
  (departments, selectedDept) =>
    departments.find(({ code }) => code === selectedDept) || head(departments)
);
