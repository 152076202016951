import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../store";
import { SideFilterPlaceholder, TablePlaceholder } from "./components";

const ResultsLoading = ({ departmentCode, loadingText, name }) => {
  let depts = useSelector(selectors.configuration.getDepartments);

  const [searchFilters, setSearchFilters] = useState([]);

  useEffect(() => {
    if (depts.length === 0) return;
    if (!departmentCode) return;

    const filters = depts.find((d) => {
      return d.code === departmentCode;
    });
    if (filters !== undefined) setSearchFilters(filters.searchFilters);
  }, [depts, departmentCode]);

  return (
    <div className="search-results">
      <Helmet>
        <title>Loading Results...</title>
      </Helmet>
      <div className="results-loading__loading-text">{loadingText}</div>
      <div className="results-loading__department">
        <span className="results-loading__department-bold">Department:</span>
        &nbsp;
        {name}
      </div>
      <div className="search-results__container">
        {searchFilters.length > 0 && <SideFilterPlaceholder />}
        <div className="search-results__results-wrap">
          <TablePlaceholder searchText="Loading Results" />
        </div>
      </div>
    </div>
  );
};

ResultsLoading.displayName = "ResultsLoading";

export default ResultsLoading;
