import classnames from "classnames";
import PropTypes from "prop-types";
import isEmpty from "ramda/src/isEmpty";

const ErrorMessage = ({ inputError = "" }) => {
  const errorClass = classnames("error-message", {
    "error-message__hide": isEmpty(inputError),
  });

  return <div className={errorClass}>{inputError}</div>;
};

ErrorMessage.displayName = "Error";

ErrorMessage.propTypes = {
  inputError: PropTypes.string,
};

export default ErrorMessage;
