import defaultTo from "ramda/src/defaultTo";
import equals from "ramda/src/equals";
import lensProp from "ramda/src/lensProp";
import over from "ramda/src/over";
import pipe from "ramda/src/pipe";
import reject from "ramda/src/reject";
import { removeFieldIfEmpty } from "./remove-field-if-empty";

export const remove = (name) => (v) =>
  pipe(
    over(lensProp(name), pipe(defaultTo([]), reject(equals(v)))),
    removeFieldIfEmpty(name)
  );
