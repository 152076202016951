import { css } from "@emotion/react";

const styles = {
  warningIcon: css`
    height: 18px;
    width: 18px;
  `,
  text: css`
    color: #824900;
    font-size: 16px;
    margin-left: 15px;

    a {
      text-decoration: underline;
    }
  `,
  removeButton: css`
    background: none;
    margin-left: auto;
    padding: 0;
  `,
  removeIcon: css`
    height: 15px;
    width: 15px;
  `,
};

export default styles;
