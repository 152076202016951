import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBar, Link, LoadingSpinner } from "../../components";
import { useInterval } from "../../hooks";
import { usePopup } from "../../hooks/use-popup";
import { actions, selectors } from "../../store";
import { isNotNil } from "../../utils";
import { checkoutTypes, prepareCheckout } from "./utils";

export const NCourtPayment = ({ orderIncomplete, props }) => {
  const dispatch = useDispatch();

  const { ncourt, cart } = selectors;
  const cartNotInitiated = !useSelector(ncourt.checkoutInitiated);
  const expressNotInitiated = !useSelector(ncourt.expressCheckoutInitiated);
  const cartId = useSelector(cart.getCartId);
  const orderId = useSelector(ncourt.getOrderId);
  const orderReady = isNotNil(orderId);
  const orderPaid = useSelector(ncourt.isOrderPaid);
  const orderNotPaid = !orderPaid;
  const shouldOpenPopup = useSelector(ncourt.shouldOpenPopup);
  const ncourtUrl = useSelector(ncourt.getCheckoutUrl);
  const popup = usePopup(ncourtUrl, "NCourtPayment");
  const { entityType } = props.common;
  const error = useSelector(ncourt.checkoutError);
  const checkoutType = checkoutTypes[entityType];

  useEffect(() => {
    const [shouldInitialize, payload] = prepareCheckout({
      checkoutType,
      expressNotInitiated,
      cartNotInitiated,
      cartId,
      props,
    });

    if (shouldInitialize) {
      const action = actions.checkout.purchase;

      dispatch(action(payload));
    }
  }, [checkoutType]);

  useEffect(() => void (shouldOpenPopup && popup.open()), [shouldOpenPopup]);

  useEffect(() => {
    if (orderPaid === true) {
      dispatch(actions.cart.clearCart());
    }
  }, [orderPaid]);

  useInterval(() => {
    if (orderNotPaid && orderReady) {
      dispatch(actions.ncourt.isOrderPaid({ id: orderId }));
    }
  }, 5000);

  if (orderNotPaid && popup.closedByUser) {
    orderIncomplete();
    dispatch(actions.ncourt.clear());
  }

  const statusText = orderReady
    ? "An external window should have popped open to collect a credit card payment."
    : "Please wait while we retrieve your information.";

  return (
    <React.Fragment>
      <Helmet>
        <title>Processing Checkout</title>
      </Helmet>
      <div className="ncourt-payment">
        <ErrorBar error={error} />
        {!error && <LoadingSpinner />}
        {!error && <p className="ncourt-payment__text">{statusText}</p>}
        {checkoutType === checkoutTypes.cart && (
          <Link
            className="ncourt-payment__link"
            to="/cart/contents"
            workspaceID="cart"
            dataTourId="cartWidget"
          >
            View Cart
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

NCourtPayment.displayName = "NCourtPayment";

NCourtPayment.defaultProps = {
  orderIncomplete: () => {},
  props: {
    common: {
      entityType: "cart",
    },
  },
};

NCourtPayment.propTypes = {
  orderIncomplete: PropTypes.func.isRequired,
  props: PropTypes.object,
};

export default NCourtPayment;
