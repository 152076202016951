import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import isEmpty from "ramda/src/isEmpty";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { connect } from "react-redux";
import { withProps } from "recompose";
import { selectors } from "../store";
import { noop } from "../utils";
import { default as cartActions } from "./withCartActions";

export const mapStateToProps = (state, props) => {
  const { docId, isDocPreview = false } = props;
  const departmentCode = selectors.workspaces.getSelectedDepartment(state);
  const department = selectors.configuration.getConfigForDepartment(state);
  const documentsHash = selectors.documents.getActiveWorkspaceDataHash(state);
  const documentData = documentsHash[docId];
  const docPreviewData = selectors.docPreview.getDocumentData(state);
  const loggedIn = selectors.user.getLoggedIn(state);
  const docsInCart = selectors.cart.getRegularCartDocs(state);
  const externalCertifications =
    selectors.configuration.getExternalCertProvider(state);
  const previewData = {
    ...docPreviewData,
    imageId: prop("imageId", documentData),
  };

  const formattedDocumentData = isDocPreview ? previewData : documentData;
  const path = ["make", "purchaseUrl"];
  const purchaseUrl = pathOr(noop, path, externalCertifications);

  return {
    deptActions: propOr([], "actions", department),
    shoppingCartId: selectors.cart.getCartId(state),
    inCart: contains(docId, docsInCart),
    documentData: formattedDocumentData,
    departmentCode,
    loggedIn,
    externalCertifications: {
      ...externalCertifications,
      url: purchaseUrl({
        referenceNo: prop("instrumentNumber", documentData),
        description: prop("id", documentData),
        pageCount: prop("pageCount", documentData),
      }),
    },
  };
};

export const withCartActions = (modalChild) =>
  compose(
    cartActions(modalChild),
    withProps((props) => ({
      actions: mapActionsForDoc(props.deptActions, props.documentData, props),
    }))
  );

export const enhance = (modal) =>
  compose(connect(mapStateToProps), withCartActions(modal));

export const actionPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })
);

export default enhance;

export function mapActionsForDoc(
  actions,
  {
    isSecured = false,
    imageId = null,
    isAttachment = false,
    imageUrls = [],
  } = {},
  props
) {
  const labels = {
    addAttachmentToCart: "Add Attachment To Cart",
    addToCart: isAttachment ? "Add Document To Cart" : "Add To Cart",
    expressCheckout: "Express Checkout",
    download: "Download (Free)",
    purchaseCertifiedCopy: "Purchase Electronic Certified Copy",
  };

  const actionsToMap = isAttachment
    ? ["addAttachmentToCart", ...actions]
    : actions;

  const noImages = props.isDocPreview ? isEmpty(imageUrls) : imageId === null;

  if (noImages || isSecured) return [];

  return actionsToMap.map((actionName) => ({
    value: actionName,
    action: props[actionName],
    label: propOr("", actionName, labels),
  }));
}
