// mod: doc-preview

import pathOr from "ramda/src/pathOr";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isAbsent, isNotNil } from "../../utils";
import useAddSupportDocs, {
  Types,
} from "../checkout.modal.common/use-purchase-support-docs";

const useManageQuote = (props) => {
  const {
    toggleByType,
    priceQuote,
    pages,
    setPages,
    setAddCertifiedCopy,
    addCertifiedCopy,
    includeAttachments,
    ...args
  } = useAddSupportDocs(props.certifiedCopy);

  const { isNewDocPreviewEnabled } = selectors.configuration;
  const newDocPreviewEnabled = useSelector(isNewDocPreviewEnabled);
  const isFirstQuote = useSelector(selectors.documents.isFirstQuoteFetched);

  const addSupportDocs = { ...props.addSupportDocs, toggleByType };
  const form = { ...props.form };
  let docInfo = { ...props.info };

  const pageSelection = {
    ...props.pageSelection,
    disabled: form.certifiedCopy,
  };

  const certifiedCopy = {
    ...props.certifiedCopy,
    disabled: form.selectedPages !== "all",
  };

  if (newDocPreviewEnabled) {
    if (!isFirstQuote) {
      certifiedCopy.isChecked = pathOr(
        false,
        ["certifiedCopy", "isChecked"],
        props
      );
    }

    if (pathOr(false, ["attachments", "isPresent"], addSupportDocs)) {
      addSupportDocs.attachments.action = toggleByType(Types.Attachments);
    }

    const quoteLoading = propOr(true, "isLoading", priceQuote);
    const certCopyChecked = isNotNil(addCertifiedCopy)
      ? addCertifiedCopy
      : certifiedCopy.isChecked;

    for (const key in docInfo) {
      if (isAbsent(docInfo[key])) {
        docInfo[key] = priceQuote[key];
      }
    }

    docInfo.exportPages = docInfo.totalPagesSelected;
    docInfo.isLoading = isAbsent(priceQuote) || quoteLoading;

    certifiedCopy.onChange = setAddCertifiedCopy;
    certifiedCopy.disabled = pages !== "all";

    form.selectedPages = pages;
    form.includeAttachments = includeAttachments;
    form.certifiedCopy = certCopyChecked;

    pageSelection.disabled = certCopyChecked;
    pageSelection.onChange = setPages;
  }

  return {
    docInfo,
    pageSelection,
    addSupportDocs,
    form,
    certifiedCopy,
    ...args,
  };
};

export default useManageQuote;
