import groupBy from "ramda/src/groupBy";
import prop from "ramda/src/prop";
import values from "ramda/src/values";

const flattenObjList = (objList) => {
  const result = {};

  for (const obj of objList) {
    for (const key in obj) {
      result[key] = [...(result[key] || []), obj[key]];
    }

    result.legalType = obj.legalType;
  }

  return result;
};

export const groupLegalsByType = ({ legals = [] }) => {
  const grouped = values(groupBy(prop("legalType"), legals));

  return grouped.map(flattenObjList);
};
