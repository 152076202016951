import PropTypes from "prop-types";
import isEmpty from "ramda/src/isEmpty";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { LoadingSpinner, Table } from "../../../../../../../components";
import { selectors } from "../../../../../../../store";
import { matchHistoryConfig, NoAlerts, TableError } from "./";

const MatchHistory = ({ loading, matches }) => {
  const table = (
    <Table
      className="fraud-alert-account__table"
      metadata={matchHistoryConfig()}
      data={matches}
    />
  );

  const hasError = useSelector(selectors.fraudAlert.hasFetchMatchesError);
  const errorMsg = hasError
    ? "Can't fetch alert history right now. Please try again."
    : "";

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className="fraud-alert-account__title">
        Alert History ({matches.length})
      </div>
      <TableError message={errorMsg} />
      {isEmpty(matches) ? <NoAlerts type="history" /> : table}
    </Fragment>
  );
};

MatchHistory.displayName = "MatchHistory";

MatchHistory.propTypes = {
  matches: PropTypes.array,
  loading: PropTypes.bool,
};

MatchHistory.defaultProps = {
  matches: [],
};

export default MatchHistory;
