import always from "ramda/src/always";
import compose from "ramda/src/compose";
import find from "ramda/src/find";
import isNil from "ramda/src/isNil";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import when from "ramda/src/when";
import { Status } from "../../constants";

const { Error, Loading, Init } = Status;
const isError = (s) => s === Error;
const isLoading = (s) => s === Loading || s === Init;

export const getFraudAlert = prop("fraudAlert");

export const getAlertsList = compose(
  pathOr([], ["alerts", "activeList"]),
  getFraudAlert
);

export const getDeleteId = compose(
  pathOr(null, ["alerts", "deleteId"]),
  getFraudAlert
);

export const hasFetchMatchesError = compose(
  isError,
  pathOr([], ["matches", "fetchStatus"]),
  getFraudAlert
);

export const hasFetchAlertsError = compose(
  isError,
  pathOr([], ["alerts", "fetchStatus"]),
  getFraudAlert
);

export const hasErrorDeletingAlerts = compose(
  isError,
  pathOr([], ["alerts", "deleteStatus"]),
  getFraudAlert
);

export const isAlertListLoading = compose(
  isLoading,
  pathOr([], ["alerts", "fetchStatus"]),
  getFraudAlert
);

export const isMatchesListLoading = compose(
  isLoading,
  pathOr([], ["matches", "fetchStatus"]),
  getFraudAlert
);

export const getMatchesList = compose(
  pathOr([], ["matches", "history"]),
  getFraudAlert
);

export const getMatchRecordsList = compose(
  pathOr([], ["matches", "history"]),
  getFraudAlert
);

export const getSubmissionStatus = compose(
  pathOr(null, ["alerts", "createStatus"]),
  getFraudAlert
);

export const getCriteria = compose(prop("criteria"), getFraudAlert);

export const getMatch = (id) =>
  compose(
    when(isNil, always(null)),
    find((m) => String(m.id) === String(id)),
    getMatchesList
  );
