// feat: usePaymentMethods

import { css } from "@emotion/react";
import { CardEntry } from "../../components";

const style = css`
  margin-top: 16px;
`;

const CardInput = (props) => {
  const {
    inProgress,
    cardInfo,
    userSavedCard,
    updateRemember,
    isDisabled,
    onUserInput,
  } = props;

  const cardProps = {
    cardAreaProps: {
      cardError: "",
      inProgress,
      cardInfo,
      showSavedCard: userSavedCard,
      disabled: isDisabled,
      updateRemember,
    },
    onUserInput,
    label: "Credit or Debit Card",
  };

  return (
    <div css={style}>
      <CardEntry {...cardProps} />
    </div>
  );
};

export default CardInput;
