import { css } from "@emotion/react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const button = css`
  width: 100%;
  padding: var(--spacing-md);
  border-radius: var(--primitive-6);
  color: var(--color-primary-600);
  cursor: pointer;
  background: var(--color-neutral-lightest);
  border: 1px solid rgba(4, 29, 68, 0.1);
`;

const forgotPassword = css`
  text-align: right;
  color: #006dd5;
`;

const styles = {
  modal: {
    container: css`
      display: grid;
      grid-template-areas:
        "link link "
        "submit cancel";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content;
      grid-gap: 20px;

      & button {
        ${button}
      }

      & button[type="submit"] {
        background-color: var(--color-primary-600);
        color: var(--color-neutral-white);
        border: 0;
      }
    `,

    forgotPassword: css`
      grid-area: link;
      ${forgotPassword}
    `,
  },
  page: {
    container: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 15px;
      padding-top: 20px;

      & button {
        ${button}
        background-color: var(--color-primary-600);
        color: var(--color-neutral-white);
        border: 0;
      }
    `,
  },
};

const FormFooter = (props) => {
  const { isModal = false, isLoading, close } = props;

  const modalView = (
    <div css={styles.modal.container}>
      <Link css={styles.modal.forgotPassword} to="/forgotpassword">
        Forgot Password?
      </Link>
      <button type="button" onClick={close}>
        Cancel
      </button>
      <button type="submit" data-testid="loginButton">
        {isLoading ? "Loading..." : "Sign In"}
      </button>
    </div>
  );

  const pageView = (
    <div css={styles.page.container}>
      <Link css={styles.page.forgotPassword} to="/forgotpassword">
        Forgot Password?
      </Link>
      <button type="submit" data-testid="loginButton">
        {isLoading ? "Loading..." : "Sign In"}
      </button>
    </div>
  );

  return isModal ? modalView : pageView;
};

export default withRouter(FormFooter);
