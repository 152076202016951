import styled from "@emotion/styled";

export const Container = styled.form`
  background-color: var(--color-neutral-lightest);
  border: 1px solid #dadde2;
  border-radius: 12px;
  padding: 45px;
  width: 450px;
  margin: 20px auto;

  & > * + * {
    margin-top: 30px;
  }
`;
