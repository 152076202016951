import concat from "ramda/src/concat";
import groupWith from "ramda/src/groupWith";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import last from "ramda/src/last";
import length from "ramda/src/length";
import map from "ramda/src/map";
import pipe from "ramda/src/pipe";
import range from "ramda/src/range";
import reduce from "ramda/src/reduce";
import reject from "ramda/src/reject";
import sort from "ramda/src/sort";
import uniq from "ramda/src/uniq";
import validator from "validator";
import { buildList } from "../../utils/formValidation";
import { radioOptions } from "./page-selection.constants";

const getSeries = (a, b) => a + 1 === b;
const getGroupsFrom = groupWith(getSeries);

const explodeRanges = reduce((acc, val) => {
  if (validator.matches(val, /[0-9]+-[0-9]+/)) {
    const numbers = map(parseInt, val.split("-"));
    const exclusiveRange = range(head(numbers), last(numbers));
    const r = concat(exclusiveRange, [last(numbers)]);

    return concat(acc, r);
  }

  return concat(acc, [val]);
}, []);

const groupToString = reduce((acc, val) => {
  if (val.length > 1) {
    const rangeString = `${head(val)}-${last(val)}`;

    return isEmpty(acc) ? rangeString : `${acc}, ${rangeString}`;
  }

  return isEmpty(acc) ? `${head(val)}` : `${acc}, ${head(val)}`;
}, "");

const makePageSelectionList = pipe(
  buildList,
  explodeRanges,
  map(parseInt),
  sort((a, b) => a - b),
  uniq
);

export const formatPageSelections = pipe(
  makePageSelectionList,
  getGroupsFrom,
  groupToString
);

export const checkInRange = (totalPages, selectedPages) => {
  const isInRange = (input) => input >= 1 && input <= totalPages;
  const selections = makePageSelectionList(selectedPages);

  return selections.reduce((acc, val) => acc && isInRange(val), true);
};

export const getNumPages = pipe(
  buildList,
  explodeRanges,
  map(parseInt),
  uniq,
  length
);

export const getRadioOptions = (currentPage) => {
  if (!currentPage) {
    return reject(({ value }) => value === "currentPage", radioOptions);
  }

  return radioOptions;
};
