import { css } from "@emotion/react";
import addIndex from "ramda/src/addIndex";
import identity from "ramda/src/identity";
import intersperse from "ramda/src/intersperse";
import is from "ramda/src/is";
import pipe from "ramda/src/pipe";
import prop from "ramda/src/prop";
import Rreduce from "ramda/src/reduce";
import reverse from "ramda/src/reverse";
import { Link, useRouteMatch } from "react-router-dom";
import uuid from "uuid/v4";
import { AccountTitle, Divider, Footer } from "../../../../components";

const reduce = addIndex(Rreduce);
const isAString = is(String);
const isAnArray = is(Array);

const style = {
  main: css`
    padding: 24px;
    display: flex;
    flex-direction: column;

    @media print {
      width: calc(100vw - 48px);
    }
  `,
  content: css`
    flex-grow: 1;
  `,
  footer: css`
    grid-area: footer;
  `,
  title: css`
    @media screen {
      a {
        color: #006dd5;
      }
    }
    @media print {
      display: none;
    }
  `,
  titleDelimiter: css`
    margin: 0 10px;
    font-size: 1.75rem;
  `,
};

const defaultGivenStyles = {
  content: css``,
};

export const SettingsPane = (props) => {
  const { children, styles = defaultGivenStyles } = props;
  const { url } = useRouteMatch();

  const title = makeBreadcrumbs({
    input: props.title,
    url,
    transform: {
      delimiter: (v) => (
        <span key={uuid()} css={style.titleDelimiter}>
          {v}
        </span>
      ),
      current: (v) => <span key={v}>{v}</span>,
      init: ({ text, to }) => (
        <Link key={to} to={to}>
          {text}
        </Link>
      ),
    },
  });

  const titleText = makeBreadcrumbs({
    input: props.title,
    url,
    transform: {
      init: prop("text"),
    },
  }).join(" ");

  return (
    <section css={style.main}>
      <AccountTitle
        renderReturnButton
        title={title}
        text={titleText}
        css={style.title}
      />
      <Divider />
      <div
        css={css`
          ${style.content}
          ${styles.content}
        `}
      >
        {children}
      </div>
      <Footer css={style.footer} />
    </section>
  );
};

function makeBreadcrumbs(props) {
  const { input, url } = props;
  const DELIMITER = "›";

  const transform = {
    delimiter: props.transform.delimiter || identity,
    current: props.transform.current || identity,
    init: props.transform.init || identity,
  };

  if (isAString(input)) {
    return [transform.current(input)];
  } else if (isAnArray(input)) {
    const pathSegments = url.split("/");

    return pipe(
      reverse,
      reduce((memo, part, i) => {
        const isLastPart = i === 0;
        const path = pathSegments.slice(0, i * -1).join("/");

        if (isLastPart) {
          return [...memo, transform.current(part)];
        }

        return [...memo, transform.init({ text: part, to: path })];
      }, []),
      reverse,
      intersperse(transform.delimiter(DELIMITER))
    )(input);
  } else {
    return [];
  }
}
