import { useSelector } from "react-redux";
import { getImg } from "../../store/selectors/assets";
import Button from "./_icon.button";

const IconButton = ({
  hidden,
  text,
  onClick,
  icon,
  shouldMirrorIcon,
  isActive,
  disabled,
}) => {
  if (hidden) return null;

  const iconImg = useSelector(getImg(`icon_${icon}.svg`));

  return (
    <Button
      aria-label="button"
      type="button"
      onClick={onClick}
      mirrorIcon={shouldMirrorIcon}
      isActive={isActive}
      disabled={disabled}
    >
      <img src={iconImg} alt={text} />
    </Button>
  );
};

export default IconButton;
