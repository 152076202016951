import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import { useState } from "react";

const shouldDisplayRadios = (selected) => {
  const keys = Object.keys(selected);

  if (keys.length >= 2) return true;

  const totalSelected = keys.reduce((acc, key) => {
    return acc + selected[key].length;
  }, 0);

  return totalSelected >= 2;
};

const radioOptions = [
  {
    id: "mergeCriteria",
    value: "mergeCriteria",
    label: "Set my alert for these criteria appearing together",
  },
  {
    id: "default",
    value: "default",
    label: "Set my alert to include any of these criteria",
  },
];

const shouldMergeCriteria = (e) => {
  const path = ["currentTarget", "elements", "mergeCriteria", "checked"];
  return pathOr(false, path, e);
};

export const useMultiSelectRadios = (defaultSelection = "mergeCriteria") => {
  const [radioSelected, setRadioSelected] = useState(defaultSelection);

  const inputProps = {
    onChange: (e) => setRadioSelected(path(["target", "value"], e)),
    value: radioSelected,
  };

  return { inputProps, shouldDisplayRadios, radioOptions, shouldMergeCriteria };
};
