import { useAPI } from "../../../../../../hooks/use-api";

export const useUpdateUserInfo = () =>
  useAPI({
    send: "update-user-info",
    listenFor: {
      success: "user-info-updated",
      failure: "error-updating-user-info",
    },
  });
