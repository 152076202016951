import { css } from "@emotion/react";
import { Arrow } from "@kofile/icons";
import { parse } from "query-string";
import curry from "ramda/src/curry";
import { actions } from "../../../../../store";
import { isNotNil } from "../../../../../utils";
import { SortedListHeader } from "./_list-view.sorted-header";
import formatCellByDataType from "./configure.table.format-cells";
import { ToolTip } from "./configure.table.tooltip.jsx";

const sortArrowStyle = css`
  box-sizing: border-box;
  color: #006dd5;
  height: 1.25rem;
  padding: 4px 0;
  transform: rotate(90deg);
  width: 1.25rem;
`;

export const onCellHover = (props) => (event, element, p) => {
  const { isHovered } = p;
  const { dispatch, tooltipIsOpen, location, partyFields } = props;
  const eventClasses = event.relatedTarget.classList;
  const isNotAGap =
    isNotNil(eventClasses) && !eventClasses.contains("tooltip__gap");

  if (tooltipIsOpen && !isHovered && isNotAGap) {
    dispatch(actions.tooltip.hide());

    return false;
  }

  const { order, key } = p.column;
  const data = p.rowData[order];
  const openPreview = () => void props.openPreview(p.rowData.id);

  if (Array.isArray(data) && data.length) {
    let start = null;
    const hover = (timestamp) => {
      if (start === null) {
        start = timestamp;
      }

      const progress = timestamp - start;

      if (progress < 750) {
        return window.requestAnimationFrame(hover);
      }

      if (!element.matches(":hover")) {
        return;
      }

      const elementDims = element.getBoundingClientRect();

      dispatch(
        actions.tooltip.show({
          children: (
            <ToolTip
              data={data}
              columnKey={key}
              partyFields={partyFields}
              location={location}
              openPreview={openPreview}
            />
          ),
          left: Math.min(
            elementDims.left + window.pageXOffset + element.clientWidth / 2,
            elementDims.left + window.pageXOffset + 100
          ),
          top: elementDims.top + window.pageYOffset + element.clientHeight / 2,
          isOpen: true,
          distance: 25,
          position: "bottom",
          isHoverable: true,
        })
      );
    };

    return window.requestAnimationFrame(hover);
  }
};

const headerTransform = curry((props, { key, name }) => {
  const query = parse(props.location.search);
  const { sort, sortBy } = query;

  if (sortBy === key) {
    return (
      <SortedListHeader>
        <Arrow
          id="list-view-icon-arrow"
          css={sortArrowStyle}
          rotation={sort === "asc" ? 0 : 180}
        />
        <p>{name}</p>
      </SortedListHeader>
    );
  }

  return name;
});

export const getBaseConfig = (props) => ({
  cellOptions: {
    on: {
      hover: onCellHover(props),
      click: (e, domEl, cell) => void props.openPreview(cell.rowData.id),
    },
    valueTransform: formatCellByDataType,
  },
  headerOptions: {
    on: {
      click: (e, { key }) => void props.onSortUpdate(key),
    },
    valueTransform: headerTransform(props),
  },
  rowOptions: {
    className: (props) => ({
      "is-selected": props.isSelected,
      "in-cart": props.inCart,
      "is-viewed": props.viewed,
      "is-active": props.isActive,
      "is-uncertified": props.isUncertified,
      "download-is-loading": props.isDownloading,
      "download-has-loaded": props.hasDownloaded,
    }),
  },
});
