import { css } from "@emotion/react";
import { Container } from "./_choose-plan.plan.container";
import { Title } from "./_choose-plan.plan.title";
import { buttonStyle } from "./_style.button";
import { selectButtonStyle } from "./_style.select.button";
import { Price } from "./subscription.price";

const style = (isActivePlan) => css`
  ${buttonStyle}
  ${!isActivePlan && selectButtonStyle}
  grid-area: choose-button;

  ${isActivePlan &&
  `
  backgroundColor: #dadde2;
  borderColor: #dadde2;
  color: #666;
  cursor: default;
  background-color: #f5f6f7;
  border: 0;

  &:hover: {
    textDecoration: unset;
  }
  `}
`;

export const Plan = ({ choose, plan, showPlanTypePicker }) => {
  const { title, amount, period, description, isActivePlan = false } = plan;

  const chooseIfPossible = isActivePlan ? () => {} : choose(plan);

  const buttonText = isActivePlan
    ? "Your Current Subscription"
    : "Select Subscription";

  return (
    <Container showPlanTypePicker={showPlanTypePicker}>
      <Title>{title}</Title>
      <Price amount={amount} period={period} label={description} />

      <button css={style(isActivePlan)} onClick={chooseIfPossible}>
        {buttonText}
      </button>
    </Container>
  );
};
