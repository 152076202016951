import { css } from "@emotion/react";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../../components";
import { actions, selectors } from "../../store";
import { quickSearchTour } from "../../tours";
import { Input } from "./_basic-search.input";

const hiddenLabel = css`
  display: none;
  position: absolute;
  left: -9999px;
`;

const dataTestId = "searchInputBox";
const ariaDescription =
  "Use this field to search for the name of a Grantor/Grantee or by subdivision, document type or document number.";

export const SearchInput = ({ searchTerm, disabled }) => {
  const dispatch = useDispatch();
  const hasViewedTour = useSelector(selectors.user.getQuickSearchTourViewed);
  const tourIsRunning = useSelector(selectors.tour.getRun);
  const isHidden = hasViewedTour || tourIsRunning;

  const clearTour = () => {
    dispatch(actions.user.setTourViewed({ tourViewed: "quickSearch" }));
  };

  const runTour = () => {
    dispatch(actions.tour.run({ steps: quickSearchTour, onEnd: clearTour }));
  };

  const tooltipProps = {
    isHidden,
    onClick: runTour,
    onClear: clearTour,
    headerText: "Not sure where to start?",
    buttonText: "Take the Tour",
    dataTourId: "quickSearchTour",
    bodyText:
      "Take a quick tour through the app and you can become a search expert in no time.",
  };

  return (
    <>
      <label css={hiddenLabel} htmlFor="basicSearchInputBox">
        {ariaDescription}
      </label>
      <Tooltip
        visible={!isHidden}
        placement="bottomLeft"
        overlay={<Popup {...tooltipProps} />}
      >
        <Input
          id="basicSearchInputBox"
          aria-label="search term input"
          dataTestId={dataTestId}
          placeholder="Search for grantor/grantee, subdivision, doc type, or doc#"
          value={searchTerm.value}
          onChange={searchTerm.update}
          onKeyDown={searchTerm.handleEnter}
          disabled={disabled}
          dataTourId={dataTestId}
        />
      </Tooltip>
    </>
  );
};
