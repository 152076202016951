import { LoadingSpinner } from "../../../components";
import { styles } from "./styles";

export const Connecting = ({ cancel }) => {
  return (
    <>
      <div>
        <LoadingSpinner css={styles.spinner} />
        <p>Connecting to County Fusion</p>
      </div>
      {cancel && (
        <button
          onClick={cancel}
          title="cancel linking"
          css={styles.cancelButton}
        >
          Cancel
        </button>
      )}
    </>
  );
};
