import { useAPI } from "../../../../../hooks/use-api";

export const useSubscriptions = (props = {}) =>
  useAPI({
    send: "fetch-subscriptions-for-a-user",
    initialPayload: props,
    exportAs: "subscriptions",
    listenFor: {
      success: "subscriptions-for-a-user",
      failure: "error-fetching-subscriptions-for-a-user",
    },
  });
