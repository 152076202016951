import { useAPI } from "../../hooks/use-api";

export const useSubscriptionAdminHistory = () =>
  useAPI({
    send: "fetch-orders-by-organization-id",
    listenFor: {
      success: "orders-by-organization-id",
      failure: "error-fetching-orders-by-organization-id",
    },
  });
