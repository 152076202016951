import { types as syncTypes } from "@kofile/ko-search-action-types";
import types from "../types/order";

export default {
  fetch: (payload) => ({ type: syncTypes.FETCH_ORDER_1, payload }),
  fetchFullfilled: (payload) => ({
    type: syncTypes.FETCH_ORDER_FULFILLED_2,
    payload,
  }),
  cancelFetch: (payload) => ({
    type: syncTypes.FETCH_ORDER_CANCELLED_1,
    payload,
  }),
  clear: (payload) => ({ type: types.CLEAR, payload }),
};
