import analytics from "./analytics";
import cart from "./cart";
import checkout from "./checkout";
import documents from "./documents";
import download from "./download";
import help from "./help";
import initialization from "./initialization";
import notifications from "./notifications";
import password from "./password";
import search from "./search";
import user from "./user";
import workspaces from "./workspaces";

export default {
  analytics,
  cart,
  checkout,
  documents,
  download,
  help,
  initialization,
  notifications,
  password,
  search,
  user,
  workspaces,
};
