import append from "ramda/src/append";
import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import prop from "ramda/src/prop";
import reduce from "ramda/src/reduce";
import without from "ramda/src/without";
import { isLoading, hasError, hasLoaded, isInert } from "../../constants";
import { emptyDownload } from "../reducers/download";

const loading = "loading";
const downloaded = "downloaded";

export const getDownloadIds = compose(
  reduce(
    (statuses, { status, docId }) => {
      const appendIdTo = (type) => append(docId, statuses[type]);
      const removeIdFrom = (type) => without([docId], statuses[type]);

      if (isLoading(status)) statuses[loading] = appendIdTo(loading);
      if (hasLoaded(status)) statuses[downloaded] = appendIdTo(downloaded);
      if (isInert(status)) statuses[downloaded] = appendIdTo(downloaded);

      if (hasError(status)) {
        statuses[loading] = removeIdFrom(loading);
        statuses[downloaded] = removeIdFrom(downloaded);
      }

      return statuses;
    },
    { downloaded: [], loading: [] }
  ),
  prop("download")
);

export const getDownload = (id) =>
  compose(
    (download) => (download.length ? download[0] : emptyDownload),
    filter((download) => download.docId === id),
    prop("download")
  );

export const isDownloadingForDocId = (selectedDocId) =>
  compose(
    ({ status, docId }) => isLoading(status) && selectedDocId === docId,
    getDownload(selectedDocId)
  );

export const hasDownloadedForDocId = (selectedDocId) =>
  compose(
    ({ status, docId }) =>
      selectedDocId === docId && (hasLoaded(status) || isInert(status)),
    getDownload(selectedDocId)
  );
