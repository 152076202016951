import { css } from "@emotion/react";
import isEmpty from "ramda/src/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../components";
import { selectors } from "../../store";
import { PrintButton } from "./../common/_print.button";
import EmptyHistory from "./history.empty";
import getHistoryTableConfig from "./history.table.config";

export const HistoryTable = ({ orders, isAdmin }) => {
  const dispatch = useDispatch();
  const { configuration } = selectors;
  const clerkTitle = useSelector(configuration.getClerkTitle);
  const tenant = useSelector(configuration.getTenant);
  const countyName = useSelector(configuration.getCountyName);
  const sealUrl = useSelector(configuration.getSealUrl);

  const metadata = getHistoryTableConfig({ dispatch, isAdmin });

  if (isEmpty(orders)) {
    return <EmptyHistory />;
  }

  const style = {
    printButton: css`
      @media print {
        display: none;
      }

      margin-bottom: 10px;
    `,
    seal: css`
      display: block;
      height: 80px;
      margin-bottom: 35px;
    `,

    buttonContainer: css`
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `,
    showForPrintOnly: css`
      @media print {
        display: flex;
        flex-direction: row;
      }
      @media screen {
        display: none;
      }
    `,
    officeInfo: css`
      font-size: 14px;
      margin-left: 16px;
      color: #041d44;
    `,
    infoBold: css`
      font-weight: 700;
    `,
    infoText: css`
      font-weight: 400;
    `,
    printTitle: css`
      @media screen {
        display: none;
      }
      @media print {
        font-size: 24px;
        color: #041d44;
      }
    `,
  };

  return (
    <div>
      <div css={style.showForPrintOnly}>
        <img
          css={style.seal}
          alt={`county seal for ${countyName}`}
          src={sealUrl}
        />
        <div css={style.officeInfo}>
          <p
            css={style.infoHeader}
          >{`${tenant.clerkFirstName} ${tenant.clerkLastName}`}</p>
          <p css={style.infoText}>{clerkTitle}</p>
          <p css={style.infoText}>{tenant.addressLine1}</p>
          <p css={style.infoText}>{tenant.addressLine2}</p>
        </div>
      </div>
      <div css={style.printTitle}>Order History</div>
      <div css={style.buttonContainer}>
        <PrintButton
          id="print-button"
          buttonText={"Printable Version"}
          emotionCss={style.printButton}
        />
      </div>
      <Table
        className="order-history__table"
        metadata={metadata}
        data={orders}
      />
    </div>
  );
};
