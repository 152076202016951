import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";

const AlertsDisabled = ({ openTermsModal }) => {
  const warningIcon = useSelector(
    selectors.assets.getImg("icon_warning_circle.svg")
  );

  return (
    <div className="fraud-alert-disabled__container">
      <img className="" src={warningIcon} alt="warning" />
      <h3>Your property alerts have been temporarily disabled.</h3>
      <p>In order to re-enable your alerts, please accept the updated</p>
      <p>Terms and Conditions by clicking the button below.</p>
      <button onClick={openTermsModal}>Re-enable Property Alerts</button>
    </div>
  );
};

AlertsDisabled.displayName = "AlertsDisabled";

AlertsDisabled.propTypes = {
  openTermsModal: PropTypes.func,
};

export default AlertsDisabled;
