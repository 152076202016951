import { useAPI } from "../../hooks/use-api";

export const useCancelSubscription = () =>
  useAPI({
    send: "cancel-subscription",
    exportAs: "subscriptions",
    listenFor: {
      success: "subscription-canceled",
      failure: "error-canceling-subscription",
    },
  });
