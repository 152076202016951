import styled from "@emotion/styled";

export const Text = styled.span`
  color: rgba(4, 29, 68, 0.7);
  display: inline-flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  text-overflow: ellipsis;
  width: 100%;
`;
