import { css } from "@emotion/react";
import not from "ramda/src/not";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ConfiguredHTMLContent } from "../../components";
import { hasError, hasLoaded, isLoading } from "../../constants";
import { selectors } from "../../store";
import { getImg } from "../../store/selectors/assets";
import { isAbsent, isPresent } from "../../utils";
import ErrorBar from "../common/error.bar";
import { Button } from "./_activate.button";
import { DownloadApplicationDoc } from "./application.submit.download";
import { PaymentInfo } from "./application.submit.payment";
import { useCreateSubscription } from "./use-create-subscription";

const style = {
  container: css`
    display: grid;
    grid-template-areas:
      "info     info"
      "content  content"
      "download add-payment"
      "error    error"
      "initiate initiate";
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
  `,
  info: css`
    grid-area: info;
  `,
  initiate: css`
    margin: 20px 0;
    grid-area: initiate;
    display: grid;
    align-items: center;
    justify-content: center;

    p {
      border-radius: 4px;
      border: 1px solid grey;
      background-color: var(--color-neutral-lightest);
      padding: 20px;
      line-height: 1.5;

      & img {
        padding-right: 10px;
      }
    }
  `,
  button: css`
    width: max-content;
    margin-top: 10px;

    &:disabled {
      opacity: 50%;
      cursor: not-allowed;
    }
  `,
};

const contentStyle = "grid-area: content;";

const errorMsg = "There's been an error. Please try again later.";

export const SubmitApplication = ({ prev, plan, data }) => {
  if (isAbsent(plan)) return <Redirect to="/account/subscription" />;
  const { create, status } = useCreateSubscription();
  const warnIcon = useSelector(getImg("icon_warning_triangle_yellow.svg"));

  const createSubscription = () => {
    create({ planCode: plan.planCode, defer: true });
  };

  const html = propOr("", "html", data);
  const loading = isLoading(status);
  const isRecurring = not(propOr(false, "fixedTerm", plan));
  const cardInfo = useSelector(selectors.user.getCardInfo);
  const hasSavedPayment = isPresent(cardInfo);
  const planCostsMoney = not(prop("amount", plan) === "$0.00");
  const disableSubmission = !hasSavedPayment && isRecurring && planCostsMoney;

  return (
    <section css={style.container}>
      <h3 css={style.info}>
        You&apos;ve selected a {prop("title", plan).toUpperCase()} subscription
        for {prop("amount", plan)} per {prop("period", plan)}.<br />
        {!hasLoaded(status) && (
          <span>
            Want to change it? <button onClick={prev}>Go Back</button>
          </span>
        )}
      </h3>

      <ConfiguredHTMLContent customStyle={contentStyle} html={html} />

      <DownloadApplicationDoc
        url={prop("url", data)}
        copy={prop("urlCardCopy", data)}
      />

      <PaymentInfo
        plan={plan}
        hasSavedPayment={hasSavedPayment}
        copy={prop("paymentCardCopy", data)}
      />

      {hasError(status) && <ErrorBar error={errorMsg} />}

      <div css={style.initiate}>
        {hasLoaded(status) && (
          <p>
            <img
              src={warnIcon}
              alt="You have submitted a pending application."
            />
            You have submitted a pending subscription. Please follow the
            instructions above to complete the application process.
          </p>
        )}

        {!hasLoaded(status) && (
          <Button
            css={style.button}
            onClick={createSubscription}
            disabled={loading || disableSubmission}
          >
            {loading ? "Loading..." : "Submit My Pending Subscription"}
          </Button>
        )}
      </div>
    </section>
  );
};
