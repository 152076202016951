import { css } from "@emotion/react";
import { Link } from "../../../../components";

const style = {
  item: css`
    padding: 8px;
    cursor: auto;
    color: #006dd5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2;

    &:hover {
      background-color: #e6e8ed;
    }

    & a {
      color: #006dd5;
      font-size: 16px;
    }
  `,
};

const PreviousSearches = ({ data }) =>
  data.map(({ searchText: text, url }, i) => (
    <li key={`search-history:${i}`} css={style.item}>
      <Link to={url} dataTestId="searchHistoryItem">
        {text}
      </Link>
    </li>
  ));

export default PreviousSearches;
