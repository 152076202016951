import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { Link } from "./_choose-plan.learn-more.text";

const style = css`
  height: 14px;
  width: 14px;
  margin-left: 5px;
`;

export const LearnMore = ({ url }) => {
  const popout = useSelector(selectors.assets.getImg("icon_popout_blue.svg"));

  return (
    <Link href={url} target="_blank">
      Learn More
      <img src={popout} alt="popout" css={style} />
    </Link>
  );
};
