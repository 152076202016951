import { assets as initialState } from "../initialState";
import types from "../types/assets";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.SAVE]: save,
});

function save(_, { payload }) {
  return payload;
}
