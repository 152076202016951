import { useSelector } from "react-redux";
import { withModal } from "../../hoc";
import { selectors } from "../../store";
import BlueButton from "../doc-detail.common/_button.blue";
import useDocument from "../doc-detail.common/useDocument";
import useExpressCheckout from "./use-express-checkout";

const CertifiedCopyCheckoutButton = ({ modal }) => {
  const document = useDocument();

  const expressProps = { modal, isCertifiedCopy: true, document };
  const { initiateCheckout, disabled, icon, isExternalCert } =
    useExpressCheckout(expressProps);

  const { configuration } = selectors;
  const actionEnabled = useSelector(configuration.certifiedDocsEnabled);

  if (actionEnabled) {
    return (
      <BlueButton
        onClick={initiateCheckout}
        disabled={disabled}
        makeIconWhite={isExternalCert}
      >
        <img src={icon} alt="certified copy checkout button" />
        Certified Copy
      </BlueButton>
    );
  }

  return null;
};

export default withModal(CertifiedCopyCheckoutButton);
