import { compareDesc } from "date-fns";
import compose from "ramda/src/compose";
import match from "ramda/src/match";
import tail from "ramda/src/tail";
import { isNotNil } from "../../utils";

export const types = {
  INSTRUMENT: "instrumentDateRange",
  RECORDED: "recordedDateRange",
  APPLICATION: "applicationDateRange",
};

export const labels = {
  [types.INSTRUMENT]: "Instrument Date",
  [types.RECORDED]: "Recorded Date",
  [types.APPLICATION]: "Application Date",
};

export const findDepartmentConfig = ({ departments, workspaces }) =>
  departments.configuration.find(
    (conf) => conf.code === workspaces.selectedDepartment
  );

export const findDateLabel = (props) => {
  const { fullTextSearchDateRangeLabel, fullTextSearchDateRangeProp } =
    findDepartmentConfig(props);

  if (isNotNil(fullTextSearchDateRangeLabel)) {
    return fullTextSearchDateRangeLabel;
  }

  if (fullTextSearchDateRangeProp === types.INSTRUMENT) {
    return labels.instrumentDateRange;
  }

  if (fullTextSearchDateRangeProp === types.RECORDED) {
    return `${labels.recordedDateRange}`;
  }

  if (fullTextSearchDateRangeProp === types.APPLICATION) {
    return labels.applicationDateRange;
  }

  return "";
};

export const updatePresetByDepartment = (props, fullTextSearchKey) => {
  const { presets } = props.dateRange;
  const { fullTextSearchDateRangeLabel } = findDepartmentConfig(props);

  const label =
    fullTextSearchDateRangeLabel === undefined
      ? labels[fullTextSearchKey]
      : fullTextSearchDateRangeLabel;

  const defaultValue = { label, value: "DefaultValue" };
  return [defaultValue, ...presets.slice(1)];
};

const makeDate = (date) => {
  const [year, month, day] = compose(
    tail,
    match(/^(\d{4})(\d{2})(\d{2})$/)
  )(date);

  return new Date(year, month, day);
};

export const parseDateRange = (formatted) => {
  const emptyRange = [null, null];
  try {
    const [start, end] = formatted.split(",");

    if (!start && !end) return emptyRange;

    const startMoment = start ? makeDate(start) : null;
    const endMoment = end ? makeDate(end) : null;

    return [startMoment, endMoment];
  } catch (err) {
    return emptyRange;
  }
};

export const isValidDateRange = (formatted) => {
  const [start, end] = parseDateRange(formatted);

  return start !== null && end !== null && compareDesc(start, end) !== -1;
};

export const validDateRangeInState = ({ dateRange }) => {
  const { rangeValue } = dateRange;

  if (!dateRange || !rangeValue) return false;
  if (dateRange.value !== "DefaultValue") return true;

  return isValidDateRange(rangeValue);
};
