import { types as syncTypes } from "@kofile/ko-search-action-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAPI } from "../../../hooks/use-api";
import { selectors } from "../../../store";

export default () => {
  const { token } = useParams();
  const tenant = useSelector(selectors.configuration.getTenant);

  const { data, status, initiate } = useAPI({
    send: syncTypes.FETCH_CERT_REQUEST_BY_CODE_0,
    listenFor: {
      success: syncTypes.FETCH_CERT_REQUEST_BY_CODE_FULFILLED_0,
      failure: syncTypes.FETCH_CERT_REQUEST_BY_CODE_REJECTED_0,
    },
  });

  useEffect(() => {
    if (token) initiate({ verificationCode: token });
  }, [token]);

  return {
    countyName: tenant.name,
    status,
    docNumber: data?.documentNumber,
    recordedDate: data?.recordedDate,
    url: data?.certifiedCopyUrl,
  };
};
