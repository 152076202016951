import { CheckboxField } from "../../components/Fields";

const RememberPayment = ({ className, onChange, isChecked }) => {
  const checkboxProps = {
    id: "remember-me",
    checkboxLabel: "Save card for future use",
    labelPosition: "right",
    className,
    isChecked,
    onChange,
  };

  return <CheckboxField {...checkboxProps} />;
};

RememberPayment.defaultProps = {
  onChange: () => {},
};

export default RememberPayment;
