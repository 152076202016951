import { useSelector } from "react-redux";
import { ModalShell } from "../../components";
import { withModal } from "../../hoc";
import { selectors } from "../../store";
import { RemoveButton } from "./_member-list.button.remove";
import RemoveConfirmation from "./member-list.table.remove.confirmation";

const Modal = (props) =>
  Object.assign(
    () => {
      const remove = () => {
        props.removeMember();
        props.close();
      };

      return (
        <ModalShell close={props.close}>
          <RemoveConfirmation {...props} remove={remove} close={props.close} />
        </ModalShell>
      );
    },
    { displayName: "RemoveMemberModal" }
  );

export const Remove = (props) => {
  const { disabled, modal, userId, isAdmin, memberIsBeingRemoved, ...rest } =
    props;
  const trashCan = useSelector(selectors.assets.getImg("icon_trash.svg"));
  const removeMember = () => props.removeMember(userId);
  const openModal = () =>
    modal.open(Modal({ ...rest, removeMember, close: modal.close }));
  const loading = memberIsBeingRemoved(userId);

  return (
    <RemoveButton
      isAdmin={isAdmin}
      type="button"
      onClick={openModal}
      aria-label={`remove ${rest.fullName} from your organization`}
      disabled={disabled || loading}
    >
      <img src={trashCan} alt="trashcan" />
      {loading ? "Loading..." : "Remove"}
    </RemoveButton>
  );
};

export default withModal(Remove);
