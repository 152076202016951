import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const navStyle = css`
  border-left: 6px solid transparent;
  font-size: 18px;
  padding: 16px;
  display: flex;
  align-items: center;

  span::before {
    color: #57688b;
    margin-right: 1rem;
    font-size: 20px;
  }

  &.active {
    background-color: #e7e9ee;
    border-left: 6px solid #2f6cce;

    span::before {
      color: #2f6cce;
    }
  }
`;

const NavTab = ({ label, to, icon }) => (
  <NavLink
    to={`/account${to}`}
    css={navStyle}
    data-testid="accountNavigationTab"
  >
    <span className={`icon-${icon}`} />
    {label}
  </NavLink>
);

NavTab.displayName = "NavTab";

NavTab.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default NavTab;
