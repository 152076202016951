import { useEffect, useContext, useState } from "react";
import { isPresent } from "../../utils";
import ErrorContext from "./context.card-error";

const useCardErrors = () => {
  const errorContext = useContext(ErrorContext);
  const [cardError, setCardError] = useState("");

  useEffect(() => {
    if (isPresent(errorContext) && cardError !== errorContext) {
      setCardError(errorContext);
    }
  }, [errorContext]);

  const onChange = ({ error = {} }) => {
    if (error.message !== cardError) setCardError(error.message);
  };

  return { cardError, setCardError: onChange };
};

export default useCardErrors;
