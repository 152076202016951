import { push } from "connected-react-router";
import indexOf from "ramda/src/indexOf";
import length from "ramda/src/length";
import prop from "ramda/src/prop";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectors } from "../../store";
import useDocument from "../doc-detail.common/useDocument";

export const navigateTo = {
  Next: "Next",
  Previous: "Previous",
};

const toPrevious = (oldIndex) => (oldIndex > 0 ? oldIndex - 1 : oldIndex);
const disablePrevious = (index) => index === 0;

const toNext = (old, results) => (old < results - 1 ? old + 1 : old);
const disableNext = (index, results) => results - 1 === index;

export default (to) => {
  const dispatch = useDispatch();
  const data = useDocument();
  const { search } = useLocation();
  const [disabled, setDisabled] = useState(false);
  const isNext = to === navigateTo.Next;
  const determineIndex = isNext ? toNext : toPrevious;
  const disable = isNext ? disableNext : disablePrevious;
  const resetDocToFirstPage = () => data.setCurrentPage(0);

  const byOrder = useSelector(selectors.documents.getActiveWorkspaceDataOrder);
  const workspace = useSelector(selectors.workspaces.getActiveWorkspace);
  const oldIndex = indexOf(prop("id", data), byOrder);
  const index = determineIndex(oldIndex, length(byOrder));
  const newId = byOrder[index];

  const path =
    oldIndex === -1 ? workspace.path : `/doc/${newId}${search ?? ""}`;

  useEffect(() => {
    setDisabled(path === workspace.path);
  }, [path, workspace.path]);

  useEffect(() => {
    if (disable(oldIndex)) setDisabled(true);
  }, [oldIndex, disable]);

  const navigate = (e) => {
    e.preventDefault();

    const inWorkspace = { workspaceID: workspace.id };
    dispatch(push(path, inWorkspace));
    resetDocToFirstPage();
  };

  return { navigate, disabled };
};
