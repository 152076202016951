import { useAPI } from "../../hooks/use-api";
import { types } from "../../store/reducers/download";

export default () =>
  useAPI({
    send: types.fetchDownloadUrl,
    listenFor: {
      success: types.downloadUrl,
      failure: types.errorFetchingDownloadUrl,
    },
  });
