// feat: usePaymentMethods

import { isAbsent } from "../../utils";
import CardInput from "./stripe.card-input";

const StripePayment = ({ price = 0, cardInput, isDisabled }) => {
  if (price === 0 || isAbsent(price)) return null;

  return <CardInput {...cardInput} isDisabled={isDisabled} />;
};

export default StripePayment;
