import isNil from "ramda/src/isNil";
import { Fragment, useEffect, useState } from "react";
import { Table } from "../../components";
import { hasError, hasLoaded, isLoading, isInit } from "../../constants";
import { useNotifications } from "../../hooks/use-notifications";
import { useRemoveMember } from "../account-settings.common/use-remove-member";
import { AddMember } from "./member-list.add";
import memberTableConfig from "./member-list.table.config";

export const MemberList = (props) => {
  const [userIdToRemove, setUserIdToRemove] = useState(null);
  const { members, subscriptionId, refetchMemberList, ...rest } = props;
  const { initiate, status, data: message, error } = useRemoveMember();
  const pushNotification = useNotifications();

  const removeMember = (userId) => {
    if (isInit(status) || hasLoaded(status)) {
      initiate({ userId, subscriptionId });
      setUserIdToRemove(userId);
    }
  };

  const memberIsBeingRemoved = (userId) =>
    isLoading(status) && userIdToRemove === userId;

  useEffect(() => {
    if (hasLoaded(status)) {
      pushNotification({ message });
      refetchMemberList();
    }

    if (hasError(status)) {
      pushNotification({ message: error.reason.message });
    }
  }, [status]);

  const memberTable = (
    <Table
      className="fraud-alert-account__table"
      metadata={memberTableConfig({
        ...rest,
        removeMember,
        memberIsBeingRemoved,
      })}
      data={isNil(members) ? [] : members}
    />
  );

  return (
    <Fragment>
      <AddMember {...props} />
      {memberTable}
    </Fragment>
  );
};

export default MemberList;
