import { useSelector } from "react-redux";
import { hasLoaded, isLoading } from "../../constants";
import { selectors } from "../../store";
import { ContactSupport } from "../../units/common/contact-support";
import { chooseViewFor } from "../../utils/choose-view-for";
import DocumentActions from "./document-actions";
import MultiPage from "./multi-page";
import SinglePage from "./single-page";
import style from "./style";
import useContainer from "./use-container";
import useImage from "./use-image";
import { isSinglePage, isMultiPage } from "./utils";

const ViewDocumentImages = ({ type, showDetails }) => {
  const { page, src, status, thumbnails, refs } = useImage(type);
  const { container, canvas } = useContainer({ rotation: page.rotation });
  const { assets } = selectors;

  const { isSecured, hidePreview, hidePreviewMessage } = page;

  const securedImage = useSelector(assets.getImg("secured.svg"));
  const noImageIcon = useSelector(assets.getImg("no_image.svg"));
  const imagesAvailable = src !== null;

  const showActions =
    imagesAvailable && hasLoaded(status) && page.pageCount > 0;

  if (process.env.IS_CLIENT === "f") return null;

  if (isLoading(status)) return <loading-indicator />;

  if (isSecured) {
    return (
      <div css={style.secured}>
        <img src={securedImage} alt="Secured" />
      </div>
    );
  }

  if (hidePreview) {
    return (
      <div css={[style.secured, style.hiddenImage]}>{hidePreviewMessage}</div>
    );
  }

  if (!imagesAvailable) {
    return (
      <div css={style.noImage}>
        <img src={noImageIcon} alt="No Document"></img>
      </div>
    );
  }

  return (
    <section css={style.wrap(showDetails)} ref={container}>
      {showActions && (
        <DocumentActions page={page} disabled={isMultiPage(page.viewType)} />
      )}
      {chooseViewFor(page.viewType)
        .with({ isSinglePage, isMultiPage })
        .when({
          isSinglePage: () => (
            <SinglePage
              key={src}
              page={page}
              src={src}
              refs={refs}
              canvas={canvas}
            />
          ),
          isMultiPage: () => <MultiPage thumbnails={thumbnails} page={page} />,
          default: () => <ContactSupport />,
        })}
    </section>
  );
};

export default ViewDocumentImages;
