import classnames from "classnames";
import curry from "ramda/src/curry";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import propOr from "ramda/src/propOr";
import { useHistory } from "react-router-dom/";
import { DropdownMenu } from "../";
import { DocActionButton } from "../../components/doc-action-button";
import withCartWiring from "../../hoc/withCartWiring";
import modalChild from "../../units/checkout.modal/modal";
import { isPresent } from "../../utils";

const optionRenderer = curry(
  (
    { id, inCart, attachmentId },
    { action, label, value, onClick = () => {}, disabled = false }
  ) => {
    const idForAction = value === "addAttachmentToCart" ? attachmentId : id;

    const click = () => {
      action([idForAction])();
      onClick();
    };

    return (
      <DocActionButton
        disabled={disabled || (value === "addToCart" && inCart)}
        onClick={click}
        key={label}
        data-testid="documentAction"
      >
        {label}
      </DocActionButton>
    );
  }
);

export const DocumentActions = (props) => {
  const {
    actions = [],
    docId,
    className = "",
    label = "",
    inCart = false,
    showInCart = false,
    attachmentId,
    alignment,
    customActions = [],
  } = props;

  const history = useHistory();
  const addedToCart = showInCart && inCart;

  if (isPresent(customActions)) {
    customActions.forEach(({ label = "", url = "" }) => {
      const openCustomLink = () => () => {
        if (url === "#") return;
        if (url[0] === "/") {
          history.push(url);
        } else {
          window.open(url, "_blank");
        }
      };

      actions.push({
        label,
        value: label.split(" ").join(""),
        disabled: url === "#",
        action: openCustomLink,
      });
    });
  }

  const firstAction = head(actions) || {};
  const defaultLabel = addedToCart
    ? "Added To Cart"
    : propOr("", "label", firstAction);

  if (isEmpty(actions)) return null;

  return (
    <DropdownMenu
      options={actions}
      optionRenderer={optionRenderer({
        options: actions,
        id: docId,
        inCart,
        attachmentId,
      })}
      label={label || defaultLabel}
      className={classnames("document-actions", className, {
        "document-actions__in-cart": addedToCart,
      })}
      alignment={alignment}
      dataTestId={props.dataTestId}
    />
  );
};

export default withCartWiring(modalChild)(DocumentActions);
