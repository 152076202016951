import styled from "@emotion/styled";

export const NavLink = styled.li`
  align-items: center;
  border-right: 1px solid var(--color-neutral-700);
  display: flex;
  height: 15px;
  white-space: nowrap;

  &:last-child {
    border-right: 0;
  }

  & .menu__control {
    font-size: 15px;
  }
`;
