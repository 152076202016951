import styled from "@emotion/styled";

const greenFilter = `filter: invert(62%) sepia(58%) saturate(527%) hue-rotate(126deg) brightness(93%) contrast(101%);`;

const Icon = styled.img`
  justify-self: center;
  width: 4em;
  margin: 10px;
  ${(props) => !props.isError && greenFilter}
`;

export default Icon;
