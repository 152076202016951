import { Children, useContext } from "react";
import { tabs } from "./style";
import TabIds from "./tab-ids";
import TabsContext from "./tabs-context";

const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

const Tabs = ({ children }) => {
  const tabIds = Children.toArray(children).map(({ props }) => props.id);
  const { activeTab, activateTab } = useContext(TabsContext);

  return (
    <div css={tabs.wrap}>
      <ul css={tabs.tablist}>
        {tabIds.map((id) => (
          <li key={id} css={tabs.tablink(activeTab === id)}>
            <button onClick={activateTab(id)}>
              {getKeyByValue(TabIds, id)}
            </button>
          </li>
        ))}
      </ul>

      <div css={tabs.tab}>{children}</div>
    </div>
  );
};

export default Tabs;
