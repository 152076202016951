import complement from "ramda/src/complement";
import isEmpty from "ramda/src/isEmpty";
import propEq from "ramda/src/propEq";
import { useParams } from "react-router-dom";
import { chooseViewFor } from "../../utils/choose-view-for";
import { Active } from "./subscriptions.active";
import { Canceled } from "./subscriptions.canceled";
import { Editing } from "./subscriptions.edit";
import { NoSubscriptions } from "./subscriptions.empty";

const hasSome = complement(isEmpty);
const hasNone = isEmpty;
const isActive = propEq("status", "active");
const isCanceled = propEq("status", "canceled");
const isUnpaid = propEq("status", "unpaid");

const chooseSubscriptionView = (props) => (sub) => {
  const { isEditing, onCancel, onChange, isOrganizationAdmin } = props;

  return chooseViewFor(sub)
    .with({ isActive, isCanceled, isEditing, isUnpaid })
    .when({
      isEditing: () => (
        <Editing key={sub.title} subscription={sub} onChange={onChange} />
      ),
      isActive: () => (
        <Active
          key={sub.title}
          subscription={sub}
          onCancel={onCancel}
          isOrganizationAdmin={isOrganizationAdmin}
        />
      ),
      isCanceled: () => (
        <Canceled
          key={sub.title}
          subscription={sub}
          isOrganizationAdmin={isOrganizationAdmin}
        />
      ),
      isUnpaid: () => <Canceled key={sub.title} subscription={sub} />,
      default: () => null,
    });
};

export const Subscriptions = (props) => {
  const { id } = useParams();
  const { subscriptions } = props;
  const isEditing = (sub) => String(sub.id) === id;

  return chooseViewFor(subscriptions)
    .with({ hasSome, hasNone })
    .when({
      hasNone: () => <NoSubscriptions />,
      hasSome: () =>
        subscriptions.map(chooseSubscriptionView({ isEditing, ...props })),
      default: () => null,
    });
};
