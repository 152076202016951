import { css } from "@emotion/react";

export const navLink = css`
  align-items: center;
  background: none;
  color: var(--color-neutral-lightest);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 100%;
  padding: 0;

  & > a {
    color: var(--color-neutral-lightest);
  }
`;

export const navLinkMargin = css`
  ${navLink}
  margin: 0 8px;
`;
