import { useDispatch } from "react-redux";
import { actions } from "../../store";
import { BodyWrapper } from "../checkout.modal.common/_modal.body.wrapper";
import { Wrapper } from "../checkout.modal.common/_modal.wrapper";
import DocumentInfo from "../checkout.modal.common/document-info";
import Footer from "../checkout.modal.common/footer";
import Header from "../checkout.modal.common/header";
import PageSelection from "../checkout.modal.common/page-selection";
import PurchaseCertifiedCopy from "../checkout.modal.common/purchase-certified-copy";
import useManageQuote from "../checkout.modal.common/use-manage-quote-data";
import AddSupportDocs from "./add-support-docs";
import formatItemsByType from "./add-to-cart.format-items";

export const AddToCart = ({ common, ...props }) => {
  const dispatch = useDispatch();
  const {
    addSupportDocDataTo,
    pageSelection,
    certifiedCopy,
    docInfo,
    addSupportDocs,
    form,
  } = useManageQuote(props);

  const addToCart = (items) => void dispatch(actions.cart.addToCart({ items }));

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const items = formatItemsByType(common.entityType, {
      items: addSupportDocDataTo(common.items),
      department: common.department,
      form,
      pageCount: pageSelection.totalPages,
    });

    addToCart(items);

    form.onSubmit();
    common.close();
  };

  return (
    <form disabled={form.disabled} onSubmit={submitForm}>
      <Wrapper>
        <Header {...props.header} />
        <BodyWrapper>
          <DocumentInfo {...docInfo} />
          <PageSelection {...pageSelection} />
          <AddSupportDocs {...addSupportDocs} />
          <PurchaseCertifiedCopy {...certifiedCopy} />
        </BodyWrapper>
        <Footer {...props.footer} />
      </Wrapper>
    </form>
  );
};

export default AddToCart;
