import { css } from "@emotion/react";

const buttonIsOnRight = `
  border-radius: 0 4px 4px 0;
`;

const buttonIsOnLeft = `
  border-radius: 4px 0 0 4px;
  border-right: 0;
`;

const gridWithBookNav = `
  grid-template-columns: 140px 140px 145px 145px 1fr 200px;
  grid-template-areas: 
    "back        back        book-label book-label .       ."
    "prev-search next-search prev-book  next-book  buttons toggle-details";
`;

const gridWithoutBookNav = `
  grid-template-columns: 140px 140px 1fr 200px;
  grid-template-areas: 
    "back        back         .       ."
    "prev-search next-search  buttons toggle-details";
`;

export default {
  wrapper: (displayBookNav) => css`
    grid-area: nav;
    padding: 10px 0;
    display: grid;
    width: 100%;
    grid-template-rows: 20px 35px;
    ${displayBookNav ? gridWithBookNav : gridWithoutBookNav};
    justify-content: start;
    row-gap: 3px;
  `,
  button: (isOnRight) => css`
    ${isOnRight ? buttonIsOnRight : buttonIsOnLeft}
    grid-area: nav-by-book;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &:disabled {
      border: 1px solid #d9dde3;
      ${!isOnRight ? "border-right: 0;" : ""}
    }
  `,
  back: css`
    grid-area: back;
    color: #006dd5;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 5px;
    }
  `,
  prevSearch: css`
    grid-area: prev-search;
  `,
  nextSearch: css`
    grid-area: next-search;
    margin-right: 10px;
  `,
  bookLabel: css`
    grid-area: book-label;
    color: #68778f;
    font-size: 13px;
  `,
  prevBook: css`
    grid-area: prev-book;
  `,
  nextBook: css`
    grid-area: next-book;
    margin-right: 10px;
  `,
  buttons: css`
    grid-area: buttons;
    display: flex;
    flex-direction: row;
    gap: 10px;
  `,
};
