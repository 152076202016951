import styled from "@emotion/styled";
import { lightGray } from "./style";

export default styled.figure`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 100%;
  max-height: 978px;
  position: absolute;
  height: calc(100% - 55px);
  width: 100%;

  & img {
    max-height: 380px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border: 1px solid ${lightGray};

    &:hover {
      transform: scale3d(1.05, 1.05, 1.05);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
`;
