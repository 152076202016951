import { useSelector } from "react-redux";
import { selectors } from "../../store";
import styles from "./announcement.style";

const Announcement = ({ announcement, clear }) => {
  const { getImg } = selectors.assets;
  const warningIcon = useSelector(getImg("icon_announcement.svg"));
  const removeIcon = useSelector(getImg("icon_close_announcement.svg"));

  return (
    <div className="announcement">
      <img css={styles.warningIcon} src={warningIcon} alt="warning" />
      <p css={styles.text} dangerouslySetInnerHTML={{ __html: announcement }} />
      <button css={styles.removeButton} onClick={clear}>
        <img css={styles.removeIcon} src={removeIcon} alt="remove" />
      </button>
    </div>
  );
};

export default Announcement;
