import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";

export const DeleteError = ({ id }) => {
  const { getDeleteId, hasErrorDeletingAlerts } = selectors.fraudAlert;
  const noError = !useSelector(hasErrorDeletingAlerts);
  const deleteId = useSelector(getDeleteId);

  if (noError || id !== deleteId) {
    return null;
  }

  return (
    <span className="fraud-alert__remove-error">
      There has been an error deleting this alert. Please try again.
    </span>
  );
};

export default DeleteError;
