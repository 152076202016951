import { useRef } from "react";
import style from "./style";

const boundPagesTo = (limit) => (page) => {
  if (limit === 0) return limit;
  if (page <= 0) return 1;
  if (page > limit) return limit;
  return page;
};

const PageInput = (props) => {
  const { currentPage = 0, pageLimit, goToPage, disabled } = props;
  const inputRef = useRef(null);
  const getPageInBounds = boundPagesTo(pageLimit);

  const selectInput = () => {
    inputRef.current?.select?.();
  };

  const handle = (event) => {
    const value = getPageInBounds(Number(event.target.value));
    inputRef.current.value = value;
    // The array is 0 based, but on the UI we show it starting from 1.
    goToPage(value - 1);
  };

  return (
    <div css={style.inputContainer}>
      <input
        key={currentPage}
        css={style.input}
        type="number"
        ref={inputRef}
        min={1}
        max={pageLimit}
        defaultValue={currentPage + 1}
        aria-label="Page Number"
        disabled={pageLimit === 1 || disabled}
        onFocus={selectInput}
        onClick={selectInput}
        onChange={handle}
      />
      <span css={style.inputText} data-testid="docPreviewPageCount">
        of {pageLimit}
      </span>
    </div>
  );
};

export default PageInput;
