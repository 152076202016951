import styled from "@emotion/styled";

export default styled.form`
  height: 100%;
  display: grid;
  grid-area: body;
  grid-template-areas:
    "header"
    "body"
    "footer";
  grid-template-rows: min-content 1fr min-content;
`;
