import { Container } from "./_list-types.container";
import { ListTypeButton } from "./list-types.button";

export const ListTypes = (props) => {
  const { onChange = () => {}, currentView = "", viewModes = [] } = props;
  if (viewModes.length <= 1) {
    return null;
  }

  return (
    <Container data-tourid="listTypes">
      {viewModes.map((mode, i) => (
        <ListTypeButton
          key={i}
          onClick={onChange(mode)}
          mode={mode}
          active={currentView === mode}
        />
      ))}
    </Container>
  );
};

export default ListTypes;
