import { useSelector } from "react-redux";
import { ErrorBar } from "../../../../components";
import { selectors } from "../../../../store";
import { getPriceFromCents } from "../../../../utils";
import { style } from "./checkout.styles";

export const CheckoutTotal = ({ checkoutError, totalPrice }) => {
  const { getCountyFusionExcludeFees } = selectors.configuration;
  const feesCalculatedLater = useSelector(getCountyFusionExcludeFees);

  if (feesCalculatedLater) return null;

  return (
    <>
      <div css={style.total} data-testid="checkoutTotal">
        <span>Total:</span> {getPriceFromCents(totalPrice)}
      </div>
      {totalPrice === 0 && <ErrorBar error={checkoutError} />}
    </>
  );
};
