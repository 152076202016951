import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../store";
import { isAbsent, isPresent } from "../../../../utils";

const style = css`
  line-height: 1.7;
`;

const Description = () => {
  const { configuration } = selectors;
  const clerkTitle = useSelector(configuration.getRawClerkTitle);
  const tenantMidName = useSelector(configuration.getTenantMidName);

  return <p css={style}>{constructDescription(clerkTitle, tenantMidName)}</p>;
};

export const constructDescription = (title, midName) => {
  const beginning =
    "Get notified against potentially fraudulent claims or transactions regarding Real Property. Receive email alerts any time your personal or entity name is used in a Real Property filing within the county. This is a free service";
  const ending =
    " designed with the goal of reducing fraudulently-recorded documents that could affect your property ownership.";
  let mid = "";

  if (isPresent(title)) {
    mid = ` provided by GovOS and your ${title}`;
  }

  if (isAbsent(title) && isPresent(midName)) {
    mid = ` provided by GovOS and ${midName}`;
  }

  return beginning + mid + ending;
};

export default Description;
