export default {
  fetchDepartmentDateRanges: ({ department, shouldFetch = () => true }) => {
    if (shouldFetch(department)) {
      return {
        type: "fetch-department-dates",
        payload: { department },
      };
    }

    return { type: "noop" };
  },
};
