import { Status } from "../../constants";
import { default as checkoutTypes } from "../types/checkout";
import { handleActions } from "./utils";

const { Init, Loading, Loaded, Error } = Status;

/**
 * for orderStatus and expressOrderStatus:
 *  - Loading means payment has been initiated with Ncourt
 *  - Loaded means payment has been successfully initiated with Ncourt and the checkoutUrl/orderId is available.
 *  - When the order is complete those keys are reset to Init
 *
 * paid key indicates whether has been successfully completed and we've received nCourt's POST back to API.
 */

export const Payment = Object.freeze({
  init: "init",
  paid: "paid",
  created: "created",
});

export const initialState = {
  paid: Payment.init,
  orderStatus: Init,
  expressOrderStatus: Init,
  orderId: null,
  checkoutUrl: null,
};

export const types = {
  prepareCheckout: "prepare-ncourt-checkout",
  checkoutReady: "ncourt-checkout-ready",
  preparationError: "error-preparing-ncourt-checkout",
  orderComplete: "order",
  orderError: "error-order",
  clearOrder: "clear-ncourt-order",
  clearError: "clear-ncourt-error",
  prepareExpressCheckout: "prepare-ncourt-express-checkout",
  expressCheckoutReady: "ncourt-express-checkout-ready",
  expressPreparationError: "error-preparing-ncourt-express-checkout",
  resetOrderStatuses: "reset-order-statuses",
  prepareExportOrder:
    "prepare-ncourt-express-checkout-for-a-search-export-results-pdf",
};

export const errorUrl = "/static/payment/error";

export default handleActions(initialState, {
  // prepare cart checkout
  [types.prepareCheckout]: (state) => ({
    ...state,
    orderStatus: Loading,
    orderId: null,
    checkoutUrl: null,
  }),

  [types.checkoutReady]: (state, { payload }) => {
    if (payload.url === null) {
      const paymentStatus = Payment[payload.status];
      const resetStatuses = { orderStatus: Init, expressOrderStatus: Init };
      const status = paymentStatus === Payment.paid ? resetStatuses : {};

      return {
        ...state,
        paid: paymentStatus,
        orderId: parseInt(payload.orderId, 10),
        ...status,
      };
    } else {
      return {
        ...state,
        orderStatus: Loaded,
        orderId: payload.orderId,
        checkoutUrl: payload.url,
      };
    }
  },

  [types.preparationError]: (state) => ({
    ...state,
    orderStatus: Error,
    orderId: null,
    checkoutUrl: errorUrl,
  }),

  //prepare express checkout
  [types.prepareExpressCheckout]: (state) => ({
    ...state,
    expressOrderStatus: Loading,
    orderId: null,
    checkoutUrl: null,
  }),

  [types.expressCheckoutReady]: (state, { payload }) => {
    if (payload.url === null) {
      const paymentStatus = Payment[payload.status];
      const resetStatuses = { orderStatus: Init, expressOrderStatus: Init };
      const status = paymentStatus === Payment.paid ? resetStatuses : {};

      return {
        ...state,
        paid: paymentStatus,
        orderId: parseInt(payload.orderId, 10),
        ...status,
      };
    } else {
      return {
        ...state,
        expressOrderStatus: Loaded,
        orderId: payload.orderId,
        checkoutUrl: payload.url,
      };
    }
  },

  [types.expressPreparationError]: (state) => ({
    ...state,
    expressOrderStatus: Error,
    orderId: null,
    checkoutUrl: errorUrl,
  }),

  // prepare export checkout
  [types.prepareExportOrder]: (state) => ({
    ...state,
    expressOrderStatus: Loading,
  }),

  // order complete/polling for completion
  [types.orderComplete]: (state, { payload }) => {
    const paymentStatus = Payment[payload.status];
    const resetStatuses = {
      orderStatus: Init,
      expressOrderStatus: Init,
    };
    const status = paymentStatus === Payment.paid ? resetStatuses : {};

    return {
      ...state,
      paid: paymentStatus,
      orderId: payload.id,
      ...status,
    };
  },

  [types.orderError]: (state) => ({
    ...state,
    paid: Payment.init,
    orderStatus: Error,
    expressOrderStatus: Error,
  }),

  [types.clearOrder]: (state) => ({ ...state, ...initialState }),

  [types.resetOrderStatuses]: (state) => ({
    ...state,
    orderStatus: Init,
    expressOrderStatus: Init,
  }),

  [checkoutTypes.CLEAR]: (state) => ({ ...state, ...initialState }),
});
