import { useSelector } from "react-redux";
import { selectors } from "../../store";
import styles from "./styles";

const currentYear = new Date().getFullYear();

export const Footer = ({ hide = false }) => {
  const { assets, configuration } = selectors;
  const logo = useSelector(assets.getImg("govos_logo.svg"));
  const countyName = useSelector(configuration.getCountyName);

  if (hide) return null;

  return (
    <footer css={styles.container} id="footer">
      <div css={styles.left}>
        &copy; {`${currentYear} ${countyName}. All Rights Reserved`}
      </div>
      <div css={styles.right}>
        Powered By
        <img src={logo} alt="GovOS logo" css={styles.logo} />
      </div>
    </footer>
  );
};

export default Footer;
