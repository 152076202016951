import { parse } from "query-string";
import isNil from "ramda/src/isNil";
import length from "ramda/src/length";
import path from "ramda/src/path";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { hasLoaded, Status } from "../../constants";
import { isNotNil } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";
import { useFetchDocumentImages } from "./use-fetch-document-images";

const format = (query) => query.replace(/['"]+/g, "");

export default (type) => {
  const { data, status, initiate } = useFetchDocumentImages();
  const rootDoc = useDocument();
  const location = useLocation();
  const query = parse(location.search);
  const id = propOr(null, "id", rootDoc);

  useEffect(() => {
    const shouldRefetch = prop("shouldRefetch", rootDoc);

    if (shouldRefetch) {
      const clear = propOr(() => {}, "clearAdditionalImages", rootDoc);
      clear();
      rootDoc.setShouldRefetch(false);
    }
  }, [rootDoc]);

  useEffect(() => {
    const urls = path([type, "urls"], rootDoc);
    const imagesNotCached = isNotNil(id) && isNil(urls);

    if (imagesNotCached) {
      const shouldRefetch = propOr(() => {}, "setShouldRefetch", rootDoc);
      const shouldAddHighlight = isNotNil(query.highlight);

      initiate({
        resourcePath: [id],
        query: {
          type,
          ...(shouldAddHighlight && { query: format(query.highlight) }),
        },
      });
      shouldRefetch(false);
    }
  }, [id, rootDoc]);

  useEffect(() => {
    if (hasLoaded(status) && isNotNil(rootDoc[type])) {
      const pageCount = length(propOr([], "urls", data));
      const setImages = propOr(() => {}, "setAdditionalImages", rootDoc);

      setImages({ ...data, type, pageCount });
    }
  }, [status, data]);

  if (isNotNil(path([type, "urls"], rootDoc))) {
    return { status: Status.Loaded };
  }

  return { status };
};
