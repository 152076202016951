import { useAPI } from "../../../hooks/use-api";

export const useCanUserPurchaseSubscription = () =>
  useAPI({
    send: "can-user-purchase-subscriptions",
    initialPayload: {},
    listenFor: {
      success: "user-can-purchase-subscriptions",
      failure: "undetermined-if-user-can-purcahse-subscriptions",
    },
  });
