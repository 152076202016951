const SideFilterPlaceholder = () => (
  <div className="sidefilter-placeholder">
    <div className="sidefilter-placeholder__timeline-item">
      <div className="sidefilter-placeholder__header" />
      <div className="sidefilter-placeholder__content">
        <div className="sidefilter-placeholder__animated-background">
          <div className="sidefilter-placeholder__pillar-wrap">
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__full-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__full-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
            <div className="sidefilter-placeholder__small-pillar" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

SideFilterPlaceholder.displayName = "SideFilterPlaceholder";

export default SideFilterPlaceholder;
