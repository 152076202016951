import styled from "@emotion/styled";
import { ClearableInput } from "../../components";
import colors from "../../utils/colors";

export const Input = styled(ClearableInput)`
  appearance: none;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
  grid-area: input;

  & input {
    border-color: #e4e8eb;
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0;
    padding: 0 28px 0 12px;
  }

  & input:focus {
    border: 1px solid ${colors.brightBlue};
  }

  & input::placeholder {
    color: #a0aab9;
  }
`;
