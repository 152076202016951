// feat: usePaymentMethods

import isNil from "ramda/src/isNil";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { CardEntry } from "../../components";
import CardErrorContext from "../../components/CardArea/context.card-error";
import { hasLoaded, isLoading, hasError } from "../../constants/status";
import { withStripe } from "../../hoc";
import { useNotifications } from "../../hooks/use-notifications";
import { selectors } from "../../store";
import RefreshOrgAdminContext from "../../units/account-settings.subscriptions.common/refresh-org-admin-context";
import { PaymentMethods } from "../../units/payment-methods";
import { isPresent, isAbsent } from "../../utils";
import { extractAPIError } from "../../utils/extract-api-error";
import { ExpiredSubscriptionMessage } from "../common/error.bar.expired.subscription";
import { useStripePaymentMethod } from "../common/use-stripe-payment-method";
import { CancelButton } from "./_button.cancel";
import { SubmitPaymentButton } from "./_button.submit-payment";
import { Actions } from "./_pay.actions";
import { Container } from "./_pay.container";
import { Title } from "./_pay.title";
import { cardEntryStyle } from "./_style.card-entry";
import { getStripeToken } from "./get-stripe-token";
import { PlanInfo } from "./pay.plan-info";
import { useCreateSubscription } from "./use-create-subscription";

const notification = {
  success: "Your subscription is now active. An email receipt has been sent.",
};

export const OldPay = withStripe((props) => {
  const pushNotification = useNotifications();
  const { card } = useStripePaymentMethod();
  const { create, status, error } = useCreateSubscription();
  const refreshOrgAdmin = useContext(RefreshOrgAdminContext);
  const { stripe, plan, next, prev, back, organizationName: orgName } = props;
  const [forceDisabled, setForceDisabled] = useState(false);
  const [localError, setLocalError] = useState("");
  const [savedCardIgnored, setSavedCardIgnored] = useState(false);

  const hasNoPlan = isNil(plan);
  const ignoreSavedCard = () => setSavedCardIgnored(true);
  const hasNoSavedCard = isNil(card);
  const invalidOrgName = isNil(orgName) || orgName.length === 0;
  const organizationName = invalidOrgName ? {} : { organizationName: orgName };
  const isDisabled = isLoading(status) || forceDisabled;

  if (hasNoPlan) {
    prev();

    return null;
  }

  useEffect(() => {
    if (hasLoaded(status)) {
      pushNotification({ message: notification.success });
      setForceDisabled(false);
      setLocalError("");
      refreshOrgAdmin();
      next();
    }

    if (hasError(status)) {
      setForceDisabled(false);
      setLocalError(extractAPIError(error));
    }
  }, [status]);

  const cardAreaProps = {
    cardError: "",
    cardInfo: card,
    showSavedCard: !!card,
    disabled: isLoading(status),
    offerToRemember: false,
  };

  const submit = async (e) => {
    e.preventDefault();
    if (isLoading(status)) return;

    setLocalError("");

    const payload = { planCode: plan.planCode, ...organizationName };

    if (savedCardIgnored || hasNoSavedCard) {
      const { token, tokenCreationError } = await getStripeToken(stripe);

      if (isPresent(tokenCreationError)) {
        setLocalError(tokenCreationError);
      } else {
        payload.cardToken = token;
        payload.shouldRemember = true;
      }
    }

    if (isAbsent(localError)) create(payload);
  };

  return (
    <CardErrorContext.Provider value={localError}>
      <Container onSubmit={submit}>
        <Title>Confirm Payment Information</Title>
        <PlanInfo plan={plan} />

        <ExpiredSubscriptionMessage disableCheckout={setForceDisabled} />

        <CardEntry
          css={cardEntryStyle}
          label="Card"
          cardAreaProps={cardAreaProps}
          onUserInput={ignoreSavedCard}
        />

        <Actions>
          <CancelButton type="button" onClick={back}>
            Cancel
          </CancelButton>

          <SubmitPaymentButton type="submit" disabled={isDisabled}>
            {isLoading(status) ? "Loading..." : "Purchase Subscription"}
          </SubmitPaymentButton>
        </Actions>
      </Container>
    </CardErrorContext.Provider>
  );
});

const NewPay = (props) => {
  const { plan, prev, organizationName } = props;
  const hasNoPlan = isNil(plan);

  if (hasNoPlan) {
    prev();
    return null;
  }

  const item = {
    type: "subscription",
    planCode: plan.planCode,
  };

  if (typeof organizationName === "string" && organizationName.length > 0) {
    item.organizationName = organizationName;
  }

  const amount = parseInt(
    plan.amount.replace(/^\$/, "").replaceAll(/,/g, ""),
    10
  );

  return (
    <Container>
      <Title>Confirm Payment Information</Title>
      <PlanInfo plan={plan} />
      <PaymentMethods
        amount={amount * 100}
        item={item}
        url="/cb/create-subscription"
      />
    </Container>
  );
};

export const Pay = (props) => {
  const usePaymentMethods = useSelector(
    selectors.configuration.usePaymentMethods
  );

  return usePaymentMethods ? <NewPay {...props} /> : <OldPay {...props} />;
};
