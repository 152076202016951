import { css } from "@emotion/react";
import { Form } from "@kofile/react-form";
import { parse } from "query-string";
import { useEffect } from "react";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import ErrorBar from "../../../components/ErrorBar";
import { PasswordInput } from "../../../components/Fields";
import { actions, selectors } from "../../../store";
import Container from "../_loginRegisterFooter";
import FormFooter from "./form-footer";
import config from "./formConfig";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-neutral-lightest);
    border: 1px solid rgba(4, 29, 68, 0.15);
    border-radius: 10px;
    margin: 0 auto;
    width: 400px;
    padding: 20px 40px;
    font-size: 16px;

    & form {
      width: 100%;

      & input {
        background: var(--color-neutral-lightest);
        border: 1px solid rgba(4, 29, 68, 0.1);
        border-radius: 4px;
        box-sizing: border-box;
        height: 32px;
        padding-left: 10px;
        margin: 15px 0;
        width: 100%;
      }

      & input:focus {
        border: 1px solid #296dce;
      }
    }
  `,
  verifiedMsg: css`
    margin: 16px;
  `,
  hasModal: (hasModal) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: ${hasModal ? "20px" : "20px 0 0"};
    color: #041d44;
    font-size: 24px;
  `,
  button: css``,
};

const invalidRedirects = ["/signin", "/register", "/signout"];

const SignInForm = (props) => {
  const {
    isLoading,
    loginError,
    isModal = false,
    close,
    location,
    verified,
    loggedIn,
    workspaceID,
    history,
    actionOnSignIn,
  } = props;

  const handleSubmit = ({ isValid, formValues }) => {
    if (isValid) {
      props.signIn(formValues);
    }
  };

  const onChange = () => props.loginError && props.clearError();

  const { returnPath } = parse(location.search);
  const registerPath = `/register?returnPath=${encodeURIComponent(returnPath)}`;

  useEffect(() => props.clearError, []);

  useEffect(() => {
    if (!loggedIn) return;

    let { returnPath = "/" } = parse(location.search);

    invalidRedirects.forEach((invalidRedirect) => {
      if (matchPath(returnPath, { path: invalidRedirect })) {
        returnPath = "/";
      }
    });

    if (isModal) {
      close();
      actionOnSignIn();
    } else {
      history.push(decodeURIComponent(returnPath), { workspaceID });
    }
  }, [
    loggedIn,
    isModal,
    close,
    actionOnSignIn,
    location.search,
    workspaceID,
    history,
  ]);

  return (
    <>
      <div css={styles.container}>
        <h1 css={styles.hasModal(isModal)}>Sign In</h1>
        <ErrorBar error={loginError} />
        {verified && (
          <p className="success message" css={styles.verifiedMsg}>
            Your account has been verified.
          </p>
        )}
        <Form
          config={config}
          onChange={onChange}
          onSubmit={handleSubmit}
          method="post"
          render={(fields) => (
            <>
              <input
                type="email"
                placeholder="Email"
                data-testid="loginEmail"
                aria-label="Email"
                required
                {...fields.email.inputProps}
                id="email"
              />

              <PasswordInput
                placeholder="Password"
                showPasswordOption
                required
                ariaLabel="Password"
                {...fields.password.inputProps}
                id="password"
              />

              <FormFooter
                isModal={isModal}
                isLoading={isLoading}
                close={close}
              />
            </>
          )}
        />
      </div>
      {!isModal && (
        <Container>
          Don&apos;t have an account? &nbsp;
          <Link aria-label="Register" to={registerPath}>
            Register
          </Link>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    isLoading: user.loading,
    loginError: user.loginError,
    loggedIn: user.loggedIn,
    workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(actions.user.clearError()),
  signIn: ({ email, password }) =>
    dispatch(actions.user.signIn({ email, password })),
});

export const EnhancedView = withRouter(SignInForm);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedView);
