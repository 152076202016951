import { push } from "connected-react-router";
import { stringify } from "query-string";
import compose from "ramda/src/compose";
import { connect } from "react-redux";
import { actions, selectors } from "../store";
import { extractSearchParams } from "../utils";
import withModal from "./withModal";

const mapStateToProps = (state) => ({
  activeDocID: selectors.docPreview.getDocumentData(state).id,
  selectedDepartment: selectors.workspaces.getSelectedDepartment(state),
  activeWorkspaceID: selectors.workspaces.getActiveWorkspaceID(state),
  docNumber: selectors.docPreview.getDocumentNumber(state),
});

const mapDispatchToProps = (dispatch) => ({
  setActiveDoc: (docID) => dispatch(actions.docPreview.setActiveDoc(docID)),
  addViewed: (docID) => dispatch(actions.docPreview.addViewed(docID)),
  reset: () => dispatch(actions.docPreview.reset()),
  goToDetail:
    ({ workspaceID }) =>
    (docID) => {
      const { pathname, search } = window.location;
      const params = extractSearchParams(
        ["searchValue", "searchOcrText", "searchType", "ocrText"],
        search
      );

      const { searchValue, searchOcrText, searchType, ocrText } = params;
      const isAdvancedSearch = searchType === "advancedSearch";
      const isOCRSearch = searchOcrText === "true" || isAdvancedSearch;
      const highlight = isAdvancedSearch ? ocrText : searchValue;

      dispatch(actions.workspaces.saveSearchUrl(`${pathname}${search}`));

      dispatch(
        push({
          pathname: `/doc/${docID}`,
          search: stringify(isOCRSearch ? { highlight } : ""),
          state: { workspaceID },
        })
      );
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { addViewed, setActiveDoc, reset, goToDetail } = dispatchProps;
  const { activeWorkspaceID } = stateProps;
  const { modal, isFromCart } = ownProps;
  const openPreview = goToDetail({ workspaceID: activeWorkspaceID });

  return { ...ownProps, openPreview };
};

// TODO when removing DocPreview-in-modal, remove this composition
export default compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
);
