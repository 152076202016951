import { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../store";
import { isPresent, getPriceFromCents } from "../../../../utils";
import { style } from "./checkout.styles";

export const ConvenienceFees = ({ convenienceFee }) => {
  const [explanationOpen, setExplanationOpen] = useState(false);

  const helpIcon = useSelector(selectors.assets.getImg("icon_help.svg"));
  const feesCalculatedLater = useSelector(
    selectors.configuration.getCountyFusionExcludeFees
  );
  const convenienceFeeConfig = useSelector(
    selectors.configuration.getConvenienceFeeConfig
  );

  const { applyTo = "perOrder", flat = 0 } = convenienceFeeConfig;

  if (feesCalculatedLater) {
    return (
      <div css={style.lineGeneral} data-testid="checkoutConvenienceFee">
        Fees calculated after selecting a payment provider.
      </div>
    );
  }

  return (
    <div
      css={style.line(isPresent(convenienceFee))}
      data-testid="checkoutConvenienceFee"
    >
      <span>Convenience Fee: </span>
      {applyTo === "perDoc" && (
        <div css={style.help}>
          <span
            onMouseEnter={() => setExplanationOpen(true)}
            onMouseLeave={() => setExplanationOpen(false)}
          >
            <img alt="convenience fee info" src={helpIcon} />
          </span>
          {explanationOpen && (
            <div css={style.explanation.container}>
              <dl css={style.list}>
                <dt css={style.explanation.color}>Convenience Fee Per Doc:</dt>
                <dd css={[style.explanation.color, style.alignRight]}>
                  {getPriceFromCents(flat)}
                </dd>
              </dl>
              <hr />
              <dl css={style.list}>
                <dt css={style.totalColor}>Total Fees:</dt>
                <dd css={[style.totalColor, style.alignRight]}>
                  {getPriceFromCents(convenienceFee)}
                </dd>
              </dl>
              <span css={style.triangle} />
            </div>
          )}
        </div>
      )}
      {getPriceFromCents(convenienceFee)}
    </div>
  );
};
