import { makeActionTypes } from "../../utils";

export default makeActionTypes("docPreview", [
  "UPDATE",
  "VIEWED",
  "SET_PREVIEW_OPEN",
  "SET_PREVIEW_CLOSED",
  "OPEN_POPOUT",
  "CLOSE_POPOUT",
  "SET_ACTIVE_DOC",
  "SET_ACTIVE_VIEW",
  "SET_IS_MARGINAL_REFERENCE",
  "CLEAR_IS_MARGINAL_REFERENCE",
  "CLEAR_ERROR",
  "RESET",
  "UPDATE_PAGE",
  "CLEAR_ACTIVE_VIEW",
  "OPEN_ATTACHMENT_VIEWER",
  "CLOSE_ATTACHMENT_VIEWER",
  "ADD_MARGINAL_REFERENCE_VIEWED",
  "REMOVE_MARGINAL_REFERENCE_VIEWED",
]);
