import styled from "@emotion/styled";

const ExpandButton = styled.button`
  color: #006dd5;
  background: transparent;
  margin: 8px 0;
  display: flex;
  flex-direction: row;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export default ExpandButton;
