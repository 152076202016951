import { useAPI } from "../../hooks/use-api";

export const useChangeSubscription = () =>
  useAPI({
    send: "change-subscription",
    exportAs: "subscriptions",
    listenFor: {
      success: "subscription-changed",
      failure: "error-changing-subscription",
    },
  });
