import { css } from "@emotion/react";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const styles = {
  searchIcon: css`
    color: rgba(4, 29, 68, 0.7);
    height: 18px;
    padding: 7px;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
  `,
};

class TokenWrap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
    };

    this.setInputFocused = (inputFocused) => this.setState({ inputFocused });
    this.handleBlur = () => this.setInputFocused(false);
    this.handleFocus = () => {
      this.props.openMenu();
      this.setInputFocused(true);
    };
    this.handleItemClick = (token) => () => this.props.removeItem(token);
  }

  render() {
    const {
      hidePills,
      getInputProps,
      selectedItems,
      openAllDropdowns,
      exIcon,
      docGroupIcon,
      searchIcon,
      labelLookupTable,
      disabled,
    } = this.props;

    const mappedSelections = selectedItems.map((token, index) => (
      <div key={index} className="react-tokenized-select__token">
        <img
          className="tokenized-nested-select__doc-group"
          src={docGroupIcon}
          alt="ex icon"
        />
        {labelLookupTable[token]}
        <button
          type="button"
          className="react-tokenized-select__remove-button"
          onClick={this.handleItemClick(token)}
          disabled={disabled}
        >
          <img src={exIcon} alt="ex icon" />
        </button>
      </div>
    ));

    const inputProps = {
      onChange: openAllDropdowns,
      className: "react-tokenized-select__input",
      placeholder: "Filter Document Types",
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      disabled,
      role: "combobox",
    };

    const wrapperClass = classnames("react-tokenized-select__token-wrap", {
      "react-tokenized-select__token-wrap--focused": this.state.inputFocused,
    });

    return (
      <div className="react-tokenized-select__token-wrap-container">
        <div className={wrapperClass}>
          {!hidePills && mappedSelections}
          <input {...getInputProps(inputProps)} />
          <img src={searchIcon} alt="search icon" css={styles.searchIcon} />
        </div>
      </div>
    );
  }
}

TokenWrap.displayName = "TokenWrap";

TokenWrap.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  openAllDropdowns: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  exIcon: PropTypes.node,
  docGroupIcon: PropTypes.node,
  hidePills: PropTypes.bool,
  labelLookupTable: PropTypes.object.isRequired,
};

const mapStateToProps = ({ assets }) => ({
  exIcon: assets.img["icon_pill_remove.svg"],
  docGroupIcon: assets.img["icon_doc_group.svg"],
  searchIcon: assets.img["icon_search.svg"],
});

export default connect(mapStateToProps)(TokenWrap);
