import { useEffect } from "react";
import { LoadingSpinner } from "../../components";
import { isNotNil } from "../../utils";
import { chooseViewFor } from "../../utils/choose-view-for";
import { ContactSupport } from "../common/contact-support";
import { HistoryTable } from "./history.table";
import { useSubscriptionAdminHistory } from "./use-subscription-admin-history";

export const Admin = (organizationId) => {
  const { data, status, error, initiate } = useSubscriptionAdminHistory();

  useEffect(() => {
    if (isNotNil(organizationId)) {
      initiate(organizationId);
    }
  }, [organizationId]);

  return chooseViewFor(status).when({
    isInit: () => <LoadingSpinner />,
    isLoading: () => <LoadingSpinner />,
    hasLoaded: () => <HistoryTable orders={data.orders} isAdmin />,
    default: () => <ContactSupport error={error} />,
  });
};

export default Admin;
