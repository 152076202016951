import styled from "@emotion/styled";

export const LinkContainer = styled.div`
  & a {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 0.6rem;
  }
`;
