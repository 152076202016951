/* global htmx -- Utilizes Htmx-powered fragments from API */

import { css } from "@emotion/react";
import { useEffect } from "react";

const style = css`
  .htmx-indicator {
    display: none;
  }

  .htmx-indicator.account-loader {
    display: block;
    width: 100px;
    margin: 120px auto 0;
  }
`;

export const ConfigurePaymentMethods = () => {
  useEffect(() => {
    if (typeof htmx !== "undefined") htmx.process(document.body);
  }, []);

  return (
    <div
      css={style}
      hx-get={`/get-configurable-payment-methods`}
      hx-trigger="load, refreshConfigurePaymentMethods from:body"
    >
      <loading-indicator
        id="loading"
        class="htmx-indicator account-loader"
      ></loading-indicator>
    </div>
  );
};
