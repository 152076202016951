import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import { mapIndexed, toHalfs } from "../../../utils";

const format = (value) => {
  const typeIs = (type) => typeof value === type;

  return typeIs("string") || typeIs("number") ? value : "";
};

// Components
const Column = ({ label, value }, i) => (
  <li key={i} className="doc-preview-summary__column-list-item">
    <span className="doc-preview-summary__column-span">{label}</span>
    <span
      className="doc-preview-summary__column-span"
      data-testid="docPreviewSummaryItemValue"
    >
      {format(value)}
    </span>
  </li>
);

// Wrappers
const RowWrap = (rows, i) => (
  <ul className="doc-preview-summary__columns-list" key={i}>
    {rows.map(Column)}
  </ul>
);

const ColumnsWrap = (children) => (
  <div className="doc-preview-summary__columns">{children}</div>
);

const Columns = compose(
  ColumnsWrap,
  mapIndexed(RowWrap),
  toHalfs,
  prop("columns")
);

export default Columns;
