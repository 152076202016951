import styled from "@emotion/styled";

export const Form = styled.form`
  background: var(--color-neutral-lightest);
  border: 1px solid rgba(4, 29, 68, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  margin: 80px auto 0;
  padding: 32px 0;
  width: 450px;
`;
