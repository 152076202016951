import { css } from "@emotion/react";
import { TokenizedSelect as Select } from "@kofile/react-tokenized-select";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSuggestions } from "../../hooks/use-suggestions";
import { selectors } from "./../../store";

const separatorStyle = css`
  .react-tokenized-select__separator {
    display: inline-block;
    padding-left: 6px;
    padding-top: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #041d44;
  }
`;

export const mapStateToProps = (state) => ({
  exIcon: state.assets.img["icon_pill_remove.svg"],
});

const TokenizedSelectWithSuggestions = (props) => {
  const exIcon = useSelector(selectors.assets.getImg("icon_pill_remove.svg"));
  const suggestionsProps = useSuggestions(props.id);

  useEffect(() => {
    const newError = suggestionsProps.error?.reason?.message;

    if (typeof props.updateFieldError !== "function") return;
    if ((newError || "") === props.error) return;

    props.updateFieldError(newError);
  }, [suggestionsProps.error, props.error, props.updateFieldError]);

  return (
    <Select
      {...props}
      {...suggestionsProps}
      exIcon={exIcon}
      css={separatorStyle}
    />
  );
};

export const TokenizedSelect = (props) => {
  if (props.useSuggestions === false) {
    const exIcon = useSelector(selectors.assets.getImg("icon_pill_remove.svg"));

    return <Select {...props} exIcon={exIcon} css={separatorStyle} />;
  }

  return <TokenizedSelectWithSuggestions {...props} />;
};

export default TokenizedSelect;
