import { Chevron } from "@kofile/icons";
import classnames from "classnames";
import PropTypes from "prop-types";
import { compose, defaultProps, withState, withHandlers } from "recompose";

const enhance = compose(
  defaultProps({
    isOpen: false,
    onChange: () => {},
  }),
  withState("isOpen", "setIsOpen", (props) => props.isOpen),
  withHandlers({
    onToggleClick:
      ({ isOpen, setIsOpen, onChange }) =>
      () => {
        setIsOpen(!isOpen);
        onChange(!isOpen);
      },
  })
);

export const Filter = (props) => {
  const { title, isOpen, onToggleClick, children } = props;

  const filterToggleClass = classnames("filter__toggle", {
    "filter__toggle--is-open": isOpen,
  });

  return (
    <div className="filter">
      <button className={filterToggleClass} onClick={onToggleClick}>
        <div className="filter__flexwrap">
          <div className="filter__title">{title}</div>
          <div className="filter__align-right">
            <Chevron
              id="filter-chevron-icon"
              className="icon--small"
              rotation={isOpen ? 0 : 270}
            />
          </div>
        </div>
      </button>
      {isOpen && <section className="filter__content">{children}</section>}
    </div>
  );
};

Filter.displayName = "Filter";
Filter.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
  children: PropTypes.node,
};

export default enhance(Filter);
