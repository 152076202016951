import { css } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { actions, selectors } from "../../../../../store";
import { SettingsPane } from "../settings-pane";

const style = css`
  max-width: 960px;

  .doc-preview .image {
    height: 750px;
  }

  .doc-preview,
  .doc-preview__body,
  .docpreview-header {
    background: transparent;
  }

  .docpreview-header {
    border-bottom: 0;
  }
`;

// NOTE this is not very efficient, as we're fetching all of the user's
// matches instead of the one we're interested. We'd need a new endpoint on
// FTS for /:tenant/matches/:id to clean this up.
const FraudAlertMatch = () => {
  const dispatch = useDispatch();
  const { matchId } = useParams();
  const { getMatch } = selectors.fraudAlert;
  const { fetchMatchRecords } = actions.fraudAlert;
  const match = useSelector(getMatch(matchId));

  useEffect(() => void dispatch(fetchMatchRecords()), []);

  if (match) {
    const { docId } = match;

    return <Redirect to={`/doc/${docId}`} />;
  }

  return (
    <SettingsPane css={style}>{!match && <h1>Loading...</h1>}</SettingsPane>
  );
};

FraudAlertMatch.displayName = "FraudAlertMatch";

export default FraudAlertMatch;
