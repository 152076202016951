import { passwordValidations } from "./../../../utils/formValidation";

const getFormConfig = () => ({
  firstName: {
    id: "firstName",
    type: "StringInput",
    ariaLabel: "First Name",
    placeholder: "First Name",
    fieldId: "first-name",
    autoFocus: true,
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "matches",
        arg: /[^0-9]/,
        error: "Input must be a valid first name",
      },
    ],
  },
  lastName: {
    id: "lastName",
    type: "StringInput",
    ariaLabel: "Last Name",
    placeholder: "Last Name",
    fieldId: "last-name",
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "matches",
        arg: /[^0-9]/,
        error: "Input must be a valid last name",
      },
    ],
  },
  email: {
    id: "email",
    type: "EmailInput",
    ariaLabel: "Email",
    placeholder: "Email",
    fieldId: "sign-in-input-email",
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "isEmail",
        error: "Input must be a valid email",
      },
    ],
  },
  password: {
    id: "password",
    type: "PasswordInput",
    ariaLabel: "Password",
    placeholder: "Password",
    fieldId: "sign-in-input-password",
    showPasswordOption: true,
    validations: passwordValidations,
  },
});

export default getFormConfig;
