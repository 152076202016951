import { css } from "@emotion/react";
import { Triangle, History } from "@kofile/icons";

const style = {
  control: css`
    margin: 0 auto;
    background: #f5f6f9;
    border: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: inherit;

    & > * {
      margin: 0 5px;
    }
  `,
  icon: {
    history: css`
      width: 17px;
    `,
    control: css`
      width: 10px;
    `,
  },
};

const Toggle = ({ onClick, isOpen }) => {
  const buttonProps = {
    onClick,
    "data-tourid": "searchHistoryControl",
    css: style.control,
  };

  const indicatorProps = {
    "data-testid": "searchHistoryLink",
    css: style.icon.control,
    id: `history-triangle-${isOpen ? "open" : "closed"}`,
    filled: true,
    rotation: isOpen ? 270 : 90,
  };

  return (
    <button {...buttonProps}>
      <History css={style.icon.history} id="history-icon" />
      View Recent Search History
      <Triangle {...indicatorProps} />
    </button>
  );
};

Toggle.defaultProps = {
  isOpen: false,
  onClick: () => {},
};

export default Toggle;
