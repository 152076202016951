import { ModalShell } from "../../../../components";
import ConfirmActionModal from "../../../../units/account-settings.common/ConfirmActionModal";

const Modal = ({ errorMessage, clearDownload = () => {}, close }) =>
  Object.assign(
    () => {
      const content = <p>{errorMessage}</p>;

      const clear = () => {
        clearDownload();
        close();
      };

      const confirmationProps = {
        title: "Document Download Error",
        content,
        cancel: { onClick: clear, text: "Close" },
      };

      return (
        <ModalShell close={clear}>
          <ConfirmActionModal {...confirmationProps} />
        </ModalShell>
      );
    },
    { displayName: "ConfirmModal" }
  );

export default Modal;
