import { css } from "@emotion/react";
import { Button } from "../../components/button";
import { CancelButton } from "../../components/cancel-button";

const style = {
  wrap: css`
    background: var(--color-neutral-lightest);
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    button {
      min-width: 100px;
    }

    & > * + * {
      margin-left: 0.8rem;
    }
  `,
};

const Footer = ({ onCancel = () => {}, disabled = true }) => (
  <div css={style.wrap}>
    <CancelButton onClick={onCancel} type="button">
      Cancel
    </CancelButton>

    <Button disabled={disabled} type="submit" data-testid="addButton">
      Add
    </Button>
  </div>
);

export default Footer;
