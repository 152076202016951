import compose from "ramda/src/compose";
import set from "ramda/src/set";
import view from "ramda/src/view";
import { onlineStatus as initialState } from "../initialState";
import lens from "../lenses/online-status";
import types from "../types/online-status";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.CONNECTING]: connecting,
  [types.CONNECTED]: connected,
  [types.DISCONNECTED]: disconnected,
  [types.RESET_STATE]: reset,
});

function connecting(state) {
  const setAttemptConnectAt = set(lens.attemptConnectAt, Date.now());
  const setDisconnectedAt = set(lens.disconnectedAt, 1);
  const getAttemptedAt = view(lens.attemptConnectAt);
  const reconnecting = getAttemptedAt(state) !== getAttemptedAt(initialState);

  if (reconnecting) {
    return compose(setAttemptConnectAt, setDisconnectedAt)(state);
  }

  return setAttemptConnectAt(state);
}

function connected(state) {
  const setConnectedAt = set(lens.connectedAt, Date.now());
  const setConnected = set(lens.connected, true);

  return compose(setConnectedAt, setConnected)(state);
}

function disconnected(state) {
  const setDisconnectedAt = set(lens.disconnectedAt, Date.now());
  const setConnected = set(lens.connected, false);

  return compose(setDisconnectedAt, setConnected)(state);
}

function reset() {
  return initialState;
}
