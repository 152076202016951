import { css } from "@emotion/react";
import includes from "ramda/src/includes";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../../../components";
import { selectors } from "../../../../../../store";
import { isAbsent } from "../../../../../../utils";

const inputStyles = css`
  input {
    appearance: auto;
    cursor: pointer;
  }

  label {
    padding-left: 5px;
    cursor: pointer;
  }
`;

const styles = {
  contactWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    ${inputStyles}
  `,
  a: css`
    color: #006dd5;
    text-decoration: underline;
  `,
  title: css`
    font-size: 1.2rem;
    font-weight: 500;
    padding: 1.2rem 0;
  `,
  support: css`
    font-size: 1.1rem;
    font-weight: 500;
    padding: 20px 0 5px 0;
  `,
  tos: css`
    padding-top: 30px;
  `,
  checkboxContainer: css`
    padding: 1rem 0 0.5rem 0;
    ${inputStyles}
  `,
};

const PropertyAlertContactSettings = ({
  formData,
  handleInputChange,
  isLoading,
}) => {
  const fraudAlert = useSelector(selectors.configuration.getFraudAlert);
  const alertMethodsConfig = propOr([], "alertMethods", fraudAlert);
  const countyUsesPhone =
    includes("sms", alertMethodsConfig) ||
    includes("voice", alertMethodsConfig);

  if (fraudAlert.enabled !== true || isAbsent(alertMethodsConfig)) return null;
  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <h3 css={styles.title}>Property Alert Notification(s) Preferences</h3>
      <p>
        Choose your preferences for how you’d like to be notified about Property
        Alerts. If you want to disable a Property Alert entirely, please visit
        the{" "}
        <a css={styles.a} href="/account/property-alert">
          Property Alert Dashboard
        </a>
        .
        {countyUsesPhone &&
          ' To stop receiving messages via phone or text, unselect the options below or reply to an SMS notification with "STOP."'}
      </p>
      <div css={styles.contactWrapper}>
        <div>
          <input
            type="checkbox"
            name="contactByEmail"
            checked={true}
            onChange={handleInputChange}
            disabled
          />
          <label htmlFor="contactByEmail">Email</label>
        </div>
        {includes("sms", alertMethodsConfig) && (
          <div>
            <input
              id="contactByText"
              type="checkbox"
              name="contactByText"
              checked={formData.contactPreferences.includes("sms")}
              onChange={handleInputChange}
            />
            <label htmlFor="contactByText">Text Message</label>
          </div>
        )}
        {includes("voice", alertMethodsConfig) && (
          <div>
            <input
              id="contactByPhone"
              type="checkbox"
              name="contactByPhone"
              checked={formData.contactPreferences.includes("voice")}
              onChange={handleInputChange}
            />
            <label htmlFor="contactByPhone">Voice Phone Call</label>
          </div>
        )}
      </div>
      <p>
        If there is a match to your Property Alert criteria, you can receive at
        most one message a day.{" "}
        {countyUsesPhone && "Message and data rates may apply."}
      </p>
      {countyUsesPhone && (
        <div css={styles.checkboxContainer}>
          <input
            id="consentToContact"
            type="checkbox"
            name="consentToContact"
            checked={formData.consentToContact}
            onChange={handleInputChange}
          />
          <label htmlFor="consentToContact">
            I consent to receiving Text SMS and/or Voice Calls
          </label>
        </div>
      )}
      <h4 css={styles.support}>For support:</h4>
      <p>
        Email:{" "}
        <a css={styles.a} href="mailto:support@govos.com">
          support@govos.com
        </a>
      </p>
      {countyUsesPhone && <p>For help with Text Messages reply HELP</p>}
      <p>Call: (833) 456-3453</p>
      <p css={styles.tos}>
        <a css={styles.a} href="https://www.govos.com/terms-and-conditions">
          Terms and Conditions
        </a>
        {"  |  "}
        <a css={styles.a} href="https://www.govos.com/privacy-policy">
          Privacy Policy
        </a>
      </p>
    </>
  );
};

export default PropertyAlertContactSettings;
