import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../components";
import { selectors } from "../../store";
import { getPriceFromCents } from "../../utils";

const InfoText = ({ label, value, isLoading }) => (
  <div className="checkout__text" data-testid="checkoutDocInfo">
    {`${label}: `}
    {isLoading ? <LoadingSpinner className="checkout__text--loading" /> : value}
  </div>
);

const DocumentInfo = (props) => {
  const { getCountyFusionExcludeFees } = selectors.configuration;
  const hideFees = useSelector(getCountyFusionExcludeFees);
  const price = hideFees ? props.subtotal : props.totalPrice;

  return (
    <div className="checkout__info-wrap">
      <InfoText
        label="Total Pages"
        value={props.totalPagesSelected}
        isLoading={props.isLoading}
      />
      {Boolean(props.convenienceFee) && !hideFees && (
        <InfoText
          label="Fees"
          value={getPriceFromCents(props.convenienceFee)}
          isLoading={props.isLoading}
        />
      )}
      <InfoText
        label={hideFees ? "Subtotal" : "Total Price"}
        value={props.totalPrice === 0 ? "Free" : getPriceFromCents(price)}
        isLoading={props.isLoading}
      />
    </div>
  );
};

DocumentInfo.defaultProps = {
  totalPagesSelected: 0,
  totalPrice: 0,
  subtotal: 0,
  convenienceFee: 0,
  isLoading: false,
};

export default DocumentInfo;
