import { css } from "@emotion/react";

const styles = {
  input: css`
    margin-bottom: 16px;
    border: 1px solid rgba(4, 29, 68, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.8em;
    font-weight: lighter;
    height: 32px;
    padding: 0 0.5rem;
    width: 100%;
  `,
  label: css`
    margin: 0.5rem 0;
    font-weight: 600;
    font-size: 0.9rem;
    padding-top: 10px;
  `,
  email: css`
    margin-bottom: 16px;
    font-size: 0.9rem;
  `,
};

const UserInfo = ({ formData, handleInputChange, email }) => {
  return (
    <>
      <label css={styles.label} htmlFor="firstName">
        First Name
      </label>
      <input
        css={styles.input}
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={handleInputChange}
        placeholder="First Name"
        aria-label="First Name"
      />

      <label css={styles.label} htmlFor="lastName">
        Last Name
      </label>
      <input
        css={styles.input}
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={handleInputChange}
        placeholder="Last Name"
        aria-label="Last Name"
      />

      <label css={styles.label} htmlFor="phoneNumber">
        Phone Number
      </label>
      <input
        css={styles.input}
        type="tel"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        placeholder="Phone Number"
        aria-label="Phone Number"
      />

      <label css={styles.label} htmlFor="email">
        Email
      </label>
      <div css={styles.email}>{email}</div>
    </>
  );
};

export default UserInfo;
