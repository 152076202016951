import { useRef, useState } from "react";

const toList = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join(",");

export const usePopup = (url, windowName) => {
  const [closedByUser, setClosedByUser] = useState(false);
  const closedInterval = useRef(null);
  const popupRef = useRef(null);

  const windowFeatures = {
    resizable: "yes",
    scrollbars: "yes",
    status: "yes",
    width: 950,
    height: 950,
  };

  const pollingInterval = () =>
    setInterval(() => {
      if (popupRef.current && popupRef.current.closed) {
        setClosedByUser(true);
        popupRef.current = null;
        closedInterval.current && clearInterval(closedInterval.current);
      }
    }, 500);

  const close = () => {
    if (popupRef.current) {
      closedInterval.current && clearInterval(closedInterval.current);
      popupRef.current.close();
      popupRef.current = null;
    }
  };

  const open = () => {
    if (!url) {
      return;
    }

    popupRef.current = window.open(url, windowName, toList(windowFeatures));

    if (popupRef.current) {
      popupRef.current.focus();

      closedInterval.current = pollingInterval();
    }
  };

  return { closedByUser, close, open };
};
