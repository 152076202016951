/**
 * Prefixing function to scope related actions.
 *
 * @param {string} domain prefix
 * @returns {(actionName: string) => string} the prefixed action name
 */
export const domainify = (domain) => (actionName) =>
  [domain, actionName].join("/");

/**
 * Applies a domain prefix to an array of action names.
 *
 * @param {string} domain prefix
 * @param {string[]} types array of action names
 * @returns {object} an object with { actionName: prefixedActionName } pairs
 */
export const makeActionTypes = (domain, types) => {
  const inDomain = domainify(domain);

  return types.reduce(
    (memo, type) => ({
      ...memo,
      [type]: inDomain(type),
    }),
    {}
  );
};
