// mod: doc-preview

import all from "ramda/src/all";
import allPass from "ramda/src/allPass";
import always from "ramda/src/always";
import assoc from "ramda/src/assoc";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import curry from "ramda/src/curry";
import either from "ramda/src/either";
import equals from "ramda/src/equals";
import head from "ramda/src/head";
import ifElse from "ramda/src/ifElse";
import includes from "ramda/src/includes";
import map from "ramda/src/map";
import not from "ramda/src/not";
import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import pathSatisfies from "ramda/src/pathSatisfies";
import prop from "ramda/src/prop";
import propEq from "ramda/src/propEq";
import propOr from "ramda/src/propOr";
import when from "ramda/src/when";
import { createSelector } from "reselect";
import { labels as dateRangeLabels } from "../../units/search.basic/basic-search.utils";
import { equalsInsensitive, isAbsent, isPresent } from "../../utils";
import { getConfigForDepartment } from "./configuration/get-config-for-department";
import { getConfiguration } from "./configuration/get-configuration";
import { getDepartments } from "./configuration/get-departments";

export { getConfigForDepartment } from "./configuration/get-config-for-department";
export { getConfiguration } from "./configuration/get-configuration";
export { getDepartments } from "./configuration/get-departments";

export const getTenant = compose(prop("tenant"), getConfiguration);

export const getFeatureFlips = compose(
  propOr({}, "featureFlips"),
  getConfiguration
);

export const shouldHideThumbnailInDept = createSelector(
  [getFeatureFlips, getConfigForDepartment],
  (flips, dept) => {
    const flippedDeptConf = (flips.departments || {})[dept.code];

    return !!dept.hideThumbnails || !!flippedDeptConf?.hideThumbnails || false;
  }
);

export const canUserUpdateSubscription = compose(
  pathOr(false, ["subscriptions", "userCanUpdatePlan"]),
  getFeatureFlips
);

const isSubscriptionApplicationEnabled = compose(
  pathOr(false, ["subscriptionApplication", "enabled"]),
  getConfiguration
);

export const getSubscriptionApplicationEnabled = createSelector(
  [getFeatureFlips, isSubscriptionApplicationEnabled],
  (flips, isEnabled) => {
    return flips.useSubscriptionApplication === true && isEnabled;
  }
);

export const getFeedbackForm = compose(
  propOr(false, "enableFeedbackForm"),
  getConfiguration
);

export const isNewDocPreviewEnabled = createSelector([], () => true);

export const isNavDocsByBookEnabled = compose(
  pathOr(false, ["bookVolumePageNavigation", "enabled"]),
  getConfigForDepartment
);

export const getTenantName = compose(prop("tenantName"), getConfiguration);

export const getFraudAlert = compose(
  propOr({}, "fraudAlert"),
  getConfiguration
);

export const availableFraudAlertContactMethods = compose(
  path(["fraudAlert", "alertMethods"]),
  getConfiguration
);

export const isFraudAlertEnabled = createSelector(
  [getConfiguration],
  (config) => {
    const hasFraudAlert = pathOr(false, ["fraudAlert", "enabled"], config);

    return hasFraudAlert === true;
  }
);

export const hideFraudAlertNameOption = createSelector(
  [getConfiguration],
  (config) => {
    return pathOr(false, ["fraudAlert", "hideNameOption"], config) === true;
  }
);

export const getLandingLegalCopy = compose(
  pathOr([], ["fraudAlert", "verbiage", "landingPage", "legalCopy"]),
  getConfiguration
);

export const getCountyName = compose(prop("countyName"), getConfiguration);

const defaultLimitedSearch = { enabled: false };

export const getLimitedSearch = compose(
  pathOr(defaultLimitedSearch, ["limitedSearch"]),
  getConfiguration
);

export const isLimitedSearchEnabled = createSelector(
  [getLimitedSearch],
  (limitedSearch) => limitedSearch.enabled
);

export const getPricingConfiguration = compose(
  prop("pricing"),
  getConfiguration
);

export const isNCourtEnabled = compose(
  (model) => model === "nCourt",
  path(["pricing", "creditCardModel"]),
  getPricingConfiguration
);

export const usePaymentMethods = createSelector(
  [getFeatureFlips],
  propEq("usePaymentMethods", true)
);

export const isStripeEnabled = createSelector(
  [usePaymentMethods, isNCourtEnabled],
  (...args) => all(equals(false))(args)
);

export const getDepartmentPricing = (state) => (deptCode) => {
  const pricingConfiguration = getPricingConfiguration(state);

  const deptPricePath = ["departments", deptCode, "pricing"];
  const deptPrice = path(deptPricePath, pricingConfiguration);
  const generalPrice = prop("pricing", pricingConfiguration);

  return deptPrice || generalPrice || null;
};

export const getPricePerPage = compose(
  path(["pricing", "perPage"]),
  getPricingConfiguration
);

export const getCountyFusionIsEnabled = compose(
  path(["pricing", "countyFusionEscrow", "isEnabled"]),
  getPricingConfiguration
);

export const getCountyFusionLoginUrl = compose(
  path(["pricing", "countyFusionEscrow", "loginUrl"]),
  getPricingConfiguration
);

export const getCountyFusionExcludeFees = compose(
  pathOr(false, ["countyfusionEscrow", "excludeConvenienceFee"]),
  getPricingConfiguration
);

export const getConvenienceFeeConfig = compose(
  propOr({}, "convenienceFee"),
  prop("pricing"),
  getPricingConfiguration
);

export const getDepartmentCodes = createSelector(
  [getDepartments],
  map(prop("code"))
);

export const getFirstDepartmentCode = createSelector(
  [getDepartments],
  compose(prop("code"), head)
);

export const getDepartmentActions = compose(
  propOr([], "actions"),
  getConfigForDepartment
);

export const expressCheckoutEnabled = compose(
  includes("expressCheckout"),
  getDepartmentActions
);

export const certifiedDocsEnabled = compose(
  includes("purchaseCertifiedCopy"),
  getDepartmentActions
);

export const doesNotInclude = complement(includes);

export const checkoutsDisabled = compose(
  allPass([doesNotInclude("addToCart"), doesNotInclude("expressCheckout")]),
  getDepartmentActions
);

export const onlyCertifiedCheckout = createSelector(
  [certifiedDocsEnabled, checkoutsDisabled],
  (certifiedDocsEnabled, checkoutsDisabled) =>
    certifiedDocsEnabled && checkoutsDisabled
);

export const getTenantId = compose(propOr("", "tenantId"), getConfiguration);

export const getDocTypeMappings = compose(
  prop("docTypeMappings"),
  getConfiguration
);

export const getDocPreview = compose(
  propOr({}, "docPreviewConfig"),
  getConfigForDepartment
);

export const getDocPreviewSummary = compose(
  propOr({}, "summary"),
  getDocPreview
);

export const getDocPreviewLegals = (state) => {
  const { legals = {} } = getDocPreviewSummary(state);
  const { order = [], enabled = false, columnsByType = {} } = legals;

  return {
    order,
    enabled,
    columnsByType,
  };
};

export const getSealUrl = compose(propOr("", "sealUrl"), getConfiguration);

export const getAnnouncement = compose(
  either(
    pathOr(null, ["announcement", "message"]),
    ifElse(
      pathSatisfies(equals(true), [
        "admin",
        "tenantBanner",
        "bannerIsPublished",
      ]),
      pathOr(null, ["admin", "tenantBanner", "bannerContent"]),
      always(null)
    )
  ),
  getConfiguration
);

export const getDatePresets = compose(prop("datePresets"), getConfiguration);

export const departmentOptions = createSelector(
  [getDepartments],
  (departments) =>
    departments.map(({ code, name }) => ({
      label: name,
      value: code,
    }))
);

export const getCurrentDeptDocTypeMappings = (state, deptCode) => {
  const docTypeMappings = getDocTypeMappings(state) || [];

  const deptConfig = docTypeMappings.find(
    compose(equalsInsensitive(deptCode), prop("code"))
  );

  return propOr([], "docGroup", deptConfig);
};

export const getAllDocTypesForDept = (state, deptCode) => {
  const currentDeptMapping = getCurrentDeptDocTypeMappings(state, deptCode);

  return currentDeptMapping.reduce((acc, { docType }) => {
    const typesForGroup = docType.map(prop("code"));

    return acc.concat(typesForGroup);
  }, []);
};

export const getDocTypeLookupTable = compose(
  prop("docTypeLookupTable"),
  getConfiguration
);

export const getDocTypeName = compose(
  (lookupTable) => (code) => lookupTable[code],
  getDocTypeLookupTable
);

export const getPreviewConfig = compose(
  prop("docPreviewConfig"),
  getConfigForDepartment
);

export const getDeptParties = compose(
  propOr([], "partyFields"),
  getConfigForDepartment
);

export const getDeptLegalFields = compose(
  prop("legalFieldsByType"),
  getConfigForDepartment
);

export const getDeptRequiredFields = compose(
  pathOr([], ["advancedSearch", "required"]),
  getConfigForDepartment
);

export const getDocNumberFormat = compose(
  prop("docNumberFormat"),
  getConfiguration
);

export const getInstNumberFormat = compose(
  prop("instNumberFormat"),
  getConfigForDepartment
);

export const getSideFilters = compose(
  propOr([], "searchFilters"),
  getConfigForDepartment
);

const cardConfig = compose(propOr([], "cardConfig"), getConfigForDepartment);

export const getCardConfig = createSelector([cardConfig], (cardConfig) => {
  if (Array.isArray(cardConfig) && cardConfig.length === 0) return [];
  return cardConfig;
});

export const getLegalsCardConfig = createSelector(
  getCardConfig,
  ({ legals = {} }) => {
    const {
      columnsByType = [],
      defaultView = "show-first",
      enabled = false,
    } = legals;

    return {
      enabled,
      columnsByType,
      defaultView,
    };
  }
);

export const getIsCertified = compose(
  propOr([], "isCertified"),
  getConfigForDepartment
);

const getExternalCertificationProvider = compose(
  pathOr({}, ["certification", "externalCertificationProvider"]),
  getConfiguration
);

const supportedCertProviders = ["triedata"];

const defaultExitConfirmation =
  "Do you really want to leave our site and buy this certified copy? You will be redirected to an external site for checkout and document delivery.";

const constructTriedata = (externalCerts, departmentIsCertified) => {
  const {
    enabled = true,
    serviceProvider = "",
    copy = {},
    url = {},
    publisherCode = "",
    docType = "",
    subscriberCode = "",
  } = externalCerts;

  const noExitMsg = isAbsent(prop("exitConfirmation", copy));

  const buildUrl = curry((path, params) => {
    const urlWithPath = `${url.base}${path}`;
    const serviceUrl = new URL(urlWithPath);
    const urlParams = new URLSearchParams(params);
    serviceUrl.search = urlParams.toString();

    return serviceUrl.toString();
  });

  const buildPurchaseUrlWithParams = (paramArgs = {}) => {
    const params = { publisherCode, docType, subscriberCode, ...paramArgs };
    return buildUrl(url.purchasePath, params);
  };

  return {
    serviceProvider,
    make: {
      purchaseUrl: buildPurchaseUrlWithParams,
      receiptUrl: buildUrl(url.receiptPath),
    },
    copy: noExitMsg
      ? assoc("exitConfirmation", defaultExitConfirmation, copy)
      : copy,
    enabled: enabled && departmentIsCertified,
  };
};

export const getExternalCertProvider = createSelector(
  [getExternalCertificationProvider, getIsCertified],
  (externalCerts, departmentIsCertified) => {
    const { serviceProvider = "" } = externalCerts;

    const serviceIsValid = supportedCertProviders.includes(serviceProvider);

    if (!serviceIsValid) {
      return { enabled: false, serviceProvider: serviceProvider };
    }

    if (serviceProvider === "triedata") {
      return constructTriedata(externalCerts, departmentIsCertified);
    }
  }
);

export const getDenySearchAfterCertifiedDate = compose(
  prop("denySearchAfterCertifiedDate"),
  getConfigForDepartment
);

export const isSubscriptionEnabled = createSelector(
  [getPricingConfiguration],
  pathOr(false, ["subscriptions", "isEnabled"])
);

export const getSubscriptionHelpUrl = compose(
  pathOr(null, ["subscriptions", "helpUrl"]),
  getConfiguration
);

export const getDepartmentFields = compose(
  propOr([], "fields"),
  getConfigForDepartment
);

export const dateRangePresets = createSelector(
  [getConfigForDepartment, getDatePresets],
  ({ fullTextSearchDateRangeProp: defaultSearchProp }, presets) => {
    const label = dateRangeLabels[defaultSearchProp];

    return [{ label, value: "DefaultValue" }, ...presets];
  }
);

export const showBasicSearchDatePicker = compose(
  propOr(true, "showBasicSearchDatePicker"),
  getConfigForDepartment
);

export const showCoverPage = compose(
  propOr(true, "showCoverPage"),
  getConfigForDepartment
);

export const skipCoverPage = compose(not, showCoverPage);

export const coverPageStartDate = compose(
  propOr(null, "coverPageStartDate"),
  getConfigForDepartment
);

export const externalLinksArePresent = compose(
  pathOr({}, ["externalLinks"]),
  getConfiguration
);

export const getExternalLinks = compose(
  propOr([], "links"),
  externalLinksArePresent
);

export const externalLinksEnabled = compose(
  propOr(false, "enabled"),
  externalLinksArePresent
);

export const areExternalLinksEnabled = createSelector(
  [getExternalLinks, externalLinksEnabled],
  (links, isEnabled) => isPresent(links) && isEnabled
);

export const getClerkTitle = createSelector(
  [getTenant],
  propOr("Admin", "clerkTitle")
);

export const getTenantMidName = createSelector(
  [getTenant],
  prop("tenantMidName")
);

export const getTenantUrl = createSelector(
  [getConfiguration],
  propOr("", "tenantUrl")
);

export const getSupplementaryOfficials = createSelector(
  [getTenant],
  prop("officials")
);

export const getRawClerkTitle = createSelector([getTenant], prop("clerkTitle"));

export const getCountyClerk = createSelector(
  [getConfiguration],
  ({ tenant }) => `${tenant.clerkFirstName} ${tenant.clerkLastName}`
);

export const getClerkPictureUrl = createSelector(
  [getTenant],
  prop("clerkPictureUrl")
);

export const getOfficials = createSelector(
  [getTenant, getCountyClerk, getClerkTitle],
  (tenant, tenantClerk, clerkTitle) => {
    return propOr(
      [{ name: tenantClerk, title: clerkTitle }],
      "officials",
      tenant
    );
  }
);

export const getAdministrativeDivision = createSelector(
  [getTenant],
  propOr("The County of", "administrativeDivision")
);

export const getAdvancedSearch = createSelector(
  [getConfiguration],
  prop("advancedSearch")
);

export const getDepartmentAdvancedSearch = compose(
  prop("advancedSearch"),
  getConfigForDepartment
);

export const getDepartmentResultsViewModes = compose(
  pathOr(["list", "card"], ["searchResult", "viewModes"]),
  getConfigForDepartment
);

export const getSearchTitle = createSelector(
  [getConfiguration],
  propOr("Official Records Search", "searchTitle")
);

export const getBasicSearchToolTips = createSelector(
  [getConfiguration],
  pathOr({}, ["quickSearch", "tooltipText"])
);

export const getDepartmentTooltipText = compose(
  when(equals(""), always(null)),
  propOr(null, "departmentTooltipText"),
  getConfigForDepartment
);
