import concat from "ramda/src/concat";
import find from "ramda/src/find";
import remove from "ramda/src/remove";
import { announcements as initialState } from "../initialState";
import types from "../types/announcements";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.CLEAR_ANNOUNCEMENT]: clearAnnouncement,
  [types.SET_ANNOUNCEMENT]: setAnnouncement,
});

function clearAnnouncement(state, { payload }) {
  return remove(payload, 1, state);
}

function setAnnouncement(state, { payload }) {
  const newMsgHash = hashMessage(payload);

  return find((h) => h[0] === newMsgHash, state)
    ? state
    : concat(state, [[newMsgHash, payload]]);
}

function hashMessage(message) {
  let hash = 0;
  let chr;

  for (let i = 0; i < message.length; i++) {
    chr = message.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}
