import { css } from "@emotion/react";

const button = (theme) => css`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 166px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  margin-left: auto;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    margin-top: 16px;
    margin-left: 0;
  }
`;

export const styles = {
  integration: (theme) => css`
    margin-top: 15px;
    border: 1px solid #dadde2;
    padding: 24px;
    background-color: var(--color-neutral-lightest);
    border-radius: 4px;

    display: grid;
    padding: 24px;
    grid-auto-rows: min-content;
    gap: 24px;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      gap: 16px;
    }
  `,
  connectionType: (theme) => css`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > h4 {
        font-size: 15px;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          line-height: 20px;
        }
      }
      > p {
        font-weight: 500;
      }
    }
  `,
  logo: css`
    width: 31px;
    height: 26px;
  `,
  divider: css`
    background: #dadde2;
    height: 1px;
    width: 100%;
  `,

  account: (theme) => css`
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    line-height: 36px;

    @media screen and (max-width: ${theme.breakpoints.md}px) {
      flex-direction: column;
      align-items: flex-start;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `,
  confirmButton: (theme) => css`
      ${button(theme)};
      background: #006dd5;
      color: var(--color-neutral-lightest);
    }
  `,
  cancelButton: (theme) => css`
    ${button(theme)};
    background: var(--color-neutral-lightest);
    color: #006dd5;
    border: 1px solid #dadde2;
  `,
  tryAgainButton: (theme) => css`
    ${button(theme)};
    background: var(--color-neutral-lightest);
    color: #c4382f;
    border: 1px solid #c4382f;
  `,
  spinner: css`
    width: 24px;
    height: 24px;
    flex: 0 0 auto;

    > img {
      width: 24px;
      height: 24px;
    }
    margin-right: 8px;
  `,
  error: css`
    color: #c4382f;
  `,
};
