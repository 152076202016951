import { css } from "@emotion/react";
import prop from "ramda/src/prop";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { A11yAnnouncement, Pagination } from "../../../components";
import { actions, selectors } from "../../../store";
import {
  List,
  Cards,
  NoResults,
  ResultsLoading,
  Loading,
  Header,
  SideFilters,
} from "./components";

const Views = {
  list: List,
  card: Cards,
};

const styles = {
  main: css`
    padding: 16px;
    display: grid;
    grid-template-areas: "results-header" "results";
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    grid-auto-rows: 0;
  `,
  header: css`
    grid-area: results-header;
  `,
  results: css`
    grid-area: results;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
    overflow-x: scroll;
  `,
};

export const SearchResultsView = (props) => {
  const dispatch = useDispatch();
  const numRecords = props.view.noResults.numRecords || 0;
  const showTour = useSelector(selectors.search.showResultsTour);

  const disableTour = () => {
    dispatch(actions.user.setTourViewed({ tourViewed: "searchResults" }));
  };

  useEffect(() => {
    if (showTour === false) disableTour();
  }, [showTour]);

  if (props.view.noResults.invalidDepartment) {
    return (
      <NoResults
        {...props.view.noResults}
        errors="An invalid department code was specified in the url."
      />
    );
  }

  if (props.view.loading.isLoading) {
    const { view, filters } = props;

    const loadingProps = {
      loadingText: view.loading.loadingText,
      name: view.loading.department.name,
      departmentCode: filters.query.department,
    };

    return <ResultsLoading {...loadingProps} />;
  }

  if (numRecords === 0) {
    return <NoResults {...props.view.noResults} />;
  }

  const ResultComponent = prop(props.view.searchResults.type, Views);

  const exportResultsLoading = props.export.inProgress && (
    <Loading key="loading" searchText="Exporting results to PDF" />
  );

  return (
    <div css={styles.main}>
      <Helmet>
        <title>Search Results</title>
      </Helmet>
      <A11yAnnouncement>Navigated to search results page</A11yAnnouncement>
      <Header css={styles.header} {...props.header} />
      <div css={styles.results}>
        <SideFilters {...props.filters} />
        <div
          className="search-results__results-wrap"
          data-tourid="searchResults"
        >
          <ResultComponent {...props.view.searchResults} />
          {!props.common.isLoading && (
            <div className="search-results__pagination">
              <Pagination {...props.pagination} />
            </div>
          )}
        </div>
      </div>
      {exportResultsLoading}
    </div>
  );
};

SearchResultsView.displayName = "SearchResultsView";

export default SearchResultsView;
