// feat: usePaymentMethods

import and from "ramda/src/and";
import not from "ramda/src/not";
import or from "ramda/src/or";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../../../components";
import { actions, selectors } from "../../../../../../store";
import { Connections } from "../../../../../../units/account-settings.payment.connections";
import { ConfigurePaymentMethods } from "../../../../../../units/configure-payment-methods";
import { isPresent } from "../../../../../../utils";
import { SettingsPane } from "../../settings-pane";
import ExistingCard from "./ExistingCard";
import PaymentForm from "./PaymentForm";
import { styles } from "./styles";

export const OldPayment = () => {
  const dispatch = useDispatch();
  const [shouldUpdateCard, setShouldUpdateCard] = useState(false);

  const { configuration, user } = selectors;
  const useNCourt = useSelector(configuration.isNCourtEnabled);
  const isLoading = useSelector(user.getLoading);
  const cardError = useSelector(user.getCardError);
  const cardInfo = useSelector(user.getCardInfo);
  const useSavedCard = useSelector(user.getUseSavedCard);
  const userHasCard = isPresent(cardInfo);
  const showSavedCard = and(userHasCard, useSavedCard);
  const loading = useSelector(user.getLoading);

  useEffect(() => {
    if (useNCourt) {
      return;
    }

    fetchUserCards();

    return () => {
      clearErrors();
      setUseSaveCard(true);
    };
  }, [useNCourt]);

  useEffect(() => {
    if (isLoading === false) {
      setShouldUpdateCard(false);
    }
  }, [isLoading]);

  if (useNCourt) {
    return null;
  }

  const setUseSaveCard = (value) =>
    dispatch(actions.user.setUseSavedCard(value));
  const fetchUserCards = () => dispatch(actions.user.fetchUserCards());
  const clearErrors = () => dispatch(actions.user.clearError());
  const onUserInput = () => void setShouldUpdateCard(true);

  const cardAreaProps = {
    cardError,
    cardInfo,
    showSavedCard,
    disabled: isLoading,
    offerToRemember: false,
    onUserInput,
  };

  const saveNewCard = or(not(showSavedCard), shouldUpdateCard);
  const paymentFormProps = {
    cardAreaProps,
    isLoading,
    saveNewCard,
    showSavedCard,
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SettingsPane title="Payment Info">
      <h3 css={styles.header}>Credit or Debit Card</h3>
      {saveNewCard ? (
        <PaymentForm {...paymentFormProps} />
      ) : (
        <ExistingCard
          cardInfo={cardInfo}
          setShouldUpdateCard={setShouldUpdateCard}
        />
      )}
      <Connections />
    </SettingsPane>
  );
};

export const NewPayment = () => {
  return (
    <SettingsPane title="Payment Info">
      <ConfigurePaymentMethods />
    </SettingsPane>
  );
};

export const Payment = () => {
  const { configuration } = selectors;
  const usePaymentMethods = useSelector(configuration.usePaymentMethods);
  const useNCourt = useSelector(configuration.isNCourtEnabled);

  if (useNCourt || !usePaymentMethods) return <OldPayment />;

  return <NewPayment />;
};

export default Payment;
