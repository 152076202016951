import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasLoaded } from "../../../../../../constants/status";
import { actions, selectors } from "../../../../../../store";
import ConfirmActionModal from "../../../../../../units/account-settings.common/ConfirmActionModal";
import { useSubscriptions } from "../use-subscriptions";

const RemoveCardModal = ({ onClose, cardId }) => {
  const dispatch = useDispatch();
  const [hasSubscription, setHasSubscription] = useState(false);
  const { subscriptions, status } = useSubscriptions();
  const { success, loading, error } = useSelector(selectors.user.getRemoveCard);

  const removeSavedCard = () =>
    dispatch(actions.user.removeSavedCard({ cardId }));

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);

  useEffect(() => {
    if (hasLoaded(status) && subscriptions.length) {
      setHasSubscription(true);
    }
  }, [subscriptions, status]);

  const subscriptionCancellationWarning = hasSubscription
    ? "When you remove this payment card, your subscription will be cancelled."
    : "";

  const confirmationProps = {
    title: "Remove Card",
    loading,
    error,
    content: `Are you sure you want to remove your payment card information? ${subscriptionCancellationWarning}`,
    cancel: { onClick: onClose, text: "Don't Remove" },
    action: { onClick: removeSavedCard, text: "Remove Card" },
  };

  return <ConfirmActionModal {...confirmationProps} />;
};

export default RemoveCardModal;
