import map from "ramda/src/map";
import { ENTITY_TYPES } from "../../constants";

export const checkoutTypes = {
  export: "export",
  document: "express",
  cart: "cart",
};

export const prepareCheckout = ({
  checkoutType,
  expressNotInitiated,
  cartNotInitiated,
  cartId,
  props,
}) => {
  if (checkoutType === checkoutTypes.cart && cartNotInitiated) {
    return [true, { cartId }];
  }

  const items = prepareItemsForCheckout(props);
  if (checkoutType === checkoutTypes.document && expressNotInitiated) {
    return [true, { items }];
  }

  if (checkoutType === checkoutTypes.export && expressNotInitiated) {
    return [true, { items }];
  }

  return [false, {}];
};

export const prepareItemsForCheckout = ({
  common,
  form,
  info,
  pageSelection,
}) => {
  const { items, department, entityType } = common;

  const pageCount =
    entityType === "export"
      ? info.totalPagesSelected
      : pageSelection.totalPages;

  const mapItems = {
    [ENTITY_TYPES.DOCUMENT]: mapItemsForExpressCheckout,
    [ENTITY_TYPES.EXPORT]: mapItemsForExport,
  }[entityType];

  return mapItems({
    items,
    department,
    form,
    pageCount,
  });
};

export const mapItemsForExport = ({ items, department }) =>
  map(
    (item) => ({
      id: item.id,
      type: "indexpage",
      selectedPages: "all",
      filePath: item.filePath,
      totalPages: item.pages,
      department,
    }),
    items
  );

export const mapItemsForExpressCheckout = ({
  items,
  department,
  form,
  pageCount,
}) =>
  map((item) => {
    const mapped = {
      department,
      type: "docimage",
      selectedPages: form.selectedPages,
      id: item.imageId,
      docId: item.docId,
      deliveryMethod: "download",
      docType: item.docTypeCode,
      instrumentNumber: item.instrumentNumber,
      totalPages: items.length === 1 ? pageCount : item.pageCount,
      includeAttachments: form.includeAttachments,
      isCertifiedCopy: form.certifiedCopy,
    };

    return mapped;
  }, items);
