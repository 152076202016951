import styled from "@emotion/styled";

export const Container = styled.div`
  align-items: center;
  display: flex;

  & > * {
    margin-left: 0.6rem;
  }

  &:first-child {
    margin-left: 0;
    margin-right: 8px;
  }
`;
