import { useSelector } from "react-redux";
import FraudAlert from "../../components/FraudAlert";
import ModalShell from "../../components/ModalShell";
import { hasLoaded } from "../../constants";
import { selectors } from "../../store";
import { isAbsent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";

export default (modal) => {
  const document = useDocument();

  const isFraudAlertEnabled = useSelector(
    selectors.configuration.isFraudAlertEnabled
  );

  const configureAlert = (e) => {
    e.preventDefault();
    if (isFraudAlertEnabled === false) return null;

    return modal.open(() => (
      <ModalShell close={modal.close}>
        <FraudAlert cancel={modal.close} />
      </ModalShell>
    ));
  };

  return {
    configureAlert,
    isVisible: isFraudAlertEnabled,
    disabled: !hasLoaded(document?.status) || isAbsent(document),
  };
};
