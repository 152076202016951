import { css } from "@emotion/react";
import { RadioSet } from "../../components/Fields";
import { isNotNil } from "../../utils";
import { Title } from "./_choose-plan.pick-type.title";
import { TitleWrapper } from "./_choose-plan.pick-type.title.wrapper";
import { LearnMore } from "./choose-plan.learn-more";
import OrganizationName from "./choose-plan.organization-name-input";

const radioStyle = css`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;

  .form-field-radio__wrapper:not(:first-of-type) {
    margin-left: 10px;
  }
`;

export const PickType = (props) => {
  const {
    pick,
    selected,
    organizationName,
    onOrgUpdate,
    showOrgNameError,
    helpUrl,
  } = props;

  const inputProps = {
    onChange: (e) => pick(e.target.value),
    value: selected,
  };

  const radioOptions = [
    {
      id: "individual",
      value: "individual",
      label: "Individual Plans",
    },
    {
      id: "multiuser",
      value: "multiUser",
      label: "Multi-User Plans",
    },
  ];

  return (
    <section>
      <TitleWrapper>
        <Title>Select Subscription Type</Title>
        {isNotNil(helpUrl) && <LearnMore url={helpUrl} />}
      </TitleWrapper>
      <RadioSet
        inputProps={inputProps}
        radioOptions={radioOptions}
        css={radioStyle}
      />
      <OrganizationName
        selected={selected}
        name={organizationName}
        onChange={onOrgUpdate}
        showError={showOrgNameError}
      />
    </section>
  );
};
