import { useAPI } from "../../hooks/use-api";

export const useMembers = () =>
  useAPI({
    send: "fetch-subscription-members",
    initialPayload: {},
    listenFor: {
      success: "subscription-members",
      failure: "error-fetching-subscription-members",
    },
  });
