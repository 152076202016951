import mitt from "mitt";
import is from "ramda/src/is";
import omit from "ramda/src/omit";
import uuidv4 from "uuid/v4";
import intercept from "./bunni/intercept";
import Sockette from "./sockette";
import { keyMirror } from "./utils/key-mirror";

const State = keyMirror("Connecting", "Connected", "Closed");
const Event = keyMirror("StateChange", "MsgFromAPI", "MsgToAPI", "Ping");
const BUFFER_MAX_SIZE = 20;
const PING_INTERVAL = 30e3;
const RECONNECTION_TIMEOUT = 3e3;
const RECONNECTION_MAX_ATTEMPTS = 50;
const isFn = is(Function);

const addToBuffer = (buffer, msg) => {
  if (buffer.length >= BUFFER_MAX_SIZE) buffer.shift();
  buffer.push(msg);
};

const mkSocket = ({ url, ctor, forceReconnect = false }) => {
  const state = { state: State.Connecting };
  const buffer = [];
  const events = mitt();

  globalThis.buffer = buffer;

  let pingInterval;

  const setupPing = () =>
    setInterval(() => {
      if (state.state === State.Connected) events.emit(Event.Ping);
    }, PING_INTERVAL);

  const resetPing = () => {
    clearInterval(pingInterval);
    pingInterval = setupPing();
  };

  const notifyStateChangedTo = (newState) => () => {
    events.emit(Event.StateChange, newState);
    state.state = newState;

    if (newState === State.Connected) {
      resetPing();

      while (buffer.length > 0) {
        events.emit(Event.MsgToAPI, buffer.shift());
      }
    } else {
      clearInterval(pingInterval);
    }
  };

  const notifyMsgReceived = () => (event) => {
    events.emit(Event.MsgFromAPI, JSON.parse(event.data));
    resetPing();
  };

  const socket = new Sockette(url, {
    ctor,
    forceReconnect,
    timeout: RECONNECTION_TIMEOUT,
    maxAttempts: RECONNECTION_MAX_ATTEMPTS,
    onopen: notifyStateChangedTo(State.Connected),
    onmessage: notifyMsgReceived(),
    onreconnect: notifyStateChangedTo(State.Connecting),
    onclose: notifyStateChangedTo(State.Closed),
    onerror: (event) => {
      console.error("unhandled ws error event", event);
    },
  });

  events.on(Event.MsgToAPI, (msg) => {
    if (state.state === State.Connected) {
      socket.json(msg);
    } else {
      addToBuffer(buffer, msg);
    }
    resetPing();
  });

  events.on(Event.Ping, () => {
    events.emit(Event.MsgToAPI, {
      type: "PING",
      correlationId: uuidv4(),
      authToken: globalThis.__ort,
      sync: true,
    });
  });

  const free = () => {
    clearInterval(pingInterval);
    socket.close();
    events.all.clear();
  };

  const onStateChange = (targetState) => (cb) => {
    events.on(Event.StateChange, (newState) => {
      if (isFn(cb) && newState === State[targetState]) cb();
    });
  };

  const onAPIMsg = (cb) => {
    events.on(Event.MsgFromAPI, (msg) => {
      if (isFn(cb)) cb(msg);
    });
  };

  const send = (msg) => {
    const payload = intercept({
      ...omit(["cid"], msg),
      correlationId: msg.cid || uuidv4(),
      sync: true,
    });

    events.emit(Event.MsgToAPI, payload);
  };

  const off = events.off.bind(events);

  return {
    send,
    free,
    onAPIMsg,
    off,
    onConnected: onStateChange(State.Connected),
    onConnecting: onStateChange(State.Connecting),
    onClosed: onStateChange(State.Closed),
  };
};

export default mkSocket;
