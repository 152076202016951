import { types as syncTypes } from "@kofile/ko-search-action-types";
import __ from "ramda/src/__";
import dissocPath from "ramda/src/dissocPath";
import lensPath from "ramda/src/lensPath";
import merge from "ramda/src/merge";
import mergeDeepRight from "ramda/src/mergeDeepRight";
import omit from "ramda/src/omit";
import over from "ramda/src/over";
import pathOr from "ramda/src/pathOr";
import set from "ramda/src/set";
import view from "ramda/src/view";
import {
  documents as initialState,
  defaultWorkspaceState,
} from "../initialState";
import types from "../types/documents";
import workspaceTypes from "../types/workspaces";
import { handleActions } from "./utils";

const makeWorkspaceLens = (id) => lensPath(["workspaces", id]);

export const makeLenses = (workspaceID) => ({
  workspace: Object.assign(makeWorkspaceLens(workspaceID)),
});

export const newTypes = { clearQuotes: "clear-quotes" };

export default handleActions(initialState, {
  [syncTypes.FETCH_DOCUMENTS_4]: (state, { payload: { workspaceID } }) => {
    const lenses = makeLenses(workspaceID);
    const oldWorkspaceState = view(lenses.workspace, state);
    const newWorkspaceValue = {
      ...oldWorkspaceState,
      isLoading: true,
      hasSearched: true,
    };

    return set(lenses.workspace, newWorkspaceValue, state);
  },

  [syncTypes.FETCH_DOCUMENTS_FULFILLED_6]: (state, { payload }) => {
    const { workspaceID, data, meta } = payload;

    const updatedValues = {
      data,
      meta,
      errors: "",
      isLoading: false,
      hasFetched: true,
    };

    const lenses = makeLenses(workspaceID);

    return over(lenses.workspace, mergeDeepRight(__, updatedValues), state);
  },

  [syncTypes.FETCH_DOCUMENTS_REJECTED_1]: (state, { payload }) => {
    const { workspaceID, errors } = payload;
    const updatedValues = { data: [], meta: {}, errors, isLoading: false };
    const lenses = makeLenses(workspaceID);

    return over(lenses.workspace, merge(__, updatedValues), state);
  },

  [syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_0]: (state, { payload }) => {
    const { quoteId } = payload;

    return {
      ...state,
      isFetchingQuote: true,
      priceQuotes: {
        ...state.priceQuotes,
        [quoteId]: {},
      },
    };
  },

  [syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_FULFILLED_0]: (state, { payload }) => {
    return {
      ...state,
      isFetchingQuote: false,
      mostRecentQuoteId: payload.quoteId,
      priceQuotes: {
        ...state.priceQuotes,
        [payload.quoteId]: payload,
      },
    };
  },

  [syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_REJECTED_0]: (state, { payload }) => {
    const { quoteId, reason } = payload;
    const oldQuote = pathOr({}, ["priceQuotes", quoteId], state);

    return {
      ...state,
      priceQuotes: {
        ...state.priceQuotes,
        [quoteId]: {
          ...oldQuote,
          error: reason,
        },
      },
    };
  },

  [syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_CANCELLED_0]: (
    state,
    { payload }
  ) => ({
    ...state,
    priceQuotes: omit(payload.quoteId, state.priceQuotes),
  }),

  [types.REMOVE_FETCHED_DOCUMENTS]: (state, { payload }) => {
    const { workspaceID } = payload;
    const lenses = makeLenses(workspaceID);

    return over(lenses.workspace, merge(__, defaultWorkspaceState), state);
  },

  [workspaceTypes.ADD]: (state, { payload }) => {
    const { type, id } = payload;
    const lenses = makeLenses(id);

    if (type === "search" || type === "results") {
      return set(lenses.workspace, defaultWorkspaceState, state);
    }

    return state;
  },

  [workspaceTypes.REMOVE]: (state, { payload: workspaceID }) =>
    dissocPath(["workspaces", workspaceID], state),

  [types.UPDATE_LEGAL_DISPLAY]: (state, { payload }) => {
    const lenses = makeLenses(payload.workspaceID);

    const updateDisplayOption = set(
      lensPath(["cards", "legalDisplayOption"]),
      payload.displayOption
    );

    return over(lenses.workspace, updateDisplayOption, state);
  },

  [newTypes.clearQuotes]: (state) => ({ ...state, priceQuotes: {} }),
});
