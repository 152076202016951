import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { selectors } from "../../../../../store";
import { formatSearchText } from "../../../../../utils";

const Bullet = () => <span className="no-search-results__bullet">•</span>;

const Errors = ({ errors }) => (
  <span>
    <h5 className="no-search-results__body-title">
      Error While Running Search:
    </h5>
    <div className="no-search-results__errors">{errors}</div>
  </span>
);

const Suggestions = () => (
  <span>
    <h5 className="no-search-results__body-title">Suggestions:</h5>
    <ul>
      <li>
        <Bullet />
        Make sure all names are spelled correctly
      </li>
      <li>
        <Bullet />
        Try different name variations
      </li>
      <li>
        <Bullet />
        Try broadening your search
      </li>
      <li>
        <Bullet />
        Try fewer keywords
      </li>
      <li>
        <Bullet />
        See our friendly
        <a
          className="no-search-results__help-link"
          href="https://kofilehelp.zendesk.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Help Guide
        </a>
      </li>
    </ul>
  </span>
);

const NoResults = (props) => {
  const { query, partyFields, history, errors, workspaceID } = props;
  const { searchType = "quickSearch" } = query;
  const searchPath = {
    quickSearch: "/",
    advancedSearch: "/search/advanced",
  }[searchType];

  const backToSearch = () =>
    history.push({
      state: { workspaceID },
      pathname: searchPath,
    });

  return (
    <div className="no-search-results__wrapper">
      <header className="no-search-results__header">
        <h3 className="no-search-results__header-title"> No Results Found </h3>
        <p className="no-search-results__header-search-text">
          Your search for
          <span className="no-search-results__search-text">
            {` "${formatSearchText(query, partyFields)}" `}
          </span>
          returned no results.
        </p>
      </header>
      <div className="no-search-results__body">
        {errors ? <Errors errors={errors} /> : <Suggestions />}
        <button
          className="no-search-results__edit-search-button"
          onClick={backToSearch}
        >
          Edit Search Criteria
        </button>
      </div>
    </div>
  );
};

NoResults.displayName = "NoResults";
NoResults.propTypes = {
  partyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.object,
};

const mapStateToProps = (state) => ({
  workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
});

export default compose(withRouter, connect(mapStateToProps))(NoResults);
