export default {
  byName: {
    title: "Create a Custom Keyword Alert",
    blurb:
      "You can set up a property alert by entering a name, multiple names, or other keyword(s).",
    buttonUrl: "/property-alert/configure",
    buttonText: "Enter a Keyword",
  },

  byDoc: {
    title: "Create an Alert from a Document",
    blurb:
      "Once you’ve found the right document, select Property Alert and select your notification criteria.",
    buttonUrl: "/",
    buttonText: "Find a Document",
  },
};
