import { useAPI } from "../../hooks/use-api";

export const useRemoveMember = () =>
  useAPI({
    send: "remove-member-from-subscription",
    listenFor: {
      success: "member-removed-from-subscription",
      failure: "error-removing-member-from-subscription",
    },
  });
