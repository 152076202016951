import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const style = css`
  height: 120px;
  width: 120px;
`;

export const Icon = () => {
  const icon = useSelector(selectors.assets.getImg("404_icon.svg"));

  return <img alt="Sad face" css={style} src={icon} />;
};
