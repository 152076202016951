import { parse } from "query-string";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import concat from "ramda/src/concat";
import defaultTo from "ramda/src/defaultTo";
import equals from "ramda/src/equals";
import filter from "ramda/src/filter";
import find from "ramda/src/find";
import findIndex from "ramda/src/findIndex";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import merge from "ramda/src/merge";
import objOf from "ramda/src/objOf";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import reduce from "ramda/src/reduce";
import toLower from "ramda/src/toLower";
import update from "ramda/src/update";
import { castArray, formatDateOnly } from "../../../../../utils";

/**
 * Modifies the parties array into usuable values
 * @param {Array<Object>} parties - The parties to collapse
 * @param {Object} highlights - An object of highlight values
 * @return {Array<Object>} - The parties value to use
 */
export const collapseParties = (parties, highlights) =>
  reduce(
    (acc, val) => {
      const { type = "", name = "" } = val;

      const unmodified = propOr(
        name.split("  "),
        type.toLowerCase(),
        highlights
      );

      const partyIndex = findIndex(
        compose(equals(type.toLowerCase()), toLower(), prop("type")),
        acc
      );

      const names = filter(complement(isEmpty), unmodified);

      if (partyIndex > -1) {
        const currentValue = acc[partyIndex];

        const newValue = merge(
          currentValue,
          objOf("name", concat(castArray(currentValue.name), names))
        );

        return update(partyIndex, newValue, acc);
      }

      const updatedValue = merge(val, objOf("name", names));

      return concat(acc, castArray(updatedValue));
    },
    [],
    parties
  );

/**
 * Gets the title of the party group, given sortBy
 *
 * @param {Array<Object>} parties - The parties to look through
 * @param {string} sortBy - The current Search sortBy
 * @return {string} - The string to use as the party title
 */
export const getPartyTitle = (parties = [], sortBy = "") => {
  const defaultTitle = {
    name: ["N/A"],
  };

  const firstDirect = compose(
    head,
    prop("name"),
    defaultTo(defaultTitle),
    find(
      compose(equals(sortBy.toLowerCase()), toLower(), prop("type"))
    )
  );

  return firstDirect(parties);
};

/**
 * Given a document, location, and parties, returns the title to use
 *
 * @param {Object} args - { doc: Object, parties: [Object], location: Object }
 * @return {string} - The title of the card
 */
export const getCardTitle = ({ doc, parties = [], location }) => {
  const dateFields = ["recordedDate", "instrumentDate"];
  const parsed = parse(location.search);
  const { sortBy = "" } = parsed;
  const knownValues = [
    "docType",
    "recordedDate",
    "instrumentDate",
    "documentNumber",
  ];

  const knownKey = knownValues.find(
    (s) => s.toLowerCase() === sortBy.toLowerCase()
  );

  if (knownKey) {
    const value = doc[knownKey];

    return value && dateFields.includes(knownKey)
      ? formatDateOnly(value)
      : value;
  }

  if (!sortBy) {
    return "";
  }

  return getPartyTitle(parties, sortBy);
};

/**
 * Returns a cleaned value for the Legal Description
 *
 * @param {Array<string>} legalDescription - Legal description from API
 * @return {Array<string>} - The legal description object for Card consumption
 */
export const getLegalDescription = (legalDescription) =>
  !isEmpty(legalDescription) ? legalDescription : ["N/A"];

/**
 * Our incoming document values to clean
 *
 * @typedef {Object} DocData
 * @property {Object} doc - The document
 * @property {Object} highlights - The highlights of the document
 * @property {Array} parties - The cleaned parties of the document
 * @property {Object} location - The location of the app
 */

/**
 * Cleans data for ResultCard consumption
 * @param {DocData} args - The incoming data to clean
 * @return {Object} - A cleaned document
 */
export const getCleanedDoc = ({ doc, highlights, parties, location }) =>
  merge(
    mapObjIndexed((value, key) => propOr(value, key, highlights), doc),
    {
      title: getCardTitle({ doc, parties, location }),
      legalDescription: getLegalDescription(doc.legalDescription),
    }
  );
