import styled from "@emotion/styled";

export const Wrapper = styled.p`
  white-space: nowrap;
  color: rgba(4, 29, 68, 0.7);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  text-overflow: ellipsis;
  width: 100%;
`;
