// feat: usePaymentMethods
//   TODO move this to API

import ifElse from "ramda/src/ifElse";
import omit from "ramda/src/omit";

/**
 * The response from Stripe.js createToken
 *
 * @typedef {Object} TokenResponse
 * @property {Object?} error - Any errors from Stripe
 * @property {string?} token - The token to use
 */

/**
 * The props injected by Component using checkout
 *
 * @typedef {Object} StripeProps
 * @property {[Object]} docs - The docs to purchase
 * @property {number} price - The price, in cents, of the checkout
 * @property {string} token - The token to use for stripe
 * @property {[string=card]} type - The type of token we are sending
 */

/**
 * Calls createToken and passes in type
 *
 * @param {Object} opts - { stripe, type }
 * @return {Promise} - The result of calling opts.stripe.createToken({ type })
 */
export const createToken = ({ stripe, type = "card" }) =>
  stripe.createToken({ type });

/**
 * Checks if a createToken response is an error or not
 *
 * @param {TokenResponse} response - The response from createToken
 * @return {*} - The value at `error`
 */
export const isError = ({ error }) => error;

/**
 * Checks the TokenResponse for errors and extracts the data
 *
 * @param {TokenResponse} response - The resposne from createToken
 * @return {Promise} - <Response without error key | Rejection with error>
 */
export const checkToken = ifElse(
  isError,
  ({ error }) => Promise.reject(error),
  (response) => Promise.resolve(omit(["error"], response))
);

/**
 * HoF that wraps a token response with the needed props
 *
 * @param {StripeProps} props - The Stripe props needed for checkout
 * @return {Function} - ({ token }) -> Object
 */
export const getResponse =
  ({ docs: items, price, type = "card" }) =>
  ({ token }) => ({
    items,
    price,
    token,
    type,
  });
