import defaultTo from "ramda/src/defaultTo";
import isNil from "ramda/src/isNil";
import path from "ramda/src/path";
import rprop from "ramda/src/prop";
import { isAbsent, isPresent } from "../utils";
import { concatDateAndTime } from "../utils/dates";
import { createOptionValue } from "../values";
import { formatCollection } from "./format-collection";
import { formatDocumentDates } from "./format-document-dates";

export function extractDataPathFromKey(key) {
  if (key === "parcel") return ["propAddress"];
  if (key.includes(".")) return key.split(".");

  return [key];
}

const determineMeta = (documentData, value, key) => {
  const pagination = rprop(`${key}Pagination`, documentData);

  if (isPresent(pagination)) {
    return { ...pagination.meta };
  }

  const total = rprop(`${key}Total`, documentData);
  const fallback = Array.isArray(value) ? value.length : 0;
  return { total: isPresent(total) ? total : fallback };
};

export function documentPropGetter(documentData) {
  return (prop) => {
    const { key } = prop;
    const dataPath = prop.dataPath || extractDataPathFromKey(key);

    let label = prop.label;
    let value = path(dataPath, documentData);
    let meta = {};

    switch (key) {
      case "bookVolumePage":
        value = formatCollection([
          documentData.book,
          documentData.volume,
          documentData.page,
        ]);
        label = label || "Book/Volume/Page";
        break;
      case "lotBlock":
        // NOTE lotBlock always pulls from legals, which is an array
        // WARNING hard-code!
        if (isNil(value)) {
          value = "";
        } else {
          value = value.map((legal) =>
            formatCollection([
              legal.lot,
              legal.block,
              legal.block2,
              legal.block3,
            ])
          );
        }
        break;
      case "recordedDate": {
        value = concatDateAndTime("recordedDate", "recordedTime", documentData);
        break;
      }
      case "receivedDate": {
        value = concatDateAndTime("receivedDate", "receivedTime", documentData);
        break;
      }
      case "instrumentDate":
        value = formatDocumentDates(value);
        break;
      case "parcel":
        value = isNil(value) ? [] : value.map(rprop("parcel"));
        meta = determineMeta(documentData, value, "propAddress");
        meta.fetchKey = "propAddress";
        break;
      case "legalDescriptions":
        value = path(dataPath, documentData);
        if (dataPath.at(-1) === "legals") {
          meta = determineMeta(documentData, value, "legals");
          meta.fetchKey = "legals";
        }
        break;
      default:
        value = defaultTo([])(value);
    }

    if (isAbsent(meta)) {
      meta = determineMeta(documentData, value, key);
    }
    if (isAbsent(value)) {
      value = "";
    }

    return createOptionValue({ key, label, value, meta });
  };
}
