import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { hasError, hasLoaded, isLoading } from "../../constants";
import { useNotifications } from "../../hooks/use-notifications";
import { Input } from "../common/input";
import { AddButton } from "./_member-list.add.button";
import { AddMemberContainer } from "./_member-list.add.container";
import { MaxMembers } from "./_member-list.add.max-members";
import { useAddMember } from "./use-add-member";

const style = css`
  grid-area: input;
`;

export const AddMember = (props) => {
  const { maxMembersReached, maxMembers, refetchMemberList, subscriptionId } =
    props;
  const { initiate, status, error } = useAddMember();
  const pushNotification = useNotifications();
  const [email, setEmail] = useState("");
  const [localError, setLocalError] = useState("");
  const isDisabled = isLoading(status) || maxMembersReached;
  const label = "Invite a new user to your organization:";
  const hasLocalError = localError.length > 0;

  const onChange = (e) => {
    if (localError.length) {
      setLocalError("");
    }
    setEmail(e.target.value);
  };

  const add = () => {
    if (email.length > 5) {
      initiate({ email, subscriptionId });
    } else {
      setLocalError("Must enter a valid email.");
    }
  };

  useEffect(() => {
    if (hasLoaded(status)) {
      pushNotification({
        message: `${email} has been added to your subscription.`,
      });
      setEmail("");
      setLocalError("");
      refetchMemberList();
    }
  }, [status]);

  useEffect(() => {
    if (hasError(status)) {
      setLocalError(error.reason.message);
    }
  }, [status, error]);

  const maxUserCopy = maxMembersReached
    ? "Max user limit reached"
    : `${maxMembers} users maximum allowed`;

  return (
    <AddMemberContainer>
      <Input
        css={style}
        type="text"
        name="email"
        value={email}
        onChange={onChange}
        label={label}
        disabled={isDisabled}
        placeholder="Email address"
        error={localError}
      />
      <AddButton hasError={hasLocalError} onClick={add} disabled={isDisabled}>
        {isLoading(status) ? "Loading..." : "Add User"}
      </AddButton>
      <MaxMembers hasError={hasLocalError} atMax={maxMembersReached}>
        {maxUserCopy}
      </MaxMembers>
    </AddMemberContainer>
  );
};

export default AddMember;
