import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import { GroupWrap } from "./group-wrap";
import { Table } from "./legals.table";

export const Legals = (props) => {
  const { data = [] } = props;
  const legalsConfig = useSelector(selectors.configuration.getDocPreviewLegals);
  const { order = [], enabled = false, columnsByType = {} } = legalsConfig;

  if (order.length === 0 || !enabled) return null;

  const legalsInOrder = order.filter((orderData) =>
    data.some(({ legalType }) => legalType === orderData.key)
  );

  return legalsInOrder.map(({ key, label }, i) => {
    const columns = columnsByType[key];
    const legals = data.filter(({ legalType }) => legalType === key);
    const meta = { total: legals.length };
    const groupData = { value: legals, label, key, meta };

    return (
      <GroupWrap key={`legal-wrap${i}`} data={groupData} isSubGroup={true}>
        <Table data={legals} columns={columns} />
      </GroupWrap>
    );
  });
};

export default Legals;
