import { css } from "@emotion/react";
import { OrderReceipt } from "@kofile/react-order-receipt";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { keyMirror } from "../../utils/key-mirror";
import { Header } from "./_header";

const Kind = keyMirror("FullWidth", "Compressed");

const style = {
  wrap: ({ kind }) => css`
    background: #f5f6f8;
    border-radius: 4px;
    ${kind === Kind.Compressed && "margin: 0 auto;"}
    ${kind === Kind.Compressed && "padding: 30px;"}
    ${kind === Kind.Compressed && "width: 860px;"}
  `,
};

export const Receipt = ({ order, kind, fresh }) => {
  const { configuration } = selectors;
  const officials = useSelector(configuration.getSupplementaryOfficials);

  return (
    <div css={style.wrap({ kind: kind(Kind) })}>
      <Header fresh={fresh} />
      <OrderReceipt officials={officials} {...order} />
    </div>
  );
};

Receipt.defaultProps = {
  fresh: false,
  kind: prop("FullWidth"),
};
