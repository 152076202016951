import { css } from "@emotion/react";
import { formatDate } from "./format-date";

const style = css`
  color: #6a778d;
  font-size: 15px;
  grid-area: date;
`;

export const Date = ({ date, children }) => (
  <span css={style}>{children(formatDate(date))}</span>
);
