import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";

export const getCheckout = prop("checkout");
export const getOrder = compose(prop("order"), getCheckout);
export const getZipLink = compose(prop("path"), getOrder);
export const getItemsNumber = compose(prop("itemsNumber"), getOrder);
export const getError = compose(propOr("", "error"), getCheckout);
export const getInProgress = compose(prop("inProgress"), getCheckout);
export const getSuccess = compose(prop("success"), getCheckout);
export const isCheckoutFresh = compose(prop("isFresh"), getCheckout);
