import classnames from "classnames";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form } from "../../../../../../components";
import { actions, selectors } from "../../../../../../store";
import { notEmpty } from "../../../../../../utils";
import { SubmitButton } from "../../button.submit";
import { FormContainer } from "../../form.container";
import { SettingsPane } from "../../settings-pane";
import formConfig from "./formConfig";

export class Password extends Component {
  componentWillUnmount() {
    this.props.clearErrors();
  }

  render() {
    const {
      state,
      updateFormValue,
      loading,
      changePasswordError,
      updatePassword,
      email,
      clearErrors,
      updateFormError,
    } = this.props;

    const formActions = {
      onChange: updateFormValue,
    };

    const currentPassword = state.currentPassword.value;
    const newPassword = state.newPassword.value;
    const form = Form.buildForm(formConfig, state, formActions);
    const fieldsFilled = notEmpty(currentPassword) && notEmpty(newPassword);
    const disableButton = !fieldsFilled;
    const buttonText = loading ? "Loading..." : "Change Password";

    const errorClass = classnames("account-password__error", {
      "account-password__error--show": changePasswordError,
    });

    const handleSubmit = (event) => {
      const isValidForm = Form.checkIfFormValid(
        formConfig,
        state,
        updateFormError
      );

      if (isValidForm) {
        updatePassword({ email, oldPassword: currentPassword, newPassword });
      }
      event.preventDefault();

      clearErrors();
    };

    return (
      <SettingsPane title="Password">
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <div className="account-password__input-wrap">
              {form.currentPassword}
            </div>
            <div className="account-password__input-wrap">
              {form.newPassword}
            </div>
            <div className={errorClass}>{changePasswordError}</div>
            <SubmitButton disabled={disableButton}>{buttonText}</SubmitButton>
          </FormContainer>
        </form>
      </SettingsPane>
    );
  }
}

Password.propTypes = {
  state: PropTypes.shape({
    currentPassword: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
    newPassword: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  updateFormValue: PropTypes.func.isRequired,
};

Password.displayName = "Password";

export const mapStateToProps = (state) => ({
  loading: selectors.user.getLoading(state),
  changePasswordError: selectors.user.getChangePasswordError(state),
  email: selectors.user.getUserEmail(state),
});

export const mapDispatchToProps = (dispatch) => ({
  updatePassword: ({ email, oldPassword, newPassword }) =>
    dispatch(
      actions.user.changePassword({
        email,
        oldPassword,
        newPassword,
      })
    ),
  clearErrors: () => dispatch(actions.user.clearError()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  Form.withFormState(formConfig, "state")
)(Password);
