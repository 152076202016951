import addIndex from "ramda/src/addIndex";
import compose from "ramda/src/compose";
import defaultTo from "ramda/src/defaultTo";
import flip from "ramda/src/flip";
import has from "ramda/src/has";
import map from "ramda/src/map";
import path from "ramda/src/path";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";
import { formatDateOnly, concatDateAndTime } from "../../../../../../../utils";
import { ColumnWrapper } from "./_card-info.column";
import { Info } from "./_card-info.info";
import { Wrapper } from "./_card-info.wrapper";

const PossibleHit = ({ value }) => (
  <p dangerouslySetInnerHTML={{ __html: value }} />
);

const CardInfoColumn = ({ label, value }, i) => (
  <Info key={i}>
    <p>{label}:</p> <PossibleHit value={value} />
  </Info>
);

const CardColumn = (columns, i) => (
  <ColumnWrapper key={i}>
    {columns.map((values, j) => (
      <CardInfoColumn {...values} key={j} />
    ))}
  </ColumnWrapper>
);

const CardColumns = compose(addIndex(map)(CardColumn), prop("columns"));

export const CardInfo = ({ doc, isUncertified }) => {
  const department = useSelector(selectors.workspaces.getSelectedDepartment);
  const cardConfig = useSelector((state) =>
    selectors.configuration.getCardConfig(state, department)
  );
  const { body: cardBody = [] } = cardConfig;
  const cardLabels = createLabels(doc, isUncertified);

  const columns = cardBody.map(map(cardLabels));

  return (
    <Wrapper>
      <CardColumns columns={columns} />
    </Wrapper>
  );
};

const createLabels =
  (doc, isUncertified) =>
  ({ label, key, type, dataPath, ...rest }) => {
    const types = { date: formatDateOnly };
    const defaultValue = defaultTo("N/A", rest.default);
    const orDefault = propOr(defaultValue);
    const value = Array.isArray(key)
      ? key.map(flip(orDefault)(doc)).join(rest.separator)
      : orDefault(key, doc);

    if (key === "receivedDate") {
      return {
        label,
        value: concatDateAndTime("receivedDate", "receivedTime", doc),
      };
    }

    if (key === "recordedDate") {
      return {
        label,
        value: concatDateAndTime("recordedDate", "recordedTime", doc),
      };
    }

    if (key === "docStatus") {
      return { label, value: isUncertified ? "In Progress" : "Complete" };
    }

    if (dataPath) {
      return { label, value: path(dataPath, doc) };
    }

    if (type && has(type, types)) {
      if (value === "N/A") return { label, value: defaultValue };

      return { label, value: types[type](value) || defaultValue };
    }

    return { label, value };
  };
