import { css } from "@emotion/react";

export const resetEmailContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  & > div {
    background: #fef4c5;
    padding: 12px;
  }

  & > a {
    color: #006dd5;
    cursor: pointer;
  }
`;
