// polyfill older browsers for globalThis
(function () {
  if (typeof globalThis === "object") return;
  Object.prototype.__defineGetter__("__magic__", function () {
    return this;
  });
  // eslint-disable-next-line no-undef -- black magic trickery
  __magic__.globalThis = __magic__; // lolwat
  delete Object.prototype.__magic__;
})();
