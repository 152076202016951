import PropTypes from "prop-types";
import { parse, stringify } from "query-string";
import merge from "ramda/src/merge";
import { withRouter } from "react-router";
import { Dropdown } from "../";
import { SEARCH_RESULTS } from "../../../../../../../constants";

export const ResultCountSelector = ({ workspaceID, location, history }) => {
  const { limit = SEARCH_RESULTS.DEFAULT_LIMIT } = parse(location.search);

  const options = SEARCH_RESULTS.LIMIT_OPTIONS.map((val) => ({
    key: val,
    name: val,
    value: parseInt(val, 10),
    action: () => setLimit({ limit: val, history, location, workspaceID }),
  }));

  return (
    <Dropdown
      options={options}
      label="Results Per Page:"
      activeOption={limit}
    />
  );
};

ResultCountSelector.displayName = "ResultCountSelector";

ResultCountSelector.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  workspaceID: PropTypes.string.isRequired,
};

export default withRouter(ResultCountSelector);

// --------------------------------------------------------------------- private
function setLimit({ limit, history, location, workspaceID }) {
  const searchObj = parse(location.search);
  const newSearch = stringify(merge(searchObj, { limit }));

  history.push({
    pathname: location.pathname,
    search: newSearch,
    state: { workspaceID, refetch: true },
  });
}
