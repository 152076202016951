import curry from "ramda/src/curry";

const dispatchFormChangeForKey = curry((ID, props, stateKey, value) => {
  props.formDispatch({ type: ID, payload: { stateKey, value } });
});

const dispatchFormStateChange = curry((ID, props, payload) => {
  props.formDispatch({ type: ID, payload });
});

export const actions = {
  updateFormValue: dispatchFormChangeForKey("UPDATE_FORM_VALUE"),
  updateFormError: dispatchFormChangeForKey("UPDATE_FORM_ERROR"),
  reinitializeState: dispatchFormStateChange("REINITIALIZE_STATE"),
};

export default (state, { type, payload }) => {
  switch (type) {
    case "REINITIALIZE_STATE": {
      return payload;
    }
    case "UPDATE_FORM_VALUE": {
      const stateKey = payload.stateKey;

      return {
        ...state,
        [stateKey]: {
          value: payload.value,
          error: "",
        },
      };
    }
    case "UPDATE_FORM_ERROR": {
      const stateKey = payload.stateKey;

      return {
        ...state,
        [stateKey]: {
          value: state[stateKey].value,
          error: payload.value,
        },
      };
    }
    default: {
      return state;
    }
  }
};
