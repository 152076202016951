import { XmlEntities } from "html-entities";
import { Checkbox } from "../../components";
import { useSelectableCollection } from "../../hooks";
import { wrap } from "../../utils";
import { add } from "./add";
import { remove } from "./remove";

export const Section = ({ title, name, data, set }) => {
  const entities = new XmlEntities();
  const col = useSelectableCollection();

  const onSelect = (v) => (e) => {
    e.preventDefault();

    if (col.isSelected(v)) {
      set(remove(name)(v));
    } else {
      set(add(name)(v));
    }

    col.toggle(v)(e);
  };

  if (wrap(data).length === 0) return null;

  return (
    <section>
      <h2>{title}</h2>
      <ul>
        {data.map((datum, index) => {
          const decoded = entities.decode(datum);
          const uniqueId = `${name}-${index}-${datum[0]}`;

          return (
            <li key={uniqueId}>
              <Checkbox
                id={uniqueId}
                isChecked={col.isSelected(decoded)}
                onClick={onSelect(decoded)}
                label={decoded}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
};
