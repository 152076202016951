import styled from "@emotion/styled";

const SubTitle = styled.div`
  font-size: 1.4em;
  color: #041d4499;
  text-align: center;
  align-self: ${(props) => (props.center ? "center" : "flex-start")};
`;

export default SubTitle;
