import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router";
import { A11yAnnouncement, Link } from "../../../components";
import CompleteAlert from "./complete-alert";
import ConfigureFullScreen from "./configure";
import LandingPage from "./LandingPage";
import styles from "./styles";

export const FraudAlert = () => (
  <>
    <Helmet>
      <title>Property Alert</title>
    </Helmet>
    <A11yAnnouncement>{`Navigated to Property Alert page`}</A11yAnnouncement>
    <Link css={styles.returnLink} to="/" openNewWorkspace>
      ← Return To Public Search
    </Link>
    <Switch>
      <Route exact path="/property-alert" component={LandingPage} />
      <Route
        exact
        path="/property-alert/configure"
        component={ConfigureFullScreen}
      />
      <Route exact path="/property-alert/complete" component={CompleteAlert} />
    </Switch>
  </>
);

export default FraudAlert;
