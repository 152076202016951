import styled from "@emotion/styled";

export const StyledInput = styled.input`
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.8em;
  font-weight: lighter;
  height: 32px;
  padding: 0 0.5rem;
  width: 100%;
  margin-top: 5px;
  border: ${({ error }) => (error ? "2px solid #c4382f" : "1px solid #dadde2")};
`;
