import { types as syncTypes } from "@kofile/ko-search-action-types";
import { error as initialState } from "../initialState";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.API_ERROR_0]: apiError,
});

function apiError(state) {
  return {
    ...state,
    hasServiceError: true,
  };
}
