import classnames from "classnames";
import Downshift from "downshift";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import concat from "ramda/src/concat";
import contains from "ramda/src/contains";
import defaultTo from "ramda/src/defaultTo";
import has from "ramda/src/has";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import toLower from "ramda/src/toLower";
import trim from "ramda/src/trim";
import { Component } from "react";
import { connect } from "react-redux";
import { List } from "react-virtualized";
import { selectors } from "../../store";
import { containsAny, noop } from "../../utils";
import { Button, Item, TokenWrap } from "./components";

const getOptionValue = compose(defaultTo(""), prop("value"));
const getSelectedItems = propOr([], "selectedItems");
const prepareInputValue = compose(toLower, trim, defaultTo(""));
const matchesSearch =
  (searchTerm) =>
  (input = "") =>
    contains(searchTerm, input.toLowerCase());

class TokenizedNestedSelect extends Component {
  constructor(props) {
    super(props);

    const { options = [], selectedItems = [] } = this.props;

    const indices = options.reduce((acc, { items }, index) => {
      const itemValues = items.map(prop("value"));
      const isSelected = containsAny(itemValues, selectedItems);

      return isSelected ? concat(acc, [index]) : acc;
    }, []);

    this._options = [];
    this._isMounted = false;

    this.state = {
      openIndices: indices || [],
      selectedItems: this.props.selectedItems || [],
      optionsComputed: false,
    };

    this.onButtonClick = (index) => () =>
      this.inOpenIndices(index)
        ? this.removeOpenIndex(index)
        : this.addOpenIndex(index);

    this.getListRef = (ref) => {
      this.listRef = ref;
    };

    this.updateSelectedItems = (newItems, callback = noop) => {
      const { onChange = () => {} } = this.props;

      onChange(newItems.join(","));

      this.setState({ selectedItems: newItems }, () => callback());
    };

    this.addSelectedItem = (item, callback) => {
      const { selectedItems } = this.state;

      this.updateSelectedItems([...selectedItems, item], callback);
    };

    this.removeSelectedItem = (item, callback) => {
      const { selectedItems } = this.state;

      this.updateSelectedItems(
        selectedItems.filter((i) => i !== item),
        callback
      );
    };

    this.openAllDropdowns = (indexArray) =>
      this.setState({ openIndices: indexArray });

    this.handleChange = (selection = {}, { clearSelection }) => {
      if (isNil(selection)) {
        return null;
      }

      const { value: selectedItem } = selection;

      return contains(selectedItem, this.state.selectedItems)
        ? this.removeSelectedItem(selectedItem, clearSelection)
        : this.addSelectedItem(selectedItem, clearSelection);
    };

    this.isSelected = (value) => contains(value, this.state.selectedItems);

    this.addOpenIndex = (index) =>
      this.setState(
        {
          openIndices: [...this.state.openIndices, index],
        },
        () => this.listRef.recomputeRowHeights()
      );

    this.removeOpenIndex = (index) =>
      this.setState(
        {
          openIndices: this.state.openIndices.filter((i) => i !== index),
        },
        () => this.listRef.recomputeRowHeights()
      );

    this.inOpenIndices = (index) => contains(index, this.state.openIndices);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextSelectedItems = getSelectedItems(nextProps);
    const prevSelectedItems = getSelectedItems(prevState);
    const stateDiffers = nextSelectedItems !== prevSelectedItems;

    if (stateDiffers) {
      return { selectedItems: nextSelectedItems };
    }

    return null;
  }

  componentDidMount() {
    this._isMounted = true;

    setTimeout(() => {
      const { allDocTypes = [] } = this.props;

      const allDocTypeMap = allDocTypes.map((val) => ({
        label: val,
        hits: 0,
      }));

      const { docTypeMappings, docTypes = allDocTypeMap } = this.props;

      this._options = makeOptionsFromMappings(docTypes, docTypeMappings);

      if (this.props.rerenderOnCompute && this._isMounted) {
        this.setState({ optionsComputed: true });
      }
    }, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      id,
      keepOpen,
      width = 600,
      hidePills = false,
      className = "",
      labelLookupTable = {},
      disabled = false,
    } = this.props;

    const downShiftProps = {
      id,
      onChange: this.handleChange,
      itemToString: getOptionValue,
      onStateChange: this.handleStateChange,
    };

    return (
      <Downshift {...downShiftProps}>
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
        }) => {
          const matcher = matchesSearch(prepareInputValue(inputValue));

          const rows = this._options
            .map(({ name, items }, index) => {
              const reducedItems = items.reduce(
                (acc, { value, label, hits }) =>
                  matcher(label) ? concat(acc, [{ value, label, hits }]) : acc,
                []
              );

              const isSelected = this.inOpenIndices(index);

              const buttonValue = {
                isButton: true,
                label: name,
                index,
                isSelected,
              };

              if (isEmpty(reducedItems)) {
                return [];
              }

              return isSelected
                ? [buttonValue, ...reducedItems]
                : [buttonValue];
            })
            .reduce((f, l) => f.concat(l), []);

          const buttonIndices = rows.reduce(
            (acc, { isButton, index }) =>
              isButton ? concat([index], acc) : acc,
            []
          );

          const { selectedItems } = this.state;

          const rowRenderer = ({ key, index, style }) => {
            const {
              isButton,
              value,
              label,
              index: i,
              isSelected = false,
              hits = 0,
            } = rows[index];

            const ListElement = isButton ? Button : Item;
            const buttonClick = this.onButtonClick(i);
            const itemProps = getItemProps({
              item: { value, label },
              index,
            });

            return (
              <ListElement
                hits={hits}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                key={key}
                style={style}
                onClick={buttonClick}
                index={index}
                isOpen={isSelected}
                itemProps={itemProps}
                isSelected={this.isSelected(value)}
              >
                {label}
              </ListElement>
            );
          };

          const rowHeight = 30;
          const maxHeight = 400;

          const height = Math.min(maxHeight, rows.length * rowHeight);

          const listProps = {
            width,
            height,
            rowCount: rows.length,
            rowRenderer,
            rowHeight,
          };

          const tokenWrapProps = {
            labelLookupTable,
            hidePills,
            openMenu,
            getInputProps,
            selectedItems,
            removeLastItem: this.removeLastItem,
            removeItem: this.removeSelectedItem,
            inputValue,
            disabled,
            exIcon: null,
            openAllDropdowns: (e) => {
              if (e.target.value) {
                return this.openAllDropdowns(buttonIndices);
              }

              return this.setState({ openIndices: [] });
            },
          };

          const showOptions = keepOpen || isOpen;

          return (
            <div
              id={this.props.id}
              className={classnames("tokenized-nested-select", className)}
            >
              <TokenWrap {...tokenWrapProps} />
              {showOptions && (
                <div className="tokenized-nested-select__dropdown">
                  <List ref={this.getListRef} {...listProps} />
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

TokenizedNestedSelect.displayName = "TokenizedNestedSelect";

TokenizedNestedSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  id: PropTypes.string,
  keepOpen: PropTypes.bool,
  width: PropTypes.number,
  hidePills: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  labelLookupTable: PropTypes.object,
  department: PropTypes.string,
  disabled: PropTypes.bool,
  rerenderOnCompute: PropTypes.bool,
};

export const makeOptionsFromMappings = (
  docTypes = [],
  docTypeMappings = []
) => {
  const hitMap = {};
  const result = [];

  for (let i = 0; i < docTypes.length; i++) {
    const { label, hits } = docTypes[i];

    hitMap[label] = hits;
  }

  for (let i = 0; i < docTypeMappings.length; i++) {
    const items = [];
    const group = docTypeMappings[i];

    for (let ii = 0; ii < group.docType.length; ii++) {
      const { code, description } = group.docType[ii];
      const shouldAddToCollection = has(code, hitMap);

      if (shouldAddToCollection) {
        items.push({ label: description, value: code, hits: hitMap[code] });
      }
    }

    items.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;

      return 0;
    });

    if (items.length) {
      result.push({ name: group.description, items });
    }
  }

  result.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    return 0;
  });

  return result;
};

export const mapStateToProps = (state, { department = "" }) => ({
  docTypeMappings: selectors.configuration.getCurrentDeptDocTypeMappings(
    state,
    department
  ),
  allDocTypes: selectors.configuration.getAllDocTypesForDept(state, department),
  labelLookupTable: selectors.configuration.getDocTypeLookupTable(state),
});

export default connect(mapStateToProps)(TokenizedNestedSelect);
