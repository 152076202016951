import { Helmet } from "react-helmet";
import { A11yAnnouncement } from "../../../../components";

const heading = {
  paddingBottom: "15px",
  fontSize: "24px",
};

const wrapper = {
  padding: "30px 0",
  textAlign: "center",
};

export const PaymentComplete = () => (
  <div>
    <Helmet>
      <title>Payment Complete</title>
    </Helmet>
    <A11yAnnouncement>{`Navigated to Payment Complete page, please close this window`}</A11yAnnouncement>
    <div style={wrapper} className="payment-complete_wrapper">
      <h1 style={heading}>Payment Complete</h1>
      <p style={{ padding: "10px 0" }}>
        Thank you for completing your payment, please close this window.
      </p>
    </div>
  </div>
);

PaymentComplete.displayName = "PaymentComplete";

export default PaymentComplete;
