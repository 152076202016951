import { Triangle } from "@kofile/icons";
import { Menu } from "@kofile/react-a11y-menu";
import classnames from "classnames";
import curry from "ramda/src/curry";
import identity from "ramda/src/identity";
import propIs from "ramda/src/propIs";
import { Option } from "./_option";
import { Options } from "./_options";

const Wrap = ({ children, className, dataTestId }) => (
  <div className={classnames("a11y-menu", className)} data-testid={dataTestId}>
    {children}
  </div>
);

const Toggle = ({ label, ...rest }) => (
  <button className="a11y-menu__control" data-tourid="menu__control" {...rest}>
    {label}
    <Indicator isOpen={rest.isOpen} />
  </button>
);

const Indicator = ({ isOpen }) => (
  <Triangle
    id={isOpen ? "triangle-icon-open" : "triangle-icon-closed"}
    className="indicator"
    rotation={isOpen ? 270 : 90}
    filled
  />
);

const DropdownMenu = (props) => {
  const { className, dataTestId, label, options, optionRenderer, alignment } =
    props;

  if (process.env.IS_CLIENT === "f")
    return (
      <Wrap className={className} dataTestId={dataTestId}>
        <Toggle label={label} />
      </Wrap>
    );

  const makeOptions = curry(({ item, closeMenu }, option, i) => {
    const onClick = () => {
      if (propIs(Function, "onClick", option)) {
        option.onClick();
      }
      closeMenu();
    };

    return (
      <Option key={i}>{optionRenderer({ ...option, ...item, onClick })}</Option>
    );
  });

  return (
    <Wrap className={className} dataTestId={dataTestId}>
      <Menu>
        {({ menu, control, isOpen, item }) => (
          <>
            <Toggle {...control} label={label} />
            {isOpen && (
              <Options alignment={alignment} {...menu}>
                {options.map(makeOptions({ item, closeMenu: control.onClick }))}
              </Options>
            )}
          </>
        )}
      </Menu>
    </Wrap>
  );
};

DropdownMenu.defaultProps = {
  className: "",
  label: "",
  options: [],
  optionRenderer: identity,
  alignment: "left",
};

export default DropdownMenu;
