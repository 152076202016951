import append from "ramda/src/append";
import compose from "ramda/src/compose";
import dropLast from "ramda/src/dropLast";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lensPath from "ramda/src/lensPath";
import lensProp from "ramda/src/lensProp";
import merge from "ramda/src/merge";
import path from "ramda/src/path";
import pipe from "ramda/src/pipe";
import set from "ramda/src/set";
import uniq from "ramda/src/uniq";
import { DOC_PREVIEW } from "../../constants";
import { isNotNil, isPresent } from "../../utils";
import { Status } from "../enums/status";
import { docPreview as initialState } from "../initialState";
import types from "../types/doc-preview";
import { handleActions } from "./utils";

export const localTypes = {
  fetchDocument: "fetch-a-document",
  document: "document",
  errorFetchingDocument: "error-fetching-document",
};

export default handleActions(initialState, {
  [types.UPDATE]: update,
  [types.VIEWED]: viewed,
  [types.RESET]: reset,
  [types.SET_ACTIVE_DOC]: setActiveDoc,
  [types.OPEN_ATTACHMENT_VIEWER]: openAttachmentViewer,
  [types.CLOSE_ATTACHMENT_VIEWER]: closeAttachmentViewer,
  [types.SET_ACTIVE_VIEW]: setActiveView,
  [types.SET_IS_MARGINAL_REFERENCE]: setIsMarginalReference,
  [types.CLEAR_IS_MARGINAL_REFERENCE]: clearIsMarginalReference,
  [types.ADD_MARGINAL_REFERENCE_VIEWED]: addMarginalReferenceViewed,
  [types.REMOVE_MARGINAL_REFERENCE_VIEWED]: removeMarginalReferenceViewed,
  [types.SET_PREVIEW_OPEN]: setPreviewOpen,
  [types.SET_PREVIEW_CLOSED]: setPreviewClosed,
  [types.CLEAR_ERROR]: clearError,
  [types.CLEAR_ACTIVE_VIEW]: clearActiveView,
  [types.UPDATE_PAGE]: updatePage,
  [localTypes.fetchDocument]: fetchDocumentSummary,
  [localTypes.document]: fetchDocumentSummaryFulfilled,
  [localTypes.errorFetchingDocument]: fetchDocumentSummaryRejected,
});

function update(state, { payload }) {
  const previewSettings = merge(state.previewSettings, payload);

  return merge(state, { previewSettings });
}

function viewed(state, { payload }) {
  return merge(state, {
    viewed: uniq(append(payload, state.viewed)),
  });
}

function reset(state) {
  return merge(state, {
    status: Status.Init,
    currentPage: 1,
    activeView: DOC_PREVIEW.VIEW_TYPES.IMAGE,
    previewSettings: {
      zoom: 1,
      rotation: 0,
      fit: "best",
    },
    marginalReferencesViewed: [],
    document: {
      id: null,
      activeAttachment: null,
      data: {},
      isMarginalReference: false,
    },
    isLoading: true,
    error: "",
  });
}

function setActiveDoc(state, { payload }) {
  return merge(state, {
    currentPage: 1,
    document: {
      id: payload,
      data: state.document.data,
      isMarginalReference: false,
    },
  });
}

function openAttachmentViewer(state, { payload }) {
  return compose(
    set(lensPath(["document", "activeAttachment"]), payload),
    set(lensProp("activeView"), DOC_PREVIEW.VIEW_TYPES.ATTACHMENT_IMAGE)
  )(state);
}

function closeAttachmentViewer(state) {
  return compose(
    set(lensPath(["document", "activeAttachment"]), null),
    set(lensProp("activeView"), null)
  )(state);
}

function setActiveView(state, { payload }) {
  return merge(state, {
    activeView: payload,
  });
}

function setIsMarginalReference(state) {
  return set(lensPath(["document", "isMarginalReference"]), true, state);
}

function clearIsMarginalReference(state) {
  return set(lensPath(["document", "isMarginalReference"]), false, state);
}

function addMarginalReferenceViewed(state, { payload }) {
  return {
    ...state,
    marginalReferencesViewed: [
      ...state.marginalReferencesViewed,
      parseInt(payload, 10),
    ],
  };
}

function removeMarginalReferenceViewed(state) {
  const margRefViewed = dropLast(1, state.marginalReferencesViewed);
  const newState = {
    ...state,
    marginalReferencesViewed: margRefViewed,
  };

  if (isEmpty(margRefViewed)) {
    return set(lensPath(["document", "isMarginalReference"]), false, newState);
  }

  return newState;
}

function setPreviewOpen(state) {
  return merge(state, { previewOpen: true });
}

function setPreviewClosed(state) {
  return merge(state, { previewOpen: false });
}

function clearError(state) {
  return merge(state, { error: "" });
}

function clearActiveView(state) {
  return merge(state, { activeView: null });
}

function updatePage(state, { payload }) {
  return merge(state, { currentPage: payload });
}

function fetchDocumentSummary(state) {
  return merge(state, { isLoading: true, status: Status.Loading });
}

const defaultCountMissingFromPayload = (state, data) => {
  return (
    isNil(data.truncatedCount) &&
    isNotNil(path(["document", "data", "truncatedCount"], state))
  );
};

const generateTotals = (state, data) => {
  let keys = [];

  if (isPresent(data.truncatedProperties)) {
    data.truncatedProperties.forEach((key) => keys.push(`${key}Total`));
  }

  const totals = {};

  keys.map((key) => {
    if (isNil(data[key]) && isNotNil(path(["document", "data", key], state))) {
      totals[key] = state.document.data[key];
    }

    if (isNotNil(data[key])) {
      totals[key] = data[key];
    }
  });

  return totals;
};

function fetchDocumentSummaryFulfilled(state, { payload }) {
  // TODO eventually update the rest of the UI to use the un-transformed values
  const data = pipe(
    movePropIfPresent({ from: "urls", to: "imageUrls" }),
    movePropIfPresent({ from: "thumbnails", to: "imageThumbnailUrls" })
  )(payload);

  data.attachmentThumbnailUrls = [];

  const totals = generateTotals(state, data);
  if (Object.keys(totals).length > 0) {
    data.totals = totals;
  }

  if (defaultCountMissingFromPayload(state, data)) {
    data.truncatedCount = state.document.data.truncatedCount;
  }

  for (const attachment of data.attachments || []) {
    data.attachmentThumbnailUrls.push(attachment.thumbnail);
    attachment.imageUrls = attachment.images;

    delete attachment.images;
    delete attachment.thumbnail;
  }

  return merge(state, {
    document: {
      id: state.document.id,
      data,
      isMarginalReference: state.document.isMarginalReference,
    },
    isLoading: false,
    status: Status.Loaded,
  });
}

function fetchDocumentSummaryRejected(state) {
  return merge(state, {
    isLoading: false,
    status: Status.Error,
    error: "Unable to fetch document summary.",
  });
}

function movePropIfPresent({ to, from }) {
  return (data) => {
    const value = data[from];

    if (isPresent(value)) {
      const { [from]: _, ...rest } = data;

      return { ...rest, [to]: value };
    }

    return data;
  };
}
