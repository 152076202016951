import PropTypes from "prop-types";
import { Fragment } from "react";

const ActiveAlerts = () => (
  <Fragment>
    No active alert criteria found
    <a href="/property-alert">Create one now</a>
  </Fragment>
);

export const NoAlerts = ({ type }) => {
  const isHistory = type === "history";
  const content = isHistory ? "No alerts in your history" : <ActiveAlerts />;

  return (
    <div className="fa-empty">
      <p className="fa-empty__text">{content}</p>
    </div>
  );
};

NoAlerts.displayName = "NoAlerts";

NoAlerts.propTypes = {
  type: PropTypes.string,
};

export default NoAlerts;
