export default {
  cartID: "",
  userCart: {
    byOrder: [],
    byHash: {},
  },
  totalPrice: null,
  convenienceFee: null,
  express: {},
  isFresh: false,
};
