import { css } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store";
import { isPresent } from "../../utils";
import { commonCardStyles } from "./application.submit.payment";

export const style = {
  download: css`
    grid-area: download;
  `,
  card: css`
    ${commonCardStyles}
    display: grid;
    grid-template-areas:
      "icon description"
      "icon action";
    grid-template-columns: 35px 1fr;
    grid-template-rows: 1fr min-content;
    grid-gap: 15px;

    p {
      grid-area: description;
      margin: 0;
    }
  `,
};

const defaultUrlCardCopy =
  "Download the forms from the link. All required forms are contained in this one PDF download.";

export const DownloadApplicationDoc = ({ url, copy }) => {
  const dispatch = useDispatch();
  const { getImg } = selectors.assets;
  const downloadIcon = useSelector(getImg("icon_download.svg"));

  useEffect(() => {
    dispatch(actions.user.fetchUserCards());
  }, []);

  return (
    <div css={[style.download, style.card]}>
      <p>{isPresent(copy) ? copy : defaultUrlCardCopy}</p>

      <img alt="green checkmark icon" src={downloadIcon} />
      <a target="_blank" rel="noreferrer noopener" href={url}>
        Download Application
      </a>
    </div>
  );
};
