import { makeActionTypes } from "../../utils";

export default makeActionTypes("checkout", [
  "CHECKOUT_REQUESTED",
  "CHECKOUT_REJECTED",
  "CHECKOUT_ERROR",
  "CLEAR",
  "CLEAR_ERROR",
  "MAKE_CHECKOUT_STALE",
  "MAKE_CHECKOUT_FRESH",
]);
