import createBrowserHistory from "history/createBrowserHistory";
import createMemoryHistory from "history/createMemoryHistory";

const createHistory = (historyObj) => {
  if (process.env.NODE_ENV === "test" || typeof window === "undefined") {
    const initialEntries = [];

    return createMemoryHistory({ initialEntries });
  }

  if (historyObj) {
    return createMemoryHistory(historyObj);
  }

  return createBrowserHistory();
};

export default createHistory;
