const A11yButton = (props) => {
  const {
    id,
    className = "",
    disabled = false,
    label,
    icon = null,
    onClick = () => {},
    type = "button",
  } = props;

  const commonProps = {
    onClick,
    disabled,
    className,
    type,
    role: "button",
    "aria-disabled": disabled,
    onKeyPress: (e) => {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        onClick?.(e);
      }
    },
  };

  if (typeof icon === "function") {
    return (
      <button {...commonProps} aria-labelledby={id}>
        <span id={id} hidden>
          {label}
        </span>
        {icon({ "aria-hidden": true })}
      </button>
    );
  }

  return (
    <button {...commonProps} aria-label={label}>
      {label}
    </button>
  );
};

export default A11yButton;
