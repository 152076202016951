import { css } from "@emotion/react";

export const selectStyles = {
  container: (base) => ({
    ...base,
    gridArea: "department-select",
    boxSizing: "border-box",
  }),
  control: (base, { isFocused }) => ({
    ...base,
    border: "1px solid #e5e8ec",
    borderRadius: "4px 0 0 4px",
    backgroundColor: "#dde0e5",
    width: "180px",
    height: "100%",
    boxShadow: isFocused ? "0 0 0 1px #2684ff inset" : "unset",
  }),
};

export const deptSelectControlStyle = css`
  height: 100%;
`;

export const landCorners = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px;

  span {
    margin-left: 7px;
    margin-bottom: 1px;
  }
`;
