import merge from "ramda/src/merge";
import omit from "ramda/src/omit";
import { notifications as initialState } from "../initialState";
import types from "../types/notifications";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.ADD_NOTIFICATION]: addNotification,
  [types.REMOVE_NOTIFICATION]: removeNotification,
});

function addNotification(state, { payload }) {
  return merge(state, { [payload.id]: payload });
}

function removeNotification(state, { payload }) {
  return omit([payload.id], state);
}
