import Card from "./card";

const ErrorCard = ({ close, message }) => (
  <Card
    hideBorder={true}
    iconName="icon_warning_triangle"
    close={close}
    body={{
      title: "There was an error.",
      content: message ? message : "Please try again later.",
    }}
    center={true}
    isError={true}
  />
);

export default ErrorCard;
