import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

export const style = css`
  margin-left: 15px;
`;

export const FinishSubmissionInstructions = ({
  copy = {},
  type = "",
  defaultCopy = "",
}) => {
  const { getImg } = selectors.assets;
  const icon = useSelector(getImg("green_check.png"));

  return (
    <>
      <img alt="green checkmark icon" src={icon} />
      <p css={style}>{copy[type] || defaultCopy}</p>
    </>
  );
};
