import { css } from "@emotion/react";
import { Arrow } from "@kofile/icons";
import { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { LoadingSpinner } from "../../components";
import { colors, isNotNil } from "../../utils";
import { Container } from "./_date-range.inline-picker.container";
import DateInput from "./date-range.input";

const style = (dropdownOpen) => css`
  color: ${colors.grayDark};
  display: block;
  height: 15px;
  padding: 0 5px;
  width: 18px;

  ${dropdownOpen && `color: ${colors.white};`}
`;

const safeDate = (date) => (date instanceof Date && isNaN(date) ? "" : date);

export const InlineDatePicker = (props) => {
  const { isLoading, startDate, endDate, disabled = false, label } = props;
  const datepickerTo = useRef(null);
  const datepickerFrom = useRef(null);

  useEffect(() => {}, [isLoading]);

  const clickOutside = () => {
    if (isNotNil(datepickerTo.current)) {
      datepickerTo.current.cancelFocusInput();
      datepickerTo.current.setOpen(false);
    }
    if (isNotNil(datepickerFrom.current)) {
      datepickerFrom.current.cancelFocusInput();
      datepickerFrom.current.setOpen(false);
    }
  };

  const handleSelect = (e) => {
    const { target } = e.nativeEvent;
    target.setSelectionRange(0, target.value.length);
  };

  const setLabel = (period) => {
    const dateType = label.length ? label : "Date";
    return `${period}ing ${dateType}`;
  };

  const commonProps = {
    onFocus: handleSelect,
    adjustDateOnChange: true,
    autoComplete: "off",
    disabled,
    dateFormat: "M/d/yyyy",
    showMonthDropdown: false,
    showYearDropdown: true,
    dropdownMode: "select",
    startDate: safeDate(startDate),
    endDate: safeDate(endDate),
    className: "date-range-select__date-range-input",
    onClickOutside: clickOutside,
  };

  const startDatePicker = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <DatePicker
          {...commonProps}
          customInput={<DateInput tooltip={setLabel("Start")} />}
          ref={datepickerTo}
          selected={safeDate(startDate)}
          selectsStart
          onChange={props.onChangeStart}
          onBlur={props.onChangeStart}
          minDate={safeDate(props.minDate)}
          maxDate={safeDate(endDate)}
          disabledKeyboardNavigation
        />
      );
    }
  };

  const endDatePicker = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <DatePicker
          {...commonProps}
          onFocus={handleSelect}
          customInput={<DateInput tooltip={setLabel("End")} />}
          ref={datepickerFrom.current}
          selected={safeDate(endDate)}
          selectsEnd
          onChange={props.onChangeEnd}
          onBlur={props.onChangeEnd}
          minDate={safeDate(startDate)}
          maxDate={safeDate(props.maxDate)}
          calendarClassName="rightCalendar"
          popperPlacement="bottom-end"
          disabledKeyboardNavigation
        />
      );
    }
  };

  return (
    <Container>
      {startDatePicker()}
      <Arrow css={style(props.dropdownOpen)} rotation={180} />
      {endDatePicker()}
    </Container>
  );
};
