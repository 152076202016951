import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectors } from "../../../store";

const EmptyNotepad = () => {
  const notepadGraphic = useSelector(
    selectors.assets.getImg("graphic_empty_notepad.svg")
  );

  return (
    <section className="empty-notepad">
      <img
        alt="notepad-graphic"
        src={notepadGraphic}
        className="empty-notepad__graphic"
      />
      <h1 className="empty-notepad__header">Your Personal Workspace</h1>
      <p className="empty-notepad__info">
        Move document to the Notepad, add notes for each document and export the
        results in a number of formats.
      </p>
      <Link to="/" className="empty-notepad__button">
        Search for Documents
      </Link>
    </section>
  );
};

EmptyNotepad.displayName = "EmptyNotepad";

export default EmptyNotepad;
