import styled from "@emotion/styled";
import BlueButton from "./_button.blue";

export default styled(BlueButton)`
  color: #006dd5;
  border: 1px solid #d9dde3;
  background-color: var(--color-neutral-lightest);

  &:disabled {
    color: #9ea0a5;
    border: 1px solid var(--color-neutral-lightest);
    cursor: default;
  }
`;
