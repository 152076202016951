import { css } from "@emotion/react";

export default {
  wrapper: css`
    width: 100%;
    margin: 0 auto;
    max-width: 1500px;
    padding: 30px;
    box-sizing: border-box;
  `,
  error: {
    wrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      padding: 50px;
      box-sizing: border-box;
      margin: 0 auto;
    `,
    icon: css`
      color: #c4382f;
      width: 50px;
      height: 50px;
      margin-right: 20px;
    `,
    text: css`
      font-size: 14px;
      color: #041d44;
      font-style: italic;
      font-size: 25px;
    `,
  },
  grid: css`
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 20px;
  `,
  summary: css`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  `,
  image: css`
    width: 100%;
    height: auto;
  `,
};
