import { connectRouter, routerMiddleware } from "connected-react-router";
import compose from "ramda/src/compose";
import flatten from "ramda/src/flatten";
import values from "ramda/src/values";
import pathOr from "ramda/src/pathOr";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { Subject } from "rxjs";
import { actions } from "../store";
import kosearchStoreEpics from "../store/epics";
import kosearchStoreReducers from "../store/reducers";
import { findKeyByPath } from "../utils";
import makeMiddleware from "./redux-middleware";

const mkStore = (dependencies, initialData) => {
  const { history, socket, ...epicDependencies } = dependencies;
  const epics = flatten([values(kosearchStoreEpics)]);

  const epicMiddleware = createEpicMiddleware({
    dependencies: epicDependencies,
  });

  const rootEpic = combineEpics(...epics);
  const completedRemoteActionStream = new Subject();

  const middlewares = [
    epicMiddleware,
    ...compose(values, makeMiddleware)(socket),
    routerMiddleware(history),
  ];

  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...kosearchStoreReducers,
    }),
    initialData,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  epicMiddleware.run(rootEpic);

  const { workspaces } = store.getState();
  const { tabs } = workspaces;
  const { location = {} } = history;
  const { pathname, search } = location;
  const fullPath = `${pathname}${search}`;

  // If there is a workspace whose path matches, activate it
  const workspaceID = findKeyByPath(tabs, fullPath);

  history.replace({ pathname, search, state: { workspaceID } });

  socket.onConnected(() => {
    store.dispatch(actions.onlineStatus.connected());
  });

  socket.onClosed(() => {
    store.dispatch(actions.onlineStatus.disconnected());
  });

  socket.onConnecting(() => {
    store.dispatch(actions.onlineStatus.connecting());
  });

  socket.onAPIMsg((action) => {
    store.dispatch(action);
    completedRemoteActionStream.next(action);
  });

  // enable cross-talk between HTMX & global Redux store
  if (process.env.IS_CLIENT === "t") {
    document.body.addEventListener("htmx:updateRedux", (event) => {
      if (!Array.isArray(event.detail.value)) return;

      for (let [action, value] of event.detail.value) {
        action = pathOr(null, action.split("."), actions);

        if (typeof action === "function") {
          store.dispatch(action(value));
        }
      }
    });
  }

  if (module.hot) {
    module.hot.accept(["../store/reducers"], () => {
      const reducer = combineReducers({
        router: connectRouter(history),
        ...require("../store/reducers"),
      });

      store.replaceReducer(reducer);
    });
  }

  return [store, completedRemoteActionStream];
};

export default mkStore;
