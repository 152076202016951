import { LoadingSpinner } from "../../components";
import ErrorBar from "../common/error.bar";
import { BlueButton } from "./_button.blue";
import { RedButton } from "./_button.red";
import { WhiteButton as CancelButton } from "./_button.white";
import { RemoveModalBody as Body } from "./_remove-modal.body";
import { RemoveModalContainer as Container } from "./_remove-modal.container";
import { RemoveModalFooter as Footer } from "./_remove-modal.footer";
import { RemoveModalTitle as Title } from "./_remove-modal.title";

const ConfirmActionModal = (props) => {
  const {
    action,
    cancel,
    content,
    title,
    error = "",
    warnUser = false,
    color = "red",
    loading = false,
    contentLoading = false,
  } = props;

  if (contentLoading) return <LoadingSpinner />;

  const Button = color === "red" ? RedButton : BlueButton;

  return (
    <Container>
      <Title>{title}</Title>
      <ErrorBar error={error} warn={warnUser} />
      <Body>{content}</Body>
      <Footer>
        {cancel && (
          <CancelButton type="button" onClick={cancel.onClick}>
            {cancel.text}
          </CancelButton>
        )}
        {action && (
          <Button
            type="button"
            onClick={action.onClick}
            disabled={action.disabled}
          >
            {loading ? "Loading..." : action.text}
          </Button>
        )}
      </Footer>
    </Container>
  );
};
export default ConfirmActionModal;
