import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { isAbsent } from "../../../utils";

const styles = {
  link: css`
    padding: var(--spacing-xs);
    border-radius: var(--primitive-6);
    border: 1px solid var(--color-primary-600);
    color: var(--color-primary-600);
    text-decoration: none;
    cursor: pointer;
    text-align: center;
  `,
};

export const ContentLink = ({ link }) => {
  if (isAbsent(link)) return null;

  if (link.url) {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        css={styles.link}
      >
        {link.title}
      </a>
    );
  }

  return (
    <Link css={styles.link} to={link.path}>
      {link.title}
    </Link>
  );
};
