import { createElement } from "react";
import { useSelector } from "react-redux";
import SignIn from "../App/screens/SignIn/View";
import { selectors } from "../store";

export const useLogin = () => {
  const loggedIn = useSelector(selectors.user.getLoggedIn);
  const ret = { loggedIn, do: () => {} };

  if (!loggedIn) {
    return { ...ret, do: () => createElement(SignIn) };
  }

  return ret;
};
