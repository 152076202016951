import { css } from "@emotion/react";
import { isAbsent } from "../../utils";

const styles = {
  container: css`
    margin-bottom: 1rem;
  `,
  label: css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  `,
  select: css`
    border: 1px solid rgba(4, 29, 68, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.8em;
    font-weight: lighter;
    height: 32px;
    padding: 0 0.5rem;
    width: 100%;
    background-color: white;
  `,
  error: css`
    color: #c4382f;
    margin-top: 5px;
  `,
};

export const Dropdown = (props) => {
  const { id, label, options, value, onChange, required, error } = props;

  if (isAbsent(options)) return null;

  return (
    <div css={styles.container}>
      <label css={styles.label} htmlFor={id}>
        {label}
      </label>
      <select
        css={styles.select}
        id={id}
        name={id}
        value={value}
        onChange={(e) => void onChange(e.target.value)}
        required={required}
        aria-label={label}
      >
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p css={styles.error} id={`${id}-error`} role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  value: "",
  onChange: () => {},
  required: false,
  options: [],
  error: "",
};

export default Dropdown;
