import classnames from "classnames";
import prop from "ramda/src/prop";
import { useDispatch } from "react-redux";
import { DocumentActions } from "../../../../../../../components";
import { actions } from "../../../../../../../store";
import { useShouldDownload } from "../../../use-should-download";
import { CartDropdownWrapper } from "./_header.cart-dropdown";
import { DocType } from "./_header.doc-type";
import { Info } from "./_header.info";
import { LegalDropdownWrapper } from "./_header.legal-dropdown";
import { Wrapper } from "./_header.wrapper";
import { Background } from "./header.background";
import { StatusIcons } from "./header.icons";
import { LegalDropdown } from "./header.legal-dropdown";

const uncertifiedDocumentMessage = "This document is not certified.";
const TOOLTIP_ID = "warning-icon";

export const Header = ({
  doc,
  hasBeenViewed,
  inCart,
  isUncertified,
  legals,
}) => {
  useShouldDownload({ docId: doc.id });

  const dispatch = useDispatch();
  const { docType = "Type", title = "", id, imageId } = doc;
  const { show, hide } = actions.tooltip;

  const showTooltip = (tooltipProps) => dispatch(show(tooltipProps));
  const hideTooltip = () => dispatch(hide());
  const onWarningHover = makeOnWarningHover(showTooltip);

  const typeClassName = classnames("card-header__type", {
    "card-header__type-uncertified": isUncertified,
  });

  return (
    <Wrapper>
      <Background inCart={inCart} docId={id} />
      <Info>
        <div className={typeClassName}>
          <DocType>{docType}</DocType>
          {isUncertified && (
            <div
              className="card-header__uncertified-tooltip-container"
              role="tooltip"
              onMouseEnter={onWarningHover}
              onMouseLeave={hideTooltip}
              aria-describedby={TOOLTIP_ID}
            >
              !
            </div>
          )}
        </div>
        {title && <h3>{title}</h3>}
      </Info>
      <LegalDropdownWrapper>
        {legals.enabled && (
          <LegalDropdown
            activeLegalOption={legals.displayOption}
            onClick={legals.update}
          />
        )}
      </LegalDropdownWrapper>
      <CartDropdownWrapper>
        <DocumentActions
          docId={id}
          imageId={imageId}
          className="card-header__select"
          customActions={prop("customActions", doc)}
        />
      </CartDropdownWrapper>
      <StatusIcons id={id} hasBeenViewed={hasBeenViewed} inCart={inCart} />
    </Wrapper>
  );
};

Header.defaultProps = {
  legals: {
    displayOption: null,
    enabled: false,
    update: () => {},
  },
};

export default Header;

// --------------------------------------------------------------------- private

function makeOnWarningHover(showTooltip) {
  return (event) => {
    const node = event.target;
    const { top, left, height, width } = node.getBoundingClientRect();
    const offsetTop = height / 2 + window.pageYOffset;
    const offsetLeft = width / 2 + window.pageXOffset;
    const tooltipProps = {
      top: top + offsetTop,
      left: left + offsetLeft,
      position: "bottom",
      children: uncertifiedDocumentMessage,
      tooltipId: TOOLTIP_ID,
    };

    showTooltip(tooltipProps);
  };
}
