import styled from "@emotion/styled";

export const CardsContainer = styled.div`
  width: 100%;

  & * {
    box-sizing: border-box;
  }

  & em {
    background: #fdf76a;
  }
`;
