import addIndex from "ramda/src/addIndex";
import map from "ramda/src/map";
import split from "ramda/src/split";
import React from "react";
import { emptyOrNil } from "../../utils";

const splitParagraphs = split(/[\n\s]{2,}/g);
const splitLineBreaks = split("\n");
const mapIndexed = addIndex(map);

const wrapFragment = (input) => <React.Fragment>{input}</React.Fragment>;

const intersperseBreaks = (lines) =>
  lines.reduce((memo, curr, index, arr) => {
    const pair = index !== arr.length - 1 ? [curr, <br key={index} />] : [curr];

    return memo.concat(pair);
  }, []);

const generateComponents = (lines, index) => (
  <p key={index}>{intersperseBreaks(lines)}</p>
);

const parseParagraphs = (rawText) => {
  return map(splitLineBreaks, splitParagraphs(rawText));
};

export const generateTextComponents = (rawText) => {
  if (emptyOrNil(rawText)) {
    return [];
  }

  const paragraphs = parseParagraphs(rawText);

  return wrapFragment(mapIndexed(generateComponents, paragraphs));
};
