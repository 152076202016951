import styled from "@emotion/styled";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: "title price choose-button";
  border-top: 1px solid #dadde2;
  align-items: center;

  ${(props) =>
    props.showPlanTypePicker
      ? ""
      : `
    &:first-of-type {
      padding-top: 0px;
      border-top: 0px;
    }
  `}
`;
