import { compose, withState, lifecycle } from "recompose";
import withSpinnerWhileLoading from "./withSpinnerWhileLoading";

export default compose(
  withState("loaded", "setLoaded", false),
  lifecycle({
    componentDidMount() {
      this.props.setLoaded(true);
    },
  }),
  withSpinnerWhileLoading((props) => !props.loaded)
);
