import { css } from "@emotion/react";
import { TokenizedSelect } from "../../../components";
import { RadioSet } from "../../../components/Fields";
import { isPresent } from "../../../utils";
import { FieldContainer } from "./configure.field.container";
import { useMergeCriteriaRadio } from "./use-merge-criteria-radio";

const styles = {
  input: css`
    margin-top: 10px;
    margin-bottom: 20px;
  `,
  radios: css`
    padding-bottom: 20px;
  `,
  subhead: css`
    font-weight: 600;
  `,
  info: css`
    color: #818ea0;
    font-size: 15px;
    line-height: 1.5;
  `,
};

const defaultPlaceholder =
  "LASTNAME FIRSTNAME (Insert party name by pressing enter)";

export const ConfigureNames = (props) => {
  const {
    value = [],
    inputChange = () => {},
    placeholder,
    copy,
    label,
    fieldType,
    id,
    error,
    hideMergeParties = false,
  } = props;

  const { inputProps, radioOptions } = useMergeCriteriaRadio(true);
  const count = (Array.isArray(value) && value.length) || 0;

  return (
    <FieldContainer
      copy={copy}
      id={id}
      fieldType={fieldType}
      label={label}
      error={error}
    >
      <TokenizedSelect
        id={id}
        css={styles.input}
        onChange={inputChange}
        placeholder={isPresent(placeholder) ? placeholder : defaultPlaceholder}
        aria-describedby={`${id}-describe`}
      />
      {count >= 2 && !hideMergeParties && (
        <RadioSet
          css={styles.radios}
          inputProps={inputProps}
          radioOptions={radioOptions}
        />
      )}
    </FieldContainer>
  );
};
