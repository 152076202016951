import { css } from "@emotion/react";
import { isPresent } from "../../../utils";

const styles = {
  container: css`
    padding-botton: 20px;
  `,
  error: css`
    color: #b71c1c;
    font-size: 12px;
    padding-bottom: 20px;
  `,
  label: css`
    font-weight: 600;
    line-height: 1.1;
  `,
  subhead: css`
    line-height: 1.1;
  `,
  info: css`
    color: #818ea0;
    font-size: 15px;
    line-height: 1.1;
  `,
};

export const FieldContainer = (props) => {
  const { children, error, copy, id, fieldType, label } = props;

  return (
    <div css={styles.container}>
      <label css={styles.label} htmlFor={`${id}-${fieldType}`}>
        {label}
      </label>
      {isPresent(copy.subhead) && (
        <p id={`${id}-describe`} css={styles.subhead}>
          {copy.subhead}
        </p>
      )}
      {isPresent(copy.info) && <p css={styles.info}>{copy.info}</p>}
      {children}
      {isPresent(error) ? <p css={styles.error}>{error}</p> : null}
    </div>
  );
};
