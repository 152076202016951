import { css } from "@emotion/react";

const style = css`
  color: rgba(4, 29, 68, 0.8);
  display: flex;
  flex-direction: column;
  width: 500px;

  p {
    line-height: 1.5;
  }

  .checkbox__label {
    font-size: 1rem;
    font-weight: 400;
  }

  .checkbox--disabled .checkbox__container {
    background: #abacac;
    border: none;
  }
`;

export const FormContainer = ({ children }) => (
  <div css={style}>{children}</div>
);
