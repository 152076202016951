import { withModal } from "../../hoc";
import BlueButton from "../doc-detail.common/_button.blue";
import useDownloadDocument from "./use-download-document";

const DownloadButton = ({ modal }) => {
  const { available, downloadDoc, text, disabled } = useDownloadDocument(modal);

  if (!available) return null;

  return (
    <BlueButton disabled={disabled} onClick={downloadDoc}>
      {text}
    </BlueButton>
  );
};

export default withModal(DownloadButton);
