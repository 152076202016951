import styled from "@emotion/styled";

export default styled.div`
  align-items: flex-start;
  line-height: 1.25rem;
  background-color: ${(props) => (props.warn ? "#fef4c5" : "#f9eae9")};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  height: 40px;
  grid-column: 1 / -1;
`;
