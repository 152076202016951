import styled from "@emotion/styled";

export const PlanList = styled.section`
  & > * {
    padding-top: 35px;
  }

  & > * + * {
    margin-top: 35px;
  }
`;
