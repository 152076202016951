import { parse } from "query-string";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "../../../../components";
import { selectors } from "../../../../store";
import { Nav } from "./_nav";
import { NavContainer } from "./_nav-container";
import { NavLink } from "./_nav-link";
import { navLink } from "./_style.nav-link";
import Cart from "./cart";
import ExternalLinks from "./external-links";
import FraudAlert from "./fraud-alert";
import HelpMenu from "./help-menu";
import LoginButton from "./login-button";
import UserButton from "./user-button";
import UserTooltip from "./user-tooltip";

const Navigation = () => {
  const location = useLocation();
  const { user, configuration, cart } = selectors;
  const loading = useSelector(user.getLoading);
  const fraudAlertEnabled = useSelector(configuration.isFraudAlertEnabled);
  const cartAmount = useSelector(cart.getCartAmount);
  const fullName = useSelector(user.getUserFullName);
  const loggedIn = useSelector(user.getLoggedIn);
  const externalLinksEnabled = useSelector(
    configuration.areExternalLinksEnabled
  );

  const returnPath = propOr(
    `${location.pathname}${location.search}`,
    "returnPath",
    parse(location.search)
  );

  const encodedURI = encodeURIComponent(returnPath);
  const externalLinks = shouldDisplayLink(externalLinksEnabled, ExternalLinks);
  const fraudAlert = shouldDisplayLink(fraudAlertEnabled, FraudAlert);

  return (
    <NavContainer id="nav">
      <Nav>
        {externalLinks}
        {fraudAlert}
        <Cart cartAmount={cartAmount} />
        <NavLink>
          {loggedIn && (
            <UserTooltip>
              <UserButton fullName={fullName} />
            </UserTooltip>
          )}
          {!loggedIn && (
            <Link
              to="/register"
              css={navLink}
              anchorClass="a11y-menu"
              dataTestId="registerLink"
            >
              Register
            </Link>
          )}
        </NavLink>
        <NavLink data-tourid="helpMenu">
          <HelpMenu />
        </NavLink>
        <NavLink>
          <LoginButton
            loggedIn={loggedIn}
            loading={loading}
            signInLink={`/signin?returnPath=${encodedURI}`}
          />
        </NavLink>
      </Nav>
    </NavContainer>
  );
};

export default Navigation;

const shouldDisplayLink = (enabled, Component) => {
  if (!enabled) return null;

  return (
    <NavLink>
      <Component />
    </NavLink>
  );
};
