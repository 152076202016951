import PropTypes from "prop-types";
import concat from "ramda/src/concat";
import contains from "ramda/src/contains";
import filter from "ramda/src/filter";
import { compose, withHandlers, withState } from "recompose";
import { castArray, containsAll, mapIndexed } from "../../../../../../utils";
import CategoryDropdown from "./CategoryDropdown";

const joinArray = (arr) => arr.join(",");

const enhance = compose(
  withState(
    "selections",
    "updateSelections",
    (props) => props.selections || []
  ),
  withHandlers({
    addSelections: (props) => (options, category, value) => {
      const updatedSelections = concat(castArray(value), props.selections);

      if (containsAll(options, updatedSelections)) {
        const notInOptions = (val) => !contains(val, options);
        const filteredSelections = filter(notInOptions, props.selections);
        const selectionsWithCategory = concat(
          castArray(category),
          filteredSelections
        );

        props.notifyParent(joinArray(selectionsWithCategory));

        return props.updateSelections(selectionsWithCategory);
      }

      props.updateSelections(updatedSelections);

      return props.notifyParent(joinArray(updatedSelections));
    },
    removeSelections: (props) => (options, category, value) => {
      const isNotValue = (val) => val !== value;

      if (contains(category, props.selections)) {
        const selectionsToEdit = filter(
          (val) => val !== category,
          props.selections
        );
        const updatedSelections = concat(
          selectionsToEdit,
          filter(isNotValue, options)
        );

        props.updateSelections(updatedSelections);

        return props.notifyParent(joinArray(updatedSelections));
      }

      const filteredSelections = filter(isNotValue, props.selections);

      props.updateSelections(filteredSelections);

      return props.notifyParent(joinArray(filteredSelections));
    },
    addAll: (props) => (options, value) => {
      const notInOptions = (val) => !contains(val, options);
      const filteredSelections = filter(notInOptions, props.selections);
      const updatedSelections = concat(filteredSelections, castArray(value));

      props.updateSelections(updatedSelections);

      return props.notifyParent(joinArray(updatedSelections));
    },
    removeAll: (props) => (options, category) => {
      const filteredSelections = filter(
        (val) => val !== category,
        props.selections
      );

      props.updateSelections(filteredSelections);

      return props.notifyParent(joinArray(filteredSelections));
    },
  })
);

const DecadeSelect = (props) => {
  const {
    structure,
    selections,
    addSelections,
    addAll,
    removeSelections,
    removeAll,
    id,
    isOnlySelection = false,
  } = props;

  const dropdownProps = {
    selections,
    addAll,
    addSelections,
    removeSelections,
    removeAll,
    id,
    isOnlySelection,
  };

  const createDropdown = (values, key) => (
    <CategoryDropdown key={key} {...dropdownProps} {...values} />
  );

  const mappedRows = mapIndexed(createDropdown, structure);

  return <div className="decade-select">{mappedRows}</div>;
};

DecadeSelect.displayName = "DecadeSelect";

DecadeSelect.propTypes = {
  structure: PropTypes.arrayOf(PropTypes.object).isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  addSelections: PropTypes.func.isRequired,
  removeSelections: PropTypes.func.isRequired,
  addAll: PropTypes.func.isRequired,
  removeAll: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOnlySelection: PropTypes.bool,
};

export default enhance(DecadeSelect);
