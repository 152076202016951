import { LoadingSpinner } from "../../components";
import { chooseViewFor } from "../../utils/choose-view-for";
import { ContactSupport } from "../common/contact-support";
import { HistoryTable } from "./history.table";
import { useIndividualHistory } from "./use-history";

export const Individual = () => {
  const { data, status, error } = useIndividualHistory();

  return chooseViewFor(status).when({
    isInit: () => <LoadingSpinner />,
    isLoading: () => <LoadingSpinner />,
    hasLoaded: () => <HistoryTable orders={data.orders} />,
    default: () => <ContactSupport error={error} />,
  });
};

export default Individual;
