const fieldMapping = {
  documentNumberRange: {
    type: "InputRange",
  },
  legalDescription: {
    type: "StringInput",
  },
  department: {
    type: "Select",
  },
  partyNames: {
    type: "TokenizedSelect",
  },
  book: {
    group: 1,
    type: "StringInput",
  },
  volume: {
    group: 1,
    type: "StringInput",
  },
  page: {
    group: 1,
    type: "PositiveNumberInput",
  },
  docTypes: {
    type: "TokenizedNestedSelect",
  },
  instrumentDateRange: {
    type: "InlineDatePicker",
  },
  recordedDateRange: {
    type: "InlineDatePicker",
  },
  applicationDateRange: {
    type: "InlineDatePicker",
  },
  ocrText: {
    type: "StringInput",
  },
  municipality: {
    type: "Autocomplete",
  },
};

export default fieldMapping;
