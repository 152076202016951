import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Route } from "react-router";
import { ErrorPage } from "../../../components";

export const Status = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = code;
      }

      return children;
    }}
  />
);

Status.displayName = "Status";

Status.propTypes = {
  code: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const NotFound = () => (
  <Status code={404}>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <ErrorPage code={404} />
  </Status>
);

NotFound.displayName = "NotFound";

export default NotFound;
