import { css } from "@emotion/react";
import { FieldContainer } from "./configure.field.container";

const styles = {
  container: css`
    padding: 20px 0;
  `,
  input: css`
    border: 1px solid rgba(4, 29, 68, 0.1);
    border-radius: 4px;
    width: 100%;
    font-size: 12px;
    height: 28px;
    padding: 2px 2px 2px 10px;
    color: #041d44;
    margin-top: 10px;
    margin-bottom: 20px;
  `,
};

export const ConfigureText = ({
  copy,
  id,
  fieldType,
  label,
  inputChange,
  placeholder,
  value,
  error,
}) => (
  <FieldContainer
    copy={copy}
    id={id}
    fieldType={fieldType}
    label={label}
    error={error}
  >
    <input
      id={id}
      css={styles.input}
      aria-label={label}
      type="text"
      onChange={(e) => inputChange(e.target.value)}
      placeholder={placeholder}
      value={value}
      aria-describedby={`${id}-describe`}
    />
  </FieldContainer>
);
