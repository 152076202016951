import { chooseViewFor } from "../../utils/choose-view-for";
import ErrorBar from "../common/error.bar";
import ViewDocumentImages from "../doc-detail.view-document.images";
import useAdditionalImages from "./use-additional-images";

const AdditionalImages = ({ type }) => {
  const { status } = useAdditionalImages(type);
  const msg = "There was an error, please try again.";

  return chooseViewFor(status).when({
    isLoading: () => <loading-indicator />,
    hasError: () => <ErrorBar error={msg} />,
    hasLoaded: () => <ViewDocumentImages type={type} />,
    default: () => <ErrorBar error={msg} />,
  });
};

export default AdditionalImages;
