import classnames from "classnames";
import PropTypes from "prop-types";
import always from "ramda/src/always";
import cond from "ramda/src/cond";
import has from "ramda/src/has";
import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import T from "ramda/src/T";
import { noop } from "../../utils";

const tableClassName = (className) => classnames("table", className);

const TableHeaderCell = ({ column, numItems, orderId }) => {
  const conditionalRender = cond([
    [
      has("renderHeader"),
      ({ renderHeader }) => renderHeader({ numItems, orderId }),
    ],
    [T, always(column.name)],
  ]);

  const headerClassName = classnames(
    "table__header-column",
    column.headerClassName
  );

  return (
    <th
      className={headerClassName}
      onClick={isNil(column.action) ? noop : column.action}
    >
      {conditionalRender(column)}
    </th>
  );
};

const TableHead = ({ metadata, numItems, orderID }) => (
  <thead className="table__header">
    <tr className="table__header-row">
      {metadata.columns.map((column, i) => (
        <TableHeaderCell
          key={i}
          column={column}
          numItems={numItems}
          orderId={orderID}
        />
      ))}
    </tr>
  </thead>
);

TableHead.displayName = "TableHead";
TableHead.propTypes = {
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
};

const TableBodyRow = ({ metadata, entity, orderID, docTypeLookupMap }) => {
  const { rowProps = noop } = metadata;
  const resolvedRowProps = rowProps(entity);

  return (
    <tr {...resolvedRowProps}>
      {metadata.columns.map((column, i) => (
        <TableBodyCell
          key={`table-body-cell-${i}`}
          column={column}
          cell={prop(column.key, entity)}
          entity={entity}
          orderId={orderID}
          docTypeLookupMap={docTypeLookupMap}
        />
      ))}
    </tr>
  );
};

TableBodyRow.displayName = "TableBodyRow";
TableBodyRow.propTypes = {
  entity: PropTypes.object,
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  docTypeLookupMap: PropTypes.object,
};

const TableBodyCell = ({ column, cell, entity, orderId, docTypeLookupMap }) => {
  const tdClass = classnames("table__body-cell", column.cellClassName);

  const conditionalRender = cond([
    [
      has("render"),
      (config) =>
        config.render({
          cell,
          entity,
          itemId: entity.id,
          orderId,
          docTypeLookupMap,
        }),
    ],
    [T, always(cell)],
  ]);

  return <td className={tdClass}>{conditionalRender(column)}</td>;
};

TableBodyCell.displayName = "TableBodyCell";
TableBodyCell.propTypes = {
  cell: PropTypes.any,
  entity: PropTypes.object,
  column: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  docTypeLookupMap: PropTypes.object,
};

const TableBody = ({ metadata, data, orderID, docTypeLookupMap }) => (
  <tbody>
    {data.map((entity, i) => (
      <TableBodyRow
        key={`table-body-row-${i}`}
        metadata={metadata}
        entity={entity}
        docTypeLookupMap={docTypeLookupMap}
        orderID={orderID}
      />
    ))}
  </tbody>
);

TableBody.displayName = "TableBody";
TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  docTypeLookupMap: PropTypes.object,
};

const Table = ({
  metadata,
  orderID,
  data,
  className,
  docTypeLookupMap,
  numItems,
  showHead = true,
}) => (
  <table className={tableClassName(className)}>
    {showHead && (
      <TableHead metadata={metadata} numItems={numItems} orderID={orderID} />
    )}
    <TableBody
      metadata={metadata}
      data={data}
      numItems={numItems}
      orderID={orderID}
      docTypeLookupMap={docTypeLookupMap}
    />
  </table>
);

Table.displayName = "Table";
Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  docTypeLookupMap: PropTypes.object,
};

export { TableHead, TableBody, TableBodyRow, TableBodyCell, TableHeaderCell };

export default Table;
