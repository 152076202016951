import { useCombobox } from "downshift";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { hasError } from "../../constants/status";
import { selectors } from "../../store";
import { debounce } from "../../utils";
import { Container } from "./_input.autocomplete.container";
import { Input } from "./_input.autocomplete.input";
import { List } from "./_input.autocomplete.list";
import { Error } from "./_input.error";
import { VisuallyHiddenLabel } from "./_input.label.visually-hidden";
import { Suggestions } from "./input.autocomplete.suggestions";
import { useFetchAutocompleteSuggestions } from "./use-fetch-autocomplete-suggestions";

export const Autocomplete = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const { data, status, initiate } = useFetchAutocompleteSuggestions();
  const debounceInitiate = useRef(null);
  const suggestions = data === null ? [] : data;

  const departmentCode = useSelector(
    selectors.workspaces.getSelectedDepartment
  );

  if (!debounceInitiate.current) {
    debounceInitiate.current = debounce(initiate, 250);
  }

  const onInputValueChange = ({ inputValue }) => {
    setInputValue(inputValue);
    props.onChange(inputValue);

    debounceInitiate.current({
      departmentCode,
      fields: [props.id],
      prefix: inputValue,
      size: 20,
    });
  };

  const {
    isOpen,
    getMenuProps,
    getLabelProps,
    getInputProps,
    getComboboxProps,
    selectItem,
    selectedItem,
    ...rest
  } = useCombobox({
    items: suggestions,
    onInputValueChange,
    inputValue,
    itemToString: (item) => (item ? item.label : ""),
  });

  const errorMsg = props.error || `Error getting ${props.id} list`;
  const error = hasError(status);
  const inputProps = getInputProps();
  const formCleared = props.value !== inputProps.value && props.cleared;

  useEffect(() => {
    if (formCleared) selectItem(null);
  }, [formCleared, selectItem]);

  return (
    <>
      <Container id={props.id} {...getComboboxProps()}>
        <VisuallyHiddenLabel {...getLabelProps()}>
          {props.id}
        </VisuallyHiddenLabel>
        <Input error={error} {...inputProps} />
      </Container>
      <List {...getMenuProps()}>
        {isOpen && <Suggestions {...rest} suggestions={suggestions} />}
      </List>
      {error && <Error>{errorMsg}</Error>}
    </>
  );
};
