import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import pipe from "ramda/src/pipe";
import propOr from "ramda/src/propOr";
import toString from "ramda/src/toString";
import values from "ramda/src/values";
import { useSelector } from "react-redux";
import { makeBEMBlock } from "../../utils";
import { Notification } from "./notification";

const block = makeBEMBlock("notifications");

const Notifications = () => {
  const notifications = useSelector(pipe(propOr({}, "notifications"), values));

  if (isEmpty(notifications)) return null;

  return (
    <article className={toString(block)}>
      <Notification {...head(notifications)} block={block} />
    </article>
  );
};

export default Notifications;
