import { css } from "@emotion/react";
import DateTime from "../../components/DateTime";
import { isNotNil } from "../../utils";
import { CardArea } from "./_card-area";

const style = css`
  color: rgba(4, 29, 68, 0.8);
`;

const SavedCard = ({ cardInfo, handleEdit }) => {
  const { last4, exp_month, exp_year, expirationDate, last4Digits } = cardInfo;

  const cardLast4 = isNotNil(last4Digits) ? last4Digits : last4;

  const date = isNotNil(expirationDate)
    ? new Date(expirationDate)
    : new Date(exp_year, exp_month - 1);

  return (
    <CardArea className="saved-card" onClick={handleEdit} type="button">
      <span css={style}>**** **** **** {cardLast4}</span>
      <span>
        <DateTime dateTime={date} formatting={"MM/yy"} />
      </span>
    </CardArea>
  );
};

SavedCard.displayName = "SavedCard";

export default SavedCard;
