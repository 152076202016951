const LotBlock = ({ data = [] }) => {
  return data.map((lotBlockDesc, i) => (
    <li key={i} className="doc-preview-group__summary-group-item">
      <span className="doc-preview-group__summary-group-label">
        {lotBlockDesc}
      </span>
    </li>
  ));
};

export default LotBlock;
