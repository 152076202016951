import classnames from "classnames";
import PropTypes from "prop-types";

const Refund = ({ refund, className }) => {
  const refundHide = "refund__hide";
  const refundClass = classnames("refund__title", className, {
    [refundHide]: !refund,
  });

  return (
    <div className={refundClass}>
      <span className="refund__text">
        <span className="refund__arrow">&#x2714;</span>
        Refund approved
      </span>
    </div>
  );
};

Refund.displayName = "Refund";
Refund.propTypes = {
  className: PropTypes.string,
  refund: PropTypes.bool,
};

export default Refund;
