import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle } from "recompose";
import { makeLoginRoute } from "../utils";

const mapStateToProps = ({ user }) => ({
  loggedIn: user.loggedIn,
});

export const redirectIfNeeded = ({ loggedIn, history, location }) => {
  if (!loggedIn) {
    history.push(makeLoginRoute(location));
  }
};

export const withAuthentication = compose(
  withRouter,
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      redirectIfNeeded(this.props);
    },
    componentWillReceiveProps(props) {
      redirectIfNeeded(props);
    },
  })
);

export default withAuthentication;
