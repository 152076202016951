import append from "ramda/src/append";
import either from "ramda/src/either";
import hasPath from "ramda/src/hasPath";
import lensIndex from "ramda/src/lensIndex";
import pathOr from "ramda/src/pathOr";
import pipe from "ramda/src/pipe";
import set from "ramda/src/set";
import tap from "ramda/src/tap";
import { useEffect, useState, useRef, useCallback } from "react";
import { isInit } from "../../constants/status";
import { useAPI } from "../../hooks/use-api";
import { useSocket } from "../../hooks/use-socket";
import { isNotNil, isPresent } from "../../utils";

const PDF_PATH = "pdf-path";
const ZIP_PATH = "zip-path";
const PDF_PATH_ERROR = "error-fetching-pdf-path";
const ZIP_PATH_ERROR = "error-fetching-zip-path";
const TIMEOUT = 1000 /* 1 sec */ * 60 /* 1 min */ * 2;

const getItemId = either(
  pathOr(null, ["itemId"]),
  pathOr(null, ["reason", "itemId"])
);

export const useOrder = (id) => {
  const [pdfLinks, setPDFLinks] = useState([]);
  const [zipLinks, setZIPLinks] = useState([]);
  const [timeoutsConfigured, setTimeoutsConfiguredTo] = useState(false);
  const timeouts = useRef({});

  const updatePDFLinks = useCallback(
    pipe(
      tap((link) => {
        const itemId = getItemId(link);

        if (itemId) {
          clearTimeout(timeouts.current[itemId]);

          delete timeouts.current[itemId];
        }
      }),
      (link) => (links) => {
        const existing = links.findIndex(
          (i) => getItemId(i) === getItemId(link)
        );

        return existing === -1
          ? append(link, links)
          : set(lensIndex(existing), link, links);
      },
      setPDFLinks
    ),
    [timeouts.current]
  );

  const updateZIPLinks = useCallback(
    pipe(
      tap(() => {
        clearTimeout(timeouts.current.zip);

        delete timeouts.current.zip;
      }),
      append,
      setZIPLinks
    ),
    [timeouts.current]
  );

  const {
    data: orderResponse,
    status,
    initiate,
  } = useAPI({
    send: "fetch-order-for-a-user",
    listenFor: {
      success:
        'I want to name this "order" but cannot because of the ncourt handler; ' +
        "https://github.com/kofile/ko-search-api/issues/1332",
      failure: "error-fetching-order",
    },
  });

  useEffect(() => {
    if (isInit(status) && isPresent(id)) {
      initiate({ id: Number(id) });
    }
  }, [status, id]);

  const { responses: pdfLinkResps } = useSocket({
    listenFor: [PDF_PATH, PDF_PATH_ERROR],
  });

  const { responses: zipLinkResps } = useSocket({
    listenFor: [ZIP_PATH, ZIP_PATH_ERROR],
  });

  useEffect(() => {
    pdfLinkResps.on(PDF_PATH, ({ payload }) => updatePDFLinks(payload));
    pdfLinkResps.on(PDF_PATH_ERROR, ({ payload }) => updatePDFLinks(payload));
    zipLinkResps.on(ZIP_PATH, ({ payload }) => updateZIPLinks(payload));
    zipLinkResps.on(ZIP_PATH_ERROR, ({ payload }) => updateZIPLinks(payload));

    return () => {
      pdfLinkResps.off(PDF_PATH);
      zipLinkResps.off(ZIP_PATH);
      pdfLinkResps.off(PDF_PATH_ERROR);
      zipLinkResps.off(ZIP_PATH_ERROR);
    };
  }, []);

  useEffect(() => {
    if (timeoutsConfigured === true) {
      return;
    }

    const assetRequests = pathOr(null, ["assetRequests"], orderResponse);

    if (assetRequests === null) {
      return;
    }

    for (const req of assetRequests) {
      if (req.assetType === "pdf") {
        timeouts.current[req.itemId] = setTimeout(() => {
          updatePDFLinks({
            reason: {
              itemId: req.itemId,
              message: "Document link timed out.",
            },
          });
        }, TIMEOUT);
      } else if (req.assetType === "zip") {
        timeouts.current.zip = setTimeout(() => {
          updateZIPLinks({ reason: { message: "ZIP link timed out." } });
        }, TIMEOUT);
      }
    }

    setTimeoutsConfiguredTo(true);

    return () => {
      Object.keys(timeouts.current).forEach((key) => {
        clearTimeout(key);
      });
    };
  }, [orderResponse, timeouts.current, timeoutsConfigured]);

  return { order: composeOrder(orderResponse, pdfLinks, zipLinks), status };
};

// maintain compatibility with existing order-receipt component
function composeOrder(orderResponse, pdfLinks, zipLinks) {
  const order = pathOr(null, ["order"], orderResponse);
  const assetRequests = pathOr(null, ["assetRequests"], orderResponse);

  if (order === null) {
    return null;
  }

  for (const req of assetRequests) {
    const index = order.lineItems.findIndex((i) => i.id === req.itemId);

    if (req.assetType === "pdf") {
      const pdfLink = pdfLinks.find((i) => getItemId(i) === getItemId(req));

      if (index !== -1 && isNotNil(pdfLink)) {
        const isError = hasPath(["reason", "message"], pdfLink);

        order.lineItems[index].downloadLink = {
          isLoading: false,
          error: isError ? "Document link not found." : null,
          link: pdfLink.link,
        };
      }
    } else if (req.assetType === "zip") {
      const zipLink = zipLinks[0];

      if (isNotNil(zipLink)) {
        const isError = hasPath(["reason", "message"], zipLink);

        order.zipLink = {
          isLoading: false,
          error: isError ? "ZIP link not found." : null,
          link: zipLink.link,
        };
      }
    }
  }

  return order;
}
