import { useEffect, useRef, memo } from "react";
import { isLoading } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import OcrHits from "../doc-detail.view-document.ocr";
import style from "./style";
import useDrag from "./use-drag";

const SVGImage = ({
  currentPageOcr,
  hitIndex,
  isOcrVisible,
  status,
  dimensions,
  canvas,
  viewBox,
  src,
  zoom,
  zoomIn,
  zoomOut,
  rotation,
  on,
  type,
}) => {
  const svgRef = useRef(null);
  const zoomInDebounced = useDebounce(zoomIn, 10);
  const zoomOutDebounced = useDebounce(zoomOut, 10);

  const { ref, transform } = useDrag({
    dimensions,
    rotation,
    zoom,
    on,
  });

  function createMouseObserver() {
    return {
      subscribers: [],

      subscribe(fn) {
        this.subscribers.push(fn);
      },

      unsubscribe(fn) {
        this.subscribers = this.subscribers.filter(
          (subscription) => subscription !== fn
        );
      },

      broadcast(data) {
        for (let i = 0; i < this.subscribers.length; i++) {
          this.subscribers[i](data);
        }
      },
    };
  }

  useEffect(() => {
    if (!window?.hovering) window.hovering = {};
    if (isLoading(status)) return;
    if (!window.mouseWheelObserver)
      window.mouseWheelObserver = createMouseObserver();

    window.mouseWheelObserver.subscribe((e) => {
      if (window.hovering[type]) {
        e.preventDefault();
        e.wheelDeltaY > 0 ? zoomInDebounced() : zoomOutDebounced();
      }
    });

    window.mouseWheelHandler = document.addEventListener(
      "wheel",
      (e) => {
        if (window?.mouseWheelObserver) window.mouseWheelObserver.broadcast(e);
      },
      { passive: false }
    );

    return () => {
      window.hovering = {};
    };
  }, [status]);

  if (isLoading(status)) {
    return (
      <figure css={style.spinner}>
        <loading-indicator />
      </figure>
    );
  }

  return (
    <svg
      viewBox={viewBox}
      width={canvas.width}
      height={canvas.height}
      display="block"
      ref={svgRef}
    >
      <g style={{ transform }} css={style.g}>
        <image
          ref={ref}
          css={style.image}
          xlinkHref={`https://${window.location.host}${src}`}
          width={dimensions.img.width}
          height={dimensions.img.height}
          onMouseEnter={() => {
            window.hovering[type] = true;
          }}
          onMouseLeave={() => {
            window.hovering[type] = false;
          }}
        />
        <OcrHits
          currentPageOcr={currentPageOcr}
          hitIndex={hitIndex}
          isOcrVisible={isOcrVisible}
          ocrScale={dimensions.ocrScale}
        />
      </g>
    </svg>
  );
};

export default memo(SVGImage);
