import { css } from "@emotion/react";
import CompleteAlert from "../../App/screens/FraudAlert/complete-alert";
import { Button } from "../../components/Modal";

const confirmedStyle = css`
  align-items: center;
  background-color: var(--color-neutral-lightest);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;

  & button {
    font-size: 15px;
  }
`;

export const Confirmed = ({ close, isModal }) => (
  <div css={confirmedStyle}>
    <CompleteAlert isModal={isModal} />

    <Button onClick={close} text="Close" />
  </div>
);
