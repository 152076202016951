import always from "ramda/src/always";
import pathOr from "ramda/src/pathOr";
import thunkify from "ramda/src/thunkify";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBar, NCourtPayment } from "../../components";
import { ENTITY_TYPES } from "../../constants";
import { actions, selectors } from "../../store";
import { ExpiredSubscriptionMessage } from "../../units/common/error.bar.expired.subscription";
import { BodyWrapper } from "../checkout.modal.common/_modal.body.wrapper";
import { Wrapper } from "../checkout.modal.common/_modal.wrapper";
import DocumentInfo from "../checkout.modal.common/document-info";
import Header from "../checkout.modal.common/header";
import PageSelection from "../checkout.modal.common/page-selection";
import PurchaseCertifiedCopy from "../checkout.modal.common/purchase-certified-copy";
import AddSupportDocs from "../checkout.modal/add-support-docs";
import ExportInfo from "./export-info";
import Footer from "./footer";
import StripePayment from "./stripe";

const showPaymentForm = (props) => {
  if (!props.ncourt.enabled) {
    return (
      <StripePayment
        cardInput={props.cardInput}
        price={pathOr("", ["info", "totalPrice"], props)}
      />
    );
  }

  if (props.submitting) {
    const orderIncomplete = () => {
      props.setOrderInterrupted(true);
      props.dispatch({ type: "reset-order-statuses" });
    };

    return <NCourtPayment props={props} orderIncomplete={orderIncomplete} />;
  }
};

const submitForm = (props) => (e) => {
  e.preventDefault();
  e.stopPropagation();

  if (!props.ncourt.enabled) {
    if (typeof props.submitForm === "function") {
      props.submitForm(e);
      props.footer.setIsDisabled();
    }
    return;
  }

  if (props.info.totalPrice > 0) {
    const toList = (obj) =>
      Object.keys(obj)
        .map((key) => `${key}=${obj[key]}`)
        .join(",");

    const windowFeatures = {
      resizable: "yes",
      scrollbars: "yes",
      status: "yes",
      width: 950,
      height: 950,
    };

    const windowUrl = props.ncourtUrl || "/static/loading/ncourt";

    window.open(windowUrl, "NCourtPayment", toList(windowFeatures));
  }

  props.setSubmitting(true);
  props.setOrderInterrupted(false);
};

const ExpressCheckoutWrapper = ({ noErrorBar, ...props }) => {
  const [forceDisable, setForceDisable] = useState(false);
  const isDisabled = forceDisable === true;

  return (
    <form disabled={props.form.disabled}>
      <Wrapper>
        <Header {...props.header} />
        <ExpiredSubscriptionMessage disableCheckout={setForceDisable} />
        <ErrorBar error={noErrorBar ? "" : props.checkout.error} />
        <BodyWrapper>{props.children(isDisabled)}</BodyWrapper>
      </Wrapper>
    </form>
  );
};

// -------------------------------------------------------------------- Standard
const Standard = (props) => (
  <ExpressCheckoutWrapper {...props}>
    {(isDisabled) => (
      <Fragment>
        <DocumentInfo {...props.info} />
        {!props.submitting && (
          <Fragment>
            <PageSelection {...props.pageSelection} />
            <AddSupportDocs {...props.addSupportDocs} />
            <PurchaseCertifiedCopy {...props.certifiedCopy} />
            {!props.ncourt.enabled && showPaymentForm(props)}
            <Footer
              {...props.footer}
              isSubmittingPayment={props.submitting}
              submit={submitForm(props)}
              disabled={isDisabled}
            />
          </Fragment>
        )}
        {props.submitting && showPaymentForm(props)}
      </Fragment>
    )}
  </ExpressCheckoutWrapper>
);

Standard.displayName = "Standard";

// ---------------------------------------------------------------------- Export

const Export = (props) => (
  <ExpressCheckoutWrapper {...props}>
    {(isDisabled) => (
      <Fragment>
        <ExportInfo {...props.info} />
        {showPaymentForm(props)}
        <Footer
          {...props.footer}
          submit={submitForm(props)}
          disabled={isDisabled}
        />
      </Fragment>
    )}
  </ExpressCheckoutWrapper>
);

Export.displayName = "Export";

// ---------------------------------------------------------------------- Layout

const layouts = {
  [ENTITY_TYPES.DOCUMENT]: Standard,
  [ENTITY_TYPES.EXPORT]: Export,
};

const Layout = (props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [orderInterrupted, setOrderInterrupted] = useState(false);
  const canCheckout = useSelector(selectors.user.userCanCheckout);
  const View = layouts[props.common.entityType];
  const clearNCourtError = thunkify(dispatch)(actions.ncourt.clearError());
  const ncourtUrl = useSelector(selectors.ncourt.getCheckoutUrl);

  useEffect(always(clearNCourtError), []);

  useEffect(() => {
    if (orderInterrupted) {
      setSubmitting(false);
    }
  }, [orderInterrupted]);

  const localProps = {
    dispatch,
    canCheckout,
    ncourtUrl,
    submitting,
    setSubmitting,
    setOrderInterrupted,
  };

  return <View {...props} {...localProps} />;
};

export default Layout;
