import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { A11yAnnouncement, TooltipTrigger } from "../../components";
import { useDateRangeMetaLabel } from "../../hooks";
import { selectors } from "../../store";
import { Submit } from "./_basic-search.button.submit";
import { Container } from "./_basic-search.container";
import { DateError } from "./_basic-search.date-error";
import { Form } from "./_basic-search.form";
import DateRangeSelect from "./date-range";
import { SearchInput } from "./search-input";
import { SelectSearchScope } from "./search-scope.select";
import { deptSelectControlStyle, landCorners, selectStyles } from "./style";

const style = {
  dateRangeMetaLabel: css`
    grid-area: label;
    text-align: right;
    padding: 8px 0;
    color: var(--color-neutral-500);
    font-size: 14px;
    font-weight: 400;
  `,
  resetButton: css`
    margin: 20px auto 0;
    padding: 10px;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    img {
      margin-right: 5px;
    }
  `,
  searchIcon: css`
    width: 26px;
    height: 26px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7414%)
      hue-rotate(180deg) brightness(115%) contrast(92%);
  `,
};

const DeptSelectControl = (props) => (
  <TooltipTrigger
    isFocused={props.isFocused}
    tooltip="Change department"
    css={deptSelectControlStyle}
    render={({ innerRef, ...triggerProps }) => (
      <div {...triggerProps} ref={innerRef}>
        <components.Control {...props} />
      </div>
    )}
  />
);

const BasicSearchView = ({
  form,
  departments,
  searchTerm,
  dateRange,
  searchOcrText,
}) => {
  const getImg = useSelector(selectors.assets.getBoundImg);
  const infoIcon = getImg("icon_info.svg");
  const exIcon = getImg("icon-XCircle.svg");
  const searchIcon = getImg("icon_search.svg");
  const noDateFor = (key) => isNil(prop(key, dateRange));

  const dateRangeDisabled =
    departments.noDocuments || noDateFor("rangeValue") || noDateFor("maxRange");

  const dateRangeMetaLabel = useDateRangeMetaLabel();

  return (
    <Container>
      <A11yAnnouncement>Navigated to quick search page</A11yAnnouncement>
      <Form onSubmit={form.handleSubmit}>
        <div className="basic-search">
          <span
            css={style.dateRangeMetaLabel}
            dangerouslySetInnerHTML={{ __html: dateRangeMetaLabel || "&nbsp;" }}
          ></span>
          <Select
            options={departments.options}
            onChange={departments.select}
            value={departments.value}
            styles={selectStyles}
            isSearchable={false}
            components={{ Control: DeptSelectControl }}
            id="department-select"
          />
          <SearchInput
            searchTerm={searchTerm}
            disabled={departments.noDocuments}
          />

          <DateRangeSelect {...dateRange} disabled={dateRangeDisabled} />
          <Submit
            disabled={form.isDisabled}
            type="submit"
            aria-label="search button"
            data-testid="searchSubmitButton"
            data-tourid="searchSubmitButton"
          >
            <img
              css={style.searchIcon}
              id="basicsearch-icon"
              src={searchIcon}
              alt="search icon"
            />
          </Submit>
        </div>

        {departments.noDocuments && (
          <DateError aria-live="polite">
            No documents to search in department.
          </DateError>
        )}

        {departments?.tooltipText && (
          <div css={landCorners}>
            <img src={infoIcon} alt="Info" />
            <span>{departments.tooltipText}</span>
          </div>
        )}

        {searchOcrText.searchScope.available.length === 2 && (
          <SelectSearchScope searchOcrText={searchOcrText} />
        )}

        <button
          css={style.resetButton}
          onClick={form.reset}
          className="Control"
        >
          <img src={exIcon} alt="circle with an x inside" />
          Reset Search
        </button>
      </Form>
    </Container>
  );
};

export default BasicSearchView;
