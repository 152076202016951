import { types as syncTypes } from "@kofile/ko-search-action-types";
import { stringify } from "query-string";
import values from "ramda/src/values";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { formatAdvancedSearch, formatBasicSearch } from "../../utils";

export const makeSearchText = ({ name: deptName, partyFields } = {}, query) => {
  if (query.searchType === "advancedSearch") {
    return formatAdvancedSearch(
      Object.assign({}, query, { deptName }),
      partyFields
    );
  }

  return formatBasicSearch(Object.assign({}, query, { deptName }));
};

export const updateOnSearch = (actions$, store) =>
  actions$.pipe(
    ofType(syncTypes.FETCH_DOCUMENTS_4),
    map(({ payload }) => {
      const { configuration } = store.value;
      const { departments: deptHash } = configuration;
      const searchURL = `/results?${stringify(payload.query)}`;

      const department = values(deptHash).find(
        ({ code }) => code === payload.query.department
      );

      const searchText = makeSearchText(department, payload.query);

      return {
        type: syncTypes.ADD_TO_SEARCH_HISTORY_0,
        payload: {
          url: searchURL,
          searchText,
          searchQuery: payload.query,
          departmentCode: department.code,
        },
      };
    })
  );

export default [updateOnSearch];
