import path from "ramda/src/path";
import AllCheckbox from "./all-checkbox";
import Actions from "./table.dropdown-actions";
import RowIcons from "./table.icons";

export const CustomButton = () => <div className="list-view__selector" />;

export const findParentOfType = (type) => (child) => {
  if (child.tagName.toLowerCase() === type.toLowerCase()) {
    return child;
  }

  let node = child;

  while (node.parentNode) {
    node = node.parentNode;

    if (node.tagName.toLowerCase() === type.toLowerCase()) {
      return node;
    }
  }

  return null;
};

export const findParentTD = findParentOfType("td");
export const getIDFromProps = path(["rowData", "id"]);

export const makePrependColumns = (config) => [
  {
    name: "docSelect",
    key: "docSelect",
    size: 30,
    locked: true,
    headerOptions: {
      on: { click: () => {} },
      valueTransform: () => <AllCheckbox id="table-checkbox-select-all" />,
    },
    cellOptions: {
      on: { click: selectDocOnClick(config) },
    },
  },
  {
    name: "rowActions",
    key: "rowActions",
    size: 30,
    locked: true,
    headerOptions: {
      on: {},
      className: () => ({ isDropdown: true }),
      valueTransform: () => " ",
    },
    cellOptions: {
      on: { click: () => {} },
      className: () => ({ isDropdown: true, hasIcon: true }),
      valueTransform: (doc) => <Actions doc={doc} config={config} />,
    },
  },
  {
    name: "rowIcon",
    key: "rowIcon",
    size: 30,
    locked: true,
    headerOptions: {
      on: {},
      className: () => {},
      valueTransform: () => " ",
    },
    cellOptions: {
      className: () => ({ isDropdown: true, hasIcon: true }),
      valueTransform: (input) => <RowIcons {...input} />,
    },
  },
];

export const shapeConfig = (v = {}, i) => ({
  ...v,
  order: i,
  size: v.size || 150,
});

const selectDocOnClick = (config) => (event, element, props) => {
  const { onRowSelectionChanged } = config;
  const td = findParentTD(event.target);
  const id = getIDFromProps(props);

  if (td) {
    const checkbox = td.querySelector("input");

    return onRowSelectionChanged({
      id,
      document: config.documentsHash[id],
      isSelected: !checkbox.checked,
    });
  }

  return {};
};

export const makeAppendColumns = (columns) => [...columns];
