import styled from "@emotion/styled";

export const CardContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  text-align: center;

  &.card-left {
    grid-area: card1;
    min-height: 240px;
  }

  &.card-right {
    grid-area: card2;
  }
`;
