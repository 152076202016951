import { ClassNames } from "@emotion/react";
import PropTypes from "prop-types";
import complement from "ramda/src/complement";
import either from "ramda/src/either";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import { useEffect, useState } from "react";
import { SavedCard } from "../";
import { chooseViewFor } from "../../utils/choose-view-for";
import CardArea from "../CardArea";

const isNotPresent = complement(either(isNil, isEmpty));

const showSaved = ({ card, toggled }) =>
  toggled === false && isNotPresent(card);

const showEntry = complement(showSaved);

export const Wrapper = ({ className, children, label }) => (
  <ClassNames>
    {({ cx }) => (
      <div className={cx("card-entry__wrapper", className)}>
        <div className="card-entry__label">{label}</div>
        {children}
      </div>
    )}
  </ClassNames>
);

const noop = () => {};

const CardEntry = ({ cardAreaProps, onUserInput = noop }) => {
  const [toggledToEntry, setToggledToEntry] = useState(true);
  const { cardInfo = {} } = cardAreaProps;
  const criteria = { card: cardInfo, toggled: toggledToEntry };

  const editCard = () => {
    setToggledToEntry(true);
    onUserInput();
  };

  const showSavedWhenCardPresent = () => {
    if (isNotPresent(cardInfo)) {
      setToggledToEntry(false);
    }
  };

  useEffect(showSavedWhenCardPresent, [cardInfo]);

  return chooseViewFor(criteria)
    .with({ showSaved, showEntry })
    .when({
      showSaved: () => (
        <Wrapper>
          <SavedCard cardInfo={cardInfo} handleEdit={editCard} />
        </Wrapper>
      ),
      showEntry: () => (
        <Wrapper>
          <CardArea {...cardAreaProps} onUserInput={onUserInput} />
        </Wrapper>
      ),
      default: () => null,
    });
};

CardEntry.displayName = "CardEntry";

CardEntry.propTypes = {
  cardAreaProps: PropTypes.shape({
    error: PropTypes.string,
    inProgress: PropTypes.bool,
    disabled: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default CardEntry;
