export default {
  create: (payload) => ({
    type: "create-a-fraud-alert",
    payload,
  }),

  delete: (payload) => ({
    type: "delete-fraud-alert",
    payload,
  }),

  fetchAlerts: () => ({
    type: "fetch-fraud-alerts",
  }),

  fetchMatchRecords: () => ({
    type: "fetch-fraud-alert-match-records",
  }),

  fetchCriteria: (payload) => ({
    type: "fetch-fraud-alert-criteria-for-document",
    payload,
  }),
};
