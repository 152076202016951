import classnames from "classnames";
import PropTypes from "prop-types";
import is from "ramda/src/is";
import map from "ramda/src/map";
import { connect } from "react-redux";
import { compose, withHandlers, withProps, withState } from "recompose";
import { mapIndexed } from "../../utils";
import { Dropdown, DropdownElement } from "./components";

const enhance = compose(
  withState("dropdownOpen", "setDropdownOpen", false),
  withHandlers({
    closeDropdown: (props) => () => props.setDropdownOpen(false),
    openDropdown: (props) => () => props.setDropdownOpen(true),
  }),
  withProps((props) => {
    const withClose = (option) => ({
      ...option,
      action: (params) => {
        option.action(params);
        props.closeDropdown();
      },
    });
    const { options = [] } = props;
    const mappedOptions = map(withClose, options);

    return {
      dropdownElements: mapIndexed(DropdownElement, mappedOptions),
    };
  })
);

export const SelectAction = (props) => {
  const {
    label = "",
    dropdownOpen,
    openDropdown,
    closeDropdown,
    dropdownElements,
    customButton,
    baseRight = false,
    transparent = false,
    className = "",
    dataTestId = "",
    directAction,
  } = props;
  const buttonStyling =
    customButton || transparent
      ? "select-action__emptyButton"
      : "select-action__dropdown-button";

  const buttonClass = classnames(buttonStyling, {
    "select-action__dropdown-open": transparent && dropdownOpen,
  });

  const iconClass = classnames("select-action__icon", {
    "select-action__transparent": transparent,
  });

  const labelClass = classnames("select-action__label", {
    "select-action__transparent": transparent,
    "select-action__dropdown-open": dropdownOpen,
  });

  const arrowToRender =
    transparent && dropdownOpen
      ? props.assets.img["icon_dropdown_arrow_white.svg"]
      : props.assets.img["icon_dropdown_arrow.svg"];

  const buttonToRender = is(Function, customButton)
    ? customButton(props)
    : customButton;

  const wrapperClass = classnames("select-action", className);

  const openArrowDropdown = (e) => {
    e.stopPropagation();
    openDropdown();
  };

  return (
    <div className={wrapperClass} data-tourid="documentActions">
      <button
        className={buttonClass}
        onClick={directAction}
        aria-label="document actions"
        data-testid={dataTestId}
      >
        {buttonToRender || (
          <div className="select-action__flex-wrap">
            <div className={labelClass}>{label}</div>
            <div
              role="button"
              tabIndex="0"
              className={iconClass}
              onClick={openArrowDropdown}
              onKeyPress={openArrowDropdown}
            >
              <img
                alt="arrow"
                src={arrowToRender}
                className="select-action__arrow"
              />
            </div>
          </div>
        )}
      </button>
      <Dropdown
        dropdownElements={dropdownElements}
        dropdownOpen={dropdownOpen}
        closeDropdown={closeDropdown}
        baseRight={baseRight}
      />
    </div>
  );
};

SelectAction.displayName = "SelectAction";
SelectAction.propTypes = {
  dropdownElements: PropTypes.node.isRequired,
  customButton: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  label: PropTypes.string,
  dropdownOpen: PropTypes.bool.isRequired,
  openDropdown: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  baseRight: PropTypes.bool,
  transparent: PropTypes.bool,
  assets: PropTypes.shape({
    img: PropTypes.object,
  }).isRequired,
  className: PropTypes.string,
};

const mapStateToProps = ({ assets }) => ({ assets });

export const EnhancedSelectAction = enhance(
  connect(mapStateToProps)(SelectAction)
);

export default EnhancedSelectAction;
