import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import WhiteButton from "../doc-detail.common/_button.white";
import styles from "./style";
import { navigateTo } from "./use-navigate-docs-by-results";

export const addArrows = (isNext, str) => (isNext ? `${str} >` : `< ${str}`);

const Button = styled(WhiteButton)`
  padding-left: 13px;
  ${(props) => (!props.isNext ? "direction: rtl;" : "")}

  img {
    height: 13px;
    ${(props) =>
      props.isNext
        ? "margin-left: 5px;"
        : "transform: rotate(180deg); transform-origin: 50% 50%;"}
  }

  :disabled {
    img {
      filter: invert(100%) saturate(0%);
    }
  }
`;

const NavigateButton = ({ to, style, str, navigate, disabled }) => {
  const isNext = to === navigateTo.Next;
  const arrow = useSelector(selectors.assets.getImg("icon_arrow-new.svg"));

  return (
    <Button
      isNext={isNext}
      css={[styles.button(isNext), style]}
      disabled={disabled}
      onClick={navigate}
    >
      {str}
      <img src={arrow} alt="next document icon" />
    </Button>
  );
};

export default NavigateButton;
