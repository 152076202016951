import { css } from "@emotion/react";

const style = css`
  background: transparent;
  border: 0px;
  color: #006dd5;
  cursor: pointer;
  padding: 8px 32px 9px;
  font-weight: 500;
  align-self: flex-end;
  margin-left: auto;
  grid-area: toggle-details;
`;

const ToggleDetails = ({ show, toggleDetails }) => {
  const text = show ? "Hide Panel >>" : "Show Panel <<";

  return (
    <button css={style} onClick={toggleDetails}>
      {text}
    </button>
  );
};

export default ToggleDetails;
