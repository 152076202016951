import { stringify } from "query-string";
import { resetEmailContainer } from "../Payment/_settings-profile";

export const EmailVerification = ({ email, isVerified }) => {
  if (isVerified) return null;

  const query = stringify({ email });
  const resetEmailLink = `/cb/send-registration-verification-email?${query}`;

  return (
    <div css={resetEmailContainer}>
      <div>Email has not been verified</div>
      <a
        hx-push-url="false"
        hx-swap="none"
        hx-boost="true"
        href={resetEmailLink}
        className="cta"
      >
        Resend Verification Email
        <loading-indicator style={{ width: "20px" }} />
      </a>
    </div>
  );
};
