import { useSelector } from "react-redux";
import { Link } from "../../../../components";
import { selectors } from "../../../../store";
import { CardContainer } from "./_card.container";
import { CardsContainer } from "./_cards.container";
import { Container } from "./_container";
import { EmailContainer } from "./_email.container";
import { EmailContent } from "./_email.content";
import { EmailTitle } from "./_email.title";
import { Header } from "./_header";
import { Image } from "./_image.container";
import { LegalContent } from "./_legal-content";
import { Or } from "./_or";
import { Title } from "./_title";
import Card from "./card";
import Description from "./description";
import styles from "./styles";

const emailContent =
  "These filings may be actions such as tax liens or releases. They may also be fraudulent attempts to affect property ownership.";

const LandingPage = () => {
  const { configuration } = selectors;
  const legalCopy = useSelector(configuration.getLandingLegalCopy);
  const hideNameOption = useSelector(configuration.hideFraudAlertNameOption);
  const legalContent = legalCopy.map((data, i) => <p key={i}>{data}</p>);
  const getImg = (name) => useSelector(selectors.assets.getImg(name));
  const fraudLandingImg = getImg("iphone_mail_notification.png");
  const fraudEmailIcon = getImg("icon_fraud_landing_mail.svg");

  const defaultTitle = "There are two ways to set up a free property alert:";
  const singleTitle = "Set up a free property alert:";

  return (
    <Container>
      <Title>GovOS Property Alerts</Title>
      <Description />
      <CardsContainer hideNameOption={hideNameOption}>
        <Header>
          <h2>{hideNameOption ? singleTitle : defaultTitle}</h2>
          <div css={styles.headerLinkContainer}>
            Already created an alert?
            <Link
              to="/account/property-alert"
              workspaceID="property-alert"
              css={styles.headerLink}
            >
              View Dashboard
            </Link>
            <br />
            For further details, visit our
            <a
              href="https://kofilehelp.zendesk.com/hc/en-us/articles/360059921113-Property-Alert-FAQ"
              css={styles.headerAnchor}
              target="_blank"
              rel="noopener noreferrer"
            >
              Property Alert FAQ
            </a>
          </div>
        </Header>
        <Image>
          <img src={fraudLandingImg} alt="property alert email notification" />
        </Image>
        {hideNameOption === false && (
          <>
            <CardContainer className="card-left">
              <Card type="byName" />
            </CardContainer>
            <Or>or</Or>
          </>
        )}
        <CardContainer className="card-right">
          <Card type="byDoc" />
        </CardContainer>
      </CardsContainer>
      <EmailContainer>
        <img src={fraudEmailIcon} alt="email icon" />
        <EmailTitle>Receive an Email when the Document is Filed</EmailTitle>
        <EmailContent>{emailContent}</EmailContent>
      </EmailContainer>
      <LegalContent>{legalContent}</LegalContent>
    </Container>
  );
};

export default LandingPage;
