import PropTypes from "prop-types";
import { parse, stringify } from "query-string";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import equals from "ramda/src/equals";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import keys from "ramda/src/keys";
import map from "ramda/src/map";
import merge from "ramda/src/merge";
import pick from "ramda/src/pick";
import pickBy from "ramda/src/pickBy";
import propOr from "ramda/src/propOr";
import Tooltip from "rc-tooltip";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Filter } from "../";
import { Popup, TokenizedNestedSelect } from "../../../../../components";
import {
  withWorkspaceId,
  withModalRerenderBlock,
  withModal,
} from "../../../../../hoc";
import { actions, selectors } from "../../../../../store";
import { resultsTour } from "../../../../../tours";
import { splitWithDefault } from "../../../../../utils";
import DecadeSelect from "./DecadeSelect";
import FilterSet from "./FilterSet";
import { handleQueryUpdate } from "./utils";

export class SideFilters extends React.Component {
  constructor(props) {
    super(props);
    this.runSearchResultsTour = runSearchResultsTour.bind(this);
  }

  render() {
    const {
      meta,
      query,
      onFilterUpdate,
      filterConfig = [],
      partyFields = [],
    } = this.props;

    if (filterConfig.length === 0) {
      return null;
    }

    const {
      _recordedYears = [],
      _instrumentYears = [],
      _docTypes = [],
    } = convertToArrays(query);

    const selections = {
      recordedYears: _recordedYears,
      instrumentYears: _instrumentYears,
      docTypes: _docTypes,
    };

    const { partyStats = {}, docTypes } = meta;
    const { department = "" } = query;

    const partyFilter = ({ id, label }) => {
      const stats = partyStats[id] || [];
      const partyKey = `_${id}`;
      const selectionsString = propOr("", partyKey, query);
      const selections = splitWithDefault(selectionsString);

      if (isEmpty(stats)) {
        return null;
      }

      return (
        <Filter key={id} title={label} isOpen>
          <FilterSet
            id={id}
            options={stats}
            notifyParent={onFilterUpdate(partyKey)}
            selections={selections}
            isOnlySelection={isOnlyFilter(partyKey, query)}
          />
        </Filter>
      );
    };

    const docTypeFilter = ({ label }) => {
      if (isEmpty(docTypes)) {
        return null;
      }

      const popupProps = {
        onClick: () => this.runSearchResultsTour(this.props),
        headerText: "Refine Search Results",
        bodyText:
          "Toggle the checkboxes to apply filters to the search results.",
        buttonText: "Take the Tour",
        onClear: this.props.setResultsTourViewed,
        isHidden:
          this.props.tour.resultsTourViewed || this.props.tour.isRunning,
      };

      return (
        <Tooltip
          visible={!popupProps.isHidden}
          placement="top"
          overlay={<Popup {...popupProps} />}
        >
          <Filter title={label} isOpen key="docTypes">
            <TokenizedNestedSelect
              className="search-results-tns"
              hidePills
              keepOpen
              width={250}
              onChange={onFilterUpdate("_docTypes")}
              selectedItems={_docTypes}
              docTypes={docTypes}
              department={department}
              rerenderOnCompute
            />
          </Filter>
        </Tooltip>
      );
    };

    const yearsFilter = ({ id, label }) => {
      if (isEmpty(meta[id])) {
        return null;
      }

      return (
        <Filter title={label} isOpen key={id}>
          <DecadeSelect
            id={id}
            structure={meta[id]}
            selections={selections[id]}
            notifyParent={onFilterUpdate(`_${id}`)}
            isOnlySelection={isOnlyFilter(`_${id}`, query)}
          />
        </Filter>
      );
    };

    const filters = {
      docTypes: docTypeFilter,
      recordedYears: yearsFilter,
      instrumentYears: yearsFilter,
      ...makePartyFilters(filterConfig, partyFields, partyFilter),
    };

    const renderFilter = ({ key, label }) => {
      const Component = filters[key];

      if (!Component) {
        return null;
      }

      return <Component key={key} id={key} label={label} />;
    };

    return (
      <div>
        <aside className="side-filters">
          <div className="tour-filter" data-tourid="filters">
            {filterConfig.map(renderFilter)}
          </div>
        </aside>
      </div>
    );
  }
}

export const mapStateToProps = (state, { department }) => {
  const partyFields = selectors.configuration.getDeptParties(state);
  const statistics = selectors.documents.getActiveWorkspaceStatistics(state);

  const partyStats = partyFields.reduce((acc, partyName) => {
    const statsArray = statistics[partyName] || [];

    return statsArray ? merge(acc, { [partyName]: statsArray }) : acc;
  }, {});

  return {
    tour: {
      resultsTourViewed: selectors.user.getResultsTourViewed(state),
      isRunning: selectors.tour.getRun(state),
    },
    docTypeMappings: selectors.configuration.getCurrentDeptDocTypeMappings(
      state,
      department
    ),
    meta: {
      recordedYears: selectors.documents.getRecordedYears(state),
      instrumentYears: selectors.documents.getInstrumentYears(state),
      partyStats,
      docTypes: statistics.docTypes,
    },
    filterConfig: selectors.configuration.getSideFilters(state, department),
    partyFields,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  runTour: (props) => dispatch(actions.tour.run(props)),
  setResultsTourViewed: () =>
    dispatch(actions.user.setTourViewed({ tourViewed: "searchResults" })),
  fetch: (key, query) => (value) => {
    const { workspaceID, history } = ownProps;

    const newValue = handleQueryUpdate({
      value,
      key,
      query,
    });

    const newQuery = merge(query, newValue);
    const newSearch = stringify(newQuery);

    return history.push({
      pathname: "/results",
      search: newSearch,
      state: {
        workspaceID,
      },
    });
  },
});

SideFilters.displayName = "SideFilters";

SideFilters.propTypes = {
  meta: PropTypes.shape({
    yearRanges: PropTypes.arrayOf(PropTypes.object),
    docTypes: PropTypes.arrayOf(PropTypes.object),
  }),
  query: PropTypes.object.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  filterOrder: PropTypes.array,
};

SideFilters.defaultProps = {
  data: {},
  query: {},
};

const enhance = compose(
  withRouter,
  withWorkspaceId,
  withModal,
  withModalRerenderBlock,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SideFilters);

// --------------------------------------------------------------------- private

function runSearchResultsTour() {
  const { location, runTour, tour, setResultsTourViewed } = this.props;

  const { viewType = "list" } = parse(location);
  const isListView = viewType === "list";

  if (isListView && !tour.resultsTourViewed) {
    runTour({ steps: resultsTour, onEnd: setResultsTourViewed });
  }
}

function convertToArrays(obj) {
  return compose(
    map(splitWithDefault),
    pick(["_recordedYears", "_instrumentYears", "_docTypes"])
  )(obj);
}

function isOnlyFilter(filterKey, query) {
  const underscore = (val, key) => equals("_", head(key));

  return compose(equals([filterKey]), keys, pickBy(underscore))(query);
}

function makePartyFilters(filterConfig, partyFields, partyFilterComponent) {
  return filterConfig.reduce((acc, { key }) => {
    return contains(key, partyFields)
      ? merge(acc, { [key]: partyFilterComponent })
      : acc;
  }, {});
}
