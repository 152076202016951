import { useSelector } from "react-redux";
import { selectors } from "../store";
import { useAPI } from "./use-api";

export function useSuggestions(id) {
  const deptCode = useSelector(selectors.workspaces.getSelectedDepartment);
  const partyFields = useSelector(selectors.configuration.getDeptParties);

  const {
    data: items,
    initiate: fetchItems,
    clear: clearItems,
    error,
  } = useAPI({
    initialState: [],
    send: "fetch-autocomplete-suggestions",
    listenFor: {
      success: "autocomplete-suggestions",
      failure: "error-fetching-autocomplete-suggestions",
    },
  });

  return {
    items: (items || []).map((i) => i.value),
    error,
    fetchItems: ({ inputValue }) => {
      fetchItems({
        prefix: inputValue,
        departmentCode: deptCode,
        fields: [id.indexOf("party") === 0 ? partyFields.join(",") : id],
        size: 20,
      });
    },
    clearItems,
    cancelFetchItems: () => {},
  };
}
