import { Helmet } from "react-helmet";
import { A11yAnnouncement } from "../../../../components";

const heading = {
  paddingBottom: "15px",
  fontSize: "24px",
};

const wrapper = {
  padding: "30px 0",
  textAlign: "center",
};

export const PaymentError = () => (
  <div>
    <Helmet>
      <title>Payment Error</title>
    </Helmet>
    <A11yAnnouncement>{`An error occurred processing payment, please try again`}</A11yAnnouncement>
    <div style={wrapper} className="payment-error_wrapper">
      <h1 style={heading}>Payment Error</h1>
      <p style={{ padding: "10px 0" }}>
        There was an error processing the payment, please try again.
      </p>
    </div>
  </div>
);

PaymentError.displayName = "PaymentError";

export default PaymentError;
