import { css } from "@emotion/react";

export const style = {
  buttonRow: css`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    & a {
      text-decoration: none;
    }

    & span {
      color: #041d44a6;
    }
  `,
};

const selectedStyle = `
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(33, 33, 33, 0.5);
`;

export const pageButtonStyle = (selected) => css`
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2.5px;
  color: rgba(0, 109, 213, 1);
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  text-align: center;
  width: 25px;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(33, 33, 33, 0.5);
  }

  &:focus {
    box-shadow: 0.1px 0.1px 7px rgba(0, 109, 213, 1);
  }

  ${selected && selectedStyle}
`;

const disabledStyle = `
  opacity: 0.5;

  &:hover {
    background: rgba(0, 109, 213, 1);
    color: rgba(255, 255, 255, 1);
  }
`;

export const pageAdvanceStyle = (disabled) => css`
  align-items: center;
  background: rgba(0, 109, 213, 1);
  border: 1px solid rgba(0, 109, 213, 1);
  border-radius: 2.5px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  margin: 0.25rem;
  transition: all 0.125s ease-in;
  width: 25px;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.7);
    color: rgba(0, 109, 213, 1);
    transition: all 0.125s ease-out;
  }

  &:focus {
    box-shadow: 0.1px 0.1px 7px rgba(0, 109, 213, 1);
  }

  ${disabled && disabledStyle}
`;
