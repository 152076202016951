import { css } from "@emotion/react";
import { push } from "connected-react-router";
import all from "ramda/src/all";
import equals from "ramda/src/equals";
import propOr from "ramda/src/propOr";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  A11yAnnouncement,
  ErrorBar,
  LoadingSpinner,
} from "../../../components";
import { RadioSet } from "../../../components/Fields";
import { NotificationInfo } from "../../../components/FraudAlert/notification-info";
import { useLogin } from "../../../hooks";
import { ConfigureNames } from "./configure.names";
import { ConfigureText } from "./configure.text";
import { useFraudAlertSetup } from "./use-fraud-alert-setup";
import { useMergeCriteriaRadio } from "./use-merge-criteria-radio";

const styles = {
  fraudAlertWrapper: css`
    margin: 0 auto;
    max-width: 450px;
    background-color: var(--color-neutral-lightest);
    border: 1px #dadde3 solid;
    border-radius: 15px;
    color: #041d44;
    padding: 30px;
  `,
  submitButton: css`
    background-color: #006dd5;
    border-radius: 5px;
    color: var(--color-neutral-lightest);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 14px;
    margin: 0 auto;
  `,
  title: css`
    font-size: 27px;
    margin-bottom: 25px;
  `,
  error: css`
    color: #b71c1c;
    font-size: 12px;
    padding-bottom: 20px;
  `,
  noFields: css`
    line-height: 1.5;
  `,
  homeLink: css`
    background-color: #006dd5;
    border-radius: 5px;
    color: var(--color-neutral-lightest);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 14px;
    margin-top: 20px;
    display: inline-block;
  `,
  radios: css`
    padding-bottom: 20px;
  `,
};

const ConfigureFullScreen = ({ errorBar }) => {
  const dispatch = useDispatch();
  const login = useLogin();
  const { inputProps, radioOptions } = useMergeCriteriaRadio(false);

  const {
    inputChange,
    terms,
    submission,
    generalError,
    submit,
    fields,
    fieldsConfig,
    formData,
  } = useFraudAlertSetup();

  if (!login.loggedIn) return login.do();
  if (terms.show) return terms.loading ? <LoadingSpinner /> : terms.init();
  if (submission.isLoading) return <LoadingSpinner />;
  if (submission.hasLoaded) dispatch(push("/property-alert/complete"));
  const multipleFieldsConfigured = Object.keys(fieldsConfig).length > 1;

  const count = Object.values(formData).reduce((total, field) => {
    const data = field.data;
    return total + (Array.isArray(data) ? data.length : data ? 1 : 0);
  }, 0);

  const fieldComponents = fields.map((field, i) => {
    const config = propOr({}, field, fieldsConfig);
    const fieldRaw = propOr({}, field, formData);
    const fieldType = propOr("default", "fieldType", config);
    const id = `${fieldType}-${field}-${i}`;

    const commonProps = {
      key: id,
      value: fieldRaw.data,
      error: fieldRaw.error,
      inputChange: (value) => inputChange(field, value),
      ...config,
    };

    switch (fieldType) {
      case "name":
        return (
          <ConfigureNames
            id="partyNames"
            {...commonProps}
            hideMergeParties={multipleFieldsConfigured}
          />
        );
      case "text":
        return <ConfigureText id={id} {...commonProps} />;
      default:
        return null;
    }
  });

  const noFieldsPresent = all(equals(null), fieldComponents);

  return (
    <Fragment>
      <A11yAnnouncement>
        Navigated to configure property alert page
      </A11yAnnouncement>
      <ErrorBar {...errorBar} />
      <form
        css={styles.fraudAlertWrapper}
        onSubmit={submit}
        id="select-name-form"
      >
        <h1 css={styles.title}>Configure your Search Criteria</h1>

        {fieldComponents}

        {count >= 2 && multipleFieldsConfigured && (
          <RadioSet
            css={styles.radios}
            inputProps={inputProps}
            radioOptions={radioOptions}
          />
        )}

        {generalError.length === 0 ? null : (
          <p css={styles.error}>{generalError}</p>
        )}
        {noFieldsPresent && (
          <>
            <p css={styles.noFields}>
              You&apos;ll need to create an alert from a document. Search for a
              document and once you’ve found the right one, select Property
              Alert and select your notification criteria. If this doesn&apos;t
              seem correct, please contact support.
            </p>
            <Link to="/" css={styles.homeLink}>
              Find a Document
            </Link>
          </>
        )}
        <NotificationInfo />
        {!noFieldsPresent && (
          <button
            id="create-alert-button"
            type="submit"
            form="select-name-form"
            value="Create Property Alert"
            css={styles.submitButton}
          >
            Create Property Alert
          </button>
        )}
      </form>
    </Fragment>
  );
};

export default ConfigureFullScreen;
