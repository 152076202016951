import classnames from "classnames";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Link as DomLink } from "react-router-dom";
import { selectors } from "../../store";
import { parseUrl } from "../../utils";

const Link = (props) => {
  const {
    to,
    children,
    className,
    exact = false,
    workspaceID: propWorkspaceID,
    openNewWorkspace = false,
    dataTestId,
    dataTourId,
    anchorClass,
    role,
    "aria-label": ariaLabel,
    disabled = false,
    title = "",
  } = props;

  const { workspaces } = selectors;
  const activeWorkspaceID = useSelector(workspaces.getActiveWorkspaceID);
  const workspaceID = propWorkspaceID || activeWorkspaceID;

  const resolveRoute = (to) => {
    const { pathname, search } = parseUrl(to);
    const newRoute = { pathname, search };

    newRoute.state = !openNewWorkspace ? { workspaceID } : {};

    return newRoute;
  };

  const linkMaker = ({ match }) => {
    const combinedClassName = classnames(className, {
      [`${className}--active`]: className && match,
      [`${className}--disabled`]: disabled,
    });

    const linkClass = anchorClass || "";
    const resolvedTo = resolveRoute(to);

    return (
      <div
        className={combinedClassName}
        data-testid={dataTestId}
        data-tourid={dataTourId}
        title={title}
      >
        <DomLink
          className={linkClass}
          to={resolvedTo}
          role={role}
          aria-label={ariaLabel}
        >
          {children}
        </DomLink>
      </div>
    );
  };

  return (
    <Route path={to} exact={exact}>
      {linkMaker}
    </Route>
  );
};

export default Link;
