import { Link } from "../../../../components";
import { navLink, navLinkMargin } from "./_style.nav-link";

const LoginButton = ({ signInLink, loggedIn, loading }) => {
  if (!loggedIn) {
    return (
      <Link
        css={navLink}
        anchorClass="a11y-menu"
        to={signInLink}
        dataTestId="signInLink"
        aria-label="Sign In"
      >
        Sign In
      </Link>
    );
  }

  return (
    <a css={navLinkMargin} href="/signout" aria-label="Sign Out">
      {loading ? "Loading..." : "Sign Out"}
    </a>
  );
};

export default LoginButton;
