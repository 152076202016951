import anyPass from "ramda/src/anyPass";
import equals from "ramda/src/equals";

export { Status } from "../../constants";

export const Fit = {
  Best: "best",
  Width: "width",
  Height: "height",
};

export const Types = {
  Default: "default",
  Transcription: "transcription",
  Translation: "translation",
  Attachment: "attachments",
};

export const isTranscription = equals(Types.Transcription);
export const isTranslation = equals(Types.Translation);
export const isDefault = equals(Types.Default);
export const isAttachment = equals(Types.Attachment);

export const valid = anyPass([
  isTranscription,
  isTranslation,
  isDefault,
  isAttachment,
]);
