import { isUniversal } from "@kofile/ko-search-action-types";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { selectors } from "../../store";

const includeList = [
  "add-purchasables-to-a-shopping-cart",
  "accept-fraud-alert-terms-and-conditions",
  "can-user-purchase-subscriptions",
  "cancel-subscription",
  "change-password",
  "change-subscription",
  "clear-shopping-cart",
  "create-a-fraud-alert",
  "create-subscription",
  "delete-fraud-alert",
  "ensure-a-stripe-customer-exists",
  "fetch-a-document",
  "fetch-a-marginal-reference",
  "fetch-available-subscription-plans",
  "fetch-download-url",
  "fetch-fraud-alert-criteria-for-document",
  "fetch-fraud-alert-match-records",
  "fetch-fraud-alert-matches",
  "fetch-fraud-alerts",
  "fetch-order",
  "fetch-shopping-cart-for-a-user",
  "fetch-subscriptions-for-a-user",
  "purchase",
  "prepare-ncourt-checkout",
  "prepare-ncourt-express-checkout",
  "prepare-ncourt-express-checkout-for-a-search-export-results-pdf",
  "remove-purchasables-from-a-shopping-cart",
  "fetch-department-dates",
  "fetch-user-settings",
];

const isIncluded = (action) =>
  includeList.includes(action.type) || isUniversal(action);

const mkMiddleware = (socket) => {
  const actions$ = new Subject().pipe(filter(isIncluded));

  actions$.subscribe(socket.send);

  const remoteAction = (store) => (next) => (action) => {
    const authToken = selectors.user.getAuthToken(store.getState());
    const ip = selectors.user.getIpAddress(store.getState());
    const upgradedAction = Object.assign({}, action, { authToken, ip });
    const result = next(upgradedAction);

    actions$.next(upgradedAction);

    return result;
  };

  return remoteAction;
};

export default mkMiddleware;
