import PropTypes from "prop-types";

const InputRange = (props) => {
  const { inputProps } = props;
  const { onChange, value } = inputProps;

  const parseValue = (formattedValue) => {
    const split = formattedValue.split("-");
    const start = split[0] || "";
    const end = split[1] || "";

    return { start, end };
  };

  const formatValue = (start, end) => {
    const endValue = end ? `-${end}` : "";
    const formatted = `${start}${endValue}`;

    return formatted;
  };

  const onChangeStart = (event) => {
    const { end } = parseValue(value);
    const formattedValue = formatValue(event.target.value, end);

    return onChange({ target: { value: formattedValue } });
  };

  const onChangeEnd = (event) => {
    const { start } = parseValue(value);
    const formattedValue = formatValue(start, event.target.value);

    return onChange({ target: { value: formattedValue } });
  };

  const { start, end } = parseValue(value);
  const containsComma = start.includes(",");

  return (
    <div className="form-field__row">
      <div className="form-field__half-wrap">
        <input
          className="form-field__input"
          {...inputProps}
          onChange={onChangeStart}
          value={start}
        />
      </div>
      <div className="form-field__middle-wrap">
        <span className="form-field__to">TO</span>
      </div>
      <div className="form-field__half-wrap">
        <input
          className="form-field__input"
          {...inputProps}
          onChange={onChangeEnd}
          value={end}
          disabled={containsComma}
        />
      </div>
    </div>
  );
};

InputRange.displayName = "InputRange";

InputRange.propTypes = {
  inputProps: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
  }),
};

export default InputRange;
