import { types as syncTypes } from "@kofile/ko-search-action-types";
import types from "../types/suggestions";

export default {
  fetch: (payload) => ({ type: syncTypes.FETCH_SUGGESTIONS_1, payload }),
  cancelFetch: (payload) => ({
    type: syncTypes.FETCH_SUGGESTIONS_CANCELLED_0,
    payload,
  }),
  clear: (payload) => ({ type: types.CLEAR_SUGGESTIONS_SEARCH, payload }),
};
