import { ModalShell } from "../../components";
import CheckoutWindow from "./index";

const ModalChild = (props) =>
  Object.assign(
    () => (
      <ModalShell close={props.close}>
        <CheckoutWindow
          isModal
          close={props.close}
          className="checkout"
          {...props}
        />
      </ModalShell>
    ),
    { displayName: "ExpressCheckoutModal" }
  );

export default ModalChild;
