import __ from "ramda/src/__";
import assocPath from "ramda/src/assocPath";
import compose from "ramda/src/compose";
import dissoc from "ramda/src/dissoc";
import isNil from "ramda/src/isNil";
import lensPath from "ramda/src/lensPath";
import merge from "ramda/src/merge";
import over from "ramda/src/over";
import set from "ramda/src/set";
import unless from "ramda/src/unless";
import view from "ramda/src/view";
import {
  workspaces as initialState,
  defaultWorkspaceSettings,
} from "../initialState";
import types from "../types/workspaces";
import { handleActions } from "./utils";

const selectedDocumentsLens = (workspaceId) =>
  lensPath(["tabs", workspaceId, "selectedDocuments"]);

const selectedDocumentsRanksLens = (workspaceId) =>
  lensPath(["tabs", workspaceId, "selectedDocumentsRanks"]);

export const newTypes = {
  saveSearchUrl: "SAVE_SEARCH_URL",
  document: "document",
  saveSelectedDocSummaryTab: "SAVE_DOC_SUMMARY_TAB",
};

export default handleActions(initialState, {
  [types.ADD]: add,
  [types.ACTIVATE]: activate,
  [types.REMOVE]: remove,
  [types.UPDATE_SELECTED_DEPARTMENT]: updateSelectedDepartment,
  [types.ADD_SELECTED_DOCUMENT_WITH_RANK]: addSelectedDocumentWithRank,
  [types.REMOVE_SELECTED_DOCUMENT]: removeSelectedDocument,
  [types.ADD_ALL_DOCUMENT_WITH_RANK]: addAllDocumentWithRank,
  [types.REMOVE_ALL_DOCUMENTS]: removeAllDocuments,
  [types.UPDATE_QUICK_SEARCH_STATE]: updateQuickSearchState,
  [types.UPDATE_ADVANCED_SEARCH_STATE]: updateAdvancedSearchState,
  [newTypes.saveSearchUrl]: saveSearchUrl,
  [newTypes.document]: addDocNumberTabTitle,
  [newTypes.saveSelectedDocSummaryTab]: saveSelectedDocSummaryTab,
});

function addDocNumberTabTitle(state, { payload }) {
  const { activeWorkspaceID } = state;
  const { docNumber } = payload;
  const settingsLens = lensPath(["tabs", activeWorkspaceID, "title"]);
  return set(settingsLens, `Doc # ${docNumber}`, state);
}

function add(state, { payload }) {
  const { id } = payload;

  return {
    ...state,
    activeWorkspaceID: id,
    tabs: Object.assign(state.tabs, {
      [id]: { ...payload, ...defaultWorkspaceSettings },
    }),
  };
}

function activate(state, { payload }) {
  const update = compose(
    assocPath([payload.id, "path"], payload.path),
    assocPath([payload.id, "type"], payload.type),
    unless(isNil, assocPath([payload.id, "title"], payload.title))
  );

  return {
    ...state,
    activeWorkspaceID: payload.id,
    tabs: update(state.tabs),
  };
}

function remove(state, { payload }) {
  const { activeWorkspaceID, tabs } = state;
  const newWorkspaces = dissoc(payload, tabs);

  if (payload === activeWorkspaceID) {
    return {
      ...state,
      activeWorkspaceID: null,
      tabs: newWorkspaces,
    };
  }

  return {
    ...state,
    tabs: newWorkspaces,
  };
}

function updateSelectedDepartment(state, { payload }) {
  const { activeWorkspaceID } = state;

  const settingsLens = lensPath([
    "tabs",
    activeWorkspaceID,
    "selectedDepartment",
  ]);

  return set(settingsLens, payload, state);
}

function addSelectedDocumentWithRank(state, { payload }) {
  const { activeWorkspaceID } = state;
  const { selection, rank } = payload;
  const settingsLens = selectedDocumentsLens(activeWorkspaceID);
  const rankLens = selectedDocumentsRanksLens(activeWorkspaceID);

  return compose(
    over(settingsLens, merge(__, selection)),
    over(rankLens, merge(__, rank))
  )(state);
}

function removeSelectedDocument(state, { payload }) {
  const { activeWorkspaceID } = state;
  const settingsLens = selectedDocumentsLens(activeWorkspaceID);
  const rankLens = selectedDocumentsRanksLens(activeWorkspaceID);
  const removeByIndex = dissoc(payload);

  return compose(
    over(settingsLens, removeByIndex),
    over(rankLens, removeByIndex)
  )(state);
}

function addAllDocumentWithRank(state, { payload }) {
  const { activeWorkspaceID } = state;
  const { selections, ranks } = payload;
  const settingsLens = selectedDocumentsLens(activeWorkspaceID);
  const rankLens = selectedDocumentsRanksLens(activeWorkspaceID);

  return compose(
    over(settingsLens, merge(__, selections)),
    over(rankLens, merge(__, ranks))
  )(state);
}

function removeAllDocuments(state) {
  const { activeWorkspaceID } = state;
  const settingsLens = selectedDocumentsLens(activeWorkspaceID);
  const rankLens = selectedDocumentsRanksLens(activeWorkspaceID);

  return compose(set(settingsLens, {}), set(rankLens, {}))(state);
}

function updateQuickSearchState(state, { payload }) {
  const { state: formState, workspaceID } = payload;

  const settingsLens = lensPath(["tabs", workspaceID, "quickSearchState"]);

  if (view(lensPath(["tabs", workspaceID]), state)) {
    return set(settingsLens, formState, state);
  }

  return state;
}

function updateAdvancedSearchState(state, { payload }) {
  const { state: formState, workspaceID } = payload;

  const settingsLens = lensPath(["tabs", workspaceID, "advancedSearchState"]);

  if (view(lensPath(["tabs", workspaceID]), state)) {
    return set(settingsLens, formState, state);
  }

  return state;
}

function saveSearchUrl(state, { payload: url }) {
  const { activeWorkspaceID } = state;
  const searchUrlLens = lensPath(["tabs", activeWorkspaceID, "searchUrl"]);

  return set(searchUrlLens, url, state);
}

function saveSelectedDocSummaryTab(state, { payload: tabName }) {
  const { activeWorkspaceID } = state;
  const selectedTab = lensPath(["tabs", activeWorkspaceID, "docSummaryTab"]);

  return set(selectedTab, tabName, state);
}
