import { useEffect } from "react";
import { hasError, isInit, isLoading } from "../../constants";
import { protocols, useAPI } from "../../hooks/use-api";
import { isAbsent } from "../../utils";

const useFetchApplicationContent = () =>
  useAPI({
    send: "fetch-subscription-application-content",
    protocol: protocols.HTTP,
  });

export const useApplicationContent = (page) => {
  const { data, initiate, status } = useFetchApplicationContent();

  useEffect(() => {
    if (isAbsent(data)) initiate({ query: { page } });
  }, []);

  return {
    data,
    loading: isLoading(status) || isInit(status),
    hasError: hasError(status),
  };
};
