import { useState } from "react";
import useAttachments from "../doc-detail.common/useAttachments";
import useDocument from "../doc-detail.common/useDocument";
import { Types } from "./constants";
import useControls from "./use-controls";
import {
  ViewType,
  getDataByType,
  isNotLastPage,
  isNotFirstPage,
} from "./utils";

const defaultHideMessage = "Public Access to this Document Image is Restricted";

export default (type = Types.Default) => {
  const docData = useDocument();
  const attachments = useAttachments();
  const { controls, modifiers } = useControls();

  const { status } = docData;

  const [viewType, setViewType] = useState(ViewType.SinglePage);

  const {
    src,
    pageCount,
    currentPage,
    setCurrentPage,
    isOcrVisible,
    thumbnails,
    currentPageOcr,
    hasOcrHits,
    hitIndex,
    hitMapIndex,
    ocrHits,
    setHitIndex,
    setHitMapIndex,
    totalHits,
    toggleOcrVisibility,
  } = getDataByType(type, docData, attachments.current);

  const isSecured = docData?.isSecured ?? null;
  const hidePreview = docData?.hidePreview ?? null;
  const hidePreviewMessage = docData?.hidePreviewMessage ?? defaultHideMessage;

  const hasNext = isNotLastPage(currentPage, pageCount);
  const hasPrev = isNotFirstPage(currentPage);
  const lastPage = pageCount - 1 >= 0 ? pageCount - 1 : 0;

  const goToFirstPage = () => hasPrev && setCurrentPage(0);
  const goToLastPage = () => hasNext && setCurrentPage(lastPage);
  const goToPrevPage = () => hasPrev && setCurrentPage(currentPage - 1);
  const goToNextPage = () => hasNext && setCurrentPage(currentPage + 1);

  const viewSinglePage = (e) => {
    e.preventDefault();
    setViewType(ViewType.SinglePage);
  };

  const viewMultiPage = (e) => {
    e.preventDefault();
    setViewType(ViewType.MultiPage);
  };

  return {
    page: {
      // data
      ...modifiers,
      currentPage,
      currentPageOcr,
      hasNext,
      hasOcrHits,
      hasPrev,
      hitIndex,
      hitMapIndex,
      isOcrVisible,
      isSecured,
      hidePreview,
      hidePreviewMessage,
      ocrHits,
      pageCount,
      totalHits,
      viewType,
      type,

      // actions
      ...controls,
      goToFirstPage,
      goToLastPage,
      goToNextPage,
      goToPage: setCurrentPage,
      goToPrevPage,
      setHitIndex,
      setHitMapIndex,
      toggleOcrVisibility,
      viewMultiPage,
      viewSinglePage,
    },
    src,
    thumbnails,
    status,
  };
};
