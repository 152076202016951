import { css } from "@emotion/react";

export const borderGray = "#DADDE3";

export const buttons = {
  list: css`
    grid-area: nav;
    display: grid;
    list-style: none;
    border: 1px solid ${borderGray};
    border-radius: 4px;
    justify-items: stretch;
    align-items: stretch;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0;
    height: 27px;
    background: var(--color-neutral-lightest);
    min-width: 175px;

    & > li {
      display: grid;
      justify-items: stretch;
      align-items: stretch;
    }

    & > li + li {
      border-left: 1px solid ${borderGray};
    }

    & button {
      padding: 5px;
      margin: 0;
      cursor: pointer;
      background: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    & button img {
      height: 15px;
      width: 15px;
      filter: invert(43%) sepia(14%) saturate(742%) hue-rotate(180deg)
        brightness(90%) contrast(86%);
    }
  `,
  exit: css`
    grid-area: close;
    justify-self: right;
    font-size: 15px;
  `,
  close: css`
    width: 15px;
    background: transparent;
    justify-self: flex-end;
  `,
};

export default {
  bold: css`
    font-weight: bold;
  `,
  matches: css`
    grid-area: matches;
    align-items: center;
  `,
  wrapper: css`
    color: #14315d;
    padding: 5px 10px;
    box-shadow: 0px 2px 4px 0px #00000033;
    border: 1px solid #b9c3d5;
    margin: 10px;
    border-radius: 4px;
    background: linear-gradient(180deg, #ffffff 18.45%, #ebedf0 100%);
    position: absolute;
    right: 0;
    display: grid;
    align-items: center;
    height: 80px;
    grid-template-areas:
      "matches close"
      "nav     nav";
  `,
  highlight: (isVisible) => css`
    color: ${isVisible ? "#34eb62" : "#ffff00"};
    fill: currentColor;
    mix-blend-mode: multiply;
  `,
};
