import { Search, Cart, Close, DocGroup } from "@kofile/icons";
import classnames from "classnames";
import isEmpty from "ramda/src/isEmpty";
import pathOr from "ramda/src/pathOr";
import { useSelector } from "react-redux";
import { Link } from "../../components";
import { selectors } from "../../store";
import { types as dateRangeTypes } from "../../units/search.basic/basic-search.utils";
import { formatDateRangeSingleRange } from "../../utils/format";
import { tab } from "./workspaces.style";

const Tab = (props) => {
  const { id, path, close } = props;
  const workspaces = useSelector(selectors.workspaces.getWorkspaces);
  const workspace = pathOr(null, ["tabs", id], workspaces);
  const workspacesWithTooltips = ["Search Results", "Advanced Search"];

  const titlePath = pathOr("", ["title"], workspace);
  const showTooltip = workspacesWithTooltips.includes(titlePath);
  const isAdvancedSearch = !isEmpty(
    pathOr({}, ["advancedSearchState"], workspace)
  );

  const quickSearch = {
    textPath: ["quickSearchState", "searchTerm", "value"],
    searchRangePath: ["quickSearchState", "dateRange", "rangeValue"],
  };

  const advancedSearch = {
    textPath: ["advancedSearchState", "form", "values", "ocrText"],
    searchRangePath: [
      "advancedSearchState",
      "form",
      "values",
      dateRangeTypes.RECORDED,
    ],
  };

  const getSearchType = () =>
    isAdvancedSearch ? "advancedSearchState" : "quickSearchState";

  const text = pathOr(
    "",
    getSearchType() === "advancedSearchState"
      ? advancedSearch.textPath
      : quickSearch.textPath,
    workspace
  );

  const getSearchRange = () =>
    pathOr(
      "All Dates",
      isAdvancedSearch
        ? advancedSearch.searchRangePath
        : quickSearch.searchRangePath,
      workspace
    );

  let { type, title } = props;

  if (workspace !== null) {
    type = workspace.type;
    title = workspace.title;
  }

  const searchText = text.length === 0 ? "" : `${text}`;
  const formattedDate = formatDateRangeSingleRange(getSearchRange());
  const plus = searchText ? ` + ` : "";

  const tooltip = showTooltip
    ? `Results for "${searchText}${plus}${formattedDate}"`
    : null;

  const activeWorkspaceID = useSelector(
    selectors.workspaces.getActiveWorkspaceID
  );

  const isActive = activeWorkspaceID === id;

  const Icon = {
    search: Search,
    advanced: Search,
    results: Search,
    cart: Cart,
    detail: DocGroup,
  }[type];

  const className = classnames("workspaces__tab", {
    "workspaces__tab--active": isActive,
  });

  const closeTab = close(id);

  return (
    <li
      className={className}
      key={id}
      data-tourid="workspaceTab"
      title={tooltip}
    >
      <Link to={path} css={tab.link(isActive)} workspaceID={id}>
        <div css={tab.wrap}>
          <Icon id={`workspaces-icon-${id}`} className="icon--large" />
          <span css={tab.title}>{title}</span>
        </div>
      </Link>
      <button
        onClick={closeTab}
        css={tab.close}
        data-testid="workspaceCloseButton"
        aria-label="close workspace"
      >
        <Close id="close-icon" className="icon--tiny" />
      </button>
    </li>
  );
};

export default Tab;
