import { makeActionTypes } from "../../utils";

export default makeActionTypes("user", [
  "UPDATE_AUTH",
  "CLEAR_ERROR",
  "CLEAR_CARD_INFO",
  "SET_ERROR",
  "SANITIZE_TOKEN",
  "SET_USE_SAVED_CARD",
  "CLEAR_REGISTRATION_COMPLETE",
  "ADD_DOCUMENTS_TO_CART",
  "ADD_ATTACHMENTS_TO_CART",
  "EXPRESS_CHECKOUT",
  "EXPORT_RESULTS",
  "RESET_REMOVE_CARD",
]);
