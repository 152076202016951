import AddDocCheckbox from "./add-support-docs.field";

const AddSupportDocs = (props) => {
  const { attachments, transcription, translation, toggleByType } = props;

  return (
    <>
      <AddDocCheckbox type="attachments" {...attachments} />
      <AddDocCheckbox
        type="transcription"
        action={toggleByType("transcription")}
        isPresent={transcription.isPresent}
      />
      <AddDocCheckbox
        type="translation"
        action={toggleByType("translation")}
        isPresent={translation.isPresent}
      />
    </>
  );
};

export default AddSupportDocs;
