import { useAPI } from "../../../hooks/use-api";

export const useFetchContentPanel = () =>
  useAPI({
    send: "fetch-content-panel",
    initialPayload: {},
    exportAs: "content",
    listenFor: {
      success: "content-panel",
      failure: "error-fetching-content-panel",
    },
  });
