import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { hasLoaded } from "../../constants/status";
import { selectors } from "../../store";
import { isNotNil } from "../../utils";
import { chooseViewFor } from "../../utils/choose-view-for";
import { ContactSupport } from "../common/contact-support";
import { Plans } from "./choose-plan.plans";
import { Loading } from "./loading";
import { usePlans } from "./use-plans";

export const PlanType = {
  Individual: "individual",
  MultiUser: "multiUser",
};

export const ChoosePlan = (props) => {
  const {
    select,
    next,
    className,
    planCode,
    organizationName = "",
    setName = () => {},
    showMultiUserPlans = true,
    ...rest
  } = props;

  const [planType, setPlanType] = useState(PlanType.Individual);
  const { plans, status } = usePlans({ currentPlanCode: planCode });
  const [showPlanTypePicker, setShowPlanTypePicker] = useState(false);
  const [showOrgNameError, setShowOrgNameError] = useState(false);
  const isIndividualPlan = planType === PlanType.Individual;
  const isMultiUserPlan = planType === PlanType.MultiUser;
  const planIsNotEmpty = (type) => plans[type].length > 0;
  const helpUrl = useSelector(selectors.configuration.getSubscriptionHelpUrl);

  useEffect(() => {
    if (hasLoaded(status)) {
      const hasMultiUserPlans =
        planIsNotEmpty(PlanType.MultiUser) && showMultiUserPlans;
      const hasIndividualPlans = planIsNotEmpty(PlanType.Individual);

      if (hasIndividualPlans && hasMultiUserPlans) {
        setShowPlanTypePicker(true);
      }

      if (hasMultiUserPlans) {
        setPlanType(PlanType.MultiUser);
      }

      if (hasIndividualPlans) {
        setPlanType(PlanType.Individual);
      }
    }
  }, [plans, status]);

  useEffect(() => {
    if (!isMultiUserPlan) {
      setShowOrgNameError(false);
      setName("");
    }
  }, [isMultiUserPlan]);

  const onOrgUpdate = (e) => {
    setName(e.target.value);
    setShowOrgNameError(false);
  };

  const selectPlanAndAdvanceNav = (plan) => {
    select(plan);
    next(plan);
  };

  const choose = (plan) => () => {
    const multiUserNameIsValid =
      isMultiUserPlan &&
      isNotNil(organizationName) &&
      organizationName.length > 0;

    if (isIndividualPlan || multiUserNameIsValid) {
      selectPlanAndAdvanceNav(plan);
    } else {
      setShowOrgNameError(true);
    }
  };

  const planProps = {
    planType,
    setPlanType,
    plans,
    choose,
    organizationName,
    onOrgUpdate,
    setShowOrgNameError,
    showOrgNameError,
    showPlanTypePicker,
    helpUrl,
    ...rest,
  };

  return chooseViewFor(status).when({
    isInit: () => <Loading className={className} />,
    isLoading: () => <Loading className={className} />,
    hasLoaded: () => <Plans className={className} {...planProps} />,
    default: () => <ContactSupport className={className} />,
  });
};
