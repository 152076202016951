// mod: doc-preview

import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { usePriceQuote } from "./use-get-price-quote";

export const Types = {
  Attachments: "attachments",
  Transcription: "transcription",
  Translation: "translation",
  CertifiedCopy: "certified_copy",
};

const useAddSupportDocs = (certifiedCopy) => {
  const [priceQuote, getPriceQuote] = usePriceQuote(certifiedCopy);
  const [pages, setPages] = useState("all");
  const [addTranscription, setAddTranscription] = useState(false);
  const [addTranslation, setAddTranslation] = useState(false);
  const [addAttachments, setAddAttachments] = useState(false);

  const certDefault = prop("isChecked", certifiedCopy)
    ? certifiedCopy.isChecked
    : null;
  const [addCertifiedCopy, setAddCertifiedCopy] = useState(certDefault);

  const { isNewDocPreviewEnabled } = selectors.configuration;
  const newDocPreviewEnabled = useSelector(isNewDocPreviewEnabled);

  const toggleTranslation = () => setAddTranslation(!addTranslation);
  const toggleTranscription = () => setAddTranscription(!addTranscription);
  const toggleAttachments = () => setAddAttachments(!addAttachments);
  const toggleCertifiedCopy = () =>
    setAddCertifiedCopy(isNil(addCertifiedCopy) ? false : !addCertifiedCopy);

  useEffect(() => {
    if (newDocPreviewEnabled) {
      getPriceQuote({
        includeTranslation: addTranslation,
        includeTranscription: addTranscription,
        includeAttachments: addAttachments,
        isCertifiedCopy: addCertifiedCopy,
        selectedPages: pages,
      });
    }
  }, [
    addTranscription,
    addTranslation,
    addAttachments,
    pages,
    addCertifiedCopy,
  ]);

  const toggleByType = (type) => () => {
    let fn = () => {};
    if (type === Types.Translation) fn = toggleTranslation;
    if (type === Types.Transcription) fn = toggleTranscription;
    if (type === Types.Attachments) fn = toggleAttachments;
    if (type === Types.CertifiedCopy) fn = toggleCertifiedCopy;

    return fn();
  };

  const addSupportDocDataTo = (items) => {
    return items.map((item) => ({
      ...item,
      includeTranslation: addTranslation,
      includeTranscription: addTranscription,
      includeAttachments: addAttachments,
      isCertifiedCopy: addCertifiedCopy,
    }));
  };

  return {
    toggleByType,
    addSupportDocDataTo,
    priceQuote,
    pages,
    setPages,
    setAddCertifiedCopy,
    addCertifiedCopy: isNil(addCertifiedCopy) ? false : addCertifiedCopy,
    includeAttachments: addAttachments,
  };
};

export default useAddSupportDocs;
