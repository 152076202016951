import { Remove, DeleteError } from "./";

const activeAlertsConfig = () => ({
  rowProps: () => ({
    className: "fraud-alert__table-row",
  }),
  columns: [
    {
      name: "Search Criteria",
      key: "criteria",
      headerClassName: "fraud-alert__table-header-cell",
      cellClassName: "fraud-alert__table-cell fraud-alert__active-name",
      render: ({ entity }) => (
        <div data-testid="activeAlertCriteria">{entity.desc}</div>
      ),
    },
    {
      name: "",
      key: "remove",
      headerClassName: "fraud-alert__table-header-cell",
      cellClassName: "fraud-alert__table-cell fraud-alert__remove-active",
      render: ({ entity }) => <Remove id={entity.id} name={entity.desc} />,
    },
    {
      name: "",
      key: "error",
      headerClassName: "fraud-alert__table-header-cell",
      cellClassName: "fraud-alert__table-cell fraud-alert__remove-error",
      render: ({ entity }) => <DeleteError id={entity.id} />,
    },
  ],
});

export default activeAlertsConfig;
