import styled from "@emotion/styled";
import { Button } from "./_button";

export const ClearButton = styled(Button)`
  background: var(--color-neutral-lightest);
  border: 1px solid rgba(4, 29, 68, 0.1);
  color: #006dd5;
  grid-area: clear;
  margin-left: auto;
  padding: 0.8rem 12px;
`;
