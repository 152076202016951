import always from "ramda/src/always";
import compose from "ramda/src/compose";
import cond from "ramda/src/cond";
import contains from "ramda/src/contains";
import equals from "ramda/src/equals";
import filter from "ramda/src/filter";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import join from "ramda/src/join";
import keys from "ramda/src/keys";
import map from "ramda/src/map";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import split from "ramda/src/split";
import T from "ramda/src/T";

/**
 * Calls a function with scoped arguments
 * @param {*} args - Any arguments to pass to the next function
 * @return {function(Function) -> *} - A function that calls the given func
 */
export const callWith =
  (...args) =>
  (fn) =>
    fn(...args);

/**
 * A function to cleans the key based on stats obj
 *
 * @param {string} key - The key to look on the stats object for
 * @return {function(Stats) -> function(string) -> string} - A function that cleans given value
 */
export const cleanKeyBasedOn = (key) => (stats) => (value) => {
  const statKeys = keys(stats);
  const statCodesForKey = compose(map(prop("label")), propOr({}, key))(stats);

  return ifElse(
    () => contains(key, statKeys),
    compose(
      join(","),
      filter((val) => contains(val, statCodesForKey)),
      split(",")
    ),
    identity
  )(value);
};

export const cleanDocTypesBasedOn = cleanKeyBasedOn("docTypes");

export const cleanDocGroupsBasedOn = cleanKeyBasedOn("docGroups");

/**
 * Our functions that expect stats and return a cleaning function
 */
const cleanKeys = [cleanDocTypesBasedOn, cleanDocGroupsBasedOn];

/**
 * Maps stats to the cleaning functions
 *
 * @param {Stats} stats
 * @return {Array<Function>} - An array of cleaning functions
 */
export const getCleanFns = (stats) => map(callWith(stats), cleanKeys);

/**
 * Cleans a query based on statistics object
 *
 * @param {Object} data - Stats and Query data
 * @param {Stats} data.stats - The meta statistics given back
 * @param {Object} data.query - The current query in the URL
 * @return {Object} - A valid query, given the stats
 */
export const cleanQuery = ({ stats, query }) => {
  const [cleanDocTypes, cleanDocGroups] = getCleanFns(stats);

  return mapObjIndexed((value, key) =>
    cond([
      [equals("_docTypes"), () => cleanDocTypes(value)],
      [equals("docGroups"), () => cleanDocGroups(value)],
      [T, always(value)],
    ])(key)
  )(query);
};

/**
 * Our Statistics Object
 *
 * @typedef {Object} Stats
 * @property {Object} docTypes - The docTypes statistics
 */

export const flipSort = (s) => (s === "asc" ? "desc" : "asc");

export const updateSort = ({ sort, sortBy }, key) => {
  if (key === sortBy) {
    return {
      sort: flipSort(sort),
      sortBy: key,
    };
  }

  return {
    sort: "asc",
    sortBy: key,
  };
};
