import { useSelector } from "react-redux";
import { selectors } from "../../store";
import BlueButton from "../doc-detail.common/_button.blue";
import AddToCart from "./action.add-to-cart.behavior";

const AddToCartButton = () => {
  const { getImg } = selectors.assets;
  const addToCartIcon = useSelector(getImg("icon_cart.svg"));

  return (
    <AddToCart>
      {({ text, disabled, addDocToCart }) => (
        <BlueButton disabled={disabled} onClick={addDocToCart}>
          <img src={addToCartIcon} alt="add to cart icon" />
          {text}
        </BlueButton>
      )}
    </AddToCart>
  );
};

export default AddToCartButton;
