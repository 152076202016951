import { css } from "@emotion/react";
import { LoadingSpinner } from "../../../components";
import ExpandableButton from "./_summary.expand.button";

const style = css`
  & .loading__spinner {
    height: 10px;
    width: 10px;
    margin: 3px;
    padding: 0px;
  }
`;

const ExpandGroupButton = ({ toggle, label, loading, hide }) => {
  if (hide) return null;

  return (
    <ExpandableButton disabled={loading} onClick={toggle}>
      {loading && <LoadingSpinner css={style} />}
      {label}
    </ExpandableButton>
  );
};

export default ExpandGroupButton;
