import LastActiveOn from "./member-list.table.last-active-on";
import Remove from "./member-list.table.remove-with-popup";

const classes = {
  headerClassName: "fraud-alert__table-header-cell",
  cellClassName: "fraud-alert__table-cell",
};

const memberTableConfig = (props) => ({
  rowProps: () => ({
    className: "fraud-alert__table-row",
  }),
  columns: [
    {
      name: "Name",
      key: "name",
      ...classes,
      render: ({ entity }) => <div>{entity.fullName}</div>,
    },
    {
      name: "Email",
      key: "email",
      ...classes,
      render: ({ entity }) => <div>{entity.email}</div>,
    },
    {
      name: "Last Active On",
      key: "error",
      ...classes,
      render: ({ entity }) => <LastActiveOn date={entity.lastActivityDate} />,
    },
    {
      name: "",
      key: "remove",
      ...classes,
      cellClassName: "fraud-alert__table-cell table-cell__flex-end",
      render: ({ entity }) => <Remove {...entity} {...props} />,
    },
  ],
});

export default memberTableConfig;
