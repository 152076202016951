import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import { isAbsent } from "../../../utils";

const styles = {
  container: css`
    height: auto;
    padding-top: 30px;
    grid-area: masthead;
  `,
  img: css`
    display: block;
    height: 130px;
    margin: auto;
    width: 130px;
  `,
  title: css`
    color: #041c4f;
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px;
    text-align: center;
  `,
};

export const SearchMasthead = () => {
  const countyName = useSelector(selectors.configuration.getCountyName);
  const sealUrl = useSelector(selectors.configuration.getSealUrl);
  const searchTitle = useSelector(selectors.configuration.getSearchTitle);

  if (isAbsent(searchTitle)) return null;

  return (
    <div css={styles.container}>
      <img
        css={styles.img}
        alt={`county seal for ${countyName}`}
        src={sealUrl}
      />
      <div css={styles.title}>{searchTitle}</div>
    </div>
  );
};

export default SearchMasthead;
