import isEmpty from "ramda/src/isEmpty";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../store";
import { LegalDisplayTypes } from "../constants";
import { groupLegalsByType } from "./legal-tables.group-by-type";
import { Table } from "./table";

export const LegalTables = ({ displayOption, doc = {} }) => {
  const dept = useSelector(selectors.workspaces.getSelectedDepartment);
  const legalsConfig = useSelector((state) =>
    selectors.configuration.getLegalsCardConfig(state, dept)
  );
  const config = legalsConfig.columnsByType;
  const legalsData = groupLegalsByType(doc);

  const legalTables = legalsData.map((legalData, i) => {
    const legalConfig = config[legalData.legalType] || [];

    return (
      <Table
        key={i}
        config={legalConfig}
        data={legalData}
        showOnlyFirst={displayOption === LegalDisplayTypes.SHOW_FIRST}
      />
    );
  });

  const hideTables =
    displayOption === LegalDisplayTypes.HIDE || legalTables.length === 0;
  const loading = isEmpty(doc) || isEmpty(config);

  if (hideTables || loading) return null;

  return legalTables;
};
