import lensPath from "ramda/src/lensPath";
import propOr from "ramda/src/propOr";
import set from "ramda/src/set";
import {
  INTERCEPT_PROP,
  INTERCEPT_ENABLED,
  INTERCEPTS,
  ENABLED,
} from "./constants";

const interceptLens = lensPath(["payload", INTERCEPT_PROP]);

export default (msg) => {
  if (process.env.IS_CLIENT === "f") {
    return msg;
  }

  try {
    const enabled = JSON.parse(localStorage.getItem(ENABLED));

    if (enabled !== true) {
      return msg;
    }

    const interceptEnabled = JSON.parse(
      localStorage.getItem(INTERCEPT_ENABLED)
    );

    if (interceptEnabled !== true) {
      return msg;
    }
  } catch (_) {
    return msg;
  }

  try {
    const intercepts = JSON.parse(localStorage.getItem(INTERCEPTS));

    if (Array.isArray(intercepts)) {
      const type = propOr(null, "type", msg);
      const intercept = intercepts.find((i) => i.when === type) || null;

      if (intercept !== null) {
        return set(interceptLens, intercept.force, msg);
      }

      return msg;
    }

    return msg;
  } catch (error) {
    console.error(error);

    return msg;
  }
};
