import { css } from "@emotion/react";

export default {
  container: css`
    padding: 20px;
    display: grid;
    width: 100%;
    grid-template-rows: max-content;

    & li {
      display: grid;
      grid-template-columns: 300px min-content 1fr;
      padding: 10px;
      font-size: 1.1rem;
      border-bottom: 1px solid rgba(4, 29, 68, 0.15);
    }

    & li > button:nth-of-type(3) {
      justify-self: center;
    }
  `,
  button: css`
    cursor: pointer;
    color: #006dd5;
    font-size: 1.1rem;
    text-overflow: ellipsis;
    background: transparent;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    padding: 0;
  `,
  heading: css`
    background: #f5f6f8;
    font-size: 1.4rem;
    padding: 8px;
    color: #041d44;

    & li {
      border-bottom: none;
    }
  `,
  breadcrumbs: css`
    font-size: 1.2rem;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px;
    & > div {
      margin: 2px 2px;
    }
  `,
};
