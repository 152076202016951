import PropTypes from "prop-types";
import propOr from "ramda/src/propOr";
import { DropdownMenu } from "../../../../../../../components";
import { DocActionButton } from "../../../../../../../components/doc-action-button";
import { LegalDisplayOptions } from "../constants";

const Button = ({ onClick = () => {}, label }) => (
  <DocActionButton onClick={onClick}>{label}</DocActionButton>
);

export const LegalDropdown = (props) => {
  const options = LegalDisplayOptions.map(({ key, label }) => ({
    label,
    disabled: false,
    onClick: () => props.onClick(key),
  }));

  const legalOption = LegalDisplayOptions.find(
    ({ key }) => key === props.activeLegalOption
  );

  const label = propOr("No option supplied.", "label", legalOption);

  return (
    <DropdownMenu
      className="document-actions"
      options={options}
      optionRenderer={Button}
      label={label}
    />
  );
};

LegalDropdown.displayName = "LegalDropdown";

LegalDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeLegalOption: PropTypes.string.isRequired,
};
