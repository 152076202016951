import has from "ramda/src/has";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { selectors } from "../../../../../store";
import { CardsContainer } from "./_cards.container";
import ResultCard from "./ResultCard";
import { collapseParties, getCleanedDoc } from "./utils";

const Cards = () => {
  const { workspaces, configuration, cart, documents } = selectors;
  const deptCode = useSelector(workspaces.getSelectedDepartment);
  const department = useSelector(configuration.getConfigForDepartment);
  const cartHash = useSelector(cart.getHash);
  const docIDs = useSelector(documents.getActiveWorkspaceDataOrder);
  const docMap = useSelector(documents.getActiveWorkspaceDataHash);
  const shoppingCartId = useSelector(cart.getCartId);
  const previewConfig = useSelector((state) =>
    configuration.getPreviewConfig(state, deptCode)
  );
  const showImagePreview = propOr(false, "showImages", previewConfig);

  return (
    <CardsContainer>
      {docIDs.map((id, index) => (
        <ResultCard
          doc={mapDocForCard(docMap[id])}
          key={index}
          showImagePreview={showImagePreview}
          inCart={has(id, cartHash)}
          department={department}
          shoppingCartId={shoppingCartId}
        />
      ))}
    </CardsContainer>
  );
};

export default withRouter(Cards);

// --------------------------------------------------------------------- private
function mapDocForCard(doc) {
  const highlights = propOr({}, "grantee", doc);
  const parties = collapseParties(propOr([], "parties", doc), highlights);

  return getCleanedDoc({
    doc,
    highlights,
    parties,
    location: window.location,
  });
}
