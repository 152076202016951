import styled from "@emotion/styled";

export const Container = styled.div`
  align-self: center;
  display: flex;
  margin-top: 20px;

  & > * + * {
    margin-left: 20px;
  }
`;
