import styled from "@emotion/styled";

export const Option = styled.li`
  color: #243f8a;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-size: 13px;
  white-space: nowrap;
  width: 100%;

  & button {
    padding: 8px 16px;
    width: 100%;
    text-align: left;
  }

  &:hover {
    background: rgba(4, 29, 68, 0.04);
  }

  .document-actions {
    padding: 6px 10px;
  }

  .workspace-tabs__overflow-menu {
    padding: 0;
  }
`;
