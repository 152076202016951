import isEmpty from "ramda/src/isEmpty";
import React from "react";
import { useDispatch } from "react-redux";
import Field from "../../components/Form/Field";
import { actions } from "../../store";
import { FieldRow } from "./_field-row";
import { FieldGroup } from "./field-group";

export const Fields = ({
  advancedSearchData,
  fetchDepartmentDateRanges,
  state,
  toggleGroupExpanded,
  touchField,
  updateFieldValue,
  updateFieldError,
}) => {
  const dispatch = useDispatch();
  const { form, groupExpanded } = state;
  const { selectedDepartment, fields, departments } = advancedSearchData;
  const { groups = [], layout, code } = selectedDepartment;
  const toKey = (index) => `${code}:${index}`;

  const onUpdateDepartment = (dept) => {
    dispatch(actions.workspaces.updateSelectedDepartment(dept));
    fetchDepartmentDateRanges(dept);
  };

  const departmentSelectProps = {
    id: "department",
    type: "Select",
    options: departments,
    label: "Department",
    value: code,
    onChange: onUpdateDepartment,
  };

  const mapFieldIds = (fieldIds) => {
    return fieldIds.map((fieldID, i) => {
      if (fieldID === "department") {
        return <Field key={i} {...departmentSelectProps} />;
      }

      const { values, errors } = form;
      const config = fields[fieldID];
      const key = config.id;
      const cb = () => touchField(key);

      return (
        <Field
          key={i}
          onChange={updateFieldValue({ key, config, cb })}
          value={values[key] || ""}
          error={errors[key] || ""}
          department={selectedDepartment}
          updateFieldError={updateFieldError(key)}
          {...config}
        />
      );
    });
  };

  if (!isEmpty(groups)) {
    return groups.map((group, k) => (
      <FieldRow key={toKey(k)}>
        <FieldGroup
          title={group.title}
          isOpen={groupExpanded[k]}
          onClick={toggleGroupExpanded(k)}
        >
          {group.layout.map((fieldIds, l) => (
            <FieldRow key={toKey(l)}>{mapFieldIds(fieldIds)}</FieldRow>
          ))}
        </FieldGroup>
      </FieldRow>
    ));
  }

  if (!isEmpty(layout)) {
    return (
      <React.Fragment>
        <Field {...departmentSelectProps} />
        {layout.map((fieldIDs, k) => (
          <FieldRow key={toKey(k)}>{mapFieldIds(fieldIDs)}</FieldRow>
        ))}
      </React.Fragment>
    );
  }

  return null;
};
