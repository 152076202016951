import Link from "../../../../components/Link";

const ReturnButton = () => (
  <Link className="account-title__return-button" to="/" openNewWorkspace>
    Return To Public Search
  </Link>
);

ReturnButton.displayName = "ReturnButton";

export default ReturnButton;
