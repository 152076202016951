import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background: var(--color-neutral-lightest);
  border-radius: 4px;
  width: 556px;
  font-size: var(--font-size-md-regular);
  padding: 2rem;

  & .checkbox {
    margin-top: 15px;
  }
`;
