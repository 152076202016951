import { css } from "@emotion/react";
import PropTypes from "prop-types";
import assoc from "ramda/src/assoc";
import compose from "ramda/src/compose";
import map from "ramda/src/map";
import propEq from "ramda/src/propEq";
import { connect, useDispatch, useSelector } from "react-redux";
import { branch, renderComponent } from "recompose";
import { actions, selectors } from "../../../../store";
import { mapIndexed } from "../../../../utils";
import { ContentsWrapper } from "./_contents-wrapper.js";
import Checkout from "./checkout";
import EmptyCart from "./empty-cart";
import Entry from "./entry";

const makeEntry = (props, id) => (
  <Entry key={id} isFromCart={true} {...props} />
);

const style = {
  content: css`
    width: 77%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
  checkout: css`
    width: 23%;
  `,
  columnWrap: css`
    display: flex;
  `,
  rightHeader: css`
    align-items: center;
    display: flex;
    margin-left: auto;
  `,
  leftHeader: css`
    width: 200px;
  `,
  emptyCart: css`
    background: none;
    color: #006dd5;
    font-size: 13px;
  `,
  title: css`
    color: #041d44;
    font-size: 22px;
  `,
  header: css`
    display: flex;
    padding-top: 1rem;
    width: 77%;
  `,
};

export const Contents = ({ cartAmount }) => {
  const dispatch = useDispatch();
  const { assets, cart } = selectors;
  const cartOrder = useSelector(cart.getOrder);
  const cartMap = useSelector(cart.getHash);
  const cartId = useSelector(cart.getCartId);
  const trashIcon = useSelector(assets.getImg("icon_trash.svg"));

  const cartEntries = map(
    (id) => assoc("cartId", cartId, cartMap[id]),
    cartOrder
  );

  const emptyCart = () => dispatch(actions.cart.clearCart());
  const entries = mapIndexed(makeEntry, cartEntries);

  return (
    <ContentsWrapper>
      <header css={style.header}>
        <div css={style.leftHeader}>
          <h1
            css={style.title}
            data-testid="cartTitle"
          >{`Shopping Cart (${cartAmount})`}</h1>
        </div>
        <div css={style.rightHeader}>
          <img src={trashIcon} alt="trashcan" />
          <button onClick={emptyCart} css={style.emptyCart}>
            Empty Shopping Cart
          </button>
        </div>
      </header>
      <div css={style.columnWrap}>
        <div css={style.content}>{entries}</div>
        <div css={style.checkout}>
          <Checkout />
        </div>
      </div>
    </ContentsWrapper>
  );
};

Contents.displayName = "Contents";

Contents.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      label: PropTypes.func,
    })
  ),
};

const mapStateToProps = (state) => ({
  cartAmount: selectors.cart.getOrder(state).length,
});

const withEmptyCartSupport = branch(
  propEq("cartAmount", 0),
  renderComponent(EmptyCart)
);

export default compose(
  connect(mapStateToProps),
  withEmptyCartSupport
)(Contents);
