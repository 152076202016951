import { Fragment } from "react";
import { Capacity } from "../account-settings.subscriptions.common/subscription.capacity";
import { Price } from "../account-settings.subscriptions.common/subscription.price";
import { CancelButton } from "./_subscriptions.button.cancel";
import { OrgText } from "./_subscriptions.organization.text";
import { OrgTitle } from "./_subscriptions.organization.title";

export const MemberActions = ({ subscription: sub, cancel }) => (
  <Fragment>
    <Capacity>1</Capacity>
    <Price amount={sub.amount} period={sub.period} label={sub.description} />
    <CancelButton onClick={cancel} isMember={true}>
      Remove me from this subscription
    </CancelButton>
    <Fragment>
      <OrgTitle>Organization</OrgTitle>
      <OrgText>{sub.organizationName}</OrgText>
    </Fragment>
  </Fragment>
);
