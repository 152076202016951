import styled from "@emotion/styled";
import { buttonStyle } from "../account-settings.subscriptions.common/_style.button";
import { canBeDisabled } from "../account-settings.subscriptions.common/_style.button.can-be-disabled";
import { selectButtonStyle } from "../account-settings.subscriptions.common/_style.select.button";

export const AddButton = styled.button((props) => ({
  ...buttonStyle,
  ...selectButtonStyle,
  ...(canBeDisabled(props) || {}),
  gridArea: "button",
  maxHeight: "32px",
  paddingTop: "8px",
  marginTop: "21px",
}));
