import PropTypes from "prop-types";
import __ from "ramda/src/__";
import divide from "ramda/src/divide";
import pipe from "ramda/src/pipe";

const getFormattedNumber = (currency) =>
  pipe(
    divide(__, 100),
    new Intl.NumberFormat(undefined, {
      currency,
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format
  );

const Currency = (props) => {
  const { className, currency = "USD", dataTestId, type, quantity } = props;

  const amount =
    quantity === 0 ? "Free" : getFormattedNumber(currency)(quantity);

  const subscription = type === "subscription" ? " (Monthly Subscription)" : "";

  return (
    <span className={className} data-testid={dataTestId}>
      {amount}
      {subscription}
    </span>
  );
};

Currency.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  type: PropTypes.string,
  quantity: PropTypes.number.isRequired,
};

export default Currency;
