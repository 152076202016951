export default {
  loggedIn: false,
  loginError: "",
  loading: false,
  userDetails: {},
  cardInfo: {},
  cardError: "",
  useSavedCard: true,
  updateError: "",
  authToken: "",
  ort: "",
  tourViewed: true,
  registrationComplete: false,
  verificationError: "",
  changePasswordError: "",
  verified: false,
  settings: {},
  hasFetchedUserSettings: false,
  checkoutWindowData: {},
  removeCard: {
    success: false,
    loading: false,
    error: "",
  },
};
