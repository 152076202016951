import styled from "@emotion/styled";

export const Header = styled.div`
  color: #62738d;
  grid-area: header;
  margin: 10px;
  text-align: center;

  & h2 {
    font-size: 23px;
    text-transform: uppercase;
  }
`;
