const Parcel = ({ data = [] }) => (
  <ul className="doc-preview-summary__columns-list">
    {data.map((parcel, i) => (
      <li
        key={`${parcel}${i}`}
        className="doc-preview-summary__column-list-item"
      >
        {parcel}
      </li>
    ))}
  </ul>
);

export default Parcel;
