export const defaultWorkspaceSettings = {
  selectedDocuments: {},
  selectedDocumentsRanks: {},
  previewSettings: {
    zoom: 1,
    rotation: 0,
    offsetX: 0,
    offsetY: 0,
  },
  quickSearchState: {
    form: {
      isValid: true,
      isDisabled: false,
    },
    departments: {},
    searchTerm: {
      value: "",
    },
    dateRange: {
      value: "DefaultValue",
      isValid: true,
    },
    searchOcrText: {
      value: false,
    },
  },
  advancedSearchState: {},
};

export default {
  activeWorkspaceID: "search",
  tabs: {
    search: {
      id: "search",
      title: "New Search",
      isClosable: false,
      path: "/",
      type: "search",
      ...defaultWorkspaceSettings,
    },
  },
};
