import { types as syncTypes } from "@kofile/ko-search-action-types";
import compose from "ramda/src/compose";
import flip from "ramda/src/flip";
import lensPath from "ramda/src/lensPath";
import prepend from "ramda/src/prepend";
import prop from "ramda/src/prop";
import set from "ramda/src/set";
import take from "ramda/src/take";
import uniq from "ramda/src/uniq";
import { formatDateRange } from "../../utils";
import { search as initialState } from "../initialState";
import { handleActions } from "./utils";

export const updateSearch = (obj, list) =>
  compose(take(10), uniq, flip(prepend)(list))(obj);

export default handleActions(initialState, {
  [syncTypes.ADD_TO_SEARCH_HISTORY_0]: addToSearchHistory,
  "fetch-department-dates": fetchDepartmentDate,
  "error-fetching-department-dates": fetchDepartmentDateRejected,
  "department-dates": fetchDepartmentDateFulfilled,
});

function addToSearchHistory(state, { payload }) {
  return {
    ...state,
    history: updateSearch(payload, state.history),
  };
}

function fetchDepartmentDate(state) {
  return {
    ...state,
    datesLoading: true,
  };
}

function fetchDepartmentDateRejected(state) {
  return {
    ...state,
    datesLoading: false,
  };
}

const from = prop("from");
const to = prop("to");

function fetchDepartmentDateFulfilled(state, { payload }) {
  const {
    recorded,
    instrument,
    applicationDateRange: app,
    department,
  } = payload;

  const departmentDateRangeLens = lensPath([
    "departmentDateRanges",
    department,
  ]);

  const dateRanges = {
    recordedDateRange: formatDateRange(from(recorded), to(recorded)),
    instrumentDateRange: formatDateRange(from(instrument), to(instrument)),
    applicationDateRange: formatDateRange(from(app), to(app)),
    certifiedDate: payload.certDate,
  };

  const mergeNewState = (curState) => ({
    ...curState,
    datesLoading: false,
  });

  return compose(
    mergeNewState,
    set(departmentDateRangeLens, dateRanges)
  )(state);
}
