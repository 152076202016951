import format from "date-fns/format";
import PropTypes from "prop-types";

const DateTime = ({ className, dateTime, formatting, dataTestId }) => (
  <time className={className} dateTime={dateTime} data-testid={dataTestId}>
    {format(dateTime, formatting)}
  </time>
);

DateTime.displayName = "DateTime";
DateTime.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.instanceOf(Date),
  formatting: PropTypes.string,
};

export default DateTime;
