import isNil from "ramda/src/isNil";
import map from "ramda/src/map";
import propOr from "ramda/src/propOr";
import Tooltip from "rc-tooltip";
import { useSelector } from "react-redux";
import Radio from "../../components/Fields/RadioSet/Radio";
import { selectors } from "../../store";
import { mapIndexed } from "../../utils";
import { Container } from "./_search-scope.container";
import { TooltipContent } from "./_search-scope.tooltip";

const makeRadio = ({ tooltip, tooltipDisabled, ...props }, id) => {
  if (tooltipDisabled) {
    return <Radio key={id} {...props} />;
  }

  return (
    <Tooltip
      key={id}
      overlay={<TooltipContent>{tooltip}</TooltipContent>}
      placement="bottom"
    >
      <Radio {...props} />
    </Tooltip>
  );
};

makeRadio.displayName = "SelectSearchTypeRadio";

export const SelectSearchScope = ({ searchOcrText }) => {
  const tooltips = useSelector(selectors.configuration.getBasicSearchToolTips);
  const getTooltipText = (val) => propOr(null, val, tooltips);

  const radioOptions = [
    {
      id: "withoutOcr",
      value: "withoutOcr",
      label: "Search Index Only",
      tooltip: getTooltipText("indexOnly"),
    },
    {
      id: "withOcr",
      value: "withOcr",
      label: "Search Index & Full Text (OCR)",
      tooltip: getTooltipText("ocr"),
    },
  ];

  const mapPropsToOptions = (option) => {
    const value = searchOcrText.value ? "withOcr" : "withoutOcr";

    return {
      ...option,
      tooltipDisabled: isNil(option.tooltip),
      checked: option.value === value,
      onChange: searchOcrText.update,
    };
  };

  const optionsWithProps = map(mapPropsToOptions, radioOptions);
  const mappedRadios = mapIndexed(makeRadio, optionsWithProps);

  return <Container>{mappedRadios}</Container>;
};
