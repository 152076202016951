import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAsset } from "../../hooks/use-asset";
import { actions, selectors } from "../../store";
import { isPresent } from "../../utils";
import { Button } from "./_activate.button";
import { Container } from "./_activate.container";

const saveMessage = "In order to continue, you must have a saved credit card.";

export const Activate = ({ next }) => {
  const dispatch = useDispatch();
  const icon = useAsset("icon_subscription.svg");
  const cardInfo = useSelector(selectors.user.getCardInfo);
  const hasSavedPayment = isPresent(cardInfo);

  useEffect(() => {
    dispatch(actions.user.fetchUserCards());
  }, []);

  return (
    <Container>
      <img alt="subscription-icon" src={icon} />

      <h1>Activate Your Subscription</h1>

      <p>Make purchasing documents easier with subscription plans.</p>

      {hasSavedPayment && <Button onClick={next}>Activate Subscription</Button>}

      {!hasSavedPayment && <p>{saveMessage}</p>}
      {!hasSavedPayment && <Link to="/account/payment">Add Card To File</Link>}
    </Container>
  );
};
