import pathOr from "ramda/src/pathOr";
import { Status } from "../../constants";
import { handleActions } from "./utils";
import { isPresent } from "../../utils";

const { Init, Loading, Loaded, Error } = Status;

const initialState = {
  alerts: {
    fetchStatus: Init,
  },
  matches: {
    fetchStatus: Init,
  },
};

export default handleActions(initialState, {
  "create-a-fraud-alert": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      createStatus: Loading,
    },
  }),

  "fraud-alert-created": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      createStatus: Loaded,
    },
  }),

  "error-creating-fraud-alert": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      createStatus: Error,
    },
  }),

  "fetch-fraud-alerts": (state) => ({
    ...state,
    alerts: { ...state.alerts, activeList: [], fetchStatus: Loading },
  }),

  "fraud-alerts": (state, { payload }) => ({
    ...state,
    alerts: {
      ...state.alerts,
      activeList: payload.alerts,
      fetchStatus: Loaded,
    },
  }),

  "error-fetching-fraud-alerts": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      activeList: [],
      fetchStatus: Error,
    },
  }),

  "fetch-fraud-alert-match-records": (state) => ({
    ...state,
    matches: {
      ...state.matches,
      history: [],
      fetchStatus: Loading,
    },
  }),

  "fraud-alert-match-records": (state, { payload }) => ({
    ...state,
    matches: {
      ...state.matches,
      history: payload,
      fetchStatus: Loaded,
    },
  }),

  "error-fetching-fraud-alert-match-records": (state) => ({
    ...state,
    matches: {
      ...state.matches,
      history: [],
      fetchStatus: Error,
    },
  }),

  "delete-fraud-alert": (state, { payload }) => ({
    ...state,
    alerts: {
      ...state.alerts,
      deleteId: payload.id,
      deleteStatus: Loading,
    },
  }),

  "fraud-alert-deleted": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      activeList: state.alerts.activeList.filter(
        (a) => a.id !== state.alerts.deleteId
      ),
      deleteId: null,
      deleteStatus: Loaded,
    },
  }),

  "error-deleting-fraud-alert": (state) => ({
    ...state,
    alerts: {
      ...state.alerts,
      deleteStatus: Error,
    },
  }),

  "fraud-alert-criteria-for-document": (state, { payload }) => ({
    ...state,
    criteria: {
      data: isPresent(payload) ? payload : [],
      status: Loaded,
      errorMessage: "",
    },
  }),

  "error-fetching-fraud-alert-criteria-for-document": (state, { payload }) => ({
    ...state,
    criteria: {
      data: [],
      status: Error,
      errorMessage: pathOr("", ["reason", "message"], payload),
    },
  }),
});
