import { types as syncTypes } from "@kofile/ko-search-action-types";
import types from "../types/export-results";

export default {
  exportResults: (payload) => ({
    type: syncTypes.REQUEST_SEARCH_RESULT_EXPORT_1,
    payload,
  }),
  exportResultsFulfilled: (payload) => ({
    type: syncTypes.REQUEST_SEARCH_RESULT_EXPORT_FULFILLED_1,
    payload,
  }),
  exportResultsRejected: (payload) => ({
    type: syncTypes.REQUEST_SEARCH_RESULT_EXPORT_REJECTED_1,
    payload,
  }),
  cancelExportResults: (payload) => ({
    type: syncTypes.REQUEST_SEARCH_RESULT_EXPORT_CANCELLED_0,
    payload,
  }),
  clearExportResults: (payload) => ({ type: types.CLEAR, payload }),
};
