import { css } from "@emotion/react";
import { Cards, List } from "@kofile/icons";
import { Button } from "./_list-types.button";

const iconStyle = (active) => css`
  color: #14315d;
  font-weight: 400;
  width: 18px;
  ${active && `color: var(--color-neutral-lightest);`}
`;

export const ListTypeButton = ({ onClick, mode, active = true }) => {
  const Icon = { list: List, card: Cards }[mode];
  const toolTip = { list: "List View", card: "Card View" }[mode];

  return (
    <Button
      onClick={onClick}
      active={active}
      aria-label="change view"
      title={toolTip}
    >
      <Icon css={iconStyle(active)} />
    </Button>
  );
};
