import { format } from "date-fns";
import pick from "ramda/src/pick";
import propOr from "ramda/src/propOr";
import values from "ramda/src/values";
import {
  dateRangePresets,
  isNotNil,
  isPresent,
  notEmpty,
  parseDateRange,
} from ".";

export const formatDateRangeSingleRange = (dateRange) =>
  formatDateRange(dateRange);

const formatDateRange = (dateRange) => {
  if (dateRange.match(/\d{8},\d{8}/g)) {
    const { startDate, endDate } = parseDateRange(dateRange);
    const dateFormat = "MM/dd/yyyy";

    return `${format(startDate, dateFormat)}-${format(endDate, dateFormat)}`;
  }

  return dateRangePresets[dateRange] || dateRange;
};

export const formatAdvancedSearch = (query, partyFields = []) => {
  const {
    recordedDateRange = "",
    instrumentDateRange = "",
    applicationDateRange: appDateRange = "",
    partyNames = "",
    legalDescription = "",
  } = query;

  const partyFieldValues = values(pick(partyFields, query));
  const parties = [partyNames, ...partyFieldValues].filter(notEmpty);

  const searchText = [
    legalDescription,
    parties.join(", "),
    isPresent(recordedDateRange) ? formatDateRange(recordedDateRange) : "",
    isPresent(instrumentDateRange) ? formatDateRange(instrumentDateRange) : "",
    isPresent(appDateRange) ? formatDateRange(appDateRange) : "",
  ].filter(notEmpty);

  return searchText.join(" + ");
};

export const formatBasicSearch = (query) => {
  const {
    searchValue = "",
    recordedDateRange = "",
    instrumentDateRange = "",
    applicationDateRange = "",
  } = query;

  const searchText = [
    searchValue,
    isPresent(recordedDateRange) ? formatDateRange(recordedDateRange) : "",
    isPresent(instrumentDateRange) ? formatDateRange(instrumentDateRange) : "",
    isPresent(applicationDateRange) ? formatDateRange(applicationDateRange) : "",
  ].filter(notEmpty);

  return searchText.join(" + ");
};

const formatLandCornersSearch = (query) => {
  const parsed = JSON.parse(query.legals)[0];
  const lots = propOr("", "sectionLandcornerValue", parsed);
  const colon = lots.length > 0 ? ":" : "";

  let formattedLots = lots.split(",").join(", ");
  if (formattedLots.length > 23) {
    formattedLots = `${formattedLots.slice(0, 23)}...`;
  }

  return `${parsed.sectionTown} / ${parsed.sectionRange}${colon} ${formattedLots}`;
};

export const formatSearchText = (query, partyFields) => {
  const quickSearchType = "quickSearch";
  const { searchType = quickSearchType } = query;

  if (query.department === "LC" && isNotNil(query.legals)) {
    return formatLandCornersSearch(query);
  }

  return searchType === quickSearchType
    ? formatBasicSearch(query)
    : formatAdvancedSearch(query, partyFields);
};

export const toArray = (input) => {
  if (input === undefined) {
    return [];
  }

  if (Array.isArray(input)) {
    return input;
  }

  return [input];
};
