import { Fragment } from "react";
import { A11yAnnouncement } from "../../../components";

const Quicklink = () => (
  <Fragment>
    <A11yAnnouncement>Navigated to quicklink page</A11yAnnouncement>
    <h1>This is quicklink!</h1>
  </Fragment>
);

Quicklink.displayName = "Quicklink";

export default Quicklink;
