import map from "ramda/src/map";
import pathOr from "ramda/src/pathOr";
import { mapItemsForExport } from "../../components/NCourtPayment/utils";
import { ENTITY_TYPES } from "../../constants";
import { isPresent } from "../../utils";
import { capitalizeFirstLetter } from "../../utils/capitalize-first-letter";

const mapDocumentsForCheckout = (props) => {
  const { items, department, form, pageCount } = props;

  const includeSupportDoc = (type) => {
    return pathOr(false, [0, `include${capitalizeFirstLetter(type)}`], items);
  };

  return map(
    (doc) => ({
      type: "docimage",
      selectedPages: form.selectedPages,
      includeAttachments: form.includeAttachments,
      includeTranscription: includeSupportDoc("transcription"),
      includeTranslation: includeSupportDoc("translation"),
      isCertifiedCopy: form.certifiedCopy,
      deliveryMethod: "download",
      instrumentNumber: doc.instrumentNumber,
      totalPages: items.length === 1 ? pageCount : doc.pageCount,
      department: isPresent(doc.department) ? doc.department : department,
      id: doc.imageId,
      itemId: doc.imageId,
      docId: isPresent(doc.docId) ? doc.docId : doc.id,
      docType: isPresent(doc.docTypeCode) ? doc.docTypeCode : doc.docType,
    }),
    items
  );
};

export default (type, payload) => {
  const mapMethods = {
    [ENTITY_TYPES.DOCUMENT]: mapDocumentsForCheckout,
    [ENTITY_TYPES.EXPORT]: mapItemsForExport,
  };

  return mapMethods[type](payload);
};
