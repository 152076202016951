import equals from "ramda/src/equals";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectors } from "../../../../store";
import { ContactSupport } from "../../../../units/common/contact-support";
import { chooseViewFor } from "../../../../utils/choose-view-for";
import { keyMirror } from "../../../../utils/key-mirror";
import FreeCheckout from "./free";
import PaidCheckout from "./paid";

const Kind = keyMirror("Free", "Paid", "Invalid");
const isFree = equals(Kind.Free);
const isPaid = equals(Kind.Paid);
const isInvalid = equals(Kind.Invalid);

export const Checkout = () => {
  const totalPrice = useSelector(selectors.cart.getTotalPrice);
  const cart = useSelector(selectors.cart.getRegularCart);
  const cartIsEmpty = cart.byOrder.length === 0;
  const orderIsPaid = useSelector(selectors.checkout.getSuccess);

  let checkoutType = equals(totalPrice, 0) ? Kind.Free : Kind.Paid;

  if (cartIsEmpty && !orderIsPaid) {
    checkoutType = Kind.Invalid;
  }

  return chooseViewFor(checkoutType)
    .with({ isFree, isPaid, isInvalid })
    .when({
      isFree: () => <FreeCheckout />,
      isPaid: () => <PaidCheckout />,
      isInvalid: () => <Redirect to="/cart/contents" />,
      default: () => <ContactSupport />,
    });
};

export default Checkout;
