import { Arrow } from "@kofile/icons";
import Link from "../../components/Link";

const ReturnButton = () => (
  <Link to="/" className="return-button" openNewWorkspace>
    <Arrow className="return-button__arrow" id="return-button-arrow" />
    Return to Public Search
  </Link>
);

ReturnButton.displayName = "ReturnButton";

export default ReturnButton;
