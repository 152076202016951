import styled from "@emotion/styled";
import { buttonStyle } from "../account-settings.subscriptions.common/_style.button";

export const CancelChangeButton = styled.button`
  ${buttonStyle}
  grid-area: cancel-button;
`;

CancelChangeButton.defaultProps = {
  children: "Don't Change Subscription",
};
