import { Close } from "@kofile/icons";
import PropTypes from "prop-types";
import React from "react";

const clone = React.cloneElement;

const ModalShell = ({ close, children }) => (
  <div className="modal-shell">
    {clone(children, { close })}
    <button onClick={close} className="modal-shell__close-control">
      <Close
        id="modalshell-close-icon"
        className="modal-shell__close-control-icon"
      />
    </button>
  </div>
);

ModalShell.displayName = "ModalShell";
ModalShell.propTypes = {
  close: PropTypes.func,
  children: PropTypes.node,
};

export default ModalShell;
