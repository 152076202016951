import isBefore from "date-fns/isBefore";
import includes from "ramda/src/includes";
import { Checkbox } from "../../../../../components";

export const prependRows = ({ isSelected, ...doc }) => [
  <Checkbox
    id={`table-checkbox-${doc.id}`}
    key={doc.id}
    isChecked={Boolean(isSelected)}
    data-testid="searchResultCheckbox"
  />,
  { ...doc },
  { ...doc },
];

export const configureRow = (props) => (row) => {
  const {
    cartDocuments = [],
    selectedDocuments = [],
    viewedDocuments = [],
    activeDocID,
    documentsHash = {},
    certifiedDate,
    isDepartmentCertified,
    downloadIds = { downloaded: [], loading: [] },
  } = props;

  const { id } = row;
  const documentData = documentsHash[id] || {};
  const { recordedDate, imageId } = documentData;
  const isUncertified = isDepartmentCertified
    ? isBefore(certifiedDate, new Date(recordedDate))
    : false;

  const isActive = activeDocID === id;
  const isSelected = includes(id, selectedDocuments);
  const inCart = includes(id, cartDocuments);
  const viewed = includes(id, viewedDocuments);
  const isDownloading = includes(id, downloadIds.loading);
  const hasDownloaded = includes(id, downloadIds.downloaded);

  const fullRows = [
    ...prependRows({
      id,
      isSelected,
      inCart,
      imageId,
      viewed,
      document,
      isSecured: documentData.isSecured || false,
    }),
    ...row,
  ];

  return Object.assign(fullRows, {
    id,
    isSelected,
    inCart,
    isActive,
    isUncertified,
    viewed,
    isDownloading,
    hasDownloaded,
  });
};
