import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { hasLoaded } from "../../constants";
import { selectors } from "../../store";
import AttachmentsContext from "../doc-detail.common/attachments-context.js";
import DocumentContext from "../doc-detail.common/document-context";
import FeedbackForm from "../doc-detail.feedback";
import Navigation from "../doc-detail.navigation/index.jsx";
import useDisplayDocumentDetails from "../doc-detail.navigation/use-toggle-document-details";
import ViewDocumentImages from "../doc-detail.view-document.images";
import ViewDocumentSummary from "./document-summary";
import { main } from "./style";
import useAttachments from "./use-attachments.js";
import useViewDocument from "./use-view-document";

const DocDetail = () => {
  const document = useViewDocument();
  const attachments = useAttachments(document);
  const { showDetails, toggleDetails } = useDisplayDocumentDetails();
  const { configuration, announcements } = selectors;
  const showFeedback = useSelector(configuration.getFeedbackForm);
  const announcementPresent = useSelector(announcements.hasAnnouncements);

  return (
    <>
      <Helmet>
        <title>Document Preview</title>
      </Helmet>
      <DocumentContext.Provider value={document}>
        <AttachmentsContext.Provider value={attachments}>
          <section css={main.wrapper(showDetails, announcementPresent)}>
            <Navigation
              toggleDetails={toggleDetails}
              showDetails={showDetails}
            />

            <div css={main.images}>
              <ViewDocumentImages showDetails={showDetails} />
            </div>

            <ViewDocumentSummary showDetails={showDetails} />
          </section>

          {hasLoaded(document?.status) && showFeedback && <FeedbackForm />}
        </AttachmentsContext.Provider>
      </DocumentContext.Provider>
    </>
  );
};

export default DocDetail;
