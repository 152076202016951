import includes from "ramda/src/includes";
import not from "ramda/src/not";
import { useSelector, useDispatch } from "react-redux";
import { hasLoaded } from "../../constants";
import { selectors, actions } from "../../store";
import modalChild from "../../units/checkout.modal/modal";
import useDocument from "../doc-detail.common/useDocument";

export default ({ modal }) => {
  const dispatch = useDispatch();
  const document = useDocument();
  const { pageCount } = document;

  const { configuration, cart } = selectors;
  const cartID = useSelector(cart.getCartId);
  const cartDocs = useSelector(cart.getRegularCartDocs);
  const deptActions = useSelector(configuration.getDepartmentActions);

  const { id, department, currentPage } = document;
  const inCart = includes(id, cartDocs);
  const noImages = document?.urls?.length === 0 && !document?.hidePreview;
  const isSecured = document?.isSecured ?? false;
  const hasNotLoaded = not(hasLoaded(document.status));
  const isVisible = includes("addToCart", deptActions);

  const addDocToCart = (e) => {
    e.preventDefault();

    const payload = { cartID, department, itemIDs: [id] };

    dispatch(actions.user.addDocumentsToCart(payload));

    const modalProps = {
      currentPage,
      pageCount: pageCount,
      close: modal.close,
    };

    return modal.open(modalChild(modalProps));
  };

  return {
    addDocToCart,
    text: inCart ? "Document in Cart" : "Add to Cart",
    isVisible,
    disabled: hasNotLoaded || noImages || isSecured || inCart,
  };
};
