import { css } from "@emotion/react";

const style = (mirrored) => css`
  transform: ${mirrored ? "scaleX(-1)" : "none"};
`;

const A11yIcon = ({ className, alt, src, mirrored }) => (
  <img
    css={style(mirrored)}
    alt={alt}
    src={src}
    className={className}
    tabIndex="-1"
  />
);

export default A11yIcon;
