import styled from "@emotion/styled";
import { colors } from "../../utils";
const { white } = colors;

const disabled = `
  opacity: 0.5;
  pointer-events: none;
  & input {
    background: ${white};
  }
`;

const isOpen = `
  & .react-datepicker__input-container > input {
    color: ${white};
  }
`;

export const Container = styled.div`
  background: ${white};
  border: 1px solid rgba(4, 29, 68, 0.1);
  box-sizing: border-box;
  position: relative;
  grid-area: date-ranges;

  .loading__spinner {
    flex: 0 0 30px;
  }

  ${(props) => props.disabled && disabled}
  ${(props) => props.isOpen && isOpen}
`;
