import { types as syncTypes } from "@kofile/ko-search-action-types";
import { useAPI } from "../../../../hooks/use-api";

export const useClearSearchHistory = () =>
  useAPI({
    send: syncTypes.CLEAR_SEARCH_HISTORY_0,
    listenFor: {
      success: syncTypes.CLEAR_SEARCH_HISTORY_FULFILLED_0,
      failure: syncTypes.CLEAR_SEARCH_HISTORY_REJECTED_0,
    },
  });
