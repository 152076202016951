import { types as syncTypes } from "@kofile/ko-search-action-types";
import values from "ramda/src/values";
import { ofType } from "redux-observable";
import { merge, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { notifications, cart, user } from "../actions";

export const notifyUserEpics$ = {
  signInFulfilled: (action$) =>
    action$.pipe(
      ofType(syncTypes.SIGN_IN_FULFILLED_1),
      mergeMap((action) => {
        const notification = ({ payload }) => {
          const firstName = payload.userDetails.firstName;

          return notifications.addNotification({
            message: `Welcome back, ${firstName}!`,
          });
        };

        return merge(
          of(notification(action), cart.fetch(), cart.makeCartFresh())
        );
      })
    ),

  signOutFulfilled: (action$) =>
    action$.pipe(
      ofType(syncTypes.SIGN_OUT_FULFILLED_1),
      mergeMap(() =>
        of(
          notifications.addNotification({
            message: "You have successfully been signed out.",
          }),
          cart.makeCartStale()
        )
      )
    ),

  updateInfoFulfilled: (action$) =>
    action$.pipe(
      ofType("user-updated"),
      map(() =>
        notifications.addNotification({
          message: "You have successfully modified your account!",
        })
      )
    ),

  resetPasswordFulfilled: (action$) =>
    action$.pipe(
      ofType(syncTypes.REGISTER_FULFILLED_1),
      map(() =>
        notifications.addNotification({
          message: "Registration is complete.",
        })
      )
    ),

  changePasswordFulfilled: (action$) =>
    action$.pipe(
      ofType("password-changed"),
      map(() =>
        notifications.addNotification({
          message: "Your password has been successfully updated.",
        })
      )
    ),
};

export const clearCardInfo$ = {
  requestCheckout: (action$) =>
    action$.pipe(
      ofType(syncTypes.REQUEST_CHECKOUT_0),
      map(() => user.clearCardInfo())
    ),

  requestExpressCheckout: (action$) =>
    action$.pipe(
      ofType(syncTypes.REQUEST_EXPRESS_CHECKOUT_0),
      map(() => user.clearCardInfo())
    ),
};

export const removeSavedCard$ = {
  refetchCards: (action$) =>
    action$.pipe(
      ofType(syncTypes.REMOVE_SAVED_CARD_FULFILLED_0),
      map(() => user.fetchUserCards())
    ),
};

export default [
  ...values(notifyUserEpics$),
  ...values(clearCardInfo$),
  ...values(removeSavedCard$),
];
