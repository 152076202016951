import either from "ramda/src/either";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { hasError, hasLoaded, isLoading } from "../../constants/status";
import { useNotifications } from "../../hooks/use-notifications";
import { selectors } from "../../store";
import ConfirmActionModal from "../account-settings.common/ConfirmActionModal";
import { useRemoveMember } from "../account-settings.common/use-remove-member";

const notification = {
  error: "An error occurred while trying to remove you from this subscription.",
  success:
    "You've been removed from this subscription. A confirmation email has been sent.",
};

const shouldBeDisabled = either(isLoading, hasLoaded);

export const RemoveModal = ({ subscription, close, onCancel }) => {
  const { initiate: removeMember, error, status } = useRemoveMember();
  const pushNotification = useNotifications();
  const userDetails = useSelector(selectors.user.getUserDetails);
  const isDisabled = shouldBeDisabled(status);

  const cancel = () =>
    void removeMember({
      subscriptionId: subscription.id,
      userId: userDetails.id,
    });

  const onSuccess = (updatedSubList) => {
    pushNotification({ message: notification.success });
    onCancel(updatedSubList);
    close();
  };

  useEffect(() => {
    if (hasLoaded(status)) {
      onSuccess([]);
    }
  }, [status]);

  useEffect(() => {
    if (hasError(status)) {
      pushNotification({ message: notification.error });
      close();
    }
  }, [error]);

  const content = (
    <Fragment>
      <p>
        <strong>
          Are you sure you want to remove yourself from this monthly
          subscription?
        </strong>
      </p>
      <p>Your subscription will be cancelled immediately.</p>
    </Fragment>
  );

  const confirmationProps = {
    title: "Cancel Subscription",
    loading: isDisabled,
    content,
    error,
    cancel: { onClick: close, text: "Don't Remove Me" },
    action: {
      onClick: cancel,
      text: "Remove Me",
      disabled: isDisabled,
    },
  };

  return <ConfirmActionModal {...confirmationProps} />;
};
