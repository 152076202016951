import { useSelector } from "react-redux";
import { CheckboxField } from "../../components/Fields";
import { selectors } from "../../store";
import { getPriceFromCents } from "../../utils";
import { Text } from "./_purchase-certified-copy.text";

const PurchaseCertifiedCopy = (props) => {
  const {
    onChange,
    isChecked,
    certificationFeeSubtotal,
    disabled = false,
  } = props;

  const { configuration: config, workspaces } = selectors;
  const certifiedDocsEnabled = useSelector(config.certifiedDocsEnabled);
  const externalCertProvider = useSelector(config.getExternalCertProvider);
  const onlyCertifiedCheckout = useSelector(config.onlyCertifiedCheckout);
  const pricePerCertification = useSelector(
    workspaces.department.pricePerCertification
  );

  const checkboxLabel = certificationFeeSubtotal
    ? `Purchase Electronic Certified Copy (${getPriceFromCents(
        certificationFeeSubtotal
      )})`
    : `Purchase Electronic Certified Copy (${getPriceFromCents(
        pricePerCertification
      )} per doc)`;

  if (!certifiedDocsEnabled || externalCertProvider.enabled) return null;

  if (onlyCertifiedCheckout) return <Text>{checkboxLabel}</Text>;

  const checkboxProps = {
    id: "purchase-certified-copy",
    checkboxLabel,
    labelPosition: "right",
    onChange,
    isChecked,
    disabled,
  };

  return <CheckboxField {...checkboxProps} />;
};

export default PurchaseCertifiedCopy;
