// feat: usePaymentMethods
//  TODO delete

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import withKeys from "../../hoc/withKeys";
import { selectors } from "../../store";

const Stripe = ({ children, keys }) => {
  const isStripeEnabled = useSelector(selectors.configuration.isStripeEnabled);

  if (isStripeEnabled) {
    return (
      <StripeProvider apiKey={keys.stripe}>
        <Elements>{children}</Elements>
      </StripeProvider>
    );
  }

  return children;
};

Stripe.displayName = "Stripe";

Stripe.propTypes = {
  children: PropTypes.node,
  keys: PropTypes.shape({
    stripe: PropTypes.string,
  }),
};

Stripe.defaultProps = {
  keys: {
    stripe: "",
  },
};

export default withKeys(Stripe);
