// mod: doc-preview

import { A11yTable } from "@kofile/react-a11y-table";
import { parse, stringify } from "query-string";
import compose from "ramda/src/compose";
import map from "ramda/src/map";
import mergeDeepRight from "ramda/src/mergeDeepRight";
import { createElement } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  withModalRerenderBlock,
  withWorkspaceId,
  withSearchResultsProps,
  withModal,
} from "../../../../../hoc";
import { actions, selectors } from "../../../../../store";
import { mapIndexed } from "../../../../../utils";
import { makePrependColumns, shapeConfig } from "./configure.columns";
import { configureRow } from "./configure.rows";
import { getBaseConfig } from "./configure.table";

const mapStateToProps = (state, { location }) => {
  const { department } = parse(location.search);
  const { assets, cart, configuration, docPreview, workspaces } = selectors;
  const documentsHash = selectors.documents.getActiveWorkspaceDataHash(state);
  const cartDocuments = cart.getRegularCartDocs(state);
  const viewedDocuments = docPreview.getViewed(state);
  const partyFields = configuration.getDeptParties(state);
  const config = configuration.getConfigForDepartment(state);
  const activeDocID = docPreview.getActiveDocumentID(state);
  const shoppingCartId = cart.getCartId(state);
  const tooltipIsOpen = selectors.tooltip.getIsOpen(state);
  const certifiedDate = selectors.search.getCertifiedDate(state, department);
  const listViewIcon = "icon_listview_secure_doc.svg";
  const isSecuredIcon = assets.getImg(listViewIcon, state);
  const selectedDocuments = workspaces.getSelectedDocumentsKeys(state);
  const isDepartmentCertified = configuration.getIsCertified(state, department);
  const downloadIds = selectors.download.getDownloadIds(state);
  const newDocPreviewEnabled = configuration.isNewDocPreviewEnabled(state);

  return {
    selectedDocuments,
    cartDocuments,
    viewedDocuments,
    configuration: config,
    documentsHash,
    department,
    activeDocID,
    shoppingCartId,
    tooltipIsOpen,
    certifiedDate,
    isDepartmentCertified,
    isSecuredIcon,
    partyFields,
    downloadIds,
    newDocPreviewEnabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRowSelectionChanged: ({ id, document, isSelected }) => {
    const action = isSelected
      ? actions.workspaces.addSelectedDocument({ id, [id]: document })
      : actions.workspaces.removeSelectedDocument(id);

    dispatch(action);
  },
  fetch: (payload) => dispatch(actions.documents.fetch(payload)),
  dispatch,
  updateQuery: (updatedQuery) => {
    const search = stringify(updatedQuery);
    const { workspaceID, history } = ownProps;

    history.push({ pathname: "/results", search, state: { workspaceID } });
    dispatch(actions.documents.fetch({ query: updatedQuery, workspaceID }));
  },
});

const mergeTableProps = (stateProps, dispatchProps, ownProps) => {
  const { configuration } = stateProps;

  const fullProps = {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
  };

  const prependColumns = makePrependColumns(fullProps);
  const fullColumnConfig = [...prependColumns, ...configuration.viewConfig];
  const baseConfig = getBaseConfig(fullProps);
  const cellConfig = mapIndexed(shapeConfig, fullColumnConfig);
  const fullConfig = mergeDeepRight(baseConfig, { data: cellConfig });

  const data = map(configureRow(fullProps), ownProps.results);

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    config: fullConfig,
    data,
  };
};

const ListView = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeTableProps),
  withModal,
  withModalRerenderBlock
)((props) => createElement(A11yTable, { ...props, key: props.department }));

ListView.displayName = "ListView";

export { mapStateToProps, mapDispatchToProps, mergeTableProps };

export default compose(
  withRouter,
  withWorkspaceId,
  withSearchResultsProps
)(ListView);
