import { css } from "@emotion/react";

const style = css`
  font-size: 15px;
  grid-area: capacity;
  align-self: center;
`;

export const Capacity = ({ children }) => (
  <span css={style}>{`${children} ${
    String(children) === "1" ? "User" : "Users"
  }`}</span>
);
