import propEq from "ramda/src/propEq";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasError, hasLoaded } from "../../../../constants";
import { withModal } from "../../../../hoc";
import { actions, selectors } from "../../../../store";
import downloadInIframe from "../../../../units/doc-detail.view-document/download-in-iframe";
import { isNotNil } from "../../../../utils";
import Modal from "./download.error-modal";

export const useShouldDownload = ({ docId, modal }) => {
  const dispatch = useDispatch();
  const { getDownload } = selectors.download;
  const { download: dl } = actions;
  const { error, status, url, ...download } = useSelector(getDownload(docId));

  useEffect(() => {
    const userIsUnverified =
      hasError(status) && propEq("userIsUnverified", true, download);

    if (userIsUnverified) {
      modal.open(
        Modal({
          errorMessage: error,
          clearDownload: () => dispatch(dl.clear({ docId })),
          close: modal.close,
        })
      );
    }
  }, [status, error, download, modal, dispatch, dl, docId]);

  useEffect(() => {
    if (isNotNil(url) && hasLoaded(status)) {
      downloadInIframe(url);
      dispatch(dl.downloadComplete({ docId }));
    }
  }, [url, status, docId, dispatch, dl]);
};

export default withModal(useShouldDownload);
