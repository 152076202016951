import defaultTo from "ramda/src/defaultTo";
import has from "ramda/src/has";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import lensProp from "ramda/src/lensProp";
import pipe from "ramda/src/pipe";
import set from "ramda/src/set";

export const ensureFieldCollection = (name) =>
  ifElse(pipe(defaultTo({}), has(name)), identity, set(lensProp(name), []));
