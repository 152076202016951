import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAPI } from "../../hooks";
import { useSkipCoverPage } from "../../hooks/use-skip-cover-page";
import { actions } from "../../store";
import { isAbsent, isPresent } from "../../utils";
import useOcr from "./use-ocr";
import useTranscriptionTranslation from "./use-transcription-translation";

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { set, clearImages, transcription, translation } =
    useTranscriptionTranslation(id);

  const [currentPage, setCurrentPage] = useState(0);
  const [shouldRefetchImages, setShouldRefetchImages] = useState(false);

  const { data, initiate, status } = useAPI({
    send: "fetch-a-document",
    listenFor: { success: "document", failure: "error-fetching-document" },
  });

  const ocr = useOcr(data, currentPage);

  const shouldSkipCover = useSkipCoverPage(data);

  useEffect(() => {
    shouldSkipCover && isAbsent(data?.ocrHits)
      ? setCurrentPage(1)
      : setCurrentPage(0);
  }, [shouldSkipCover]);

  useEffect(() => {
    if (isPresent(data?.ocrHits) && ocr.hasOcrHits) {
      const pageHasHits = (page) => page.length > 0;
      const firstHitsPage = data?.ocrHits?.pages.findIndex(pageHasHits);
      setCurrentPage(firstHitsPage);
    }
  }, [data?.ocrHits]);

  useEffect(() => {
    dispatch(actions.docPreview.reset());
    dispatch(actions.docPreview.setActiveDoc(Number(id)));
    dispatch(actions.docPreview.addViewed(Number(id)));
  }, [id]);

  useEffect(() => {
    const query = ocr.highlight ? { query: ocr.highlight } : {};

    initiate({ id: Number(id), ...query });
    clearImages();
    setShouldRefetchImages(true);
    ocr.setHitIndex(0);
    ocr.setHitMapIndex(0);
  }, [id]);

  return {
    ...data,
    clearAdditionalImages: clearImages,
    currentPage,
    setAdditionalImages: set,
    setCurrentPage,
    setShouldRefetchImages,
    shouldRefetchImages,
    status,
    ...ocr,
    transcription,
    translation,
  };
};
