import prop from "ramda/src/prop";
import { createSelector } from "reselect";
import { getActiveWorkspaceID } from "./get-active-workspace-id";
import { getTabs } from "./get-tabs";

export const getActiveWorkspaceSettings = createSelector(
  getActiveWorkspaceID,
  getTabs,
  prop
);
