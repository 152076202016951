import { Copy } from "./_pay.plan-info.copy";
import { Title } from "./_pay.plan-info.title";

export const PlanInfo = ({ plan }) => (
  <div>
    <Title>{plan.title}</Title>
    <Copy>
      {plan.priceLabel.amount}
      {plan.priceLabel.period} subscription
    </Copy>
    {plan.priceLabel.details && <Copy>{plan.priceLabel.details}</Copy>}
  </div>
);
