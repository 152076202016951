import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { CountyFusion } from "./county-fusion";
import { styles } from "./styles";

export const Connections = () => {
  const isCountyFusionEnabled = useSelector(
    selectors.configuration.getCountyFusionIsEnabled
  );

  if (!isCountyFusionEnabled) {
    return null;
  }

  return (
    <section css={styles.container}>
      <h3>Connections</h3>
      <CountyFusion />
    </section>
  );
};
