import classnames from "classnames";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { LoadingSpinner } from "../../components";

export const Thumbnail = (props) => {
  const { src, openPreview, className, isCertifiedCopy } = props;
  const buttonClass = classnames("thumbnail", className);
  const spinner = <LoadingSpinner />;

  const image = (
    <LazyLoad once offset={150} height={150} placeholder={spinner}>
      <img className="thumbnail__image" alt="thumbnail" src={src} />
    </LazyLoad>
  );

  const placeholder = (
    <div className="thumbnail__no-image">VIEW DOCUMENT SUMMARY</div>
  );

  return (
    <button className={buttonClass} onClick={openPreview}>
      {isCertifiedCopy && (
        <div className="thumbnail__overlay">
          <div className="thumbnail__certified">CERTIFIED COPY</div>
        </div>
      )}
      {src ? image : placeholder}
    </button>
  );
};

Thumbnail.displayName = "Thumbnail";

Thumbnail.propTypes = {
  src: PropTypes.string,
  isCertifiedCopy: PropTypes.bool,
};

Thumbnail.defaultProps = {
  src: "",
  isCertifiedCopy: false,
};

export default Thumbnail;
