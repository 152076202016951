import addIndex from "ramda/src/addIndex";
import always from "ramda/src/always";
import anyPass from "ramda/src/anyPass";
import complement from "ramda/src/complement";
import cond from "ramda/src/cond";
import curry from "ramda/src/curry";
import either from "ramda/src/either";
import identity from "ramda/src/identity";
import is from "ramda/src/is";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import map from "ramda/src/map";
import pickBy from "ramda/src/pickBy";
import reject from "ramda/src/reject";
import T from "ramda/src/T";
import { matchPath } from "react-router";

/**
 * Check if a value is *not* nil-like
 *
 * @see http://ramdajs.com/docs/#isNil
 *
 * @param {*} value any value that may or may not be nil-like
 * @returns {boolean} true if the value is NOT nil-like; false otherwise
 */
export const isNotNil = complement(isNil);

/**
 * Check if a value is *not* empty.
 *
 * @see http://ramdajs.com/docs/#isEmpty
 *
 * @param {*} value any value that may or may not be empty
 * @returns {boolean} true if the value is NOT empty; false otherwise
 */
export const notEmpty = complement(isEmpty);

/**
 * A version of Ramda map that also returns the index during iteration. The
 * signature of the callback will be (current, index).
 *
 * @param {*[]} array
 * @returns {*[]} an array
 */
export const mapIndexed = addIndex(map);

/**
 * A function that does nothing and produces no side-effects.
 *
 * @param {*}
 * @returns {void}
 */
export const noop = function noop() {
  return undefined;
};

/**
 * Returns a new array with all of the empty or nil-like values removed.
 *
 * @see http://ramdajs.com/docs/#isEmpty
 * @see http://ramdajs.com/docs/#isNil
 *
 * @param {*[]} array
 * @returns {*[]} new array with empty and nil-like values removed
 */
export const compact = reject(either(isEmpty, isNil));

/**
 * Will return a copy of an object with empty keys removed.
 *
 * @see notEmpty
 *
 * @param {Object}
 * @returns {Object} new object with empty keys removed
 */
export const omitEmpty = pickBy(notEmpty);

/**
 * A function that returns whether or not it was called with arguments.
 *
 * @example
 * ```javascript
 * noArgs()     //=> true
 * noArgs(null) //=> false
 * noArgs(1)    //=> false
 * ```
 *
 * @returns {boolean}
 */
export const noArgs = function hasNoArgs() {
  return arguments.length === 0;
};

/**
 * A function that will wrap a value inside a new array, unless that value is
 * already an array. If the value provided is already an array, the given value
 * is returned.
 *
 * If called with no arguments, a new empty array is returned.
 *
 * @param {?*}
 * @returns {Array}
 */
export const castArray = cond([
  [noArgs, always([])],
  [is(Array), identity],
  [T, (value) => [value]],
]);

/**
 * Returns true if the input is *either* empty *or* nil-like. False otherwise.
 *
 * @see http://ramdajs.com/docs/#isNil
 * @see http://ramdajs.com/docs/#isEmpty
 *
 * @param {*}
 * @returns {boolean}
 */
export const emptyOrNil = anyPass([isNil, isEmpty]);

/**
 * Returns true if the input is *neither* empty *or* nil-like. False otherwise.
 *
 * @see emptyOrNil
 *
 * @param {*}
 * @returns {boolean}
 */
export const notEmptyOrNil = complement(emptyOrNil);

/**
 * Checks if two strings are equal. Ignores case. Curried.
 *
 * @param {string} a
 * @return {(b: string) => boolean}
 */
export const equalsInsensitive = curry(
  (a, b) => String(a).toLowerCase() === String(b).toLowerCase()
);

/**
 * Takes a url string and parses it into pathname and search
 *
 * @param {string} path
 * @return {(path: string) => Object}
 */
export const parseUrl = (path = "") => {
  const splitPath = path.split("?");
  const basePath = splitPath[0] || "";
  const params = splitPath[1] || "";
  const search = params && `?${params}`;

  return {
    pathname: basePath,
    search,
  };
};

/**
 * Converts offset and limit to page
 *
 * @param {number} offset
 * @param {number} path
 * @return {(offset: number, limit: number) => number}
 */
export const getPage = (offset, limit) => Math.floor(offset / limit) + 1;

/**
 * Checks if full path has root of basePath
 *
 * @param {string} basePath
 * @param {string} currentPath
 * @return {(basePath: string, currentPath: string) => boolean}
 */
export const matchesBasePath = curry((basePath, currentPath) =>
  Boolean(
    matchPath(currentPath, {
      path: basePath,
      exact: false,
      strict: false,
    })
  )
);

/**
 * Checks if full path has root of one of paths in list
 *
 * @param {Array.<string>} pathList
 * @param {string} currentPath
 * @return {(pathList: Array.<string>, currentPath: string) => boolean}
 */
export const matchesBasePathInList = curry((pathList, path) =>
  pathList.reduce(
    (acc, workspacePath) => acc || matchesBasePath(workspacePath, path),
    false
  )
);

/**
 * splits comma separated string. defaults empty string to empty array
 *
 * @return {(string) => Array.<string>}
 */
export const splitWithDefault = (str) => (isEmpty(str) ? [] : str.split(","));

// https://davidwalsh.name/javascript-debounce-function
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
