import { css } from "@emotion/react";

const iconWhiteToGreenFilter = `
  filter: invert(74%) sepia(63%) saturate(244%) hue-rotate(118deg) brightness(250%) contrast(94%);
`;

const anchorStyle = `
  margin-left: 5px;
  color: #2b82da;
  font-weight: 400;
`;

export default {
  headerLinkContainer: css`
    padding-top: 15px;
  `,
  headerAnchor: css`
    ${anchorStyle}
  `,
  headerLink: css`
    color: #62738d;
    display: inline;

    & a {
      ${anchorStyle}
    }
  `,

  card: {
    icon: (isDoc) => css`
      height: 100px;
      padding-bottom: 15px;

      ${isDoc && iconWhiteToGreenFilter}
    `,
    heading: css`
      font-size: 20px;
      font-weight: 700;
      padding: 10px 0;
    `,
    blurb: css`
      color: #4a5f7d;
      line-height: 1.3;
    `,
    link: css`
      display: block;
      width: 100%;
      background-color: #52c3ac;
      border-radius: 3px;
      color: var(--color-neutral-lightest);
      font-size: 20px;
      margin: 30px 0;
      padding: 10px 20px;
    `,
  },
};
