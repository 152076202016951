import PropTypes from "prop-types";
import { DropdownMenu } from "../../../../../../../components";
import { CheckIcon } from "./components";

const Dropdown = (props) => {
  const activeOption =
    props.options.find(({ key }) => key === props.activeOption) || {};

  const optionRenderer = ({ name, action, key }) => (
    <button onClick={action} className="sort-dropdown__option">
      {name}
      <CheckIcon isActive={key === props.activeOption} />
    </button>
  );

  return (
    <div className="sort-dropdown">
      <div className="sort-dropdown__label">{props.label}</div>
      <DropdownMenu
        label={activeOption.name}
        options={props.options}
        optionRenderer={optionRenderer}
      />
    </div>
  );
};

Dropdown.displayName = "Dropdown";

Dropdown.propTypes = {
  label: PropTypes.string,
  activeOption: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      action: PropTypes.func,
    })
  ),
};

Dropdown.defaultProps = {
  options: [],
  label: "",
};

export default Dropdown;
