import { css } from "@emotion/react";
import prop from "ramda/src/prop";
import { useContext } from "react";
import { useSelector } from "react-redux";
import subscriptionsContext from "../../App/screens/Account/SettingsView/views/subscriptions-context";
import { ConfiguredHTMLContent } from "../../components";
import { getImg } from "../../store/selectors/assets";
import { isAbsent } from "../../utils";
import { Loading } from "../account-settings.subscriptions.common/loading";
import { DownloadApplicationDoc } from "../account-settings.subscriptions.signup/application.submit.download";
import { useApplicationContent } from "../account-settings.subscriptions.signup/use-fetch-application-content";

const style = {
  container: css`
    display: grid;
    grid-template-areas:
      "info   "
      "support"
      "content"
      "download";
    grid-template-columns: 1fr;
  `,
  support: css`
    grid-area: support;
    margin-top: 20px;
  `,
  warn: css`
    border-radius: 4px;
    border: 1px solid grey;
    background-color: var(--color-neutral-lightest);
    padding: 20px;
    grid-area: info;
    line-height: 1.5;

    & img {
      padding-right: 10px;
    }
  `,
  content: "grid-area: content;",
};

export const PendingOverview = () => {
  const { subscriptions } = useContext(subscriptionsContext);
  const { data, loading, hasError } = useApplicationContent("confirmation");
  const warnIcon = useSelector(getImg("icon_warning_triangle_yellow.svg"));

  if (loading || isAbsent(subscriptions)) return <Loading />;

  const sub = subscriptions.filter((sub) => sub.status === "pending")[0];

  return (
    <section css={style.container}>
      <p css={style.warn}>
        <img src={warnIcon} alt="You have submitted a pending application." />
        You have a pending {prop("title", sub)} subscription for{" "}
        {prop("amount", sub)} per {" " + prop("period", sub)}. Please follow the
        instructions below to complete the application process.
      </p>
      <p css={style.support}>
        If you need to make changes or cancel this pending subscription type
        please contact support.
      </p>

      {hasError && (
        <p>
          There&apos;s been an error fetching subscription application details.
          Please try again later or contact support.
        </p>
      )}

      <ConfiguredHTMLContent
        customStyle={style.content}
        html={prop("html", data)}
      />

      <DownloadApplicationDoc
        url={prop("url", data)}
        copy={prop("urlCardCopy", data)}
      />
    </section>
  );
};
