import times from "ramda/src/times";

const Divider = (i) => <div key={i} className="table-placeholder__divider" />;

const TablePlaceholder = () => (
  <div className="table-placeholder">
    <div className="table-placeholder__timeline-item">
      <div className="table-placeholder__header" />
      <div className="table-placeholder__table">
        <div className="table-placeholder__animated-background">
          <div className="table-placeholder__pillar-wrapper">
            <div className="table-placeholder__divider-wrap">
              {times(Divider, 20)}
            </div>
            <div className="table-placeholder__border-pillar-upright-left" />
            <div className="table-placeholder__border-pillar-upright-right" />
            <div className="table-placeholder__border-pillar-top" />
            <div className="table-placeholder__border-pillar-bottom" />
            <div className="table-placeholder__pillar-left" />
            <div className="table-placeholder__pillar-middle" />
            <div className="table-placeholder__pillar-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

TablePlaceholder.displayName = "TablePlaceholder";

export default TablePlaceholder;
