import { ThemeProvider } from "@emotion/react";
import "./polyfill-globalthis";
import "./utils/polyfills";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import createStore from "./App/store";
import createHistory from "./helpers/history";
import mkSocket from "./socket";
import { actions } from "./store";
import ErrorBoundary from "./units/common/error.boundary";
import { notEmptyOrNil } from "./utils";

const socket = mkSocket({
  url: `wss://${window.location.host}/ws`,
  forceReconnect: true,
});

const dest = document.getElementById("content");
const history = createHistory();
const hydratedState = window.__data || {};
const [store] = createStore({ history, socket, window }, hydratedState);

globalThis.socket = socket;
store.dispatch(actions.initialization.beforeLoad());

window.addEventListener("notify", (e) => {
  store.dispatch(
    actions.notifications.addNotification({ message: e.detail.msg })
  );
});

const BugSnagErrorBoundary = createBugSnagErrorBoundary(
  store.getState().keys["bugsnag-key"],
  process.env.APP_VERSION
);

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    render(require("./App").default);
  });
}

function render(AppComponent) {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.AXE_ENABLED === "t"
  ) {
    require("@axe-core/react")(React, ReactDOM, 1000);
  }

  const component = (
    <BugSnagErrorBoundary>
      <ThemeProvider theme={window.__cssVars}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppComponent />
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </BugSnagErrorBoundary>
  );

  ReactDOM.hydrate(component, dest);
}

function createBugSnagErrorBoundary(key, version) {
  if (process.env.NODE_ENV === "production" && notEmptyOrNil(key)) {
    try {
      const bugsnagClient = window.bugsnag({
        apiKey: key,
        appVersion: version,
      });

      return bugsnagClient.use(window.bugsnag__react(React));
    } catch (_) {
      return ErrorBoundary;
    }
  }

  return ErrorBoundary;
}
