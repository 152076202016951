import { css } from "@emotion/react";
import propOr from "ramda/src/propOr";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { withModal } from "../../../../../../hoc";
import { selectors } from "../../../../../../store";
import { SubmitButton } from "../../button.submit";
import { FormContainer } from "../../form.container";
import { SettingsPane } from "../../settings-pane";
import { EmailVerification } from "./email-verification";
import PropertyAlertContactSettings from "./property-alert-contact-settings";
import { useUpdateProfile } from "./use-update-profile";
import UserInfo from "./user-info";

const styles = {
  formError: css`
    color: red;
    font-size: 1rem;
    padding-top: 20px;
    height: 20px;
  `,
};

export const Profile = ({ modal }) => {
  const user = useSelector(selectors.user.getUserDetails);
  const { formData, handleSubmit, formError, isLoading, handleInputChange } =
    useUpdateProfile(modal);

  useEffect(() => void htmx.process(document.body), []);

  return (
    <SettingsPane title="Profile">
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <UserInfo
            formData={formData}
            handleInputChange={handleInputChange}
            email={user?.email}
          />

          <EmailVerification
            email={user?.email}
            isVerified={user?.isVerified}
          />

          <PropertyAlertContactSettings
            formData={formData}
            handleInputChange={handleInputChange}
            isLoading={isLoading}
          />

          <div css={styles.formError}>{formError}</div>
          <SubmitButton>
            {isLoading ? "Loading..." : "Update Profile"}
          </SubmitButton>
        </FormContainer>
      </form>
    </SettingsPane>
  );
};

export const PATHS = {
  ALERT_METHODS: "alertMethods",
  CONTACT_PREFERENCES: "contactPreferences",
  CONSENT_TO_CONTACT: "consentToContact",
};

export function getAlertMethodsHave(settings) {
  const alertMethods = propOr([], PATHS.CONTACT_PREFERENCES, settings);

  return {
    phone: alertMethods.includes("voice"),
    text: alertMethods.includes("sms"),
    consent: propOr(false, PATHS.CONSENT_TO_CONTACT, settings),
  };
}

export default withModal(Profile);
