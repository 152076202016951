import PropTypes from "prop-types";
import React from "react";
import ReactTooltip from "react-tooltip";

class TooltipTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: this.props.isFocused || false,
    };

    this.targetRef = React.createRef();
    this.closeTooltipOnEscape = closeTooltipOnEscape.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isFocused !== state.isFocused) {
      return {
        isFocused: props.isFocused,
      };
    }

    return state;
  }

  componentDidUpdate(_, prevState) {
    const target = this.targetRef.current;

    ReactTooltip.rebuild();

    if (!target) {
      return;
    }

    if (this.state.isFocused !== prevState.isFocused) {
      if (this.state.isFocused) {
        ReactTooltip.show(target);
      } else {
        ReactTooltip.hide(target);
      }
    }
  }

  render() {
    const triggerProps = {
      className: this.props.className,
      "aria-describedby": "tooltip",
      "data-tip": this.props.tooltip,
      "data-for": "tooltip",
      "data-place": this.props.tooltipPlacement,
      innerRef: this.targetRef,
      onKeyDown: this.closeTooltipOnEscape,
    };

    return this.props.render(triggerProps);
  }
}

TooltipTrigger.displayName = "TooltipTrigger";

TooltipTrigger.propTypes = {
  tooltipPlacement: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  render: PropTypes.func.isRequired,
};

export default TooltipTrigger;

// --------------------------------------------------------------------- Private

function closeTooltipOnEscape(event) {
  if (event.key === "Escape") {
    ReactTooltip.hide(this.targetRef.current);
  }
}
