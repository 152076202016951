/* global htmx -- Utilizes Htmx-powered fragments from API */

import { css } from "@emotion/react";
import assoc from "ramda/src/assoc";
import pick from "ramda/src/pick";
import pipe from "ramda/src/pipe";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const style = css`
  display: grid;
  width: 556px;
  margin: 80px auto 0;
  padding: var(--spacing-md);

  & > loading-indicator {
    width: var(--primitive-64);
    margin: 0 auto;
  }
`;

export const PaymentMethods = (props) => {
  const { amount = 0, item, url = "/cb/purchase" } = props;

  const values = { what: item, url, amount };

  useEffect(() => {
    htmx.ajax("POST", "/get-payment-methods", {
      target: "#payment-methods-container",
      values,
    });
  }, [amount, item]);

  return (
    <div css={style} id="payment-methods-container">
      <loading-indicator></loading-indicator>
    </div>
  );
};
