import prop from "ramda/src/prop";
import { cloneElement } from "react";
import { isPresent, isAbsent } from "../../utils";
import { Loading } from "../account-settings.subscriptions.common/loading";
import { Button } from "./_activate.button";
import { useApplicationContent } from "./use-fetch-application-content";

export const Wrapper = ({ view, children }) => {
  const { data, loading, hasError } = useApplicationContent(view);

  if (loading) return <Loading />;

  if (isAbsent(prop("html", data)) || hasError) {
    return (
      <section>
        <h1>Apply for a Subscription</h1>
        <p>
          In order to receive a subscription, you need to apply and be approved
          by an administrator.
        </p>
        <p>Please contact them directly for additional information.</p>
        {isPresent(prop("url", data)) && <Button>Download Document</Button>}
      </section>
    );
  }

  return cloneElement(children, { data });
};
