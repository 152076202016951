import { types as syncTypes } from "@kofile/ko-search-action-types";
import { suggestions as initialState } from "../initialState";
import types from "../types/suggestions";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.FETCH_SUGGESTIONS_1]: fetchSuggestions,
  [syncTypes.FETCH_SUGGESTIONS_FULFILLED_1]: fetchSuggestionsFulfilled,
  [syncTypes.FETCH_SUGGESTIONS_REJECTED_0]: fetchSuggestionsRejected,
  [syncTypes.FETCH_SUGGESTIONS_CANCELLED_0]: fetchSuggestionsCancelled,
  [types.CLEAR_SUGGESTIONS_SEARCH]: clearSuggestionsSearch,
});

function fetchSuggestions(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function fetchSuggestionsFulfilled(state, { payload }) {
  return {
    ...state,
    isLoading: false,
    data: payload.options,
  };
}

function fetchSuggestionsRejected(state) {
  return {
    ...state,
    isLoading: false,
    data: [],
  };
}

function fetchSuggestionsCancelled(state) {
  return {
    ...state,
    isLoading: false,
    data: [],
  };
}

function clearSuggestionsSearch(state) {
  return {
    ...state,
    isLoading: false,
    data: [],
  };
}
