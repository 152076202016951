import useAttachments from "../doc-detail.common/useAttachments";
import ViewDocumentImages from "../doc-detail.view-document.images";
import { Types } from "../doc-detail.view-document.images/constants";
import styles from "./attachments.style";

const ViewSingleAttachment = () => {
  const { breadcrumbText, actions } = useAttachments();

  return (
    <>
      <div css={styles.breadcrumbs}>
        <button css={styles.button} onClick={actions.close}>
          Attachments
        </button>
        &nbsp;
        <div>{breadcrumbText}</div>
      </div>
      <ViewDocumentImages type={Types.Attachment} />
    </>
  );
};

export default ViewSingleAttachment;
