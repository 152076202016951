import equals from "ramda/src/equals";
import { isTranscription, isTranslation, isAttachment } from "./constants";

export const ViewType = {
  SinglePage: "single-page",
  MultiPage: "multi-page",
};

export const isSinglePage = equals(ViewType.SinglePage);

export const isMultiPage = equals(ViewType.MultiPage);

export const isNotFirstPage = (current) => current !== 0;

export const isNotLastPage = (current, total) => current !== total - 1;

export const isTranscriptionOrTranslation = (type) =>
  isTranscription(type) || isTranslation(type);

export const getDataByType = (type, docData, attachmentData) => {
  const docInfo = {
    currentPage: docData?.currentPage,
    isOcrVisible: docData?.isOcrVisible,
    pageCount: docData?.totalPages ?? null,
    setCurrentPage: docData?.setCurrentPage,
    src: docData?.urls?.[docData.currentPage] ?? null,
    thumbnails: docData?.thumbnails,
    currentPageOcr: docData?.currentPageOcr,
    hasOcrHits: docData?.hasOcrHits,
    hitIndex: docData?.hitIndex,
    hitMapIndex: docData?.hitMapIndex,
    ocrHits: docData?.ocrHits,
    setHitIndex: docData?.setHitIndex,
    setHitMapIndex: docData?.setHitMapIndex,
    totalHits: docData?.totalHits,
    toggleOcrVisibility: docData?.toggleOcrVisibility,
  };

  if (isAttachment(type)) {
    return {
      ...docInfo,
      currentPage: attachmentData.currentPage,
      isOcrVisible: false,
      pageCount: attachmentData?.pageCount ?? null,
      setCurrentPage: attachmentData.setCurrentPage,
      src: attachmentData?.data?.urls?.[attachmentData.currentPage] ?? null,
      thumbnails: attachmentData?.data?.urls ?? [],
    };
  } else if (isTranscriptionOrTranslation(type)) {
    const subType = docData?.[type];

    return {
      currentPage: subType?.currentPage,
      isOcrVisible: subType?.isOcrVisible,
      pageCount: subType?.pageCount ?? null,
      setCurrentPage: subType?.setCurrentPage,
      src: subType?.urls?.[subType.currentPage] ?? null,
      thumbnails: subType?.thumbnails,
      currentPageOcr: subType?.currentPageOcr,
      hasOcrHits: subType?.hasOcrHits,
      hitIndex: subType?.hitIndex,
      hitMapIndex: subType?.hitMapIndex,
      ocrHits: subType?.ocrHits,
      setHitIndex: subType?.setHitIndex,
      setHitMapIndex: subType?.setHitMapIndex,
      totalHits: subType?.totalHits,
      toggleOcrVisibility: subType?.toggleOcrVisibility,
    };
  } else {
    return docInfo;
  }
};
