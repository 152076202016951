// del: doc-preview

import { formatItemsForQuote } from "./get-price-quote.format";

// for old doc preview
export const getPriceQuote =
  ({ props, updateQuoteID, ...args }) =>
  () => {
    const newDocPreviewDisabled = !props.newDocPreviewEnabled;
    const viewingSearchResults = props.onSearchResults;

    if (newDocPreviewDisabled || viewingSearchResults) {
      const data = {
        ...args,
        items: props.items,
        department: props.department,
        entityType: props.entityType,
        totalPages: props.totalPages,
        isCertifiedCopy: args.certifiedCopy,
      };

      const formattedItems = formatItemsForQuote(data);
      props.getDocPriceQuote({ items: formattedItems, updateQuoteID });
    }
  };
