import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { hasLoaded, isLoading, hasError } from "../../constants";
import { selectors } from "../../store";
import useDocument from "../doc-detail.common/useDocument";
import { Container, Header, Label } from "./_container";
import Wrapper from "./_wrapper";
import Error from "./card.error";
import FormSpinner from "./card.loading";
import Success from "./card.success";
import Form from "./form";
import useFeedbackSubmit from "./use-feedback-submit";

const portalStyle = css`
  position: absolute;
  right: 200px;
  width: 400px;
`;

const labelStyle = css`
  margin-right: 12px;
`;

const FeedbackForm = () => {
  const docData = useDocument();
  const { status, error, initiate: submit, reset } = useFeedbackSubmit();
  const [open, setOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const getImg = useSelector(selectors.assets.getBoundImg);
  const loggedIn = useSelector(selectors.user.getLoggedIn);
  const verified = useSelector(selectors.user.getIsVerified);
  const doubleArrowDown = getImg("icon_double_arrow_down.svg");
  const submitted = hasLoaded(status) && isNil(error);
  const loading = isLoading(status);
  const showForm = !loading && !submitted && open && isNil(error);

  const showSuccess = !loading && submitted && open;
  const showError = hasError(status);

  const isOnlyWhitespace = (str) => str.trim().length === 0;
  const isSubmittable = showForm && userInput && !isOnlyWhitespace(userInput);
  const disabled = !loggedIn || !verified;
  const labelText = open ? "Hide" : "Show";

  const tooltipText = disabled
    ? "Want to offer feedback?  Please login to a verified account."
    : "Offer feedback on details about this document.";

  const toggle = () => setOpen(!open);
  const clear = () => setUserInput("");

  const close = () => {
    setOpen(false);
    reset();
  };

  const submitFeedback = (e) => {
    e.preventDefault();
    if (!isSubmittable) return;

    const { department, id } = docData;

    submit({
      payload: {
        departmentCode: department,
        docId: id,
        feedbackContent: userInput,
      },
    });
  };

  useEffect(() => {
    if (hasLoaded(status)) {
      clear();
    }
  }, [status]);

  if (process.env.IS_CLIENT === "f") return null;

  const headerProps = { open, onClick: toggle, disabled };
  if (!open) headerProps.title = tooltipText;

  return createPortal(
    <div css={portalStyle}>
      <Container open={open} showSuccess={showSuccess}>
        <Header {...headerProps}>
          Document Feedback
          <div>
            <Label open={open} css={labelStyle}>
              {labelText}
            </Label>
            <img alt="down arrow" src={doubleArrowDown} />
          </div>
        </Header>
        {open && (
          <Wrapper>
            {loading && <FormSpinner />}
            {showError && (
              <Error close={close} message={error.reason.message} />
            )}
            {showForm && (
              <Form
                close={close}
                userInput={userInput}
                setUserInput={setUserInput}
                isSubmittable={isSubmittable}
                onSubmit={submitFeedback}
              />
            )}
            {showSuccess && <Success close={close} />}
          </Wrapper>
        )}
      </Container>
    </div>,
    document.getElementById("footer")
  );
};

export default FeedbackForm;
