import NavigateButton from "./navigate.button";
import useNavigateDocsByBook from "./use-navigate-docs-by-book";

const NavigateBookButton = ({ to, style }) => {
  const { navigate, disabled } = useNavigateDocsByBook(to);
  const str = `${to} in Book`;

  return (
    <NavigateButton
      navigate={navigate}
      disabled={disabled}
      str={str}
      to={to}
      style={style}
    />
  );
};

export default NavigateBookButton;
