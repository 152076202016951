import format from "date-fns/format";
import { stringify } from "query-string";
import { ofType } from "redux-observable";
import { empty as emptyObservable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import types from "../types/help";

const DATE_FORMAT = "M/d/yyyy h:m:saa";
const formatDate = (date) => format(date, DATE_FORMAT);

const getHelpFrameUrl = (params) => {
  const query = stringify(params);

  return `https://www.livehelpnow.net/lhn/lcv.aspx?${query}`;
};

const openLiveHelpWindow = (action$, store, { window }) =>
  action$.pipe(
    ofType(types.OPEN_LIVE_HELP_CHAT),
    mergeMap(() => {
      const query = {
        d: 37437, // department number
        lhnid: 25615, // livehelp account number
        zzwindow: 36182, // window number?,
        pcv: JSON.stringify({
          // pre chat values
          "-website": window.location.href,
        }),
      };

      const wWidth = 500;
      const wHeight = 500;
      const left = window.screen.width / 2 - wWidth / 2;
      const top = window.screen.height / 2 - wHeight / 2;

      const externalWindow = window.open(
        getHelpFrameUrl({ ...query, time: formatDate(new Date()) }),
        "Live Chat Support",
        `resizable,scrollbars, width=${wWidth}, height=${wHeight}, top=${top}, left=${left}`
      );

      externalWindow.focus();

      return emptyObservable();
    })
  );

export default [openLiveHelpWindow];
