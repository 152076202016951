import { pageButtonStyle } from "./style";

const PageButton = ({ value, onChange, selected = false }) => {
  const onClick = () => onChange(value);

  return (
    <button
      css={pageButtonStyle(selected)}
      aria-label={`page ${value}`}
      aria-current={selected ? "page" : null}
      value={value}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

export default PageButton;
