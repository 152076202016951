import { css } from "@emotion/react";
import { useDispatch } from "react-redux";
import { CardArea } from "../../../../../../components";
import { Wrapper as CardAreaWrapper } from "../../../../../../components/CardEntry";
import { withStripe } from "../../../../../../hoc";
import { actions } from "../../../../../../store";
import { checkToken, createToken } from "../../../../../../utils/stripe";
import { PaymentForm as PaymentFormWrapper } from "./_payment.form";
import UpdateButton from "./UpdateButton";

const style = css`
  width: 400px;
`;

const PaymentForm = (props) => {
  const { saveNewCard, stripe, cardAreaProps, isLoading } = props;
  const dispatch = useDispatch();

  const setError = (error) => dispatch(actions.user.setError(error));
  const saveCard = (cardToken) => dispatch(actions.user.saveCard(cardToken));

  const handleSaveCard = (event) => {
    event.preventDefault();

    if (saveNewCard) {
      createToken({ type: "card", stripe })
        .then(checkToken)
        .then(({ token }) => void saveCard({ cardToken: token.id }))
        .catch((error) => void setError(error));
    }
  };

  return (
    <PaymentFormWrapper onSubmit={handleSaveCard}>
      <CardAreaWrapper css={style}>
        <CardArea label="Credit or debit card" {...cardAreaProps} />
      </CardAreaWrapper>
      <UpdateButton isLoading={isLoading} disabled={!saveNewCard} />
    </PaymentFormWrapper>
  );
};

export default withStripe(PaymentForm);
