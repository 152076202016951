import PropTypes from "prop-types";
import Link from "../../../../components/Link";

const DropdownElement = ({ disabled = false, label, action, to, isLink }, id) =>
  isLink ? (
    <Link
      key={id}
      className="select-action-dropdown-element"
      onClick={action}
      to={to}
      data-testid="dropdownElement"
    >
      {label}
    </Link>
  ) : (
    <button
      key={id}
      className="select-action-dropdown-element"
      onClick={action}
      disabled={disabled}
      data-testid="dropdownElement"
    >
      {label}
    </button>
  );

DropdownElement.displayName = "DropdownElement";
DropdownElement.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  to: PropTypes.string,
  isLink: PropTypes.bool,
};

export default DropdownElement;
