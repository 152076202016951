import { types as syncTypes } from "@kofile/ko-search-action-types";
import always from "ramda/src/always";
import removeProp from "ramda/src/dissoc";
import equals from "ramda/src/equals";
import is from "ramda/src/is";
import length from "ramda/src/length";
import lensProp from "ramda/src/lensProp";
import map from "ramda/src/map";
import omit from "ramda/src/omit";
import over from "ramda/src/over";
import pipe from "ramda/src/pipe";
import unless from "ramda/src/unless";
import view from "ramda/src/view";
import when from "ramda/src/when";
import types from "../types/checkout";

const items = lensProp("items");
const returnEmptyArray = always([]);
const isArray = is(Array);

const hasZeroItems = pipe(view(items), length, equals(0));

const formatForPurchase = pipe(
  omit(["cartId"]),
  over(items, pipe(unless(isArray, returnEmptyArray), map(omit(["itemId"])))),
  when(hasZeroItems, removeProp("items"))
);

export default {
  checkout: (payload) => ({ type: syncTypes.REQUEST_CHECKOUT_0, payload }),
  purchase: (payload) => ({
    type: "purchase",
    payload: formatForPurchase(payload),
  }),
  expressCheckout: (payload) => ({
    type: syncTypes.REQUEST_EXPRESS_CHECKOUT_0,
    payload,
  }),
  checkoutError: (payload) => ({ type: types.CHECKOUT_ERROR, payload }),
  clear: (payload) => ({ type: types.CLEAR, payload }),
  clearError: (payload) => ({ type: types.CLEAR_ERROR, payload }),
  makeCheckoutStale: () => ({ type: types.MAKE_CHECKOUT_STALE }),
  makeCheckoutFresh: () => ({ type: types.MAKE_CHECKOUT_FRESH }),
};
