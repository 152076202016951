import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../../../../components";
import { selectors } from "../../../../../../../store";
import { IconsWrapper } from "./_header.icons";
import { Icon } from "./header.icons.icon";

const style = css`
  & .loading__spinner {
    height: 30px;
    width: 30px;
    margin: 0;
    padding: 5px;
  }
`;

export const StatusIcons = ({ hasBeenViewed, inCart, id }) => {
  const { download: dl } = selectors;
  const downloading = useSelector(dl.isDownloadingForDocId(id));
  const downloaded = useSelector(dl.hasDownloadedForDocId(id));

  return (
    <IconsWrapper>
      {inCart && <Icon icon={"row_cart"} alt="document in cart" />}
      {downloading && <LoadingSpinner css={style} />}
      {downloaded && <Icon icon={"download"} alt="document downloaded" />}
      {hasBeenViewed && <Icon icon={"eye_black"} alt="document viewed" />}
    </IconsWrapper>
  );
};
