import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import flatten from "ramda/src/flatten";
import has from "ramda/src/has";
import is from "ramda/src/is";
import isEmpty from "ramda/src/isEmpty";
import omit from "ramda/src/omit";
import pickBy from "ramda/src/pickBy";
import propEq from "ramda/src/propEq";
import propOr from "ramda/src/propOr";
import reject from "ramda/src/reject";
import replace from "ramda/src/replace";
import split from "ramda/src/split";
import toPairs from "ramda/src/toPairs";
import values from "ramda/src/values";
import { notEmpty, omitEmpty } from "../../utils";

const prepareValue = compose(split(","), propOr(""));

const formatParty = (term, types) => ({ term, types });

const convertPartyField = (values, types) =>
  values.map((val) => formatParty(val, types));

const combinePartyFields = (partyFields, queryObj) => {
  const parties = prepareValue("partyNames", queryObj);

  const mappedParties = reject(
    propEq("term", ""),
    convertPartyField(parties, partyFields)
  );

  const mappedPartyFields = partyFields.reduce((acc, party) => {
    const partyValue = prepareValue(party, queryObj);
    const value = convertPartyField(
      partyValue.filter((term) => term !== ""),
      [party]
    );

    if (value.length > 0) acc[party] = value;

    return acc;
  }, {});

  if (mappedParties.length > 0) {
    mappedPartyFields.parties = mappedParties;
  }

  return mappedPartyFields;
};

const mapParties =
  ({ partyFields = [] }) =>
  (queryObj) => {
    const omitFields = compose(omitEmpty, omit([...partyFields, "partyNames"]));

    const cleanedQuery = omitFields(queryObj);
    const parties = combinePartyFields(partyFields, queryObj);

    return {
      ...cleanedQuery,
      ...(notEmpty(parties) && {
        parties: JSON.stringify(parties),
      }),
    };
  };

const mapLandCorners = (queryObj) => {
  if (has("landCorner", queryObj)) {
    const landCorner = queryObj.landCorner;
    const [sectionTown, sectionRange, sectionLandcornerValue] =
      landCorner.split(":");
    const legals = queryObj.legals || [];

    legals.push({ sectionTown, sectionRange, sectionLandcornerValue });

    return {
      ...omit(["landCorner"], queryObj),
      legals: JSON.stringify(legals),
    };
  }

  return queryObj;
};

const mapLegals =
  ({ legalFields = {} }) =>
  (queryObj) => {
    if (isEmpty(legalFields)) {
      return queryObj;
    }

    const fieldsToOmit = flatten(values(legalFields));

    const omitFields = compose(omitEmpty, omit(fieldsToOmit));

    const baseQuery = omitFields(queryObj);

    const legals = toPairs(legalFields)
      .map(([legalType, legalKeys]) => {
        const legalQueryObj = pickBy((paramValue, paramKey) => {
          return contains(paramKey, legalKeys) && !isEmpty(paramValue);
        }, queryObj);

        if (isEmpty(legalQueryObj)) {
          return null;
        }

        return { legalType, ...legalQueryObj };
      }, queryObj)
      .filter((item) => item !== null);

    if (isEmpty(legals)) {
      return queryObj;
    }

    return { ...baseQuery, legals: JSON.stringify(legals) };
  };

const includeSearchType = (queryObj) => ({
  ...queryObj,
  searchType: "advancedSearch",
});

const convertQuotes = compose(
  replace("“", '"'),
  replace("”", '"'),
  replace("‘", "'"),
  replace("’", "'")
);

const deepConvertQuotes = (v) => {
  if (is(Array, v)) {
    return v.map(deepConvertQuotes);
  } else if (is(String, v)) {
    return convertQuotes(v);
  } else if (is(Object, v)) {
    for (const key in v) {
      v[key] = deepConvertQuotes(v[key]);
    }
  }

  return v;
};

export const mapToQuery = (props) => (queryObj) =>
  compose(
    deepConvertQuotes,
    includeSearchType,
    mapParties(props),
    mapLandCorners,
    mapLegals(props)
  )(queryObj);

export const makeQueryMapper = (props) => mapToQuery(props);
