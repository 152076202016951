import { useDateRangeMetaLabel } from "../../../../../../../hooks";

const CertificationStatus = ({ className }) => {
  const dateRangeLabel = useDateRangeMetaLabel();

  if (!dateRangeLabel) return null;

  return <span className={className}>{dateRangeLabel}</span>;
};

CertificationStatus.displayName = "CertificationStatus";

export { CertificationStatus };

export default CertificationStatus;
