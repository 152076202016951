import { css } from "@emotion/react";
import { parse } from "query-string";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { selectors } from "../../../store";
import { getNewSearchURL, makeAllPartySearchValue } from "../../../utils";

const style = {
  text: css`
    border-bottom: 1px solid rgba(4, 29, 68, 0.15);
    padding: 8px;
  `,
  link: css`
    color: #006dd5;
    cursor: pointer;
  `,
};

export const PartyLink = ({ term, location, partyFields, department }) => {
  const { search } = location;
  const { isLimitedSearchEnabled } = selectors.configuration;
  const limitedSearchEnabled = useSelector(isLimitedSearchEnabled);

  if (limitedSearchEnabled) {
    return <span css={style.text}>{term}</span>;
  }

  const query = {
    ...parse(search),
    department,
  };

  const searchLink = getNewSearchURL({
    query,
    value: makeAllPartySearchValue({ term, partyFields }),
    key: "parties",
  });

  return (
    <Link to={searchLink} css={[style.text, style.link]}>
      {term}
    </Link>
  );
};

export default withRouter(PartyLink);
