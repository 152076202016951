import { useState } from "react";
import style from "./skip-links.style";

const SkipLinks = () => {
  const [hasFocus, setHasFocus] = useState(false);
  const focus = () => setHasFocus(true);
  const blur = () => setHasFocus(false);

  const links = [
    { target: "#nav", label: "Skip To Header Nav" },
    { target: "#workspaces", label: "Skip To Workspace Tabs" },
    { target: "#main-content", label: "Skip To Main Content" },
  ];

  return (
    <ul css={style.bg(hasFocus)}>
      {links.map(({ target, label }) => (
        <li key={label}>
          <a css={style.link} href={target} onFocus={focus} onBlur={blur}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SkipLinks;
