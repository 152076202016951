import { useHistory } from "react-router-dom";
import { NCourtPayment } from "../../../../components";

const NCourtCheckout = () => {
  const history = useHistory();
  const orderIncomplete = () => history.push("/cart/contents");

  return <NCourtPayment orderIncomplete={orderIncomplete} />;
};

export default NCourtCheckout;
