import { formatSearchText, getPage } from "../../../../../utils";

const getFirstIndex = (offset, limit) =>
  (getPage(offset, limit) - 1) * limit + 1;

const getLastIndex = (startIndex, limit, numRecords) => {
  const proposedEnd = startIndex + limit - 1;

  return proposedEnd <= numRecords ? proposedEnd : numRecords;
};

export const useSearchText = (props) => {
  const { numRecords = 0, query, defaultLimit, department } = props;
  const { offset, limit } = query;

  const searchText = formatSearchText(query, department.partyFields);
  const offsetInt = parseInt(offset, 10);
  const limitInt = parseInt(limit, 10);
  const defaultLimitInt = parseInt(defaultLimit, 10);
  const resultStart = offset ? getFirstIndex(offsetInt, limitInt) : 1;

  const resultEnd = limit
    ? getLastIndex(resultStart, limitInt, numRecords)
    : getLastIndex(resultStart, defaultLimitInt, numRecords);

  const valueRangeText = `${resultStart}-${resultEnd} of`;
  const resultsString = ` ${numRecords.toLocaleString()} results`;
  const forString = searchText ? "for" : "";

  const label = `${valueRangeText} ${resultsString} ${forString}`;

  return { label, value: searchText };
};
