import { useSelector, useDispatch } from "react-redux";
import SignInModal from "../../App/screens/SignIn/modal";
import { Status } from "../../constants";
import { actions, selectors } from "../../store";
import modalChild from "../../units/checkout.modal/modal";
import { useExternalCertProvider } from "./use-external-cert-provider";

export default (props) => {
  const { modal, isCertifiedCopy, document } = props;
  const dispatch = useDispatch();

  const externalCertProvider = useExternalCertProvider();

  const { user } = selectors;
  const loggedIn = useSelector(user.getLoggedIn);

  const { getImg } = selectors.assets;
  const expressIcon = useSelector(getImg("icon_express-checkout.svg"));
  const externalLinkIcon = useSelector(getImg("icon_external_link.svg"));

  const noImages = document?.urls?.length === 0;
  const imageUnavailable = noImages && !document?.hidePreview;
  const disabled = document?.status !== Status.Loaded || imageUnavailable;
  const isExternalCert = externalCertProvider.enabled && isCertifiedCopy;

  const initiateCheckout = (e) => {
    e.preventDefault();

    const openExpressCheckout = () => {
      return modal.open(() =>
        modalChild({
          currentPage: document.currentPage,
          certifiedCopy: isCertifiedCopy,
          close: modal.close,
        })()
      );
    };

    const cartData = {
      department: document?.department,
      itemIDs: [document?.id],
    };

    const startCheckout = isExternalCert
      ? externalCertProvider.initiate
      : openExpressCheckout;

    dispatch(actions.user.expressCheckout(cartData));

    if (loggedIn) return startCheckout();

    const signInAction = {
      actionOnSignIn: startCheckout,
      close: props.modal.close,
    };

    return props.modal.open(SignInModal(signInAction));
  };

  return {
    initiateCheckout,
    icon: isExternalCert ? externalLinkIcon : expressIcon,
    disabled,
    isExternalCert,
  };
};
