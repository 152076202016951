import { tooltip as initialState } from "../initialState";
import types from "../types/tooltip";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.SHOW]: show,
  [types.HIDE]: hide,
  SAVE_SEARCH_URL: hide,
});

function show(state, { payload }) {
  return {
    isOpen: true,
    ...payload,
  };
}

function hide() {
  return {
    isOpen: false,
    children: null,
    distance: 0,
  };
}
