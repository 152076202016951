import { types as syncTypes } from "@kofile/ko-search-action-types";
import { newTypes } from "../reducers/documents";
import types from "../types/documents";

export default {
  fetch: (payload) => ({ type: types.CONFIGURE_SEARCH_QUERY, payload }),
  configureQuery: (payload) => ({
    type: types.CONFIGURE_SEARCH_QUERY,
    payload,
  }),
  cancelFetch: (payload) => ({
    type: syncTypes.FETCH_DOCUMENTS_CANCELLED_0,
    payload,
  }),
  getDocPriceQuote: (payload) => ({
    type: syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_0,
    payload,
  }),
  cancelGetPriceQuote: (payload) => ({
    type: syncTypes.FETCH_DOCUMENTS_PRICE_QUOTE_CANCELLED_0,
    payload,
  }),
  removeFetchedDocuments: (payload) => ({
    type: types.REMOVE_FETCHED_DOCUMENTS,
    payload,
  }),
  updateLegalDisplay: (payload) => ({
    type: types.UPDATE_LEGAL_DISPLAY,
    payload,
  }),
  clearQuotes: () => ({ type: newTypes.clearQuotes }),
};
