import AddToCart from "../doc-detail.navigation/action.add-to-cart.behavior";
import styles from "./attachments.style";

const AddToCartButton = () => (
  <AddToCart>
    {({ text, disabled, addDocToCart }) => (
      <button disabled={disabled} onClick={addDocToCart} css={styles.button}>
        {text}
      </button>
    )}
  </AddToCart>
);

export default AddToCartButton;
