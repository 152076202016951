import classnames from "classnames";
import { CardInfo } from "./card-info";
import Header from "./header";
import { ImagePreview } from "./image-preview";
import { LegalTables } from "./legal-tables";
import { Table } from "./table";

export const ResultCardView = ({ common, footer, ...props }) => {
  const cardInfoClassName = common.showImagePreview
    ? "result-card__left"
    : "result-card__center";

  const resultCardClass = classnames("result-card", {
    "result-card__active": common.isActive,
  });

  return (
    <div className={resultCardClass}>
      <div className="result-card__section-wrap">
        <div className={cardInfoClassName}>
          <Header {...props.header} />
          <CardInfo {...props.info} />
          <div className="result-card__ocr-text">
            <p dangerouslySetInnerHTML={{ __html: common.doc.ocrText }} />
          </div>
        </div>
        <ImagePreview {...props.imagePreview} {...common} />
      </div>
      <Table
        shouldTruncate
        config={footer.config}
        data={common.doc}
        {...common}
      />
      {footer.legals.enabled && (
        <LegalTables
          doc={common.doc}
          displayOption={footer.legals.displayOption}
        />
      )}
    </div>
  );
};
