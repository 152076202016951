import { css } from "@emotion/react";
import { LoadingSpinner } from "../../components";

const spinnerStyle = css`
  grid-area: body;
`;

const FormSpinner = () => {
  return <LoadingSpinner css={spinnerStyle} />;
};

export default FormSpinner;
