import styled from "@emotion/styled";

export const AddMemberContainer = styled.div`
  display: grid;
  margin: 10px 0;
  padding-bottom: 20px;
  grid-template: 1fr / 400px 120px 1fr;
  grid-template-areas: "input button message";
  column-gap: 15px;
`;
