import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../../../../store";

const CheckIcon = ({ isActive }) => {
  const blueCheckIcon = useSelector(selectors.assets.getImg("blue_check.svg"));

  if (!isActive) {
    return null;
  }

  return (
    <img
      className="sort-dropdown__check-icon"
      src={blueCheckIcon}
      alt="check"
    />
  );
};

CheckIcon.displayName = "CheckIcon";

CheckIcon.propTypes = {
  isActive: PropTypes.bool,
};

export default CheckIcon;
