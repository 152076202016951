export const buttonStyle = {
  backgroundColor: "#006dd5",
  color: "#fff",
  borderRadius: "4px",
  border: "1px solid #d8dde6",
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 600,
  height: "34px",
  margin: "1px 3px",
  transition: "background-color 0.3s ease-out",
  padding: "9px 16px",

  "&:hover": {
    textDecoration: "underline",
  },

  "&:disabled": {
    backgroundColor: "#d1d1d1",
    border: "1px solid #d1d1d1",
    opacity: ".5",
    cursor: "not-allowed",
    textDecoration: "none",
  },
};
