import { useSelector } from "react-redux";
import { selectors } from "../../store";
import BlueButton from "../doc-detail.common/_button.blue";
import ConfigurePropertyAlert from "./action.configure-property-alert.behavior";

const ConfigurePropertyAlertButton = () => {
  const starBadge = useSelector(selectors.assets.getImg("icon_fraud_nav.svg"));

  return (
    <ConfigurePropertyAlert>
      {({ configureAlert, disabled }) => (
        <BlueButton disabled={disabled} onClick={configureAlert}>
          <img src={starBadge} alt="property alert icon" />
          Property Alert
        </BlueButton>
      )}
    </ConfigurePropertyAlert>
  );
};

export default ConfigurePropertyAlertButton;
