import compose from "ramda/src/compose";
import defaultTo from "ramda/src/defaultTo";
import filter from "ramda/src/filter";
import has from "ramda/src/has";
import isEmpty from "ramda/src/isEmpty";
import keys from "ramda/src/keys";
import lensPath from "ramda/src/lensPath";
import map from "ramda/src/map";
import merge from "ramda/src/merge";
import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import reduce from "ramda/src/reduce";
import sort from "ramda/src/sort";
import values from "ramda/src/values";
import view from "ramda/src/view";
import { createSelector } from "reselect";
import { isAbsent } from "../../utils";
import { workspaces as workspacesSelectors } from "../selectors";

export const makeWorkspaceLens = (id) => lensPath(["workspaces", id]);

export const makeLenses = (workspaceID) => ({
  workspace: Object.assign(makeWorkspaceLens(workspaceID)),
});

export const getDocuments = prop("documents");

export const getActiveWorkspaceData = createSelector(
  [(state) => workspacesSelectors.getActiveWorkspaceID(state), getDocuments],
  (id, docs) => {
    const lenses = makeLenses(id);

    return view(lenses.workspace, docs);
  }
);

export const getActiveWorkspaceLoading = createSelector(
  getActiveWorkspaceData,
  propOr(true, "isLoading")
);

export const getActiveWorkspaceDocuments = createSelector(
  getActiveWorkspaceData,
  propOr({}, "data")
);

export const getActiveWorkspaceDataOrder = createSelector(
  getActiveWorkspaceData,
  pathOr([], ["data", "byOrder"])
);

export const getActiveWorkspaceDataHash = createSelector(
  getActiveWorkspaceData,
  pathOr({}, ["data", "byHash"])
);

export const getDocumentImageId = (docId) =>
  createSelector([getActiveWorkspaceDataHash], (byHash = {}) => {
    return byHash[docId]?.imageId ?? null;
  });

export const getLegalDisplayOption = createSelector(
  getActiveWorkspaceData,
  path(["cards", "legalDisplayOption"])
);

export const getActiveWorkspaceDocsWithoutImage = createSelector(
  getActiveWorkspaceDataHash,
  compose(
    map(prop("docId")),
    filter(({ imageId, isSecured = false }) => imageId === null || isSecured),
    values
  )
);

export const getActiveWorkspaceMeta = createSelector(
  getActiveWorkspaceData,
  propOr({}, "meta")
);

export const getActiveWorkspaceStatistics = createSelector(
  getActiveWorkspaceMeta,
  propOr({}, "statistics")
);

export const getRecordedYears = createSelector(
  getActiveWorkspaceStatistics,
  (statistics) => makeDecadeRanges(statistics["recorded-years"])
);

export const getInstrumentYears = createSelector(
  getActiveWorkspaceStatistics,
  (statistics) => makeDecadeRanges(statistics["instrument-years"])
);

export const getPriceQuotes = createSelector(getDocuments, prop("priceQuotes"));

export const isFirstQuoteFetched = createSelector(getPriceQuotes, isAbsent);

export const getMostRecentQuoteId = createSelector(
  getDocuments,
  prop("mostRecentQuoteId")
);

export const getQuoteProgress = createSelector(
  getDocuments,
  prop("isFetchingQuote")
);

export const getExpressQuote = createSelector(
  [getMostRecentQuoteId, getPriceQuotes],
  (quoteId, quotes) => prop(quoteId, quotes)
);

export const getDocumentsForExport = createSelector(
  [getActiveWorkspaceDataOrder, getActiveWorkspaceDataHash],
  (byOrder = [], byHash = {}) => byOrder.map((id) => prop(id, byHash))
);

export const getResults = createSelector(
  [
    getActiveWorkspaceDataHash,
    getActiveWorkspaceDataOrder,
    (state) => workspacesSelectors.getSelectedDepartmentConfig(state),
  ],
  (normalizedDocs, docsOrder, { viewConfig = [] } = {}) => {
    if (isEmpty(docsOrder) && !isEmpty(viewConfig)) return [];

    return docsOrder.map((docId) => {
      const doc = normalizedDocs[docId];

      const desiredData = viewConfig.map(({ key, dataPath = "" }) => {
        if (dataPath) return path(dataPath, doc);
        return doc[key];
      });

      return Object.assign(desiredData, { id: doc.id });
    });
  }
);

// ----------------------------------------------------------------------- Utils
const getYearsBetween = (input) => (str) => {
  const [start, end] = str.split("-").map((n) => Number(n));

  return keys(input)
    .filter((year) => Math.min(end, year) === Math.max(start, year))
    .map((value) => ({
      value,
      hits: input[value],
    }));
};

const sortObjectKeys = compose(
  sort((a, b) => Number(b) - Number(a)),
  keys
);

const reduceByDecade = (input) => {
  const getYears = getYearsBetween(input);
  const decades = reduce(
    (acc, year) => {
      const baseYear = year.slice(0, -1);
      const decadeStart = `${baseYear}0`;
      const decadeEnd = Number(decadeStart) + 9;
      const decade = `${decadeStart}-${decadeEnd}`;

      if (has(decade, acc)) {
        return merge(acc, {
          [decade]: acc[decade] + input[year],
        });
      }

      return merge(acc, {
        [decade]: input[year],
      });
    },
    {},
    sortObjectKeys(input)
  );

  return reduce(
    (acc, curr) =>
      acc.concat({
        category: curr,
        options: getYears(curr),
        hits: decades[curr],
      }),
    [],
    keys(decades)
  );
};

export const replaceWithPresent = (decadeList) =>
  decadeList.map((decadeObj) => {
    const { category } = decadeObj;
    const [decadeStart, decadeEnd] = category.split("-");
    const date = new Date();
    const updatedDecadeEnd =
      decadeEnd >= date.getFullYear() ? "Present" : decadeEnd;
    const newDecadeRange = `${decadeStart}-${updatedDecadeEnd}`;

    return merge(decadeObj, { category: newDecadeRange });
  });

const convertToObj = reduce(
  (acc, { label, hits }) => merge(acc, { [label]: hits }),
  {}
);

function makeDecadeRanges(input) {
  return compose(
    replaceWithPresent,
    reduceByDecade,
    convertToObj,
    defaultTo([])
  )(input);
}
