export default {
  clear: () => ({ type: "clear-ncourt-order" }),

  clearError: () => ({ type: "reset-order-statuses" }),

  initializeOrder: (payload) => ({
    type: "prepare-ncourt-checkout",
    payload,
  }),

  initializeExpressOrder: (payload) => ({
    type: "prepare-ncourt-express-checkout",
    payload,
  }),

  initializeExportOrder: (payload) => ({
    type: "prepare-ncourt-express-checkout-for-a-search-export-results-pdf",
    payload,
  }),

  isOrderPaid: (payload) => ({
    type: "fetch-order",
    payload,
  }),
};
