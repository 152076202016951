import { css } from "@emotion/react";

const styles = {
  table: css`
    table-layout: fixed;
    width: 100%;
  `,
  thead: css`
    border-bottom: 1px solid rgba(4, 29, 68, 0.15);
  `,
  th: css`
    text-align: left;
    color: rgba(4, 29, 68, 0.6);
    font-size: 14px;
    font-family: "Source Sans Pro";
    padding: 8px 0;
  `,
  td: css`
    color: #041d44;
    padding: 8px 0;
    font-size: 13px;
  `,
};

const Row = ({ columns, rowData, rowIndex }) => (
  <tr key={rowIndex}>
    {columns.map(({ key }, cellIndex) => (
      <td key={cellIndex} css={styles.td}>
        {rowData[key] || "N/A"}
      </td>
    ))}
  </tr>
);

export const Table = ({ data = [], columns = [] }) => (
  <table css={styles.table}>
    <thead css={styles.thead}>
      <tr>
        {columns.map(({ label }, i) => (
          <th css={styles.th} key={i}>
            {label}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((rowData, rowIndex) => (
        <Row
          key={`summary-table-${rowIndex}`}
          columns={columns}
          rowData={rowData}
          rowIndex={rowIndex}
        />
      ))}
    </tbody>
  </table>
);
