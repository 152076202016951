import styled from "@emotion/styled";
import { Button } from "./_button";

export const SubmitButton = styled(Button)`
  background: rgba(255, 105, 0, 1);
  color: rgba(255, 255, 255, 1);
  grid-area: search;
  margin: auto;
  padding: 0.8rem 0;
  width: 200px;

  &:focus {
    border: 1px solid rgba(0, 109, 213, 1);
    box-shadow: inset -1px -1px 2px 0 rgba(0, 0, 0, 0.2),
      inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
      -2px -2px 4px 0 rgba(0, 134, 241, 0.2),
      2px 2px 4px 0 rgba(0, 134, 241, 0.2);
  }

  &:disabled {
    opacity: 0.5;
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
