import not from "ramda/src/not";
import propOr from "ramda/src/propOr";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "../../components";
import { selectors } from "../../store";
import { Container } from "./_date-range.container";
import { CustomDateRange } from "./date-range.custom";

const DateRangeSelect = ({ value, disabled = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { showBasicSearchDatePicker } = selectors.configuration;
  const hideDateRange = not(useSelector(showBasicSearchDatePicker));

  if (hideDateRange) return null;

  const onStateChange = (event) => {
    setIsOpen(propOr(true, "isOpen", event));
  };

  return (
    <Container
      data-tourid="dateRangeSelect"
      disabled={disabled}
      isOpen={isOpen}
    >
      <CustomDateRange
        dateRange={props.rangeValue}
        dateRangeOption={props.dateRangeOption}
        disabled={props.disabled || false}
        isOpen={isOpen}
        label={props.label}
        maxRange={props.maxRange}
        onChange={props.onRangeChange}
        value={value}
      />
      <Select
        className="date-select"
        onChange={props.onChange}
        onStateChange={onStateChange}
        options={props.options}
        value={value}
        disabled={disabled}
      />
    </Container>
  );
};

export default DateRangeSelect;
