import { css } from "@emotion/react";
import either from "ramda/src/either";
import not from "ramda/src/not";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import subscriptionsContext from "../../App/screens/Account/SettingsView/views/subscriptions-context";
import { hasError, hasLoaded, isInit, isLoading } from "../../constants";
import { useAPI, protocols } from "../../hooks/use-api";
import { useNotifications } from "../../hooks/use-notifications";
import { selectors } from "../../store";
import { isAbsent, isPresent } from "../../utils";
import ConfirmActionModal from "../account-settings.common/ConfirmActionModal";
import { createModalText, notification } from "./modal.edit.text";
import { useChangeSubscription } from "./use-change-subscription";

const style = css`
  line-height: 1.3;
  margin-top: 30px;
  text-align: left;
`;

const strong = css`
  font-weight: 600;
`;

const useGetConfigurablePaymentMethods = () =>
  useAPI({
    send: "get-configurable-payment-methods",
    protocol: protocols.HTTP,
  });

export const EditModal = ({ plan, close, onChange, nav }) => {
  const history = useHistory();
  const subsFromContext = useContext(subscriptionsContext);
  const { initiate, status, subscriptions } = useChangeSubscription();
  const paymentMethods = useGetConfigurablePaymentMethods();
  const pushNotification = useNotifications();
  const [cardOnFile, setCardOnFile] = useState(false);

  const subscription = pathOr({}, ["subscriptions", 0], subsFromContext);
  const cardInfo = useSelector(selectors.user.getCardInfo);
  const isDisabled = either(isLoading, hasLoaded)(status);
  const notFixedTerm = not(prop("fixedTerm", plan));
  const planNotFree = prop("amount", plan) !== "$0.00";
  const selectedPlanIsRecurring = planNotFree && notFixedTerm;
  const contentLoading =
    !cardOnFile &&
    (isInit(paymentMethods.status) || isLoading(paymentMethods.status));

  const change = () => {
    initiate({
      newPlanCode: plan.planCode,
      currentSubscriptionId: subscription.id,
    });
  };

  useEffect(() => {
    if (isPresent(cardInfo)) setCardOnFile(true);
  }, [cardInfo]);

  useEffect(() => {
    if (isInit(paymentMethods.status) && isAbsent(cardInfo)) {
      paymentMethods.initiate();
    }
  }, [paymentMethods.status, cardInfo]);

  useEffect(() => {
    const dataPresent = isPresent(paymentMethods.data);

    if (
      dataPresent &&
      paymentMethods.data.includes("Card on File") &&
      isAbsent(cardInfo)
    )
      setCardOnFile(true);
  }, [prop("data", paymentMethods)]);

  useEffect(() => {
    if (hasLoaded(status)) {
      onChange(subscriptions);
      nav.goToSubscriptions();
      pushNotification({ message: notification.success });
      close();
    }
  }, [status, subscriptions]);

  useEffect(() => {
    if (hasError(status)) {
      pushNotification({ message: notification.error });
      close();
    }
  }, [status]);

  useEffect(
    () => () => {
      paymentMethods.reset();
      subsFromContext.initiate({ valid: false });
    },
    []
  );

  const createConfirmationProps = ({
    content,
    action,
    actionText,
    color = "red",
  }) => ({
    content,
    color,
    loading: isDisabled,
    title: "Change Subscription",
    cancel: { onClick: close, text: "Cancel" },
    action: { onClick: action, text: actionText, disabled: isDisabled },
  });

  const genericConfirmationContent = (
    <>
      <p css={strong}>
        <strong>Are you sure you want to change your subscription?</strong>
      </p>
      <p css={style}>{createModalText({ subscription, plan })}</p>
    </>
  );

  const saveCardContent = (
    <>
      <p css={strong}>
        <strong>
          In order to continue, you need to have a saved credit card on file.
        </strong>
      </p>

      <p css={style}>
        This subscription requires a credit card on file. Please save a credit
        card in the payment section of your Account Profile to continue with the
        application process.
      </p>
    </>
  );

  const genericConfirmationProps = createConfirmationProps({
    content: genericConfirmationContent,
    action: change,
    actionText: "Change Subscription",
  });

  const saveCardProps = createConfirmationProps({
    content: saveCardContent,
    action: () => history.push("/account/payment"),
    actionText: "Save Credit Card",
    color: "blue",
  });

  const actionProps =
    selectedPlanIsRecurring && !cardOnFile
      ? saveCardProps
      : genericConfirmationProps;

  return (
    <ConfirmActionModal {...actionProps} contentLoading={contentLoading} />
  );
};
