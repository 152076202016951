import { localTypes } from "../reducers/doc-preview";
import types from "../types/doc-preview";

export default {
  update: (payload) => ({ type: types.UPDATE, payload }),
  addViewed: (payload) => ({ type: types.VIEWED, payload }),
  fetchDocumentSummary: (payload) => ({
    type: localTypes.fetchDocument,
    payload,
  }),
  openPopout: (payload) => ({ type: types.OPEN_POPOUT, payload }),
  closePopout: (payload) => ({ type: types.CLOSE_POPOUT, payload }),
  setActiveDoc: (payload) => ({ type: types.SET_ACTIVE_DOC, payload }),
  setActiveView: (payload) => ({ type: types.SET_ACTIVE_VIEW, payload }),
  clearActiveView: (payload) => ({ type: types.CLEAR_ACTIVE_VIEW, payload }),
  setIsMarginalReference: (payload) => ({
    type: types.SET_IS_MARGINAL_REFERENCE,
    payload,
  }),
  clearIsMarginalReference: (payload) => ({
    type: types.CLEAR_IS_MARGINAL_REFERENCE,
    payload,
  }),
  clearError: (payload) => ({ type: types.CLEAR_ERROR, payload }),
  updatePage: (payload) => ({ type: types.UPDATE_PAGE, payload }),
  openAttachmentViewer: (payload) => ({
    type: types.OPEN_ATTACHMENT_VIEWER,
    payload,
  }),
  closeAttachmentViewer: (payload) => ({
    type: types.CLOSE_ATTACHMENT_VIEWER,
    payload,
  }),
  fetchMarginalReference: (payload) => ({
    type: "fetch-a-marginal-reference",
    payload,
  }),
  addMarginalReferenceViewed: (payload) => ({
    type: types.ADD_MARGINAL_REFERENCE_VIEWED,
    payload,
  }),
  removeMarginalReferenceViewed: (payload) => ({
    type: types.REMOVE_MARGINAL_REFERENCE_VIEWED,
    payload,
  }),
  reset: (payload) => ({ type: types.RESET, payload }),
  setPreviewOpen: (payload) => ({ type: types.SET_PREVIEW_OPEN, payload }),
  setPreviewClosed: (payload) => ({ type: types.SET_PREVIEW_CLOSED, payload }),
};
