import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  bottom: 0;
  display: grid;
  grid-template-areas:
    "header"
    "body"
    "footer";
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 10px;
  background-color: var(--color-neutral-lightest);
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default Wrapper;
