import { createOcrHighlights } from "./highlight";

const OcrHits = ({ currentPageOcr, hitIndex, isOcrVisible, ocrScale }) => {
  const canDisplayOcr = !isNaN(ocrScale) && !!currentPageOcr?.length;

  const makeHighlight = createOcrHighlights({
    hitIndex,
    isOcrVisible,
    ocrScale,
  });

  if (!canDisplayOcr || !isOcrVisible) return null;

  return <g>{currentPageOcr.map((hit, i) => hit.map(makeHighlight(i)))}</g>;
};

export default OcrHits;
