import includes from "ramda/src/includes";
import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  hasError,
  isInit,
  isLoading as isStatusLoading,
  hasLoaded,
} from "../../../../../../constants";
import { selectors } from "../../../../../../store";
import { isAbsent, isPresent } from "../../../../../../utils";
import RemovePhoneWarning from "./modal.remove-phone";
import { useFetchUserSettings } from "./use-fetch-settings";
import { useUpdateUserInfo } from "./use-update-user-info";

const messages = {
  mustConsent: "You must consent to receive alerts by phone or text.",
  mustEnterPhone:
    "You must enter a phone number to receive notifications by voice or sms.",
};

const reconcileFormData = (incoming) => (prev) => {
  const keys = Object.keys(prev);
  return keys.reduce((acc, key) => {
    if (key === "contactPreferences" && isPresent(incoming.alertMethods)) {
      acc[key] = isAbsent(incoming.alertMethods)
        ? prev[key]
        : incoming.alertMethods;
    } else {
      acc[key] = isAbsent(incoming[key]) ? prev[key] : incoming[key];
    }
    return acc;
  }, {});
};

const PREFERENCE_MAP = {
  contactByPhone: "voice",
  contactByText: "sms",
  contactByEmail: "email",
};

export const useUpdateProfile = (modal) => {
  const { configuration, user: userConfig } = selectors;
  const settings = useSelector(userConfig.getSettings);
  const user = useSelector(userConfig.getUserDetails);
  const { initiate, status, error, data } = useUpdateUserInfo();
  const fetchSettings = useFetchUserSettings();
  const fraudAlert = useSelector(configuration.getFraudAlert);

  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phoneNumber || "",
    contactPreferences: settings?.contactPreferences || [],
    consentToContact: settings?.consentToContact || false,
  });

  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const alertMethods = propOr([], "alertMethods", fraudAlert);
  const featureEnabled = fraudAlert.enabled === true && isPresent(alertMethods);
  const countyUsesPhone =
    includes("sms", alertMethods) || includes("voice", alertMethods);

  useEffect(() => {
    if (settings && shouldFetchSettings) {
      setIsLoading(true);
      fetchSettings.initiate();
    }
  }, []);

  useEffect(() => {
    if (hasLoaded(fetchSettings.status)) {
      setIsLoading(false);
      setFormData(reconcileFormData(fetchSettings.data));
    }
  }, [fetchSettings.status]);

  useEffect(() => {
    if (hasLoaded(status)) {
      setIsLoading(false);
      setFormData(reconcileFormData(data));
    }
    if (hasError(status) && error?.reason?.message) {
      setIsLoading(false);
      setFormError(error?.reason?.message);
    }
  }, [status]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name in PREFERENCE_MAP) {
      setFormData((prev) => ({
        ...prev,
        contactPreferences: checked
          ? [...new Set([...prev.contactPreferences, PREFERENCE_MAP[name]])]
          : prev.contactPreferences.filter(
              (pref) => pref !== PREFERENCE_MAP[name]
            ),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = (event) => {
    setFormError("");
    event.preventDefault();

    const contactBy = (type) => formData.contactPreferences.includes(type);
    const anyPhoneContact = contactBy("sms") || contactBy("voice");
    if (anyPhoneContact && !formData.phoneNumber) {
      setFormError(messages.mustEnterPhone);
      return;
    }

    if (anyPhoneContact && !formData.consentToContact) {
      setFormError(messages.mustConsent);
      return;
    }

    const updateUserInfo = () => {
      const payload = {
        phoneNumber: formData.phoneNumber === "" ? null : formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
        consentToContact: formData.consentToContact,
        alertMethods: formData.contactPreferences,
      };

      setIsLoading(true);
      initiate(payload);
    };

    if (formData.phoneNumber === "" && featureEnabled && countyUsesPhone) {
      modal.open(
        RemovePhoneWarning({
          close: modal.close,
          updateUserInfo,
          status,
          error: error?.reason?.message || "",
          actionCopy: isStatusLoading(status)
            ? "Loading..."
            : "Remove Phone Number",
        })
      );
    } else {
      updateUserInfo();
    }
  };

  const contactPrefsMissing =
    isAbsent(settings?.consentToContact) ||
    isAbsent(settings?.contactPreferences);

  const shouldFetchSettings =
    featureEnabled && contactPrefsMissing && isInit(fetchSettings.status);

  return {
    formData,
    handleSubmit,
    formError,
    handleInputChange,
    isLoading:
      isLoading || shouldFetchSettings || isStatusLoading(fetchSettings.status),
  };
};
