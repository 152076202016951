import contains from "ramda/src/contains";
import curryN from "ramda/src/curryN";
import filter from "ramda/src/filter";
import { isPresent } from "../../../../utils";
import styles from "./entry.styles";
import AddSupportDoc from "./entry.support-doc.button.add";
import { attachmentIdsBy, displaySupportDocIfInCart } from "./entry.utils";

const AddSupportDocButtons = (props) => {
  const {
    attachments = [],
    docId,
    docType,
    includedAttachments = [],
    instrumentNumber,
    type,
  } = props;

  const numIncludedAttachments = includedAttachments.length;
  const numAttachments = attachments.length;
  const attRemainder = numAttachments - numIncludedAttachments;
  const includedAttachmentsIds = attachmentIdsBy("id", includedAttachments);

  const attachmentsToAdd = filter(
    ({ id }) => !contains(id, includedAttachmentsIds),
    attachments
  );

  const makeSupportDoc = curryN(2, (type, item = {}, i = 1) => ({
    type,
    itemId: item.id,
    docId,
    docType,
    totalPages: item.totalPages,
    selectedPages: "all",
    deliveryMethod: "download",
    instrumentNumber,
    name: `${type} ${i}`,
  }));

  const hasAttachmentsToShow = isPresent(attachments) && attRemainder > 0;

  const shouldShow = displaySupportDocIfInCart(false, props);

  const show = {
    attachments: (type !== "attachment") & hasAttachmentsToShow,
    transcription: shouldShow("transcription"),
    translation: shouldShow("translation"),
  };

  const fullAttachmentsToAdd = attachmentsToAdd.map(
    makeSupportDoc("attachment")
  );

  const hideAll = !show.attachments && !show.transcription && !show.translation;

  if (hideAll) return null;

  return (
    <>
      <div css={styles.entryOption}>Include:</div>
      <AddSupportDoc
        show={show.attachments}
        text={`Attachments (${attRemainder})`}
        data={fullAttachmentsToAdd}
      />
      <AddSupportDoc
        show={show.transcription}
        text={"Transcription (1)"}
        data={[makeSupportDoc("transcription", props.transcription)]}
      />
      <AddSupportDoc
        show={show.translation}
        text={"Translation (1)"}
        data={[makeSupportDoc("translation", props.translation)]}
      />
    </>
  );
};

export default AddSupportDocButtons;
