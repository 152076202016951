import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import { withHandlers } from "recompose";
import SignInModal from "../App/screens/SignIn/modal";
import { actions } from "../store";
import { isNotNil } from "../utils";
import withModal from "./withModal";

const withCartActions = (modalChild) =>
  compose(
    withModal,
    withHandlers({
      addToCart: (props) => (docs) => () => {
        const cartData = {
          cartID: props.shoppingCartId,
          department: props.departmentCode,
          itemIDs: docs,
        };

        props.dispatch(actions.user.addDocumentsToCart(cartData));
        props.modal.open(
          modalChild({
            currentPage: props.currentPage,
            close: props.modal.close,
          })
        );
      },
      addAttachmentToCart: (props) => (items) => () => {
        const cartData = {
          cartID: props.shoppingCartId,
          itemIDs: items,
        };

        props.dispatch(actions.user.addAttachmentsToCart(cartData));
        props.modal.open(
          modalChild({
            currentPage: props.currentPage,
            close: props.modal.close,
          })
        );
      },
      expressCheckout: (props) => (docs) => () => {
        const cartData = { department: props.departmentCode, itemIDs: docs };

        props.dispatch(actions.user.expressCheckout(cartData));

        const openExpressCheckout = () =>
          props.modal.open(() =>
            modalChild({
              currentPage: props.currentPage,
              close: props.modal.close,
            })()
          );

        if (props.loggedIn) return openExpressCheckout();

        return props.modal.open(
          SignInModal({
            actionOnSignIn: openExpressCheckout,
            close: props.modal.close,
          })
        );
      },
      download: (props) => () => () => {
        const { departmentCode, docId, loggedIn, modal, dispatch } = props;

        const { pageCount, instrumentNumber, imageId, docTypeCode } =
          props.documentData;

        const docType = isNotNil(docTypeCode)
          ? docTypeCode
          : props.documentData.docType;

        const payload = {
          item: {
            department: departmentCode,
            docId,
            docType,
            id: imageId,
            instrumentNumber,
            totalPages: pageCount,
          },
        };

        const fetchDownload = () => dispatch(actions.download.fetch(payload));

        loggedIn
          ? fetchDownload()
          : modal.open(
              SignInModal({ actionOnSignIn: fetchDownload, close: modal.close })
            );
      },
      purchaseCertifiedCopy: (props) => (docs) => () => {
        const externalCertifications = prop("externalCertifications", props);

        const openExternalCertifications = () => {
          const { copy = {}, url } = externalCertifications;

          if (window.confirm(copy.exitConfirmation)) {
            window.location.href = url;
          }
        };

        if (externalCertifications.enabled === true && props.loggedIn) {
          openExternalCertifications();
          return;
        }

        const cartData = { department: props.departmentCode, itemIDs: docs };
        props.dispatch(actions.user.expressCheckout(cartData));

        const openExpressCheckout = () => {
          if (externalCertifications.enabled)
            return openExternalCertifications();

          return props.modal.open(
            modalChild({
              currentPage: props.currentPage,
              certifiedCopy: true,
              close: props.modal.close,
            })
          );
        };

        if (props.loggedIn) return openExpressCheckout();

        return props.modal.open(
          SignInModal({
            actionOnSignIn: openExpressCheckout,
            close: props.modal.close,
          })
        );
      },
    })
  );

export default withCartActions;
