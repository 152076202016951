import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const styles = {
  warningContainer: css`
    display: grid;
    grid-template-columns: auto max-content;
    gap: 16px;
    align-items: center;
    padding: 12px 16px;
    background-color: #fff7ed;
    border: 1px solid #fdba74;
    border-radius: 4px;
    color: #9a3412;
    margin-bottom: 16px;
  `,

  button: css`
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    background-color: var(--color-primary-600);
    color: white;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    border: none;

    img {
      margin-right: 6px;
      filter: brightness(0) invert(1);
    }
  `,
};

export const ExternalServiceWarning = () => {
  const { assets, configuration } = selectors;
  const warningIcon = useSelector(assets.getImg("icon_warning_circle.svg"));
  const externalLinkIcon = useSelector(assets.getImg("icon_external_link.svg"));
  const extCerts = useSelector(configuration.getExternalCertProvider);
  const countyName = useSelector(configuration.getCountyName);

  const sp = extCerts?.serviceProvider;
  const serviceProvider = sp?.charAt(0).toUpperCase() + sp?.slice(1);

  if (!extCerts?.enabled) return null;

  return (
    <div css={styles.warningContainer}>
      <span css={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <img src={warningIcon} alt="Warning" width={20} height={20} />
        To view certified document orders for {countyName}, please visit{" "}
        {serviceProvider}&#39;s website.
      </span>
      <a
        css={styles.button}
        href={extCerts?.make.receiptUrl({})}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={externalLinkIcon}
          alt={`External link to ${serviceProvider}`}
          width={16}
          height={16}
        />
        Visit {serviceProvider}
      </a>
    </div>
  );
};
