import { Types } from "../doc-detail.view-document.images/constants";
import AdditionalImages from "./additional-images";
import Attachments from "./attachments";
import { main } from "./style";
import Summary from "./Summary";
import Tab from "./tab";
import TabIds from "./tab-ids";
import Tabs from "./tabs";
import TabsContext from "./tabs-context";
import useTabs from "./use-tabs";

const ViewDocumentSummary = ({ showDetails }) => {
  const { availableTabs, ...tabData } = useTabs();

  if (!showDetails) return null;

  return (
    <div css={main.metadata}>
      <TabsContext.Provider value={tabData}>
        <Tabs>
          <Tab id={TabIds.Summary}>
            <Summary />
          </Tab>

          {availableTabs.transcription && (
            <Tab id={TabIds.Transcription}>
              <AdditionalImages type={Types.Transcription} />
            </Tab>
          )}

          {availableTabs.translation && (
            <Tab id={TabIds.Translation}>
              <AdditionalImages type={Types.Translation} />
            </Tab>
          )}

          {availableTabs.attachments && (
            <Tab id={TabIds.Attachments}>
              <Attachments />
            </Tab>
          )}
        </Tabs>
      </TabsContext.Provider>
    </div>
  );
};

export default ViewDocumentSummary;
