import curry from "ramda/src/curry";
import style from "./style";

export const createOcrHighlights = curry((props, index, hit) => {
  const { hitIndex, isOcrVisible, ocrScale } = props;
  const [baseX, baseY, width, height] = hit;
  const isSelected = index === hitIndex;
  const isHighlighted = isSelected && isOcrVisible;

  const x = Number.isFinite(baseX / ocrScale) ? baseX / ocrScale : 0;
  const y = Number.isFinite(baseY / ocrScale) ? baseY / ocrScale : 0;

  return (
    <rect
      x={x}
      y={y}
      width={width / ocrScale}
      height={height / ocrScale}
      css={style.highlight(isHighlighted)}
      key={`${x}:${y}:${width}:${height}`}
    />
  );
});
