import { css } from "@emotion/react";
import pathOr from "ramda/src/pathOr";
import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { RadioSet } from "../../components/Fields";
import { isNotNil } from "../../utils";
import {
  getRadioOptions,
  getNumPages,
  checkInRange,
  formatPageSelections,
} from "./page-selection.utils";

const styles = {
  inputWrap: css`
    padding-top: 0.4rem;
  `,
  input: css`
    background: var(--color-neutral-200);
    border-radius: var(--border-radius-sm);
    box-sizing: border-box;
    font-size: 13.5px;
    font-weight: 300;
    padding: var(--spacing-sm);
    margin-top: var(--spacing-sm);
    width: 100%;
  `,
};

const PageSelection = (props) => {
  const [radioValue, setRadioValue] = useState("all");
  const [inputValue, setInputValue] = useState("");
  const [hasError, setHasError] = useState(false);

  const updateRadioValue = (event) => {
    const { value } = event.target;
    setRadioValue(value);
    broadcastSelectedPages({ radio: value });
  };

  const updateHasError = (hasE) => {
    setHasError(hasE);
    hasE ? props.onError() : props.onClearError();
  };

  const updateInputValue = (event) => {
    const value = pathOr("", ["target", "value"], event);
    const pageLimitExceeded = getNumPages(value) > props.totalPages;
    const outOfRange = !checkInRange(props.totalPages, value);

    if (pageLimitExceeded || outOfRange) {
      updateHasError(true);
      setInputValue(value);
    } else {
      updateHasError(false);
      setInputValue(value);
      broadcastSelectedPages({ input: value });
    }
  };

  const broadcastSelectedPages = ({ radio, input }) => {
    const inputVal = isNotNil(input) ? input : inputValue;
    const radioVal = isNotNil(radio) ? radio : radioValue;

    if (radioVal === "all") {
      props.onChange("all");
    } else if (radioVal === "custom" && inputVal) {
      props.onChange(formatPageSelections(inputVal));
    } else if (radioVal === "currentPage") {
      props.onChange(formatPageSelections(String(props.currentPage)));
    }
  };

  const disabled = props.disabled || props.totalPages === 1;

  const localProps = {
    radio: {
      disabled: disabled,
      radioOptions: getRadioOptions(props.currentPage),
      inputProps: {
        value: radioValue,
        onChange: updateRadioValue,
      },
    },
    input: {
      type: "text",
      placeholder: "e.g. 2-4, 6, 8",
      disabled: disabled || radioValue !== "custom",
      value: inputValue,
      onChange: updateInputValue,
      debounceTimeout: 500,
    },
  };

  if (props.itemCount > 1) return null;

  return (
    <>
      <div css={styles.inputWrap}>
        <RadioSet {...localProps.radio} />
        <label htmlFor="page-input" className="visually-hidden">
          Page Range
        </label>
        <DebounceInput
          css={styles.input}
          id="page-input"
          {...localProps.input}
        />
      </div>
      {hasError && (
        <div className="error-message">Values must be within page limit.</div>
      )}
    </>
  );
};

export default PageSelection;
