import { useEffect, useState } from "react";
import { isTranscription } from "../doc-detail.view-document.images/constants";
import useOcr from "./use-ocr";

const initialPageState = {
  transcription: 0,
  translation: 0,
};

export default (id) => {
  const [transcription, setTranscription] = useState({});
  const [translation, setTranslation] = useState({});
  const [currentPage, setCurrentPage] = useState(initialPageState);

  const transcriptionOcr = useOcr(transcription, currentPage.transcription);
  const translationOcr = useOcr(translation, currentPage.translation);

  const set = ({ type, ...payload }) => {
    const isTranscript = isTranscription(type);
    const fn = isTranscript ? setTranscription : setTranslation;
    const setPage = (page) => {
      setCurrentPage((prev) => ({ ...prev, [type]: page }));
    };

    fn({ ...payload, setCurrentPage: setPage });
  };

  const clearImages = () => {
    setTranscription({});
    setTranslation({});
  };

  useEffect(() => {
    setCurrentPage(initialPageState);
  }, [id]);

  return {
    set,
    clearImages,
    transcription: {
      ...transcription,
      ...transcriptionOcr,
      currentPage: currentPage.transcription,
    },
    translation: {
      ...translation,
      ...translationOcr,
      currentPage: currentPage.translation,
    },
  };
};
