import LegalLink from "./legal-link";

const LegalDescriptions = ({ data = [] }) =>
  data.map(({ description }, i) => (
    <li
      key={i}
      className="doc-preview-group__summary-group-item"
      data-testid="docPreviewLegalDescription"
    >
      <LegalLink value={description} />
    </li>
  ));

export default LegalDescriptions;
