import types from "../types/notifications";

const DEFAULT_DELAY = 3000;

export default {
  addNotification: (payload) => ({
    type: types.ADD_NOTIFICATION,
    payload: {
      id: payload.id || Date.now().toString(),
      delay: DEFAULT_DELAY,
      ...payload,
    },
  }),
  removeNotification: (payload) => ({
    type: types.REMOVE_NOTIFICATION,
    payload,
  }),
};
