import { push } from "connected-react-router";
import curry from "ramda/src/curry";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Thumbnail } from "../../../../components";
import { withModal } from "../../../../hoc";
import { actions, selectors } from "../../../../store";
import { getPriceFromCents, mapIndexed } from "../../../../utils";
import { TableRow } from "./_entry.table-row";
import { TableText } from "./_entry.table-text";
import styles from "./entry.styles";
import AddSupportDocButtons from "./entry.support-doc.buttons";
import { Column } from "./entry.table-column";
import { Row } from "./entry.table-row";
import {
  getSelectedPages,
  attachmentIdsBy,
  COLUMN_SIZES,
  displaySupportDocIfInCart,
} from "./entry.utils";

const makeTableRow = curry((docProps, props, i) => (
  <Row key={i} num={i} {...docProps} {...props} />
));

export const Entry = (props) => {
  const {
    cartId,
    docId,
    docType,
    id,
    includedAttachments = [],
    type,
    translation,
    transcription,
  } = props;

  const dispatch = useDispatch();
  let location = useLocation();
  const workspaceID = useSelector(selectors.workspaces.getActiveWorkspaceID);
  const removeFromCart = (cartId, itemIds) => () =>
    dispatch(actions.cart.removeFromCart({ cartId, itemIds: itemIds }));

  const { configuration, cart } = selectors;
  const cartHash = useSelector(cart.getHash);
  const docTypeLookupMap = useSelector(configuration.getDocTypeLookupTable);
  const cartData = cartHash[id] || {};
  const thumbnailUrl = cartData.thumbnail || cartData.thumbnailUrl;

  const includedAttachmentsCartItemIds = attachmentIdsBy(
    "cartItemId",
    includedAttachments
  );

  const isAttachment = type === "attachment";
  const tagText = isAttachment ? "Attachment" : docTypeLookupMap[docType];

  const shouldShow = displaySupportDocIfInCart(true, props);

  const docProps = { docID: id, cartId, removeFromCart };

  const MappedAttachments = mapIndexed(
    makeTableRow(docProps),
    includedAttachments
  );

  const includedTransX = [];
  const addTransX = (data, type) => includedTransX.push({ ...data, type });

  if (shouldShow("translation")) addTransX(translation, "translation");
  if (shouldShow("transcription")) addTransX(transcription, "transcription");

  const MappedTranslationTranscription = mapIndexed(
    makeTableRow(docProps),
    includedTransX
  );

  const selectedPages = getSelectedPages(props.selectedPages, props.totalPages);

  return (
    <div css={styles.wrapper}>
      <div className="cart-entry__thumbnail-wrap">
        <Thumbnail
          isCertifiedCopy={cartData.isCertifiedCopy}
          className="cart-entry__thumbnail"
          src={thumbnailUrl}
          docID={docId}
          openPreview={launchPreview}
        />
      </div>
      <header css={styles.header}>
        <div className="cart-entry__tag">{tagText}</div>
        <button
          className="cart-entry__remove"
          onClick={removeFromCart(cartId, [
            id,
            ...includedAttachmentsCartItemIds,
          ])}
        >
          Remove from cart
        </button>
        <AddSupportDocButtons {...props} />
      </header>
      <div css={styles.tableHeader}>
        <Column size={COLUMN_SIZES.LARGE}>
          <TableText>Instrument Number</TableText>
        </Column>
        <Column size={COLUMN_SIZES.SMALL}>
          <TableText>Pages</TableText>
        </Column>
        <Column size={COLUMN_SIZES.MEDIUM}>
          <TableText>Delivery Method</TableText>
        </Column>
        <Column size={COLUMN_SIZES.SMALL}>
          <TableText>Price</TableText>
        </Column>
        {props.certificationFee && (
          <Fragment>
            <Column size={COLUMN_SIZES.SMALL}>
              <TableText>Cert Fee</TableText>
            </Column>
            <Column size={COLUMN_SIZES.SMALL}>
              <TableText>Total</TableText>
            </Column>
          </Fragment>
        )}
      </div>
      <div css={styles.tableBody}>
        <TableRow>
          <Column size={COLUMN_SIZES.LARGE}>
            <div
              className="cart-entry__main-number"
              data-testid="cartItemInstrumentNumber"
            >
              {props.instrumentNumber}
            </div>
          </Column>
          <Column size={COLUMN_SIZES.SMALL} dataTestId="cartItemPages">
            {selectedPages}
          </Column>
          <Column
            size={COLUMN_SIZES.MEDIUM}
            dataTestId="cartItemDeliveryMethod"
          >
            Download
          </Column>
          <Column size={COLUMN_SIZES.SMALL} dataTestId="cartItemPrice">
            {getPriceFromCents(props.price)}
          </Column>
          {props.certificationFee && (
            <Fragment>
              <Column size={COLUMN_SIZES.SMALL} dataTestId="cartItemCertFee">
                {getPriceFromCents(props.certificationFee)}
              </Column>
              <Column size={COLUMN_SIZES.SMALL} dataTestId="cartItemCertFee">
                {getPriceFromCents(props.total)}
              </Column>
            </Fragment>
          )}
        </TableRow>
        {MappedTranslationTranscription}
        {MappedAttachments}
      </div>
    </div>
  );

  function launchPreview() {
    const { pathname, search } = location;
    dispatch(actions.workspaces.saveSearchUrl(`${pathname}${search}`));
    dispatch(
      push({
        pathname: `/doc/${docId}`,
        state: { workspaceID, workspaceTitle: `Doc #${docId}` },
      })
    );
  }
};

export default withModal(Entry);
