import complement from "ramda/src/complement";
import includes from "ramda/src/includes";
import is from "ramda/src/is";
import propOr from "ramda/src/propOr";
import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../App/screens/SearchResults/components/download.error-modal";
import SignInModal from "../../App/screens/SignIn/modal";
import { hasError, hasLoaded, isLoading, Status } from "../../constants";
import { selectors } from "../../store";
import { isAbsent, isPresent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";
import downloadInIframe from "../doc-detail.view-document/download-in-iframe";
import useFetchDownloadUrl from "../doc-detail.view-document/use-fetch-download-url";

const isNot = complement(is);
const isNotAnObject = isNot(Object);

export default (modal) => {
  assertValid(modal);
  const docData = useDocument();
  const downloadData = useFetchDownloadUrl();
  const [status, setStatus] = useState(Status.Init);

  const { status: downloadStatus, initiate, data, error } = downloadData;
  const { configuration, user } = selectors;
  const department = useSelector(configuration.getConfigForDepartment);
  const isLoggedIn = useSelector(user.getLoggedIn);
  const loading = isLoading(status);

  if (loading) {
    if (hasError(downloadStatus)) {
      const modalProps = {
        errorMessage: error?.reason?.message,
        close: modal.close,
      };

      modal.open(Modal(modalProps));
      setStatus(Status.Error);
    } else if (hasLoaded(downloadStatus) && isPresent(data)) {
      downloadInIframe(data.pdfPath, () => setStatus(Status.Loaded));
    }
  }

  const initiateDownload = () => {
    if (isAbsent(docData)) return;

    const payload = {
      item: {
        department: docData?.department,
        docId: docData?.id,
        docType: docData?.docType,
        id: docData?.imageId,
        instrumentNumber: docData?.instrumentNumber,
        totalPages: docData?.pageCount,
      },
    };

    setStatus(Status.Loading);
    initiate(payload);
  };

  const downloadDoc = () => {
    const action = { actionOnSignIn: initiateDownload, close: modal.close };
    const openSignInModal = () => modal.open(SignInModal(action));

    isLoggedIn ? initiateDownload() : openSignInModal();
  };

  const available = includes("download", propOr([], "actions", department));

  return {
    available,
    downloadDoc,
    text: loading ? "Loading..." : "Download (Free)",
    disabled: loading || isAbsent(docData) || isAbsent(docData.urls),
  };
};

export function assertValid(modal) {
  if (isNotAnObject(modal)) {
    throw new Error("modal must be a object");
  }
}
