// FIXME This is a duplicate of src/App/screens/Search/components/DateRangeSelect/components/InlineDatePicker
import classnames from "classnames";
import PropTypes from "prop-types";
import isNil from "ramda/src/isNil";
import { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import {
  formatDateRange,
  noop,
  parseDateRange,
  isAbsent,
} from "../../../utils";

class InlineDatePicker extends Component {
  constructor(props) {
    super(props);

    this.storeStartRef = (input) => void (this.startDatePicker = input);
    this.storeEndRef = (input) => void (this.endDatePicker = input);
    this.focusStartDatePicker = () => this.startDatePicker.setFocus();
    this.focusEndDatePicker = () => this.endDatePicker.setFocus();
  }

  render() {
    const {
      id,
      defaultValue,
      value = null,
      onChange = noop,
      maxRange,
      error = false,
      assets,
      className,
      disabled,
    } = this.props;

    const deserializedDates = isNil(value)
      ? parseDateRange(defaultValue)
      : parseDateRange(value);

    const { startDate: minStartDate, endDate: maxEndDate } =
      parseDateRange(maxRange);

    const toDate = (maybeDate) => {
      if (isAbsent(maybeDate)) return maybeDate;

      if ("toDate" in maybeDate && typeof maybeDate.toDate === "function") {
        return maybeDate.toDate();
      }

      return maybeDate;
    };

    const commonProps = {
      autoComplete: "off",
      disabled,
      dateFormat: ["MM/dd/yyyy", "MMddyyyy"],
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: "select",
      minDate: toDate(minStartDate),
      maxDate: toDate(maxEndDate),
      startDate: toDate(deserializedDates.startDate),
      endDate: toDate(deserializedDates.endDate),
      className,
    };

    const setStartDate = (startDate) =>
      onChange(formatDateRange(startDate, commonProps.endDate));

    const setEndDate = (endDate) =>
      onChange(formatDateRange(commonProps.startDate, endDate));

    const datePickerClass = classnames(
      "form-field__row",
      "form-field-inline-datepicker",
      {
        "form-field-inline-datepicker__error": error,
      }
    );

    return (
      <div className={datePickerClass}>
        <div className="form-field__half-wrap">
          <DatePicker
            {...commonProps}
            id={id}
            ref={this.storeStartRef}
            showMonthDropdown={false}
            openToDate={toDate(commonProps.startDate || commonProps.minDate)}
            selected={toDate(commonProps.startDate)}
            selectsStart
            onChange={setStartDate}
          />
          <button
            type="button"
            className="form-field-inline-datepicker__date-button"
            onClick={this.focusStartDatePicker}
          >
            <div className="form-field-inline-datepicker__flex-wrap">
              <img src={assets.img["icon_calendar.svg"]} alt="calendar" />
            </div>
          </button>
        </div>
        <div className="form-field__middle-wrap">
          <span className="form-field__to">TO</span>
        </div>
        <div className="form-field__half-wrap">
          <DatePicker
            {...commonProps}
            customInput={<input aria-label="end date" type="text" />}
            ref={this.storeEndRef}
            showMonthDropdown={false}
            openToDate={toDate(commonProps.endDate || commonProps.maxDate)}
            selected={toDate(commonProps.endDate)}
            selectsEnd
            onChange={setEndDate}
          />
          <button
            type="button"
            className="form-field-inline-datepicker__date-button"
            onClick={this.focusEndDatePicker}
          >
            <div className="form-field-inline-datepicker__flex-wrap">
              <img src={assets.img["icon_calendar.svg"]} alt="calendar" />
            </div>
          </button>
        </div>
      </div>
    );
  }
}

InlineDatePicker.displayName = "InlineDatePicker";

InlineDatePicker.propTypes = {
  assets: PropTypes.shape({
    img: PropTypes.object,
  }).isRequired,
};

InlineDatePicker.propTypes = {
  id: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  className: PropTypes.string,
  maxRange: PropTypes.string,
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ assets }) => ({ assets });

export default connect(mapStateToProps)(InlineDatePicker);
