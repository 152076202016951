import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import PartyLink from "./parties.link";

const Parties = ({ data = [] }) => {
  const partyFields = useSelector(selectors.configuration.getDeptParties);
  const department = useSelector(selectors.docPreview.getDepartment);

  return (
    <div
      className="doc-preview-group__summary-group-item"
      data-testid="docPreviewParty"
    >
      {data.map(({ name, type }, i) => {
        return (
          <Fragment key={`${name}:${type}:${i}`}>
            <PartyLink
              term={name}
              partyFields={partyFields}
              department={department}
            />
            <span className="doc-preview-group__summary-group-label">
              {type}
            </span>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Parties;
