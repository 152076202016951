import { toArray } from "../../../../../../../utils";

function mapLotBlock({ legals = [] }) {
  return legals.map((obj) => {
    return `${obj.lowLot || "--"}/${obj.block || "--"}/${obj.block2 || "--"}/${
      obj.block3 || "--"
    }`;
  });
}

export function formatData({ data, key }) {
  if (key === "lotBlock") {
    return mapLotBlock(data);
  }

  return toArray(data[key]);
}
