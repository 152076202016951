export * from "./checkout";
export * from "./status";
export * from "./workspaces";
export * from "./routes";

export const genericError = "There's been an error, please try again later";

export const SUBSCRIPTION_STATUS = {
  NEW: "new",
  ACTIVE: "active",
  CANCELED: "canceled",
  UNPAID: "unpaid",
};

export const DOC_PREVIEW = {
  VIEW_TYPES: {
    IMAGE: "image",
    SUMMARY: "summary",
    ATTACHMENTS: "attachments",
    ATTACHMENT_IMAGE: "attachmentImage",
  },
  IMAGE_VIEW_TYPES: {
    FULL_PAGE: "full-page",
    THUMBNAIL: "thumbnail",
  },
};

export const SEARCH_RESULTS = {
  LIMIT_OPTIONS: ["25", "50", "100", "250"],
  DEFAULT_LIMIT: "50",
  DEFAULT_OFFSET: "0",
};
