import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { countyFusionEscrowLogin } from "../../../constants";
import { useAsset } from "../../../hooks/use-asset";
import { selectors } from "../../../store";
import { Account } from "./account";
import { Connecting } from "./connecting";
import { styles } from "./styles";
import { useCheckAccountConnection } from "./use-check-account-connection";

export const CountyFusion = () => {
  const logo = useAsset("favicon-32x32.png");

  const { data, initiate, status } = useCheckAccountConnection();

  const loginUrl = useSelector(selectors.configuration.getCountyFusionLoginUrl);
  const tenant = useSelector(selectors.configuration.getTenant);

  const callbackUrl = useMemo(() => {
    const url = new URL(loginUrl);
    url.searchParams.append("county", tenant.code);
    url.searchParams.append("callback", countyFusionEscrowLogin);
    return url.href;
  }, [loginUrl, tenant.code]);

  useEffect(() => {
    initiate();
  }, []);

  return (
    <div css={styles.integration}>
      <div css={styles.connectionType}>
        <img src={logo} alt={"county fusion"} css={styles.logo} />
        <div>
          <h4>County Fusion</h4>
          <p>Buy documents directly with your County Fusion Account.</p>
        </div>
      </div>
      <div css={styles.divider} />
      <div css={styles.account}>
        {status === "Loading" ? (
          <Connecting />
        ) : (
          <Account callbackUrl={callbackUrl} username={data?.username} />
        )}
      </div>
    </div>
  );
};
