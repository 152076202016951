import compose from "ramda/src/compose";
import propOr from "ramda/src/propOr";
import { connect } from "react-redux";
import { actions, selectors } from "../../store";

const mapStateToProps = (state) => ({
  checkout: {
    inProgress: selectors.checkout.getInProgress(state),
    success: selectors.checkout.getSuccess(state),
    error: selectors.checkout.getError(state),
    orderId: propOr(null, "id", selectors.checkout.getOrder(state)),
  },
  card: {
    isLoading: selectors.user.getLoading(state),
    info: selectors.user.getCardInfo(state),
    saved: selectors.user.getUseSavedCard(state),
  },
  ncourt: {
    enabled: selectors.configuration.isNCourtEnabled(state),
    isPaid: selectors.ncourt.isOrderPaid(state),
  },
  isFirstQuote: selectors.documents.isFirstQuoteFetched,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  expressCheckout: dispatchProps.purchase,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserCards: () => dispatch(actions.user.fetchUserCards()),
  expressCheckout: (checkoutProps) =>
    dispatch(actions.checkout.expressCheckout(checkoutProps)),
  purchase: compose(dispatch, actions.checkout.purchase),
  clearError: () => dispatch(actions.checkout.clearError()),
  clearCheckout: () => dispatch(actions.checkout.clear()),
  clearQuotes: () => dispatch(actions.documents.clearQuotes()),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
