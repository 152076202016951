import styled from "@emotion/styled";

const Title = styled.h4`
  font-size: 2em;
  color: #041d44;
  padding: 20px;
  text-align: center;
`;

export default Title;
