import { noop } from "../../utils";
import { tour as initialState } from "../initialState";
import types from "../types/tour";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [types.RUN]: run,
  [types.CANCEL]: cancel,
});

function run(state, { payload }) {
  return {
    ...state,
    run: true,
    steps: payload.steps,
    onEnd: payload.onEnd || noop,
  };
}

function cancel(state) {
  return {
    ...state,
    run: false,
    steps: [],
    onEnd: noop,
  };
}
