import { DOC_PREVIEW } from "../../constants";
import { Status } from "../enums/status";

export default {
  status: Status.Init,
  previewOpen: false,
  activeView: DOC_PREVIEW.VIEW_TYPES.IMAGE,
  currentPage: 1,
  previewSettings: {
    zoom: 1,
    rotation: 0,
    fit: "best",
  },
  viewed: [],
  marginalReferencesViewed: [],
  document: {
    id: null,
    activeAttachment: null,
    data: {},
    isMarginalReference: false,
  },
  isLoading: true,
  error: "",
};
