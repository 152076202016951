import styled from "@emotion/styled";

export const Info = styled.div`
  border-bottom: 1px solid rgba(4, 29, 68, 0.15);
  display: flex;

  & p {
    color: #041d44;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.8;
    padding: 4px 0;
  }

  & p:first-of-type {
    flex-basis: 135px;
  }

  & p:last-of-type {
    color: var(--color-primary-900);
    font-weight: 400;
  }

  &:last-of-type {
    border: unset;
  }
`;
