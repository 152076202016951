import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { default as OrganizationInfo } from "../../../../../units/account-settings.organization";
import { SettingsPane } from "../settings-pane";

const Organization = ({ organizationEnabled }) => {
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const name = organizationName.length > 0 ? `: ${organizationName}` : "";

  useEffect(() => {
    if (organizationEnabled === false) {
      dispatch(push(`account`));
    }
  }, [organizationEnabled]);

  return (
    <SettingsPane title={`Organization${name}`}>
      <OrganizationInfo setOrganizationName={setOrganizationName} />
    </SettingsPane>
  );
};

export default Organization;
