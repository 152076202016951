import compose from "ramda/src/compose";
import curry from "ramda/src/curry";
import flip from "ramda/src/flip";
import prop from "ramda/src/prop";
import view from "ramda/src/view";
import lens from "../lenses/assets";
import { getLens } from "./utils";

export const get = getLens(lens);
export const getAll = view(lens);

export const getImg = curry((imgName, state) =>
  compose(prop(imgName), get(lens.img))(state)
);

export const getBoundImg = flip(getImg);
