import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isAbsent } from "../../utils";
import Container from "./_error.bar.container";

const styles = (warn) => ({
  icon: css`
    flex-basis: 15px;
    flex-shrink: 0;
    height: 15px;
    width: 15px;
    margin-top: 3px;
    color: ${warn ? "#824900" : "unset"};
  `,

  text: css`
    color: ${warn ? "#824900" : "#c4382f"};
    font-size: 14px;
    margin-left: 10px;

    a {
      color: #236fce;
      text-decoration: underline;
      cursor: pointer;
    }
  `,
});

const ErrorBar = ({ error = "", warn = false }) => {
  const img = `icon_warning_triangle${warn ? "_yellow.svg" : ".svg"}`;
  const warningIcon = useSelector(selectors.assets.getImg(img));
  const style = styles(warn);

  if (isAbsent(error)) {
    return null;
  }

  return (
    <Container warn={warn}>
      <img css={style.icon} src={warningIcon} alt="warning" />
      <span css={style.text}>{error}</span>
    </Container>
  );
};

export default ErrorBar;
