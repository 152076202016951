import complement from "ramda/src/complement";
import equals from "ramda/src/equals";
import has from "ramda/src/has";
import identity from "ramda/src/identity";
import is from "ramda/src/is";
import Rpath from "ramda/src/path";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import values from "ramda/src/values";
import { useEffect, useState, useRef } from "react";
import uuidv4 from "uuid/v4";
import { Status } from "../constants/status";
import { isNotNil } from "../utils";
import { useSocket } from "./use-socket";

const lacks = complement(has);
const isNot = complement(is);
const isNotAString = isNot(String);
const isNotAnObject = isNot(Object);
const isNotAFunction = isNot(Function);

export const protocols = {
  WS: "ws",
  HTTP: "http",
};

export const contentTypes = {
  json: "application/json",
  text: "text/plain",
};

export const useAPI = (props) => {
  assertValid(props);

  const {
    send: sendType,
    listenFor,
    transform,
    initialState,
    protocol = protocols.WS,
    method = "GET",
    defaultQuery = {},
    type = "json",
  } = props;

  const { send, responses } = useSocket({ listenFor: values(listenFor) });
  const [data, setData] = useState(initialState || null);
  const [status, setStatus] = useState(Status.Init);
  const [error, setError] = useState(null);
  const exportAs = propOr("data", "exportAs", props);
  const initiateAs = propOr("initiate", "initiateAs", props);

  const reset = () => {
    setStatus(Status.Init);
    setError(null);
    setData(initialState || null);
  };

  const clear = () => {
    setData(initialState || null);
  };

  if (protocol === protocols.HTTP) {
    // TODO allow plain text requests as well as JSON
    // https://github.com/kofile/ko-search-react/issues/2845
    const initiate = async ({ payload, query, resourcePath = [] } = {}) => {
      setStatus(Status.Loading);
      setError(null);

      const cid = uuidv4();
      const url = constructHttpUrl(sendType, resourcePath);

      // TODO add abort functionality
      // TODO move to WebWorkers
      // https://github.com/kofile/ko-search-react/issues/2846
      try {
        const newUrl = new URL(url.href);
        const contentType = contentTypes[type] ?? "json";

        const fetchOpts = {
          method,
          headers: { "Content-Type": contentType },
        };

        if (isNotNil(payload)) {
          fetchOpts.body =
            contentType === contentTypes.json
              ? JSON.stringify(payload)
              : payload;
        }

        if (isNotNil(query)) {
          const q = { ...defaultQuery, ...query, cid };

          for (const key in q) {
            newUrl.searchParams.set(key, q[key]);
          }
        } else {
          newUrl.searchParams.set("cid", cid);
        }

        if (process.env.IS_CLIENT === "t") {
          newUrl.searchParams.set("ort", window.__ort);
        }

        const data = await fetch(newUrl.href, fetchOpts).then(async (resp) => {
          const txt = await resp.text();

          try {
            return { text: JSON.parse(txt), status: resp.status };
          } catch (_) {
            return { text: txt, status: resp.status };
          }
        });

        if (data.status !== 200) {
          const message = "There's been an error, please try again later.";
          setError({ reason: { message } });
          setStatus(Status.Error);
        } else {
          setData((transform || identity)(data.text));
          setStatus(Status.Loaded);
        }
      } catch (error) {
        setStatus(Status.Error);
        console.info("encountered an error", error);
      }
    };

    return {
      [initiateAs]: initiate,
      [exportAs]: data,
      error,
      status,
      setData,
      reset,
      clear,
    };
  }

  if (protocol === protocols.WS) {
    const cid = useRef(uuidv4());

    const initiate = (payload) => {
      send(sendType, payload, cid.current);
      setStatus(Status.Loading);
      setError(null);
    };

    useEffect(() => {
      if (has("initialPayload", props)) {
        initiate(props.initialPayload);
      }

      responses.on(listenFor.success, (message) => {
        if (message.__cid === cid.current) {
          setData((transform || identity)(message.payload));
          setStatus(Status.Loaded);
        }
      });

      responses.on(listenFor.failure, (message) => {
        if (message.__cid === cid.current) {
          setError(message.payload);
          setStatus(Status.Error);
        }
      });

      return () => {
        responses.off(listenFor.success);
        responses.off(listenFor.failure);
      };
    }, []);

    return {
      [initiateAs]: initiate,
      [exportAs]: data,
      error,
      status,
      setData,
      reset,
      clear,
    };
  }
};

const constructHttpUrl = (sendType, resourcePath = []) => {
  const getFromWindow = (loc) => Rpath(["location", loc], window);
  const path = resourcePath.length > 0 ? `/${resourcePath.join("/")}` : "";

  const makeUrl = (p = "") =>
    new URL(
      `/cb/${sendType.replace(/^\//, "")}${p}`,
      `${getFromWindow("protocol")}//${getFromWindow("host")}`
    );

  return makeUrl(path);
};

export function assertValid(props, { isNotFake = true } = {}) {
  if (isNotAnObject(props)) {
    throw new Error("props must be an object");
  }

  if (lacks("send", props) || isNotAString(props.send)) {
    throw new Error("props.send must be a string");
  }

  if (has("protocol", props) && equals(prop("protocol", props), protocols.WS)) {
    if (lacks("listenFor", props) || isNotAnObject(props.listenFor)) {
      throw new Error("props.listenFor must be an object");
    }

    if (
      lacks("success", props.listenFor) ||
      isNotAString(props.listenFor.success)
    ) {
      throw new Error("props.listenFor.success must be a string");
    }

    if (
      lacks("failure", props.listenFor) ||
      isNotAString(props.listenFor.failure)
    ) {
      throw new Error("props.listenFor.failure must be a string");
    }
  }

  if (has("transform", props) && isNotAFunction(props.transform)) {
    throw new Error("props.transform must be a function");
  }

  if (isNotFake) {
    if (has("data", props)) {
      throw new Error("props.data cannot be defined in live code");
    }

    if (has("loadingTime", props)) {
      throw new Error("props.loadingTime cannot be defined in live code");
    }

    if (has("error", props)) {
      throw new Error("props.error cannot be defined in live code");
    }
  }
}
