import styled from "@emotion/styled";

const Footer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-end")};
  padding: 10px;
  grid-area: footer;

  & button {
    margin: 0 5px 0 5px;
    padding: 10px 15px;
  }
`;

export default Footer;
