export const radioOptions = [
  {
    label: "All",
    value: "all",
    id: "radio1",
  },
  {
    label: "Current Page",
    value: "currentPage",
    id: "radio2",
  },
  {
    label: "Custom",
    value: "custom",
    id: "radio3",
  },
];
