import { useAPI } from "../../../../../../hooks/use-api";

export const useFetchUserSettings = () =>
  useAPI({
    send: "fetch-user-settings",
    listenFor: {
      success: "fetched-user-settings",
      failure: "error-fetching-user-settings",
    },
  });
