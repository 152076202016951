import { parse } from "query-string";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { A11yAnnouncement, ReturnButton } from "../../../components";
import SignInForm from "./View";

const SignIn = () => {
  const { search } = useLocation();
  const query = parse(search);
  const isVerified = query.verified === "true";

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <A11yAnnouncement>Navigated to sign in page</A11yAnnouncement>
      <ReturnButton />
      <SignInForm verified={isVerified} />
    </>
  );
};

export default SignIn;
