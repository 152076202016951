import { Close } from "@kofile/icons";
import classnames from "classnames";
import PropTypes from "prop-types";
import merge from "ramda/src/merge";
import pick from "ramda/src/pick";
import { withProps } from "recompose";
import Link from "../../components/Link";
import { noop, e } from "../../utils";

const tabClass = (isActive) =>
  classnames("tab", {
    "is-active": isActive,
  });

const enhanceTab = withProps((props) => {
  const { id, title, path, onSelect, closeHandler = noop } = props;

  return {
    onClick: () => onSelect({ id, title, path }),
    requestClose: () => closeHandler(id),
  };
});

const Tab = enhanceTab((props) => {
  const {
    path,
    title,
    isActive,
    onClick,
    type,
    images,
    exIcon,
    requestClose,
    isLink,
    isDisabled = false,
  } = props;

  const clickableElement = (children) =>
    isLink ? (
      <Link to={path} onClick={onClick}>
        {children}
      </Link>
    ) : (
      <button onClick={onClick} disabled={isDisabled} type="button">
        {children}
      </button>
    );

  const icon =
    images && images[type] && e(images[type], { className: "tabs__icon" });

  const clickableElementChildren = (
    <div className="children">
      {icon}
      <div className="linkText" data-testid="tab">
        {title}
      </div>
    </div>
  );

  return (
    <li className={tabClass(isActive)}>
      {clickableElement(clickableElementChildren)}
      {exIcon && (
        <button
          onClick={requestClose}
          className="closeButton"
          aria-label="Close Workspace"
        >
          <Close id="tabs-close-icon" className="tabs__close" />
        </button>
      )}
    </li>
  );
});

const Tabs = (props) => {
  const className = classnames(props.className, "tabs");

  return (
    <div className={className}>
      <ul className="tabs">
        {props.entries.map((entry, i) => {
          const tabProps = merge(
            pick(
              ["exIcon", "onSelect", "images", "closeHandler", "isLink"],
              props
            ),
            entry
          );

          return <Tab key={i} {...tabProps} />;
        })}
      </ul>
    </div>
  );
};

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

Tabs.propTypes = {
  className: PropTypes.string,
};

Tabs.defaultProps = {
  onSelect: () => {},
};

Tab.displayName = "Tab";
Tabs.displayName = "Tabs";

export default Tabs;
