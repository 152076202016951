import { Text } from "./_search-text.text";
import { Wrapper } from "./_search-text.wrapper";
import { useSearchText } from "./use-search-text";

export const SearchText = (props) => {
  const { label, value } = useSearchText(props);

  return (
    <Wrapper data-testid="resultsSummary">
      <span aria-label="Search Result Totals">{label}</span>{" "}
      {value && <Text>{` "${value}"`}</Text>}
    </Wrapper>
  );
};
