import anyPass from "ramda/src/anyPass";
import cond from "ramda/src/cond";
import has from "ramda/src/has";
import head from "ramda/src/head";
import identity from "ramda/src/identity";
import is from "ramda/src/is";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import prop from "ramda/src/prop";
import { isValidElement } from "react";
import { formatDateOnly } from "../../../../../utils";
import { ListNotAvailable } from "./_list-view.not-available";

const isNilOrEmpty = anyPass([isNil, isEmpty]);
const isArray = is(Array);
const isObj = is(Object);

const isDate = (value, key) => {
  if (key.toLowerCase().includes("date") === false) return false;

  return /^\d{4}-\d{2}-\d{2}$/.test(value);
};

const makeNode = (value) => (
  <span dangerouslySetInnerHTML={{ __html: value }} />
);
const defaultNode = () => <ListNotAvailable>N/A</ListNotAvailable>;

const arrayNode = (arr) => {
  const node = makeNode(head(arr));
  return arr.length ? node : defaultNode();
};

const createLabel = (v) => <span>{prop("text", v[0])}</span>;
const needsLabel = (v) => isArray(v) && isObj(v[0]) && has("text", v[0]);

const formatCellByDataType = cond([
  [isNilOrEmpty, defaultNode],
  [needsLabel, createLabel],
  [isArray, arrayNode],
  [isValidElement, identity],
  [isDate, formatDateOnly],
  [is(String), makeNode],
  [isObj, identity],
]);

export default formatCellByDataType;
