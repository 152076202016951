import filter from "ramda/src/filter";
import isNil from "ramda/src/isNil";
import map from "ramda/src/map";
import path from "ramda/src/path";
import pipe from "ramda/src/pipe";
import { useEffect, useState } from "react";
import { isLoading } from "../../../constants";
import { isPresent } from "../../../utils";
import { useFetchDocument } from "./use-fetch-document";

export const useManageExtendedGroups = (id) => {
  const { status, fetchDoc } = useFetchDocument();

  const [data, setData] = useState({});
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const toggle = (groupKey, fetchKey) => {
    const unit = {
      fetchKey,
      isOpen: isNil(data[groupKey]) ? true : !data[groupKey].isOpen,
    };

    setData({ ...data, [groupKey]: unit });
  };

  const generate = (groupKey = "") => {
    const keys = [...Object.keys(data)];
    if (!keys.includes(groupKey)) {
      keys.push(groupKey);
      setShouldRefetch(true);
    }

    const removeClosed = (key) => {
      const isOpen = path([key, "isOpen"], data);
      const shouldToggle = groupKey === key && isPresent(data[key]);

      if (shouldToggle) return !isOpen;
      return isOpen === true || isOpen === undefined;
    };

    const correctKeyNames = (key) => {
      const correctedKey = path([key, "fetchKey"], data);
      return isPresent(correctedKey) ? correctedKey : key;
    };

    const formatByKey = pipe(
      filter(removeClosed),
      map(correctKeyNames),
      filter(isPresent),
      (v) => [...new Set(v)]
    );

    return formatByKey(keys);
  };

  const reconcile = (groupKey, fetchKey) => {
    toggle(groupKey, fetchKey);
    return generate(groupKey);
  };

  useEffect(() => {
    if (shouldRefetch) {
      fetchDoc({ id, extendedProperties: generate() });
      setShouldRefetch(false);
    }
  }, [data]);

  return {
    data,
    reconcile,
    loading: isLoading(status),
  };
};
