import { css } from "@emotion/react";
import { useDispatch } from "react-redux";
import { ModalShell } from "../../../../../../components";
import { withModal } from "../../../../../../hoc";
import { actions } from "../../../../../../store";
import { WhiteButton as Remove } from "../../../../../../units/account-settings.common/_button.white";
import RemoveCardModal from "./RemoveCardModal";

const style = css`
  grid-area: remove-button;
`;

const Modal = ({ cardId, resetRemoveCard, close }) =>
  Object.assign(
    () => {
      const onClose = () => {
        resetRemoveCard();
        close();
      };

      return (
        <ModalShell close={onClose}>
          <RemoveCardModal onClose={onClose} cardId={cardId} />
        </ModalShell>
      );
    },
    { displayName: "RemoveCardModal" }
  );

const RemoveButton = ({ cardId, disabled, hidden, modal }) => {
  const dispatch = useDispatch();
  const resetRemoveCard = () => dispatch(actions.user.resetRemoveCard());

  const modalProps = { resetRemoveCard, cardId, close: modal.close };
  const openModal = () => modal.open(Modal(modalProps));

  if (hidden) return null;

  return (
    <Remove css={style} type="button" disabled={disabled} onClick={openModal}>
      Remove
    </Remove>
  );
};

RemoveButton.defaultProps = {
  disabled: false,
  hidden: false,
};

export default withModal(RemoveButton);
