import { useState } from "react";
import { Fit } from "./constants";

export default () => {
  const [fit, setFit] = useState(Fit.Best);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);

  const rotateLeft = () =>
    setRotation((current) => (current === 0 ? 270 : current - 90));
  const rotateRight = () =>
    setRotation((current) => (current === 270 ? 0 : current + 90));

  const zoomIn = () => setZoom((current) => Math.min(5, current + 0.25));
  const zoomOut = () => setZoom((current) => Math.max(1, current - 0.25));

  const fitWidth = () => {
    setZoom(1);
    setFit(Fit.Width);
  };

  const fitHeight = () => {
    setZoom(1);
    setFit(Fit.Height);
  };

  const fitBest = () => {
    setZoom(1);
    setFit(Fit.Best);
  };

  return {
    modifiers: {
      fit,
      rotation,
      zoom,
    },
    controls: {
      fitWidth,
      fitHeight,
      fitBest,
      rotateLeft,
      rotateRight,
      zoomIn,
      zoomOut,
    },
  };
};
