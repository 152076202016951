import styled from "@emotion/styled";

export const ClerkPicture = styled.div`
  grid-area: clerk-pic;
  align-self: center;

  img {
    border-radius: 50%;
    height: 75px;
    object-fit: cover;
    width: 75px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    display: none;
  }
`;
