import styled from "@emotion/styled";

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: #041d44;
  grid-area: header;
  padding: 20px;
`;

export default Header;
