import view from "ramda/src/view";
import { createSelector } from "reselect";
import { onlineStatus as initialState } from "../initialState";
import lens from "../lenses/online-status";
import { getLens } from "./utils";

export const get = getLens(lens);

export const isConnected = createSelector(
  get(lens.connectedAt),
  get(lens.disconnectedAt),
  get(lens.attemptConnectAt),
  (connectedAt, disconnectedAt, attemptConnectAt) => {
    const notDisconnected = connectedAt > disconnectedAt;
    const notAttemptingToConnect = connectedAt > attemptConnectAt;
    const initialDisconnectedAt = view(lens.disconnectedAt, initialState);
    const previouslyDisconnected = disconnectedAt !== initialDisconnectedAt;

    return notDisconnected && notAttemptingToConnect && previouslyDisconnected;
  }
);

export const isDisconnected = createSelector(
  get(lens.connectedAt),
  get(lens.disconnectedAt),
  get(lens.attemptConnectAt),
  (connectedAt, disconnectedAt, attemptConnectAt) => {
    const notConnected = disconnectedAt > connectedAt;
    const notAttemptingToConnect = disconnectedAt > attemptConnectAt;

    return notConnected && notAttemptingToConnect;
  }
);

export const isConnecting = createSelector(
  get(lens.connectedAt),
  get(lens.disconnectedAt),
  get(lens.attemptConnectAt),
  (connectedAt, disconnectedAt, attemptConnectAt) => {
    const attemptingConnect = attemptConnectAt > connectedAt;
    const notDisconnected = attemptConnectAt > disconnectedAt;
    const initialDisconnectedAt = view(lens.disconnectedAt, initialState);
    const previouslyDisconnected = disconnectedAt !== initialDisconnectedAt;

    return attemptingConnect && notDisconnected && previouslyDisconnected;
  }
);
