import { CheckMark as CheckMarkBoxIcon } from "@kofile/icons";
import classnames from "classnames";
import PropTypes from "prop-types";
import isNil from "ramda/src/isNil";
import { Component } from "react";
import { noop } from "../../utils";

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      isKeyboardFocused: false,
    };

    this.handleChange = (event) => {
      this.setState({
        checked: event.target.checked,
      });
      this.props.onChange(event);
    };

    this.handleBlur = () => {
      this.setState({
        isKeyboardFocused: false,
      });
    };

    this.handleFocus = () => {
      this.setState({
        isKeyboardFocused: true,
      });
    };
  }

  render() {
    const {
      disabled,
      name,
      id,
      label,
      className,
      labelPosition,
      showDash,
      isChecked,
      ...inputProps
    } = this.props;

    const checked = isNil(isChecked) ? this.state.checked : isChecked;

    const checkboxClassNames = () => {
      const { isKeyboardFocused } = this.state;

      return classnames("checkbox", {
        "checkbox--isChecked": checked,
        "checkbox--disabled ": disabled,
        "checkbox--focused": isKeyboardFocused,
        "checkbox--position-left": labelPosition === "left",
      });
    };

    const containerClassName = classnames(className, checkboxClassNames());

    const labelElement = (
      <span className="checkbox__label" key={`${id}-label`}>
        {label}
      </span>
    );

    const iconElement = (
      <span className="checkbox__container" key={`${id}-icon`}>
        {checked && (
          <CheckMarkBoxIcon id="checkbox-icon" className="checkbox__icon" />
        )}
      </span>
    );

    const dashElement = (
      <span className="checkbox__dash-container" key={`${id}-icon`}>
        <div className="checkbox__dash-icon" />
      </span>
    );

    const elementsInOrder = (() => {
      const elementToShow = showDash ? dashElement : iconElement;
      const elements = [elementToShow, labelElement];

      return (
        <div className="checkbox__controls">
          {labelPosition === "right" ? elements : elements.reverse()}
        </div>
      );
    })();

    return (
      <label htmlFor={id} className={containerClassName}>
        <input
          {...inputProps}
          id={id}
          type="checkbox"
          name={name}
          className="checkbox__input"
          checked={checked}
          disabled={disabled}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
        {elementsInOrder}
      </label>
    );
  }
}

Checkbox.displayName = "Checkbox";

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  showDash: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelPosition: PropTypes.oneOf(["left", "right"]),
};

Checkbox.defaultProps = {
  disabled: false,
  onChange: noop,
  labelPosition: "right",
};

export default Checkbox;
