import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBar } from "../../../../components";
import { actions, selectors } from "../../../../store";
import { ExpiredSubscriptionMessage } from "../../../../units/common/error.bar.expired.subscription";
import { getPriceFromCents } from "../../../../utils";
import { ConvenienceFees } from "./checkout.convenience-fees";
import { PurchaseButton } from "./checkout.purchase-button";
import { style } from "./checkout.styles";
import { CheckoutTotal } from "./checkout.total";

const Checkout = () => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { cart, checkout, configuration, ncourt, user } = selectors;
  const nCourtEnabled = useSelector(configuration.isNCourtEnabled);
  const nCourtError = useSelector(ncourt.checkoutError);
  const checkoutError = useSelector(checkout.getError);
  const userCanCheckout = useSelector(user.userCanCheckout);
  const loggedIn = useSelector(user.getLoggedIn);
  const pricingInfo = useSelector(cart.getPricingInfo);
  const ncourtUrl = useSelector(ncourt.getCheckoutUrl);
  const isCheckoutStale = !useSelector(checkout.isCheckoutFresh);
  const usePaymentMethods = useSelector(configuration.usePaymentMethods);

  const {
    certificationFeeSubtotal = 0,
    convenienceFee = 0,
    subtotal = 0,
    totalPrice = 0,
  } = pricingInfo;

  const disableSubmit = (e) => {
    e.stopPropagation();
    setSubmitDisabled(true);
  };

  useEffect(() => {
    if (isCheckoutStale) {
      dispatch(actions.cart.fetch());
      dispatch(actions.checkout.makeCheckoutFresh());
    }

    if (checkoutError || nCourtError) setSubmitDisabled(false);

    return () => {
      if (checkoutError) dispatch(actions.checkout.clearError());
      if (nCourtError) dispatch(actions.ncourt.clearError());
    };
  }, []);

  const toList = (obj) =>
    Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join(",");

  const initializeNCourtOrder = (e) => {
    disableSubmit(e);

    const windowFeatures = {
      resizable: "yes",
      scrollbars: "yes",
      status: "yes",
      width: 950,
      height: 950,
    };

    if (userCanCheckout && totalPrice > 0) {
      const windowUrl = ncourtUrl || "/static/loading/ncourt";
      window.open(windowUrl, "NCourtPayment", toList(windowFeatures));
    }

    const forwardPath = loggedIn
      ? "/cart/checkout/"
      : "/cart/checkout?returnPath=/cart/contents";

    dispatch(push(forwardPath));
  };

  const ncourtErrorText = nCourtError
    ? "Unable to contact payment provider.  Please try again later."
    : "";

  const useOldNcourt = !usePaymentMethods && nCourtEnabled;

  return (
    <div css={style.wrap}>
      <ExpiredSubscriptionMessage disableCheckout={setSubmitDisabled} />
      <div css={style.innerWrap}>
        <div css={style.line()} data-testid="checkoutSubtotal">
          <span>Subtotal:</span> {getPriceFromCents(subtotal)}
        </div>
        {Boolean(certificationFeeSubtotal) && (
          <div css={style.line()} data-testid="certificationFeeSubtotal">
            <span>Certification Fees:</span>{" "}
            {getPriceFromCents(certificationFeeSubtotal)}
          </div>
        )}
        <ConvenienceFees convenienceFee={convenienceFee} />
        <CheckoutTotal checkoutError={checkoutError} totalPrice={totalPrice} />
        <ErrorBar error={ncourtErrorText} />

        <PurchaseButton
          onClick={useOldNcourt ? initializeNCourtOrder : disableSubmit}
          nCourtEnabled={useOldNcourt}
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
};

export default Checkout;
