import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isAbsent } from "../../utils";

const style = css`
  padding-bottom: 20px;
  color: #041d44;
  line-height: 1.2;

  a {
    text-decoration: underline;
  }
`;

const formatContactMethods = (unformattedPrefs) => {
  if (isAbsent(unformattedPrefs)) return "email";
  const prefs = unformattedPrefs.map((pref) => {
    if (pref === "sms") return "text message";
    if (pref === "voice") return "voice phone call";
    return pref;
  });

  if (prefs.length === 1) return `email and ${prefs[0]}`;

  const lastItem = prefs[prefs.length - 1];
  const otherItems = prefs.slice(0, -1);
  return `${otherItems.join(", ")} and ${lastItem}`;
};

export const NotificationInfo = () => {
  const { user, configuration } = selectors;
  const preferences = useSelector(user.getContactPreferences);

  const availableMethods = useSelector(
    configuration.availableFraudAlertContactMethods
  );

  if (isAbsent(availableMethods)) return null;

  const contactMethods = formatContactMethods(preferences);

  return (
    <p css={style}>
      You’ll receive alerts via {contactMethods}. Visit your{" "}
      <a href="/account/profile">account settings</a> to update your
      notification preferences.
    </p>
  );
};
