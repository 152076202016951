import { useCallback, useEffect, useReducer, useRef } from "react";

const getDelta = ({ deltas, rotation }) => {
  if (rotation === 180) return { x: deltas.x * -1, y: deltas.y * -1 };
  if (rotation === 270) return { x: deltas.x * -1, y: deltas.y };
  if (rotation === 90) return { x: deltas.x, y: deltas.y * -1 };

  return deltas;
};

const initialState = {
  offset: {
    x: 0,
    y: 0,
  },
  bounds: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  drag: {
    x: 0,
    y: 0,
  },
  isNew: true,
};

const Actions = {
  Drag: "drag",
  Reset: "reset",
  Stop: "stop",
};

const getOffset = ({ state, deltas, isSideways }) => {
  const { isNew, offset, bounds } = state;

  const actualOffset = {
    x: isNew ? offset.x : offset.x - deltas.x,
    y: isNew ? offset.y : offset.y - deltas.y,
  };

  const actualBounds = {
    left: isSideways ? bounds.bottom : bounds.left,
    right: isSideways ? bounds.top : bounds.right,
    top: isSideways ? bounds.right : bounds.top,
    bottom: isSideways ? bounds.left : bounds.bottom,
  };

  if (actualOffset.x > actualBounds.right) {
    actualOffset.x = actualBounds.right;
  } else if (actualOffset.x < actualBounds.left) {
    actualOffset.x = actualBounds.left;
  }
  if (actualOffset.y > actualBounds.top) {
    actualOffset.y = actualBounds.top;
  } else if (actualOffset.y < actualBounds.bottom) {
    actualOffset.y = actualBounds.bottom;
  }

  return actualOffset;
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case Actions.Drag: {
      const { drag, isNew } = state;
      const { rotation } = payload;

      const isSideways = rotation === 270 || rotation === 90;

      const { x, y } = {
        x: isSideways ? payload.y : payload.x,
        y: isSideways ? payload.x : payload.y,
      };

      const deltas = getDelta({
        rotation,
        deltas: {
          x: x - drag.x,
          y: y - drag.y,
        },
      });

      const newOffset = getOffset({
        state,
        deltas,
        isSideways,
      });

      return {
        ...state,
        offset: newOffset,
        drag: {
          x: isNew ? 0 : x,
          y: isNew ? 0 : y,
        },
        isNew: isNew ? false : isNew,
      };
    }
    case Actions.Stop: {
      return {
        ...state,
        isNew: true,
      };
    }
    case Actions.Reset: {
      return { ...initialState, ...payload };
    }
  }
};

export default ({ dimensions, rotation, zoom, on }) => {
  const payload = {
    offset: {
      x: dimensions.img.x,
      y: dimensions.img.y,
    },
    bounds: dimensions.bounds,
  };
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...payload,
  });
  const handlers = useRef({});

  const ref = useCallback(
    (el) => {
      if (el) {
        if (handlers?.current?.pointerdown) {
          handlers.current.pointerdown.remove();
        }

        handlers.current.pointerdown = on.current(el, "pointerdown", (e) => {
          const init = { x: e.clientX, y: e.clientY };

          el.dataset.isDragging = "";

          if (handlers?.current?.pointermove) {
            handlers.current.pointermove.remove();
          }

          handlers.current.pointermove = on.current(el, "pointermove", (ee) => {
            window.requestAnimationFrame(() => {
              const payload = {
                x: init.x - ee.clientX,
                y: init.y - ee.clientY,
                rotation,
              };

              dispatch({
                type: Actions.Drag,
                payload,
              });
            });
          });
        });

        if (handlers?.current?.pointerup) {
          handlers.current.pointerup.remove();
        }

        handlers.current.pointerup = on.current(window, "pointerup", () => {
          dispatch({ type: Actions.Stop });
          delete el.dataset.isDragging;
          handlers.current?.pointermove?.remove?.();
        });
      }
    },
    [rotation]
  );

  useEffect(() => {
    dispatch({
      type: Actions.Reset,
      payload,
    });
  }, [dimensions]);

  useEffect(() => {
    return () => {
      if (handlers?.pointerdown) {
        handlers.pointerdown.remove();
        delete handlers.pointerdown;
      }
      if (handlers?.pointermove) {
        handlers.pointermove.remove();
        delete handlers.pointermove;
      }
      if (handlers?.pointerup) {
        handlers.pointerup.remove();
        delete handlers.pointerup;
      }
    };
  }, []);

  const transform = `rotate(${rotation}deg) scale(${zoom}) translate3d(${state.offset.x}px, ${state.offset.y}px, 0px)`;

  return { ref, drag: state.drag, offset: state.offset, transform };
};
