import not from "ramda/src/not";
import { useSelector } from "react-redux";
import { withModal } from "../../hoc";
import { selectors } from "../../store";
import BlueButton from "../doc-detail.common/_button.blue";
import useDocument from "../doc-detail.common/useDocument";
import useExpressCheckout from "./use-express-checkout";

const ExpressCheckoutButton = ({ modal }) => {
  const document = useDocument();

  const expressProps = { modal, isCertifiedCopy: false, document };
  const { initiateCheckout, disabled, icon } = useExpressCheckout(expressProps);

  const { configuration: config } = selectors;
  const expressEnabled = useSelector(config.expressCheckoutEnabled);

  if (not(expressEnabled)) return null;

  return (
    <BlueButton onClick={initiateCheckout} disabled={disabled}>
      <img src={icon} alt="express checkout button" />
      ExpressCheckout
    </BlueButton>
  );
};

export default withModal(ExpressCheckoutButton);
