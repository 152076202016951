import { LOCATION_CHANGE } from "connected-react-router";
import allPass from "ramda/src/allPass";
import always from "ramda/src/always";
import { ofType } from "redux-observable";
import { filter, map } from "rxjs/operators";
import { notEmptyOrNil } from "../../utils";

export default [handleRouteChange];

function handleRouteChange(action$, $store) {
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(enabled($store)),
    map(({ payload }) => {
      const { pathname, search = "" } = payload;
      const searchString = search && `${search}`;
      const fullPath = `${pathname}${searchString}`;
      const { "google-analytics": gaId } = $store.value.keys;

      window.gtag("config", gaId, {
        page_path: fullPath,
        page_location: window.location.href,
      });

      return { type: "DO_NOTHING" };
    })
  );
}

function enabled($store) {
  return allPass([
    always(process.env.NODE_ENV === "production"),
    always(typeof window !== "undefined"),
    always(notEmptyOrNil($store.value.keys["google-analytics"])),
  ]);
}
