import constructN from "ramda/src/constructN";
import is from "ramda/src/is";

class BEMValidSelector {
  constructor(selector) {
    this._selector = selector;
  }

  get selector() {
    return this._selector;
  }

  toString() {
    return this.selector;
  }

  isValid() {
    if (this.selector && is(String, this.selector)) {
      return true;
    }

    throw new Error("Selector is undefined or not a string!");
  }
}

class BEMModifier extends BEMValidSelector {}

class ModifiableBEMValidSelector extends BEMValidSelector {
  modify(modifier) {
    if (this.isValid()) {
      return new BEMModifier(`${this.selector}--${modifier}`);
    }

    return null;
  }
}

class BEMElement extends ModifiableBEMValidSelector {}

class BEMBlock extends ModifiableBEMValidSelector {
  element(element) {
    return new BEMElement(`${this.selector}__${element}`);
  }
}

export default constructN(1, BEMBlock);
