import styled from "@emotion/styled";

const addBorder = `
  border-bottom: 1px solid #DADDE3;
  border-top: 1px solid #DADDE3;
`;

const hideBorder = `
  border: none;
`;

const Body = styled.div`
  grid-area: body;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  align-items: center;
  justify-content: center;
  padding: 20px;
  ${(props) => (props.hideBorder ? hideBorder : addBorder)}
`;

export default Body;
