import styled from "@emotion/styled";

export const DocActionButton = styled.button`
  background: none;
  color: #243f8a;
  display: block;
  font-weight: 400;
  outline: none;
  padding: 2px;

  &:disabled {
    opacity: 0.5;
  }
`;
