import { useContext } from "react";
import { useSelector } from "react-redux";
import { ModalShell } from "../../components";
import { withModal } from "../../hoc";
import { selectors } from "../../store";
import { Date } from "../account-settings.subscriptions.common/date";
import { ExpiredSubscriptionMessage } from "../common/error.bar.expired.subscription";
import { ChangeButton } from "./_subscriptions.button.change";
import { Subscription } from "./_subscriptions.container";
import { Title } from "./_subscriptions.title";
import NavContext from "./context.nav";
import { CancelModal } from "./modal.cancel";
import { RemoveModal } from "./modal.remove";
import { AdminActions } from "./subscriptions.active.admin";
import { MemberActions } from "./subscriptions.active.member";
import { SingleUserActions } from "./subscriptions.active.single-user";

const shouldShowIf = (subscription) => ({
  is: (status) => (component) =>
    subscription.status === status ? component : null,
});

export const Active = withModal((props) => {
  const nav = useContext(NavContext);
  const { modal, subscription, onCancel, isOrganizationAdmin: isAdmin } = props;
  const { title, id: subscriptionId, type, endDate } = subscription;
  const showIfActive = shouldShowIf(subscription).is("active");
  const isMultiUser = type === "multiUser";
  const isMultiUserAdmin = isMultiUser && isAdmin;
  const isMultiUserMember = isMultiUser && !isAdmin;
  const isSingleUser = !isMultiUser;
  const { close } = modal;

  const userCanChangeSubscription = useSelector(
    selectors.configuration.canUserUpdateSubscription
  );

  const cancel = () => {
    modal.open(() => (
      <ModalShell close={close}>
        {isMultiUserMember ? (
          <RemoveModal
            close={close}
            subscription={subscription}
            onCancel={onCancel}
          />
        ) : (
          <CancelModal
            close={close}
            subscription={subscription}
            onCancel={onCancel}
            isMultiUserAdmin={isMultiUserAdmin}
          />
        )}
      </ModalShell>
    ));
  };

  const changeButton =
    userCanChangeSubscription &&
    isSingleUser &&
    showIfActive(
      <ChangeButton onClick={nav.editSubscription(subscriptionId)}>
        Change Subscription
      </ChangeButton>
    );

  const ActionCopy = subscription.fixedTerm === true ? "Expires" : "Renews";

  const renewDate = showIfActive(
    <Date date={endDate}>{(formatted) => `${ActionCopy} on ${formatted}`}</Date>
  );

  const actionProps = { subscription, cancel, changeButton, renewDate };

  return (
    <Subscription>
      <ExpiredSubscriptionMessage />
      <Title>{title}</Title>
      {isMultiUserAdmin && <AdminActions {...actionProps} />}
      {isMultiUserMember && <MemberActions {...actionProps} />}
      {isSingleUser && <SingleUserActions {...actionProps} />}
    </Subscription>
  );
});
