import styled from "@emotion/styled";

export const Info = styled.div`
  width: 100%;
  z-index: 1;

  & h3 {
    color: #041d44;
    display: block;
    flex-shrink: 1;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.25px;
    margin-top: 0.5rem;
    opacity: 0.75;
    overflow: hidden;
    padding-left: 0.5rem;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;
