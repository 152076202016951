import { whenPropIsTrue } from "../../utils/when-prop-is-true";

const whenDisabled = whenPropIsTrue("disabled");

export const canBeDisabled = whenDisabled({
  backgroundColor: "#dadde2",
  borderColor: "#dadde2",
  color: "#666",
  "&:hover": {
    textDecoration: "unset",
  },
});
