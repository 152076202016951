import range from "ramda/src/range";
import PageButton from "./button.page";
import PageAdvanceButton from "./button.page-advance";
import { style } from "./style";

const getPages = ({ listLength, activePage, maxRange, maxPagesPerRow }) => {
  const maxPage = Math.ceil(listLength / maxRange);
  const start = Math.max(1, activePage - maxPagesPerRow / 2);
  const end = Math.min(maxPage + 1, start + maxPagesPerRow);

  return maxPage < maxPagesPerRow
    ? range(start, maxPage + 1)
    : range(end - maxPagesPerRow, end);
};

const Pagination = (props) => {
  const {
    rangeSize = "10",
    currentPage = "1",
    listLength = 100,
    children = null,
    maxPagesPerRow = 10,
    onChange = () => {},
    showLabel = false,
  } = props;

  if (listLength < rangeSize) return null;

  const activePage = Number(currentPage);
  const maxRange = Number(rangeSize);
  const pages = getPages({ listLength, activePage, maxRange, maxPagesPerRow });
  const validPrev = activePage - 1 > 0;
  const validNext = activePage < listLength / rangeSize;
  const handlePrev = () => (validPrev ? onChange(activePage - 1) : {});
  const handleNext = () => (validNext ? onChange(activePage + 1) : {});
  const firstResultPageIndex = rangeSize * (activePage - 1) + 1;
  const lastResultPageIndex = rangeSize * activePage;

  return (
    <nav aria-label="pagination" className="Pagination">
      {children}

      <div css={style.buttonRow}>
        {showLabel && isFinite(activePage) && (
          <span>
            Showing {firstResultPageIndex}-{lastResultPageIndex} of {listLength}{" "}
            results
          </span>
        )}
        <PageAdvanceButton
          ariaLabel="previous page"
          disabled={!validPrev}
          onChange={handlePrev}
          text="◀"
        />
        {pages.map((value, index) => (
          <PageButton
            key={index}
            onChange={onChange}
            selected={activePage === value}
            value={value}
          />
        ))}
        <PageAdvanceButton
          ariaLabel="next page"
          disabled={!validNext}
          onChange={handleNext}
          text="▶"
        />
      </div>
    </nav>
  );
};

export default Pagination;
