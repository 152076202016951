import { useContext } from "react";
import TabsContext from "./tabs-context";

const Tab = ({ id, children }) => {
  const { activeTab } = useContext(TabsContext);

  if (activeTab !== id) return null;

  return children;
};

export default Tab;
