const tourSteps = [
  {
    title: "Refine Search Results",
    selector: "[data-tourid='filters']",
    position: "right",
    text: "Toggle the checkboxes to apply filters to the search results.",
  },
  {
    title: "Sort Results By Column",
    selector: "[data-tourid='searchResults'] .col3",
    position: "top",
    text: "Click a column header to sort the search results by that column.",
  },
  {
    title: "View Document Image",
    selector: "[data-tourid='searchResults'] .col-1",
    position: "top",
    text: "Click the row to view the document image. Select the checkboxes on the left to choose multiple documents. Click the ... button on a document with images to see available actions.",
  },
  {
    title: "Change Layout Modes",
    selector: "[data-tourid='listTypes']",
    position: "bottom",
    text: "Switch between a compact view or an expanded view of the search results",
  },
  {
    title: "Edit Search Criteria",
    selector: "[data-tourid='editSearch']",
    position: "bottom",
    text: "Edit your existing search criteria to improve your results. Boost relevance by searching indexed fields.",
  },
  {
    title: "Run a New Search",
    selector: "[data-tourid='addWorkspace']",
    position: "right",
    text: "Run a new search or view your past search history.",
  },
  {
    title: "Manage Multiple Tabs",
    selector: "[data-tourid='workspaceTab']",
    position: "bottom",
    text: "Open as many tabs as you want and switch between them.",
  },
  {
    title: "Add Documents To The Cart",
    selector: "[data-tourid='documentActions']",
    position: "left",
    text: "Select the checkbox in each row for the documents you want and add them to your shopping cart.",
  },
  {
    title: "Proceed to Checkout",
    selector: "[data-tourid='cartWidget']",
    position: "bottom",
    text: "When you're all finished, review the documents in your cart and submit your order.",
  },
  {
    title: "Get Assistance Anytime",
    selector: "[data-tourid='helpMenu']",
    position: "bottom",
    text: "Re-take the tour, view tutorials, or chat with a live support person.",
  },
];

export default tourSteps;
