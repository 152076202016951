import useAttachments from "../doc-detail.common/useAttachments";
import ViewSingleAttachment from "./attachments.document";
import AttachmentsList from "./attachments.list";
import styles from "./attachments.style";

const Attachments = () => {
  const { showSingleAttachment } = useAttachments();

  return (
    <div css={styles.container}>
      {showSingleAttachment && <ViewSingleAttachment />}
      {!showSingleAttachment && <AttachmentsList />}
    </div>
  );
};

export default Attachments;
