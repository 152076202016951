import { styles } from "./styles";

export const ConnectAcccount = ({ onClick }) => {
  return (
    <>
      <p>No accounts connected yet.</p>
      <button css={styles.confirmButton} onClick={onClick}>
        Connect Account
      </button>
    </>
  );
};
