import prop from "ramda/src/prop";
import { createElement } from "react";
import { isAbsent, isPresent } from "../../utils";
import { PlanList } from "./_choose-plan.plan.list";
import { ChooseContainer } from "./_choose-plan.plans-container";
import { Title } from "./_choose-plan.plans.title";
import { PickType } from "./choose-plan.pick-type";
import { Plan } from "./choose-plan.plan";

export const Plans = ({
  choose,
  plans: allPlans,
  className,
  setPlanType,
  planType,
  showPlanTypePicker,
  currentSub,
  ...rest
}) => {
  const plansRaw = allPlans[planType];
  const isGovt = prop("isAuthorized");
  const isFixedTerm = prop("fixedTerm");

  const isNotGovtOrFixedTerm = (plan) =>
    !(isGovt(plan) === true || isFixedTerm(plan) === true);

  const plans = plansRaw.filter((plan) => {
    if (isAbsent(currentSub)) return true;
    return isNotGovtOrFixedTerm(plan);
  });

  if (isPresent(currentSub) && isAbsent(plans[0]?.isActivePlan)) {
    plans.unshift({
      isActivePlan: true,
      title: prop("title", currentSub),
      priceLabel: {
        amount: prop("amount", currentSub),
        period: prop("period", currentSub),
        details: prop("description", currentSub),
      },
    });
  }

  const renderPlan = (plan) => {
    return createElement(Plan, {
      plan: createPlanProps(plan, showPlanTypePicker),
      key: plan.title,
      choose,
    });
  };

  const showMultiUser = prop("type", currentSub) !== "individual";

  if (plans.length === 0) {
    return (
      <p>
        No plans are currently available. Please contact support if you believe
        this is an error.
      </p>
    );
  }

  return (
    <article className={className}>
      <Title>Choose your Subscription Level</Title>

      <ChooseContainer>
        {showPlanTypePicker && showMultiUser && (
          <PickType
            pick={setPlanType}
            selected={planType}
            plans={allPlans}
            {...rest}
          />
        )}
        <PlanList>{plans.map(renderPlan)}</PlanList>
        {plans.length === 1 && (
          <p>
            Your plan cannot be changed through the online portal. Please
            contact your county for more information.
          </p>
        )}
      </ChooseContainer>
    </article>
  );
};

// TODO TP69392 normalize payloads on API for pricing info; prefer priceLabel structure
function createPlanProps(plan, showPlanTypePicker) {
  return {
    ...plan,
    showPlanTypePicker,
    amount: plan.priceLabel.amount,
    period: plan.priceLabel.period.replace(/^\//, ""),
    description: plan.priceLabel.details,
  };
}
