import PropTypes from "prop-types";
import { Component } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { actions, selectors } from "../../store";
import { noop } from "../../utils";

export class Tour extends Component {
  constructor(props) {
    super(props);

    this.joyrideRef = (el) => {
      this.joyride = el;
    };

    this.handleEnd = ({ type }) => {
      if (type === "finished") {
        this.joyride.setState({ index: 0 });
        this.props.clearTour();
        this.props.onEnd();
      }
    };
  }

  componentWillUnmount() {
    this.props.clearTour();
    this.props.onEnd();
  }

  render() {
    const { run, steps } = this.props;

    return (
      <Joyride
        autoStart
        ref={this.joyrideRef}
        steps={steps}
        run={run}
        type="continuous"
        disableOverlay
        showBackButton
        showSkipButton
        showStepsProgress
        callback={this.handleEnd}
      />
    );
  }
}

Tour.displayName = "Tour";

Tour.propTypes = {
  run: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object),
  onEnd: PropTypes.func,
};

Tour.defaultProps = {
  onEnd: noop,
  run: false,
  steps: [],
};

const mapStateToProps = (state) => ({
  run: selectors.tour.getRun(state),
  steps: selectors.tour.getSteps(state),
  onEnd: selectors.tour.getOnEnd(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearTour: () => dispatch(actions.tour.cancel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tour);
