import { Container } from "./_input.container";
import { Error } from "./_input.error";
import { Label } from "./_input.label";
import { StyledInput } from "./_input.styled";

export const Input = (props) => {
  const {
    type,
    name,
    error,
    value,
    onChange = () => {},
    disabled = false,
    label = "",
    placeholder,
    style,
  } = props;

  return (
    <Container css={style}>
      {label.length && <Label htmlFor={name}>{label}</Label>}
      <StyledInput
        type={type}
        name={name}
        error={error !== "" && error}
        value={value}
        placeholder={placeholder || label}
        onChange={onChange}
        disabled={disabled}
        aria-label={label}
      />
      {error.length > 0 && <Error error={error}>{error}</Error>}
    </Container>
  );
};
