import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { selectors } from "../store";
import { formatDate } from "../utils";

const { getConfigForDepartment } = selectors.configuration;
const { getCertifiedDate } = selectors.search;

export function useDateRangeMetaLabel() {
  const deptConfig = useSelector(getConfigForDepartment);

  const certifiedDate = useSelector((state) =>
    getCertifiedDate(state, propOr(null, "code", deptConfig))
  );

  if (!deptConfig) return null;

  const deptIsCertified = deptConfig.isCertified === true;
  const configLabel = deptConfig.verifiedStartDate;
  const date = formatDate(certifiedDate);

  if (configLabel && certifiedDate) {
    return `${configLabel} through ${date}`;
  }

  if (deptIsCertified && certifiedDate && !configLabel) {
    return `Certified through ${date}`;
  }

  return null;
}
