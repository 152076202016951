import NavigateButton from "./navigate.button";
import useNavigateDocsByResults from "./use-navigate-docs-by-results";

const NavigateSearchButton = ({ to, style }) => {
  const { navigate, disabled } = useNavigateDocsByResults(to);
  const str = `${to} Result`;

  return (
    <NavigateButton
      navigate={navigate}
      disabled={disabled}
      str={str}
      to={to}
      style={style}
    />
  );
};

export default NavigateSearchButton;
