import PropTypes from "prop-types";
import { parse, stringify } from "query-string";
import concat from "ramda/src/concat";
import omit from "ramda/src/omit";
import { withRouter } from "react-router";
import { Dropdown } from "../";
import { updateSearchParams, resolveSort } from "../../../../../../../utils";

const SortSelector = ({ columns = [], workspaceID, location, history }) => {
  const { sortBy = "relevance" } = parse(location.search);
  const options = makeOptions({ workspaceID, history, location, columns });

  return (
    <Dropdown options={options} label="Arranged By" activeOption={sortBy} />
  );
};

SortSelector.displayName = "SortSelector";

SortSelector.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      locked: PropTypes.bool,
    })
  ),
  workspaceID: PropTypes.string.isRequired,
};

export default withRouter(SortSelector);

// --------------------------------------------------------------------- private

function makeOptions({ workspaceID, history, location, columns }) {
  const relevanceOption = {
    key: "relevance",
    name: "Relevance",
    action: () => removeSort({ workspaceID, location, history }),
  };

  return columns.reduce(
    (acc, column) =>
      column.locked
        ? acc
        : concat(acc, [
            {
              ...column,
              action: sortByKey({
                key: column.key,
                history,
                location,
                workspaceID,
              }),
            },
          ]),
    [relevanceOption]
  );
}

function removeSort({ history, location, workspaceID }) {
  const searchWithoutSort = omit(["sort", "sortBy"], parse(location.search));

  history.push({
    pathname: location.pathname,
    search: stringify(searchWithoutSort),
    state: { workspaceID, refetch: true },
  });
}

function sortByKey({ key, history, location, workspaceID }) {
  const newSort = resolveSort(parse(location.search), key);

  const newSearch = updateSearchParams(location, {
    sortBy: key,
    sort: newSort,
  });

  return () =>
    history.push({
      pathname: location.pathname,
      search: newSearch,
      state: { workspaceID, refetch: true },
    });
}
