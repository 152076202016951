import { useAPI } from "../../hooks/use-api";

export const useCreateSubscription = () =>
  useAPI({
    send: "create-subscription",
    initiateAs: "create",
    listenFor: {
      success: "subscription-created",
      failure: "error-creating-subscription",
    },
  });
