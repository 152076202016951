import prop from "ramda/src/prop";
import { pad } from "../../utils";
import { addDay } from "../account-settings.subscriptions.common/add-day";
import { formatDate } from "../account-settings.subscriptions.common/format-date";

export const notification = {
  error: "An error occurred when trying to update your subscription.",
  success: "Your subscription has been updated.",
};

export const createModalText = ({ subscription, plan }) => {
  const toNum = (currency) => Number(currency.replace(/[^0-9.-]+/g, ""));
  const isUpgrade = toNum(subscription.amount) < toNum(plan.amount);
  const endDate = addDay(subscription.endDate);

  if (prop("changeType", plan) === "upgrade" || isUpgrade) {
    return `The remaining balance on your current subscription will be applied to the new subscription, which will begin today. Today's charge will be ${
      plan.priceLabel.amount
    }, less the prorated amount. Details will be provided on your receipt. Your subscription will automatically renew on ${pad.left(
      endDate
    )} and every month thereafter.`;
  }

  return `Your old subscription will remain active until ${pad(
    formatDate(subscription.endDate)
  )} and then you will be charged the new rate of ${pad(
    plan.priceLabel.amount + plan.priceLabel.period
  )} on ${endDate}.`;
};
