// feat: usePaymentMethods

import has from "ramda/src/has";
import pathOr from "ramda/src/pathOr";

export const getStripeToken = async (stripe, type = "card") => {
  const response = await stripe.createToken({ type });

  if (has("error", response)) {
    if (typeof response.error === "string") throw new Error(response.error);
    let defaultMsg = "There was an error from Stripe";
    const message = pathOr(defaultMsg, ["error", "message"], response);

    return { tokenCreationError: message };
  }

  return { token: response.token.id, type };
};
