import { Item } from "./_input.autocomplete.list-item";

export const Suggestions = ({ suggestions, highlightedIndex, getItemProps }) =>
  suggestions.map((suggestion, index) => (
    <Item
      isHighlighted={highlightedIndex === index}
      key={`${suggestion.value}${index}`}
      {...getItemProps({ item: suggestion.label, index })}
    >
      {suggestion.label}
    </Item>
  ));
