import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../../components";
import { actions, selectors } from "../../../../../store";

const style = {
  loading: css`
    & .loading__spinner {
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 5px;
    }
  `,
  listIcon: css`
    height: 30px;
    width: 30px;
    padding: 5px;
  `,
  uncertifiedIcon: css`
    height: 16px;
    width: 16px;
  `,
};

const uncertifiedDocumentMessage = "This document is not certified.";
const TOOLTIP_ID = "warning-icon";

const RowIcons = ({ id, inCart, isUncertified }) => {
  const dispatch = useDispatch();
  const [iconSrc, setIconSrc] = useState("");
  const [iconLabel, setIconLabel] = useState("");

  const { assets, download: dl } = selectors;
  const downloading = useSelector(dl.isDownloadingForDocId(id));
  const downloaded = useSelector(dl.hasDownloadedForDocId(id));
  const shouldDipslayIcon = iconSrc && !downloading;
  const uncertifiedIcon = useSelector(assets.getImg("icon_row_uncert.svg"));
  const cartIcon = useSelector(assets.getImg("icon_row_cart.svg"));
  const downloadedIcon = useSelector(assets.getImg("icon_download.svg")); //wrong icon
  const uncertifiedCartIcon = useSelector(
    assets.getImg("icon_row_cart_uncert.svg")
  );

  const setIconData = (src, label) => {
    setIconSrc(src);
    setIconLabel(label);
  };

  useEffect(() => {
    if (inCart && isUncertified) {
      setIconData(uncertifiedCartIcon, "uncertified in cart");
    }
    if (downloaded && !inCart) {
      setIconData(downloadedIcon, "downloaded");
    }
    if (inCart) setIconData(cartIcon, "in cart");
    if (isUncertified) setIconData(uncertifiedIcon, "uncertified");
  }, [
    cartIcon,
    downloaded,
    downloadedIcon,
    inCart,
    isUncertified,
    uncertifiedCartIcon,
    uncertifiedIcon,
  ]);

  return (
    <div className="list-view__cart-wrap" data-testid="searchResult">
      {downloading && <LoadingSpinner css={style.loading} />}
      {shouldDipslayIcon && (
        <img
          alt={iconLabel}
          src={iconSrc}
          css={isUncertified ? style.uncertifiedIcon : style.listIcon}
          {...determineAdditionalProps(isUncertified, dispatch)}
        />
      )}
    </div>
  );
};

const determineAdditionalProps = (isUncertified, dispatch) => {
  const showTooltip = (tooltipProps) =>
    dispatch(actions.tooltip.show(tooltipProps));

  const hideTooltip = () => dispatch(actions.tooltip.hide());

  return isUncertified
    ? {
        role: "tooltip",
        onMouseEnter: onWarningHover(showTooltip),
        onMouseLeave: hideTooltip,
        "aria-describedby": TOOLTIP_ID,
      }
    : {};
};

const onWarningHover = (showTooltip) => (event) => {
  const node = event.target;
  const { top, left, height, width } = node.getBoundingClientRect();
  const offsetTop = height / 2 + window.pageYOffset;
  const offsetLeft = width / 2;
  const tooltipProps = {
    top: top + offsetTop,
    left: left + offsetLeft,
    position: "bottom",
    children: uncertifiedDocumentMessage,
    distance: 20,
    tooltipId: TOOLTIP_ID,
  };
  showTooltip(tooltipProps);
};

export default RowIcons;
