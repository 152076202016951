import { Global } from "@emotion/react";
import complement from "ramda/src/complement";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import Bunni from "../bunni";
import {
  A11yAnnouncementContainer,
  CheckEmail,
  ErrorPage,
  Footer,
  Tour,
} from "../components";
import { useAnnouncements } from "../hooks";
import { selectors } from "../store";
import DocDetail from "../units/doc-detail.view-document";
import { isWorkspacePath, matchesBasePath } from "../utils";
import {
  Tooltip,
  Announcements,
  SkipLinks,
  Header,
  Workspaces,
  OnlineStatus,
  Notifications,
} from "./components";
import {
  Account,
  Cart,
  ForgotPassword,
  FraudAlert,
  Notepad,
  NotFound,
  Quicklink,
  Register,
  RefundPolicy,
  ResetPassword,
  Search,
  SearchResults,
  SignIn,
  SignOut,
  Static,
  Verify,
  VerifyCertifiedCopy,
} from "./screens";
import style from "./style";

const useNone = matchesBasePath("/error");
const useAccount = matchesBasePath("/account");
const useLimited = complement(isWorkspacePath);

export const viewTypes = {
  LIMITED: "LIMITED",
  ACCOUNT: "ACCOUNT",
  STANDARD: "STANDARD",
  NONE: "NONE",
};

const determineLayout = (path) => {
  if (useNone(path)) return viewTypes.NONE;
  if (useAccount(path)) return viewTypes.ACCOUNT;
  if (useLimited(path)) return viewTypes.LIMITED;
  return viewTypes.STANDARD;
};

const App = ({ isStatic = false }) => {
  const { pathname } = useLocation();
  const layoutType = determineLayout(pathname);
  const layoutIs = (type) => layoutType === type;
  const isStandardView = layoutIs(viewTypes.STANDARD);
  const isAccountView = layoutIs(viewTypes.ACCOUNT);

  useAnnouncements();

  const { error } = selectors;
  const hasServiceError = useSelector(error.getHasServiceError);

  if (hasServiceError) return <ErrorPage />;

  if (isStatic) {
    return (
      <main>
        <Switch>
          <Route path="/static" component={Static} />
        </Switch>
      </main>
    );
  }

  return (
    <>
      {process.env.IS_CLIENT === "t" && <Bunni />}
      <Global styles={style.global(layoutType)} />
      <A11yAnnouncementContainer />
      <Tour />
      <Tooltip />

      <SkipLinks />
      <Announcements />
      <Header />
      <Workspaces hide={!isStandardView} />
      <OnlineStatus />
      <Notifications />

      {isStandardView && (
        <ReactTooltip
          effect="solid"
          event="mouseover focus"
          eventOff="mouseout blur"
          role="tooltip"
          id="tooltip"
        />
      )}

      <article id="main-content" css={style.content(isAccountView)}>
        <Switch>
          <Route exact path="/" component={Search} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/search/advanced" component={Search} />
          <Route path="/results" component={SearchResults} />
          <Route path="/doc/:id" component={DocDetail} />
          <Route path="/notepad" component={Notepad} />
          <Route path="/quicklink" component={Quicklink} />
          <Route path="/register" component={Register} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signout" component={SignOut} />
          <Route path="/property-alert" component={FraudAlert} />
          <Route path="/forgotpassword/:token" component={ResetPassword} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/cart" component={Cart} />
          <Route path="/account" component={Account} />
          <Route path="/verify/:token" component={Verify} />
          <Route path="/verifycert/:token" component={VerifyCertifiedCopy} />
          <Route
            path="/check-registration-email"
            render={() => <CheckEmail preset="registration" />}
          />
          <Route component={NotFound} />
        </Switch>
      </article>
      <Footer hide={isAccountView} />
    </>
  );
};

export default App;
