import { css } from "@emotion/react";
import { parse } from "query-string";
import includes from "ramda/src/includes";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { selectors } from "../../../../../../../store";
import {
  getNewSearchURL,
  makeAllPartySearchValue,
} from "../../../../../../../utils";

const styles = {
  dataListElem: css`
    & + * {
      margin-top: 10px;
    }

    color: #041d44;
    font-size: 13px;
    display: block;
  `,
  link: css`
    color: #006dd5;
    cursor: pointer;
    padding: 0;
    background: 0;
    margin: 0;
    text-align: left;
  `,
};

export const ListElement = ({ location, dataKey, ...props }) => {
  const { configuration } = selectors;
  const departmentConfig = useSelector(configuration.getConfigForDepartment);
  const shouldHideLinks = useSelector(configuration.isLimitedSearchEnabled);
  const search = prop("search", location);
  const partyFields = prop("partyFields", departmentConfig);

  const value = String(props.value);
  const query = parse(search);

  if (dataKey === "marginalReferences" && !shouldHideLinks) {
    return (
      <Link css={[styles.dataListElem, styles.link]} to={props.value.link}>
        {props.value.text}
      </Link>
    );
  }

  if (includes(dataKey, partyFields)) {
    if (value.includes("See document for full list")) {
      return (
        <button css={[styles.dataListElem, styles.link]} onClick={props.open}>
          {value}
        </button>
      );
    }

    if (!shouldHideLinks) {
      const partySearch = getNewSearchURL({
        query,
        key: "parties",
        value: makeAllPartySearchValue({ term: value, partyFields }),
      });

      return (
        <a
          css={[styles.dataListElem, styles.link]}
          href={partySearch}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    }
  }

  return (
    <div
      css={styles.dataListElem}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default withRouter(ListElement);
