import { isBefore, parse } from "date-fns";
import all from "ramda/src/all";
import allPass from "ramda/src/allPass";
import complement from "ramda/src/complement";
import includes from "ramda/src/includes";
import is from "ramda/src/is";
import pathOr from "ramda/src/pathOr";
import { isPresent } from "../../utils";

const excludes = complement(includes);
const matchRegex = (r) => (s) => s.match(r);
const isDate = (s) => s.match(/^\d{8}$/);

const validationRegexes = {
  instNum: {
    NumericWithSuffix: {
      regex: /^[0-9]+[a-zA-Z]+$/,
      label: "alphasuffixed",
    },
    Numeric: {
      regex: /^[0-9]*$/,
      label: "numeric",
    },
    AlphaNumeric: {
      regex: /^[-a-zA-Z0-9]*$/,
      label: "alphanumeric",
    },
  },
  "year-instNum": {
    NumericWithSuffix: {
      regex: /^[0-9]{4}-[0-9]+[a-zA-Z]+$/,
      label: "alphasuffixed and in (year)-(instrument number) format",
    },
    Numeric: {
      regex: /^[0-9]{4}-[0-9]+$/,
      label: "numeric and in (year)-(instrument number) format",
    },
    AlphaNumeric: {
      regex: /^[0-9]{4}-[a-zA-Z0-9]+$/,
      label: "alphanumeric and in (year)-(instrument number) format",
    },
  },
};

const typeError = (type) => `Input must be ${type}`;
const typeErrorHyphenated = (type) =>
  `Input must be ${type} and in {year}-{number} format`;

const isNumberRange = (
  value,
  validateByRegex,
  label,
  docNumFormat,
  instrumentNumberFormat
) => {
  const splitValues = value.split(",");
  const [from = "", to = ""] = splitValues;

  if (splitValues.length > 2) {
    return "Input must be a valid number range";
  }

  // Allow empty range
  if (!from && !to) {
    return "";
  }

  if (!from || !to) {
    return "Input must be a complete range";
  }

  if (docNumFormat === "instNum") {
    if (!validateByRegex(from) || !validateByRegex(to)) {
      return typeError(label);
    }

    if (
      parseInt(from, 10) > parseInt(to, 10) ||
      parseInt(to, 10) < parseInt(from, 10)
    ) {
      return "Input must be a valid number range";
    }

    return "";
  } else if (docNumFormat === "year-instNum") {
    let [fromYear, fromNumber] = from.split("-");
    let [toYear, toNumber] = to.split("-");

    if (!validateByRegex(from) || !validateByRegex(to)) {
      return typeErrorHyphenated(label);
    }

    if (instrumentNumberFormat === "NumericWithSuffix") {
      fromNumber = fromNumber.replace(/[a-zA-Z]/, "");
      toNumber = toNumber.replace(/[a-zA-Z]/, "");
    }

    if (fromYear !== toYear) {
      return "Number ranges must be in the same year";
    }

    if (
      parseInt(fromNumber, 10) > parseInt(toNumber, 10) ||
      parseInt(toNumber, 10) < parseInt(fromNumber, 10)
    ) {
      return "Input must be a valid number range";
    }

    return "";
  }

  return "";
};

const attemptParse = (val) => {
  let resolvedValue;

  try {
    resolvedValue = JSON.parse(val);
  } catch (e) {
    resolvedValue = val;
  }

  return resolvedValue;
};

export const validateDocumentNumber = (
  value = "",
  { documentNumberFormat, instrumentNumberFormat }
) => {
  const { regex, label } = pathOr(
    {},
    [documentNumberFormat, instrumentNumberFormat],
    validationRegexes
  );

  const validateByRegex = matchRegex(regex);
  const parsedValue = attemptParse(value);

  // Is an array of individual numbers
  if (Array.isArray(parsedValue)) {
    return all(validateByRegex, parsedValue) ? "" : typeError(label);
  }

  // Is a range
  return isNumberRange(
    parsedValue,
    validateByRegex,
    label,
    documentNumberFormat,
    instrumentNumberFormat
  );
};

const validatePresence = (value) =>
  isPresent(value) ? "" : "You must provide a value.";

const validMonth = (value) => {
  if (!is(String, value)) return "";

  const isValid = allPass([isPresent, excludes("-")]);
  const isInvalid = complement(isValid);
  const year = value.slice(0, 4);
  const month = value.slice(4, 6);
  const missingMonth = isInvalid(month);
  const missingYear = isInvalid(year);
  const hasMonth = isValid(month);
  const hasYear = isValid(year);

  if (missingMonth && missingYear) return "";
  if (missingMonth && hasYear) return "You must select a month.";
  if (hasMonth && missingYear) return "You must select a year.";
  if (Number(month) < 1 || Number(month) > 12) return "Invalid month.";
  if (Number(year) < 1600 || Number(year) > 9999) return "Invalid year.";

  return "";
};

export const validateDateRange = (dateRange) => {
  const [fromRaw, toRaw] = dateRange.split(",");

  const isValidDate = !isDate(fromRaw) || !isDate(toRaw);

  if (!fromRaw && !toRaw) return "";
  if (!fromRaw || !toRaw) return "Input must be a complete date range";

  const from = parse(fromRaw, "yyyyMMdd", new Date());
  const to = parse(toRaw, "yyyyMMdd", new Date());

  if (isValidDate) {
    return "Dates must be in the format MM/DD/YYYY";
  }

  if (isBefore(to, from)) {
    return '"From" date must come before "to" date';
  }

  return "";
};

const validations = {
  presence: validatePresence,
  dateRange: validateDateRange,
  commaSeparated: {
    method: "matches",
    arg: /^.*$/,
    error: "Input must be a list of comma separated names",
  },
  isAlpha: {
    method: "isAlpha",
    error: "Input must only contain letters A-Z",
  },
  isInt: {
    method: "isInt",
    arg: { min: 0 },
    error: "Input must be a whole number",
  },
  documentNumberRange: validateDocumentNumber,
  simpleChars: {
    method: "matches",
    arg: /^.*$/,
    error: "Input must only contain letters, numbers, and simple punctuation",
  },
  lengthMax5: {
    method: "isLength",
    arg: { min: 0, max: 5 },
    error: "Maximum length is five",
  },
  lengthMax8: {
    method: "isLength",
    arg: { min: 0, max: 8 },
    error: "Maximum length is eight",
  },
  lengthMax20: {
    method: "isLength",
    arg: { min: 0, max: 5 },
    error: "Maximum length is 20",
  },
  month: validMonth,
};

export default validations;
