import hoistStatics from "hoist-non-react-statics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "../store";

const withKeys = (Component) => {
  const C = (props) => <Component {...props} />;

  C.displayName = `withKeys(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  C.propTypes = {
    keys: PropTypes.shape({
      stripe: PropTypes.string,
    }),
  };

  const mapStateToProps = (state) => ({ keys: selectors.keys.getKeys(state) });

  return hoistStatics(connect(mapStateToProps)(C), Component);
};

export default withKeys;
