import { useSelector } from "react-redux";
import { ModalShell, Stripe } from "../../components";
import { CHECKOUT_TYPES, ENTITY_TYPES } from "../../constants";
import { withKeys } from "../../hoc";
import { selectors } from "../../store";
import ExpressCheckout from "../checkout.modal.express";

const View = ({ close }) => {
  const { exportResults, workspaces } = selectors;
  const exportResultsData = useSelector(exportResults.getExportResultsData);
  const department = useSelector(workspaces.getSelectedDepartment);

  const props = {
    common: {
      department,
      items: [exportResultsData],
      close,
      entityType: ENTITY_TYPES.EXPORT,
    },
    header: {
      checkoutType: CHECKOUT_TYPES.EXPRESS,
      entityType: ENTITY_TYPES.EXPORT,
    },
    info: {
      totalPagesSelected: exportResultsData.pages,
      rows: exportResultsData.rows,
      totalPrice: exportResultsData.totalPrice,
      convenienceFee: exportResultsData.convenienceFee,
    },
  };

  return (
    <Stripe>
      <ExpressCheckout {...props} />
    </Stripe>
  );
};

const ViewWithKeys = withKeys(View);

const ModalChild = (close) =>
  Object.assign(
    () => (
      <ModalShell close={close}>
        <ViewWithKeys close={close} />
      </ModalShell>
    ),
    { displayName: "CheckoutModal" }
  );

export default ModalChild;
