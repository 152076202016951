import PropTypes from "prop-types";
import map from "ramda/src/map";
import { mapIndexed } from "../../../utils";
import Radio from "./Radio";

const makeRadio = (props, id) => <Radio key={id} {...props} />;

makeRadio.displayName = "Radio";

const RadioSet = ({ radioOptions, inputProps, className, disabled }) => {
  const { value, onChange } = inputProps;

  const mapPropsToOptions = (option) => {
    const checked = option.value === value;
    const shouldDisableAll = disabled ? disabled : option.disabled;

    return {
      ...option,
      checked,
      onChange,
      disabled: shouldDisableAll,
    };
  };

  const optionsWithProps = map(mapPropsToOptions, radioOptions);
  const mappedRadios = mapIndexed(makeRadio, optionsWithProps);

  return <div className={className}>{mappedRadios}</div>;
};

RadioSet.displayName = "RadioSet";

RadioSet.propTypes = {
  radioOptions: PropTypes.arrayOf(PropTypes.object),
  inputProps: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  }),
  disabled: PropTypes.bool,
};

export default RadioSet;
