import { css } from "@emotion/react";
import { useState } from "react";
import { SettingsPane } from "../../App/screens/Account/SettingsView/settings-pane";
import OrderReceipt from "../../components/order-receipt";

const content = css`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;

export const Receipt = () => {
  const [orderNumber, setOrderNumber] = useState("");

  return (
    <SettingsPane
      title={["Order History", `Order #${orderNumber}`]}
      styles={{ content }}
    >
      <OrderReceipt setOrderNumber={setOrderNumber} />
    </SettingsPane>
  );
};
