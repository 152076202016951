import { css } from "@emotion/react";

export const Actions = ({ children }) => (
  <div
    css={css`
      align-items: center;
      background: #f7f8f9;
      border-top: 2px solid #d8dde6;
      display: flex;
      justify-content: flex-end;
      padding: 12px 13px 12px 26px;

      button {
        flex: 0 0 auto;
        font-size: 15px;
      }

      button + button {
        margin-left: 10px;
      }

      button[disabled] {
        background: #87b1e2;
        cursor: not-allowed;
      }
    `}
  >
    {children}
  </div>
);
