import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import { ErrorBar, LoadingSpinner } from "../../components";
import CancelButton from "./CancelButton";
import Content from "./Content";
import SubmitButton from "./SubmitButton";

const isNotNil = (e) => !isNil(e);

export const styles = {
  container: css`
    border: 1px solid rgb(216, 221, 230);
    border-radius: 4px;
    flex-direction: column;
    max-width: 700px;
    padding: 0;
    margin: 0 auto;
  `,

  buttonContainer: css`
    background-color: #edeff2;
    bottom: 0;
    box-shadow: 0 -2px 0 0 rgba(4, 29, 68, 0.15);
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
  `,
};

const TermsAndConditions = ({
  cancel,
  changeLanguage,
  data = { content: {} },
  error,
  errorMsg = "We can't get the terms at this time, please try again later.",
  language = "en",
  loading,
  submit,
}) => {
  if (isNotNil(error)) {
    return <ErrorBar error={errorMsg} />;
  }

  if (loading || !data.content[language]) {
    return <LoadingSpinner />;
  }

  return (
    <div css={styles.container} aria-modal="true">
      <Content
        lang={language}
        body={data.content[language].text || ""}
        onChange={changeLanguage}
      />
      <div css={styles.buttonContainer}>
        <CancelButton cancel={cancel} />
        <SubmitButton submit={submit} />
      </div>
    </div>
  );
};

export default TermsAndConditions;
