import { produce } from "immer";
import { useEffect, useRef, useState } from "react";
import { Fit, Status } from "./constants";

const getImageInfo = async (src) =>
  new Promise((resolve, reject) => {
    const img = new global.Image();

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (e) => {
      console.error(e);
      reject(e);
    };
    img.src = src;
  });

const getFit = ({ fit, aspectRatio }) => {
  if (fit !== Fit.Best) return fit;

  return aspectRatio >= 1 ? Fit.Width : Fit.Height;
};

const getBounds = ({ canvas, img, rotation, zoom, offset }) => {
  if (!canvas || !img) return {};

  const isSideways = rotation === 90 || rotation === 270;

  const actual = {
    width: isSideways ? img.height * zoom : img.width * zoom,
    height: isSideways ? img.width * zoom : img.height * zoom,
    offsetX: isSideways ? offset.y : offset.x,
    offsetY: isSideways ? offset.x : offset.y,
  };

  const offsets = {
    x1: actual.offsetX - (canvas.width - actual.width) / (2 * zoom),
    x2: actual.offsetX + (canvas.width - actual.width) / (2 * zoom),
    y1: actual.offsetY - (canvas.height - actual.height) / (2 * zoom),
    y2: actual.offsetY + (canvas.height - actual.height) / (2 * zoom),
  };

  const bounds = {
    left: offsets.x1 >= offsets.x2 ? offsets.x2 : offsets.x1,
    right: offsets.x1 >= offsets.x2 ? offsets.x1 : offsets.x2,
    bottom: offsets.y1 >= offsets.y2 ? offsets.y2 : offsets.y1,
    top: offsets.y1 >= offsets.y2 ? offsets.y1 : offsets.y2,
  };

  return bounds;
};

const getImageDimensions = (dimensions) => {
  const { canvasWidth, canvasHeight, width, height } = dimensions;

  const fit = getFit({
    fit: dimensions.fit ?? Fit.Best,
    aspectRatio: width / height,
  });

  if (fit === "height") {
    const newWidth = canvasHeight * (width / height) || 0;
    const offset = { x: (canvasWidth - newWidth) / 2, y: 0 };
    const x = newWidth <= canvasWidth ? offset.x : 0;

    return {
      img: { width: Math.max(newWidth, 0), height: Math.max(canvasHeight, 0), x, y: offset.y },
      offset,
    };
  }

  const newHeight = canvasWidth * (height / width) || 0;
  const offset = { y: (canvasHeight - newHeight) / 2, x: 0 };
  const y = newHeight <= canvasHeight ? offset.y / 2 : 0;

  return {
    img: { width: Math.max(0, canvasWidth), height: Math.max(0, newHeight), x: offset.x, y },
    offset,
  };
};

const getOcrScale = ({ rawImage, img }) => {
  if (rawImage?.width && img?.width) {
    return rawImage.width / img.width;
  }

  return 0;
};

export default ({ src, fit, canvas, rotation, zoom }) => {
  const [dimensions, setDimensions] = useState({
    rawImage: { width: 0, height: 0 },
    img: { width: 0, height: 0, x: 0, y: 0 },
    ocrScale: 1,
    bounds: {},
    offset: { x: 0, y: 0 },
  });
  const on = useRef(null);
  const [status, setStatus] = useState(Status.Loading);

  useEffect(() => {
    if (!canvas?.width || !canvas?.height) return;
    const measureImage = async () => {
      const rawImage =
        !dimensions?.rawImage?.width || !dimensions?.rawImage?.height
          ? await getImageInfo(src)
          : dimensions.rawImage;

      const { img, offset } = getImageDimensions({
        canvasWidth: canvas.width,
        canvasHeight: canvas.height,
        width: rawImage.width,
        height: rawImage.height,
        fit: fit,
      });

      const ocrScale = getOcrScale({ rawImage, img });

      const bounds = getBounds({
        canvas,
        img,
        rotation,
        zoom,
        offset,
      });

      setDimensions(
        produce((draft) => {
          draft.rawImage = rawImage;
          draft.img = img;
          draft.ocrScale = ocrScale;
          draft.bounds = bounds;
          draft.offset = offset;
        })
      );

      if (!on?.current) {
        if (process.env.IS_CLIENT === "f") return;
        import("@clubajax/on").then((imported) => {
          on.current = imported.default;
          setStatus(Status.Loaded);
        });
      } else {
        setStatus(Status.Loaded);
      }
    };
    measureImage().catch(console.error);
  }, [canvas, fit, rotation, zoom]);

  const viewBox = [
    0,
    0,
    Math.max(canvas?.viewPort?.width || 0, 0),
    Math.max(canvas?.viewPort?.height || 0, 0),
  ].join(" ");

  return { dimensions, status, viewBox, on };
};
