import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import { createSelector } from "reselect";
import { Status } from "../../constants";
import { getError } from "./checkout";
import { isNCourtEnabled } from "./configuration";

const { Loaded } = Status;
const isInitiated = (s) => s === Loaded;
const hasPaid = (a) => a === "paid";

export const getNCourt = prop("ncourt");
export const getOrderId = compose(prop("orderId"), getNCourt);
export const getCheckoutUrl = compose(prop("checkoutUrl"), getNCourt);
export const isOrderPaid = compose(hasPaid, prop("paid"), getNCourt);
export const orderStatus = compose(prop("orderStatus"), getNCourt);
export const checkoutError = getError;

export const checkoutInitiated = compose(
  isInitiated,
  prop("orderStatus"),
  getNCourt
);

export const expressCheckoutInitiated = compose(
  isInitiated,
  prop("expressOrderStatus"),
  getNCourt
);

export const shouldOpenPopup = createSelector(
  [isNCourtEnabled, isOrderPaid, getCheckoutUrl],
  (useNCourt, ncourtPaymentComplete, ncourtUrl) =>
    useNCourt && ncourtUrl !== null && !ncourtPaymentComplete
);
