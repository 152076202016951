import { parse } from "query-string";
import compose from "ramda/src/compose";
import descend from "ramda/src/descend";
import find from "ramda/src/find";
import has from "ramda/src/has";
import length from "ramda/src/length";
import omit from "ramda/src/omit";
import propOr from "ramda/src/propOr";
import sort from "ramda/src/sort";
import values from "ramda/src/values";
import { paths, workspaceTypes } from "../constants";
import { randomString } from ".";
import {
  getPage,
  matchesBasePath,
  matchesBasePathInList,
  parseUrl,
} from "./general";

export function getDocumentRank(documentsByOrder, workspace, id) {
  const index = documentsByOrder.indexOf(id);
  const { limit = "1", offset = "0" } = parse(parseUrl(workspace.path).search);
  const page = getPage(Number.parseInt(offset, 10), Number.parseInt(limit, 10));

  return 1000 * page + index;
}

function getCurrentPage(workspace) {
  const { limit = "1", offset = "0" } = parse(parseUrl(workspace.path).search);

  return getPage(Number.parseInt(offset, 10), Number.parseInt(limit, 10));
}

export function createWorkspaceID(path, workspaces) {
  let key;

  if (/^\/cart/.test(path)) {
    return "cart";
  }

  do {
    key = randomString();
  } while (has(key, workspaces));

  return key;
}

export function getTypeFromPath(path) {
  const matchingPath = compose(
    find((pathForType) => matchesBasePath(pathForType, path)),
    sort(descend(length)),
    values
  )(paths);

  return propOr(null, matchingPath, workspaceTypes);
}

export function getDocumentsRanks(documentsByOrder, workspace, selections) {
  const result = {};
  const pageRank = 1000 * getCurrentPage(workspace);

  for (let index = 0; index < documentsByOrder.length; index++) {
    const docId = documentsByOrder[index];

    if (docId in selections) {
      result[docId] = pageRank + index;
    }
  }

  return result;
}

export const isWorkspacePath = (path) => {
  if (path === "/") {
    return true;
  }

  return matchesBasePathInList(getWorkspaceValues(paths), path);
};

export function makeBaseWorkspace({
  id,
  path,
  selectedDepartment,
  isActive = false,
}) {
  return {
    id,
    title: `${getTypeFromPath(parseUrl(path).pathname)}`,
    path,
    isActive,
    isClosable: false,
    selectedDepartment,
    type: getTypeFromPath(parseUrl(path).pathname),
  };
}

const getWorkspaceValues = compose(values, omit(["HOME"]));
