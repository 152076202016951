import useAttachments from "../doc-detail.common/useAttachments";
import AddToCartButton from "./attachments.list.add-to-cart";
import styles from "./attachments.style";

const AttachmentsList = () => {
  const { actions, collection } = useAttachments();

  return (
    <>
      <h1 css={styles.heading}>Additional Images</h1>
      <ul>
        {collection.map((attachment, index) => (
          <li key={attachment.id}>
            <button css={styles.button} onClick={() => actions.open(index)}>
              {attachment.path}
            </button>
            <button css={styles.button}>{`.${attachment.extension}`}</button>
            <AddToCartButton />
          </li>
        ))}
      </ul>
    </>
  );
};

export default AttachmentsList;
