import { Warning } from "@kofile/icons";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions, selectors } from "../../../store";
import { stringify } from "query-string";

class Verify extends Component {
  componentDidMount() {
    const { match } = this.props;

    this.props.verifyUser(match.params.token);
  }

  componentWillReceiveProps(nextProps) {
    const params = { returnPath: "/signin?verified=true" };

    this.props.history.push("/signout?" + stringify(params));
  }

  render() {
    const { verificationError = "", loadingSpinner } = this.props;
    const text = verificationError || "Verifying User Account Registration...";

    return (
      <div className="verify">
        <Helmet>
          <title>Verify</title>
        </Helmet>
        <div className="verify__content">
          {verificationError ? (
            <Warning
              id="verify-warning-icon"
              className="verify__warning-icon"
            />
          ) : (
            <img
              className="loading__spinner"
              src={loadingSpinner}
              alt="loading spinner"
            />
          )}
          <h1 className="verify__loading-text">{text}</h1>
        </div>
      </div>
    );
  }
}

Verify.displayName = "Verify";
Verify.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
  verifyUser: PropTypes.func,
  verificationError: PropTypes.string,
  verified: PropTypes.bool,
  loggedIn: PropTypes.bool,
  loadingSpinner: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => ({
  verificationError: selectors.user.getVerificationError(state),
  verified: selectors.user.getVerificationStatus(state),
  loadingSpinner: state.assets.img["icon_spinner.svg"],
  loggedIn: selectors.user.getLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  verifyUser: (token) => dispatch(actions.user.verify({ token })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Verify);
