import mitt from "mitt";
import lensProp from "ramda/src/lensProp";
import set from "ramda/src/set";
import { useEffect, useRef } from "react";

const authLens = lensProp("authToken");

export const useSocket = ({ listenFor = [] }) => {
  const { socket, __ort } = globalThis;
  const emitter = useRef(mitt());

  const send = (type, payload, cid) => {
    const message = { type, payload, cid };
    const augment = set(authLens, __ort);

    socket.send(augment(message));
  };

  const listenForTypes = () => {
    const handlers = {};

    for (const type of listenFor) {
      handlers[type] = ({ type, payload, correlationId }) => {
        emitter.current.emit(type, { payload, __cid: correlationId });
      };

      socket.onAPIMsg(handlers[type]);
    }

    return () => {
      for (const handler in handlers) {
        socket.off(handler, handlers[handler]);
      }
    };
  };

  useEffect(listenForTypes, []);

  return { send, responses: emitter.current };
};
