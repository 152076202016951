import { Status } from "../../../constants";
import { ConnectAcccount } from "./connect-account";
import { Connecting } from "./connecting";
import { styles } from "./styles";
import { useCountyFusionPopup } from "./use-county-fusion-popup";

export const Account = ({ callbackUrl, username }) => {
  const { status, error, account, connect, cancel } = useCountyFusionPopup({
    callbackUrl,
    username,
  });

  switch (status) {
    case Status.Loading:
      return <Connecting cancel={cancel} />;

    case Status.Loaded:
      return (
        <>
          <p>Account: {account}</p>
          <button
            onClick={cancel}
            title="remove account"
            css={styles.cancelButton}
          >
            Remove Account
          </button>
        </>
      );

    case Status.Error:
      return (
        <>
          <p css={styles.error}>{error}</p>
          <button
            title="try again"
            onClick={connect}
            css={styles.tryAgainButton}
          >
            Try again
          </button>
        </>
      );

    case Status.Init:
      return <ConnectAcccount onClick={connect} />;
  }
};
