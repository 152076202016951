import { push } from "connected-react-router";
import path from "ramda/src/path";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../store";
import { isPresent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";

export default (to) => {
  const dispatch = useDispatch();
  const data = useDocument();
  const [disabled, setDisabled] = useState(false);
  const [navigateTo, setNavigateTo] = useState("");

  const workspace = useSelector(selectors.workspaces.getActiveWorkspace);

  const direction = to.toLowerCase();
  const docId = path(["bookVolPageNav", direction], data);

  const navigate = (e) => {
    e.preventDefault();

    const inWorkspace = { workspaceID: workspace.id };
    dispatch(push(`/doc/${navigateTo}`, inWorkspace));
  };

  useEffect(() => {
    if (isPresent(docId)) {
      setNavigateTo(docId);
      setDisabled(false);
    } else {
      setNavigateTo("");
      setDisabled(true);
    }
  }, [data.id]);

  return { navigate, disabled };
};
