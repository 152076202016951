import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router";
import { A11yAnnouncement } from "../../../components";
import { countyFusionEscrowLogin } from "../../../constants";
import {
  PaymentComplete,
  PaymentError,
  LoadingNCourt,
  CountyFusionEscrow,
} from "./views";

export const StaticPages = () => (
  <div>
    <Helmet>
      <title>Payment</title>
    </Helmet>
    <A11yAnnouncement>{`Navigated to Payment page`}</A11yAnnouncement>
    <Switch>
      <Route exact path="/static" component={PaymentComplete} />
      <Route
        exact
        path="/static/payment/complete"
        component={PaymentComplete}
      />
      <Route exact path="/static/payment/error" component={PaymentError} />
      <Route exact path="/static/loading/ncourt" component={LoadingNCourt} />
      <Route
        exact
        path={countyFusionEscrowLogin}
        component={CountyFusionEscrow}
      />
    </Switch>
  </div>
);

StaticPages.displayName = "StaticPages";

export default StaticPages;
