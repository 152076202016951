import formatDateFn from "date-fns/format";
import parse from "date-fns/parse";
import always from "ramda/src/always";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import cond from "ramda/src/cond";
import curry from "ramda/src/curry";
import head from "ramda/src/head";
import identity from "ramda/src/identity";
import is from "ramda/src/is";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import join from "ramda/src/join";
import pick from "ramda/src/pick";
import T from "ramda/src/T";
import values from "ramda/src/values";
import { isPresent } from ".";
import { emptyOrNil } from "./general";

export const RANGE_DATE_FORMAT = "yyyyMMdd";
const DATE_RENDER_FORMAT = "MM/dd/yyyy";

export const dateRangePresets = {
  L24H: "Last 24 Hours",
  L3D: "Last 3 Days",
  L1W: "Last 1 Week",
  L2W: "Last 2 Weeks",
  L1M: "Last 1 Month",
  L3M: "Last 3 Months",
  L6M: "Last 6 Months",
  L1Y: "Last 1 Year",
};

const invalidDate = new Date(NaN);

const isDateValid = (value) => {
  if (value === null || typeof value === "undefined" || value === "") {
    return false;
  } else if (value instanceof Date) {
    return Number.isNaN(value.getTime()) === false;
  }

  return true;
};

const isDateInvalid = complement(isDateValid);

const formatDateRangeFn = (value) => {
  if (isDateInvalid(value)) return "";
  if (value instanceof Date) return formatDateFn(value, RANGE_DATE_FORMAT);

  return value;
};

const formatsToTry = ["yyyy-MM-dd", "yyyyMMdd"];

export const ISOToDateOnly = (input) => {
  // drop any time information, if provided
  const [dateStr] = input.split("T");

  // construct a new JS date object using date-fns/parse.
  // NOTE: JS dates MUST have the local timezone--there's no way
  // around this (until Temporal)!
  let date = null;

  for (const format of formatsToTry) {
    date = parse(dateStr, format, new Date());

    if (date instanceof Date && isNaN(date)) continue;
    break;
  }

  if (date instanceof Date && isNaN(date)) return dateStr;

  return date;
};

export const makeDate = cond([
  [is(Date), identity],
  [is(String), ISOToDateOnly],
  [isNil, always(invalidDate)],
  [T, always(invalidDate)],
]);

export const formatDateRange = (startStr, endStr) => {
  if (emptyOrNil(startStr) && emptyOrNil(endStr)) {
    return "";
  }

  const startDate = makeDate(startStr);
  const endDate = makeDate(endStr);

  if (isDateInvalid(startDate) && isDateInvalid(endDate)) {
    return "";
  }

  return `${formatDateRangeFn(startDate)},${formatDateRangeFn(endDate)}`;
};

export const parseDateRange = (formatted) => {
  if (formatted) {
    const split = formatted.split(",");
    const startDateParsed = split[0];
    const endDateParsed = split[1];

    const startDateToPass = isEmpty(startDateParsed)
      ? null
      : parse(startDateParsed, RANGE_DATE_FORMAT, new Date());

    const endDateToPass = isEmpty(endDateParsed)
      ? null
      : parse(endDateParsed, RANGE_DATE_FORMAT, new Date());

    return {
      startDate: startDateToPass,
      endDate: endDateToPass,
    };
  }

  return { startDate: null, endDate: null };
};

export const isValidDateRange = (formatted) => {
  const parsed = parseDateRange(formatted);

  return !isNil(parsed.startDate) && !isNil(parsed.endDate);
};

export const defaultRange = () => {
  const startDate = new Date("1990-01-01T00:00:00.000");
  const endDate = new Date();

  return formatDateRange(startDate, endDate);
};

export const formatDate = curry((dateString, format = null) => {
  if (isEmpty(dateString)) return "";

  const htmlEntities = { em: /<em>(.+?)<\/em>/g };

  for (const [, regexp] of Object.entries(htmlEntities)) {
    if (!regexp.test(dateString)) continue;

    // if the server-repsonse contains HTML entites, the UI absolves itself of
    // any responsibility to re-format the date and uses the server-provided
    // value as-is
    return dateString;
  }
  const dateFormat = isPresent(format) ? format : DATE_RENDER_FORMAT;

  return formatDateFn(new Date(dateString), dateFormat);
});

const checkIfArray = (date) => {
  if (Array.isArray(date)) {
    return head(date);
  }

  return date;
};

export const formatDateOnly = compose(formatDate, ISOToDateOnly, checkIfArray);

export const concatDateAndTime = (strDate, strTime, document) => {
  const picked = pick([strDate, strTime], document);
  const vals = values(picked);
  const joined = join(" ", vals);
  return joined;
};
