import { passwordValidations } from "./../../../utils/formValidation";
const formConfig = {
  newPassword: {
    id: "newPassword",
    type: "PasswordInput",
    ariaLabel: "New Password",
    placeholder: " New Password",
    autoFocus: true,
    showPasswordOption: true,
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "matches",
        arg: /^[a-zA-Z0-9.,;:/*@!`%#&}{"'?^~_-]{8,2048}$/,
        error:
          "Passwords must be at least 8 characters long and contain only letters, numbers or special characters. Spaces are not allowed.",
      },
    ],
  },
  confirmPassword: {
    id: "confirmPassword",
    type: "PasswordInput",
    ariaLabel: "Confirm Password",
    placeholder: "Confirm Password",
    showPasswordOption: true,
    validations: passwordValidations,
  },
};

export default formConfig;
