import { Triangle } from "@kofile/icons";
import { DownshiftSelect } from "@kofile/react-downshift-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const menuIndicator = {
  open: <Triangle id="select-triangle-icon-open" filled rotation={270} />,
  closed: <Triangle id="select-triangle-icon-closed" filled rotation={90} />,
};

const CustomItem = ({ label, isSelected, activeCheck }) => (
  <span>
    {label}
    {isSelected && (
      <img className="select__active-check" src={activeCheck} alt="check" />
    )}
  </span>
);

CustomItem.displayName = "CustomItem";
CustomItem.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  activeCheck: PropTypes.string,
};

const CustomControl = ({ label, isOpen }) => {
  const indicator = isOpen ? menuIndicator.open : menuIndicator.closed;

  return (
    <div className="select__control-wrap">
      {label}
      <span className="select__indicator">{indicator}</span>
    </div>
  );
};

CustomControl.displayName = "CustomItem";
CustomControl.propTypes = {
  label: PropTypes.string,
  isOpen: PropTypes.bool,
};

const Select = (props) => {
  const renderCustomItem = (itemProps) => (
    <CustomItem activeCheck={props.activeCheck} {...itemProps} />
  );

  const selectProps = {
    itemRenderer: renderCustomItem,
    controlRenderer: CustomControl,
    items: props.options,
    ...props,
  };

  return <DownshiftSelect {...selectProps} />;
};

Select.displayName = "Select";
Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object])
  ),
  activeCheck: PropTypes.string,
  onSelect: PropTypes.func,
};

const mapStateToProps = ({ assets }) => ({
  activeCheck: assets.img["blue_check.svg"],
});

export default connect(mapStateToProps)(Select);
