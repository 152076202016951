import { types as syncTypes } from "@kofile/ko-search-action-types";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import notificationActions from "../actions/notifications";

function resetPasswordFulfilled$(action$) {
  return action$.pipe(
    ofType(syncTypes.REQUEST_RESET_PASSWORD_FULFILLED_0),
    map(() =>
      notificationActions.addNotification({
        message: "A password reset email has been sent.",
      })
    )
  );
}

function updatePasswordFulfilled$(action$) {
  return action$.pipe(
    ofType(syncTypes.UPDATE_RESET_PASSWORD_FULFILLED_0),
    map(() =>
      notificationActions.addNotification({
        message: "Your password was successfuly reset.",
      })
    )
  );
}

export default [resetPasswordFulfilled$, updatePasswordFulfilled$];
