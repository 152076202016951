import map from "ramda/src/map";
import { ENTITY_TYPES } from "../../constants";
import { isPresent } from "../../utils";

const formatDocuments = ({ items, department, form, pageCount }) => {
  return map(
    (doc) => ({
      department,
      id: doc.imageId,
      itemId: doc.imageId,
      docId: doc.id,
      type: "docimage",
      selectedPages: form.selectedPages,
      deliveryMethod: "download",
      instrumentNumber: doc.instrumentNumber,
      totalPages: items.length === 1 ? pageCount : doc.pageCount,
      includeTranscription: doc.includeTranscription,
      includeTranslation: doc.includeTranslation,
      includeAttachments: form.includeAttachments,
      isCertifiedCopy: doc.isCertifiedCopy || form.certifiedCopy,
      docType: isPresent(doc.docTypeCode) ? doc.docTypeCode : doc.docType,
    }),
    items
  );
};

const formatAttachments = ({ items, form, pageCount }) => {
  return map(
    (doc) => ({
      department: doc.department,
      type: "attachment",
      name: "Attachment",
      selectedPages: form.selectedPages,
      id: doc.imageId,
      itemId: doc.imageId,
      docId: doc.docId,
      deliveryMethod: "download",
      docType: doc.docType,
      instrumentNumber: doc.instrumentNumber,
      totalPages: items.length === 1 ? pageCount : doc.pageCount,
    }),
    items
  );
};

export default (type, data) => {
  const mapMethods = {
    [ENTITY_TYPES.DOCUMENT]: formatDocuments,
    [ENTITY_TYPES.ATTACHMENT]: formatAttachments,
  };

  return mapMethods[type](data);
};
