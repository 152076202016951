import styled from "@emotion/styled";

export const RemoveButton = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: var(--color-neutral-lightest);
  color: #006dd5;
  visibility: ${(props) => (props.isAdmin ? "hidden" : "unset")};
  font-size: 14px;
  font-weight: 400;

  img {
    height: 18px;
    padding-right: 5px;
  }
`;
