import propOr from "ramda/src/propOr";
import { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardEntry, StripeMessage } from "../../../../components";
import { hasLoaded } from "../../../../constants/status";
import { withStripe } from "../../../../hoc";
import { actions, selectors } from "../../../../store";
import { ExpiredSubscriptionMessage } from "../../../../units/common/error.bar.expired.subscription";
import { useStripePaymentMethod } from "../../../../units/common/use-stripe-payment-method";
import { getPriceFromCents, isPresent } from "../../../../utils";
import { checkToken, createToken, getResponse } from "../../../../utils/stripe";
import { Submit } from "./_button.submit";
import { Form as StyledForm } from "./_form.payment";
import { Title } from "./_title";

export const StripeCheckout = (props) => {
  const { card: cardInfo, status: fetchPaymentStatus } =
    useStripePaymentMethod();

  const dispatch = useDispatch();
  const history = useHistory();
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [rememberPayment, setRememberPaymentTo] = useState(false);
  const [usingNewPayment, setUsingNewPayment] = useState(null);
  const [forceDisable, setForceDisable] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const checkoutState = useSelector(selectors.checkout.getCheckout);
  const userIsLoading = useSelector(selectors.user.getLoading);
  const checkoutInProgress = useSelector(selectors.checkout.getInProgress);
  const totalPrice = useSelector(selectors.cart.getTotalPrice);
  const cardError = useSelector(selectors.user.getCardError);
  const useSavedCard = useSelector(selectors.user.getUseSavedCard);
  const cartId = useSelector(selectors.cart.getCartId);
  const checkoutError = useSelector(selectors.checkout.getError);

  const setError = (error) => dispatch(actions.checkout.checkoutError(error));
  const rejectCardError = propOr("", "error", checkoutState);
  const setNewPaymentTo = (v) => () => setUsingNewPayment(v);
  const paymentHasLoaded = hasLoaded(fetchPaymentStatus);
  const isDisabled = forceDisable === true || submitted === true;

  const checkout = (data) => dispatch(actions.checkout.purchase(data));

  const { stripe } = props;
  const paymentError = checkoutState.error || cardError;
  const isLoading = userIsLoading || checkoutInProgress;

  const chargeWithSavedCard =
    usingNewPayment !== true && useSavedCard === true && isPresent(cardInfo);

  useEffect(() => {
    if (isLoading === true) {
      setPaymentProcessing(true);
    }
    if (isPresent(paymentError)) {
      setPaymentProcessing(false);
      setSubmitted(false);
    }
  }, [isLoading, paymentError]);

  useEffect(() => {
    const isServerError = checkoutError === "Server error.";

    if (isPresent(checkoutError) && isServerError) {
      dispatch(actions.checkout.makeCheckoutStale());
      history.push("/cart/contents");
    }
  }, [checkoutError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (paymentHasLoaded) {
      if (chargeWithSavedCard) {
        checkout({ shoppingCartId: +cartId });
      } else {
        createToken({ stripe, type: "card" })
          .then(checkToken)
          .then(getResponse(props))
          .then(({ token }) => {
            checkout({
              cardToken: token.id,
              rememberCard: rememberPayment,
              shoppingCartId: +cartId,
            });
          })
          .catch(setError);
      }
    }
  };

  const buttonText = isPaymentProcessing
    ? "Loading..."
    : `Pay ${getPriceFromCents(totalPrice)}`;

  const cardAreaProps = {
    cardError: paymentError,
    inProgress: isPaymentProcessing,
    cardInfo,
    showSavedCard: chargeWithSavedCard,
    disabled: isPaymentProcessing,
    updateRemember: setRememberPaymentTo,
  };

  return (
    <Fragment>
      <Helmet>
        <title>Processing Checkout</title>
      </Helmet>
      <StyledForm onSubmit={handleSubmit}>
        <Title>Enter Payment Info</Title>
        <ExpiredSubscriptionMessage disableCheckout={setForceDisable} />
        <StripeMessage message={rejectCardError} />
        <div className="payment__fields">
          <CardEntry
            label="Card"
            cardAreaProps={cardAreaProps}
            onUserInput={setNewPaymentTo(true)}
          />
          <Submit data-testid="payButton" disabled={isDisabled}>
            {buttonText}
          </Submit>
        </div>
      </StyledForm>
    </Fragment>
  );
};

export default withStripe(StripeCheckout);
