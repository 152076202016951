import announcements from "./announcements";
import assets from "./assets";
import cart from "./cart";
import checkout from "./checkout";
import configuration from "./configuration";
import docPreview from "./doc-preview";
import documents from "./documents";
import download from "./download";
import error from "./error";
import exportResults from "./export-results";
import fraudAlert from "./fraud-alert";
import keys from "./keys";
import ncourt from "./ncourt";
import notifications from "./notifications";
import onlineStatus from "./online-status";
import order from "./order";
import password from "./password";
import search from "./search";
import suggestions from "./suggestions";
import termsAndConditions from "./terms-and-conditions";
import tooltip from "./tooltip";
import tour from "./tour";
import user from "./user";
import workspaces from "./workspaces";

export default {
  announcements,
  assets,
  cart,
  checkout,
  configuration,
  docPreview,
  documents,
  download,
  error,
  exportResults,
  fraudAlert,
  keys,
  ncourt,
  notifications,
  onlineStatus,
  order,
  password,
  search,
  suggestions,
  termsAndConditions,
  tooltip,
  tour,
  user,
  workspaces,
};
