import { useAPI } from "../../hooks/use-api";

export const useFetchAutocompleteSuggestions = () =>
  useAPI({
    send: `fetch-autocomplete-suggestions`,
    listenFor: {
      success: `autocomplete-suggestions`,
      failure: `error-fetching-autocomplete-suggestions`,
    },
  });
