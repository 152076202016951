import { useEffect, useReducer, useRef } from "react";
import { Status } from "../../../constants";
import { usePopup } from "../../../hooks";
import { useLinkAccount } from "./use-link-account";
import { useUnlinkAccount } from "./use-unlink-account";

const initialState = {
  status: Status.Init,
  linkToken: null,
  error: null,
  account: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "set_loading":
      return {
        ...initialState,
        status: Status.Loading,
      };

    case "set_error":
      return {
        ...initialState,
        error: action.payload,
        status: Status.Error,
      };

    case "set_link_token":
      return {
        ...initialState,
        status: state.status,
        linkToken: action.payload,
      };

    case "set_username":
      return {
        ...state,
        account: action.payload,
        status: Status.Loaded,
      };

    case "unlink_account":
      return {
        ...initialState,
        status: Status.Loading,
      };

    case "reset":
      return { ...initialState };

    default:
      throw Error("Unknown action.");
  }
}

const getInitialState = (username) => {
  if (username) {
    return { ...initialState, status: Status.Loaded, account: username };
  }
  return { ...initialState };
};

export const useCountyFusionPopup = ({ callbackUrl, username }) => {
  const channel = useRef(null);
  const { open, close } = usePopup(callbackUrl, "CountyFusionEscrow");
  const [state, dispatch] = useReducer(reducer, username, getInitialState);
  const linkAccount = useLinkAccount();
  const unlinkAccount = useUnlinkAccount();

  const connect = () => {
    dispatch({ type: "set_loading" });
    if (!channel.current) {
      channel.current = new BroadcastChannel("static");
    }
    open();

    channel.current.onmessage = (e) => {
      if (e.origin !== window.location.origin) {
        return;
      }

      const linkToken = new URL(e.data.href).searchParams.get("linkToken");

      if (linkToken) {
        dispatch({ type: "set_link_token", payload: linkToken });
        linkAccount.initiate();
      }
    };
  };

  const cancel = () => {
    dispatch({ type: "reset" });
    linkAccount.reset();
    unlinkAccount.reset();
    close();
  };

  const unlink = () => {
    unlinkAccount.initiate();
    linkAccount.reset();
    cancel();
  };

  useEffect(() => {
    const username = linkAccount.data?.username;
    if (!username) return;

    dispatch({ type: "set_username", payload: username });
  }, [linkAccount.data]);

  useEffect(() => {
    const error = linkAccount.error || unlinkAccount.error;
    if (!error) return;
    if (state.error === error.reason.message) return;

    dispatch({ type: "set_error", payload: error.reason.message });
  }, [linkAccount.error, unlinkAccount.error]);

  useEffect(() => {
    return () => {
      channel.current?.close();
      close();
    };
  }, []);

  return {
    connect,
    cancel,
    unlink,
    ...state,
  };
};
