import { useDispatch } from "react-redux";
import { actions } from "../../../../store";
import styles from "./entry.styles";

const AddSupportDoc = ({ show = false, data = [], text = "" }) => {
  const dispatch = useDispatch();

  const addToCart = (items) => () =>
    dispatch(actions.cart.addToCart({ items }));

  if (!show) return null;

  return (
    <div css={styles.entryOption}>
      <button
        css={styles.entryButton}
        className="cart-entry__include-button"
        onClick={addToCart(data)}
      >
        {text}
      </button>
    </div>
  );
};

export default AddSupportDoc;
