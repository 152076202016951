import { A11yButton, A11yIcon } from "../../components";
import style, { buttons } from "./style";
import useOcr from "./use-ocr";

const OcrNavigation = (props) => {
  const { hitMapIndex, toggleOcrVisibility, totalHits } = props;
  const ocrActions = useOcr(props);

  const {
    exitIcon,
    goToFirstHit,
    goToLastHit,
    goToNextHit,
    goToPreviousHit,
    isFirst,
    isLast,
    jumpToEndIcon,
    jumpToNextIcon,
  } = ocrActions;

  if (totalHits === 0) {
    return null;
  }

  return (
    <div css={style.wrapper}>
      <div css={style.matches}>
        <span css={style.bold}>{hitMapIndex + 1} </span> of {totalHits} matches
      </div>
      <button
        css={buttons.close}
        aria-labelledby="button-label"
        onClick={toggleOcrVisibility}
      >
        <span id="button-label" hidden>
          Close ORC Navigation
        </span>
        <img
          css={buttons.exit}
          src={exitIcon}
          focusable="false"
          alt="Close OCR Navigation"
        />
      </button>
      <ul css={buttons.list}>
        <li>
          <A11yButton
            id="ocr.control.first-hit"
            label="Go to first OCR hit"
            onClick={goToFirstHit}
            disabled={isFirst}
            icon={(iconProps) => (
              <A11yIcon
                mirrored
                alt="go to first hit"
                title="Go to first OCR hit"
                src={jumpToEndIcon}
                {...iconProps}
              />
            )}
          />
        </li>
        <li>
          <A11yButton
            id="ocr.control.prev-hit"
            label="Go to previous OCR hit"
            onClick={goToPreviousHit}
            disabled={isFirst}
            icon={(iconProps) => (
              <A11yIcon
                mirrored
                alt="go to the previous hit"
                title="Go to previous OCR hit"
                src={jumpToNextIcon}
                {...iconProps}
              />
            )}
          />
        </li>
        <li>
          <A11yButton
            id="ocr.control.next-hit"
            label="Go to next OCR hit"
            onClick={goToNextHit}
            disabled={isLast}
            icon={(iconProps) => (
              <A11yIcon
                alt="go to the next hit"
                title="Go to the next OCR hit"
                src={jumpToNextIcon}
                {...iconProps}
              />
            )}
          />
        </li>
        <li>
          <A11yButton
            id="ocr.control.last-hit"
            label="Go to last OCR hit"
            onClick={goToLastHit}
            disabled={isLast}
            icon={(iconProps) => (
              <A11yIcon
                alt="go to last hit"
                title="Go to last OCR hit"
                src={jumpToEndIcon}
                {...iconProps}
              />
            )}
          />
        </li>
      </ul>
    </div>
  );
};

export default OcrNavigation;
