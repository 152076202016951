import { isFuture, parseISO } from "date-fns";
import contains from "ramda/src/contains";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import not from "ramda/src/not";
import replace from "ramda/src/replace";
import { useContext, useEffect } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import subscriptionsContext from "../../App/screens/Account/SettingsView/views/subscriptions-context";
import { hasLoaded } from "../../constants/status";
import { isAbsent, isPresent } from "../../utils";
import { chooseViewFor } from "../../utils/choose-view-for";
import { Loading } from "../account-settings.subscriptions.common/loading";
import { ContactSupport } from "../common/contact-support";
import NavContext from "./context.nav";
import { Subscriptions } from "./subscriptions";

const ensureGoToHome = ifElse(
  contains(":id"),
  replace(/\/edit\/:id$/, ""),
  identity
);

export const ManageSubscriptions = (props) => {
  const { id } = useParams();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { subscriptions, error, setData, status } =
    useContext(subscriptionsContext);

  const nav = {
    editSubscription: (id) => () => void history.push(`${url}/edit/${id}`),
    goToSubscriptions: () => void history.push(ensureGoToHome(path)),
  };

  const goToSubscriptionsOnMissingId = () => {
    if (!id || !subscriptions) {
      return;
    }

    const foundSub = subscriptions.find((s) => String(s.id) === id);

    if (!foundSub) {
      history.replace(ensureGoToHome(path));
    }
  };

  const determineViewByStatus = () => {
    if (hasLoaded(status)) {
      const hasPending =
        isPresent(subscriptions) &&
        subscriptions.some((sub) => sub.status === "pending");

      if (hasPending) {
        history.push(`${url}/pending-application`);
      } else {
        const hasValidSubs = subscriptions.some(({ status, endDate }) => {
          const endsInFuture = isFuture(parseISO(endDate));
          return status === "active" || (status === "canceled" && endsInFuture);
        });

        if (isAbsent(subscriptions) || not(hasValidSubs)) {
          history.push(`${url}/activate`);
        }
      }
    }
  };

  useEffect(determineViewByStatus, [status, subscriptions]);
  useEffect(goToSubscriptionsOnMissingId, [subscriptions, id]);

  return chooseViewFor(status).when({
    isInit: () => <Loading />,
    isLoading: () => <Loading />,
    hasLoaded: () => (
      <NavContext.Provider value={nav}>
        <Subscriptions
          {...props}
          subscriptions={subscriptions}
          onCancel={setData}
          onChange={setData}
        />
      </NavContext.Provider>
    ),
    errored: () => <ContactSupport error={error} />,
    default: () => <ContactSupport />,
  });
};
