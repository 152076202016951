import compose from "ramda/src/compose";
import defaultTo from "ramda/src/defaultTo";
import pathOr from "ramda/src/pathOr";
import pick from "ramda/src/pick";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import values from "ramda/src/values";
import { createSelector } from "reselect";
import { CHECKOUT_TYPES, ENTITY_TYPES } from "../../constants";
import { selectors } from "../../store";
import { isAbsent, isPresent } from "../../utils";

export const getUser = prop("user");
export const getAuthToken = compose(prop("authToken"), getUser);
export const getIpAddress = compose(prop("ip"), getUser);
export const getCardInfo = compose(pathOr({}, ["cardInfo"]), getUser);
export const getLoading = compose(prop("loading"), getUser);
export const getCardError = compose(prop("cardError"), getUser);
export const getUseSavedCard = compose(prop("useSavedCard"), getUser);
export const getLoggedIn = compose(prop("loggedIn"), getUser);
export const getRegistrationError = compose(prop("registerError"), getUser);
export const getVerificationError = compose(prop("verificationError"), getUser);
export const getVerificationStatus = compose(prop("verified"), getUser);
export const getUserDetails = compose(prop("userDetails"), getUser);
export const getIsVerified = compose(prop("isVerified"), getUserDetails);
export const getUserFirstName = compose(prop("firstName"), getUserDetails);
export const getUserLastName = compose(prop("lastName"), getUserDetails);
export const getUserEmail = compose(prop("email"), getUserDetails);
export const getAccountType = compose(prop("accountType"), getUserDetails);
export const getSettings = compose(prop("settings"), getUser);
export const getViewedTours = compose(prop("viewedTours"), getSettings);
export const getRemoveCard = createSelector(getUser, prop("removeCard"));

export const getContactPreferences = compose(
  prop("contactPreferences"),
  getSettings
);

export const getRegistrationComplete = compose(
  prop("registrationComplete"),
  getUser
);

export const getChangePasswordError = compose(
  prop("changePasswordError"),
  getUser
);

export const userCanCheckout = createSelector(
  [(state) => selectors.cart.getLoggedInCartId(state)],
  (cartId) => cartId !== null
);

export const getUserFullName = (s) => {
  return [getUserFirstName(s), getUserLastName(s)].join(" ").trim();
};

export const getResultsTourViewed = compose(
  propOr(true, "searchResults"),
  getViewedTours
);

export const getQuickSearchTourViewed = compose(
  propOr(true, "quickSearch"),
  getViewedTours
);

export const getHasFetchedUserSettings = compose(
  prop("hasFetchedUserSettings"),
  getUser
);

export const getCheckoutWindowData = compose(
  prop("checkoutWindowData"),
  getUser
);

export const getCheckoutType = compose(
  prop("checkoutType"),
  getCheckoutWindowData
);

export const getCheckoutEntityType = compose(
  prop("entityType"),
  getCheckoutWindowData
);

export const getCheckoutWindowItemIDs = compose(
  prop("itemIDs"),
  getCheckoutWindowData
);

export const getCheckoutDocuments = createSelector(
  [
    getCheckoutWindowItemIDs,
    (state) => selectors.documents.getActiveWorkspaceDataHash(state),
    (state) => selectors.workspaces.getSelectedDocumentsMap(state),
    (state) => selectors.docPreview.getDocumentData(state),
  ],
  (itemIDs, documentsByHash, selectedDocumentsMap, docPreviewData) => {
    const documents = { ...documentsByHash, ...selectedDocumentsMap };

    const documentFromSearchResults = compose(
      defaultTo([]),
      values,
      pick(itemIDs)
    )(documents);

    if (isPresent(documentFromSearchResults)) return documentFromSearchResults;

    return [docPreviewData];
  }
);

export const getCheckoutAttachments = createSelector(
  [
    getCheckoutWindowItemIDs,
    (state) => (id) =>
      selectors.docPreview.getDocumentAttachmentById(state, id),
    (state) => selectors.docPreview.getDocument(state) || {},
  ],
  (itemIDs, getAttachmentById, docSummary) =>
    itemIDs.map((id) => {
      const attachmentData = getAttachmentById(id);

      return {
        ...docSummary.data,
        id: attachmentData.id,
        imageId: attachmentData.id,
        pageCount: attachmentData.totalPages,
      };
    })
);

export const getCheckoutDocumentsAttachmentData = createSelector(
  [getCheckoutDocuments, getCheckoutEntityType],
  (items, entityType) => {
    const initialData = {
      totalAttachments: 0,
      totalPages: 0,
    };

    if (entityType === ENTITY_TYPES.DOCUMENT) {
      return items.reduce(
        (acc, { attachments = [] }) => ({
          totalAttachments: acc.totalAttachments + attachments.length,
          totalPages:
            acc.totalPages +
            attachments.reduce((acc, { totalPages }) => acc + totalPages, 0),
        }),
        initialData
      );
    }
  }
);

const checkoutItemSelectorMap = {
  [CHECKOUT_TYPES.NORMAL]: {
    [ENTITY_TYPES.DOCUMENT]: getCheckoutDocuments,
    [ENTITY_TYPES.ATTACHMENT]: getCheckoutAttachments,
  },
  [CHECKOUT_TYPES.EXPRESS]: {
    [ENTITY_TYPES.DOCUMENT]: getCheckoutDocuments,
    [ENTITY_TYPES.EXPORT]: () => {},
  },
};

export const getCheckoutItems = (state) => {
  const checkoutType = getCheckoutType(state) || CHECKOUT_TYPES.EXPRESS;
  const entityType = getCheckoutEntityType(state) || ENTITY_TYPES.EXPORT;
  const itemSelector = checkoutItemSelectorMap[checkoutType][entityType];

  return itemSelector(state);
};

export const getCheckoutItemTotalPages = createSelector(
  [getCheckoutItems],
  (items) => {
    if (isAbsent(items)) return 0;

    return items.reduce((acc, { pageCount }) => acc + pageCount, 0);
  }
);
