import { css } from "@emotion/react";
import { nonSectionHeight } from "../../measurements";

const centerLoadingSpinner = `
  & loading-indicator {
    margin: 20px auto;
    width: 80px;
    align-self: center;
  }
`;

export const main = {
  wrapper: (detailsVisible, announcementsPresent) => css`
    position: relative !important;
    flex: 1;
    display: grid;
    grid-template-areas:
      ". nav     nav    ."
      ". preview drawer .";
    grid-template-rows: min-content 1fr 0;
    grid-template-columns: ${detailsVisible ? "0 1fr 1fr 0" : "0 1fr 0"};
    gap: 0 24px;
    height: 100%;
    max-height: calc(
      100vh - ${nonSectionHeight} - ${announcementsPresent ? "38px" : "0px"}
    );

    & > *:not(style) {
      display: flex;
    }

    & nav#primary {
      display: grid;
    }
  `,
  images: css`
    grid-area: preview;
    border: 1px solid #dddddf;
    background-color: var(--color-neutral-lightest);
    overflow: hidden;
    min-width: 600px;

    ${centerLoadingSpinner}
  `,
  metadata: css`
    grid-area: drawer;
    min-height: 0;
  `,
};

export const tabs = {
  wrap: css`
    flex: 1;
    display: grid;
    grid-template-areas: "tabs" "content";
    grid-template-rows: min-content auto;
    overflow: hidden;
  `,
  tablist: css`
    grid-area: tabs;
    display: flex;

    &::after {
      content: "";
      border-bottom: 1px solid #dddddf;
      flex: 2;
    }
  `,
  tablink: (isActive) => css`
    background: ${isActive ? "#fff" : "transparent"};
    border: 1px solid ${isActive ? "#dddddf" : "transparent"};
    border-bottom-color: ${isActive ? "#fff" : "#dddddf"};

    & > button {
      background: 0;
      text-transform: uppercase;
      color: #006dd5;
      padding: 5px 20px;
      white-space: nowrap;
    }
  `,
  tab: css`
    grid-area: content;
    background: var(--color-neutral-lightest);
    border: 1px solid #dddddf;
    border-top: 0;
    display: flex;
    min-height: 0;

    & .doc-preview__summary {
      height: unset !important;
      min-height: unset !important;
      box-shadow: unset !important;
    }

    ${centerLoadingSpinner}
  `,
};
