import { LoadingSpinner } from "../../components";
import { getPriceFromCents } from "../../utils";

const InfoText = ({ label, value, isLoading, dataTestId }) => (
  <div className="checkout__text" data-testid={dataTestId}>
    {`${label}: `}
    {isLoading ? <LoadingSpinner className="checkout__text--loading" /> : value}
  </div>
);

const ExportInfo = (props) => {
  const pages = props.exportPages;

  return (
    <div className="checkout__info-wrap">
      <InfoText
        label="Rows"
        value={props.rows}
        isLoading={props.isLoading}
        dataTestId="exportCheckoutRows"
      />
      <InfoText
        label="Pages"
        value={pages}
        isLoading={props.isLoading}
        dataTestId="exportCheckoutPages"
      />
      {Boolean(props.convenienceFee) && (
        <InfoText
          label="Fees"
          value={getPriceFromCents(props.convenienceFee)}
          isLoading={props.isLoading}
          dataTestId="exportCheckoutFees"
        />
      )}

      <InfoText
        label="Total Price"
        value={
          props.totalPrice === 0 ? "Free" : getPriceFromCents(props.totalPrice)
        }
        isLoading={props.isLoading}
        dataTestId="exportCheckoutPrice"
      />
    </div>
  );
};

ExportInfo.defaultProps = {
  totalPagesSelected: 0,
  totalPrice: 0,
  convenienceFee: 0,
  isLoading: false,
};

export default ExportInfo;
