import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectors } from "../../store";
import { FinishSubmissionInstructions } from "./application.submit.payment.finish";

const style = css`
  margin: 0;
  grid-area: title;
`;

const defaultCopy =
  "You have a credit card saved to your profile. Please continue the application process.";

export const SaveCardInstructions = ({ hasSavedPayment }) => {
  const { getImg } = selectors.assets;
  const icon = useSelector(getImg("icon_credit_card.svg"));

  if (!hasSavedPayment) {
    return (
      <>
        <p css={style}>
          This subscription requires a credit card on file. Please save a credit
          card in the payment section of your Account Profile to continue with
          the application process.
        </p>
        <img alt="save your credit card information" src={icon} />
        <Link to="/account/payment">Add Card To File</Link>
      </>
    );
  }

  return <FinishSubmissionInstructions defaultCopy={defaultCopy} />;
};
