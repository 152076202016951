import { makeActionTypes } from "../../utils";

export default makeActionTypes("workspaces", [
  "ADD",
  "ACTIVATE",
  "REMOVE",
  "UPDATE_SELECTED_DEPARTMENT",
  "ADD_SELECTED_DOCUMENT",
  "REMOVE_SELECTED_DOCUMENT",
  "ADD_ALL_DOCUMENTS",
  "REMOVE_ALL_DOCUMENTS",
  "ADD_SELECTED_DOCUMENT_WITH_RANK",
  "ADD_ALL_DOCUMENT_WITH_RANK",
  "UPDATE_QUICK_SEARCH_STATE",
  "UPDATE_ADVANCED_SEARCH_STATE",
  "SAVE_SEARCH_URL",
]);
