import classnames from "classnames";
import PropTypes from "prop-types";

const Radio = ({
  label = "",
  id,
  checked,
  value,
  onChange,
  disabled = false,
  ...props
}) => {
  const outerCircleClass = classnames("form-field-radio__outer-circle", {
    "form-field-radio__outer-circle--checked": checked,
    disabled,
  });

  const innerCircleClass = classnames("form-field-radio__inner-circle", {
    "form-field-radio__inner-circle--checked": checked,
    disabled,
  });

  const labelClass = classnames("form-field-radio__label-text", {
    disabled,
  });

  return (
    <div className="form-field-radio__wrapper" {...props}>
      <input
        className="form-field-radio__input"
        type="radio"
        id={id}
        checked={checked}
        onChange={onChange}
        value={value}
        data-testid="checkoutAllPagesRadioButton"
        disabled={disabled}
      />
      <label className="form-field-radio__label" htmlFor={id}>
        <div className={outerCircleClass} checked={checked}>
          <div className={innerCircleClass} checked={checked} />
        </div>
        <div className={labelClass}>{label}</div>
      </label>
    </div>
  );
};

Radio.displayName = "Radio";

Radio.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Radio;
