import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalShell } from "../../../../../../../components";
import { withModal } from "../../../../../../../hoc";
import { actions, selectors } from "../../../../../../../store";
import ConfirmActionModal from "../../../../../../../units/account-settings.common/ConfirmActionModal";

const Modal = (props) =>
  Object.assign(
    () => {
      const remove = () => {
        props.deleteAlert();
        props.close();
      };

      return (
        <ModalShell close={close}>
          <RemoveConfirmation {...props} remove={remove} close={close} />
        </ModalShell>
      );
    },
    { displayName: "RemoveModal" }
  );

const RemoveConfirmation = ({ close, name, remove }) => {
  const content = (
    <Fragment>
      <p>Are you sure you want to remove the</p>
      <p className="fraud-alert__line-spacing">{name} alert criteria?</p>
    </Fragment>
  );

  const confirmationProps = {
    title: "Remove Alert Criteria",
    content,
    cancel: { onClick: close, text: "Cancel" },
    action: { onClick: remove, text: "Remove" },
  };

  return <ConfirmActionModal {...confirmationProps} />;
};

export const RemoveButton = ({ id, disabled, modal, name }) => {
  const dispatch = useDispatch();
  const { assets, configuration } = selectors;
  const trashCan = useSelector(assets.getImg("icon_trash.svg"));
  const tenant = useSelector(configuration.getTenantId);

  const deleteAlert = () => {
    const { delete: deleteById, fetchAlerts } = actions.fraudAlert;
    dispatch(deleteById({ id, tenant }));
    dispatch(fetchAlerts());
  };

  const openModal = () =>
    modal.open(Modal({ id, name, deleteAlert, close: modal.close }));

  return (
    <button
      type="button"
      className={`fraud-alert_account__remove-button`}
      onClick={openModal}
      aria-label={`delete property alert for ${name}`}
      data-testid="removeActiveAlert"
      disabled={disabled}
    >
      <img src={trashCan} alt="trashcan" />
      Remove
    </button>
  );
};

export default withModal(RemoveButton);
