import always from "ramda/src/always";
import cond from "ramda/src/cond";
import is from "ramda/src/is";
import path from "ramda/src/path";
import pathSatisfies from "ramda/src/pathSatisfies";
import T from "ramda/src/T";

const isString = is(String);
const isArray = is(Array);
const pathIsString = pathSatisfies(isString);
const pathIsArray = pathSatisfies(isArray);
const messagePath = ["reason", "message"];
const reasonPath = ["reason"];

export const extractAPIError = cond([
  [pathIsString(messagePath), path(messagePath)],
  [pathIsString(reasonPath), path(reasonPath)],
  [pathIsArray(messagePath), path([...messagePath, 0])],
  [T, always("An error has occurred. Please contact support.")],
]);
