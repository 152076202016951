import complement from "ramda/src/complement";
import contains from "ramda/src/contains";
import flip from "ramda/src/flip";
import isEmpty from "ramda/src/isEmpty";
import { useState } from "react";

const includedIn = flip(contains);
const anySelected = complement(isEmpty);

const toggleFn = (isSelected, set) => (t) => (e) => {
  e.preventDefault();

  return isSelected(t)
    ? set((vs) => vs.filter((v) => v !== t))
    : set((vs) => vs.concat([t]));
};

export const useSelectableCollection = (initial) => {
  const [selected, setSelected] = useState(initial || []);
  const isSelected = includedIn(selected);
  const toggle = toggleFn(isSelected, setSelected);
  const hasAny = () => anySelected(selected);

  return { toggle, selected, isSelected, hasAny };
};
