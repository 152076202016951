import always from "ramda/src/always";
import cond from "ramda/src/cond";
import difference from "ramda/src/difference";
import pipe from "ramda/src/pipe";
import reject from "ramda/src/reject";
import T from "ramda/src/T";

export const filterActions = (props) => {
  const {
    selectedDocumentsCount,
    selectedDocuments = [],
    availablePageActions,
    documentsWithoutImage = [],
    documentsByOrder,
  } = props;

  const removeExpressCheckout = reject(
    (action) => action === "expressCheckout"
  );

  const removeAddToCart = reject((action) => action === "addToCart");

  const determineDifference = ({
    docs = selectedDocuments,
    toCompare = documentsWithoutImage,
    count = 0,
  }) => difference(docs, toCompare).length === count;

  const docsAreSelected = selectedDocumentsCount > 0;

  const noneSelectedArePurchasable = docsAreSelected && determineDifference({});

  const allSelectedArePurchasable =
    docsAreSelected &&
    determineDifference({
      count: selectedDocumentsCount,
    });

  const noResultsArePurchasable =
    !docsAreSelected &&
    determineDifference({
      docs: documentsWithoutImage,
      toCompare: documentsByOrder,
    });

  const filter = cond([
    [
      always(noResultsArePurchasable),
      pipe(removeExpressCheckout, removeAddToCart),
    ],
    [always(!docsAreSelected), removeExpressCheckout],
    [always(allSelectedArePurchasable), always(availablePageActions)],
    [always(noneSelectedArePurchasable), removeAddToCart],
    [T, always(availablePageActions)],
  ]);

  return filter(availablePageActions);
};
