import { InputRange } from "@kofile/react-input-range";
import classnames from "classnames";
import reverse from "ramda/src/reverse";
import { Component } from "react";
import { TokenizedSelect } from "../../../components/TokenizedSelect";

const Delimiter = () => <div className="delimiter">TO</div>;

const isJSON = (value) => {
  try {
    JSON.parse(value);

    return true;
  } catch (_) {
    return false;
  }
};

const parseValue = (value) =>
  isJSON(value) ? JSON.parse(value).join(",") : value;

class DocumentNumberInput extends Component {
  constructor(props) {
    super(props);

    const { value } = props.inputProps;

    this.state = {
      isRange: value ? !isJSON(value) : false,
      value: parseValue(this.props.inputProps.value),
    };

    this.setIsRangeTrue = () => this.setState({ isRange: true, value: "" });
    this.setIsRangeFalse = () => this.setState({ isRange: false, value: "" });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputProps.value !== this.props.inputProps.value) {
      this.setState({ value: parseValue(this.props.inputProps.value) });
    }
  }

  render() {
    const { inputProps, onChange, disabled = false } = this.props;
    const { isRange, value } = this.state;
    const [from = "", to = ""] = isRange ? value.split(",") : [];

    const labelClass = classnames(
      "form-field__input-title",
      "document-input-range__label"
    );

    const rangeOnChange = ({ from, to }) => onChange(`${from},${to}`);
    const individualOnChange = (values) => onChange(JSON.stringify(values));

    const inputRange = (
      <InputRange
        className="document-input-range__input-wrap"
        from={from}
        to={to}
        onChange={rangeOnChange}
        Delimiter={Delimiter}
        disabled={disabled}
      />
    );

    const tokenizedSelect = (
      <TokenizedSelect
        className="document-input-range__tokenized-select"
        {...inputProps}
        value={value}
        onChange={individualOnChange}
        disabled={disabled}
        useSuggestions={false}
      />
    );

    const Label = [
      <button
        key="single-doc-numbers"
        type="button"
        className={labelClass}
        disabled={!isRange || disabled}
        onClick={this.setIsRangeFalse}
      >
        Single Document Numbers
      </button>,
      <div key="or-text" className={labelClass}>
        <pre> or </pre>
      </div>,
      <button
        key="number-range"
        type="button"
        className={labelClass}
        disabled={isRange || disabled}
        onClick={this.setIsRangeTrue}
      >
        Document Number Range
      </button>,
    ];

    return (
      <div className="document-input-range">
        <div className="document-input-range__label-wrap">
          {isRange ? reverse(Label) : Label}
        </div>
        <div>{isRange ? inputRange : tokenizedSelect}</div>
      </div>
    );
  }
}

DocumentNumberInput.displayName = "DocumentNumberInput";

export default DocumentNumberInput;
