import equals from "ramda/src/equals";
import filter from "ramda/src/filter";
import isNil from "ramda/src/isNil";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../store";
import { isPresent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";
import TabIds from "./tab-ids";

const present = filter(equals(true));

export default () => {
  const dispatch = useDispatch();
  const document = useDocument();
  const selected = useSelector(selectors.workspaces.getSelectedDocSummaryTab);

  const attachments = isPresent(document?.attachments);
  const transcription = isPresent(document?.hasTranscription);
  const translation = isPresent(document?.hasTranslation);
  const availableTabs = { attachments, transcription, translation };
  const defaultTab = TabIds.Summary;

  const { saveDocSummaryTab } = actions.workspaces;
  const setActiveTab = (viewType) => void dispatch(saveDocSummaryTab(viewType));

  const activateTab = (viewType) => (e) => {
    e.preventDefault();
    setActiveTab(viewType);
  };

  useEffect(() => {
    const selectedTabNotAvailable = isNil(present(availableTabs)[selected]);
    const notDefaultView = selected !== TabIds.Summary;

    if (notDefaultView && selectedTabNotAvailable) setActiveTab(defaultTab);
  }, [document.id]);

  return {
    activeTab: selected || defaultTab,
    activateTab,
    availableTabs,
  };
};
