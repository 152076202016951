import { css } from "@emotion/react";

const styles = {
  wrapper: css`
    border-radius: 4px 4px 0 0;
    border: 1px solid rgba(4, 29, 68, 0.15);
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  `,
  header: css`
    padding: 20px;
    border-bottom: 2px solid #006dd5;
  `,
};

const Pane = (props) => (
  <div css={styles.wrapper}>
    <div css={styles.header}>{props.header}</div>
    {props.children}
  </div>
);

Pane.displayName = "Pane";

export default Pane;
