import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import length from "ramda/src/length";
import map from "ramda/src/map";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import pick from "ramda/src/pick";
import prop from "ramda/src/prop";
import values from "ramda/src/values";
import { createSelector } from "reselect";

export const getCart = prop("cart");
export const getRegularCart = compose(prop("userCart"), getCart);

export const getOrder = compose(prop("byOrder"), getRegularCart);

export const getCartAmount = compose(length, getOrder);

export const getHash = compose(prop("byHash"), getRegularCart);

export const getRegularCartDocs = compose(
  map(prop("docId")),
  filter(({ type }) => type !== "attachment"),
  values,
  mapObjIndexed(pick(["docId", "type"])),
  getHash
);

export const getCartId = compose(prop("cartID"), getCart);

export const isCartIdFresh = createSelector([getCart], (cart) => cart.isFresh);

export const getLoggedInCartId = createSelector(
  [getCartId, isCartIdFresh],
  (cartId, isFresh) => {
    return isFresh ? cartId : null;
  }
);

export const getConvenienceFee = compose(prop("convenienceFee"), getCart);

export const getTotalPrice = compose(prop("totalPrice"), getCart);

export const getPricingInfo = createSelector([getCart], (cart) => ({
  totalPrice: cart.totalPrice || 0,
  convenienceFee: cart.convenienceFee || 0,
  subtotal: cart.subtotal || 0,
  certificationFee: cart.certificationFee || 0,
  certificationFeeSubtotal: cart.certificationFeeSubtotal || 0,
}));
