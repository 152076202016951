import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { keyMirror } from "../../utils/key-mirror";
import { History } from "./history";
import { Receipt } from "./receipt";

const makeRoutes = (path) => ({
  Individual: `${path}/individual`,
  MultiUser: `${path}/multi-user`,
  Receipt: `${path}/:id`,
});

export const Kind = keyMirror("individual", "multiUserAdmin");

export const OrderHistory = (props) => {
  const Routes = makeRoutes("/account/history");
  const [selectedView, setSelectedView] = useState(Kind.individual);

  return (
    <Switch>
      <Route exact path={[Routes.Individual, Routes.MultiUser]}>
        <History
          {...props}
          routes={Routes}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      </Route>
      <Route exact path={Routes.Receipt}>
        <Receipt />
      </Route>
      <Redirect to={Routes.Individual} />
    </Switch>
  );
};
