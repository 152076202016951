import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import DateTime from "../../../../../../components/DateTime";
import { selectors } from "../../../../../../store";
import { WhiteButton as Button } from "../../../../../../units/account-settings.common/_button.white";
import { isNotNil } from "../../../../../../utils";
import { Last4Digits } from "./_existing-card.last4";
import { ExistingCardWrapper } from "./_existing-card.wrapper";
import RemoveButton from "./RemoveButton";

const style = {
  expiration: css`
    color: #364a69;
    grid-area: expiration;
  `,
  editButton: css`
    grid-area: edit-button;
  `,
};

const ExistingCard = ({ cardInfo, setShouldUpdateCard }) => {
  const { last4, exp_month, exp_year, expirationDate, last4Digits } = cardInfo;
  const cardLast4 = isNotNil(last4Digits) ? last4Digits : last4;

  const creditCardIcon = useSelector(
    selectors.assets.getImg("icon_credit_card.svg")
  );

  const date = isNotNil(expirationDate)
    ? new Date(expirationDate)
    : new Date(exp_year, exp_month - 1);

  return (
    <ExistingCardWrapper>
      <Last4Digits>
        <img src={creditCardIcon} alt="credit card icon" /> **** **** ****{" "}
        {cardLast4}
      </Last4Digits>
      <span>
        Expires{" "}
        <DateTime dateTime={date} formatting={"MM/yy"} css={style.expiration} />
      </span>
      <Button css={style.editButton} onClick={() => setShouldUpdateCard(true)}>
        Edit
      </Button>
      <RemoveButton cardId={cardInfo.cardId} />
    </ExistingCardWrapper>
  );
};

export default ExistingCard;
