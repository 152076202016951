import { types as syncTypes } from "@kofile/ko-search-action-types";
import { password as initialState } from "../initialState";
import types from "../types/password";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  [syncTypes.REQUEST_RESET_PASSWORD_0]: requestResetPassword,
  [syncTypes.REQUEST_RESET_PASSWORD_FULFILLED_0]: requestResetPasswordFulfilled,
  [syncTypes.REQUEST_RESET_PASSWORD_REJECTED_0]: requestResetPasswordRejected,
  [syncTypes.UPDATE_RESET_PASSWORD_0]: updateResetPassword,
  [syncTypes.UPDATE_RESET_PASSWORD_FULFILLED_0]: updateResetPasswordFulfilled,
  [syncTypes.UPDATE_RESET_PASSWORD_REJECTED_0]: updateResetPasswordRejected,
  [types.CLEAR_ERROR]: clearError,
  [types.CLEAR_STATE]: clearState,
});

function requestResetPassword(state, { payload }) {
  return {
    ...state,
    loading: true,
    resetError: "",
    emailToReset: payload.email,
  };
}

function requestResetPasswordFulfilled(state) {
  return {
    ...state,
    loading: false,
    resetIsSent: true,
    resetError: "",
  };
}

function requestResetPasswordRejected(state, { payload }) {
  return {
    ...state,
    loading: false,
    resetIsSent: false,
    resetError: payload.errors,
  };
}

function updateResetPassword(state) {
  return {
    ...state,
    loading: true,
    resetError: "",
  };
}

function updateResetPasswordFulfilled(state) {
  return {
    ...state,
    passwordReset: true,
    loading: false,
  };
}

function updateResetPasswordRejected(state) {
  return {
    ...state,
    updateError: "Update password failed.",
    loading: false,
  };
}

function clearError(state) {
  return {
    ...state,
    resetError: "",
    updateError: "",
    emailToReset: "",
  };
}

function clearState() {
  return initialState;
}
