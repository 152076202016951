import { css } from "@emotion/react";
import { parse } from "query-string";
import assoc from "ramda/src/assoc";
import compose from "ramda/src/compose";
import map from "ramda/src/map";
import pick from "ramda/src/pick";
import prop from "ramda/src/prop";
import reduce from "ramda/src/reduce";
import toPairs from "ramda/src/toPairs";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ErrorBar, Form } from "../../../components";
import { withKeys } from "../../../hoc";
import { actions, selectors } from "../../../store";
import { noop } from "../../../utils";
import Container from "../_loginRegisterFooter";
import getFormConfig from "./formConfig";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-neutral-lightest);
    border: 1px solid rgba(4, 29, 68, 0.15);
    border-radius: 10px;
    width: 400px;
    margin: 0 auto;
    padding: 20px 40px;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  `,
  title: css`
    margin-bottom: 20px;
    padding: 20px 0 0;
    color: #041d44;
    font-size: 24px;
  `,
  button: css`
    width: 100%;
    padding: var(--spacing-md);
    border-radius: var(--primitive-6);
    background-color: var(--color-primary-600);
    color: var(--color-neutral-white);
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;

    &:disabled {
      opacity: 0.5;
    }
  `,
};

const getFormProps = pick(["pricePerDocument"]);

export const RegisterForm = (props) => {
  const {
    updateFormValue,
    updateFormError,
    formState,
    location,
    register = noop,
    loading,
    registrationError,
  } = props;

  const formActions = { onChange: updateFormValue };

  const formConfig = getFormConfig(getFormProps(props));
  const form = Form.buildForm(formConfig, formState, formActions);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Form.checkIfFormValid(formConfig, formState, updateFormError)) {
      const formValues = {
        ...map(prop("value"), formState),
      };

      formValues.accountType = "regular";
      formValues.company = "";

      return register(formValues);
    }
  };

  useEffect(() => props.clearError, []);

  const { returnPath } = parse(location.search);
  const signInPath = `/signin?returnPath=${encodeURIComponent(returnPath)}`;

  return (
    <>
      <div css={styles.container}>
        <h1 css={styles.title}>Register for an Account</h1>
        <form css={styles.form} onSubmit={handleSubmit}>
          <ErrorBar error={registrationError} />
          {form.firstName}
          {form.lastName}
          {form.email}
          {form.password}
          <button type="submit" css={styles.button}>
            {loading ? "Loading..." : "Create Account"}
          </button>
        </form>
      </div>
      <Container>
        Already have an account?&nbsp;
        <Link aria-label="Sign In" to={signInPath}>
          Sign In
        </Link>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: selectors.user.getLoading(state),
  registrationComplete: selectors.user.getRegistrationComplete(state),
  registrationError: selectors.user.getRegistrationError(state),
  pricePerDocument: selectors.configuration.getPricePerPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  register: (form) => dispatch(actions.user.register(form)),
  clearError: () => dispatch(actions.user.clearError()),
});

const getValuesFromConfig = compose(
  reduce((acc, [key, { value }]) => assoc(key, value, acc), {}),
  toPairs
);

const initializer = compose(
  (input) => Form.createInitialState(input, getValuesFromConfig(input)),
  getFormConfig,
  getFormProps
);

const enhance = compose(
  withRouter,
  withKeys,
  connect(mapStateToProps, mapDispatchToProps),
  Form.withFormStateInitialized("formState", initializer)
);

export default enhance(RegisterForm);
