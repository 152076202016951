import { css } from "@emotion/react";
import { isPast, parseISO } from "date-fns";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isNotNil } from "../../utils";
import { Date } from "../account-settings.subscriptions.common/date";
import { formatDate } from "../account-settings.subscriptions.common/format-date";
import { Capacity } from "../account-settings.subscriptions.common/subscription.capacity";
import { Price } from "../account-settings.subscriptions.common/subscription.price";
import { ExpiredSubscriptionMessage } from "../common/error.bar.expired.subscription";
import { Subscription } from "./_subscriptions.container";
import { OrgText } from "./_subscriptions.organization.text";
import { OrgTitle } from "./_subscriptions.organization.title";
import { Title } from "./_subscriptions.title";

const styles = {
  warning: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    line-height: 1.5;
    border: 1px solid #fecb37;

    img {
      height: 40px;
      padding-right: 20px;
    }
  `,
};

export const Canceled = ({ subscription, isOrganizationAdmin }) => {
  const { assets, configuration } = selectors;
  const warningIcon = useSelector(assets.getImg("icon_warning_circle.svg"));
  const applicationEnabled = useSelector(
    configuration.getSubscriptionApplicationEnabled
  );

  const {
    amount,
    description,
    endDate,
    title,
    period,
    maxMembers,
    organizationName,
    type,
  } = subscription;

  const isMultiUser = type === "multiUser";

  const orgColumn = isMultiUser && (
    <Fragment>
      <OrgTitle>Organization</OrgTitle>
      <OrgText>{organizationName}</OrgText>
    </Fragment>
  );

  const isMultiUserAdmin = isOrganizationAdmin && isMultiUser;
  const capacity = isMultiUserAdmin && isNotNil(maxMembers) ? maxMembers : 1;

  if (isPast(parseISO(endDate))) return null;

  return (
    <Fragment>
      <ExpiredSubscriptionMessage />
      <Subscription>
        <Title>{title}</Title>
        <Capacity>{capacity}</Capacity>
        <Price amount={amount} period={period} label={description} />
        <Date date={endDate}>{(formatted) => `Active until ${formatted}`}</Date>
        {orgColumn}
      </Subscription>
      <div css={styles.warning}>
        <img className="" src={warningIcon} alt="warning" />
        <p>
          By your request, your monthly subscription has been canceled. Don’t
          worry! You have monthly subscription access until{" "}
          {formatDate(endDate)}
          {applicationEnabled
            ? " and can reapply for access at that time."
            : "."}
        </p>
      </div>
    </Fragment>
  );
};
