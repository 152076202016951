import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { isLoading, hasLoaded } from "../../constants/status";
import ErrorBar from "./error.bar";
import { useCheckSubscriptionStatus } from "./use-check-subscription-status";

const style = {
  link: css`
    white-space: nowrap;
  `,
};

export const ExpiredSubscriptionMessage = ({ disableCheckout }) => {
  const [error, setError] = useState(null);
  const { data, status } = useCheckSubscriptionStatus();
  const currentSubIsUnpaid = isNil(data) ? false : data.status === "unpaid";

  const expiredMessage = (
    <Fragment>
      Unable to process payment for your subscription. Update in{" "}
      <Link css={style.link} to="/account/payment">
        Account Settings
      </Link>
      .
    </Fragment>
  );

  useEffect(() => {
    if (isLoading(status)) {
      disableCheckout(true);
    } else if (hasLoaded(status) && currentSubIsUnpaid) {
      disableCheckout(true);

      return setError(expiredMessage);
    } else {
      disableCheckout(false);
    }

    return () => setError(null);
  }, [status, currentSubIsUnpaid, disableCheckout]);

  return <ErrorBar error={error} />;
};

ExpiredSubscriptionMessage.defaultProps = {
  disableCheckout: () => {},
};
