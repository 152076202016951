import classnames from "classnames";
import { Helmet } from "react-helmet";
import { A11yAnnouncement } from "../../components";
import { ReturnButton, Title } from "./components";

const AccountTitle = ({
  renderReturnButton,
  className,
  children,
  text,
  title,
}) => (
  <div className={classnames("account-title__wrap", className)}>
    <Helmet>
      <title>{text}</title>
    </Helmet>

    <A11yAnnouncement>{`Navigated to ${text} page`}</A11yAnnouncement>

    <div className="account-title__info">
      <Title>{title}</Title>
      {children}
    </div>

    <div className="account-title__return-wrap">
      {renderReturnButton ? <ReturnButton /> : null}
    </div>
  </div>
);

AccountTitle.displayName = "AccountTitle";

export default AccountTitle;
