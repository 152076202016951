import { pageAdvanceStyle } from "./style";

const PageAdvanceButton = ({ onChange, text, disabled = true, ariaLabel }) => {
  const onClick = (e) => {
    e.preventDefault();
    onChange();
  };

  return (
    <button
      css={pageAdvanceStyle(disabled)}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled ? "true" : "false"}
      value={text}
      onClick={onClick}
      tabIndex={disabled ? -1 : 0}
    >
      {text}
    </button>
  );
};

export default PageAdvanceButton;
