import { ModalShell } from "../../../../../../components";
import ConfirmActionModal from "../../../../../../units/account-settings.common/ConfirmActionModal";

const Modal = ({ close, updateUserInfo, error, actionCopy }) =>
  Object.assign(
    () => {
      const confirmationProps = {
        title: "Remove Saved Phone Number",
        error,
        content: `Are you sure you want to remove your phone number? Removing it will mean you no longer receive notifications via phone or text. If you’re trying to remove a Property Alert, please visit the Property Alert Dashboard instead.`,
        cancel: { onClick: close, text: "Cancel" },
        action: { onClick: updateUserInfo, text: actionCopy },
      };
      return (
        <ModalShell close={close}>
          <ConfirmActionModal {...confirmationProps} />
        </ModalShell>
      );
    },
    { displayName: "RemovePhoneNumberModal" }
  );

export default Modal;
