import { Container } from "./_navigation.container";
import { Tab } from "./navigation.tab";

export const Navigation = ({ selectedView, setSelectedView, kind }) => {
  const tabs = [
    {
      title: "My Orders",
      selected: selectedView === kind.individual,
      onClick: () => setSelectedView(kind.individual),
    },
    {
      title: "Subscription Orders",
      selected: selectedView === kind.multiUserAdmin,
      onClick: () => setSelectedView(kind.multiUserAdmin),
    },
  ];

  return (
    <Container>
      {tabs.map((data) => (
        <Tab key={data.title} {...data} />
      ))}
    </Container>
  );
};
