import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import map from "ramda/src/map";
import propOr from "ramda/src/propOr";
import { isNotNil } from "../../../../../utils";

export const DEFAULT_LIMIT = 50;
export const DEFAULT_OFFSET = 0;

export const getSelectedDocTypes = ({ docTypes = [], query }) => {
  const { docType = "" } = query;

  const docTypeValues = docTypes
    .map(propOr([], "options"))
    .reduce((f, l) => f.concat(l), []);

  const docTypeIDs = docType.split(",").map(Number);

  const findValueByID = (id) =>
    docTypeValues.find(({ id: docID }) => docID === id);

  const matchIDsToType = compose(filter(isNotNil), map(findValueByID));

  return matchIDsToType(docTypeIDs);
};

export const handleQueryUpdate = ({ value, key }) => ({
  [key]: value,
  limit: DEFAULT_LIMIT,
  offset: DEFAULT_OFFSET,
});
