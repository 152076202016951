import { css } from "@emotion/react";
import { parse } from "query-string";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectors } from "../../../store";
import { getNewSearchURL } from "../../../utils";
import useDocument from "../../doc-detail.common/useDocument";

const text = `
  font-size: 14px;
  padding: 0;
`;

const style = {
  text: css`
    ${text}
  `,
  link: css`
    color: #006dd5;
    cursor: pointer;
    ${text}
  `,
};

const LegalLink = ({ value = "" }) => {
  const docData = useDocument();
  const location = useLocation();
  const query = parse(location.search);
  const limitedSearchEnabled = useSelector(
    selectors.configuration.isLimitedSearchEnabled
  );

  const searchLink = (department) =>
    getNewSearchURL({
      query: { ...query, department },
      value,
      key: "legalDescription",
    });

  const formatted = value.length > 100 ? value.slice(0, 100) + "..." : value;

  if (limitedSearchEnabled) {
    return <span css={style.text}>{formatted}</span>;
  }

  return (
    <Link
      css={style.link}
      to={searchLink(prop("department", docData))}
      title={value}
    >
      {formatted}
    </Link>
  );
};

export default LegalLink;
