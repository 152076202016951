import { types as syncTypes } from "@kofile/ko-search-action-types";
import { termsAndConditions as initialState } from "../initialState";
import { handleActions } from "./utils";

export default handleActions(initialState, {
  "accept-fraud-alert-terms-and-conditions": (state) => ({
    ...state,
    loading: true,
    data: null,
  }),

  "fraud-alert-terms-and-conditions-accepted": (state, { payload }) => ({
    ...state,
    loading: false,
    data: payload,
    hasAccepted: true,
  }),

  "error-accepting-fraud-alert-terms-and-conditions": (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.reason,
    data: null,
    hasAccepted: false,
  }),

  [syncTypes.FETCH_I18N_TERMS_AND_CONDITIONS_0]: (state) => ({
    ...state,
    loading: true,
    data: null,
    fetchError: null,
  }),

  [syncTypes.FETCH_I18N_TERMS_AND_CONDITIONS_FULFILLED_0]: (
    state,
    { payload }
  ) => ({
    ...state,
    loading: false,
    data: payload,
    fetchError: null,
  }),

  [syncTypes.FETCH_I18N_TERMS_AND_CONDITIONS_REJECTED_0]: (
    state,
    { payload }
  ) => ({
    ...state,
    loading: false,
    fetchError: payload.error,
    data: null,
  }),

  [syncTypes.FETCH_USER_TERMS_STATUS_0]: (state) => ({
    ...state,
    loading: true,
    hasAccepted: null,
  }),

  [syncTypes.FETCH_USER_TERMS_STATUS_FULFILLED_0]: (state, { payload }) => ({
    ...state,
    loading: false,
    hasAccepted: payload.hasAcceptedLatestTerms,
  }),

  [syncTypes.FETCH_USER_TERMS_STATUS_REJECTED_0]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
    hasAccepted: null,
  }),
});
