import styled from "@emotion/styled";

const TextArea = styled.textarea`
  resize: none;
  height: 100%;
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 4px;
  border: #041d441a solid 2px;
  font-family: "Source Sans Pro";
  color: #041d44;
  font-size: 1.2rem;
  color: #041d44;
`;

export default TextArea;
