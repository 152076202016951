import { css } from "@emotion/react";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import take from "ramda/src/take";
import { formatData } from "./table.formatData";
import ListElement from "./table.listElement";
import { TruncatedList } from "./table.truncatedList";

const styles = {
  table: css`
    width: 100%;
    table-layout: fixed;
  `,
  thead: css`
    background-color: #f7f9fc;
    border-top: 1px solid rgba(4, 29, 68, 0.15);
    border-bottom: 1px solid rgba(4, 29, 68, 0.15);
    text-align: left;
    font-size: 13px;
  `,
  th: css`
    padding: 10px 15px;
    color: rgba(4, 29, 68, 0.6);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  td: css`
    padding: 15px;
  `,
};

const createLabel = ({ label }, i) => (
  <th css={styles.th} key={i}>
    {label}
  </th>
);

export const Table = ({
  config,
  data,
  showOnlyFirst = false,
  shouldTruncate: truncate = false,
  open,
}) => (
  <table css={styles.table}>
    <thead css={styles.thead}>
      <tr>{config.map(createLabel)}</tr>
    </thead>
    <tbody>
      <tr>
        {config.map(({ key }, i) => {
          let formatted = formatData({ data, key });

          if (showOnlyFirst) formatted = take(1, formatted);
          if (isNil(formatted)) formatted = [];

          const fullList = formatted.map((data, index) => (
            <ListElement open={open} key={index} value={data} dataKey={key} />
          ));

          const shouldTruncate = key !== "marginalReferences" && truncate;

          const truncatedList = (
            <TruncatedList id={`${key}_${i}`}>{fullList}</TruncatedList>
          );

          return (
            <td key={i} css={styles.td}>
              {shouldTruncate ? truncatedList : fullList}
            </td>
          );
        })}
      </tr>
    </tbody>
  </table>
);
