import hoistStatics from "hoist-non-react-statics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "../store";

const withWorkspaceId = (Component) => {
  const C = (props) => <Component {...props} />;

  C.displayName = `withWorkspaceId(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  C.propTypes = {
    workspaceID: PropTypes.string,
  };

  const mapStateToProps = (state) => ({
    workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
  });

  return hoistStatics(connect(mapStateToProps)(C), Component);
};

export default withWorkspaceId;
