import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { notEmpty } from "./../../utils/general";

const Popup = ({
  bodyText,
  headerText,
  buttonText,
  onClear,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const exIcon = useSelector(selectors.assets.getImg("icon_bubble_close.svg"));

  return (
    <div
      className="popup__content"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <button type="button" className="popup__clear" onClick={onClear}>
        <img alt="clear" src={exIcon} />
      </button>

      <h2>{headerText}</h2>

      <p>{bodyText}</p>

      {notEmpty(buttonText) && (
        <button type="button" className="popup__action" onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

Popup.propTypes = {
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string,
};

Popup.defaultProps = {
  onClick: () => {},
  onClear: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  isHidden: false,
  headerText: "",
  bodyText: "",
  buttonText: "Click To Learn More",
};

export default Popup;
