import { css } from "@emotion/react";
import { Chevron } from "@kofile/icons";

const styles = {
  wrapper: css`
    border: 1px solid rgba(4, 29, 68, 0.15);
    background-color: rgba(4, 29, 68, 0.01);
    border-radius: 4px;
    width: 100%;
    padding: 0;
  `,
  toggle: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px 4px 0 0;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    text-align: left;
    color: rgba(4, 29, 68, 0.6);
    font-size: 16px;
    padding: 10px;
  `,
  chevron: css`
    margin-left: auto;
    width: 15px;
    height: 15px;
    color: rgba(4, 29, 68, 0.6);
  `,
  fields: css`
    border-top: 1px solid rgba(4, 29, 68, 0.15);
    padding: 10px;
  `,
};

export const FieldGroup = ({
  title,
  isOpen = false,
  onClick = () => {},
  children,
}) => (
  <div css={styles.wrapper}>
    <button
      data-testid="toggle"
      type="button"
      css={styles.toggle}
      onClick={onClick}
    >
      {title}
      <Chevron
        id="field-group-chevron-icon"
        css={styles.chevron}
        rotation={isOpen ? 0 : 270}
      />
    </button>
    {isOpen && (
      <div data-testid="fields" css={styles.fields}>
        {children}
      </div>
    )}
  </div>
);
