import { LOCATION_CHANGE, push, replace } from "connected-react-router";
import { parse } from "query-string";
import dissoc from "ramda/src/dissoc";
import keys from "ramda/src/keys";
import last from "ramda/src/last";
import values from "ramda/src/values";
import { ofType } from "redux-observable";
import { empty, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { paths } from "../../constants";
import {
  createWorkspaceID,
  getDocumentRank,
  getDocumentsRanks,
  getTypeFromPath,
  isWorkspacePath,
  parseUrl,
} from "../../utils";
import actions from "../actions/workspaces";
import { configuration, workspaces, documents } from "../selectors";
import types from "../types/workspaces";

const defaultTitleFor = (type) => {
  switch (type) {
    case "cart":
      return "Cart";
    case "detail":
      return "Document";
    case "results":
      return "Search Results";
    case "card":
      return "Search Results";
    case "advanced":
      return "Advanced Search";
    case "search":
      return "Quick Search";
    default:
      return "New Search";
  }
};

export const handleRouteChange$ = (action$, store) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => {
      const { location } = payload;
      const { pathname, search = "", state = {}, hash } = location;

      if (!state || hash) {
        return actions.doNothing();
      }

      const { workspaceID, workspaceTitle } = state;
      const searchString = search && `${search}`;
      const fullPath = `${pathname}${searchString}`;
      const type = getTypeFromPath(pathname);

      if (isWorkspacePath(pathname)) {
        const state = store.value;
        const { tabs } = state.workspaces;

        if (workspaceID && tabs[workspaceID]) {
          return actions.activateWorkspace({
            id: workspaceID,
            path: fullPath,
            type,
            title: workspaceTitle ?? defaultTitleFor(type),
          });
        }

        const newID = createWorkspaceID(pathname, tabs);

        return actions.addWorkspace({
          id: newID,
          path: fullPath,
          type,
          selectedDepartment: configuration.getFirstDepartmentCode(state),
        });
      }

      return actions.doNothing();
    })
  );

export const handleAddDocumentToSelection$ = (action$, store) =>
  action$.pipe(
    ofType(types.ADD_SELECTED_DOCUMENT),
    map((action) => {
      const state = store.value;
      const workspace = workspaces.getActiveWorkspace(state);

      const documentsOrder = documents.getActiveWorkspaceDataOrder(state);

      const { payload } = action;
      const selection = dissoc("id", payload);

      return {
        type: types.ADD_SELECTED_DOCUMENT_WITH_RANK,
        payload: {
          rank: {
            [payload.id]: getDocumentRank(
              documentsOrder,
              workspace,
              payload.id
            ),
          },
          selection,
        },
      };
    })
  );

export const handleAddAllDocumentToSelection$ = (action$, store) =>
  action$.pipe(
    ofType(types.ADD_ALL_DOCUMENTS),
    map((action) => {
      const state = store.value;
      const workspace = workspaces.getActiveWorkspace(state);
      const documentsOrder = documents.getActiveWorkspaceDataOrder(state);

      const { payload: selections } = action;

      return {
        type: types.ADD_ALL_DOCUMENT_WITH_RANK,
        payload: {
          ranks: getDocumentsRanks(documentsOrder, workspace, selections),
          selections,
        },
      };
    })
  );

export const setWorkspaceHistoryState$ = (action$, store) =>
  action$.pipe(
    ofType(types.ADD),
    map(({ payload }) => {
      const { id: workspaceID } = payload;
      const {
        router: { location },
      } = store.value;
      const locationState = location.state || {};

      return replace({ ...location, state: { ...locationState, workspaceID } });
    })
  );

export const ensureActive$ = (action$, store) =>
  action$.pipe(
    ofType(types.REMOVE),
    map(() => {
      const state = store.value;
      const tabs = workspaces.getTabs(state);

      if (!keys(tabs).length) {
        return push({ pathname: paths.HOME });
      }

      if (workspaces.getActiveWorkspaceID(state)) {
        return actions.doNothing();
      }

      const lastWorkspace = last(values(tabs));
      const { pathname, search } = parseUrl(lastWorkspace.path);

      return push({
        pathname,
        search,
        state: { workspaceID: lastWorkspace.id },
      });
    })
  );

export const setDepartmentOnLoad$ = (action$, store) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    mergeMap(({ payload }) => {
      const state = store.value;
      const { location } = payload;
      const { search = "", pathname } = location;
      const { department } = parse(search);

      const selectedDepartment = workspaces.getSelectedDepartment(state);

      if (pathname === "/results") {
        return of(actions.updateSelectedDepartment(department));
      }

      if (selectedDepartment) {
        return empty();
      }

      const firstCode = configuration.getFirstDepartmentCode(state);

      return of(actions.updateSelectedDepartment(firstCode));
    })
  );

export const reconcileDeptUponReceivingDocData$ = (action$, store) =>
  action$.pipe(
    ofType("document"),
    mergeMap(({ payload }) => {
      const state = store.value;
      const selectedDepartment = workspaces.getSelectedDepartment(state);

      if (payload.department !== selectedDepartment) {
        return of(actions.updateSelectedDepartment(payload.department));
      }

      return empty();
    })
  );

export default [
  handleRouteChange$,
  ensureActive$,
  setDepartmentOnLoad$,
  setWorkspaceHistoryState$,
  handleAddDocumentToSelection$,
  handleAddAllDocumentToSelection$,
  reconcileDeptUponReceivingDocData$,
];
