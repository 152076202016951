import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const LoadingSpinner = ({ loadingSpinner, shouldPrefix, className, css }) => {
  if (process.env.IS_CLIENT === "f") return null;

  const prefix = shouldPrefix ? `https://${window.location.host}` : "";
  const classNames = classnames("loading__container", className);

  return (
    <div className={classNames} css={css} role="alert">
      <img
        aria-label="loading"
        className="loading__spinner"
        src={`${prefix}${loadingSpinner}`}
        alt="loading spinner"
      />
    </div>
  );
};

LoadingSpinner.displayName = "LoadingSpinner";
LoadingSpinner.propTypes = {
  LoadingSpinnerSpinner: PropTypes.string,
};

export const mapStateToProps = ({ assets }) => ({
  loadingSpinner: assets.img["icon_spinner.svg"],
});

export default connect(mapStateToProps)(LoadingSpinner);
