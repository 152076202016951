import styled from "@emotion/styled";

export const LegalContent = styled.div`
  padding-top: 40px;

  & p {
    color: #62738d;
    font-size: 12px;
    line-height: 1.3;
    padding-top: 20px;
    white-space: pre-line;
  }
`;
