import Card from "./card";

const SuccessCard = ({ close }) => {
  return (
    <Card
      hideBorder={true}
      iconName="icon-thumbs-up"
      close={close}
      body={{
        title: "Your feedback has been submitted.",
        content:
          "Thank you for providing feedback on this document. It will be forwarded to the appropriate staff for review.",
      }}
    />
  );
};

export default SuccessCard;
