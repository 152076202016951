import { Chevron } from "@kofile/icons";
import classnames from "classnames";
import PropTypes from "prop-types";
import contains from "ramda/src/contains";
import pluck from "ramda/src/pluck";
import { compose, withHandlers, withState } from "recompose";
import { Checkbox } from "../../../../../../../components";
import { containsAny, mapIndexed } from "../../../../../../../utils";
import Option from "../Option";

const enhance = compose(
  withState("dropdownIsOpen", "setDropdown", (props) => {
    const optionValues = pluck("value", props.options);

    return containsAny(optionValues, props.selections);
  }),
  withHandlers({
    toggleDropdown: (props) => () => props.setDropdown(!props.dropdownIsOpen),
  })
);

const CategoryDropdown = (props) => {
  const {
    selections,
    category,
    hits,
    options,
    dropdownIsOpen,
    toggleDropdown,
    addSelections,
    addAll,
    removeSelections,
    removeAll,
    id,
    isOnlySelection,
  } = props;

  const optionProps = {
    category,
    options,
    selections,
    addSelections,
    removeSelections,
    id,
    isOnlySelection,
  };

  const createOption = (values, key) => (
    <Option key={key} {...optionProps} {...values} />
  );

  const mappedOptions = mapIndexed(createOption, options);

  const dropdownClass = classnames("category-dropdown", {
    "category-dropdown--open": dropdownIsOpen,
  });

  const optionValues = pluck("value", options);
  const allSelected = contains(category, selections);
  const someSelected = containsAny(optionValues, selections);
  const showDash = !allSelected && someSelected;
  const plusMark = !allSelected && !someSelected && isOnlySelection ? "+" : "";

  const onCheckboxClick = () =>
    allSelected
      ? removeAll(optionValues, category)
      : addAll(optionValues, category);

  const textClass = classnames("category-dropdown__button-text", {
    "category-dropdown__button-text--active": someSelected || allSelected,
  });

  const rowClass = classnames("category-dropdown__row", {
    "category-dropdown__row--all-selected": someSelected,
  });

  return (
    <div key={id}>
      <div className={rowClass}>
        <Checkbox
          id={`${id}_${category}`}
          isChecked={allSelected}
          showDash={showDash}
          onClick={onCheckboxClick}
          aria-label="checkbox"
        />
        <button
          className="category-dropdown__dropdown-button"
          onClick={toggleDropdown}
        >
          <div className="category-dropdown__flex-wrap">
            <div className={textClass}>
              {category}
              &nbsp;
              <span className="category-dropdown__paren-text">{`(${plusMark}${hits})`}</span>
            </div>
            <Chevron
              id="categorydropdown-chevron-icon"
              className={classnames(
                "icon--small",
                "category-dropdown__chevron"
              )}
              rotation={dropdownIsOpen ? 0 : 270}
            />
          </div>
        </button>
      </div>
      <div className={dropdownClass}>{mappedOptions}</div>
    </div>
  );
};

CategoryDropdown.displayName = "CategoryDropdown";

CategoryDropdown.propTypes = {
  category: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  dropdownIsOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  hits: PropTypes.number.isRequired,
  selections: PropTypes.arrayOf(PropTypes.string).isRequired,
  addSelections: PropTypes.func.isRequired,
  addAll: PropTypes.func.isRequired,
  removeSelections: PropTypes.func.isRequired,
  removeAll: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOnlySelection: PropTypes.bool,
};

export default enhance(CategoryDropdown);
