import { Warning } from "@kofile/icons";
import { format, parseISO } from "date-fns";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { isLoading, hasError, isInit } from "../../../constants/status";
import { DocumentSummary } from "./documentSummary";
import Pane from "./pane";
import styles from "./style";
import useVerifyCert from "./use-verify-cert";

const VerifyCertifiedCopy = () => {
  const { countyName, status, docNumber, recordedDate, url } = useVerifyCert();

  if (isInit(status)) return <LoadingSpinner />;
  if (isLoading(status)) return <LoadingSpinner />;

  if (hasError(status)) {
    return (
      <div css={styles.error.wrapper} data-testid="error">
        <Warning id="verify-warning-icon" css={styles.error.icon} />
        <p css={styles.error.text}>
          We were unable to look up your document. Please make sure that the
          code in the url is correct.
        </p>
      </div>
    );
  }

  return (
    <div css={styles.wrapper}>
      <Helmet>
        <title>Verify Certified Copy</title>
      </Helmet>

      <div css={styles.grid}>
        <div css={styles.summary}>
          <Pane header="Document Summary">
            <DocumentSummary
              doc={{
                docNumber,
                recordedDate: format(parseISO(recordedDate), "MM/dd/yyyy"),
              }}
              county={countyName}
            />
          </Pane>
        </div>

        <Pane header="Document Image">
          <img css={styles.image} src={url} alt="Document With Certification" />
        </Pane>
      </div>
    </div>
  );
};

VerifyCertifiedCopy.displayName = "VerifyCertifiedCopy";
VerifyCertifiedCopy.defaultProps = { token: null };

export default VerifyCertifiedCopy;
