import { useAPI } from "../../../hooks";
import { localTypes as types } from "../../../store/reducers/doc-preview";

export const useFetchDocument = () =>
  useAPI({
    send: types.fetchDocument,
    initiateAs: "fetchDoc",
    listenFor: {
      success: types.document,
      failure: types.errorFetchingDocument,
    },
  });
