import { types as syncTypes } from "@kofile/ko-search-action-types";
import path from "ramda/src/path";
import values from "ramda/src/values";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import actions from "../actions/cart";
import notificationActions from "../actions/notifications";
import { types } from "../types/cart";

const pluralize = (count) => (count > 1 ? "s" : "");
const id = Date.now().toString();

export const clearCartOnCheckout$ = (actions$) =>
  actions$.pipe(
    ofType(syncTypes.REQUEST_CHECKOUT_FULFILLED_1),
    map(({ payload }) => {
      const { cartId } = payload.order;

      // TODO: Differentiate between checkout types on API and remove this patch
      return cartId ? actions.clearCart() : { type: "NOOP" };
    })
  );

const notifyUserEpic$ = {
  addToCartFulfilled: (action$) =>
    action$.pipe(
      ofType(types.addedToCart),
      map(({ payload }) => {
        const itemCount = payload.userCart.byOrder.length;
        const verb = itemCount > 1 ? "are" : "is";
        const plural = pluralize(itemCount);
        const message = `${itemCount} document${plural} ${verb} in your cart.`;

        return notificationActions.addNotification({ message });
      })
    ),

  addToCartRejected: (action$) =>
    action$.pipe(
      ofType(types.errorAddingToCart),
      map(({ payload }) => {
        const message = path(["reason", "message"], payload);

        return notificationActions.addNotification({ message });
      })
    ),

  addToCartLoading: (action$) =>
    action$.pipe(
      ofType(types.addToCart),
      map(({ payload }) => {
        const itemCount = payload.items.length;
        const message = `Adding ${itemCount} document${pluralize(
          itemCount
        )} to cart...`;

        return notificationActions.addNotification({ message, id });
      })
    ),

  addedToCart: (action$) =>
    action$.pipe(
      ofType(types.addedToCart),
      map(() => notificationActions.removeNotification({ id }))
    ),

  removeFromCartLoading: (action$) =>
    action$.pipe(
      ofType(types.removeFromCart),
      map(() =>
        notificationActions.addNotification({
          message: `Removing document from cart...`,
          id,
        })
      )
    ),

  removedFromCart: (action$) =>
    action$.pipe(
      ofType(types.removedFromCart),
      map(() => notificationActions.removeNotification({ id }))
    ),
};

export default [...values(notifyUserEpic$), clearCartOnCheckout$];
