import { stringify } from "query-string";

export const stripHighlights = (input) =>
  typeof input === "string" ? input.replace(/<em>|<\/em>/g, "") : input;

export const makeAllPartySearchValue = ({ term, partyFields }) =>
  JSON.stringify([
    {
      term: stripHighlights(term),
      types: partyFields,
    },
  ]);

export const getNewSearchURL = ({ query, value, key }) => {
  const { department, viewType } = query;

  const params = {
    department,
    [key]: value,
    searchType: "advancedSearch",
    viewType,
  };

  return `/results?${stringify(params)}`;
};
