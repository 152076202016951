import isBefore from "date-fns/isBefore";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import take from "ramda/src/take";
import { connect, useSelector } from "react-redux";
import withSearchResultsProps from "../../../../../../hoc/withSearchResultsProps";
import withWorkspaceId from "../../../../../../hoc/withWorkspaceId";
import { actions, selectors } from "../../../../../../store";
import { ResultCardView } from "./components";

const CARD_FOOTER_COLUMN_LIMIT = 6;

const ResultCard = ({ doc, legals, inCart, ...props }) => {
  const hideThumbnail = useSelector(
    selectors.configuration.shouldHideThumbnailInDept
  );

  const childProps = {
    common: {
      doc,
      department: props.department,
      isActive: doc.id === props.activeDocID,
      showImagePreview: props.showImagePreview,
      open: () => props.openPreview(doc.id),
    },
    info: { doc, isUncertified: props.isUncertified },
    headerBackground: { inCart, docID: doc.id },
    header: {
      doc,
      inCart,
      isUncertified: props.isUncertified,
      hasBeenViewed: props.hasBeenViewed,
      shoppingCartId: props.shoppingCartId,
      legals: {
        displayOption: legals.displayOption,
        enabled: legals.enabled,
        update: props.updateLegalOption,
      },
    },
    imagePreview: {
      isSecured: doc.isSecured,
      isHidden: !props.showImagePreview,
      thumbnail: hideThumbnail === true ? null : doc.thumbnail,
      docID: doc.id,
      openPreview: props.openPreview,
    },
    footer: {
      config: props.cardFooterConfig,
      legals: { displayOption: legals.displayOption, enabled: legals.enabled },
    },
  };

  return <ResultCardView {...childProps} />;
};

ResultCard.displayName = "ResultCard";

ResultCard.propTypes = {
  parties: PropTypes.arrayOf(PropTypes.object),
  doc: PropTypes.shape({
    isCertified: PropTypes.bool,
    id: PropTypes.number,
  }),
  showImagePreview: PropTypes.bool,
  inCart: PropTypes.bool,
  cardFooterConfig: PropTypes.arrayOf(PropTypes.object),
  certifiedDate: PropTypes.instanceOf(Date),
  legals: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    displayOption: PropTypes.string.isRequired,
  }).isRequired,
};

ResultCard.defaultProps = {
  showImagePreview: false,
  cardFooterConfig: [],
};

const mapStateToProps = (state, ownProps) => {
  const { doc, department } = ownProps;
  const { code: dept } = department;
  const { id, recordedDate } = doc;
  const cartDocs = selectors.cart.getRegularCartDocs(state);
  const certifiedDate = selectors.search.getCertifiedDate(state, dept);

  const { footer: cardFooterConfig } = selectors.configuration.getCardConfig(
    state,
    dept
  );

  const legals = selectors.configuration.getLegalsCardConfig(state, dept);

  const isDepartmentCertified = selectors.configuration.getIsCertified(
    state,
    dept
  );

  const isUncertified = isDepartmentCertified
    ? isBefore(certifiedDate, new Date(recordedDate))
    : false;

  const legalDisplayOption =
    selectors.documents.getLegalDisplayOption(state) || legals.defaultView;

  return {
    cardFooterConfig: take(CARD_FOOTER_COLUMN_LIMIT, cardFooterConfig),
    hasBeenViewed: selectors.docPreview.getIsDocumentViewed(state, id),
    activeDocID: selectors.docPreview.getActiveDocumentID(state),
    inCart: contains(id, cartDocs),
    isUncertified,
    isDepartmentCertified: selectors.configuration.getIsCertified(state, dept),
    legals: {
      enabled: legals.enabled,
      displayOption: legalDisplayOption,
    },
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  updateLegalOption: (displayOption) =>
    dispatch(
      actions.documents.updateLegalDisplay({
        displayOption,
        workspaceID: props.workspaceID,
      })
    ),
});

export default compose(
  withWorkspaceId,
  withSearchResultsProps,
  connect(mapStateToProps, mapDispatchToProps)
)(ResultCard);
