import { css } from "@emotion/react";
import { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import { actions } from "../../store";
import CardInput from "./CardInput";
import RememberPayment from "./remember-payment";

const style = {
  rememberPayment: css`
    margin-top: 10px;
  `,
};

const CardArea = ({
  inProgress,
  updateRemember,
  offerToRemember,
  onUserInput,
}) => {
  const dispatch = useDispatch();
  const [rememberPayment, setRememberPayment] = useState(false);
  const toggleRememberPayment = () => setRememberPayment((v) => !v);

  useEffect(() => {
    updateRemember(rememberPayment);

    return () => {
      if (rememberPayment === true) {
        dispatch(actions.user.setUseSavedCard(true));
      }
    };
  }, [rememberPayment]);

  if (inProgress) {
    return (
      <div className="card-area__spinner">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Fragment>
      <CardInput disabled={inProgress} onUserInput={onUserInput} />
      {offerToRemember && (
        <RememberPayment
          css={style.rememberPayment}
          onChange={toggleRememberPayment}
          isChecked={rememberPayment}
        />
      )}
    </Fragment>
  );
};

CardArea.defaultProps = {
  updateRemember: () => {},
  offerToRemember: true,
};

export default CardArea;
