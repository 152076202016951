import { css } from "@emotion/react";
import { BlueButton as SubmitButton } from "../../../../../../units/account-settings.common/_button.blue";

const style = css`
  margin: 8px 0 0 15px;
`;

const UpdateButton = ({ hidden = false, isLoading, disabled = false }) => {
  if (hidden) {
    return null;
  }

  return (
    <SubmitButton css={style} type="submit" disabled={disabled}>
      {isLoading ? "Loading..." : "Update Card"}
    </SubmitButton>
  );
};

UpdateButton.defaultProps = {
  disabled: false,
  hidden: false,
  onClick: () => {},
};

export default UpdateButton;
