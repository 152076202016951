// feat: usePaymentMethods
//  TODO move to API

import { useAPI } from "../../hooks/use-api";

export const useStripePaymentMethod = () =>
  useAPI({
    send: "ensure-a-stripe-customer-exists",
    initialPayload: {},
    exportAs: "card",
    listenFor: {
      success: "stripe-customer",
      failure: "error-ensuring-a-stripe-customer-exists",
    },
  });
