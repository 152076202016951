import { types as syncTypes } from "@kofile/ko-search-action-types";
import compose from "ramda/src/compose";
import isEmpty from "ramda/src/isEmpty";
import keys from "ramda/src/keys";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import merge from "ramda/src/merge";
import pathOr from "ramda/src/pathOr";
import pick from "ramda/src/pick";
import pickBy from "ramda/src/pickBy";
import propOr from "ramda/src/propOr";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { splitWithDefault } from "../../utils";
import types from "../types/documents";

const DEFAULT_LIMIT = 50;

const parseLegals = (queryObj) => {
  const { legals } = queryObj;

  try {
    return JSON.parse(legals);
  } catch (e) {
    return undefined;
  }
};

export const configurePayload =
  (store$) =>
  ({ payload }) => {
    const { configuration } = store$.value;
    const deptName = keys(configuration.departments).find(
      (k) => configuration.departments[k].code === payload.query.department
    );
    if (!deptName) {
      return {
        type: syncTypes.FETCH_DOCUMENTS_CANCELLED_0,
        payload,
      };
    }

    const deptConfig = configuration.departments[deptName];

    const { partyFields = [] } = deptConfig;

    const mappedParties = partyFields
      .map((party) => {
        const terms = pathOr("", ["query", party], payload);
        const splitTerms = splitWithDefault(terms);

        return splitTerms.map((t) => ({
          types: [party],
          term: t,
        }));
      })
      .reduce((f, l) => f.concat(l), []);

    const partyNames = pathOr("", ["query", "partyNames"], payload);

    const mappedPartyNames = splitWithDefault(partyNames).map((party) => ({
      types: partyFields,
      term: party,
    }));

    const fullParties = [...mappedPartyNames, ...mappedParties].filter(
      ({ term }) => term
    );

    const query = merge(
      payload.query,
      fullParties.length ? { parties: fullParties } : {}
    );

    const payloadWithoutFilters = pickBy(
      (val, key) => !key.includes("_"),
      query
    );

    const prepareObjectFromQueryValues = compose(
      mapObjIndexed(splitWithDefault),
      pick
    );

    const buildFilterParams = (queryParams) => {
      const filterables = ["_docTypes", "_recordedYears", "_instrumentYears"];
      const filterablesObj = prepareObjectFromQueryValues(filterables, query);
      const partyParams = partyFields.reduce((acc, party) => {
        const partyKey = `_${party}`;
        const val = propOr("", partyKey, queryParams);
        const newValue = splitWithDefault(val);

        return isEmpty(newValue) ? acc : merge(acc, { [partyKey]: newValue });
      }, {});

      return {
        ...filterablesObj,
        ...partyParams,
      };
    };

    const finalQuery = convertBooleans(payloadWithoutFilters);

    return {
      type: syncTypes.FETCH_DOCUMENTS_4,
      payload: {
        ...payload,
        query: {
          limit: DEFAULT_LIMIT,
          offset: 0,
          ...finalQuery,
          ...buildFilterParams(query),
          legals: parseLegals(query),
        },
      },
    };
  };

const massageSearch$ = (actions$, store) =>
  actions$.pipe(
    ofType(types.CONFIGURE_SEARCH_QUERY),
    map(configurePayload(store))
  );

export default [massageSearch$];

function convertBooleans(query) {
  return mapObjIndexed((val) => {
    if (val === "true") {
      return true;
    }

    if (val === "false") {
      return false;
    }

    return val;
  }, query);
}
