import { format as _format, parseISO } from "date-fns";
import always from "ramda/src/always";
import cond from "ramda/src/cond";
import is from "ramda/src/is";
import pipe from "ramda/src/pipe";
import T from "ramda/src/T";

const isString = is(String);
const isDate = is(Date);
const format = (v) => _format(v, "MMM dd, yyyy", new Date());

export const formatDate = cond([
  [isString, pipe(parseISO, format)],
  [isDate, format],
  [T, always("Invalid Date")],
]);
