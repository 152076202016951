import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import join from "ramda/src/join";
import { notEmptyOrNil } from "../../../utils";

const Addresses = ({ data }) => {
  const addresses = Array.isArray(data) ? data : [data];

  return addresses.map((val, i) => {
    const addressArray = [
      val.addressName,
      val.address1,
      val.address2,
      val.city,
      val.state,
      val.zip,
    ];

    const addressString = compose(
      join(" "),
      filter(notEmptyOrNil)
    )(addressArray);

    return (
      <li key={i} className="doc-preview-group__summary-group-item">
        <span className="doc-preview-group__summary-group-label">
          {addressString}
        </span>
      </li>
    );
  });
};

export default Addresses;
