import { Link } from "react-router-dom";
import { DateTime } from "../../../../../../../components";
import { makeBEMBlock } from "../../../../../../../utils";

const block = makeBEMBlock("fraud-alert");

const classNames = {
  headerClassName: "fraud-alert__table-header-cell",
  cellClassName: "fraud-alert__table-cell",
};

const matchHistoryConfig = () => ({
  rowProps: () => ({
    className: "fraud-alert__table-row",
  }),
  columns: [
    {
      name: "Alert Date",
      key: "alertDate",
      ...classNames,
      render: ({ entity }) => (
        <DateTime
          dateTime={new Date(entity.createdAt)}
          formatting={"MMM dd, yyyy"}
          dataTestId="alertDate"
        />
      ),
    },
    {
      name: "Doc Number",
      key: "docNumber",
      ...classNames,
      render: ({ entity }) => (
        <Link
          className={block.element("link")}
          to={`/account/property-alert/matches/${entity.id}`}
          data-testid="alertLink"
        >
          {entity.docNumber}
        </Link>
      ),
    },
    {
      name: "Recorded Date",
      key: "recordedDate",
      ...classNames,
      render: ({ entity }) => (
        <DateTime
          dateTime={new Date(entity.recordedDate)}
          formatting={"MMM dd, yyyy"}
          dataTestId="alertDate"
        />
      ),
    },
    {
      name: "Search Criteria",
      key: "criteria",
      ...classNames,
      render: ({ entity }) => (
        <div data-testid="searchCriteria">{entity.desc}</div>
      ),
    },
  ],
});

export default matchHistoryConfig;
