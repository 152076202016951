import { useState } from "react";
import Checkbox from "../../../components/Checkbox";

const CheckboxField = (props) => {
  const { id, checkboxLabel, disabled, onChange, className } = props;
  const [isChecked, setIsChecked] = useState(props.isChecked || false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <Checkbox
      id={id}
      onChange={handleOnChange}
      label={checkboxLabel}
      value="checked"
      isChecked={isChecked}
      disabled={disabled}
      className={className}
    />
  );
};

CheckboxField.defaultProps = {
  disabled: false,
};

CheckboxField.displayName = "CheckboxField";

export default CheckboxField;
