import { parse, stringify } from "query-string";
import all from "ramda/src/all";
import rAny from "ramda/src/any";
import anyPass from "ramda/src/anyPass";
import complement from "ramda/src/complement";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import curry from "ramda/src/curry";
import filter from "ramda/src/filter";
import flip from "ramda/src/flip";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import keys from "ramda/src/keys";
import merge from "ramda/src/merge";
import or from "ramda/src/or";
import pickAll from "ramda/src/pickAll";
import prop from "ramda/src/prop";
import splitAt from "ramda/src/splitAt";
import values from "ramda/src/values";
import React from "react";
import { emptyOrNil, notEmpty, isNotNil } from "./general";

export { default as makeBEMBlock } from "./bem";
export * from "./dates";
export { default as colors } from "./colors";
export * from "./format";
export * from "./general";
export * from "./links";
export { pad } from "./pad";
export * from "./redux";
export * from "./workspaces";

export const e = React.createElement;

export const isAbsent = anyPass([isEmpty, isNil]);

export const isPresent = complement(isAbsent);

export const fullUrl = (path, query) =>
  or(emptyOrNil(query), path.includes("?"))
    ? path
    : `${path}?${stringify(query)}`;

export const toHalfs = (list) =>
  compose(filter(notEmpty), splitAt(Math.ceil(list.length / 2)))(list);

export const getObjectFirstValue = compose(prop(0), values);

export const getFirstKey = compose(head, keys);

export const randomString = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const randomNumber = () =>
  Math.floor(Math.random() * (9000 - 1000)) + 1000;

export const findKeyByPath = (tabs, fullPath) =>
  keys(tabs).find((key) => tabs[key].path === fullPath);

export const containsAll = (array1 = [], array2 = []) =>
  all((val) => contains(val, array2), array1);

export const containsAny = (array1, array2) =>
  rAny((val) => contains(val, array2), array1);

export const swap = (keyA, keyB) => (obj) => ({
  [keyB]: obj[keyA],
  [keyA]: obj[keyB],
});

export const formatPrice = (price) => (price ? `$${price.toFixed(2)}` : "Free");
export const getPriceFromCents = (cents) => formatPrice(cents / 100);

export const updateSearchParams = (location, update) => {
  const { search } = location;
  const parsedSearch = parse(search);
  const mergedSearch = merge(parsedSearch, update);

  return stringify(mergedSearch);
};

export const getFromObj = flip(prop);

export const resolveSort = (search, sortByKey) => {
  const asc = "asc";
  const desc = "desc";
  const flipSort = (sortString) => (sortString === asc ? desc : asc);
  const { sortBy, sort } = search;

  if (sortBy === sortByKey) {
    return flipSort(sort);
  }

  return asc;
};

export const any = (arr) => arr.length > 0;

export const getOffset = (page, limit) => page * limit - limit;

export const makeLoginRoute = (location) => {
  const returnPath = location.search.includes("returnPath")
    ? parse(location.search).returnPath
    : `${location.pathname}${location.search}`;

  return `/signin?${stringify({ returnPath: encodeURIComponent(returnPath) })}`;
};

export const wrap = (v) => {
  if (Array.isArray(v)) {
    return v;
  }

  if (v === undefined || v === null) {
    return [];
  }

  return [v];
};

export const formatAddr = (obj) => {
  if (obj === null || obj === undefined) {
    return "";
  }

  const { address1, address2, city, state, zip } = obj;

  return [address1, address2, city, state, zip].filter((v) => !!v).join(" ");
};

export const conditionallyWrapWithQuotes = ({
  value,
  searchOcrText,
  keywordSearch = false,
}) => {
  const includesQueryLogic = (value) => {
    const andRegex = /(^|\s)and(\s|$)/i;
    const orRegex = /(^|\s)or(\s|$)/i;
    const notRegex = /(^|\s)not(\s|$)/i;
    const syntaxRegex = /["()*?]/;

    const regexChecks = [andRegex, orRegex, notRegex, syntaxRegex];

    return regexChecks.some((regex) => regex.test(value));
  };

  if (searchOcrText === true && isNotNil(value) && keywordSearch !== true) {
    const isNotEmpty = value.trim() !== "";
    const includesLogic = includesQueryLogic(value);
    if (isNotEmpty && !includesLogic) {
      return {
        outputValue: `"${value.trim()}"`,
        valueWasWrapped: true,
      };
    }
  }
  return { outputValue: value, valueWasWrapped: false };
};

export const extractSearchParams = curry((propsArray, search) => {
  return compose(pickAll(propsArray), parse)(search);
});
