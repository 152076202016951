import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isNotNil } from "../../utils";
import BlueButton from "../doc-detail.common/_button.blue";
import Body from "./_body";
import Footer from "./_footer";
import Header from "./_header";
import Icon from "./_icon";
import Title from "./_title";
import SubTitle from "./_title.sub-title";

const Card = ({ close, iconName, title, body = {}, hideBorder, isError }) => {
  const icon = useSelector(selectors.assets.getImg(`${iconName}.svg`));
  const hasTitle = isNotNil(title);

  return (
    <>
      {hasTitle && <Header>{title}</Header>}
      <Body hideBorder={hideBorder}>
        <Icon alt={iconName} src={icon} isError={isError} />
        <Title>{body.title}</Title>
        <SubTitle center>{body.content}</SubTitle>
      </Body>
      <Footer center>
        <BlueButton onClick={close}>Close</BlueButton>
      </Footer>
    </>
  );
};

export default Card;
