export const defaultWorkspaceState = {
  hasFetched: false,
  errors: "",
  isLoading: true,
  isFetchingQuote: false,
  cards: {
    legalDisplayOption: null,
  },
  data: {
    byOrder: [],
    byHash: {},
  },
  meta: {
    numRecords: 0,
    statistics: {},
  },
};

export default {};
