import toString from "ramda/src/toString";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

export const Notification = ({ message, block }) => {
  const notificationElement = block.element("notification");
  const iconElement = block.element("icon");
  const messageElement = block.element("message");

  const checkMark = useSelector(selectors.assets.getImg("checkmark-icon.svg"));

  return (
    <div className={toString(notificationElement)} role="alert">
      <img className={toString(iconElement)} alt="check-icon" src={checkMark} />
      <p className={toString(messageElement)}>{message}</p>
    </div>
  );
};

export default Notification;
