import map from "ramda/src/map";
import { ENTITY_TYPES } from "../../constants";
import { isNotNil } from "../../utils";

const determineSelectedPages = (form, totalPages, selectedPages) => {
  const pages = isNotNil(selectedPages) ? selectedPages : form.selectedPages;
  const result = { pages };

  if (selectedPages === "all") result.totalPages = totalPages;

  return result;
};

export const formatItemsForQuote = (props = {}) => {
  const {
    includeAttachments: attachments,
    includeTranscription = false,
    includeTranslation = false,
    isCertifiedCopy: certifiedCopy,
    selectedPages,
    form,
    items = [],
    department,
    totalPages,
    entityType,
  } = props;

  return map((item) => {
    const pages = determineSelectedPages(form, totalPages, selectedPages);
    const isDoc = ENTITY_TYPES.DOCUMENT === entityType;

    const includeAttachments = isNotNil(attachments)
      ? attachments
      : form.includeAttachments;

    const isCertifiedCopy = isNotNil(certifiedCopy)
      ? certifiedCopy
      : form.certifiedCopy;

    const docProps = {
      includeAttachments,
      isCertifiedCopy,
      includeTranscription,
      includeTranslation,
    };

    const optional = isDoc ? docProps : {};

    return {
      department,
      id: item.id,
      type: isDoc ? "docimage" : "attachment",
      ...pages,
      ...optional,
    };
  }, items);
};
