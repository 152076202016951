import OcrNavigation from "../doc-detail.view-document.ocr/ocr-navigation";
import SVGImage from "./svg-image";
import useCalculations from "./use-calculations";

const SinglePage = ({ page, src, canvas }) => {
  const {
    currentPageOcr,
    fit,
    hasOcrHits,
    hitIndex,
    hitMapIndex,
    isOcrVisible,
    ocrHits,
    rotation,
    setHitIndex,
    setHitMapIndex,
    state,
    toggleOcrVisibility,
    totalHits,
    type,
    zoom,
    zoomIn,
    zoomOut,
  } = page;

  const { dimensions, status, viewBox, on } = useCalculations({
    src,
    canvas,
    fit,
    zoom,
    rotation,
  });

  return (
    <>
      {isOcrVisible && hasOcrHits && (
        <OcrNavigation
          hitIndex={hitIndex}
          hitMapIndex={hitMapIndex}
          isOcrVisible={isOcrVisible}
          ocrHits={ocrHits}
          setHitIndex={setHitIndex}
          setHitMapIndex={setHitMapIndex}
          toggleOcrVisibility={toggleOcrVisibility}
          totalHits={totalHits}
        />
      )}
      <SVGImage
        currentPageOcr={currentPageOcr}
        fit={fit}
        hitIndex={hitIndex}
        isOcrVisible={isOcrVisible}
        rotation={rotation}
        src={src}
        zoom={zoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        dimensions={dimensions}
        canvas={canvas}
        state={state}
        status={status}
        type={type}
        viewBox={viewBox}
        on={on}
      />
    </>
  );
};

export default SinglePage;
