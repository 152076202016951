import equals from "ramda/src/equals";
import { keyMirror } from "../utils/key-mirror";

export const Status = keyMirror(
  "Init",
  "Loading",
  "Loaded",
  "Error",
  "Inert",
  "NeedsAuthentication"
);

export const isInit = equals(Status.Init);
export const isLoading = equals(Status.Loading);
export const hasLoaded = equals(Status.Loaded);
export const hasError = equals(Status.Error);
export const isInert = equals(Status.Inert);
export const needsAuthentication = equals(Status.NeedsAuthentication);
