import ConfirmActionModal from "../../units/account-settings.common/ConfirmActionModal";

const RemoveConfirmation = ({ close, fullName, remove, organizationName }) => {
  const content = (
    <p>
      Are you sure you want to remove {fullName} from the {organizationName}{" "}
      organization?
    </p>
  );
  const confirmationProps = {
    title: "Remove Alert Criteria",
    content,
    cancel: { onClick: close, text: "Cancel" },
    action: { onClick: remove, text: "Remove" },
  };

  return <ConfirmActionModal {...confirmationProps} />;
};

export default RemoveConfirmation;
