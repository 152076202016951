import Thumbnail from "./_thumbnail";

const MultiPage = ({ thumbnails, page }) => {
  if (!thumbnails) return <loading-indicator />;

  const selectThumbnail = (index) => (event) => {
    page.viewSinglePage(event);
    page.goToPage(index);
  };

  return (
    <Thumbnail>
      {thumbnails.map((src, i) => {
        return (
          <button key={src} onClick={selectThumbnail(i)}>
            <img src={src} alt={`document thumbnail #${i}`} />
          </button>
        );
      })}
    </Thumbnail>
  );
};

export default MultiPage;
