import { detect } from "detect-browser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../store";
import { isNotNil } from "../utils";

export const useAnnouncements = () => {
  const dispatch = useDispatch();
  const browser = detect();
  const unsupportedBrowsers = ["ie"];
  const message = useSelector(selectors.configuration.getAnnouncement);

  useEffect(() => {
    if (isNotNil(message)) {
      dispatch(actions.announcements.setAnnouncement(message));
    }

    if (browser && unsupportedBrowsers.includes(browser.name)) {
      const ieUnsupportedMsg = `
        Vanguard Search will end support for Internet Explorer (all versions) effective
        3/1/2020. Click <a
        href="https://kofilehelp.zendesk.com/hc/en-us/articles/360042243453-End-of-Support-for-Internet-Explorer">here</a>
        for details.`;
      dispatch(actions.announcements.setAnnouncement(ieUnsupportedMsg));
    }
  }, []);
};
