import PropTypes from "prop-types";
import isEmpty from "ramda/src/isEmpty";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LoadingSpinner, Table } from "../../../../../../../components";
import { selectors } from "../../../../../../../store";
import { activeAlertsConfig, NoAlerts, TableError } from "./";

const ActiveAlerts = ({ alerts, loading }) => {
  const alertTable = (
    <Table
      className="fraud-alert-account__table"
      metadata={activeAlertsConfig()}
      data={alerts}
    />
  );

  const AddAlertLink = () => (
    <Link
      className="fraud-alert__new-alert"
      to={`/property-alert`}
      data-testid="addNewAlert"
    >
      Add
    </Link>
  );

  const hasError = useSelector(selectors.fraudAlert.hasFetchAlertsError);
  const errorMsg = hasError
    ? "Can't fetch alert criteria right now. Please try again."
    : "";

  const tableData = () => {
    if (hasError) {
      return null;
    }

    return isEmpty(alerts) ? <NoAlerts type="active-alerts" /> : alertTable;
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Fragment>
      <div className="fraud-alert-account__title active-alert-table">
        Alert Criteria
        <AddAlertLink />
      </div>
      <TableError message={errorMsg} />
      {tableData()}
    </Fragment>
  );
};

ActiveAlerts.displayName = "ActiveAlerts";

ActiveAlerts.propTypes = {
  alerts: PropTypes.array,
  loading: PropTypes.bool,
};

ActiveAlerts.defaultProps = {
  alerts: [],
};

export default ActiveAlerts;
