import PropTypes from "prop-types";
import { SelectAction } from "../";

const Actions = ({ actions, label }) => (
  <SelectAction options={actions} label={label} />
);

Actions.displayName = "Actions";
Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
};

export default Actions;
