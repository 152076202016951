import { css } from "@emotion/react";

export const styles = {
  container: (theme) => css`
    margin-top: 32px;
    max-width: 676px;
    color: #041d44;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      font-size: 14px;
      max-width: 358px;
    }
    > h3 {
      font-size: 15px;
      font-weight: 600;

      @media screen and (max-width: ${theme.breakpoints.md}px) {
        font-size: 14px;
      }
    }
  `,
};
