import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { hasLoaded } from "../../../constants";
import { withAuthentication } from "../../../hoc";
import RefreshOrgAdminContext from "../../../units/account-settings.subscriptions.common/refresh-org-admin-context";
import { useSubscriptionAdminCheck } from "../../../units/common/use-subscription-admin-check";
import { SettingsNav } from "./nav";
import { SettingsView } from "./SettingsView/index";

const style = css`
  display: grid;
  grid-template: 100% / 250px auto;

  @media print {
    height: 100%;
    margin-bottom: 32px;
  }
`;

export const Account = () => {
  const { isEnabled, status, error, initiate } = useSubscriptionAdminCheck();
  const [organizationEnabled, setOrganizationEnabled] = useState(false);
  const refreshOrgAdmin = () => void initiate();

  useEffect(() => {
    if (hasLoaded(status) && isNil(error)) {
      setOrganizationEnabled(isEnabled);
    }
  }, [isEnabled, status, error]);

  return (
    <RefreshOrgAdminContext.Provider value={refreshOrgAdmin}>
      <div css={style}>
        <Helmet>
          <title>Account</title>
        </Helmet>

        <SettingsNav organizationEnabled={organizationEnabled} />
        <SettingsView organizationEnabled={organizationEnabled} />
      </div>
    </RefreshOrgAdminContext.Provider>
  );
};

export default withAuthentication(Account);
