import path from "ramda/src/path";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isPresent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";
import AddToCart from "./action.add-to-cart";
import CertifiedCopyCheckoutButton from "./action.checkout.certified-copy";
import ExpressCheckoutButton from "./action.checkout.express";
import ConfigurePropertyAlert from "./action.configure-property-alert";
import CustomActionButton from "./action.custom";
import DownloadButton from "./action.download";
import ToggleDetails from "./action.toggle-details";
import Back from "./navigate.back";
import NavigateBookButton from "./navigate.book.button";
import NavigateSearchButton from "./navigate.search.button";
import styles from "./style";
import { navigateTo } from "./use-navigate-docs-by-results";

const Navigation = ({ showDetails, toggleDetails }) => {
  const data = useDocument();
  const { configuration } = selectors;
  const bookNavEnabled = useSelector(configuration.isNavDocsByBookEnabled);
  const customActions = prop("customActions", data);

  return (
    <nav id="primary" css={styles.wrapper(bookNavEnabled)}>
      <Back />

      <NavigateSearchButton
        style={styles.prevSearch}
        to={navigateTo.Previous}
      />
      <NavigateSearchButton style={styles.nextSearch} to={navigateTo.Next} />

      {bookNavEnabled && (
        <>
          <p css={styles.bookLabel}>
            {path(["bookVolPageNav", "label"], data)}
          </p>
          <NavigateBookButton
            style={styles.prevBook}
            to={navigateTo.Previous}
          />
          <NavigateBookButton style={styles.nextBook} to={navigateTo.Next} />
        </>
      )}

      <div css={styles.buttons}>
        <AddToCart />
        <ExpressCheckoutButton />
        <CertifiedCopyCheckoutButton />
        <DownloadButton />
        <ConfigurePropertyAlert />
        {isPresent(customActions) &&
          customActions.map((action, i) => (
            <CustomActionButton key={`customActions-${i}`} {...action} />
          ))}
      </div>
      <ToggleDetails show={showDetails} toggleDetails={toggleDetails} />
    </nav>
  );
};
export default Navigation;
