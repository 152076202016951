import { types as syncTypes } from "@kofile/ko-search-action-types";
import types from "../types/user";

const actions = {
  register: (payload) => ({ type: syncTypes.REGISTER_0, payload }),
  signIn: (payload) => ({ type: syncTypes.SIGN_IN_0, payload }),
  signOut: (payload) => ({ type: syncTypes.SIGN_OUT_0, payload }),
  changePassword: (payload) => ({ type: "change-password", payload }),
  clearError: (payload) => ({ type: types.CLEAR_ERROR, payload }),
  setError: (payload) => ({ type: types.SET_ERROR, payload }),
  sanitizeToken: (payload) => ({ type: types.SANITIZE_TOKEN, payload }),
  setTourViewed: (payload) => ({
    type: syncTypes.UPDATE_TOUR_VIEWED_0,
    payload,
  }),
  setUseSavedCard: (payload) => ({ type: types.SET_USE_SAVED_CARD, payload }),
  saveCard: (payload) => ({ type: syncTypes.REQUEST_SAVE_CARD_0, payload }),
  saveCardFulfilled: (payload) => ({
    type: syncTypes.REQUEST_SAVE_CARD_FULFILLED_0,
    payload,
  }),
  saveCardRejected: (payload) => ({
    type: syncTypes.REQUEST_SAVE_CARD_REJECTED_0,
    payload,
  }),
  verify: (payload) => ({ type: syncTypes.VERIFY_USER_REQUESTED_0, payload }),
  updateAuth: (payload) => ({ type: types.UPDATE_AUTH, payload }),
  fetchUserCards: (payload) => ({
    type: syncTypes.FETCH_USER_CARDS_0,
    payload,
  }),
  clearCardInfo: (payload) => ({ type: types.CLEAR_CARD_INFO, payload }),
  clearRegistrationComplete: (payload) => ({
    type: types.CLEAR_REGISTRATION_COMPLETE,
    payload,
  }),
  fetchUserGeneralSettings: (payload) => ({
    type: "fetch-user-settings",
    payload,
  }),
  setUserSettings: (payload) => ({ type: "set-user-settings", payload }),
  nonInteractiveSignin: (payload) => ({
    type: syncTypes.NON_INTERACTIVE_SIGN_IN_0,
    payload,
  }),
  addDocumentsToCart: (payload) => ({
    type: types.ADD_DOCUMENTS_TO_CART,
    payload,
  }),
  addAttachmentsToCart: (payload) => ({
    type: types.ADD_ATTACHMENTS_TO_CART,
    payload,
  }),
  expressCheckout: (payload) => ({ type: types.EXPRESS_CHECKOUT, payload }),
  exportResults: (payload) => ({ type: types.EXPORT_RESULTS, payload }),
  removeSavedCard: (payload) => ({
    type: syncTypes.REMOVE_SAVED_CARD_0,
    payload,
  }),
  resetRemoveCard: () => ({ type: types.RESET_REMOVE_CARD }),
};

export default actions;
