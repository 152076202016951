import equals from "ramda/src/equals";
import { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import subscriptionsContext from "../../App/screens/Account/SettingsView/views/subscriptions-context";
import { hasLoaded } from "../../constants";
import { isPresent } from "../../utils";
import { Admin } from "./history.admin";
import { Individual } from "./history.individual";

export const HistoryTables = ({ kind, selectedView }) => {
  const history = useHistory();
  const [organizationId, setOrganizationId] = useState(null);
  const { status, subscriptions } = useContext(subscriptionsContext);
  const individualRoute = "/account/history/individual";
  const multiUserRoute = "/account/history/multi-user";

  useEffect(() => {
    const multiUserSelected = equals(kind.multiUserAdmin);
    if (multiUserSelected(selectedView)) {
      history.push(multiUserRoute);
    } else {
      history.push(individualRoute);
    }
  }, [selectedView]);

  useEffect(() => {
    if (hasLoaded(status) && isPresent(subscriptions)) {
      setOrganizationId(subscriptions[0].organizationId);
    }
  }, [subscriptions, status]);

  return (
    <Switch>
      <Route path={individualRoute}>
        <Individual />
      </Route>
      <Route path={multiUserRoute}>
        <Admin organizationId={organizationId} />
      </Route>
    </Switch>
  );
};
