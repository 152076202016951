import styled from "@emotion/styled";

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: "icons";
  height: 100%;
  margin-left: auto;
  margin-top: 5px;
  z-index: 1;
`;
