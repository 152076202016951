import { useAPI } from "../../hooks/use-api";

export const usePlans = ({ currentPlanCode }) => {
  return useAPI({
    send: "fetch-available-subscription-plans",
    exportAs: "plans",
    initialState: { individual: [], multiUser: [] },
    initialPayload: currentPlanCode ? { currentPlanCode } : {},
    listenFor: {
      success: "subscription-plans",
      failure: "error-fetching-available-subscription-plans",
    },
  });
};
