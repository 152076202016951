import invertObj from "ramda/src/invertObj";
import merge from "ramda/src/merge";

export const paths = {
  HOME: "/",
  ADVANCED: "/search/advanced",
  IMAGE: "/search/image",
  QUICKLINK: "/quicklink",
  RESULTS: "/results",
  CART: "/cart",
  CART_CONTENTS: "/cart/contents",
  CART_CHECKOUT: "/cart/placeorder",
  DOC_DETAIL: "/doc",
};

export const workspacePaths = {
  search: paths.HOME,
  quicklink: paths.QUICKLINK,
  advanced: paths.ADVANCED,
  image: paths.IMAGE,
  results: paths.RESULTS,
  cart: paths.CART_CONTENTS,
  detail: paths.DOC_DETAIL,
};

export const workspaceTypes = merge(invertObj(workspacePaths), {
  [paths.HOME]: "search",
  [paths.CART]: "cart",
});
