import styled from "@emotion/styled";
import { Link } from "../../../../components";
import { navLink } from "./_style.nav-link";

const UserName = styled.div`
  height: 100%;
  line-height: 1.5;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserButton = ({ fullName }) => {
  return (
    <Link to="/account/profile" css={navLink} aria-label="Account Settings">
      <UserName data-testid="user">{fullName}</UserName>
    </Link>
  );
};

export default UserButton;
