import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { assets } from "../../../../store/selectors";
import { getPriceFromCents, isNotNil } from "../../../../utils";
import { capitalizeFirstLetter } from "../../../../utils/capitalize-first-letter";
import { TableRow } from "./_entry.table-row";
import styles from "./entry.styles";
import { Column } from "./entry.table-column";
import { getSelectedPages, COLUMN_SIZES } from "./entry.utils";

export const Row = (props) => {
  const { cartId, cartItemId } = props;
  const selectedPages = getSelectedPages(props.selectedPages, props.totalPages);
  const price = getPriceFromCents(props.price);
  const removeIcon = useSelector(assets.getImg("icon_remove.svg"));

  const copy = isNotNil(props.type)
    ? capitalizeFirstLetter(props.type)
    : `Attachment ${props.num + 1}`;

  return (
    <TableRow>
      <Column size={COLUMN_SIZES.LARGE}>
        <div css={styles.table.buttonWrap}>
          <button onClick={props.removeFromCart(cartId, [cartItemId])}>
            <img src={removeIcon} alt="remove" />
          </button>
          {copy}
        </div>
      </Column>
      <Column size={COLUMN_SIZES.SMALL}>{selectedPages}</Column>
      <Column size={COLUMN_SIZES.MEDIUM}>Download</Column>
      <Column size={COLUMN_SIZES.SMALL}>{price}</Column>
    </TableRow>
  );
};

Row.propTypes = {
  instrumentNumber: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  docID: PropTypes.string.isRequired,
  selectedPages: PropTypes.string.isRequired,
};
