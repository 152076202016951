import lensProp from "ramda/src/lensProp";

const root = lensProp("onlineStatus");
const attemptConnectAt = lensProp("attemptConnectAt");
const disconnectedAt = lensProp("disconnectedAt");
const connectedAt = lensProp("connectedAt");
const connected = lensProp("connected");

export default Object.assign(root, {
  attemptConnectAt,
  disconnectedAt,
  connectedAt,
  connected,
});
