import { makeBaseWorkspace } from "../../utils";
import { newTypes } from "../reducers/workspaces";
import types from "../types/workspaces";

export default {
  addWorkspace: (payload) => ({
    type: types.ADD,
    payload: makeBaseWorkspace(payload),
  }),
  activateWorkspace: (payload) => ({ type: types.ACTIVATE, payload }),
  updateSelectedDepartment: (payload) => ({
    type: types.UPDATE_SELECTED_DEPARTMENT,
    payload,
  }),
  removeTab: (payload) => ({ type: types.REMOVE, payload }),
  addSelectedDocument: (payload) => ({
    type: types.ADD_SELECTED_DOCUMENT,
    payload,
  }),
  removeSelectedDocument: (payload) => ({
    type: types.REMOVE_SELECTED_DOCUMENT,
    payload,
  }),
  addAllDocuments: (payload) => ({ type: types.ADD_ALL_DOCUMENTS, payload }),
  removeAllDocuments: (payload) => ({
    type: types.REMOVE_ALL_DOCUMENTS,
    payload,
  }),
  updateQuickSearchState: (payload) => ({
    type: types.UPDATE_QUICK_SEARCH_STATE,
    payload,
  }),
  updateAdvancedSearchState: (payload) => ({
    type: types.UPDATE_ADVANCED_SEARCH_STATE,
    payload,
  }),
  doNothing: (payload) => ({ type: "DO_NOTHING", payload }),
  saveSearchUrl: (payload) => ({ type: newTypes.saveSearchUrl, payload }),
  saveDocSummaryTab: (payload) => ({
    type: newTypes.saveSelectedDocSummaryTab,
    payload,
  }),
};
