import { isToday, format } from "date-fns";
import isNil from "ramda/src/isNil";
import { ISOToDateOnly } from "../../utils/dates";

const LastActiveOn = ({ date }) => {
  if (isNil(date)) return null;

  const localLastActive = ISOToDateOnly(date);
  const dateIsToday = isToday(localLastActive);
  const formattedDate = format(localLastActive, "MMM do, yyyy");

  return <div>{dateIsToday ? "Today" : formattedDate}</div>;
};

export default LastActiveOn;
