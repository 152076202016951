import isEmpty from "ramda/src/isEmpty";
import { useSelector } from "react-redux";
import { DropdownMenu, Link } from "../../components";
import { selectors } from "../../store";

const optionRenderer = (checkIcon) =>
  Object.assign(
    (workspace) => (
      <Link
        to={workspace.path}
        className="workspace-tabs__link"
        workspaceID={workspace.id}
      >
        <span className="workspace-tabs__title">{workspace.title}</span>
        {workspace.isActive && (
          <img
            src={checkIcon}
            className="workspace-tabs__check-icon"
            alt="check icon"
          />
        )}
      </Link>
    ),
    { displayName: "Workspace" }
  );

export const OverflowMenu = ({ overflowWorkspaces = [] }) => {
  const checkIcon = useSelector(selectors.assets.getImg("blue_check.svg"));

  if (isEmpty(overflowWorkspaces)) return null;

  return (
    <DropdownMenu
      className="workspace-tabs__overflow-menu"
      label={null}
      optionRenderer={optionRenderer(checkIcon)}
      options={overflowWorkspaces}
    />
  );
};

export default OverflowMenu;
