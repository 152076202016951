import React from "react";
import { Helmet } from "react-helmet";

const styles = {
  wrapper: {
    padding: "30px 0",
    textAlign: "center",
  },
  heading: {
    paddingBottom: "15px",
    fontSize: "24px",
  },
};

export const PaymentComplete = () => (
  <React.Fragment>
    <Helmet>
      <title>Loading Payment</title>
    </Helmet>
    <div style={styles.wrapper} className="payment-complete_wrapper">
      <h1 style={styles.heading}>Loading Payment...</h1>
      <p style={{ padding: "10px 0" }}>
        Thank you for your patience. You should be automatically redirected.
      </p>
    </div>
  </React.Fragment>
);

PaymentComplete.displayName = "PaymentComplete";

export default PaymentComplete;
