import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isPresent } from "../../utils";
import useDocument from "../doc-detail.common/useDocument";

export const useExternalCertProvider = () => {
  const doc = useDocument();

  const { configuration } = selectors;
  const extCerts = useSelector(configuration.getExternalCertProvider);
  const docTypeTable = useSelector(configuration.getDocTypeLookupTable);

  const docType = docTypeTable[doc.docType];
  const { enabled, copy = {} } = extCerts;
  const path = ["make", "purchaseUrl"];
  const makePurchaseUrl = pathOr(() => {}, path, extCerts);

  const url = makePurchaseUrl({
    referenceNo: prop("instrumentNumber", doc),
    description: `${docType} | ${prop("docNumber", doc)}`,
    pageCount: prop("pageCount", doc),
  });

  const initiate = () => {
    if (window.confirm(prop("exitConfirmation", copy)) && isPresent(url)) {
      window.open(url, "_blank");
    }
  };

  return { initiate, enabled };
};
