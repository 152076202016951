import { css } from "@emotion/react";
import either from "ramda/src/either";
import { Fragment, useEffect } from "react";
import { hasError, hasLoaded, isLoading } from "../../constants/status";
import { useNotifications } from "../../hooks/use-notifications";
import ConfirmActionModal from "../account-settings.common/ConfirmActionModal";
import { formatDate } from "../account-settings.subscriptions.common/format-date";
import { useCancelSubscription } from "./use-cancel-subscription";

const notification = {
  error: "An error occurred when trying to cancel your subscription.",
  success:
    "Your subscription has been cancelled. A confirmation email has been sent.",
};

const shouldBeDisabled = either(isLoading, hasLoaded);

const cancelStyle = css`
  padding-top: 2px;
`;

export const CancelModal = (props) => {
  const { subscription, close, onCancel, isMultiUserAdmin } = props;

  const { initiate, status, error, subscriptions } = useCancelSubscription();
  const pushNotification = useNotifications();
  const isDisabled = shouldBeDisabled(status);

  const cancel = () => void initiate({ subscriptionId: subscription.id });

  const onSuccess = (updatedSubList) => {
    pushNotification({ message: notification.success });
    onCancel(updatedSubList);
    close();
  };

  useEffect(() => {
    if (hasLoaded(status)) {
      onSuccess(subscriptions);
    }
  }, [status, subscriptions]);

  useEffect(() => {
    if (hasError(status)) {
      pushNotification({ message: notification.error });
      close();
    }
  }, [error]);

  const orgWarning = isMultiUserAdmin
    ? "All order history for this organization will be lost if you cancel."
    : "";

  const body = `Your subscription will ${
    subscription.endDate
      ? `remain active until ${formatDate(subscription.endDate)}.`
      : "be cancelled at the end of this billing cycle."
  }`;

  const content = (
    <Fragment>
      <p>
        <strong>
          Are you sure you want to cancel your monthly subscription?
        </strong>
      </p>
      <p css={cancelStyle}>{body}</p>
    </Fragment>
  );

  const confirmationProps = {
    title: "Cancel Subscription",
    loading: isDisabled,
    content,
    error: orgWarning,
    warnUser: isMultiUserAdmin,
    cancel: { onClick: close, text: "Don't Cancel" },
    action: {
      onClick: cancel,
      text: "Cancel Subscription",
      disabled: isDisabled,
    },
  };

  return <ConfirmActionModal {...confirmationProps} />;
};
