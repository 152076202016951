import styled from "@emotion/styled";

export const Or = styled.div`
  align-items: center;
  color: #62738d;
  display: flex;
  font-size: 25px;
  grid-area: or;
  justify-content: center;
  text-transform: uppercase;
`;
