import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { selectors } from "../../store";
import { ChoosePlan } from "../account-settings.subscriptions.common/choose-plan";
import { Activate } from "./activate";
import { ApplyForSubscription } from "./apply";
import { Pay } from "./pay";
import { PayFree } from "./pay.free";

const makeRoutes = (path) => ({
  Activate: `${path}/activate`,
  SubmitApplication: `${path}/apply`,
  PendingApplication: `${path}/pending-application`,
  Choose: `${path}/choose-your-plan`,
  Pay: `${path}/confirm-payment`,
  PayFree: `${path}/subscribing-to-plan`,
  Done: `${path}/subscription-activated`,
});

export const SignupForSubscription = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [organizationName, setOrganizationName] = useState("");
  const [plan, setPlan] = useState(null);
  const Routes = makeRoutes(path);

  const applicationEnabled = useSelector(
    selectors.configuration.getSubscriptionApplicationEnabled
  );

  const Go = {
    ToChoose: () => history.push(Routes.Choose),
    ToPay: (chosenPlan) => {
      if (chosenPlan && chosenPlan.amount === "$0.00") {
        history.push(Routes.PayFree);
      } else {
        history.push(Routes.Pay);
      }
    },
    ToSubmitApplication: () => history.push(Routes.SubmitApplication),
    ToDone: () => history.push(Routes.Done),
    Back: () => history.goBack(),
  };

  const Replace = {
    WithChoose: () => history.replace(Routes.Choose),
  };

  if (applicationEnabled) {
    return (
      <ApplyForSubscription
        Go={Go}
        Routes={Routes}
        Replace={Replace}
        setPlan={setPlan}
        plan={plan}
      />
    );
  }

  return (
    <Switch>
      <Route path={Routes.Activate}>
        <Activate next={Go.ToChoose} />
      </Route>
      <Route path={Routes.Choose}>
        <ChoosePlan
          select={setPlan}
          next={Go.ToPay}
          organizationName={organizationName}
          setName={setOrganizationName}
        />
      </Route>
      <Route path={Routes.Pay}>
        <Pay
          plan={plan}
          organizationName={organizationName}
          next={Go.ToDone}
          prev={Replace.WithChoose}
          back={Go.Back}
        />
      </Route>
      <Route path={Routes.PayFree}>
        <PayFree
          plan={plan}
          organizationName={organizationName}
          next={Go.ToDone}
          prev={Replace.WithChoose}
        />
      </Route>
      <Route path={Routes.Done}>
        <Redirect to={path} />
      </Route>
      <Redirect to={Routes.Activate} />
    </Switch>
  );
};
