import { css } from "@emotion/react";
import classnames from "classnames";
import { useState } from "react";

const hideLabel = css`
  display: none;
  position: absolute;
  left: -9999px;
`;

const PasswordInput = (props) => {
  const {
    showPasswordOption = false,
    className = "",
    onChange,
    value,
    placeholder,
    required,
    id = "password",
    ariaLabel = "password",
    name = "password",
  } = props;

  const inputId = id;
  const describedById = `${inputId}-hint`;

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const showPassword = showPasswordOption && isPasswordShown;
  const togglePassword = () => setIsPasswordShown((v) => !v);

  const ShowButton = (
    <div className="password-input__show-button">
      <button
        onClick={togglePassword}
        type="button"
        className="password-input__toggle"
        aria-label={showPassword ? "Hide password" : "Show password"}
        aria-pressed={isPasswordShown}
        aria-controls={inputId}
      >
        {showPassword ? "HIDE" : "SHOW"}
      </button>
    </div>
  );

  return (
    <div
      className={classnames(className, "password-input")}
      role="group"
      aria-labelledby={describedById}
    >
      <input
        type={showPassword ? "text" : "password"}
        className={classnames("password-input__input", {
          "password-input__input--mask": showPasswordOption,
        })}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        required={required}
        id={inputId}
        name={name}
        aria-label={ariaLabel}
        aria-describedby={describedById}
        autoComplete="current-password"
      />
      {showPasswordOption && ShowButton}
      <span id={describedById} css={hideLabel}>
        Password must be at least 8 characters long
      </span>
    </div>
  );
};

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
