import { useEffect, useState } from "react";
import { SettingsPane } from "../../App/screens/Account/SettingsView/settings-pane";
import { keyMirror } from "../../utils/key-mirror";
import { ExternalServiceWarning } from "./history.externalCertWarning";
import { HistoryTables } from "./history.tables";
import { Navigation } from "./navigation";

export const Kind = keyMirror("individual", "multiUserAdmin");

export const History = ({
  isOrganizationAdmin,
  selectedView,
  setSelectedView,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isOrganizationAdmin === true) {
      setIsAdmin(true);
    }
  }, [isOrganizationAdmin]);

  return (
    <SettingsPane title={"Order History"}>
      {isAdmin && (
        <Navigation
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          kind={Kind}
        />
      )}
      <ExternalServiceWarning />
      <HistoryTables selectedView={selectedView} kind={Kind} />
    </SettingsPane>
  );
};

export default History;
