import { css } from "@emotion/react";
import classnames from "classnames";
import always from "ramda/src/always";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import path from "ramda/src/path";
import pipe from "ramda/src/pipe";
import { useState, useEffect } from "react";
import Select from "../../../components/Select";

const style = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
`;

const slice = (start, end) => (s) => s.slice(start, end);

const getMonth = ifElse(isNil, identity, pipe(slice(4, 6), Number));

const getYear = ifElse(isNil, identity, pipe(slice(0, 4), Number));

const safeFmtNum = (maybeNum, length, strictEqualLength) => {
  const empty = "".padStart(length, "-");
  const asStr = String(maybeNum);

  if (maybeNum === 0) return empty;
  if (strictEqualLength === true && asStr.length !== length) return empty;

  return String(maybeNum);
};

const MonthPicker = (props) => {
  const { className = "", onChange, value } = props;
  const [month, setMonth] = useState(getMonth(value));
  const [year, setYear] = useState(getYear(value));

  const changeMonth = pipe(path(["value"]), setMonth);

  const changeYear = pipe(
    path(["currentTarget", "value"]),
    ifElse(isEmpty, always(0), Number),
    setYear
  );

  useEffect(() => {
    if (month === 0 && year === 0) return;

    const newMonth = safeFmtNum(month, 2).padStart(2, "0");
    const newYear = safeFmtNum(year, 4, true);
    const newValue = `${newYear}${newMonth}`;

    if (value !== newValue) onChange(newValue);
  }, [month, year, onChange, value]);

  const options = [
    { value: 0, label: "Select a Month" },
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  return (
    <div className={classnames(className, "month-picker")} css={style}>
      <Select
        options={options}
        value={month || ""}
        onChange={changeMonth}
        className="select"
      />

      <input
        placeholder="Enter a year (YYYY)"
        className="form-field__input"
        type="number"
        id="select-year"
        min="1600"
        max="9999"
        value={year || ""}
        onChange={changeYear}
      />
    </div>
  );
};

export default MonthPicker;
