import isNil from "ramda/src/isNil";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import { isNotNil, isPresent, isAbsent } from "../../../../utils";

export const COLUMN_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const format = (pages) => (isNotNil(pages) ? `All (${pages})` : "All");

export const getSelectedPages = (selectedPages, totalPages) => {
  return selectedPages === "all" || isNil(selectedPages)
    ? format(totalPages)
    : selectedPages;
};

export const attachmentIdsBy = (key, attachments) =>
  map((a) => a[key], attachments);

export const displaySupportDocIfInCart = (isInCart, props) => (type) => {
  const fn = isInCart ? isPresent : isAbsent;
  const supportDoc = props[type];
  const hasData = isPresent(supportDoc);
  const inCart = fn(prop("cartItemId", supportDoc));

  return isNotNil(supportDoc) && hasData && inCart;
};
