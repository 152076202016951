// mod: doc-preview

import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { DocumentActions } from "../../../../../components";
import { selectors } from "../../../../../store";
import { useShouldDownload } from "../use-should-download";

const Actions = ({ doc, config }) => {
  const currentId = doc.id;
  const { results, documentsHash, newDocPreviewEnabled } = config;

  useShouldDownload({ docId: currentId });

  const determineAlignment = () => {
    const findIdByIndex = (steps) =>
      prop("id", results[results.length - steps]);

    const lastId = findIdByIndex(1);
    const secondToLastId = findIdByIndex(2);
    const docAtEndOfList = currentId === lastId || currentId === secondToLastId;
    const shouldDisplayTop = docAtEndOfList && results.length >= 5;

    if (results.length <= 5) return "center";
    if (shouldDisplayTop) return "top";

    return "bottom";
  };

  const ellipsisIcon = useSelector(
    selectors.assets.getImg("icon_ellipsis.svg")
  );

  const isSecured = (
    <div className="a11y-menu">
      <div className="a11y-menu__control">
        <img src={config.isSecuredIcon} alt="secured document" />
      </div>
    </div>
  );

  const customActions = newDocPreviewEnabled
    ? pathOr([], [currentId, "customActions"], documentsHash)
    : [];

  const actionDropdown = (
    <DocumentActions
      docId={currentId}
      customActions={customActions}
      imageId={doc.imageId}
      label={<img alt="menu icon" src={ellipsisIcon} />}
      alignment={determineAlignment()}
    />
  );

  return (
    <div className="list-view__cart-wrap" data-testid="resultActionButton">
      {doc.isSecured ? isSecured : actionDropdown}
    </div>
  );
};

export default Actions;
