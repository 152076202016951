export default (url, toggleLoading = () => {}) => {
  const downloadUrl = `${window.location.origin}${url}`;
  let iframe = document.querySelector("#download-iframe");

  if (iframe) {
    iframe.setAttribute("src", downloadUrl);
  } else {
    iframe = document.createElement("iframe");

    iframe.setAttribute("style", "visibility:hidden;display:none");
    iframe.setAttribute("id", "download-iframe");
    iframe.setAttribute("src", downloadUrl);

    document.body.appendChild(iframe);
  }

  toggleLoading();
};
