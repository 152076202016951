import isNil from "ramda/src/isNil";
import { noop } from "../utils";

const { Element, SVGElement } = global;

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

if (typeof global.CustomEvent !== "function") {
  const CustomEvent = function (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };

    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );

    return evt;
  };

  CustomEvent.prototype = global.Event.prototype;

  global.CustomEvent = CustomEvent;
}

if (!("classList" in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, "classList", {
    get() {
      return {
        contains: (className) => {
          return this.className.baseVal.split(" ").indexOf(className) !== -1;
        },
      };
    },
  });
}

// IE 11 and Edge doesn't supports focus on SVG elements 🤦‍♂️
if (isNil(SVGElement.prototype.focus)) {
  SVGElement.prototype.focus = noop;
}

if (isNil(SVGElement.prototype.blur)) {
  SVGElement.prototype.blur = noop;
}
