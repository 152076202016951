import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectors } from "../../../store";
import { styles } from "./marginal-reference.style";

const MarginalReferenceUrls = ({ data = [] }) => {
  const { isLimitedSearchEnabled } = selectors.configuration;
  const limitedSearchEnabled = useSelector(isLimitedSearchEnabled);

  return data.map((item) => {
    if (typeof item === "string") {
      return (
        <span css={styles.standaloneText} key={item}>
          {item}
        </span>
      );
    }

    const { instrumentNumber, id, docTypeDesc, recordedDate } = item;
    const instrNumCopy = `Instrument Number: ${instrumentNumber}`;

    return (
      <div key={id} css={styles.block}>
        {!limitedSearchEnabled && (
          <Link css={styles.link} to={`/doc/${id}`}>
            {instrNumCopy}
          </Link>
        )}
        {limitedSearchEnabled && (
          <span css={styles.linkAsText}>{instrNumCopy}</span>
        )}
        <span css={styles.uppercaseLabel}>{docTypeDesc}</span>
        <span css={styles.label}>{recordedDate}</span>
      </div>
    );
  });
};

export default MarginalReferenceUrls;
