import always from "ramda/src/always";
import curryN from "ramda/src/curryN";
import equals from "ramda/src/equals";
import ifElse from "ramda/src/ifElse";
import propSatisfies from "ramda/src/propSatisfies";

export const whenPropIs = curryN(4, (eqValue, propName, thenValue, obj) =>
  ifElse(
    propSatisfies(equals(eqValue), propName),
    always(thenValue),
    always(undefined)
  )(obj)
);
