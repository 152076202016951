export const valueObjectMaker = (keys) => (props) =>
  deepFreeze(
    keys.reduce((memo, key) => {
      if (props[key] === undefined) {
        throw new Error(`${key} must be provided`);
      }

      return { ...memo, [key]: props[key] };
    }, {})
  );

function deepFreeze(object) {
  for (let name in object) {
    const value = object[name];

    if (!Object.isFrozen(value)) {
      object[name] =
        value && typeof value === "object" ? deepFreeze(value) : value;
    }
  }

  return Object.freeze(object);
}
