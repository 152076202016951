import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin: 50px auto 0;
    width: 500px;
  `,
  icon: css`
    height: 100px;
    margin: auto;
    width: 100px;
  `,
  text: css`
    color: rgba(4, 29, 68, 0.8);
    font-size: 32px;
    margin-top: 50px;
    text-align: center;
  `,
};

export const Empty = () => {
  const historyIcon = useSelector(
    selectors.assets.getImg("icon_order_history_large.svg")
  );

  return (
    <div css={styles.container}>
      <img css={styles.icon} src={historyIcon} alt="history" />
      <p css={styles.text}>You have not placed any orders.</p>
    </div>
  );
};

export default Empty;
