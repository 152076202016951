import { css } from "@emotion/react";
import { Fragment } from "react";
import { useAsset } from "../../hooks/use-asset";
import { PrintButton } from "./../../units/common/_print.button";

const style = {
  wrap: css`
    padding: 16px 0;
    display: grid;
    grid-template-areas:
      "icon heading print-button"
      "icon copy    print-button";
    grid-template-columns: min-content auto min-content;
    gap: 0 16px;

    @media print {
      display: none;
    }
  `,
  print: css`
    grid-area: print-button;
  `,
  icon: css`
    grid-area: icon;
    width: 46px;
    height: 46px;
  `,
  heading: css`
    grid-area: heading;
    font-size: 24px;
    color: #031c43;
  `,
  copy: css`
    grid-area: copy;
    color: #354868;
    font-size: 14px;
  `,
};

const headerCopy = {
  default:
    "Please print the receipt for your record. " +
    "An appropriate e-mail has been sent.",
  certified:
    "A certified copy request has been sent. " +
    "We'll email you once it's ready for download.",
};

export const Header = ({ fresh }) => {
  const icon = {
    check: useAsset("icon_check.svg"),
    email: useAsset("icon_mail.svg"),
  };

  // const icon = hasCertifiedCopies ? mailIcon : checkIcon;
  // const text = hasCertifiedCopies ? certifiedText : defaultText;

  return (
    <header css={style.wrap}>
      {fresh === true && (
        <Fragment>
          <img src={icon.check} alt={"check"} css={style.icon} />
          <h1 css={style.heading}>Your order has been completed.</h1>
          <p css={style.copy}>{headerCopy.default}</p>
        </Fragment>
      )}
      <PrintButton emotionCss={style.print} />
    </header>
  );
};

Header.defaultProps = {
  fresh: false,
};
