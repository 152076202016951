import createMatcher from "feather-route-matcher";
import { parse } from "query-string";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { DropdownMenu } from "../../../../components";
import { actions } from "../../../../store";
import { resultsTour, quickSearchTour } from "../../../../tours";
import { DropdownLink } from "./_dropdown-link";

export const HelpMenu = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const runTour = (props) => dispatch(actions.tour.run(props));
  const openLiveChat = () => dispatch(actions.help.openLiveHelpChat());

  const tourRoutes = {
    "/results": {
      fn: runResultsTour(runTour),
      shouldRun: isListView({ location }),
    },
    "/": {
      fn: runQuickSearchTour(runTour),
      shouldRun: true,
    },
  };

  const matcher = createMatcher(tourRoutes);
  const { page: tourProps } = matcher(location.pathname) || {};
  const { fn = () => {}, shouldRun = false } = tourProps || {};
  const onProductTourClick = () => fn(props);

  const dropdownProps = {
    label: "Help",
    options: [
      { label: "Knowledgebase", onClick: visitHelpSite },
      { label: "Live Chat", onClick: openLiveChat },
      {
        label: "Product Tour",
        disabled: !shouldRun,
        onClick: onProductTourClick,
      },
    ],
    optionRenderer: ({ onClick, disabled, label }) => (
      <DropdownLink onClick={onClick} disabled={disabled || false}>
        {label}
      </DropdownLink>
    ),
  };

  return <DropdownMenu {...dropdownProps} />;
};

export default HelpMenu;

const runResultsTour = (runTour) => (props) => {
  if (isListView(props)) {
    runTour({ steps: resultsTour });
  }
};

const runQuickSearchTour = (runTour) => () => {
  runTour({ steps: quickSearchTour });
};

function isListView({ location }) {
  const { pathname, search } = location;
  const { viewType = "list" } = parse(search);

  return viewType === "list" && pathname === "/results";
}

function visitHelpSite() {
  window.open("https://kofilehelp.zendesk.com/hc/en-us", "_blank");
}
