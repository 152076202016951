import styled from "@emotion/styled";

const borderStyle = "1px solid #d9dde3";
const blue = "#006dd5";
const white = "#ffffff";
const fadedBlue = "#9ea0a5;";

const blueFilter =
  "filter: invert(29%) sepia(49%) saturate(4931%) hue-rotate(196deg) brightness(91%) contrast(102%);";

const whiteFilter =
  "filter: invert(100%) sepia(0%) saturate(1356%) hue-rotate(347deg) brightness(113%) contrast(106%);";

export const Label = styled.div`
  display: ${(props) => (props.open ? "visible" : "hidden")};
`;

const borderToggle = (props) => (props.open ? borderStyle : "none");
const colorToggle = (props) => (props.open ? blue : white);
const bottomToggle = (props) =>
  props.open ? (props.showSuccess ? "-20px" : "-20px") : "-4px";

const backgroundToggle = (props) => {
  if (props.open) return white;
  if (props.disabled) return fadedBlue;
  return blue;
};

export const Header = styled.button`
  display: flex;
  flex: 8%;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px 4px 0 0;
  padding: 20px;
  height: 10px;
  font-family: 'Source Sans Pro';
  background: ${backgroundToggle};
  color: ${colorToggle};

  & > div {
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & img {
    width: 12px;
    transform: ${(props) => (props.open ? "rotate(0deg)" : "rotate(180deg)")};
    ${(props) => (!props.open ? whiteFilter : blueFilter)};
`;

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 4px 4px 0 0;
  font-family: "Source Sans Pro";
  border: ${borderToggle};
  box-shadow: ${borderToggle};
  border-bottom: none;
  height: ${(props) => (props.open ? "420px" : "20px")};
  bottom: ${bottomToggle};
`;

export default Container;
